import { StringParam } from 'use-query-params';

import { CategoryQuestion, UserWithAllInfoDto } from '@api/api';
import { IMenu } from '../../../DashboardAdmin/UserManage/UserManage';
import { PrimaryButton } from '../../../commun/Buttons';
import { useQueryParamCustom } from '../../../commun/CustomHook/useQueryParamCustom';
import { TabsMenu } from '../../../commun/HorizontalMenu';
import DisplayCategoryQuestion, {
    displayTitle,
} from '../FormInvest/CommunComponent/DisplayCategoryQuestion';

const FormQuestion = ({ user }: { user: UserWithAllInfoDto }) => {
    const [pageQuery, setPageQuery] = useQueryParamCustom(
        'pageQuestion',
        StringParam,
        CategoryQuestion.targetInvestissments
    );

    const items: IMenu[] = [
        {
            queryPage: CategoryQuestion.targetInvestissments,
            label: displayTitle(CategoryQuestion.targetInvestissments),
        },
        {
            queryPage: CategoryQuestion.experienceInvestissments,
            label: displayTitle(CategoryQuestion.experienceInvestissments),
        },
        {
            queryPage: CategoryQuestion.fundsFunctionning,
            label: displayTitle(CategoryQuestion.fundsFunctionning),
        },
        {
            queryPage: CategoryQuestion.assets,
            label: displayTitle(CategoryQuestion.assets),
        },
    ];

    return (
        <div>
            <div className="md:h-fit bg-backgroundColor w-full flex justify-center items-center mt-[-2px]  sticky top-[-2px] z-[99] mb-2 mx-auto">
                <TabsMenu items={items} queryPage="pageQuestion" />
            </div>
            <DisplayCategoryQuestion
                className="w-full px-3 md:w-11/12 md:mx-auto lg:w-9/12"
                category={
                    (pageQuery as CategoryQuestion) ||
                    CategoryQuestion.targetInvestissments
                }
                renderButtons={({ isValid, isLoadingEditUser }) => (
                    <PrimaryButton
                        type="submit"
                        disabled={!isValid}
                        loading={isLoadingEditUser}
                    >
                        Modifier
                    </PrimaryButton>
                )}
            />
        </div>
    );
};

export default FormQuestion;
