import { useFetchTokenQuery, UserStatusType } from '@api/api';
import React from 'react';
import { isEmpty } from '../../../function/Utils';
import DonneesPerso from './DonneesPerso/DonneesPerso';
import FormulaireInvestisseur from './FormInvest/FormulaireInvestisseur';
import ValidationUser from './ValidationUser';

const Profil = () => {
    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

    const activeCatFunction = () => {
        if (!isEmpty(user)) {
            switch (user!.status) {
                case UserStatusType.doitRemplirSesDonnEsPersonnelles:
                    return <FormulaireInvestisseur />;
                case UserStatusType.formulaireComplTEnAttenteDeVRification:
                    return <FormulaireInvestisseur />;
                case UserStatusType.profilValidé:
                    // return <ValidationUser />;
                    // return <FormulaireInvestisseur />;

                    return <DonneesPerso />;
                // return <ValidationUser />;

                case UserStatusType.profilNonValidé:
                    return <ValidationUser />;
            }
        }
    };

    return <React.Fragment>{activeCatFunction()}</React.Fragment>;
};

export default Profil;
