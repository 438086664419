import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import { PrimaryButton, WhiteButton } from './Buttons';
// import { PrimaryButton, WhiteButton } from "./Buttons";
import { motion } from 'framer-motion';
import Loading from './Loading';
interface Props {
    children: React.ReactNode;
    title?: () => JSX.Element;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    submitItemName?: string;
    onClickSubmit?: () => void;
    onClickCancel?: () => void;
    buttonBoolean?: boolean;
    disabledOnclose?: boolean;
    onCloseBehavior?: () => void;

    expand?: boolean;
    setExpand?: React.Dispatch<React.SetStateAction<boolean>>;
    loading?: boolean;
    width?: string;
    className?: {
        dialog?: string;
    };
}

export default function PopUp({
    children,
    title,
    open,
    setOpen,
    submitItemName,
    onClickCancel,
    onClickSubmit,
    expand,
    setExpand,
    loading,
    width,
    className,
    onCloseBehavior = () => setOpen(false),
    buttonBoolean = true,
    disabledOnclose = false,
}: Props) {
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className={`relative z-[1001] ${className?.dialog ? className.dialog : ''} `}
                initialFocus={cancelButtonRef}
                onClose={!disabledOnclose ? onCloseBehavior : () => {}}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* Full-screen scrollable container */}
                <div className="fixed inset-0 overflow-y-auto">
                    {/* Container to center the panel */}
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className={`relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 ${
                                    !expand && !width
                                        ? 'sm:min-w-[500px]'
                                        : !width
                                          ? 'sm:w-10/12'
                                          : width
                                } sm:p-6`}
                            >
                                <div className="relative">
                                    {setExpand ? (
                                        <motion.div
                                            className="absolute top-[-20px] right-0 cursor-pointer"
                                            onClick={() =>
                                                setExpand && setExpand(!expand)
                                            }
                                            whileHover={{ scale: 1.09 }}
                                            whileTap={{ scale: 0.95 }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faExpand}
                                                className="h-5 w-5 text-titleColor"
                                            />
                                        </motion.div>
                                    ) : null}
                                    <div className="relative mt-3 text-center sm:mt-5">
                                        {title ? (
                                            //   <Dialog.Title
                                            //     as="h3"
                                            //     className="text-lg font-medium leading-6 text-gray-900"
                                            //   >
                                            //     {title}
                                            //   </Dialog.Title>
                                            <>{title()}</>
                                        ) : null}

                                        <div className="mt-4">
                                            {/* <p className="text-sm text-gray-500">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Eius aliquam laudantium explicabo pariatur iste
                        dolorem animi vitae error totam. At sapiente aliquam
                        accusamus facere veritatis.
                      </p> */}
                                            {children}
                                        </div>
                                    </div>
                                </div>
                                {buttonBoolean ? (
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 text-center">
                                        <WhiteButton
                                            onClick={() => {
                                                onClickCancel?.();
                                                setOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </WhiteButton>
                                        <PrimaryButton
                                            type="submit"
                                            onClick={() => {
                                                onClickSubmit?.();
                                                // setOpen(false);
                                            }}
                                        >
                                            {loading !== undefined &&
                                            loading === true ? (
                                                <Loading size={4} />
                                            ) : (
                                                submitItemName
                                            )}
                                        </PrimaryButton>
                                    </div>
                                ) : null}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
