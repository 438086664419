import { UserWithAllInfoDto } from '@api/api';
import BigTitleComponent from '@components/commun/BigTitleComponent';
import { PrimaryButton, WhiteButton } from '@components/commun/Buttons';
import UpdateInvestAccountComponent from '@components/commun/formComponent/ModelForm/InvestAccount/UpdateInvestAccountComponent';
import {
    onSubmitDocumentAddress,
    onSubmitDocumentIdentity,
} from '@components/commun/formComponent/OnSubmitHelpers';
import { useNotificationContext } from '@components/Context/notification-context';
import UpdateUserInfo, {
    FormUpdateUserInvestAccountDto,
} from '@components/DashboardUser/Profile/UpdateUserInfo';

import { hasProperty } from '@utils/Utils';
import { useNavigate } from 'react-router';
import { NumberParam, useQueryParam } from 'use-query-params';

const EditInvestAccount = ({ user }: { user: UserWithAllInfoDto }) => {
    const [investAccountIdQuery, setInvestAccountQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );

    const navigate = useNavigate();
    const { showError, showSuccess } = useNotificationContext();

    // const { ValidationCode, handleSubmitForm } = useValidationCode({
    //   onSubmit: handleSubmit(onSubmit),
    // });

    return (
        <UpdateInvestAccountComponent
            user={user}
            useSubmitBtn={false}
            investAccountId={investAccountIdQuery ?? 0}
            userInfoInvestAccount={{
                adress: user?.userInfo?.adress ?? '',
                phone: user?.userInfo?.phone ?? '',
                professionalSituation:
                    user?.userInfo?.professionalSituation ?? '',
                politicalyExposed:
                    user?.userInfo?.politicalyExposed == undefined ||
                    user?.userInfo?.politicalyExposed == null
                        ? 'false'
                        : user?.userInfo?.politicalyExposed
                          ? 'true'
                          : ('false' as any),
                politicalyExposedDetails:
                    user?.userInfo?.politicalyExposedDetails ?? '',

                usCitizen: 'false' as any,
            }}
            onSubmitSuccess={() => {
                navigate(-1);
            }}
            beforeSubmitLogic={async ({ data }) => {
                let succeed = true;

                if (!hasProperty(data, 'userInfoInvestAccount')) {
                    return false;
                }

                // update doc adress
                succeed = await onSubmitDocumentAddress(
                    (
                        data.userInfoInvestAccount as FormUpdateUserInvestAccountDto
                    ).documentAdress,
                    showError,
                    showSuccess,
                    user?.id!
                );

                // update doc indentity
                succeed = await onSubmitDocumentIdentity(
                    (
                        data.userInfoInvestAccount as FormUpdateUserInvestAccountDto
                    ).documentIdentity1,
                    showError,
                    showSuccess,
                    user?.id!
                );
                return succeed;
            }}
        >
            {({
                formState,
                disabled,
                loading,
                watch,
                handleSubmit,
                onSubmit,
            }) => (
                <>
                    <BigTitleComponent className=" mt-7">
                        Vérifiez vos informations personnelles
                    </BigTitleComponent>

                    <UpdateUserInfo
                        formState={formState as any}
                        className="w-full px-3 md:w-11/12 md:mx-auto lg:w-9/12"
                    >
                        {/* <h3 className="w-full font-mainFontFamily text-center text-xl">
              Mettez à jour vos documents d'identités si nécessaire
            </h3> */}
                    </UpdateUserInfo>

                    <div className="w-full mt-3 mb-2 flex justify-center gap-3 items-center">
                        <WhiteButton onClick={() => navigate(-1)}>
                            Annuler
                        </WhiteButton>
                        <PrimaryButton
                            type="submit"
                            loading={loading}
                            disabled={
                                (watch(
                                    'userInfoInvestAccount.usCitizen'
                                ) as any) == 'true' || disabled
                                    ? true
                                    : false
                            }
                        >
                            Continuer
                        </PrimaryButton>
                    </div>
                </>
            )}
        </UpdateInvestAccountComponent>
    );
};

export default EditInvestAccount;

// function DisplayValidationDocument({
//   validation
// }: {
//   validation: DocumentValidationForUserDto
// }) {

//   return (

//   )
// }
