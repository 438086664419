import { PrimaryButton, WhiteButton } from './Buttons';

interface Props {
    // cookieConsent:string | undefined;
    setCookieConsent: React.Dispatch<React.SetStateAction<string | null>>;
    setToggleCookie: React.Dispatch<React.SetStateAction<boolean>>;
}

const CookiesComponent: React.FC<Props> = ({
    setCookieConsent,
    setToggleCookie,
}) => {
    function OnClick(bol: boolean) {
        const element = document.querySelector(
            '.cookiesContainers'
        ) as HTMLElement;
        element.style.animationName = 'disparitionCookies_bis';
        // element?.setAttribute("style", "animation-name:disparitionCookies_bis;");
        if (bol) {
            localStorage.setItem('accepterCookies', 'true');
        } else {
            localStorage.setItem('accepterCookies', 'false');
        }
        setToggleCookie(false);
        setTimeout(() => {
            element.style.animationName = '';
            setCookieConsent('true');
        }, 600);
    }

    return (
        <div className="cookiesContainers flex flex-col">
            <div className="texteContainer">
                <h3 className="font-semibold mb-3">
                    Nous utilisons des cookies sur ce site pour améliorer votre
                    expérience d'utilisateur.
                </h3>
                <p>
                    En cliquant sur un lien de cette page, vous nous donnez
                    votre consentement de définir des cookies.
                </p>
                <a
                    href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser"
                    target="_blank"
                    rel="noreferrer"
                    className="text-secondColor focus:border-none focus:outline-none"
                >
                    En savoir plus
                </a>
            </div>
            <div className="flex mt-3 gap-2 w-11/12 mx-auto  items-center justify-center">
                <PrimaryButton
                    onClick={() => {
                        OnClick(true);
                    }}
                >
                    Accepter
                </PrimaryButton>
                <WhiteButton
                    onClick={() => {
                        OnClick(false);
                    }}
                >
                    Non, merci
                </WhiteButton>
            </div>
        </div>
    );
};

export default CookiesComponent;
