import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { NumberParam, useQueryParam } from 'use-query-params';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import CheckboxComponent from '../../commun/formComponent/CheckboxComponent';

interface Props {
    toogleInvestInfo: boolean;
    setToogleInvestInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Form {
    accept2: boolean;
}

const InvestmentInfo: React.FC<Props> = ({
    setToogleInvestInfo,
    toogleInvestInfo,
}) => {
    const [step, setStep] = useQueryParam('stepInvest', NumberParam);

    const navigate = useNavigate();

    const { register, watch } = useForm<Form>({
        defaultValues: {
            accept2: false,
        },
    });

    const text = [
        {
            title: 'Performance',
            subTitle:
                'Pour protéger les investisseurs actuels, les titres commencent à générer une performance 30 jours après leur émission.',
            items: [
                'Tout argent non investi réduit la performance du portefeuille.',
                "Afin de minimiser l'impact des nouvelles liquidités sur les investisseurs existants, la jouissance des titres est gelée pendant les 30 premiers jours.",
                "Un délai de 30 jours entre l'émission et la jouissance permet à un maximum de ces liquidités d'être investi et de générer une performance en adéquation avec celle du fonds.",
            ],
        },
        {
            title: "Horizon d'investissement",
            subTitle:
                "Il est recommandé de viser un horizon d'investissement minimum de 2 ans.",
            items: [
                "Aucun frais de sortie n'est appliqué après 24 mois.",
                "Des frais de 4 % s'appliquent pour toutes demandes de sorties durant les 24 premiers mois.",
            ],
        },
        {
            title: 'Portefeuille immoilisé',
            subTitle:
                'Pour protéger les nouveaux investisseurs, une partie de votre investissement peut être temporairement bloquée.',
            items: [
                'Un système de portefeuille immobilisé a été mis en place afin de protéger tout nouvel investisseur des projets ayant émis des signaux de difficulté.',
                "Lorsqu'un projet semble en difficulté (par exemple, retard de paiement), les investisseurs présents à ce moment verront la part de leur investissement exposée à ce projet gelée le temps de sa résolution (remboursement complet, partiel ou défaut).",
                'Le projet en difficulté est ainsi contenu aux investisseurs bloqués sur ce projet, et tout nouvel investissement ne sera pas exposé à ce projet.',
            ],
        },
    ];

    return (
        <div className="">
            <h2 className="mt-2 mb-4 text-center text-2xl  font-mainFontFamily">
                Rappel des spécificités à retenir sur le fonctionnement des
                portefeuilles
            </h2>
            <div className="w-11/12 mx-auto flex flex-col gap-5">
                {text.map((t, key) => (
                    <div className="" key={key}>
                        <h4 className="font-mainFontFamily font-semibold text-mainColor text-xl">
                            {t.title}
                        </h4>
                        <p className="font-semibold mb-3">{t.subTitle}</p>
                        <ul className="">
                            {t.items.map((item, i) => (
                                <li key={i} className="list-disc  text-justify">
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}

                <div className="mt-4">
                    <CheckboxComponent
                        register={register}
                        watch={watch}
                        values={[
                            {
                                label: " J'ai bien pris connaissance du fonctionnement et des règles de mon investissement, j'ai compris les éléments ci-dessus.",
                                name: 'accept2',
                            },
                        ]}
                    />
                </div>

                <div className="flex w-full justify-center gap-3 items-center">
                    <WhiteButton
                        onClick={() => {
                            setToogleInvestInfo(false);

                            // navigate("/DashBoardClient/investir");
                        }}
                    >
                        Annuler
                    </WhiteButton>
                    <PrimaryButton
                        disabled={!watch('accept2')}
                        onClick={() => {
                            setToogleInvestInfo(false);
                            setStep((step || 0) + 1);
                        }}
                        type="button"
                    >
                        Valider
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default InvestmentInfo;
