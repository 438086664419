import React, { ReactNode, useCallback, useEffect } from 'react';
import {
    FieldErrorsImpl,
    FieldValues,
    Path,
    UseFormGetValues,
    UseFormRegister,
    UseFormReturn,
    UseFormSetValue,
    UseFormWatch,
    useController,
} from 'react-hook-form';
import { StringParam, useQueryParam } from 'use-query-params';
import { debounce, numericValue } from '../../../function/Utils';

interface Props<T extends FieldValues> {
    register: UseFormRegister<T>;
    watch?: UseFormWatch<T>;
    errors?: FieldErrorsImpl<T>;
    commentaire?: string;
    formState?: UseFormReturn<T, any>;

    componentEnd?: () => JSX.Element;
    className?: {
        input?: string;
        container?: string;
        containerAbove?: string;
    };
    value: Path<T>;
    required?: boolean;
    cursorNotAllowed?: true;
    type?: string;
    setValue?: UseFormSetValue<T>;
    useParamBoolean?: boolean;
    getValues?: UseFormGetValues<T>;
    maxDate?: Date;
    minDate?: Date;
    min?: number;
    max?: number;
}

const InputComponent = <T extends object>(
    props: Props<T> & { children?: ReactNode }
) => {
    const [queryParams, setQueryParam] = useQueryParam(
        props.value,
        StringParam
    );

    useEffect(() => {
        if (props.useParamBoolean && props.getValues) {
            updateDateEnd();
        }
    }, [props.watch && props.watch(props.value)]);

    const updateDateEnd = useCallback(
        debounce(() => {
            if (props.useParamBoolean && props.getValues) {
                setQueryParam(props.getValues(props.value) as any);
            }
        }, 1000),
        []
    );

    useEffect(() => {
        if (props.useParamBoolean) {
            if (queryParams && props.setValue) {
                props.setValue(props.value, queryParams as any);
            }
        }
    }, [queryParams]);

    const jsxFunction = (): JSX.Element => {
        return (
            <>
                <input
                    type={props?.type != undefined ? props.type : 'text'}
                    step={`${
                        props?.type != undefined && props.type === 'number'
                            ? 'any'
                            : null
                    }`}
                    max={
                        props.maxDate
                            ? props.maxDate?.toISOString().split('T')[0]
                            : props.max !== undefined
                              ? props.max
                              : undefined
                    }
                    min={
                        props.minDate
                            ? props.minDate?.toISOString().split('T')[0]
                            : props.min !== undefined
                              ? props.min
                              : undefined
                    }
                    {...props.register(props.value, {
                        required: props?.required === false ? false : true,
                    })}
                    className={`form-control w-full ${
                        props?.cursorNotAllowed != undefined &&
                        'pointer-events-none bg-gray-300 opacity-60'
                    } ${props?.className != undefined && props.className.input} ${
                        props.errors &&
                        typeof props.value === 'string' &&
                        props.value in props.errors &&
                        props.errors[props.value as unknown as keyof T]
                            ? 'is-invalid'
                            : ''
                    }`}
                    required={props?.required === false ? false : true}
                />
            </>
        );
    };
    return (
        <div className={`form-item ${props.className?.containerAbove}`}>
            {props.children}
            {props.cursorNotAllowed ? (
                <div
                    className={`cursor-not-allowed w-full ${props?.className?.container}`}
                >
                    {jsxFunction()}
                </div>
            ) : (
                <>
                    {props?.className?.container ? (
                        <div className={`${props?.className?.container}`}>
                            {jsxFunction()}
                        </div>
                    ) : (
                        <>{jsxFunction()}</>
                    )}
                </>
            )}
            {!!props.commentaire && (
                <p className="italic commentaireInput">{props.commentaire}</p>
            )}
        </div>
    );
};

export default InputComponent;

//  Info I can useFormContext

// const FormComponent = () => {
//   const methods = useForm();

//   return (
//     <FormProvider {...methods}>
//       <form onSubmit={methods.handleSubmit((data) => console.log(data))}>
//         <NumericInput name="numberField" required min={1} max={100} />
//         <button type="submit">Submit</button>
//       </form>
//     </FormProvider>
//   );
// };

interface NumericInputProps<T extends FieldValues> {
    name: Path<T>;
    required?: boolean;
    min?: number;
    max?: number;
    formState: UseFormReturn<T, any>;

    errors?: FieldErrorsImpl<T>;
    commentaire?: string;

    componentEnd?: () => JSX.Element;
    className?: {
        input?: string;
        container?: string;
        containerAbove?: string;
    };

    cursorNotAllowed?: true;

    useParamBoolean?: boolean;
}

export const NumericInput = <T extends object>({
    name,
    required,
    min,
    max,
    formState,
    cursorNotAllowed,
    className,
    errors,
    commentaire,
    children,
    useParamBoolean,
}: NumericInputProps<T> & { children?: ReactNode }) => {
    const [queryParams, setQueryParam] = useQueryParam(name, StringParam);

    useEffect(() => {
        if (useParamBoolean) {
            updateDateEnd();
        }
    }, [formState.watch && formState.watch(name)]);

    const updateDateEnd = useCallback(
        debounce(() => {
            if (useParamBoolean) {
                setQueryParam(formState.getValues(name) as any);
            }
        }, 1000),
        []
    );

    useEffect(() => {
        if (useParamBoolean) {
            if (queryParams) {
                formState.setValue(name, queryParams as any);
            }
        }
    }, [queryParams]);

    const {
        field: { value: exportFormat, onChange: exportFormatOnChange },
    } = useController<T>({
        name: name,
        control: formState.control,
        rules: { required: required === false ? false : true },
    });

    const [isDot, setIsDot] = React.useState(false);
    const [nbOfLast0, setNbOfLast0] = React.useState(0);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // if '.' is included add to the number at the end
        const isDotValue =
            e.target.value.includes('.') &&
            (e.target.value.split('.')[1].length === 0 ||
                e.target.value.split('.')[1] == '0' ||
                e.target.value.split('.')[1][1] == '0');
        setIsDot(isDotValue);
        const isLastFigure0Value =
            e.target.value.includes('.') &&
            e.target.value.split('.')[1].length > 0 &&
            e.target.value.split('.')[1][
                e.target.value.split('.')[1]?.length - 1
            ] === '0';
        if (isLastFigure0Value) {
            // count the number of 0 at the end
            let nbOfLast0 = 0;
            for (
                let i = e.target.value.split('.')[1]?.length - 1;
                i >= 0;
                i--
            ) {
                if (e.target.value.split('.')[1][i] === '0') {
                    nbOfLast0++;
                } else {
                    break;
                }
            }
            setNbOfLast0(nbOfLast0 || 0);
        } else {
            setNbOfLast0(0);
        }

        const number = numericValue(e.target.value);
        if (Number.isNaN(number)) {
            exportFormatOnChange('');
        } else if (
            ((min != undefined && number >= min) || min == undefined) &&
            ((max != undefined && number <= max) || max == undefined)
        ) {
            exportFormatOnChange(number);
        }
    };

    const displayValue = (value: number | string | undefined) => {
        if (typeof value === 'number') {
            const valueString = value.toString();
            if (valueString.includes('.')) {
                const [integer, decimal] = valueString.split('.');
                return (
                    integer.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') +
                    '.' +
                    decimal +
                    '0'.repeat(nbOfLast0)
                );
            } else {
                let res = value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                if (isDot) {
                    res += '.';
                }

                if (nbOfLast0) {
                    res += '0'.repeat(nbOfLast0);
                }
                return res;
            }
        } else {
            return value;
        }
    };

    const jsxFunction = (): JSX.Element => {
        return (
            <input
                value={displayValue(exportFormat)}
                onChange={handleChange}
                className={`form-control w-full ${
                    cursorNotAllowed != undefined &&
                    'pointer-events-none bg-gray-300 opacity-60'
                } ${className != undefined && className.input} ${
                    errors &&
                    typeof name === 'string' &&
                    name in errors &&
                    errors[name as unknown as keyof T]
                        ? 'is-invalid'
                        : ''
                }`}
                type="text"
                required={required === false ? false : true}
            />
        );
    };

    return (
        <div className={`form-item ${className?.containerAbove}`}>
            {children}
            {cursorNotAllowed ? (
                <div
                    className={`cursor-not-allowed w-full ${className?.container}`}
                >
                    {jsxFunction()}
                </div>
            ) : (
                <>
                    {className?.container ? (
                        <div className={`${className?.container}`}>
                            {jsxFunction()}
                        </div>
                    ) : (
                        <>{jsxFunction()}</>
                    )}
                </>
            )}
            {!!commentaire && (
                <p className="italic commentaireInput">{commentaire}</p>
            )}
        </div>
    );
};
