import {
    TransactionDto,
    TransactionStatus,
    TypeTransaction,
    UpdateTransactionDistributionRequest,
    useDeleteTransactionMutation,
    useGetCashIdsQuery,
    useGetTransactionByIdQuery,
    useGetTransactionDocumentsQuery,
    useGetTransactionsUserQuery,
    useGetUsersSearchQuery,
    useUpdateTransactionDistributionMutation,
} from '@api/api';
import {
    UploadDocumentTransactionDto,
    uploadDocumentTransaction,
} from '@api/features/transactionSlice';
import { useAppDispatch } from '@api/store';
import FieldArray from '@components/commun/formComponent/FieldArray';
import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import RemoveFieldArrayComponent from '@components/commun/formComponent/RemoveFieldArrayComponent';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate, stringToFloat, transformDate } from '@utils/Utils';
import { defaultValuesTransactionUserOutItem } from '@utils/dataHelper/TransactionHelper';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useNotificationContext } from '../../Context/notification-context';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import useDeleteItem from '../../commun/CustomHook/useDeleteItem';
import DisplayDocumentTransaction from '../../commun/DisplayDocuments/DisplayDocumentTransaction';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import InputComponent, {
    NumericInput,
} from '../../commun/formComponent/InputComponent';
import SelectComponent, {
    IOptionValues,
} from '../../commun/formComponent/SelectComponent';
import UploaderMulti from '../../commun/formComponent/UploaderMulti';

export interface UpdateTransactionDistributionForm
    extends UpdateTransactionDistributionRequest {
    files?: File[];
}

export default function ManageTransactionOutShareD({
    editToggle,
    setEditToggle,
}: {
    editToggle: boolean;
    setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const dispatch = useAppDispatch();

    const { showError, showSuccess } = useNotificationContext();

    const [transactionId, setQueryParam] = useQueryParam(
        'transactionId',
        NumberParam
    );

    const {
        data: documents,
        isLoading: loadingDocument,
        refetch,
    } = useGetTransactionDocumentsQuery(transactionId ?? 0);

    useEffect(() => {
        refetch();
    }, []);

    const { data: transaction, isLoading } = useGetTransactionByIdQuery(
        transactionId ?? 0
    );

    const [loading, setLoading] = useState<boolean>(false);

    const setDefautlValues = (): void => {
        reset({
            ...transaction,
            comment: transaction?.comment ?? undefined,
            status: transaction?.status,
            date: transformDate(new Date(transaction?.date!).getTime())
                .toJSON()
                ?.split('T')[0],
        });
    };

    useEffect(() => {
        if (transaction) {
            setDefautlValues();
        }
    }, [transaction]);

    const formState = useForm<UpdateTransactionDistributionForm>();

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        control,
    } = formState;

    const [editTransaction] = useUpdateTransactionDistributionMutation();

    const [deleteTransaction, { isLoading: isLoadingDelete }] =
        useDeleteTransactionMutation();

    const onSubmit = async (data: UpdateTransactionDistributionForm) => {
        setLoading((curr) => !curr);
        const files = data?.files;
        delete data.files;

        await editTransaction({
            updateTransactionDistributionRequest: {
                id: transaction?.id!,
                date: transformDate(data.date)?.toISOString(),
                status: data.status,
                to: data.to,
                comment: data?.comment,
                amount: stringToFloat(data.amount),
                transactionUserOut: data.transactionUserOut,
            },
            id: transaction?.id!,
        })
            .unwrap()
            .then(async (res) => {
                try {
                    if (files) {
                        for (let i = 0; i < files.length; i++) {
                            const data: UploadDocumentTransactionDto = {
                                transactionId: (res as TransactionDto).id,
                                file: files[i],
                                fileName: files[i].name,
                                // type:(res.payload as TransactionDto)?.type === TypeTransaction.buyingProjectCash ? TypeDocumentTransaction. ,
                            };
                            await uploadDocumentTransaction(data)
                                .then((res) => {
                                    if (res.sucess) {
                                        showSuccess('Created', 'File uploaded');
                                    } else {
                                        showError(
                                            'Error',
                                            `Error uploading file ${data.fileName}: ${res.message}`
                                        );
                                    }
                                })
                                .catch((res) => {
                                    showError('Error', 'Error uploading file');
                                });
                        }
                    }
                    showSuccess('Edited', 'Transaction edited successfully');
                    setEditToggle((curr) => !curr);
                } catch (error) {
                    showError('Error', 'Error editing transaction');
                    return;
                }
            })
            .catch((err) => {
                showError('Error', 'Error editing transaction');
            });
        setLoading((curr) => !curr);
    };

    const { data: transactionUser } = useGetTransactionsUserQuery(
        watch('to') ?? 0,
        {
            skip: watch('to') === undefined ? true : false,
        }
    );

    const transactionUserSelect = (): IOptionValues[] => {
        if (transactionUser) {
            return transactionUser
                .filter(
                    (t) =>
                        t.type === TypeTransaction.userCash &&
                        t.from === watch('to')
                )
                .map((t) => {
                    return {
                        label: `date: ${formatDate(new Date(t.date))} montant: ${t.amount}`,
                        value: t.id,
                    };
                });
        } else {
            return [];
        }
    };

    const [searchFrom, setSearchFrom] = useState<string>('');
    const [searchTo, setSearchTo] = useState<string>('');

    const { data: cashData } = useGetCashIdsQuery();

    const { data: usersData } = useGetUsersSearchQuery(searchTo);

    const selectFunc = useCallback((): IOptionValues[] => {
        const user: IOptionValues[] =
            usersData?.map((user, key) => {
                return {
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.id,
                };
            }) ?? [];

        // if transaction and transaction.userNameTo is not in the list add it
        if (
            transaction &&
            transaction.userNameTo &&
            !user.find((u) => u.label === transaction.userNameTo)
        ) {
            user.push({
                label: transaction.userNameTo,
                value: transaction.to,
            });
        }

        return user;
    }, [usersData]);

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        transactionId: number;
    }>({
        deleteItem: () => deleteTransaction(transaction?.id!),
        toDoIfSuccess: () => setEditToggle(false),
        message: 'transaction',

        isLoading: isLoadingDelete,
    });

    const [expanded, setExpanded] = useState<boolean>(true);

    return (
        <PopUp
            open={editToggle}
            setOpen={setEditToggle}
            submitItemName={`Editer`}
            buttonBoolean={false}
            expand={expanded}
            setExpand={setExpanded}
            title={() => {
                return (
                    <div className="relative">
                        <h3 className="text-3xl font-semibold leading-6 text-gray-900">
                            Editer cette transaction
                        </h3>
                        {transaction && transaction?.id ? (
                            <div className="absolute top-[-20px] cursor-pointer left-4 transition-all hover:scale-105">
                                <FontAwesomeIcon
                                    onClick={() => setOpenDelete(true)}
                                    icon={faTrash}
                                    className=""
                                />
                            </div>
                        ) : null}
                    </div>
                );
            }}
        >
            <DeleteComponent title="cette transaction" />

            {isLoading ? (
                <div className="flex justify-center items-center h-[70vh]">
                    <Loading />
                </div>
            ) : (
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formTemplateSingUp"
                    style={{ width: `${expanded ? '98%' : '80%'}` }}
                >
                    <div className="w-full col-span-2 flex justify-center items-center">
                        <PrimaryButton
                            loading={isLoading}
                            onClick={() => {
                                setValue('status', TransactionStatus.VALIDATED);
                                handleSubmit(onSubmit)();
                            }}
                        >
                            Valider la transaction
                        </PrimaryButton>
                    </div>
                    <div
                        className={`w-full mx-auto ${
                            expanded
                                ? 'sm:flex sm:space-x-5 sm:justify-between '
                                : ''
                        }`}
                    >
                        <div
                            className={`w-full ${expanded ? 'sm:w-10/12 ' : ''}`}
                        >
                            <SelectComponent
                                register={register}
                                value={'to'}
                                container={true}
                                setSearch={setSearchTo}
                                optionValues={selectFunc()}
                                watch={watch}
                                control={control}
                                getValues={getValues}
                                setValue={setValue}
                                useParamBoolean={true}
                            >
                                <h3>
                                    Pour <span className="required">*</span>
                                </h3>
                            </SelectComponent>

                            <InputComponent
                                register={register}
                                value={'amount'}
                                type={'number'}
                            >
                                <h3>
                                    Montant <span className="required">*</span>
                                </h3>
                            </InputComponent>

                            <InputComponent
                                register={register}
                                value={'date'}
                                type={'date'}
                            >
                                <h3>
                                    Date <span className="required">*</span>
                                </h3>
                            </InputComponent>

                            <TitleComponentForm>
                                Le montant est retiré en utilisant les
                                transactions suivantes
                            </TitleComponentForm>
                            <FieldArray
                                control={control}
                                name={
                                    'transactionUserOut.transactionUserOutItems'
                                }
                            >
                                {({ fields, append, remove }) => (
                                    <>
                                        <div className="">
                                            {fields.map((field, index) => (
                                                <div
                                                    key={field.id}
                                                    className=" grid grid-cols-2 gap-x-3"
                                                >
                                                    <div className=" text-start my-2 font-semibold text-xl col-span-2 border-b-2">
                                                        Transaction {index + 1}
                                                    </div>
                                                    <NumericInput
                                                        formState={formState}
                                                        name={`transactionUserOut.transactionUserOutItems.${index}.withdrawalFee`}
                                                        min={0}
                                                    >
                                                        <LabelComponentForm>
                                                            Frais de sotie
                                                        </LabelComponentForm>
                                                    </NumericInput>

                                                    <NumericInput
                                                        formState={formState}
                                                        name={`transactionUserOut.transactionUserOutItems.${index}.share`}
                                                        min={0}
                                                    >
                                                        <LabelComponentForm>
                                                            Part
                                                        </LabelComponentForm>
                                                    </NumericInput>

                                                    <NumericInput
                                                        formState={formState}
                                                        name={`transactionUserOut.transactionUserOutItems.${index}.nbShare`}
                                                        min={0}
                                                    >
                                                        <LabelComponentForm>
                                                            Nombre de part
                                                        </LabelComponentForm>
                                                    </NumericInput>

                                                    <SelectComponent
                                                        register={register}
                                                        value={`transactionUserOut.transactionUserOutItems.${index}.transactionInId`}
                                                        container={true}
                                                        optionValues={transactionUserSelect()}
                                                        watch={watch}
                                                        control={control}
                                                        getValues={getValues}
                                                        setValue={setValue}
                                                        useParamBoolean={true}
                                                    >
                                                        <LabelComponentForm>
                                                            Transaction{' '}
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </LabelComponentForm>
                                                    </SelectComponent>

                                                    <RemoveFieldArrayComponent
                                                        remove={remove}
                                                        index={index}
                                                        className=" col-span-2"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <PrimaryButton
                                            className=" mx-auto my-2"
                                            onClick={() =>
                                                append({
                                                    ...(defaultValuesTransactionUserOutItem as any),
                                                    transactionUserOutId:
                                                        transaction
                                                            ?.transactionUserOut
                                                            ?.transactionId,
                                                    // projectId: project.current?.id!,
                                                })
                                            }
                                        >
                                            Ajouter
                                        </PrimaryButton>
                                    </>
                                )}
                            </FieldArray>

                            <InputComponent
                                register={register}
                                value={'comment'}
                                required={false}
                            >
                                <h3>Commentaire</h3>
                            </InputComponent>
                            <SelectComponent
                                register={register}
                                value={'status'}
                                container={true}
                                control={control}
                                getValues={getValues}
                                setValue={setValue}
                                watch={watch}
                                optionValues={Object.values(
                                    TransactionStatus
                                ).map((v: string) => {
                                    return {
                                        value: v,
                                        label: v,
                                    };
                                })}
                            >
                                <h3>
                                    Status <span className="required">*</span>
                                </h3>
                            </SelectComponent>
                        </div>
                        {expanded ? (
                            <div className="w-full sm:w-11/12">
                                <UploaderMulti
                                    register={register}
                                    value={'files'}
                                    size={10}
                                    watch={watch}
                                    setValue={setValue}
                                    control={control}
                                    button={false}
                                >
                                    <h3 className="text-2xl mb-5">
                                        Ajouter des documents
                                    </h3>
                                </UploaderMulti>
                                <div className="">
                                    <h3 className="text-xl font-semibold mb-3">
                                        Documents
                                    </h3>
                                    <div className="w-full sm:11/12 mx-auto gap-2 flex justify-center flex-col">
                                        {loadingDocument ? (
                                            <Loading />
                                        ) : (
                                            <>
                                                {documents &&
                                                documents?.length! > 0 ? (
                                                    documents.map(
                                                        (doc, index) => (
                                                            <DisplayDocumentTransaction
                                                                document={doc}
                                                                key={index}
                                                            />
                                                        )
                                                    )
                                                ) : (
                                                    <p>
                                                        Aucun document n'a été
                                                        trouvé
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
                        <WhiteButton
                            onClick={() => {
                                setEditToggle(false);
                            }}
                            className="w-full"
                        >
                            Cancel
                        </WhiteButton>
                        <PrimaryButton type="submit" className="w-full">
                            {loading !== undefined && loading === true ? (
                                <Loading size={4} />
                            ) : (
                                'Edit'
                            )}
                        </PrimaryButton>
                    </div>
                </form>
            )}
        </PopUp>
    );
}
