import React from "react";
import QualiteItems from "./QualiteItems";
import { qualiteList } from "../../data/qualiteList";

const Qualite: React.FC = () => {
  return (
    <React.Fragment>
      <h2 className="titre_qualite_section">
        Pourquoi investir avec New Paradigms ?
      </h2>
      <div className="qualite_section">
        {qualiteList.map((val, key) => (
          <React.Fragment key={key}>
            <QualiteItems qualiteObject={val} keyProp={key} />
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Qualite;
