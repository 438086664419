import {
    useFetchTokenQuery,
    useGetHistoricalUsersValorisationQuery,
} from '@api/api';
import { Chart } from 'chart.js';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ComputationUserDashboard } from '../../../function/ComputationUserDashboard';
import { debounce, isEmpty } from '../../../function/Utils';
import { useGetAllFondsQuery } from '../../../redux/features/fondsSlice';
import { useAppSelector } from '../../../redux/store';
import { MenuDashBoardUserBoolState } from '../../Context/AppContext';

interface Props {
    computationObject: ComputationUserDashboard;
}

const ChartValo: React.FC<Props> = ({ computationObject }) => {
    const pageState = useAppSelector(
        (state) => state.pageMeasurement.measurements
    );

    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

    const { data: valo, isLoading } = useGetHistoricalUsersValorisationQuery(
        user?.id ?? 0,
        {
            skip: !user,
        }
    );

    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    const { data: fondsData } = useGetAllFondsQuery();

    const [datasets, setDatasets] = useState<any>();
    const [labels, setLabels] = useState<any>();

    const updateGraph = useCallback(
        debounce(() => {
            if (fondsData && !isEmpty(valo) && computationObject) {
                setLabels(
                    valo?.data.map((v) =>
                        formatInTimeZone(
                            new Date(v.date),
                            'Europe/Paris',
                            'MM/yyyy'
                        )
                    )
                );

                const datasets_ = [
                    {
                        label: 'Votre valorisation',
                        data: valo?.data.map((v) => v.value),
                        backgroundColor: 'rgba(20, 93, 136, 0.2)',
                        borderColor: 'rgba(20, 93, 136,1)',
                    },
                ];

                setDatasets(datasets_);
            }
        }, 100),
        [valo, fondsData, computationObject]
    );

    useEffect(() => {
        if (!isEmpty(valo)) {
            updateGraph();
        }
    }, [valo, fondsData, menuBoolState?.toggleMenu, computationObject]);

    useEffect(() => {
        if (datasets && labels) {
            const hook = document.getElementById(
                'myChart3'
            )! as HTMLCanvasElement;
            const ctx = hook.getContext('2d')!;
            const myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: datasets,
                },
                options: {
                    responsive: true,

                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                font: {
                                    size: 20,
                                    family: 'Spectral',
                                },
                            },
                        },
                    },
                },
            });

            return () => {
                myChart.destroy();
            };
        }
    }, [pageState.widthScreen, labels, datasets, menuBoolState?.toggleMenu]);

    return (
        <React.Fragment>
            <canvas
                id="myChart3"
                // style={{ minWidth: "400px", minHeight: "200px" }}
            ></canvas>
        </React.Fragment>
    );
};

export default ChartValo;
