import { useFetchTokenQuery, useUpdatePasswordMutation } from '@api/api';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { PrimaryButton } from '../../../commun/Buttons';
import PasswordInput from '../../../commun/formComponent/PasswordInput';
import useValidationCode from '../../../commun/formComponent/ValidationCode';
import { useNotificationContext } from '../../../Context/notification-context';

export interface ChangePasswordInterface {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

const ChangePassword = () => {
    const { data: user } = useFetchTokenQuery();
    const [message, setMessage] = useState<string>('');

    const validationSchema: Yup.SchemaOf<ChangePasswordInterface> =
        Yup.object().shape({
            oldPassword: Yup.string().required(
                "L'ancien mot de passe est demandé"
            ),
            newPassword: Yup.string()
                .required('le mot de passe est demandé')
                .min(8, 'le mot de passe doit au moins contenir 8 caractères')
                .max(40, 'le mot de passe ne doit pas excéder 40 caractères')
                .matches(
                    /^(?=.*[^A-Za-z0-9])[^&\\;<>]+$/,
                    'Il faut au moins un charactère spécial mais non spécifique ne pas utiliser (&;<>)'
                ),
            confirmNewPassword: Yup.string()
                .required('Répéter le mot de passe est demandé')
                .oneOf(
                    [Yup.ref('newPassword'), null],
                    'Les mots de passe ne correspondent pas'
                ),
        });

    const { showError, showSuccess } = useNotificationContext();

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        reset,
    } = useForm<ChangePasswordInterface>({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

    const onSubmit = async (data: ChangePasswordInterface) => {
        await updatePassword({
            userId: user?.id!,
            updateUserPasswordDto: {
                id: user?.id!,
                email: user?.email!,
                password: data.newPassword,
                oldPassword: data.newPassword,
            },
        })
            .unwrap()
            .then((res) => {
                if (res.message === 'success') {
                    setMessage('Mot de passe modifié !');
                    showSuccess(
                        'Modifié',
                        'Votre mot de passe a bien été modifié !'
                    );
                    reset();
                } else {
                    setMessage(res.message);
                }
            })
            .catch(() => {
                showError(
                    'Erreur',
                    'Un problème est survenue lors de la modification du mot de passe !'
                );
            });
    };

    const { handleSubmitForm, ValidationCode } = useValidationCode({
        onSubmit: handleSubmit(onSubmit),
    });

    const formObject: { label: string; name: keyof ChangePasswordInterface }[] =
        [
            {
                label: 'Ancien mot de passe',
                name: 'oldPassword',
            },
            {
                label: 'Nouveau mot de passe',
                name: 'newPassword',
            },
            {
                label: 'Confirmez votre nouveau mot de passe',
                name: 'confirmNewPassword',
            },
        ];

    return (
        <form
            className="formTemplateSingUp"
            onSubmit={handleSubmit(handleSubmitForm)}
        >
            <ValidationCode>
                <p className="text-lg text-titleColor">
                    Afin de valider la modification vous devez rentrer
                    ci-dessous le code envoyé par mail
                </p>
            </ValidationCode>
            <p
                className={
                    message === 'Mot de passe modifié !'
                        ? 'text-green-600'
                        : 'text-red-600'
                }
            >
                {message}
            </p>
            {formObject.map(({ name, label }, key) => (
                <PasswordInput
                    register={register}
                    errors={errors}
                    label={label}
                    name={name}
                    key={key}
                    componentError={() => {
                        return (
                            <div className="invalid-feedback">
                                {errors[name]?.message}
                            </div>
                        );
                    }}
                />
            ))}
            <div className="w-fit mt-2 justify-center items-center mx-auto">
                <PrimaryButton
                    disabled={!isValid}
                    loading={isLoading}
                    type="submit"
                >
                    Modifier le mot de passe
                </PrimaryButton>
            </div>
        </form>
    );
};

export default ChangePassword;
