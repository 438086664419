import {
    TransactionDto,
    TransactionTableDto,
    TypeTransaction,
    UpdateTransactionDto,
    api,
    useDeleteTransactionMutation,
    useGetTransactionByIdQuery,
    useGetTransactionDocumentsQuery,
    useUpdateTransactionMutation,
} from '@api/api';
import {
    UploadDocumentTransactionDto,
    uploadDocumentTransaction,
} from '@api/features/transactionSlice';
import { useAppDispatch } from '@api/store';
import useDeleteItem from '@components/commun/CustomHook/useDeleteItem';
import DisplayDocumentTransaction from '@components/commun/DisplayDocuments/DisplayDocumentTransaction';
import TransactionForm, {
    TypeTransactionProject2Cash,
    UpdateOrCreateTransactionDtoForm,
} from '@components/commun/formComponent/ModelForm/Transaction/TransactionForm';
import Loading from '@components/commun/Loading';
import { useNotificationContext } from '@components/Context/notification-context';
import { transformDate } from '@utils/Utils';
import { ReactNode, useEffect, useMemo } from 'react';

interface Props {
    transactionId: number;
    afterSubmitEditLogic?: () => void;
    afterDeleteLogic?: () => void;
    renderButtons: (props: { isLoading: boolean }) => ReactNode;
    container: (props: {
        children: ReactNode;
        expanded: boolean;
        transaction?: TransactionDto;
        setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
        openDelete: boolean;
        setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    }) => ReactNode;
}

function UpdateTransactionForm({
    transactionId,
    afterSubmitEditLogic,
    afterDeleteLogic,
    ...props
}: Props): JSX.Element {
    const { showError, showSuccess } = useNotificationContext();

    const { data: transaction, isLoading } = useGetTransactionByIdQuery(
        transactionId ?? 0
    );

    const {
        data: documents,
        isLoading: loadingDocument,
        refetch,
    } = useGetTransactionDocumentsQuery(transactionId ?? 0);

    const dispatch = useAppDispatch();

    const [
        deleteTransaction,
        { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
    ] = useDeleteTransactionMutation();

    const {
        setOpen: setOpenDelete,
        open: openDelete,
        DeleteComponent,
    } = useDeleteItem<{
        transactionId: number;
    }>({
        deleteItem: () => deleteTransaction(transaction?.id!),
        message: 'transaction',
        isLoading: isLoadingDelete,
        toDoIfSuccess: () => {
            if (afterDeleteLogic) {
                afterDeleteLogic();
            }
        },
    });

    useEffect(() => {
        if (transaction) {
            if (transaction.type === TypeTransaction.cashUser) {
                dispatch(
                    api.util.updateQueryData('getUsersSearch', '', (draft) => {
                        if (draft?.find((u) => u.id === transaction.to))
                            return draft;
                        return draft?.concat([
                            {
                                id: transaction.to,
                                firstName: transaction.userNameTo!,
                                lastName: '',
                            },
                        ]);
                    })
                );
            } else if (transaction.type === TypeTransaction.userCash) {
                dispatch(
                    api.util.updateQueryData('getUsersSearch', '', (draft) => {
                        if (draft?.find((u) => u.id === transaction.from))
                            return draft;
                        return draft?.concat([
                            {
                                id: transaction.from,
                                firstName: transaction.userNameFrom!,
                                lastName: '',
                            },
                        ]);
                    })
                );
            } else if (
                transaction.type === TypeTransaction.projectCash ||
                transaction.type === TypeTransaction.projectStopCash ||
                transaction.type === TypeTransaction.buyingProjectCash
            ) {
                dispatch(
                    api.util.updateQueryData(
                        'getProjectsSearch',
                        { fondsId: transaction.fondsId, search: '' },
                        (draft) => {
                            if (draft?.find((u) => u.id === transaction.from))
                                return draft;
                            return draft?.concat([
                                {
                                    id: transaction.from,
                                    name: transaction.projectNameFrom!,
                                },
                            ]);
                        }
                    )
                );
            } else if (transaction.type === TypeTransaction.cashProject) {
                dispatch(
                    api.util.updateQueryData(
                        'getProjectsSearch',
                        { fondsId: transaction.fondsId, search: '' },
                        (draft) => {
                            if (draft?.find((u) => u.id === transaction.to))
                                return draft;
                            return draft?.concat([
                                {
                                    id: transaction.to,
                                    name: transaction.projectNameTo!,
                                },
                            ]);
                        }
                    )
                );
            }
        }
    }, [transaction]);

    const [
        editTransaction,
        { isLoading: isLoadingEdit, isSuccess: isSuccessEdit },
    ] = useUpdateTransactionMutation();

    const onSubmit = async (
        data: UpdateOrCreateTransactionDtoForm,
        sponsorId?:
            | {
                  sponsorId: number | false;
              }
            | undefined
    ) => {
        const files = data?.files;
        delete data.files;

        if (
            data.type === TypeTransaction.newParadigmsCash ||
            data.type === TypeTransaction.platformCash
        ) {
            data = {
                ...data,
                from: 0,
            };
        } else if (data.type === TypeTransaction.cashNewParadigms) {
            data = {
                ...data,
                to: 0,
            };
        } else if (data.type === TypeTransaction.projectCash) {
            let type = TypeTransaction.projectCash;
            switch (data.typeTransactionProject2Cash) {
                case TypeTransactionProject2Cash.Capital:
                    type = TypeTransaction.buyingProjectCash;
                    break;
                case TypeTransactionProject2Cash.Closure:
                    type = TypeTransaction.projectStopCash;
                    break;
                default:
                    break;
            }
            data.type = type;
        }

        if (
            data.sponsored &&
            data.transactionUserIn &&
            (data.transactionUserIn?.sponsorId || sponsorId?.sponsorId)
        ) {
            data.transactionUserIn.sponsorId =
                data.transactionUserIn?.sponsorId ||
                (sponsorId?.sponsorId ? sponsorId?.sponsorId : undefined);
        }

        delete data.typeTransactionProject2Cash;
        delete data.sponsored;

        const dataToUpdate: UpdateTransactionDto = {
            type: data.type,
            from: data.from,
            to: data.to,
            date: transformDate(data.date)?.toISOString(),
            amount: data.amount,
            status: data.status,
            dateInterest: data.dateInterest
                ? transformDate(data.dateInterest)?.toISOString()
                : undefined,
            fondsId: data.fondsId,
            comment: data.comment,
            id: transactionId,
            transactionUserIn: undefined,
            transactionUserOut: undefined,
        };

        if (data.type === TypeTransaction.userCash) {
            dataToUpdate.transactionUserIn = {
                id: transaction?.transactionUserIn?.id!,
                entranceFee: data.transactionUserIn?.entranceFee || 0,
                managementFee: data.transactionUserIn?.managementFee || 0,
                donationFee: data.transactionUserIn?.donationFee || 0,
                typeShare: data.transactionUserIn?.typeShare || 'cumulative',
                initialShare: data.transactionUserIn?.initialShare || 100,
                actualShare: data.transactionUserIn?.actualShare,
                investAccountId: data.transactionUserIn?.investAccountId!,
                subscriptionUserFondsId:
                    data.transactionUserIn?.subscriptionUserFondsId,
                typeInvestisseurId: data.transactionUserIn?.typeInvestisseurId!,
                typeInvestisseur: data.transactionUserIn?.typeInvestisseur,
                sponsorId: data.transactionUserIn?.sponsorId,
                transactionId: data.transactionUserIn?.transactionId,
            };
        } else if (data.type === TypeTransaction.cashUser) {
            dataToUpdate.transactionUserOut = {
                id: transaction?.transactionUserOut?.id!,
                investAccountId: data.transactionUserOut?.investAccountId!,
                withdrawalId: data.transactionUserOut?.withdrawalId,
                emptyInvest: data.transactionUserOut?.emptyInvest,
                transactionUserOutItems:
                    data.transactionUserOut?.transactionUserOutItems || [],
            };
        }

        await editTransaction({
            updateTransactionDto: dataToUpdate,
            id: transactionId,
        })
            .unwrap()
            .then(async (res) => {
                try {
                    if (files) {
                        for (let i = 0; i < files.length; i++) {
                            const data: UploadDocumentTransactionDto = {
                                transactionId: (res as TransactionTableDto).id,
                                file: files[i],
                                fileName: files[i].name,
                                // type:(res.payload as TransactionTableDto)?.type === TypeTransaction.buyingProjectCash ? TypeDocumentTransaction. ,
                            };
                            await uploadDocumentTransaction(data)
                                .then((res) => {
                                    if (res.sucess) {
                                        showSuccess(
                                            'Created',
                                            'File uploaded successfully'
                                        );
                                    } else {
                                        showError(
                                            'Error',
                                            `Error uploading file ${data.fileName}: ${res.message}`
                                        );
                                    }
                                })
                                .catch((res) => {
                                    showError(
                                        'Error',
                                        `Error uploading file ${data.fileName}`
                                    );
                                });
                        }
                    }
                    showSuccess('Edited', 'Transaction edited successfully');
                    if (afterSubmitEditLogic) {
                        afterSubmitEditLogic();
                    }
                } catch (error) {
                    showError('Error', 'Error editing transaction');

                    return;
                }
            })
            .catch((err) => {
                showError('Error', 'Error editing transaction');
            });
    };

    const defaultValues = useMemo(() => {
        let typeProject = undefined;
        let type = transaction?.type;
        if (type === TypeTransaction.buyingProjectCash) {
            typeProject = TypeTransactionProject2Cash.Capital;
            type = TypeTransaction.projectCash;
        } else if (type === TypeTransaction.projectStopCash) {
            typeProject = TypeTransactionProject2Cash.Closure;
            type = TypeTransaction.projectCash;
        } else if (type === TypeTransaction.projectCash) {
            typeProject = TypeTransactionProject2Cash.Coupon;
        }
        return {
            ...transaction,

            sponsored:
                transaction &&
                transaction?.type === TypeTransaction.userCash &&
                transaction?.transactionUserIn?.sponsorId
                    ? true
                    : false,
            status: transaction?.status,
            type,
            date: transformDate(new Date(transaction?.date!).getTime())
                .toJSON()
                ?.split('T')[0],
            dateInterest: transaction?.dateInterest
                ? transformDate(new Date(transaction?.dateInterest!).getTime())
                      .toJSON()
                      ?.split('T')[0]
                : undefined,
            typeTransactionProject2Cash: typeProject,
        };
    }, [transaction]);

    return (
        <>
            <DeleteComponent title="cette transaction" />
            {isLoading ? (
                <div className="flex justify-center items-center h-[70vh]">
                    <Loading />
                </div>
            ) : (
                <TransactionForm
                    {...props}
                    onSubmit={onSubmit}
                    defaultValues={defaultValues}
                    container={(propsContainer) => (
                        <>
                            {props.container({
                                ...propsContainer,
                                transaction,
                                setOpenDelete,
                                openDelete,
                            })}
                        </>
                    )}
                    renderDocument={() => (
                        <div className="">
                            <h3 className="text-xl font-semibold mb-3">
                                Documents
                            </h3>
                            <div className="w-full sm:11/12 mx-auto gap-2 flex justify-center flex-col">
                                {loadingDocument ? (
                                    <Loading />
                                ) : (
                                    <>
                                        {documents && documents?.length! > 0 ? (
                                            documents.map((doc, index) => (
                                                <DisplayDocumentTransaction
                                                    document={doc}
                                                    key={index}
                                                />
                                            ))
                                        ) : (
                                            <p>Aucun document n'a été trouvé</p>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                />
            )}
        </>
    );
}

export default UpdateTransactionForm;
