export function displayReportRiskNotation(notation?: string | number): string {
  if (notation === undefined) {
    return "A";
  }
  if (typeof notation === "string" && !parseInt(notation)) {
    return notation;
  } else {
    const dictNotation: any = {
      1: "A",
      2: "A-",
      3: "B",
      4: "B-",
      5: "C",
      6: "C-",
      7: "D",
    };
    return dictNotation[notation] as string;
  }
}

export function getColorReportRiskNotation(notation?: string | number): string {
  const letter = displayReportRiskNotation(notation);
  const dictColor: any = {
    A: "#394A1F",
    "A-": "#12B669",
    B: "#A5C060",
    "B-": "#FFE285",
    C: "#FFD700",
    "C-": "#FF1A1A",
    D: "#000000",
  };
  return dictColor[letter] as string;
}
