import { debounce } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    getInterest,
    stringToFloat,
    stringToInt,
    transformDate,
} from '../../../../function/Utils';

import {
    CreateBlackListDto,
    ProjectStatus,
    useCreateBlacklistMutation,
    useGetProjectByIdQuery,
} from '@api/api';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import FieldArray from '../../../commun/formComponent/FieldArray';
import InputComponent from '../../../commun/formComponent/InputComponent';
import RemoveFieldArrayComponent from '../../../commun/formComponent/RemoveFieldArrayComponent';
import SelectComponent from '../../../commun/formComponent/SelectComponent';
import PopUp from '../../../commun/PopUp';
import { useNotificationContext } from '../../../Context/notification-context';

function AddProjectInBlacklist({
    projectId,
    open,
    setOpen,
}: {
    projectId: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const {
        data: project,
        isLoading,
        refetch,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        control,
        getValues,
    } = useForm<CreateBlackListDto>();

    const { showError, showSuccess } = useNotificationContext();

    const [addBlackList, { isLoading: isLoadingAdd }] =
        useCreateBlacklistMutation();

    const onSubmit = async (data: CreateBlackListDto) => {
        if (project) {
            await addBlackList({
                ...data,
                comment: data.comment ?? undefined,
                fondsId: project.fondsId,
                statusProject: data.statusProject,
                dates: data.dates.map((dateb) => {
                    return {
                        date: transformDate(dateb.date)?.toISOString(),
                        deletedAt: dateb.deletedAt
                            ? transformDate(dateb.deletedAt)?.toISOString()
                            : undefined,
                    };
                }),
                projectId: project.id,
                interests: data.interests.map((pi, index) => {
                    if (index === 0) {
                        return {
                            value: stringToFloat(pi.value),
                            fondsId: stringToFloat(project.fondsId),
                            // projectId: stringToInt(data.id),
                            date: transformDate(
                                data.dates?.[0].date
                            )?.toISOString(),
                        };
                    }
                    return {
                        value: stringToFloat(pi.value),
                        fondsId: stringToInt(project.fondsId),
                        // projectId: stringToInt(data.id),
                        date: transformDate(pi.date)?.toISOString(),
                    };
                }),
            })
                .unwrap()
                .then(async (res) => {
                    showSuccess(
                        'Created',
                        'Project added to the blacklist successfully'
                    );
                    await refetch();
                    setOpen(false);
                })
                .catch((err) => {
                    showError(
                        'Error',
                        'Error adding this project in blacklist'
                    );
                });
        }
    };

    const setDefautlValues = (): void => {
        reset({
            fondsId: project?.fondsId!,
            dates: [
                {
                    date: new Date(Date.now()).toJSON().split('T')[0] as any,
                    deletedAt: undefined,
                },
            ],
            statusProject: ProjectStatus.enRetardContractuel,
            interests: [
                {
                    date: new Date(Date.now()).toJSON().split('T')[0],
                    value: getInterest(
                        project!.interests,
                        new Date(Date.now())
                    ),
                    fondsId: project?.fondsId!,
                },
            ],
        });
        // setValue("ProjetId", array[0]);
    };

    useEffect(() => {
        if (project) {
            setDefautlValues();
        }
    }, [project]);

    const updateDateEnd = useCallback(
        debounce(() => {
            if (getValues(`dates.0.date`) != null) {
                setValue(
                    `interests`,
                    getValues(`interests`).map((val, index) => {
                        if (index === 0) {
                            return {
                                ...val,
                                date: transformDate(
                                    new Date(getValues(`dates.0.date`))
                                )
                                    .toJSON()
                                    .split('T')[0],
                            };
                        } else {
                            return val;
                        }
                    })
                );
            }
        }),
        []
    );

    useEffect(() => {
        updateDateEnd();
    }, [watch(`dates.0.date`)]);

    return (
        <>
            <PopUp
                open={open}
                setOpen={setOpen}
                title={() => {
                    return (
                        <h3 className="text-2xl font-medium leading-6 text-gray-900">
                            Mettre le projet en liste noire
                        </h3>
                    );
                }}
                buttonBoolean={false}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formTemplateSingUp"
                >
                    <FieldArray
                        control={control}
                        className="w-full"
                        name="dates"
                    >
                        {({ fields, append, remove }) => (
                            <>
                                <div className="flex flex-col w-full space-y-2 mb-2">
                                    {fields.map((field, index) => (
                                        <div
                                            key={field.id}
                                            className={`grid ${
                                                index > 0
                                                    ? 'grid-cols-[1fr,1fr,auto]'
                                                    : 'grid-cols-2'
                                            }  w-full gap-x-2 relative`}
                                        >
                                            <InputComponent
                                                register={register}
                                                type="date"
                                                value={`dates.${index}.date`}
                                                // className={{ input: "row-span-1 col-span-1" }}
                                            >
                                                <h3 className="text-left">
                                                    Date{' '}
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </h3>
                                            </InputComponent>

                                            <InputComponent
                                                register={register}
                                                type="date"
                                                required={false}
                                                value={`dates.${index}.deletedAt`}
                                                // className={{ input: "row-span-1 col-span-1" }}
                                            >
                                                <h3 className="text-left">
                                                    DeletedAt
                                                </h3>
                                            </InputComponent>
                                            {index > 0 ? (
                                                <RemoveFieldArrayComponent
                                                    remove={remove}
                                                    index={index}
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                                <PrimaryButton
                                    className=" mx-auto mb-2"
                                    onClick={() =>
                                        append({
                                            date: new Date()
                                                .toJSON()
                                                ?.split('T')[0],
                                            deletedAt: undefined,
                                            // projectId: project.current?.id!,
                                        } as any)
                                    }
                                >
                                    Ajouter
                                </PrimaryButton>
                            </>
                        )}
                    </FieldArray>

                    <SelectComponent
                        register={register}
                        value={'statusProject'}
                        container={true}
                        optionValues={Object.values(ProjectStatus)?.map(
                            (val) => {
                                return {
                                    value: val,
                                    label: val,
                                };
                            }
                        )}
                        control={control}
                    >
                        <h3>
                            Situation du projet{' '}
                            <span className="required">*</span>
                        </h3>
                    </SelectComponent>

                    <h3 className="text-center my-2 mx-auto">
                        List des intérêt en fonction de la date
                    </h3>
                    <FieldArray control={control} name="interests">
                        {({ fields, append, remove }) => (
                            <>
                                <div className="flex flex-col w-full space-y-2 mb-2">
                                    {fields.map((field, index) => (
                                        <div
                                            key={field.id}
                                            className={`grid ${
                                                index > 0
                                                    ? 'grid-cols-[1fr,1fr,auto]'
                                                    : 'grid-cols-2'
                                            }  w-full gap-x-2 relative`}
                                        >
                                            <InputComponent
                                                register={register}
                                                type="date"
                                                value={`interests.${index}.date`}
                                                cursorNotAllowed={
                                                    index === 0
                                                        ? true
                                                        : undefined
                                                }
                                                // className={{ input: "row-span-1 col-span-1" }}
                                            >
                                                <h3 className="text-left">
                                                    Date{' '}
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </h3>
                                            </InputComponent>

                                            <InputComponent
                                                register={register}
                                                type="number"
                                                value={`interests.${index}.value`}
                                                // className={{ input: "row-span-1 col-span-1" }}
                                            >
                                                <h3 className="text-left">
                                                    Intérêt{' '}
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </h3>
                                            </InputComponent>
                                            {index > 0 ? (
                                                <RemoveFieldArrayComponent
                                                    remove={remove}
                                                    index={index}
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                                <PrimaryButton
                                    className=" mx-auto mb-2"
                                    onClick={() =>
                                        append({
                                            date: new Date()
                                                .toJSON()
                                                ?.split('T')[0],
                                            value: 0,
                                            fondsId: watch('fondsId')!,
                                            // projectId: project.current?.id!,
                                        })
                                    }
                                >
                                    Ajouter
                                </PrimaryButton>
                            </>
                        )}
                    </FieldArray>

                    <InputComponent
                        register={register}
                        value={'comment'}
                        required={false}
                    >
                        <h3>Commentaire</h3>
                    </InputComponent>
                    <div className="mt-5 w-full sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 text-center">
                        <WhiteButton
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </WhiteButton>
                        <PrimaryButton type="submit" loading={isLoadingAdd}>
                            Add
                        </PrimaryButton>
                    </div>
                </form>
            </PopUp>
        </>
    );
}

export default AddProjectInBlacklist;
