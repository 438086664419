import { StateStatus } from '@api/api';
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline';
import React from 'react';

interface Props {
    status: StateStatus;
    className?: string;
}

const StatusDocument: React.FC<Props> = ({ status, className }) => {
    return (
        <div className={`${className} w-24 flex gap-2 items-center`}>
            {status === StateStatus.unchecked ? (
                <>
                    <ExclamationIcon
                        className="h-6 w-6 text-blue-400"
                        aria-hidden="true"
                    />
                    <p className="">
                        Non <br /> vérifié
                    </p>
                </>
            ) : status === StateStatus.onGoing ? (
                <>
                    <CheckCircleIcon
                        className="h-6 w-6 text-blue-400"
                        aria-hidden="true"
                    />
                    <p className="w-fit">En cours</p>
                </>
            ) : status === StateStatus.validated ? (
                <>
                    <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                    />
                    <p className="w-fit">Validé</p>
                </>
            ) : (
                <>
                    <ExclamationIcon
                        className="h-6 w-6 text-red-400"
                        aria-hidden="true"
                    />
                    <p className="w-fit">Invalide</p>
                </>
            )}
        </div>
    );
};

export default StatusDocument;
