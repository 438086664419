import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import HeadlessRadioComponent from '../../../commun/formComponent/HeadlessRadioComponent';
import FormInvestTemplate from './CommunComponent/FormInvestTemplate';

import {
    CreateDocumentUserDto,
    DocumentUserName,
    DocumentUserType,
    useFetchTokenQuery,
    useGetUserDocumentsQuery,
} from '@api/api';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import Loading from '../../../commun/Loading';
import { useNotificationContext } from '../../../Context/notification-context';

import useSidedDocument, {
    FormSidedDocument,
} from '../../../commun/CustomHook/useSidedDocument';
import { onSubmitDocumentIdentity } from '../../../commun/formComponent/OnSubmitHelpers';
import { EtapeForm } from '../../../Context/AppContext';

export interface FormCreateSidedDocumentUser extends CreateDocumentUserDto {
    sidedFiles: FormSidedDocument;
}

interface Props {}

const DocumentsIdentity1: React.FC<Props> = ({}) => {
    const etapeObject = useContext(EtapeForm);

    const next = etapeObject?.stepInsideStep0!;
    const setNext = etapeObject?.setStepInsideStep0!;

    const formState = useForm<FormCreateSidedDocumentUser>({
        defaultValues: {
            sidedFiles: {
                documentRectoSide: {
                    file: [],
                },
                documentVersoSide: {
                    file: [],
                },
                documentBothSide: {
                    file: [],
                },
                sideNotBothBool: false,
                type: DocumentUserType.IDENTITY_1,
            },
        },
    });

    const {
        register,
        watch,
        getValues,
        setValue,
        reset,
        handleSubmit,
        control,
        formState: { isValid },
    } = formState;

    const { data: userData } = useFetchTokenQuery();
    const {
        data: documents,
        isLoading: loadingUser,
        refetch,
    } = useGetUserDocumentsQuery(userData?.id ?? 0, {
        skip: !userData,
    });

    const { showError, showSuccess } = useNotificationContext();

    const [isLoadingUpload, setIsLoadingUpload] = React.useState(false);

    const {
        getFiles,
        isValidForm,
        SidedFileDocument,
        DisplaySidedDocumentValidation,
    } = useSidedDocument({
        formState,
        fileNameVariable: `sidedFiles`,
        documents: documents || [],
        isLoadingUpload: isLoadingUpload,
        type: DocumentUserType.IDENTITY_1,
        nameVariable: 'name',
    });

    const onSubmit = async (dataForm: FormCreateSidedDocumentUser) => {
        if (
            dataForm.sidedFiles.documentBothSide.file.length === 0 &&
            dataForm.sidedFiles.documentVersoSide.file.length === 0 &&
            dataForm.sidedFiles.documentRectoSide.file.length === 0
        ) {
            setNext(next + 1);
            return;
        }
        setIsLoadingUpload((curr) => !curr);

        let success = true;

        success = await onSubmitDocumentIdentity(
            dataForm,
            showError,
            showSuccess,
            userData?.id!
        );

        if (success) {
            setNext(next + 1);
        }
        setIsLoadingUpload((curr) => !curr);
    };

    useEffect(() => {
        refetch();
    }, []);

    const DocumentNameAlreadyUsed = documents?.find(
        (doc) => doc.type === DocumentUserType.IDENTITY_2
    )?.name;

    return (
        <FormInvestTemplate
            title="Pièces justificatives"
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            useSubmitBtn={false}
            isValid={isValid}
            loading={isLoadingUpload}
        >
            {loadingUser ? (
                <Loading />
            ) : (
                <>
                    <DisplaySidedDocumentValidation />
                    <div className="flex flex-col gap-3 justify-center items-center w-full h-full">
                        <div
                            className={`mx-auto w-11/12 md:w-2/3 flex justify-start items-start flex-col 
              
              `}
                            // ${
                            //   !watch("name") ? "mt-[-30%]" : ""
                            // }
                        >
                            <HeadlessRadioComponent
                                register={register}
                                name={'name'}
                                watch={watch}
                                className="mx-auto"
                                control={control}
                                values={[
                                    {
                                        label: "Carte Nationale d'Identité",
                                        value: DocumentUserName.IDENTITY_CARD,
                                        used:
                                            DocumentNameAlreadyUsed ===
                                            DocumentUserName.IDENTITY_CARD,
                                    },
                                    {
                                        label: 'Passeport',
                                        value: DocumentUserName.PASSPORT,
                                        used:
                                            DocumentNameAlreadyUsed ===
                                            DocumentUserName.PASSPORT,
                                    },
                                    {
                                        label: 'Carte de séjour',
                                        value: DocumentUserName.RESIDENT_PERMIT,
                                        used:
                                            DocumentNameAlreadyUsed ===
                                            DocumentUserName.RESIDENT_PERMIT,
                                    },
                                ]}
                            >
                                <h3 className="">
                                    Choisisser une pièce d'identité{' '}
                                    <span className="required">*</span>
                                </h3>
                            </HeadlessRadioComponent>
                        </div>
                        {!watch('name') ? (
                            <WhiteButton
                                className="mt-5"
                                onClick={() => setNext(next - 1)}
                            >
                                Précedent
                            </WhiteButton>
                        ) : null}
                    </div>

                    {watch('name') ? (
                        <>
                            <SidedFileDocument>
                                {TitleDocumentUser(watch('name'))} de{' '}
                                {userData?.firstName}
                                <span className="uppercase">
                                    {' '}
                                    {userData?.lastName}
                                </span>
                                <span className="required">*</span>{' '}
                            </SidedFileDocument>
                            <div className="flex items-center gap-2 justify-center mt-10 mb-10 ">
                                <WhiteButton onClick={() => setNext(next - 1)}>
                                    Précedent
                                </WhiteButton>
                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValidForm}
                                >
                                    {isLoadingUpload ? (
                                        <Loading size={4} />
                                    ) : (
                                        'Continuer'
                                    )}
                                </PrimaryButton>
                            </div>
                        </>
                    ) : null}
                </>
            )}
        </FormInvestTemplate>
    );
};

export default DocumentsIdentity1;

export const TitleDocumentUser = (name: DocumentUserName) => {
    switch (name) {
        case DocumentUserName.IDENTITY_CARD:
            return "Carte Nationale d'Identité";
        case DocumentUserName.PASSPORT:
            return 'Passeport';
        case DocumentUserName.DRIVING_LICENSE:
            return 'Permis de conduire';
        case DocumentUserName.RESIDENT_PERMIT:
            return 'Carte de séjour';
        case DocumentUserName.BILL:
            return 'Facture (éléctricté, eau, gaz, internet, téléphone fixe)';
        case DocumentUserName.BANK_STATEMENT:
            return 'Relevé bancaire';
        case DocumentUserName.RENT_RECEIPT:
            return 'Quittance de loyer';
        case DocumentUserName.HOME_INSURANCE:
            return "Attestation d'assurance habitation";
        case DocumentUserName.THIRD_PARTY_ATTESTATION:
            return "Attestation d'hébergement";
        case DocumentUserName.PROOF_OF_INCOME:
            return 'Justificatif de revenus';
        case DocumentUserName.OTHER:
            return 'Autre';
        default:
            return "Pièce d'identité";
    }
};
