import {
    FirstDateInFundsInterface,
    ShareDto,
    UserWithSharesDto,
} from '@api/api';
import {
    ComputationUserDashboard,
    IFondsInfoUser,
} from '@utils/ComputationUserDashboard';
import { isDateClosed } from '@utils/Utils';

export const getShareValueFunction = (share: ShareDto): number | null => {
    if (share) {
        return share.share;
    } else {
        return null;
    }
};

export interface IAggragateShareComninedInFunds extends IFondsInfoUser {
    firstName: string;
    lastName: string;
    userId: number;
}

export class ComputationAdmin {
    public compurationUser: ComputationForEachUser[];

    constructor(
        public userWithShares: UserWithSharesDto[],
        public firstDate: FirstDateInFundsInterface
    ) {
        this.userWithShares = userWithShares;
        this.compurationUser = this.userWithShares.map(
            (user) =>
                new ComputationForEachUser(
                    user.shares,
                    user.id,
                    {
                        userId: user.id,
                        lastName: user.lastName,
                        firstName: user.firstName,
                    },
                    [firstDate]
                )
        );
    }

    // -------------------- General methods -----------------
    public getLastShareComputed(date: Date): {
        lastDateComputed: Date;
        shareComputed: boolean;
    } {
        const sharesForSort = this.userWithShares
            ?.map((user) => user.shares)
            ?.flat();
        if (sharesForSort.length === 0) {
            return {
                lastDateComputed: new Date(2000, 0, 1, 2),
                shareComputed: false,
            };
        }
        const lastDateComputed = sharesForSort.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        )[0].date;

        if (
            sharesForSort.filter((val) =>
                isDateClosed(new Date(val.date), date)
            ).length > 0
        ) {
            return {
                lastDateComputed: new Date(lastDateComputed),
                shareComputed: true,
            };
        } else {
            return {
                lastDateComputed: new Date(lastDateComputed),
                shareComputed: false,
            };
        }
        return {
            lastDateComputed: new Date(2021, 0, 1, 2),
            shareComputed: false,
        };
    }
}

export interface IUserInfoForAdmin {
    userId: number;
    lastName: string;
    firstName: string;
}

export class ComputationForEachUser extends ComputationUserDashboard {
    constructor(
        public shares: ShareDto[],
        public userId: number,
        public userInfo: IUserInfoForAdmin,
        public firstDate: FirstDateInFundsInterface[]
    ) {
        super(shares, userId, firstDate);
        this.userInfo = userInfo;
    }

    public getAggragateShareComninedInFunds(
        date: Date,
        fondsId: number
    ): IAggragateShareComninedInFunds | undefined {
        if (this.isDateComputed(date)) {
            return {
                ...this.allInfoUserByFunds(date, fondsId)!,
                ...this.userInfo,
            };
        } else {
            return undefined;
        }
    }
}
