import {
    FeeConfigurationDto,
    FeeConfigurationValueEntranceType,
    FeeConfigurationValueNormalType,
    FeeConfigurationValueWithdrawalType,
    FeeWithFeeConfigurationDto,
    TypeInvestisseurDto,
    useGetAllFeeConfigurationsQuery,
    useGetAllFondsQuery,
    useGetAllTypeInvestisseursForFormQuery,
} from '@api/api';
import { PrimaryButton } from '@components/commun/Buttons';
import Loading from '@components/commun/Loading';
import TableComponent from '@components/DashboardAdmin/BackOffice/Components/TableComponent';
import UpdateFeeConfigurations from '@components/DashboardAdmin/Fees/UpdateFeeConfigurations';
import { useMemo, useState } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';

export default function FeeTables() {
    const { data: fees, isLoading } = useGetAllFeeConfigurationsQuery();

    const { data: typeInvestisseurs, isLoading: isLoadingTypeInvestisseur } =
        useGetAllTypeInvestisseursForFormQuery();

    return (
        <>
            {isLoading || isLoadingTypeInvestisseur ? (
                <Loading />
            ) : (
                <div className="flex gap-5 flex-col my-6">
                    {fees?.map((fee) => (
                        <FeeTable
                            typeInvestisseurs={typeInvestisseurs || []}
                            fee={fee}
                        />
                    ))}
                </div>
            )}
        </>
    );
}

function FeeTable({
    fee,
    typeInvestisseurs,
}: {
    fee: FeeWithFeeConfigurationDto;
    typeInvestisseurs: TypeInvestisseurDto[];
}) {
    const { data: fonds } = useGetAllFondsQuery();

    const [open, setOpen] = useState<boolean>(false);

    const [feeIdParam, setFeeIdParam] = useQueryParam('feeId', NumberParam);

    const head = useMemo(() => {
        if (!fonds) return [];
        if (fee.id === 2) {
            return ['Type investisseur', 'Fonds'];
        } else {
            return ['Type investisseur', ...(fonds.map((f) => f.name) || [])];
        }
    }, [fonds]);

    return (
        <div className="">
            {open ? <UpdateFeeConfigurations setOpen={setOpen} open /> : null}
            <div className="flex justify-between items-center">
                <h2 className="text-xl uppercase text-secondColor text-center w-full font-mainFontFamily font-bold">
                    {fee.name}
                </h2>
                <PrimaryButton
                    className="mr-4"
                    onClick={() => {
                        setFeeIdParam(fee.id);
                        setOpen(true);
                    }}
                >
                    Modifier
                </PrimaryButton>
            </div>
            <TableComponent head={head}>
                <tbody>
                    <>
                        {typeInvestisseurs.map((t, index) => (
                            <RowElement
                                feeId={fee.id}
                                fc={fee.feeConfigurations.filter(
                                    (fc) => fc.typeInvestisseurId === t.id
                                )}
                                number={index}
                                typeInvestisseurName={t.name}
                            />
                        ))}
                    </>
                </tbody>
            </TableComponent>
        </div>
    );
}

function RowElement({
    fc,
    number,
    feeId,
    typeInvestisseurName,
}: {
    fc: FeeConfigurationDto[];
    typeInvestisseurName: string;
    number: number;
    feeId: number;
}) {
    const classNameObejct = {
        item: 'text-sm  font-light px-6 py-4 whitespace-nowrap',
    };

    const { data: fonds } = useGetAllFondsQuery();

    const fondsDisplay = feeId === 2 ? fonds?.slice(0, 1) : fonds;

    return (
        <tr
            className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                number % 2 === 0 ? 'bg-secondBackgroundColor' : 'bg-white '
            } `}
            key={number}
        >
            <td className={`${classNameObejct.item}`}>
                {typeInvestisseurName}
            </td>
            {fondsDisplay?.map((f) => (
                <td className={`${classNameObejct.item}`}>
                    {fc.find((fc) => fc.fondsId === f.id) ? (
                        <DisplayFeeConfigurationValue
                            fc={
                                fc.find(
                                    (fc) => fc.fondsId === f.id
                                ) as FeeConfigurationDto
                            }
                        />
                    ) : (
                        'N/A'
                    )}
                </td>
            ))}
        </tr>
    );
}

interface DisplayFeeConfigurationValueProps {
    fc: FeeConfigurationDto;
}

export function DisplayFeeConfigurationValue({
    fc,
}: DisplayFeeConfigurationValueProps) {
    const { feeId, feeValue } = fc;

    if (feeId === 11) {
        // Handling the Withdrawal type (for feeId == 11)
        const withdrawalValue = feeValue as FeeConfigurationValueWithdrawalType;
        return (
            <>
                {withdrawalValue.values.map((item, index) => (
                    <span key={index}>
                        Seniority: {item.seniority}, Value: {item.value}
                        {index < withdrawalValue.values.length - 1 && (
                            <br />
                        )}{' '}
                        {/* Add line break between items */}
                    </span>
                ))}
            </>
        );
    } else if (feeId === 2) {
        // Handling the Entrance type (for feeId == 2)
        const entranceValue = feeValue as FeeConfigurationValueEntranceType;
        return <>{entranceValue.formula}</>;
    } else {
        // Handling the Normal type (for feeId != 11)
        const normalValue = feeValue as FeeConfigurationValueNormalType;
        return <>{normalValue.value}</>;
    }
}
