import React from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { useAppSelector } from "../../../redux/store";
// ignore the warning
// @ts-ignore
import palette from "google-palette";

export interface IReChartPieSeries {
  name: string;
  value: number;
}
interface Props {
  data: IReChartPieSeries[];
  k?: number;
  legend?: boolean;
  label?: boolean;
  uniform?: boolean;
  seize?: {
    md?: number;
    lg?: number;
    sm?: number;
  };
}
const PieReChart = ({
  data,
  k = 0,
  label = true,
  uniform = false,
  legend = true,
  seize = {
    md: 100,
    lg: 120,
    sm: 80,
  },
}: Props) => {
  const pageState = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );

  const COLORS = palette(["sequential"], data.length + 1, k).map(
    (col: any) => `#${col}`
  );

  const measure =
    (pageState.widthScreen > 1400
      ? seize.lg
      : pageState.widthScreen > 650
      ? seize.md
      : seize.sm) || 100;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 25; // Label will be 10px outside of the outerRadius
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={COLORS[(index + 1) % (COLORS.length + 1)]}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(2)} %`}
      </text>
    );
  };

  return (
    // <ResponsiveContainer width="100%" height="400px">
    <PieChart
      width={pageState.widthScreen < 650 ? 350 : 400}
      height={pageState.widthScreen < 1000 ? 400 : 450}
    >
      <Pie
        dataKey="value"
        isAnimationActive={false}
        data={data}
        cx={"50%"}
        cy={"50%"}
        outerRadius={measure}
        fill="#1b96ae"
        // label={label}
        // labelLine={false}
        label={label}
      >
        {!uniform ? (
          <>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index + 1}`}
                fill={COLORS[(index + 1) % (COLORS.length + 1)]}
              />
            ))}
          </>
        ) : null}
      </Pie>
      <Tooltip formatter={(value, name, props) => value + " %"} />

      {legend ? <Legend /> : null}
    </PieChart>
    // {/* </ResponsiveContainer> */}
  );
};

export default PieReChart;
