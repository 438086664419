import {
    FeeConfigurationValueType,
    FeeWithFeeConfigurationDto,
} from '@api/api';

export function getFeeConfiguration(
    feeConfigurations: FeeWithFeeConfigurationDto[],
    feeId: number,
    typeInvestisseurId: number,
    fondsId: number
): FeeConfigurationValueType {
    const res = (feeConfigurations || [])
        .find((f) => f.id === 3)
        ?.feeConfigurations.find(
            (fc) =>
                fc.fondsId === fondsId &&
                fc.typeInvestisseurId === typeInvestisseurId
        );

    return res?.feeValue as FeeConfigurationValueType;
}
