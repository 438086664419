import { ReactNode, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import {
  FieldValues,
  UseFormRegister,
  UseFormWatch,
  FieldErrorsImpl,
  Path,
  Control,
  useController,
} from "react-hook-form";
import { motion } from "framer-motion";

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>;
  watch: UseFormWatch<T>;
  errors?: FieldErrorsImpl<T>;
  name: Path<T>;
  values: {
    label: string;
    value: string | number;
    used?: boolean;
    title?: string;
  }[];
  control: Control<T, Path<T>>;
  cursorNotAllowed?: true;
  className?: string;
}

const HeadlessRadioComponent = <T extends object>(
  props: Props<T> & { children?: ReactNode }
) => {
  const {
    field: {
      value: property,
      onChange: propertyOnChange,
      ...restPropertyField
    },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <RadioGroup
      value={property}
      onChange={propertyOnChange}
      className={`flex flex-col gap-3 ${props.className}`}
    >
      {props.children}
      {props.values.map((value) => (
        <RadioGroup.Option
          key={value.value}
          value={value.value}
          disabled={value.used}
          className={({ active, checked }) =>
            `${
              active
                ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300"
                : ""
            }
        ${
          checked
            ? "bg-sky-900 bg-opacity-75 text-white"
            : "bg-secondBackgroundColor"
        }
          relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none 
         ${value.used ? " !bg-gray-400 !text-white !cursor-not-allowed" : ""}
          ${value.title ? "mt-[70px] md:mt-[35px]" : ""} `
          }
        >
          {({ active, checked }) => (
            <>
              <div className="flex w-full items-center justify-between relative">
                {value.title ? (
                  <h3 className="absolute text-gray-800 top-[-90px] md:top-[-65px] left-[-15px]">
                    {" "}
                    {value.title}
                  </h3>
                ) : null}
                {value?.used ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    // className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
                    className="absolute top-0 right-0 h-full flex items-center"
                  >
                    <div className="bg-white rounded-lg bg-opacity-90 text-gray-900 text-center p-2 ">
                      <p className="text-md ">Déjà utilisé</p>
                    </div>
                  </motion.div>
                ) : null}
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className={`font-medium  ${
                        checked
                          ? "text-white"
                          : value?.used
                          ? "opacity-50 text-white"
                          : "text-gray-900"
                      }`}
                    >
                      {value.label}
                    </RadioGroup.Label>
                    {/* <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? 'text-sky-100' : 'text-gray-500'
                            }`}
                          >
                            <span>
                              {plan.ram}/{plan.cpus}
                            </span>{' '}
                            <span aria-hidden="true">&middot;</span>{' '}
                            <span>{plan.disk}</span>
                          </RadioGroup.Description> */}
                  </div>
                </div>
                {checked && (
                  <div className="shrink-0 text-white">
                    <CheckIcon className="h-6 w-6" />
                  </div>
                )}
              </div>
            </>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export default HeadlessRadioComponent;

function CheckIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
