import {
    TransactionDto,
    TransactionStatus,
    TypeTransaction,
    useCreateTransactionSponsorMutation,
    useGetAllSponsorsQuery,
    useGetSponsorToBeRefundQuery,
} from '@api/api';
import {
    UploadDocumentTransactionDto,
    uploadDocumentTransaction,
} from '@api/features/transactionSlice';
import { CreateTransactionSponsorForm } from '@interfaces/transaction/transactions';
import { formatDate, transformDate } from '@utils/Utils';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useNotificationContext } from '../../Context/notification-context';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import InputComponent from '../../commun/formComponent/InputComponent';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import UploaderMulti from '../../commun/formComponent/UploaderMulti';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refectTable?: () => void;
}

const AddTransactionSponsor: React.FC<Props> = ({
    open,
    setOpen,
    refectTable,
}) => {
    const { showError, showSuccess } = useNotificationContext();

    const [loading, setLoading] = useState<boolean>(false);

    const [sponsorId, setSponsorId] = useQueryParam('sponsorId', NumberParam);
    const [userId, setUserId] = useQueryParam('userId', NumberParam);
    const [searchTo, setSearchTo] = useState<string>('');

    const { register, control, watch, setValue, handleSubmit, getValues } =
        useForm<CreateTransactionSponsorForm>({
            defaultValues: {
                status: TransactionStatus.VALIDATED,
                type: TypeTransaction.newParadigmsUser,
                amount: 0,
                from: 1,
                date: new Date(Date.now()).toJSON().split('T')[0] as any,
            },
        });

    const [addTransaction] = useCreateTransactionSponsorMutation();

    const onSubmit = async (data: CreateTransactionSponsorForm) => {
        setLoading((curr) => !curr);
        const files = data?.files;
        delete data.files;

        await addTransaction({
            ...data,
            date: transformDate(data.date)?.toISOString(),
        })
            .unwrap()
            .then(async (res) => {
                try {
                    if (files) {
                        for (let i = 0; i < files.length; i++) {
                            const data: UploadDocumentTransactionDto = {
                                transactionId: (res as TransactionDto).id,
                                file: files[i],
                                fileName: files[i].name,
                                // type:(res.payload as TransactionDto)?.type === TypeTransaction.buyingProjectCash ? TypeDocumentTransaction. ,
                            };
                            await uploadDocumentTransaction(data).then(
                                (res) => {
                                    if (res.sucess) {
                                        showSuccess('Created', 'File uploaded');
                                    } else {
                                        showError(
                                            'Error',
                                            `Error uploading file ${data.fileName}: ${res.message}`
                                        );
                                    }
                                }
                            );
                        }
                    }
                } catch (error) {
                    showError('Error', 'Error uploading file');
                    setLoading((curr) => !curr);
                    return;
                }
                showSuccess('Created', 'Transaction creating successfully');
                setLoading((curr) => !curr);
                refectTable && refectTable();
                setOpen(false);
            })
            .catch((err) => {
                showError('Error', 'Error creating transaction');
                setLoading((curr) => !curr);
            });
    };

    const { data: sponsors } = useGetSponsorToBeRefundQuery(watch('to'), {
        skip: !watch('to'),
    });

    const { data: sponsorsWithNbItems, isLoading } = useGetAllSponsorsQuery({
        limit: 50,
        offset: 0,
        sponsorName: searchTo || '',
        sponsoredName: '',
        dateStart: new Date(2000, 0, 1)?.toISOString(),
        dateEnd: new Date(2050, 0, 1)?.toISOString(),
        refunded: 0,
        used: 1,
    });

    const sponsorsOptions = sponsorsWithNbItems?.rows;

    useEffect(() => {
        if (watch('sponsorId') && sponsors) {
            const amount =
                sponsors.find((s) => s.id === watch('sponsorId'))?.amount || 0;
            setValue('amount', amount);
        }
    }, [watch('sponsorId'), sponsors]);

    useEffect(() => {
        if (userId) {
            setValue('to', userId);
        }
    }, [setValue, userId]);

    useEffect(() => {
        if (sponsorId && sponsors && userId) {
            setValue('sponsorId', sponsorId);
        }
    }, [sponsorId, sponsors]);

    const [expanded, setExpanded] = useState<boolean>(true);

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            expand={expanded}
            setExpand={setExpanded}
            submitItemName={'Ajouter'}
            onClickSubmit={() => {
                onSubmit(watch());
            }}
            title={() => {
                return (
                    <>
                        <h3 className="text-2xl font-semibold leading-6 text-gray-900">
                            Parain{' '}
                            <span className=" font-semibold">
                                {sponsors?.[0]?.sponsorName}
                            </span>
                        </h3>
                    </>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
                style={{ width: `${expanded ? '90%' : '80%'}` }}
            >
                <div
                    className={`w-full mx-auto ${
                        expanded
                            ? 'sm:flex sm:gap-2 sm:justify-between sm:items-center '
                            : ''
                    }`}
                >
                    <div className={`w-full ${expanded ? 'sm:w-10/12 ' : ''}`}>
                        <SelectComponent
                            register={register}
                            value={'to'}
                            container={true}
                            setSearch={setSearchTo}
                            optionValues={
                                sponsorsOptions?.map((sponsor, key) => {
                                    return {
                                        label: `${sponsor.sponsorName}`,
                                        value: sponsor.sponsorId,
                                    };
                                }) ?? []
                            }
                            watch={watch}
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                            useParamBoolean={true}
                        >
                            <h3 className="font-mainFontFamily">
                                Pour <span className="required">*</span>
                            </h3>
                        </SelectComponent>

                        <SelectComponent
                            register={register}
                            control={control}
                            watch={watch}
                            value={'sponsorId'}
                            optionValues={
                                sponsors?.map((s) => {
                                    return {
                                        label: `${s.sponsoredName} | montant: ${
                                            s?.amount || 0
                                        } | date: ${formatDate(new Date(s?.date))}`,
                                        value: s.id,
                                    };
                                }) || []
                            }
                        >
                            <h3 className=" font-mainFontFamily text-start">
                                Selectionnez la parrainage à rembourser
                            </h3>
                        </SelectComponent>

                        <InputComponent
                            register={register}
                            value={'amount'}
                            type={'number'}
                        >
                            <h3 className="font-mainFontFamily">
                                Montant <span className="required">*</span>
                            </h3>
                        </InputComponent>
                        <InputComponent
                            register={register}
                            value={'date'}
                            type={'date'}
                        >
                            <h3 className="font-mainFontFamily">
                                Date <span className="required">*</span>
                            </h3>
                        </InputComponent>
                        <SelectComponent
                            register={register}
                            value={'status'}
                            container={true}
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                            watch={watch}
                            optionValues={Object.values(TransactionStatus).map(
                                (v: string) => {
                                    return {
                                        value: v,
                                        label: v,
                                    };
                                }
                            )}
                        >
                            <h3 className="font-mainFontFamily">
                                Status <span className="required">*</span>
                            </h3>
                        </SelectComponent>
                    </div>
                    {expanded ? (
                        <div className="w-full">
                            <UploaderMulti
                                register={register}
                                value={'files'}
                                watch={watch}
                                setValue={setValue}
                                control={control}
                                size={10}
                                button={false}
                            >
                                <h3 className="text-2xl mb-5">
                                    Ajouter des documents
                                </h3>
                            </UploaderMulti>
                        </div>
                    ) : null}
                </div>
                <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
                    <WhiteButton
                        onClick={() => {
                            setOpen(false);
                        }}
                        className="w-full"
                    >
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit" className="w-full">
                        {loading !== undefined && loading === true ? (
                            <Loading size={4} />
                        ) : (
                            'Add'
                        )}
                    </PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
};

export default AddTransactionSponsor;
