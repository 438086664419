// export const fundingTypeData = [
//     {
//         value: "Financement corporate",
//         label: "Financement corporate",
//         fonds: ["Immobilier", "Agriculture durable", "Energie renouvelable", "Transition", "Microcrédit", 'Collectivité Territoriale']
//     }, {
//         value: "Financement de projet",
//         label: "Financement de projet",
//         fonds: ["Agriculture durable", "Energie renouvelable", "Transition", "Microcrédit", "Immobilier", "Collectivité Territoriale"]
//     }, {
//         value: "Autre",
//         label: "Autre",
//         fonds: ["Immobilier"]
//     },
// ]

export const Sectors = [
    "Multi-secteur", "Energie et environnement", "Transports et entreposage",
    "Construction", "Hébergement et restauration", "Activités immobilières", "Industrue agroalimentaire", "Commerce de détail, réparation et services personnels",
    "Commerce de gros", "Industrie agroalimentaire", "Industries extractives et manufacturières* (*hors alimentaire)",
    "Activités de services administratifs et de soutien", "Activités spécialisées, scientifiques et techniques",
    "Information et communication"
]


export const fundingTypeData = [
    {
        value: "Financement corporate",
        label: "Financement corporate",
    }, {
        value: "Financement de projet",
        label: "Financement de projet",
    }, {
        value: "Autre",
        label: "Autre",

    },
]