import ParentCompany from './ParentCompany';
import Shareholder from './Shareholder';
const InvestTools = () => {
    return (
        <div>
            <h1 className="w-full mt-2 text-mainColor text-center font-mainFontFamily text-3xl">
                Investment Tools
            </h1>
            <div className="w-full md:w-11/12 mx-auto">
                <ShareholderParentCompany />
            </div>
        </div>
    );
};

export default InvestTools;

function ShareholderParentCompany() {
    return (
        <div className="grid w-full lg:gap-x-2 place-items-start place-content-start lg:grid-cols-[1.3fr,2fr] overflow_projectManage">
            <Shareholder />
            <ParentCompany />
        </div>
    );
}
