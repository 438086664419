import React from "react";
import { IStats } from "./BoxStats";

interface Props {
  stats: {
    name: string | JSX.Element;
    value: string;
  }[];

  className?: string;
  children?: React.ReactNode;
  title?: string;
  subTitle?: string;
  text?: string;
  textStats?: React.ReactNode;
}

const DisplayStatsV1 = ({
  stats,
  className,
  children,
  title,
  subTitle,
  text,
}: Props) => {
  return (
    <div
      className={`relative bg-white grid grid-cols-1 lg:grid-cols-[1fr,1.5fr] ${
        className ? className : ""
      }`}
    >
      {/* <img
        className="h-56 w-full bg-gray-50 object-cover lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-1/2"
        src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80"
        alt=""
      /> */}
      {children}

      <div className=" w-full h-full flex mt-6 justify-center flex-col px-4 xxxl:mt-0 xxxl:w-10/12">
        {title ? (
          <h2 className="text-xl  font-semibold font-mainFontFamily leading-8 text-mainColor">
            {title}
          </h2>
        ) : null}

        {subTitle ? (
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {subTitle}
          </p>
        ) : null}
        {text ? (
          <p className="mt-6 text-lg text-justify leading-8 text-gray-600">
            {text}
          </p>
        ) : null}

        <DisplayStatsV1Metrics stats={stats} />
      </div>
    </div>
  );
};

export default DisplayStatsV1;

export function DisplayStatsV1Metrics({
  stats,
}: {
  stats: {
    name: string | JSX.Element;
    value: string;
  }[];
}) {
  return (
    <dl
      className={`mt-16 grid max-w-xl mx-auto lg:mx-0 grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-16 ${
        stats?.length > 4 ? "xxl:grid-cols-3" : ""
      }`}
    >
      {stats.map((stat, index) => (
        <div
          key={index}
          className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
        >
          <dt className="text-sm leading-6 text-gray-600">{stat.name}</dt>
          <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
            {stat.value}
          </dd>
        </div>
      ))}
    </dl>
  );
}
