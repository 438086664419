import {
    CreateDocumentUserDto,
    DocumentUserName,
    DocumentUserType,
    StateStatus,
    useFetchTokenQuery,
    useGetUserDocumentsQuery,
} from '@api/api';
import { PrimaryButton, WhiteButton } from '@components/commun/Buttons';
import useSidedDocument, {
    FormSidedDocument,
} from '@components/commun/CustomHook/useSidedDocument';
import { DisplayDocumentsFormInvestisor } from '@components/commun/DisplayDocuments/DisplayDocumentsFormInvestisor';
import DisplayDocumentValidation from '@components/commun/DisplayDocuments/DisplayDocumentValidation';
import HeadlessRadioComponent from '@components/commun/formComponent/HeadlessRadioComponent';
import { onSubmitDocumentAddress } from '@components/commun/formComponent/OnSubmitHelpers';
import UploaderSingle from '@components/commun/formComponent/UploaderSingle';
import Loading from '@components/commun/Loading';
import { EtapeForm } from '@components/Context/AppContext';
import { useNotificationContext } from '@components/Context/notification-context';
import FormInvestTemplate from '@components/DashboardUser/Profile/FormInvest/CommunComponent/FormInvestTemplate';
import { TitleDocumentUser } from '@components/DashboardUser/Profile/FormInvest/DocumentsIdentity1';

import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface Props {}

export interface FormCreateDocumentUserAddress extends CreateDocumentUserDto {
    values: {
        file: File[];
        type: DocumentUserType;
        name: DocumentUserName;
    }[];
    sidedFiles: FormSidedDocument;
    name: DocumentUserName;
}

const DocumentsAdress: React.FC<Props> = ({}) => {
    const etapeObject = useContext(EtapeForm);

    const next = etapeObject?.stepInsideStep0!;
    const setNext = etapeObject?.setStepInsideStep0!;
    const formState = useForm<FormCreateDocumentUserAddress>({
        defaultValues: {
            sidedFiles: {
                documentRectoSide: {
                    file: [],
                },
                documentVersoSide: {
                    file: [],
                },
                documentBothSide: {
                    file: [],
                },
                sideNotBothBool: false,
                type: DocumentUserType.ADDRESS_IDENTTITY,
            },
        },
    });

    const {
        register,
        watch,
        getValues,
        setValue,
        reset,
        handleSubmit,
        control,
        formState: { isValid },
    } = formState;

    const { data: userData } = useFetchTokenQuery();
    const {
        data: documents,
        isLoading: loadingUser,
        refetch,
    } = useGetUserDocumentsQuery(userData?.id ?? 0, {
        skip: !userData,
    });
    const { showError, showSuccess } = useNotificationContext();

    const [isLoadingUpload, setIsLoadingUpload] = React.useState(false);

    const {
        getFiles,
        isValidForm,
        SidedFileDocument,
        DisplaySidedDocumentValidation,
    } = useSidedDocument({
        formState,
        fileNameVariable: `sidedFiles`,
        documents: documents || [],
        isLoadingUpload: isLoadingUpload,
        type: DocumentUserType.ADDRESS_IDENTTITY,
        nameVariable: `sidedFiles.name`,
    });

    const etape = useContext(EtapeForm);

    const onSubmit = async (dataForm: FormCreateDocumentUserAddress) => {
        const lengthFiles = dataForm.values.length;
        setIsLoadingUpload((current) => !current);

        let succeed = true;

        if (
            dataForm.sidedFiles.documentBothSide.file.length === 0 &&
            dataForm.sidedFiles.documentVersoSide.file.length === 0 &&
            dataForm.sidedFiles.documentRectoSide.file.length === 0 &&
            dataForm.values.every((v) => v.file.length === 0)
        ) {
            etape?.setStep(etape?.step + 1);
            return;
        }

        succeed = await onSubmitDocumentAddress(
            dataForm,
            showError,
            showSuccess,
            userData?.id!
        );

        setIsLoadingUpload((current) => !current);
        if (succeed) {
            etape?.setStep(etape?.step + 1);
        }
    };

    const document_address = documents?.filter((doc) =>
        doc.type.includes(DocumentUserType.ADDRESS)
    );

    const doc_adress = documents?.find(
        (doc) => doc.type === DocumentUserType.ADDRESS
    );

    const document_adress_ThirdParty = documents?.find(
        (doc) => doc.type === DocumentUserType.ADDRESS_THIRD_PARTY
    );

    const document_adress_ThirdParty_Attestation = documents?.find(
        (doc) => doc.type === DocumentUserType.ADDRESS_THIRD_PARTY_ATTESTATION
    );

    useEffect(() => {
        if (document_address !== undefined) {
            if (document_address?.length === 1) {
                setValue('name', document_address[0].name);
            } else if (document_address?.length > 1) {
                setValue('name', DocumentUserName.THIRD_PARTY_ATTESTATION);

                if (document_adress_ThirdParty !== undefined) {
                    setValue(
                        `values.${0}.name`,
                        document_adress_ThirdParty?.name!
                    );
                }
                if (document_adress_ThirdParty_Attestation !== undefined) {
                    setValue(
                        `values.${1}.name`,
                        document_adress_ThirdParty_Attestation?.name!
                    );
                }
            }
        }
    }, [documents]);

    useEffect(() => {
        refetch();
    }, []);

    const [openAdress, setOpenAdress] = React.useState(false);
    const [openAdressThirdParty, setOpenAdressThirdParty] =
        React.useState(false);
    const [openAttestation, setOpenAttestation] = React.useState(false);

    return (
        <FormInvestTemplate
            title="Pièces justificatives"
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            useSubmitBtn={false}
            isValid={isValid}
        >
            {loadingUser ? (
                <Loading />
            ) : (
                <>
                    {openAdress && doc_adress ? (
                        <DisplayDocumentValidation
                            document={doc_adress}
                            setOpen={setOpenAdress}
                            open={openAdress}
                            index={0}
                        />
                    ) : null}

                    {openAdressThirdParty && document_adress_ThirdParty ? (
                        <DisplayDocumentValidation
                            document={document_adress_ThirdParty}
                            setOpen={setOpenAdressThirdParty}
                            open={openAdressThirdParty}
                            index={0}
                        />
                    ) : null}

                    <DisplaySidedDocumentValidation />

                    {openAttestation &&
                    document_adress_ThirdParty_Attestation ? (
                        <DisplayDocumentValidation
                            document={document_adress_ThirdParty_Attestation}
                            setOpen={setOpenAttestation}
                            open={openAttestation}
                            index={0}
                        />
                    ) : null}

                    <div className="flex flex-col gap-3 justify-center items-center w-full h-full">
                        <div
                            className={`mx-auto w-11/12 md:w-2/3 flex justify-start items-start flex-col ${
                                !watch('name') ? '' : ''
                            }`}
                        >
                            <HeadlessRadioComponent
                                register={register}
                                name={'name'}
                                watch={watch}
                                className="mx-auto"
                                control={control}
                                values={[
                                    {
                                        label: 'Facture (éléctricté, eau, gaz, internet, téléphone fixe)',
                                        value: DocumentUserName.BILL,
                                    },
                                    {
                                        label: 'Relevé bancaire',
                                        value: DocumentUserName.BANK_STATEMENT,
                                    },
                                    {
                                        label: 'Assurance habitation',
                                        value: DocumentUserName.HOME_INSURANCE,
                                    },
                                    {
                                        label: 'Quittance de loyer',
                                        value: DocumentUserName.RENT_RECEIPT,
                                    },
                                    {
                                        label: 'Hébergé par un tiers',
                                        value: DocumentUserName.THIRD_PARTY_ATTESTATION,
                                        title: 'Vous êtes hébergé par un tiers',
                                    },
                                ]}
                            >
                                <h3 className="">
                                    Choisissez un justificatif de domicile à
                                    votre nom{' '}
                                    <span className="required">*</span>
                                </h3>
                            </HeadlessRadioComponent>
                        </div>
                        {/* {!watch("name") ? (
              <WhiteButton className="mt-5" onClick={() => setNext(next - 1)}>
                Précedent
              </WhiteButton>
            ) : null} */}
                    </div>
                    {watch('name') === undefined ? null : watch('name') !==
                      DocumentUserName.THIRD_PARTY_ATTESTATION ? (
                        <>
                            <UploaderSingle
                                popup={true}
                                documentAlreadyUploaded={!!doc_adress}
                                register={register}
                                value={`values.${0}.file`}
                                watch={watch}
                                setValue={setValue}
                                loading={isLoadingUpload}
                                button={false}
                                componentEnd={() => {
                                    return (
                                        <p className="italic commentaireInput text-justify text-[0.8rem]">
                                            <span className="font-bold">
                                                Documents acceptés:
                                            </span>{' '}
                                            Documents datant de moins de 3 mois.
                                        </p>
                                    );
                                }}
                            >
                                <>
                                    <h3 className="text-2xl mb-5">
                                        {TitleDocumentUser(watch('name'))} de{' '}
                                        {userData?.firstName}
                                        <span className="uppercase">
                                            {' '}
                                            {userData?.lastName}
                                        </span>
                                        <span className="required">*</span>
                                    </h3>
                                    {doc_adress &&
                                    doc_adress?.status ===
                                        StateStatus.unValidated ? (
                                        <div className="flex flex-col mt-2 gap-2">
                                            <p className="text-center text-red-600">
                                                Veuillez renvoyer ce document
                                                et/ou modifier vos informations
                                                personnelles pour correspondre à
                                                ce document si nécessaire.
                                            </p>
                                            <PrimaryButton
                                                className="w-fit mx-auto mb-5"
                                                onClick={() =>
                                                    setOpenAdress(true)
                                                }
                                            >
                                                Visualiser à nouveau les
                                                éléments à modifier
                                            </PrimaryButton>
                                        </div>
                                    ) : null}
                                </>
                            </UploaderSingle>
                            {document_address && doc_adress !== undefined ? (
                                <>
                                    <DisplayDocumentsFormInvestisor
                                        document={doc_adress!}
                                    />
                                </>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <HeadlessRadioComponent
                                register={register}
                                name={`sidedFiles.name`}
                                watch={watch}
                                className="mx-auto mt-5"
                                control={control}
                                values={[
                                    {
                                        label: "Carte Nationale d'Identité",
                                        value: DocumentUserName.IDENTITY_CARD,
                                    },
                                    {
                                        label: 'Passeport',
                                        value: DocumentUserName.PASSPORT,
                                    },
                                    {
                                        label: 'Carte de séjour',
                                        value: DocumentUserName.RESIDENT_PERMIT,
                                    },
                                ]}
                            >
                                <h3 className="">
                                    Choisissez une pièce d'itentité de votre
                                    tiers
                                    <span className="required">*</span>
                                </h3>
                            </HeadlessRadioComponent>

                            {watch(`sidedFiles.name`) ? (
                                <SidedFileDocument>
                                    {TitleDocumentUser(
                                        watch(`sidedFiles.name`)
                                    )}{' '}
                                    de votre tiers
                                    <span className="required">*</span>
                                </SidedFileDocument>
                            ) : null}

                            <HeadlessRadioComponent
                                register={register}
                                name={`values.${0}.name`}
                                watch={watch}
                                className="mx-auto mt-5"
                                control={control}
                                values={[
                                    {
                                        label: 'Facture (éléctricté, eau, gaz, internet, téléphone fixe)',
                                        value: DocumentUserName.BILL,
                                    },
                                    {
                                        label: 'Relevé bancaire',
                                        value: DocumentUserName.BANK_STATEMENT,
                                    },
                                    {
                                        label: 'Assurance habitation',
                                        value: DocumentUserName.HOME_INSURANCE,
                                    },
                                    {
                                        label: 'Quittance de loyer',
                                        value: DocumentUserName.RENT_RECEIPT,
                                    },
                                ]}
                            >
                                <h3 className="">
                                    Choisissez un justificatif de domicile au
                                    nom de votre tiers
                                    <span className="required">*</span>
                                </h3>
                            </HeadlessRadioComponent>
                            {watch(`values.${0}.name`) ? (
                                <>
                                    <UploaderSingle
                                        popup={true}
                                        register={register}
                                        value={`values.${0}.file`}
                                        documentAlreadyUploaded={
                                            !!document_adress_ThirdParty
                                        }
                                        watch={watch}
                                        setValue={setValue}
                                        loading={isLoadingUpload}
                                        button={false}
                                        componentEnd={() => {
                                            return (
                                                <p className="italic commentaireInput text-justify text-[0.8rem]">
                                                    <span className="font-bold">
                                                        Documents acceptés:
                                                    </span>{' '}
                                                    Documents datant de moins de
                                                    3 mois.
                                                </p>
                                            );
                                        }}
                                    >
                                        <>
                                            <h3 className="text-2xl mb-5">
                                                {TitleDocumentUser(
                                                    watch(`values.${0}.name`)
                                                )}{' '}
                                                de votre tiers
                                            </h3>
                                            {document_adress_ThirdParty &&
                                            document_adress_ThirdParty?.status ===
                                                StateStatus.unValidated ? (
                                                <div className="flex flex-col mt-2 gap-2">
                                                    <p className="text-center text-red-600">
                                                        Veuillez renvoyer ce
                                                        document et/ou modifier
                                                        vos informations
                                                        personnelles pour
                                                        correspondre à ce
                                                        document si nécessaire.
                                                    </p>
                                                    <PrimaryButton
                                                        className="w-fit mx-auto mb-5"
                                                        onClick={() =>
                                                            setOpenAdressThirdParty(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Visualiser à nouveau les
                                                        éléments à modifier
                                                    </PrimaryButton>
                                                </div>
                                            ) : null}
                                        </>
                                    </UploaderSingle>
                                    {document_address &&
                                    document_adress_ThirdParty !== undefined ? (
                                        <>
                                            <DisplayDocumentsFormInvestisor
                                                document={
                                                    document_adress_ThirdParty!
                                                }
                                            />
                                        </>
                                    ) : null}
                                </>
                            ) : null}

                            <UploaderSingle
                                register={register}
                                popup={true}
                                value={`values.${1}.file`}
                                watch={watch}
                                setValue={setValue}
                                loading={isLoadingUpload}
                                documentAlreadyUploaded={
                                    !!document_adress_ThirdParty_Attestation
                                }
                                button={false}
                                componentEnd={() => {
                                    return (
                                        <p className="italic commentaireInput text-justify text-[0.8rem]">
                                            <span className="font-bold">
                                                Documents acceptés:
                                            </span>{' '}
                                            Documents datant de moins de 3 mois.
                                        </p>
                                    );
                                }}
                            >
                                <>
                                    <h3 className="text-2xl mb-5">
                                        Attestation signée par votre tiers
                                        attestant que vous vivez à son domicile.
                                    </h3>
                                    {document_adress_ThirdParty_Attestation &&
                                    document_adress_ThirdParty_Attestation?.status ===
                                        StateStatus.unValidated ? (
                                        <div className="flex flex-col mt-2 gap-2">
                                            <p className="text-center text-red-600">
                                                Veuillez renvoyer ce document
                                                et/ou modifier vos informations
                                                personnelles pour correspondre à
                                                ce document si nécessaire.
                                            </p>
                                            <PrimaryButton
                                                className="w-fit mx-auto mb-5"
                                                onClick={() =>
                                                    setOpenAttestation(true)
                                                }
                                            >
                                                Visualiser à nouveau les
                                                éléments à modifier
                                            </PrimaryButton>
                                        </div>
                                    ) : null}
                                </>
                            </UploaderSingle>
                            {document_address &&
                            document_adress_ThirdParty_Attestation !==
                                undefined ? (
                                <>
                                    <DisplayDocumentsFormInvestisor
                                        document={
                                            document_adress_ThirdParty_Attestation!
                                        }
                                    />
                                </>
                            ) : null}
                        </>
                    )}

                    <div className="flex items-center gap-2 justify-center mt-10 mb-10 ">
                        <WhiteButton onClick={() => setNext(next - 1)}>
                            Précedent
                        </WhiteButton>
                        <PrimaryButton
                            type="submit"
                            disabled={
                                (watch('name') !==
                                    DocumentUserName.THIRD_PARTY_ATTESTATION &&
                                    ((watch(`values.0.file`) as any[])?.length >
                                        0 ||
                                        document_address?.find(
                                            (d) =>
                                                d.type ===
                                                DocumentUserType.ADDRESS
                                        ))) ||
                                (watch('name') ===
                                    DocumentUserName.THIRD_PARTY_ATTESTATION &&
                                    ((watch(`values.0.file`) as any[])?.length >
                                        0 ||
                                        document_address?.find(
                                            (d) =>
                                                d.type ===
                                                DocumentUserType.ADDRESS_THIRD_PARTY
                                        )) &&
                                    ((watch(`values.1.file`) as any[])?.length >
                                        0 ||
                                        document_address?.find(
                                            (d) =>
                                                d.type ===
                                                DocumentUserType.ADDRESS_THIRD_PARTY_ATTESTATION
                                        )) &&
                                    isValidForm)
                                    ? false
                                    : true
                            }
                        >
                            {isLoadingUpload ? (
                                <Loading size={4} />
                            ) : (
                                'Continuer'
                            )}
                        </PrimaryButton>
                    </div>
                </>
            )}
        </FormInvestTemplate>
    );
};

export default DocumentsAdress;
