import { useFetchTokenQuery } from '@api/api';
import { useContext } from 'react';
import { StringParam } from 'use-query-params';
import { useQueryParamCustom } from '../../commun/CustomHook/useQueryParamCustom';
import HorizontalMenu from '../../commun/HorizontalMenu';
import { MenuDashBoardUserBoolState } from '../../Context/AppContext';
import { IMenu } from '../../DashboardAdmin/UserManage/UserManage';
import WrapComponentDashboardUser from '../commun/WrapComponentDashboardUser';
import AssetSimulator from './AssetSimulator';

const IndexSimulation = () => {
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    const menuList: IMenu[] = [
        {
            queryPage: 'investissement',
            label: 'Investissement',
        },
    ];

    const [pageQuery, setPageQuery] = useQueryParamCustom(
        'pageSimulation',
        StringParam,
        'investissement'
    );

    const selectPage = () => {
        switch (pageQuery) {
            case 'investissement':
                return <Investment />;
            default:
                return <Investment />;
                break;
        }
    };

    return (
        <div
            className={`mx-auto  md:mt-0 lg:px-2 md:pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto `}
        >
            <WrapComponentDashboardUser
                title="Simulation"
                description=""
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
            >
                <div className="md:h-fit ml-2">
                    <HorizontalMenu
                        queryPage="pageSimulation"
                        items={menuList}
                    />
                </div>
                <div className={`p-3 `}>
                    <>{selectPage()}</>
                </div>
            </WrapComponentDashboardUser>
        </div>
    );
};

export default IndexSimulation;

function Investment({}: {}) {
    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

    return <AssetSimulator userId={user?.id || 0} />;
}
