import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";

interface Props {
  children?: React.ReactNode;
  value: string | undefined | null;
  text: string;
  sigle?: string;
  icon: IconProp;
  comment?: string;
}

const Box: React.FC<Props> = (props) => {
  const styleDiv: CSSProperties = {
    gridTemplateAreas: "'c1l1 c2' 'c1l2 c2'",
    gridTemplateColumns: "3fr 1fr",
  };

  const styleAreaC1l1: CSSProperties = {
    gridArea: "c1l1",
  };
  const styleAreaC1l2: CSSProperties = {
    gridArea: "c1l2",
  };
  const styleAreaC2: CSSProperties = {
    gridArea: "c2",
  };
  return (
    <div
      style={styleDiv}
      className="cursor-pointer p-5 relative grid grid-rows-2 bg-thirdColor text-white rounded-md h-[150px] w-[230px] gap-5 hover:scale-[1.02] transition-all"
    >
      <p
        style={styleAreaC1l1}
        className="font-titleFontFamily text-2xl self-end text-center italic font-bold"
      >
        {props.value} <span>{props.sigle}</span>
      </p>
      <div style={styleAreaC2} className="flex items-center justify-cente">
        <FontAwesomeIcon
          icon={props.icon}
          className="text-secondColor"
          size="2x"
        />
      </div>

      <p style={styleAreaC1l2} className="p-0 font-thirdFontFamily text-center">
        {props.text}
      </p>
      {props.comment && (
        <div className="flex items-center justify-center absolute bottom-1 left-3">
          <FontAwesomeIcon
            icon="info-circle"
            className="text-secondColor"
            size="2x"
          />
          <p className="p-0 font-thirdFontFamily text-center italic text-xs">
            {props.comment}
          </p>
        </div>
      )}
    </div>
  );
};

export default Box;
