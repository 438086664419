import { pageHeader } from '@api/features/page';
import React from 'react';
import Avertissementcomponent from '../components/commun/Avertissementcomponent';
import ContactSection from '../components/commun/ContactSection';
import FirstFondPage from '../components/commun/FirstFondPage';
import Footer from '../components/commun/Footer';
import Navigation from '../components/commun/Navigation';
import Avantages from '../components/home/Avantages';
import FondPresentation from '../components/home/FondPresentation';
import Qualite from '../components/home/Qualite';
import TexteIntroduction from '../components/home/TexteIntroduction';
import coverBackground from './../assets/thomas-vimare-Medium-home.jpg';

const Home = () => {
    const homeList: pageHeader = {
        coverBackground: coverBackground,
        coverImg: './img/thomas-vimare-small-home.jpg',
        classN: 'Home_Page',
        h1_: 'New Paradigms',
        h2_: 'Investir autrement',
    };

    return (
        <React.Fragment>
            {/* {Cookies.get("accepterCookies") === undefined && (
        <CookiesComponent setCookieConsent={setCookieConsent} />
      )} */}
            <Navigation bol_nosfonds={false} bol_transparent={true} />
            <FirstFondPage pageHeader={homeList} />
            <TexteIntroduction />
            <Qualite />
            <Avantages />
            <FondPresentation />
            <Avertissementcomponent />
            {/* <Faq /> */}
            <ContactSection />
            <Footer />
        </React.Fragment>
    );
};

export default Home;
