import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "@material-ui/core";
import React, { useCallback, useState } from "react";

interface Props {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const SearchComponent: React.FC<Props> = ({ search, setSearch }) => {
  const [searchValue, setSearchValue] = useState(search);

  const updateSearch = useCallback(
    debounce((value: string) => {
      setSearch(value);
    }, 500),
    []
  );

  return (
    <div className="w-full max-w-lg lg:max-w-xs">
      <label htmlFor="search" className="sr-only">
        Rechercher
      </label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          id="search"
          name="search"
          className="block w-full rounded-md border-0 bg-gray-200 py-1.5 pl-10 pr-3 text-gray-300 placeholder:text-gray-400 focus:bg-white focus:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Rechercher"
          type="search"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            updateSearch(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default SearchComponent;
