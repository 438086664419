import { ExclamationIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { formatDate } from '../../../function/Utils';

import {
    InvestAccountDto,
    StateStatus,
    useCreateSponsorCodeMutation,
    useGetRecapSponsorshipUserQuery,
    useGetUserWithAllInfoQuery,
    UserWithAllInfoDto,
} from '@api/api';
import { useNotificationContext } from '../../Context/notification-context';
import { DisplayMoralAccountCard } from '../../DashboardUser/Profile/DisplayMoralAccountCard';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import CopyButton from '../../commun/CopyButton';
import { DropDownComponentAdmin } from '../../commun/DropDownComponent';
import { ErrorIcon, SuccessIcon } from '../../commun/IconsFeedBack';
import Loading from '../../commun/Loading';
import Spinner from '../../commun/Spinner';
import { Questionnaires } from './Questionnaires';
import ValidationInvestAccount from './ValidationInvestAccount';

export function Identite({ userId }: { userId: number }) {
    const [pageIdentity, setPageIdentity] = useQueryParam(
        'pageIdentity',
        StringParam
    );

    const selectPage = () => {
        if (pageIdentity === 'validation-invest-account') {
            return <ValidationInvestAccount userId={userId} />;
        } else {
            return <Index userId={userId} />;
        }
    };

    return <>{selectPage()}</>;
}

function Index({ userId }: { userId: number }) {
    const { data: user, isLoading: loading } =
        useGetUserWithAllInfoQuery(userId);

    const [personal, setPersonal] = useState(false);
    const [investAccount, setInvestAccount] = useState(true);
    const [sponsor, setSponsor] = useState(false);
    const [question, setQuestion] = useState(false);

    return (
        <>
            {!loading && user ? (
                <>
                    <DropDownComponentAdmin
                        bool={personal}
                        setBool={setPersonal}
                        title={'Identité'}
                    >
                        <PersonalData user={user} />
                    </DropDownComponentAdmin>

                    <DropDownComponentAdmin
                        bool={sponsor}
                        setBool={setSponsor}
                        title={'Parrainage'}
                    >
                        <SponsorManage user={user} />
                    </DropDownComponentAdmin>
                    <DropDownComponentAdmin
                        bool={question}
                        setBool={setQuestion}
                        title={'Questionnaire'}
                    >
                        <Questionnaires userId={user.id} />
                    </DropDownComponentAdmin>

                    <DropDownComponentAdmin
                        bool={investAccount}
                        setBool={setInvestAccount}
                        title={"Comptes d'investissement"}
                    >
                        <InvestAccount user={user} />
                    </DropDownComponentAdmin>
                </>
            ) : (
                <Loading />
            )}
        </>
    );
}

function PersonalData({ user }: { user: UserWithAllInfoDto }) {
    return (
        <div className="relative mx-auto overflow-auto w-full md:w-[97%]">
            <div className="overflow-auto">
                <div className="mt-2 relative">
                    {/* <div className="absolute right-5 top-2">
                  {user.confirmed ? (
                    <SuccessButton>Compte Confirmer</SuccessButton>
                  ) : (
                    <ErrorButton>Compte Non confirmé</ErrorButton>
                  )}
                </div> */}
                    <dl className="grid grid-cols-1 sm:grid-cols-2">
                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Full name
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {user.firstName} {user.lastName}
                            </dd>
                        </div>
                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Sex
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {user.sex}
                            </dd>
                        </div>
                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900 flex gap-1">
                                Email address{' '}
                                {user.confirmed ? (
                                    <div className="flex gap-1 text-green-500">
                                        <SuccessIcon />
                                        Validé
                                    </div>
                                ) : (
                                    <div className="flex gap-1 text-red-500">
                                        <ErrorIcon />
                                        Non validé
                                    </div>
                                )}
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {user.email}
                            </dd>
                        </div>

                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Nationality
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {user.userInfo?.nationality}
                            </dd>
                        </div>
                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Adress
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {user.userInfo?.adress}
                            </dd>
                        </div>
                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Date of birth
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {formatDate(new Date(user.userInfo?.birthDay!))}
                            </dd>
                        </div>
                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Birth Place
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {user.userInfo?.birthPlace}
                            </dd>
                        </div>
                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Sponsor
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                Cette utilisateur a parrainé{' '}
                                <span className="font-semibold">
                                    {user?.sponsor?.length ?? 0}
                                </span>{' '}
                                utilisateurs
                            </dd>
                        </div>
                        <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Status
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {user.status}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    );
}

function SponsorManage({ user }: { user: UserWithAllInfoDto }) {
    const { showError, showSuccess } = useNotificationContext();
    const [createSponsorCode, { isLoading: loadingSponsorCode }] =
        useCreateSponsorCodeMutation();

    const { data: recapSponsorShip, isLoading: isLoadingRecap } =
        useGetRecapSponsorshipUserQuery(user?.id!, {
            skip: !user?.id,
        });

    return (
        <div className="">
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-semibold text-gray-500">
                    Code de parrainage
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                    {user?.sponsorCode ? (
                        !loadingSponsorCode ? (
                            <>
                                <p className="text-center uppercase tracking-wide bg-secondBackgroundColor rounded-sm p-2">
                                    {user?.sponsorCode}
                                </p>

                                <CopyButton text={user?.sponsorCode} />
                                {!user?.sponsor || user.sponsor.length === 0 ? (
                                    <p className="text-green-500 italic">
                                        Pas encore utilisé
                                    </p>
                                ) : (
                                    <p className="text-green-500 italic">
                                        Utilisé {user.sponsor.length} fois
                                    </p>
                                )}
                            </>
                        ) : (
                            <Spinner size={5} />
                        )
                    ) : (
                        <p
                            className="cursor-pointer rounded-md bg-mainColor text-white transition-all p-2 hover:scale-[1.02]"
                            onClick={async () =>
                                user &&
                                (await createSponsorCode(user.id)
                                    .unwrap()
                                    .then(() => {
                                        showSuccess(
                                            'Créer',
                                            'Code de parrainage créé avec succès'
                                        );
                                    })
                                    .catch(() => {
                                        showSuccess(
                                            'Erreur',
                                            'Erreur lors de la création du code de parainage'
                                        );
                                    }))
                            }
                        >
                            Générez un code de parrainage
                        </p>
                    )}
                </dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-semibold text-gray-500">
                    Lien d'invitation
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2">
                    {user?.sponsorCode ? (
                        <>
                            <p className="py-2 px-1 bg-secondBackgroundColor rounded-md text-center w-fit max-w-[260px] break-words whitespace-normal">
                                {window.location.origin}
                                /SeConnecter?register=1&code=
                                {user?.sponsorCode}
                            </p>
                            <CopyButton
                                text={`${window.location.origin}/SeConnecter?register=1&code=${user?.sponsorCode}`}
                            />
                        </>
                    ) : (
                        <p>
                            Vous devez d'abord générer un code de parrainage
                            pour pouvoir partager un lien d'invitation
                        </p>
                    )}
                </dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-semibold text-gray-500">
                    Commission récupérée
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {isLoadingRecap ? (
                        <Loading />
                    ) : (
                        <>{recapSponsorShip?.moneyReceived.toFixed(2)} €</>
                    )}
                </dd>
            </div>
            <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-semibold text-gray-500">
                    Commission disponible
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {isLoadingRecap ? (
                        <Loading />
                    ) : (
                        <>
                            {(
                                recapSponsorShip?.totalMoneyWon! -
                                recapSponsorShip?.moneyReceived!
                            ).toFixed(2)}{' '}
                            €
                        </>
                    )}
                </dd>
            </div>
        </div>
    );
}

function InvestAccount({ user }: { user: UserWithAllInfoDto }) {
    const [viewCard, setViewCard] = useState(false);

    const [investAccountIdQuery, setInvestAccountIdQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );
    return (
        <div className="mt-3">
            <div>
                {viewCard && investAccountIdQuery ? (
                    <DisplayMoralAccountCard
                        userId={user.id}
                        open={viewCard}
                        setOpen={setViewCard}
                        forAdminView={true}
                    />
                ) : null}
                <div className="">
                    {user?.investAccounts?.length === 0 ? (
                        <div className="flex flex-col items-center justify-center gap-2 my-2">
                            <p className="text-center">
                                Aucun compte d'investissement
                            </p>
                        </div>
                    ) : null}

                    <div className="mx-auto grid md:grid-cols-2 lg:grid-cols-3 gap-2">
                        {user?.investAccounts?.map((i, index) => (
                            <div
                                key={index}
                                className="w-full relative flex flex-col justify-start bg-secondBackgroundColor rounded-lg py-2 px-4"
                            >
                                <DisplayInvestAccount
                                    setViewCard={setViewCard}
                                    viewCard={viewCard}
                                    i={i}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function DisplayInvestAccount({
    i,
    viewCard,
    setViewCard,
}: {
    i: InvestAccountDto;
    viewCard: boolean;
    setViewCard: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [investAccountIdQuery, setInvestAccountIdQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );

    const [hover, setHover] = useState(false);

    const navigate = useNavigate();

    return (
        <>
            <h2 className=" font-mainFontFamily text-xl text-mainColor font-semibold">
                {i.physicalOrMoral === 'physical'
                    ? 'Compte physique'
                    : 'Compte morale'}
            </h2>
            {i.physicalOrMoral === 'moral' ? (
                <p className=" font-medium font-mainFontFamily">
                    {i.moralAccount?.companyName}
                </p>
            ) : null}
            <motion.div
                className="absolute right-2 top-2 cursor-pointer"
                onHoverStart={() => setHover(true)}
                onHoverEnd={() => setHover(false)}
            >
                {/* When hover on the icon display a text */}
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                        }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.2 }}
                        className="rounded-[50px] py-1 px-3 absolute z-[10]"
                    >
                        <div
                            className={`flex gap-3 items-center left-[-100px] absolute px-3  w-fit ${
                                i.status === StateStatus.validated
                                    ? ' top-[-40px]'
                                    : i.status === StateStatus.unchecked
                                      ? 'top-[-65px]'
                                      : ' top-[-70px]'
                            }`}
                        >
                            {hover ? (
                                <>
                                    {!i.iban ? (
                                        <p className="text-base w-[200px] bg-bgDashboardClient p-2 rounded-md text-gray-600 text-center">
                                            Ce compte n'est pas complétement
                                            complété
                                        </p>
                                    ) : i.status === StateStatus.validated ? (
                                        <p className="text-base w-[200px] bg-bgDashboardClient p-2 rounded-md text-gray-600 text-center">
                                            Compte validé
                                        </p>
                                    ) : i.status === StateStatus.unchecked &&
                                      i.iban !== undefined ? (
                                        <p className="text-base w-[200px] bg-bgDashboardClient p-2 rounded-md text-gray-600 text-center">
                                            Ce compte est en cours de validation
                                        </p>
                                    ) : (
                                        <p className="text-base w-[200px] bg-bgDashboardClient p-2 rounded-md text-gray-600 text-center">
                                            Ce compte n'a pas été validé,
                                            veuillez le modifier
                                        </p>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </motion.div>
                </AnimatePresence>

                {i.status === StateStatus.validated ? (
                    <SuccessIcon />
                ) : i.status === StateStatus.unchecked ? (
                    <ExclamationIcon
                        className="h-6 w-6 text-orange-400"
                        aria-hidden="true"
                    />
                ) : (
                    <ErrorIcon />
                )}
            </motion.div>
            <p>
                <span className=" font-semibold uppercase">Iban:</span>{' '}
                {i?.iban}
            </p>
            {i.physicalOrMoral === 'moral' ? (
                <div className=" w-full justify-center gap-3 items-center flex mt-2 mb-0">
                    <WhiteButton
                        onClick={() => {
                            setInvestAccountIdQuery(i.id);
                            setViewCard(true);
                        }}
                    >
                        Détails
                    </WhiteButton>
                    <PrimaryButton
                        onClick={() => {
                            navigate(
                                `/DashBoardAdmin/Utilisateurs/user/${i.userId}/?pageUser=general&pageIdentity=validation-invest-account&investAccountId=${i.id}`
                            );
                        }}
                    >
                        Vérifier
                    </PrimaryButton>
                </div>
            ) : (
                <div className=" w-full h-full justify-center gap-3 items-end flex mt-2 mb-0">
                    <PrimaryButton
                        onClick={() => {
                            navigate(
                                `/DashBoardAdmin/Utilisateurs/user/${i.userId}/?pageUser=general&pageIdentity=validation-invest-account&investAccountId=${i.id}`
                            );
                        }}
                    >
                        Vérifier
                    </PrimaryButton>
                </div>
            )}
        </>
    );
}
