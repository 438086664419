/* This example requires Tailwind CSS v2.0+ */
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Fragment, useEffect } from 'react';
import { formatDate } from '../../function/Utils';
import {
    INotification,
    Level,
    useNotificationContext,
} from '../Context/notification-context';
import Spinner from './Spinner';

export default function Notification({
    title,
    message,
    level,
    id,
    computation,
    progress,
    date,
    fondsName,
    errorMessage,
}: INotification) {
    const { dispatch } = useNotificationContext();

    const SECONDS_MS = 1000;

    useEffect(() => {
        if (!computation) {
            setTimeout(() => {
                dispatch({ type: 'DELETE', id });
            }, 10 * SECONDS_MS);
        }
    }, []);

    return (
        <>
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
                show={true}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    key={id}
                    className="max-w-sm relative w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                >
                    <div className="p-4">
                        <div className="flex items-center justify-center gap-3">
                            <div className="flex-shrink-0">
                                {level === Level.ERROR ? (
                                    <ExclamationIcon
                                        className="h-6 w-6 text-red-400"
                                        aria-hidden="true"
                                    />
                                ) : level === Level.WARNING ? (
                                    <ExclamationIcon
                                        className="h-6 w-6 text-orange-400"
                                        aria-hidden="true"
                                    />
                                ) : level === Level.SUCCESS ? (
                                    <CheckCircleIcon
                                        className="h-6 w-6 text-green-400"
                                        aria-hidden="true"
                                    />
                                ) : level === Level.INFO ? (
                                    <ExclamationIcon
                                        className="h-6 w-6 text-blue-400"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <Spinner size={5} />
                                )}
                            </div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                {fondsName ? (
                                    <p className="text-sm text-center font-bold text-gray-900">
                                        {fondsName}
                                    </p>
                                ) : null}
                                <p className="text-sm font-medium text-gray-900">
                                    {title}
                                </p>
                                <p className="text-sm font-medium text-gray-900">
                                    {date
                                        ? formatDate(new Date(date))
                                        : undefined}
                                </p>
                                {progress && (
                                    <p className="mt-1 text-sm text-gray-500">
                                        {progress}
                                    </p>
                                )}

                                <p className="mt-1 text-sm text-gray-500">
                                    {message}
                                </p>
                                {errorMessage ? (
                                    <p className="mt-1 text-sm text-gray-500 font-semibold">
                                        {errorMessage}
                                    </p>
                                ) : null}
                            </div>
                            <div className="ml-4 flex-shrink-0 flex">
                                <button
                                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                                    onClick={() => {
                                        dispatch({ type: 'DELETE', id });
                                    }}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </>
    );
}
