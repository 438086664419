import { IStep } from "./StepComponent";

const steps = [
  { id: "Step 1", name: "Job details", href: "#", status: "complete" },
  { id: "Step 2", name: "Application form", href: "#", status: "current" },
  { id: "Step 3", name: "Preview", href: "#", status: "upcoming" },
];

export interface IStep2 {
  id: string;
  name: string;
  status: "upcoming" | "current" | "complete";
  href: string;
}
interface Props {
  steps: IStep2[];
  className?: string;
  onClick?: (id: number) => void;
}
export default function Step2Component({ steps, className }: Props) {
  return (
    <nav aria-label="Progress" className={className}>
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step, index) => (
          <li key={index} className="md:flex-1">
            {step.status === "complete" ? (
              <a
                href={step.href}
                className="group flex flex-col border-l-4 border-secondColor py-2 pl-4 hover:border-mainColor md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-lg font-medium text-secondColor group-hover:text-mainColor">
                  {step.id}
                </span>
                <span className="text-md font-medium">{step.name}</span>
              </a>
            ) : step.status === "current" ? (
              <a
                href={step.href}
                className="flex flex-col border-l-4 border-secondColor py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                aria-current="step"
              >
                <span className="text-lg font-medium text-secondColor">
                  {step.id}
                </span>
                <span className="text-md font-medium">{step.name}</span>
              </a>
            ) : (
              <a
                href={step.href}
                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-lg font-medium text-gray-500 group-hover:text-gray-700">
                  {step.id}
                </span>
                <span className="text-md font-medium">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
