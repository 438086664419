import { useEffect, useRef, DependencyList, EffectCallback } from "react";

/**
 * A custom hook that applies debounce behavior to a callback function.
 * @param callback - The function to call after the debounce delay.
 * @param dependencies - An array of dependencies that trigger the callback when changed.
 * @param delay - The debounce delay in milliseconds.
 */
function useDebouncedEffect(
  callback: EffectCallback,
  dependencies: DependencyList,
  delay: number
): void {
  const latestCallback = useRef<EffectCallback>(callback);

  // Update the latest callback if it changes
  useEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    // Set up the timeout
    const handler = setTimeout(() => {
      latestCallback.current(); // Call the latest callback after the delay
    }, delay);

    // Clear the timeout on cleanup
    return () => clearTimeout(handler);
  }, [...dependencies, delay]); // Include dependencies and delay in the effect's dependency array
}

export default useDebouncedEffect;
