import { DocumentUserDto } from '@api/api';
import HoverEffect from '@components/commun/animation/HoverEffect';
import { PrimaryButton } from '@components/commun/Buttons';
import Loading from '@components/commun/Loading';
import PopUp from '@components/commun/PopUp';
import StatusDocument from '@components/commun/StatusDocument';
import { documentNameForUser } from '@components/DashboardUser/Profile/ValidationUser';
import { faDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

export function DisplayDocumentsFormInvestisor({
    document,
}: {
    document: DocumentUserDto;
}) {
    const type = document?.key.split('.')[1];

    const [popUp, setPopUp] = useState(false);

    return (
        <>
            {!document ? (
                <Loading />
            ) : popUp ? (
                <PopUp
                    open={popUp}
                    setOpen={setPopUp}
                    buttonBoolean={false}
                    width="w-full md:w-9/12"
                    title={() => {
                        return (
                            <h3 className="text-3xl font-semibold leading-6 text-gray-900">
                                {decodeURIComponent(document.fileName)}
                            </h3>
                        );
                    }}
                >
                    <div className="w-full flex mx-auto flex-col justify-center gap-3 md:w-10/12">
                        <img src={document?.url} className="" />
                        <div className="w-full">
                            <PrimaryButton
                                onClick={() => {
                                    setPopUp(false);
                                }}
                                className="w-fit"
                            >
                                Cancel
                            </PrimaryButton>
                        </div>
                    </div>
                </PopUp>
            ) : (
                <div
                    className={`w-full  mx-auto my-5 items-center gap-3 ${
                        type !== 'pdf'
                            ? 'grid max-w-4xl grid-cols-2 md:grid-cols-[auto,auto,auto] items-center'
                            : 'grid max-w-lg space-y-2 md:grid-cols-[auto,auto] items-center '
                    }`}
                >
                    <div
                        className={`${
                            type !== 'pdf'
                                ? 'w-full cursor-pointer flex-col md:flex-row md:col-span-1 mx-auto flex '
                                : 'w-fit '
                        } flex gap-2 justify-center items-center justify-self-start`}
                    >
                        {type !== 'pdf' ? (
                            <img
                                src={document?.url}
                                className="w-11/12 max-w-xs"
                                onClick={() => setPopUp(true)}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faFilePdf} size={'2x'} />
                        )}
                        <div className="w-fit">
                            <div className="text-sm  h-fit text-start w-full font-semibold leading-6 text-mainColor">
                                {documentNameForUser(document)}
                            </div>

                            <div className="text-sm h-fit text-start w-full font-medium leading-6 text-gray-900">
                                {decodeURIComponent(document?.fileName)}
                            </div>
                        </div>
                    </div>

                    <div
                        className={`h-fit justify-self-end text-end ${
                            document?.validation?.comment ? '' : ' '
                        }`}
                    >
                        <div className="text-sm flex gap-4   items-center font-medium leading-6  text-gray-900">
                            <StatusDocument status={document.status} />

                            <HoverEffect>
                                <a href={document.url} download={'test'}>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        className="text-mainColor cursor-pointer hover:text-secondColor w-[20px] h-[20px]"
                                    />
                                </a>
                            </HoverEffect>

                            {/* <HoverEffect onClick={() => handleDeleteDocument()}>
                <FontAwesomeIcon
                  icon={faTrash}
                  className="text-titleColor hover:text-gray-400 w-[20px] h-[20px]"
                />
              </HoverEffect> */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
