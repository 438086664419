import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { ReactNode } from "react";

interface Props {
  onClick?: () => void;
  className?: string;
}

const HoverEffect = (props: Props & { children?: ReactNode }) => {
  return (
    <motion.div
      className={`py-1 px-2 cursor-pointer flex items-center gap-2 rounded-md bg-secondBackgroundColor shadow-low w-fit ${props?.className}`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={props.onClick && props.onClick}
    >
      {props.children}
    </motion.div>
  );
};

export default HoverEffect;
