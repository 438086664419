import React, { createContext } from 'react';
import { tableBackOfficeInterface } from '../../types/BackOffice';

export interface UidContextInterface {
    uid: number | null | undefined;
    setUid: React.Dispatch<React.SetStateAction<number | null | undefined>>;
}

export interface StepFormInterface {
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    stepInsideStep0: number;
    setStepInsideStep0: React.Dispatch<React.SetStateAction<number>>;
}

export interface BackOfficeStateInterface {
    year: number;
    setYear: React.Dispatch<React.SetStateAction<number>>;
    month: number;
    setMonth: React.Dispatch<React.SetStateAction<number>>;
    day: number;
    setDay: React.Dispatch<React.SetStateAction<number>>;
    fondsId: number | undefined;
    setFondsId:
        | React.Dispatch<React.SetStateAction<number | undefined>>
        | undefined;
}

export interface BackOfficeUserStateInterface {
    year: number;
    setYear: React.Dispatch<React.SetStateAction<number>>;
    month: number;
    setMonth: React.Dispatch<React.SetStateAction<number>>;
    day: number;
    setDay: React.Dispatch<React.SetStateAction<number>>;
    fondsId: number | undefined;
    setFondsId:
        | React.Dispatch<React.SetStateAction<number | undefined>>
        | undefined;
}

export interface allowInterface {
    allow: boolean;
}

export interface loadingBackOfficeInterface {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface rootStateBlackOfficeInterface {
    fondsIdBackOffice: number | undefined;
    setFondsIdBackOffice: React.Dispatch<
        React.SetStateAction<number | undefined>
    >;
    applyFeesBool: boolean;
    setApplyFeesBool: React.Dispatch<React.SetStateAction<boolean>>;
    dailyShare: boolean;
    setdailyShare: React.Dispatch<React.SetStateAction<boolean>>;
    selectTable: tableBackOfficeInterface;
    setSelectTable: React.Dispatch<
        React.SetStateAction<tableBackOfficeInterface>
    >;
}

export interface MenuDashBoardAdminBool {
    toggleMenu: boolean;
    setToggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface MenuDashBoardUserBool {
    toggleMenu: boolean;
    setToggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UidContext = createContext<UidContextInterface | null>(null);
export const EtapeForm = createContext<StepFormInterface | null>(null);
export const BackOfficeState = createContext<BackOfficeStateInterface | null>(
    null
);
export const BackOfficeUserState =
    createContext<BackOfficeUserStateInterface | null>(null);

export const AllowState = createContext<allowInterface | null>(null);
export const LoadingBackOfficeState =
    createContext<loadingBackOfficeInterface | null>(null);

export const RootBlackOfficeState =
    createContext<rootStateBlackOfficeInterface | null>(null);

export const MenuDashBoardAdminBoolState =
    createContext<MenuDashBoardAdminBool | null>(null);

export const MenuDashBoardUserBoolState =
    createContext<MenuDashBoardAdminBool | null>(null);
