import {
    useCreateValidationCodeMutation,
    useFetchTokenQuery,
    useVerifyValidationCodeMutation,
} from '@api/api';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useVerifyValidationTokenOverridedMutation } from '@api/features/validationCodeSlice';
import { useNotificationContext } from '../../Context/notification-context';
import { PrimaryButton } from '../Buttons';
import Loading from '../Loading';
import PopUp from '../PopUp';

interface Props {
    onSubmit: () => void;
}

const useValidationCode = ({ onSubmit }: Props) => {
    const { data: userFetch, isLoading: isLoadingFetch } = useFetchTokenQuery();

    const [open, setOpen] = useState(false);

    const [createCode, { isLoading: isLoadingCreateCode }] =
        useCreateValidationCodeMutation();

    const [verifyCode, { isLoading: isLoadingVeryCode }] =
        useVerifyValidationCodeMutation();

    const [verifyToken] = useVerifyValidationTokenOverridedMutation();

    const { showError, showSuccess } = useNotificationContext();

    const [timer, setTimer] = useState<any>(null);
    const [countdown, setCountdown] = useState(60);
    const [message, setMessage] = useState('');

    const handleSubmitForm = async () => {
        await verifyToken(userFetch?.id!)
            .unwrap()
            .then(() => {
                setOpen(false);
                onSubmit();
            })
            .catch((err: any) => {
                if (err.data.tokenExpired) {
                    showError(
                        'Erreur',
                        'Votre token a expiré vous devez redemander un code'
                    );
                }

                if (!(countdown < 60 && countdown > 0)) {
                    createCode(userFetch?.id!);
                    setCountdown(59);
                    startTimer();
                }
                setOpen(true);
            });
    };

    // Check localStorage for existing timer on mount
    useEffect(() => {
        const savedTime = localStorage.getItem('timer');
        if (savedTime) {
            const timeLeft =
                60 - Math.floor((Date.now() - parseInt(savedTime)) / 1000);
            if (timeLeft > 0) {
                setCountdown(timeLeft);
                startTimer();
            }
        }
    }, []);

    // Start the timer function
    const startTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            setCountdown(60);
            localStorage.removeItem('timer');
        }, countdown * 1000);

        setTimer(newTimer);
        localStorage.setItem('timer', Date.now().toString());
    };

    // Update countdown every second
    useEffect(() => {
        if (countdown < 60 && countdown > 0) {
            setTimeout(() => setCountdown(countdown - 1), 1000);
        }
    }, [countdown]);

    const handleButtonClick = async () => {
        await createCode(userFetch?.id!);
        setCountdown(59);
        startTimer();
    };

    const { register, watch } = useForm<{
        code: string;
    }>();

    const [debouncedCode, setDebouncedCode] = useState(watch('code'));

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedCode(watch('code'));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [watch('code')]);

    const handleOnChange = async (val: string) => {
        await verifyCode({ userId: userFetch?.id!, body: { code: val } })
            .unwrap()
            .then((res) => {
                if (res.sucess) {
                    showSuccess(
                        'Code Validé',
                        'Vous pouvez maintenant modifier vos données personnelles ou effectuer des retrais pour une durée de 1 heure'
                    );
                    setOpen(false);
                    onSubmit();
                } else {
                    showError(
                        'Erreur',
                        'Erreur lors de la validation du code',
                        res.message
                    );
                }
            })
            .catch((err) => {
                setMessage(err?.data?.error);
                showError(
                    'Erreur',
                    'Erreur lors de la validation du code',
                    err?.data?.error
                );
            });
    };

    useEffect(() => {
        if (debouncedCode?.length === 8 && open === true) {
            handleOnChange(debouncedCode);
        }
    }, [debouncedCode]);

    const ValidationCode = ({ children }: { children: React.ReactNode }) => (
        <div>
            <PopUp
                open={open}
                setOpen={setOpen}
                width="w-full md:max-w-[600px]"
                buttonBoolean={false}
            >
                <div className="">
                    {children}
                    <div className="">
                        {isLoadingFetch ? (
                            <Loading />
                        ) : (
                            <>
                                <p className="my-2 text-justify">
                                    Un code vous a été envoyé à votre adresse
                                    email : {userFetch?.email}. Ce code est{' '}
                                    <span className=" text-mainColor font-semibold">
                                        valide 30 minutes
                                    </span>{' '}
                                    et peut être{' '}
                                    <span className=" text-mainColor font-semibold">
                                        utilisé qu'une seule fois
                                    </span>
                                    . Une fois ce code utilisé vous pourrez{' '}
                                    <span className=" text-mainColor font-semibold">
                                        modifier vos données personnelles ou
                                        effectuer des retrais pour une durée de
                                        1 heure
                                    </span>
                                    . Une fois ce délais dépassé, vous pourrez
                                    demander un nouveau code pour continuer vos
                                    actions.
                                </p>
                                <PrimaryButton
                                    type="button"
                                    onClick={handleButtonClick}
                                    disabled={countdown < 60 && countdown > 0}
                                    loading={isLoadingCreateCode}
                                >
                                    {countdown < 60 && countdown > 0
                                        ? `Renvoyer un code dans ${countdown} s`
                                        : 'Renvoyer un code'}
                                </PrimaryButton>
                                <div className="w-full">
                                    <p className="text-red-500 text-justify">
                                        {message}
                                    </p>
                                </div>
                                <div className="flex mx-auto gap-2 items-center relative w-fit">
                                    <input
                                        type="text"
                                        className="w-[200px] uppercase mx-auto border tracking-[3px] border-gray-300 rounded-md px-3 py-2 mt-4 focus:outline-none focus:border-blue-500"
                                        placeholder="Code de validation"
                                        {...register('code')}
                                    />
                                    {isLoadingVeryCode ? (
                                        <div className="top-5 right-[-40px] absolute">
                                            <Loading />
                                        </div>
                                    ) : null}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </PopUp>
        </div>
    );

    return {
        ValidationCode,
        open,
        setOpen,
        handleSubmitForm,
    };
};

export default useValidationCode;
