import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NumberParam, useQueryParam } from 'use-query-params';
import {
    formatDate,
    printLargeValue,
    transformDate,
} from '../../../function/Utils';

import {
    DocumentSubscriptionType,
    StatusUserTypeEnum,
    SubscriptionUserFondsDto,
    SubscriptionUserFondsStatus,
    TransactionDto,
    TransactionStatus,
    ValidSubscriptionRequest,
    ValidTransationSubscriptionRequest,
    api,
    useDeleteSubscriptionUserMutation,
    useGetAllSubscriptionsUserQuery,
    useGetSubscriptionUserQuery,
    useGetUserWithAllInfoQuery,
    useValidSubscriptionMutation,
    useValidTransactionFromSubscriptionMutation,
} from '@api/api';
import {
    UploadDocumentTransactionDto,
    uploadDocumentTransaction,
} from '@api/features/transactionSlice';
import { useAppDispatch } from '@api/store';
import {
    PrimaryButton,
    SuccessButton,
    WhiteButton,
} from '../../commun/Buttons';
import useDeleteItem from '../../commun/CustomHook/useDeleteItem';
import {
    DropDownComponentAdmin,
    DropDownComponentUser,
} from '../../commun/DropDownComponent';
import InputComponent from '../../commun/formComponent/InputComponent';
import UploaderMulti from '../../commun/formComponent/UploaderMulti';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import { useNotificationContext } from '../../Context/notification-context';
import { DisplaySubscriptionDoc } from '../../DashboardUser/Investir/EditSubscription';
import GenerateContract from '../../DashboardUser/Investir/GenerateContract';
import { DisplaySubscriptionStatus } from '../../DashboardUser/Investir/Investir';
import TableComponent from '../BackOffice/Components/TableComponent';

const SubscriptionAdmin = ({ userId }: { userId: number }) => {
    const { data: subscriptions, isLoading: isLoadingSubscriptoin } =
        useGetAllSubscriptionsUserQuery(userId ?? 0, {
            skip: userId === undefined,
        });

    const subscriptionsPending =
        subscriptions?.filter(
            (s) => s.status === SubscriptionUserFondsStatus.PENDING
        ) || [];

    const subscriptionsValidated =
        subscriptions?.filter(
            (s) => s.status === SubscriptionUserFondsStatus.ACCEPTED
        ) || [];

    const [historiqueSub, setHistoriqueSub] = useState(true);

    return (
        <div>
            <TitleComponentForm>Souscriptions en cours</TitleComponentForm>
            {subscriptionsPending?.length === 0 ? (
                <div className="p-3 text-center w-full md:w-11/12 mx-2">
                    <p className="text-lg">Aucune souscription en cours</p>
                </div>
            ) : (
                subscriptionsPending.map((s) => (
                    <div key={s.id} className="flex mt-3 flex-col gap-4">
                        <DisplaySubscription subscription={s} />
                    </div>
                ))
            )}
            <DropDownComponentAdmin
                title="Subscriptions validées"
                bool={historiqueSub}
                setBool={setHistoriqueSub}
            >
                {subscriptionsValidated?.length === 0 ? (
                    <div className="p-3 text-center w-full md:w-11/12 mx-2">
                        <p className="text-lg">Aucune souscription validée</p>
                    </div>
                ) : (
                    subscriptionsValidated.map((s) => (
                        <div key={s.id} className="flex mt-3 flex-col gap-4">
                            <DisplaySubscription subscription={s} />
                        </div>
                    ))
                )}
            </DropDownComponentAdmin>
        </div>
    );
};

export default SubscriptionAdmin;

function DisplaySubscription({
    subscription,
}: {
    subscription: SubscriptionUserFondsDto;
}) {
    const { data: user, isLoading: loading } = useGetUserWithAllInfoQuery(
        subscription.userId
    );

    const statusUser = user?.statusUser?.find(
        (status) =>
            status.url?.split('subscriptionId=')[1] &&
            parseInt(status.url?.split('subscriptionId=')[1]) ===
                subscription.id
    );

    const [deleteSubscription, { isLoading: isLoadingDelete }] =
        useDeleteSubscriptionUserMutation();

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        typeTransactionId: number;
    }>({
        deleteItem: () =>
            deleteSubscription({
                userId: user?.id!,
                subscriptionUserFondsId: subscription.id,
            }),
        message: 'cette souscription',
        isLoading: isLoadingDelete,
    });

    const [open, setOpen] = useState(false);
    const [openValidTransaction, setOpenValidTransaction] = useState(false);

    return (
        <div className="p-3 bg-gray-100 rounded-md w-full md:w-11/12 mx-2">
            <DeleteComponent title="cette souscription" />
            {open ? (
                <PopUpSubscription
                    sId={subscription.id}
                    userId={subscription.userId}
                    open={open}
                    setOpen={setOpen}
                    openValidationTransaction={openValidTransaction}
                    setOpenValidationTransaction={setOpenValidTransaction}
                />
            ) : null}

            <div className="grid grid-cols-[auto,auto]">
                <p className="text-lg">
                    Souscription du{' '}
                    <span className=" text-mainColor font-semibold">
                        {formatDate(new Date(subscription.date))}
                    </span>
                    {subscription?.dateDone ? (
                        <>
                            {' '}
                            - Date de validation:{' '}
                            <span className=" text-mainColor font-semibold">
                                {formatDate(new Date(subscription?.dateDone))}
                            </span>
                        </>
                    ) : null}
                </p>
                <div className=" justify-self-end">
                    <DisplaySubscriptionStatus
                        status={statusUser}
                        statusSub={subscription.status}
                    />
                </div>
            </div>
            <div className="grid md:grid-cols-2">
                <div className="">
                    <div className="grid text-xl font-semibold text-mainColor md:grid-cols-4">
                        <p>Fonds</p>
                        <p>Titre</p>
                        <p>Montant</p>
                        <p></p>
                    </div>
                    {subscription.transactions.map((t) => (
                        <div className="grid md:grid-cols-4 text-lg ">
                            <p className="">{t?.fondsName}</p>
                            <p>
                                {t.typeShare == 'distribution'
                                    ? 'Titre D'
                                    : 'Titre C'}
                            </p>
                            <p>{printLargeValue(t.amount)} €</p>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center items-center gap-3">
                    <WhiteButton onClick={() => setOpenDelete(true)}>
                        Annuler
                    </WhiteButton>
                    <PrimaryButton
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        <>
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="text-white mr-3"
                            />
                            {statusUser?.type === StatusUserTypeEnum.toDo
                                ? 'Valider'
                                : 'Accèder'}
                        </>
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
}

interface ValidTransationSubscriptionRequestFrom
    extends ValidTransationSubscriptionRequest {
    files?: File[];
}

function PopUpValidTransaction({
    open,
    setOpen,
    userId,
    sId,
}: {
    sId: number;
    open: boolean;
    userId: number;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [tId, setTId] = useQueryParam('transactionId', NumberParam);

    const formState = useForm<ValidTransationSubscriptionRequestFrom>({
        defaultValues: {
            date: new Date().toJSON().split('T')[0] as any,
            dateInterest: new Date().toJSON().split('T')[0] as any,
        },
    });

    const {
        formState: { isValid },
    } = formState;

    const [validTransaction, { isLoading }] =
        useValidTransactionFromSubscriptionMutation();
    const { showError, showSuccess } = useNotificationContext();

    const {
        data: s,
        isLoading: isLoadingSubscription,
        refetch: refectchSub,
    } = useGetSubscriptionUserQuery(
        {
            subscriptionUserFondsId: sId,
            userId: userId || 0,
        },
        {
            skip: !sId || userId === undefined,
        }
    );

    const [loading, setLoading] = useState<boolean>(false);

    const onSumbit = async (data: ValidTransationSubscriptionRequestFrom) => {
        setLoading((curr) => !curr);

        const files = data?.files;
        delete data.files;

        await validTransaction({
            validTransationSubscriptionRequest: {
                transactionId: tId || 0,
                date: transformDate(data.date)?.toISOString(),
                dateInterest: transformDate(data.dateInterest)?.toISOString(),
            },
            transactionId: tId || 0,
        })
            .unwrap()
            .then(async () => {
                try {
                    if (files) {
                        for (let i = 0; i < files.length; i++) {
                            const dataUpload: UploadDocumentTransactionDto = {
                                transactionId: tId || 0,
                                file: files[i],
                                fileName: files[i].name,
                            };

                            await uploadDocumentTransaction(dataUpload)
                                .then((res) => {
                                    if (res.sucess) {
                                        showSuccess(
                                            'Uploaded',
                                            'Successfully uploaded'
                                        );
                                    } else {
                                        showError(
                                            'Error',
                                            `Error uploading file ${dataUpload.fileName}: ${res.message}`
                                        );
                                    }
                                })
                                .catch((err) => {
                                    showError(
                                        'Error',
                                        `Error uploading file ${dataUpload.fileName}`
                                    );
                                });
                        }
                    }
                } catch (err) {
                    showError('Error', `Error uploading `);
                    setLoading((curr) => !curr);
                    return;
                }
                await refectchSub();
                showSuccess('Validé', 'La transaction a été validée');
                setLoading((curr) => !curr);
                setOpen(false);
            })
            .catch(() => {
                showError(
                    'Erreur',
                    'Erreur lors de la validation de la transaction'
                );
            });
    };

    return (
        <PopUp open={open} setOpen={setOpen} buttonBoolean={false}>
            <form
                onSubmit={formState.handleSubmit(onSumbit)}
                className=" formTemplateSingUp"
            >
                <InputComponent
                    value="date"
                    register={formState.register}
                    type="date"
                >
                    <label className=" text-lg">Date de la transaction</label>
                </InputComponent>
                <InputComponent
                    value="dateInterest"
                    register={formState.register}
                    type="date"
                >
                    <label className=" text-lg">
                        Date de l'émission des titres
                    </label>
                </InputComponent>
                <UploaderMulti
                    register={formState.register}
                    value={'files'}
                    watch={formState.watch}
                    setValue={formState.setValue}
                    control={formState.control}
                    required={false}
                    size={10}
                    button={false}
                >
                    <h3 className="text-2xl mb-5">Ajouter des documents</h3>
                </UploaderMulti>
                <div className="mt-4 w-full flex items-center justify-center gap-3 ">
                    <WhiteButton onClick={() => setOpen(false)}>
                        Annuler
                    </WhiteButton>
                    <PrimaryButton
                        type="submit"
                        disabled={!isValid}
                        loading={isLoading}
                    >
                        Valider
                    </PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}

function PopUpSubscription({
    sId,
    userId,
    open,
    setOpen,
    openValidationTransaction,
    setOpenValidationTransaction,
}: {
    sId: number;
    userId: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    openValidationTransaction: boolean;
    setOpenValidationTransaction: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const {
        data: s,
        isLoading: isLoadingSubscription,
        refetch,
    } = useGetSubscriptionUserQuery(
        {
            subscriptionUserFondsId: sId,
            userId: userId || 0,
        },
        {
            skip: !sId || userId === undefined,
        }
    );

    const documentSubscription = s?.documents?.find(
        (d) => d.type === DocumentSubscriptionType.SUBSCRIPTION
    );

    const {
        data: subscriptions,
        isLoading: isLoadingSubscriptoin,
        refetch: refetchAllSub,
    } = useGetAllSubscriptionsUserQuery(userId ?? 0, {
        skip: userId === undefined,
    });

    const [sousScriptionBool, setSouScriptionBool] = useState(true);
    const [transactionBool, setTransactionBool] = useState(true);
    const [documentBool, setDocumentBool] = useState(true);

    const { data: user, isLoading: loading } =
        useGetUserWithAllInfoQuery(userId);

    const status = user?.statusUser?.find(
        (status) =>
            status.url?.split('subscriptionId=')[1] &&
            s &&
            parseInt(status.url?.split('subscriptionId=')[1]) === s.id
    );

    const [sign, setSign] = useState(false);

    const [validSubscription, { isLoading: isLoadingValidSub }] =
        useValidSubscriptionMutation();

    const { showError, showSuccess } = useNotificationContext();

    const dispatch = useAppDispatch();

    const handleValidationSubscription = async (value: {
        date: Date;
        dateInterest: Date;
    }) => {
        const data: ValidSubscriptionRequest = {
            sId: sId,
            statusId: status?.id!,
            date: transformDate(value.date)?.toISOString(),
            dateInterest: transformDate(value.dateInterest)?.toISOString(),
            userId: userId,
        };
        await validSubscription({
            validSubscriptionRequest: data,
            subscriptionId: sId,
        })
            .unwrap()
            .then(async (res) => {
                showSuccess('Validé', 'La souscription a été validée');
                dispatch(
                    api.util.invalidateTags(['SubscriptionUserFonds', 'User'])
                );
                // await refetchAllSub();
                // await refetch();
                setValidOpen(false);
                setOpen(false);
            })
            .catch((err) => {
                showError(
                    'Erreur',
                    'Erreur lors de la validation de la souscription'
                );
            });
    };

    const [validOpen, setValidOpen] = useState(false);

    const formState = useForm<{
        date: Date;
        dateInterest: Date;
    }>({
        defaultValues: {
            date: new Date().toJSON().split('T')[0] as any,
            dateInterest: new Date().toJSON().split('T')[0] as any,
        },
    });

    const ValidComponent = () => {
        return (
            <PopUp
                open={validOpen}
                setOpen={setValidOpen}
                buttonBoolean={false}
            >
                <form
                    onSubmit={formState.handleSubmit(
                        handleValidationSubscription
                    )}
                    className=" formTemplateSingUp"
                >
                    <InputComponent
                        value="date"
                        register={formState.register}
                        type="date"
                    >
                        <label className=" text-lg">
                            Date de la transaction
                        </label>
                    </InputComponent>
                    <InputComponent
                        value="dateInterest"
                        register={formState.register}
                        type="date"
                    >
                        <label className=" text-lg">
                            Date de l'émission des titres
                        </label>
                    </InputComponent>
                    <div className="mt-4 w-full flex items-center justify-center gap-3 ">
                        <WhiteButton onClick={() => setValidOpen(false)}>
                            Annuler
                        </WhiteButton>
                        <PrimaryButton
                            type="submit"
                            loading={isLoadingValidSub}
                        >
                            Valider
                        </PrimaryButton>
                    </div>
                </form>
            </PopUp>
        );
    };

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            width="w-11/12 "
            title={() => (
                <div className="flex flex-col gap-3 mb-3 relative  items-center">
                    <h3 className="font-mainFontFamily text-2xl text-center">
                        Date de la souscription:{' '}
                        <span className=" text-mainColor font-semibold">
                            {s?.date
                                ? formatDate(new Date(s?.date))
                                : 'Non trouvée'}
                        </span>
                        {s?.dateDone ? (
                            <>
                                {' '}
                                - Date de validation:{' '}
                                <span className=" text-mainColor font-semibold">
                                    {formatDate(new Date(s?.dateDone))}
                                </span>
                            </>
                        ) : null}
                    </h3>
                    <div className="justify-self-end md:absolute right-0 top-[-10px]">
                        <DisplaySubscriptionStatus
                            status={status}
                            statusSub={s?.status!}
                        />
                    </div>
                </div>
            )}
        >
            {openValidationTransaction ? (
                <PopUpValidTransaction
                    open={openValidationTransaction}
                    setOpen={setOpenValidationTransaction}
                    userId={s?.userId!}
                    sId={s?.id!}
                />
            ) : null}
            {validOpen ? <ValidComponent /> : null}
            <div className="">
                {isLoadingSubscription ? (
                    <div className="w-full flex items-center justify-center">
                        <Loading />
                    </div>
                ) : (
                    <>
                        {s ? (
                            <>
                                <DropDownComponentUser
                                    bool={sousScriptionBool}
                                    setBool={setSouScriptionBool}
                                    title={'Souscription'}
                                >
                                    {documentSubscription ? (
                                        <DisplaySubscriptionDoc
                                            d={documentSubscription}
                                        />
                                    ) : (
                                        <GenerateContract
                                            s={s}
                                            sign={sign}
                                            setSign={setSign}
                                            admin={true}
                                        />
                                    )}
                                </DropDownComponentUser>
                                <DropDownComponentUser
                                    bool={transactionBool}
                                    setBool={setTransactionBool}
                                    title={'Paiement'}
                                >
                                    <>
                                        <div className="flex flex-col gap-4 mb-4">
                                            <div className="mt-3 w-full md:w-11/12 mx-auto flex flex-col mb-2 justify-center items-center">
                                                <TableComponent
                                                    head={[
                                                        'Fonds',
                                                        'Part',
                                                        'Montant',
                                                        "Frais d'entrais",
                                                        'Frais de gestion',
                                                        '',
                                                    ]}
                                                >
                                                    <tbody className=" ">
                                                        <React.Fragment>
                                                            {s?.transactions?.map(
                                                                (t, key) => (
                                                                    <DisplayTransactions
                                                                        transaction={
                                                                            t
                                                                        }
                                                                        key={
                                                                            key
                                                                        }
                                                                        index={
                                                                            key
                                                                        }
                                                                        open={
                                                                            openValidationTransaction
                                                                        }
                                                                        setOpen={
                                                                            setOpenValidationTransaction
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </React.Fragment>
                                                    </tbody>
                                                </TableComponent>

                                                {status &&
                                                status.type ===
                                                    StatusUserTypeEnum.toDo ? (
                                                    <div className="w-full flex flex-col justify-center items-center gap-3">
                                                        <p>
                                                            Transactions non
                                                            effectuées
                                                        </p>
                                                    </div>
                                                ) : status &&
                                                  status.type ===
                                                      StatusUserTypeEnum.inComing ? (
                                                    <SuccessButton
                                                        className="!w-fit"
                                                        disabled={true}
                                                    >
                                                        Transactions effectuées
                                                    </SuccessButton>
                                                ) : (
                                                    <SuccessButton
                                                        className="!w-fit"
                                                        disabled={true}
                                                        onClick={() => {}}
                                                    >
                                                        Transactions reçus
                                                    </SuccessButton>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                </DropDownComponentUser>
                                <DropDownComponentUser
                                    bool={documentBool}
                                    setBool={setDocumentBool}
                                    title={'Documents'}
                                >
                                    <></>
                                </DropDownComponentUser>
                            </>
                        ) : (
                            <div className="">
                                <p>La souscription n'a pas été trouvée</p>
                            </div>
                        )}
                    </>
                )}
                <div className="flex justify-center items-center gap-3 mt-3">
                    <WhiteButton onClick={() => setOpen(false)}>
                        Fermer
                    </WhiteButton>
                    {s?.status !== SubscriptionUserFondsStatus.ACCEPTED ? (
                        <PrimaryButton onClick={() => setValidOpen(true)}>
                            Valider la souscription
                        </PrimaryButton>
                    ) : null}
                </div>
            </div>
        </PopUp>
    );
}

function DisplayTransactions({
    transaction,
    index,
    open,
    setOpen,
}: {
    transaction: TransactionDto;
    index: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const classNameObejct = {
        item: 'text-sm font-light px-1 py-2 text-start whitespace-nowrap',
    };

    const [tId, setTId] = useQueryParam('transactionId', NumberParam);

    return (
        <tr
            key={index}
            className={`border-b cursor-pointer w-full hover:bg-slate-500 hover:opacity-80 ${
                index % 2 === 1 ? 'bg-secondBackgroundColor' : 'bg-white '
            } `}
        >
            <td className={`${classNameObejct.item} pl-1`}>
                {transaction.fondsName}
            </td>
            <td className={`${classNameObejct.item}`}>
                {transaction.typeShare == 'distribution'
                    ? 'Titre D'
                    : 'Titre C'}
            </td>

            <td className={`${classNameObejct.item}`}>
                {printLargeValue(transaction.amount)} €
            </td>
            <td className={`${classNameObejct.item}`}>
                {(transaction?.transactionUserIn?.entranceFee || 0) * 100} %
            </td>
            <td className={`${classNameObejct.item}`}>
                {(transaction?.transactionUserIn?.managementFee || 0) * 100} %
            </td>
            <td className={`${classNameObejct.item}`}>
                {transaction.status !== TransactionStatus.VALIDATED ? (
                    <PrimaryButton
                        onClick={() => {
                            setTId(transaction.id);
                            setOpen(true);
                        }}
                    >
                        Valider
                    </PrimaryButton>
                ) : (
                    <SuccessButton disabled={true}>Validé</SuccessButton>
                )}
            </td>
        </tr>
    );
}
