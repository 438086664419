import { useGetOverviewFondsInfoOverviewAdminQuery } from '@api/api';
import { Chart } from 'chart.js';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { fondsColor } from '../../../../data/FondsData';
import {
    compareTwoDate,
    createDateArray,
    debounce,
    lastMonth,
    monthNumberToString,
    setDate,
} from '../../../../function/Utils';
import { useGetAllFondsQuery } from '../../../../redux/features/fondsSlice';
import { useAppSelector } from '../../../../redux/store';
import {
    BackOfficeState,
    MenuDashBoardAdminBoolState,
    RootBlackOfficeState,
} from '../../../Context/AppContext';

const AmontInFunds = () => {
    const pageState = useAppSelector(
        (state) => state.pageMeasurement.measurements
    );

    const { data: fondsData } = useGetAllFondsQuery();

    const backOfficeState = useContext(BackOfficeState);
    const menuBoolState = useContext(MenuDashBoardAdminBoolState);

    const rootState = useContext(RootBlackOfficeState);

    const { data: ovierViewAdminInfo, isLoading } =
        useGetOverviewFondsInfoOverviewAdminQuery(
            setDate(
                backOfficeState?.year!,
                backOfficeState?.month!,
                backOfficeState?.day ?? 0
            ).toISOString(),
            {
                skip:
                    !backOfficeState?.year ||
                    backOfficeState?.month === undefined ||
                    !backOfficeState?.day ||
                    rootState?.applyFeesBool === undefined,
            }
        );

    const [datasets, setDatasets] = useState<any>();
    const [labels, setLabels] = useState<any>();

    const updateGraph = useCallback(
        debounce(() => {
            if (ovierViewAdminInfo && backOfficeState && fondsData) {
                const firstDate = setDate(2021, 3, 1);

                const currentDate: Date = setDate(
                    backOfficeState?.year!,
                    backOfficeState?.month!,
                    backOfficeState.day
                );
                let dateChosen: Date = currentDate;
                // firstDate is current date 12 months before
                for (let i = 0; i < 11; i++) {
                    dateChosen = lastMonth(dateChosen);
                }

                if (compareTwoDate(dateChosen, firstDate)) {
                    dateChosen = firstDate;
                }

                const dateArray = createDateArray(
                    dateChosen,
                    setDate(
                        backOfficeState.year,
                        backOfficeState.month,
                        backOfficeState.day
                    )
                ).map((date) => {
                    return monthNumberToString(new Date(date).getMonth());
                });

                setLabels(dateArray);

                setDatasets(
                    ovierViewAdminInfo.fondsInfo.map((backOffice, key) => {
                        const length = backOffice?.valorisationFunds.length;
                        const data = dateArray.map((d, key) => {
                            if (length + key >= dateArray.length) {
                                return backOffice?.valorisationFunds[
                                    key - (dateArray.length - length)
                                ].value;
                            } else {
                                return null;
                            }
                        });
                        return {
                            label: backOffice.fondsName,
                            data: data,
                            backgroundColor: fondsColor[key],
                            borderColor: fondsColor[key],
                        };
                    })
                );
            }
        }, 100),
        [fondsData, backOfficeState, ovierViewAdminInfo]
    );

    useEffect(() => {
        if (ovierViewAdminInfo) {
            updateGraph();
        }
    }, [fondsData, backOfficeState, ovierViewAdminInfo]);

    useEffect(() => {
        if (datasets && labels) {
            const hook = document.getElementById(
                'myChart2'
            )! as HTMLCanvasElement;
            const ctx = hook.getContext('2d')!;
            const myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: datasets,
                },
                options: {
                    responsive: true,

                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                font: {
                                    size: 12,
                                },
                            },
                        },
                    },
                },
            });

            return () => {
                myChart.destroy();
            };
        }
    }, [pageState.widthScreen, labels, datasets, menuBoolState?.toggleMenu]);

    return (
        <React.Fragment>
            <h2 className=" bg-bgDashboardClient mr-1 pl-4 p-2 uppercase rounded-md mt-2 font-mainFontFamily text-2xl text-mainColor">
                Valorisation des fonds
            </h2>

            <canvas
                id="myChart2"
                // style={{ minWidth: "400px", minHeight: "200px" }}
            ></canvas>
        </React.Fragment>
    );
};

export default AmontInFunds;
