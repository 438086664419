import React, { useEffect } from "react";
import Avertissementcomponent from "../commun/Avertissementcomponent";

const AvertissementRisques = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="reglementation-page-container">
      <h1>Avertissements sur les risques</h1>
      <Avertissementcomponent />{" "}
    </div>
  );
};

export default AvertissementRisques;
