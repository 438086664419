import { CalendarIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import {
  DateRangePicker as AriaDateRangePicker,
  DateRangePickerProps as AriaDateRangePickerProps,
  CalendarCell,
  CalendarGrid,
  DateRangePickerStateContext,
  DateValue,
  Heading,
  ValidationResult,
} from "react-aria-components";
import { Button } from "../Button/Button";
import { DateInput } from "./DateField";
import { Dialog } from "../Overlay/Dialog";
import { Description, FieldError, FieldGroup, Label } from "../Form/Field";
import { Popover } from "../Overlay/Popover";
import { RangeCalendar } from "./RangeCalendar";
import { composeTailwindRenderProps } from "../utils";
import { formatInTimeZone } from "date-fns-tz";
import { parseDate } from "@internationalized/date";
import { FieldValues, Path, UseFormReturn, useForm } from "react-hook-form";

export interface DateRangePickerProps<T extends DateValue>
  extends AriaDateRangePickerProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

// If want to use it with useFrom
// provide formSate and formStartDateName and formEndDateName
export interface DateRangePickerPropsCustom<
  T extends DateValue,
  G extends FieldValues
> extends DateRangePickerProps<DateValue> {
  defaultValueCustom?: {
    timeZone?: string;
    custom?: {
      start: Date;
      end: Date;
    };
    range?: DateRanges;
    nbDays?: number;
  };
  formState?: UseFormReturn<G, any>;
  formStartDateName?: Path<G>;
  formEndDateName?: Path<G>;
  selectRanges?: boolean;
}

export enum DateRanges {
  CUSTOM = "Custom",
  LAST_DAY = "Last day",
  LAST_3_DAYS = "Last 3 Days",
  LAST_7_DAYS = "Last 7 Days",
  LAST_MONTHS = "Last month",
  LAST_3_MONTHS = "Last 3 months",
  LAST_6_MONTHS = "Last 6 months",
  LAST_YEAR = "Last year",
  LAST_2_YEAR = "Last 2 year",
  LAST_5_YEAR = "Last 5 year",
}

export function useDateRangePickerCustom<
  T extends DateValue,
  G extends FieldValues
>({
  label,
  description,
  errorMessage,
  selectRanges = true,
  ...props
}: DateRangePickerPropsCustom<T, G>) {
  const timeZone = props?.defaultValueCustom?.timeZone || "UTC";

  const [valueRange, setValueRange] = useState<DateRanges>(
    props?.defaultValueCustom?.custom || props?.defaultValueCustom?.nbDays
      ? DateRanges.CUSTOM
      : props?.defaultValueCustom?.range || DateRanges.LAST_7_DAYS
  );

  const getDefaultValue = () => {
    let defaultValueCustom: any = undefined;
    if (props?.defaultValueCustom?.custom) {
      defaultValueCustom = {
        start: formatInTimeZone(
          new Date(props?.defaultValueCustom.custom.start),
          timeZone,
          "yyyy-MM-dd"
        ),
        end: formatInTimeZone(
          new Date(props?.defaultValueCustom.custom.end),
          timeZone,
          "yyyy-MM-dd"
        ),
      };
    } else if (props?.defaultValueCustom?.nbDays) {
      defaultValueCustom = {
        start: formatInTimeZone(
          new Date(
            new Date().getTime() -
              props?.defaultValueCustom?.nbDays * 24 * 60 * 60 * 1000
          ),
          timeZone,
          "yyyy-MM-dd"
        ),
        end: formatInTimeZone(
          new Date(new Date().getTime()),
          timeZone,
          "yyyy-MM-dd"
        ),
      };
    }
    return defaultValueCustom
      ? {
          start: parseDate(defaultValueCustom.start),
          end: parseDate(defaultValueCustom.end),
        }
      : props.defaultValue;
  };

  const [value, setValue] = useState(getDefaultValue());

  useEffect(() => {
    if (props.formState) {
      if (props.formStartDateName) {
        props.formState.setValue(
          props.formStartDateName,
          value?.start.toDate(timeZone) as any
        );
      }
      if (props.formEndDateName) {
        props.formState.setValue(
          props.formEndDateName,
          value?.end.toDate(timeZone) as any
        );
      }
    }
  }, [value]);

  const updateValue = (dateRange: DateRanges) => {
    let nbDays = 0;
    switch (dateRange) {
      case DateRanges.CUSTOM:
        return;
      case DateRanges.LAST_DAY:
        nbDays = 1;
        break;
      case DateRanges.LAST_3_DAYS:
        nbDays = 3;
        break;
      case DateRanges.LAST_7_DAYS:
        nbDays = 7;
        break;
      case DateRanges.LAST_MONTHS:
        nbDays = 30;
        break;
      case DateRanges.LAST_3_MONTHS:
        nbDays = 90;
        break;
      case DateRanges.LAST_6_MONTHS:
        nbDays = 180;
        break;
      case DateRanges.LAST_YEAR:
        nbDays = 365;
        break;
      case DateRanges.LAST_2_YEAR:
        nbDays = 730;
        break;
      case DateRanges.LAST_5_YEAR:
        nbDays = 1825;
        break;
      default:
        nbDays = 7;
        break;
    }
    setValue({
      start: parseDate(
        formatInTimeZone(
          new Date(new Date().getTime() - nbDays * 24 * 60 * 60 * 1000),
          timeZone,
          "yyyy-MM-dd"
        )
      ),
      end: parseDate(
        formatInTimeZone(new Date(new Date().getTime()), timeZone, "yyyy-MM-dd")
      ),
    });
  };

  useEffect(() => {
    updateValue(valueRange);
  }, [valueRange]);

  const DateRangePickerCustom = ({
    label,
    description,
    errorMessage,
    ...props
  }: DateRangePickerProps<T>) => (
    <div className="flex items-center gap-5 flex-wrap">
      {selectRanges ? (
        <div className="flex flex-col">
          <label
            htmlFor="range"
            className="text-sm mb-1 text-gray-500 dark:text-zinc-400 font-medium cursor-default w-fit"
          >
            Range
          </label>
          <select
            id="range"
            name="range"
            className=" border text-lg py-[5px] border-gray-300 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
            onChange={(e) => setValueRange(e.target.value as DateRanges)}
            value={valueRange}
          >
            {Object.values(DateRanges).map((range) => (
              <option
                key={range}
                value={range}
                // selected={_region.id == region ? true : false}
              >
                {range}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      <DateRangePicker
        {...props}
        label={label}
        description={description}
        errorMessage={errorMessage}
        defaultValue={getDefaultValue() as any}
        value={value as any}
        onChange={(val) => {
          setValueRange(DateRanges.CUSTOM);
          setValue(val);
        }}
      />
    </div>
  );

  return {
    DateRangePickerCustom,
    startDate: value?.start.toDate(timeZone),
    endDate: value?.end.toDate(timeZone),
    setStartDate: (val: Date) =>
      setValue({
        start: parseDate(formatInTimeZone(val, timeZone, "yyyy-MM-dd")),
        end: value?.end as any,
      }),
    setEndDate: (val: Date) =>
      setValue({
        start: value?.start as any,
        end: parseDate(formatInTimeZone(val, timeZone, "yyyy-MM-dd")),
      }),
    valueRange,
    setValueRange,
  };
}

function DateRangePicker<T extends DateValue>({
  label,
  description,
  errorMessage,
  ...props
}: DateRangePickerProps<T>) {
  function DateRangePickerClearButton() {
    const state = React.useContext(DateRangePickerStateContext)!;
    return (
      <Button
        // Don't inherit default Button behavior from DateRangePicker.
        slot={null}
        className="py-0 px-1"
        aria-label="Clear"
        onPress={() => state.setValue(null)}
      >
        ✕
      </Button>
    );
  }

  return (
    <AriaDateRangePicker
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "group flex flex-col gap-1"
      )}
    >
      {label && <Label>{label}</Label>}
      <FieldGroup className="min-w-[208px] w-auto">
        <DateInput slot="start" className="px-2 py-1.5 text-sm" />
        <span
          aria-hidden="true"
          className="text-gray-800 dark:text-zinc-200 forced-colors:text-[ButtonText] group-disabled:text-gray-200 group-disabled:dark:text-zinc-600 group-disabled:forced-colors:text-[GrayText]"
        >
          –
        </span>
        <DateInput slot="end" className="flex-1 px-2 py-1.5 text-sm" />
        <DateRangePickerClearButton />
        <Button variant="icon" className="w-6 mr-1 rounded outline-offset-0">
          <CalendarIcon aria-hidden className="w-4 h-4" />
        </Button>
      </FieldGroup>
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
      <Popover>
        <Dialog>
          <RangeCalendar />
        </Dialog>
      </Popover>
    </AriaDateRangePicker>
  );
}
