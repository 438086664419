import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { formatDate } from '../../../function/Utils';

import Loading from '../../commun/Loading';

import {
    FeeValueDto,
    GetFeesMetricsApiArg,
    useGetFeesMetricsQuery,
} from '@api/api';
import { UseFormWatch, useForm } from 'react-hook-form';
import { fondsColor } from '../../../data/FondsData';
import { useGetAllFondsQuery } from '../../../redux/features/fondsSlice';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import SelectMultiComponent from '../../commun/formComponent/SelectMultiComponent';
import {
    DateRanges,
    useDateRangePickerCustom,
} from '../../commun/wskit/DateTime/DateTimeRangePicker';

// In your component's render method or return statement

const MetricsFeeValue = () => {
    const date = new Date();
    const dateEnd = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        4
    );

    const { data: fonds } = useGetAllFondsQuery();

    const formState = useForm<GetFeesMetricsApiArg>({
        defaultValues: {
            // dateStart: addDays(dateEnd, -365)?.toISOString().split("T")[0] as any,
            // dateEnd: dateEnd?.toISOString().split("T")[0] as any,
            groupby: 'month',
            fondsIds: [] as any,
        },
    });

    const { register, watch, setValue, control } = formState;

    const { valueRange, DateRangePickerCustom } = useDateRangePickerCustom({
        formState,
        formEndDateName: 'dateEnd',
        formStartDateName: 'dateStart',
        defaultValueCustom: {
            range: DateRanges.LAST_YEAR,
        },
    });

    // Debounced state
    const [debouncedValues, setDebouncedValues] = useState({
        dateStart: watch('dateStart'),
        dateEnd: watch('dateEnd'),
        groupby: watch('groupby'),
        fondsIds: watch('fondsIds'),
    });

    useEffect(() => {
        if (
            [
                DateRanges.LAST_DAY,
                DateRanges.LAST_3_DAYS,
                DateRanges.LAST_7_DAYS,
                DateRanges.LAST_MONTHS,
            ].includes(valueRange)
        ) {
            setValue('groupby', 'day');
        } else if (
            [
                DateRanges.LAST_3_MONTHS,
                DateRanges.LAST_6_MONTHS,
                DateRanges.LAST_2_YEAR,
                DateRanges.LAST_YEAR,
            ].includes(valueRange)
        ) {
            setValue('groupby', 'month');
        } else if (valueRange === DateRanges.LAST_5_YEAR) {
            setValue('groupby', 'year');
        }
    }, [valueRange]);

    // Debounce effect
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValues({
                dateStart: watch('dateStart'),
                dateEnd: watch('dateEnd'),
                groupby: watch('groupby'),
                fondsIds: watch('fondsIds'),
            });
        }, 500); // 1 second delay

        return () => {
            clearTimeout(handler);
        };
    }, [
        watch('dateStart'),
        watch('dateEnd'),
        watch('groupby'),
        watch('fondsIds'),
    ]);

    const {
        data: feeValuesfromdb,
        isLoading: isLoadingFeeValues,
        isFetching: isFetchingFeeValues,
    } = useGetFeesMetricsQuery(
        {
            dateStart: debouncedValues.dateStart
                ? new Date(debouncedValues.dateStart)?.toISOString()
                : new Date(date).toISOString(),
            dateEnd: debouncedValues.dateEnd
                ? new Date(debouncedValues.dateEnd)?.toISOString()
                : new Date(dateEnd).toISOString(),
            groupby: debouncedValues.groupby,
            fondsIds:
                debouncedValues?.fondsIds &&
                debouncedValues?.fondsIds?.length > 0
                    ? debouncedValues.fondsIds
                    : undefined,
        },
        {
            refetchOnMountOrArgChange: true,
            skip:
                !debouncedValues.dateStart ||
                !debouncedValues.dateEnd ||
                !debouncedValues.fondsIds,
        }
    );

    // Create a map to store feeId = 9 values by date and fondsId
    const feeId9Values = new Map<string, number>();
    feeValuesfromdb?.forEach((feeValue) => {
        if (feeValue.feeId === 9) {
            const key = `${feeValue.date.toString()}-${feeValue.fondsId}`;
            feeId9Values.set(key, feeValue.value);
        }
    });

    // Update values for feeId = 2 by subtracting feeId = 9 value of the same date and fondsId
    const feeValues = feeValuesfromdb?.map((feeValue) => {
        if (feeValue.feeId === 2) {
            const key = `${feeValue.date.toString()}-${feeValue.fondsId}`;
            const feeId9Value = feeId9Values.get(key);
            if (feeId9Value !== undefined) {
                // Create a deep copy of the feeValue object and modify its value
                return { ...feeValue, value: feeValue.value - feeId9Value };
            }
        }
        // Return the original object for all other cases
        return feeValue;
    });

    const feeValuesForCa =
        feeValues?.filter((fee) => [1, 2, 3, 10].includes(fee.feeId)) || [];

    const orderFee = [1, 10, 3, 2, 9, 5];

    return (
        <div className="grid pb-10 lg:grid-cols-2 gap-x-4 px-2">
            {/* <div className="lg:col-span-2">
                <ComputeAndStoreCashValues />
            </div> */}
            <div className="formTemplateSingUp w-full md:w-10/12 lg:w-2/3   lg:col-span-2">
                <div className="w-full my-4 gap-x-4 grid md:grid-cols-2">
                    {/* <InputComponent register={register} value="dateStart" type="date">
                <label className=" font-semibold !text-lg">Date Start</label>
              </InputComponent>
              <InputComponent register={register} value="dateEnd" type="date">
                <label className=" font-semibold !text-lg">Date End</label>
              </InputComponent> */}
                    <div className="w-full flex justify-center items-center col-span-2">
                        <DateRangePickerCustom label="Date Range" />
                    </div>
                    <div className="">
                        <SelectComponent
                            register={register}
                            control={control}
                            watch={watch}
                            value="groupby"
                            optionValues={[
                                { value: 'day', label: 'Day' },
                                { value: 'week', label: 'Week' },
                                { value: 'month', label: 'Month' },
                                { value: 'year', label: 'Year' },
                            ]}
                        >
                            <label className=" font-semibold !text-lg">
                                Group By
                            </label>
                        </SelectComponent>
                    </div>
                    <SelectMultiComponent
                        register={register}
                        setValue={setValue}
                        control={control}
                        watch={watch}
                        value="fondsIds"
                        optionsRender={(fond) => fond.label}
                        valueRender={(fond) => fond.value}
                        optionValues={
                            fonds?.map((fond) => ({
                                value: fond.id,
                                label: fond.name,
                            })) || []
                        }
                    >
                        <label className=" font-semibold !text-lg">Fonds</label>
                    </SelectMultiComponent>
                </div>
            </div>
            {isLoadingFeeValues || isFetchingFeeValues ? (
                <div className=" col-span-2">
                    <Loading />
                </div>
            ) : (
                <>
                    <div className=" col-span-2">
                        <GraphComponentPerFondsId
                            data={feeValuesForCa || []}
                            watch={watch}
                        />
                    </div>

                    {fonds?.map((fond) => (
                        <>
                            {feeValuesForCa?.filter(
                                (fee) => fee.fondsId === fond.id
                            )?.length > 0 ? (
                                <GraphComponentPerFondsId
                                    fondsId={fond.id}
                                    data={feeValuesForCa || []}
                                    watch={watch}
                                />
                            ) : null}
                        </>
                    ))}
                    {orderFee.map((i) => (
                        <>
                            {(feeValues || [])?.filter((fee) => fee.feeId === i)
                                ?.length > 0 ? (
                                <GraphComponentPerFeeId
                                    feeId={i}
                                    data={feeValues || []}
                                    watch={watch}
                                />
                            ) : null}
                        </>
                    ))}
                </>
            )}
        </div>
    );
};

export default MetricsFeeValue;

type PropsPerFeeId = {
    feeId: number;
    data: FeeValueDto[]; // Assuming you pass the entire dataset and filter it based on feeId
    watch: UseFormWatch<GetFeesMetricsApiArg>;
};

type PropsPerFondsId = {
    fondsId?: number;
    data: FeeValueDto[]; // Assuming you pass the entire dataset and filter it based on feeId
    watch: UseFormWatch<GetFeesMetricsApiArg>;
};

export interface DataSet {
    label: string;
    data: { x: string; y: number }[];
    borderColor: string;
    fill: boolean;
}

export interface DataSets {
    [fondsId: number]: DataSet;
}

export const GraphComponentPerFeeId: React.FC<PropsPerFeeId> = ({
    feeId,
    data,
    watch,
}) => {
    const [graphData, setGraphData] = useState<any>({
        datasets: [],
    });

    const filteredData = data.filter((d) => d.feeId === feeId);

    useEffect(() => {
        // Filter data based on feeId

        // Group data by fondsId and prepare datasets
        const datasets = filteredData.reduce(
            (acc: DataSets, currentValue, i) => {
                if (!acc[currentValue.fondsId]) {
                    acc[currentValue.fondsId] = {
                        label: `${currentValue?.fondsName}`,
                        data: [],
                        borderColor: fondsColor[i], // Implement this function to assign different colors
                        fill: false,
                    };
                }
                acc[currentValue.fondsId].data.push({
                    x:
                        watch('groupby') === 'day' ||
                        watch('groupby') === 'month'
                            ? formatDate(new Date(currentValue.date))
                            : (currentValue.date.toString() as any),
                    y: currentValue.value,
                });
                return acc;
            },
            {}
        );

        // Set the graph data
        setGraphData({
            datasets: Object.values(datasets),
        });
    }, [feeId, data]);

    return (
        <div key={feeId} className="">
            <h2 className=" bg-bgDashboardClient mr-1 pl-4 p-2 uppercase rounded-md mt-2 font-mainFontFamily text-2xl text-mainColor">
                {filteredData?.[0]?.feeName}
            </h2>

            <Line data={graphData} />
        </div>
    );
};

export const GraphComponentPerFondsId: React.FC<PropsPerFondsId> = ({
    fondsId,
    data,
    watch,
}) => {
    const [graphData, setGraphData] = useState<any>({
        datasets: [],
    });

    const filteredData =
        fondsId !== undefined
            ? data.filter((d) => d.fondsId === fondsId!)
            : data;

    function updateDataset(
        dataArray: any,
        date: any,
        value: any,
        feeId: number
    ) {
        const existingEntry = dataArray.find((entry: any) => entry.x === date);
        // if (feeId === 9) {
        //   value = value * -1;
        // }
        if (existingEntry) {
            existingEntry.y += value;
        } else {
            dataArray.push({ x: date, y: value });
        }
    }

    useEffect(() => {
        // Filter data based on feeId

        // Group data by fondsId and prepare datasets

        const datasets = filteredData.reduce(
            (acc: DataSets, currentValue, i) => {
                const formattedDate =
                    watch('groupby') === 'day' || watch('groupby') === 'month'
                        ? formatDate(new Date(currentValue.date))
                        : currentValue.date.toString();

                // Update individual feeId dataset
                if (!acc[currentValue.feeId]) {
                    acc[currentValue.feeId] = {
                        label: `${currentValue.feeName}`,
                        data: [],
                        borderColor: fondsColor[i], // Adjust this as per your color logic
                        fill: false,
                    };
                }
                updateDataset(
                    acc[currentValue.feeId].data,
                    formattedDate,
                    currentValue.value,
                    currentValue.feeId
                );

                // Update total dataset
                if (!(acc as any).totalForDate) {
                    (acc as any).totalForDate = {
                        label: 'Total',
                        data: [],
                        borderColor: 'rgba(0, 0, 0, 0.8)', // Color for total line
                        fill: false,
                    };
                }
                updateDataset(
                    (acc as any).totalForDate.data,
                    formattedDate,
                    currentValue.value,
                    currentValue.feeId
                );

                return acc;
            },
            {}
        );

        // Set the graph data
        setGraphData({
            datasets: Object.values(datasets),
        });
    }, [fondsId, data]);

    return (
        <div key={`${fondsId}__`} className="">
            <h2 className=" bg-bgDashboardClient mr-1 pl-4 p-2 uppercase rounded-md mt-2 font-mainFontFamily text-2xl text-mainColor">
                {fondsId
                    ? filteredData?.[0]?.fondsName
                    : 'Total over all fonds'}
            </h2>

            <Line data={graphData} />
        </div>
    );
};

// Helper function to generate random colors for graph lines
function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
