import React, { ReactNode, useCallback } from "react";
import {
  FieldErrorsImpl,
  FieldValues,
  Path,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>;
  watch: UseFormWatch<T>;
  errors?: FieldErrorsImpl<T>;
  required?: boolean;
  className?: {
    div?: string;
    input?: string;
    label?: string;
    container?: string;
  };
  name: Path<T>;
  values: {
    label: string;
    value: string | number;
  }[];
  cursorNotAllowed?: true;
}

const RadioComponent = <T extends object>(
  props: Props<T> & { children?: ReactNode }
) => {
  const jsxFunction = (): JSX.Element => {
    return (
      <>
        {props.values?.map((value, key) => (
          <div
            key={key}
            className={`checkbox_container form-item form-check ${props?.className?.div}`}
          >
            <input
              type="radio"
              value={value.value}
              id={`${props.name}_${key}`}
              // defaultChecked={props.watch(props.name) == value.value && true}
              checked={props.watch(props.name) == value.value && true}
              {...props.register(props.name, {
                required: props?.required === false ? false : true,
              })}
              required={props?.required === false ? false : true}
              className={`form-check-input ${props?.className?.input} ${
                props?.cursorNotAllowed && "pointer-events-none"
              } ${
                props.errors && props.errors[props.name as unknown as keyof T]
                  ? "is-invalid"
                  : ""
              }`}
            />
            <label
              className={`pointer ${props.className?.label}`}
              htmlFor={`${props.name}_${key}`}
            >
              {value.label}
            </label>
          </div>
        ))}
      </>
    );
  };
  return (
    <React.Fragment>
      {props.children}
      {props.cursorNotAllowed ? (
        <div
          className={`cursor-not-allowed w-full ${props?.className?.container}`}
        >
          {jsxFunction()}
        </div>
      ) : (
        <>
          {props?.className?.container ? (
            <div className={`${props?.className?.container}`}>
              {jsxFunction()}
            </div>
          ) : (
            <>{jsxFunction()}</>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default RadioComponent;
