import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    formatDate,
    isEmpty,
    lastDay,
    stringToFloat,
    transformDate,
} from '../../../function/Utils';

import {
    ProjectValorisationDto,
    UpdateProjectValorisationDto,
    useDeleteProjectSubSectorMutation,
    useDeleteProjectValorisationByIdMutation,
    useGetValorisationsProjectQuery,
    useUpdateProjectValorisationMutation,
} from '@api/api';
import { useNotificationContext } from '../../Context/notification-context';
import { LightBlueButtonSmall, PrimaryButton } from '../../commun/Buttons';
import ApexLineChart from '../../commun/Charts/ApexLineChart';
import { ISeries } from '../../commun/Charts/AreaChart';
import useDebouncedEffect from '../../commun/CustomHook/useDebouncedEffect';
import useDeleteItem from '../../commun/CustomHook/useDeleteItem';
import InputComponent from '../../commun/formComponent/InputComponent';

export function Valorisation({ projectId }: { projectId: number }) {
    const { showError, showSuccess } = useNotificationContext();

    const date = lastDay(new Date(Date.now()));

    const [searchDate, setSearchDate] = useState<Date>(date);
    const [searchDateDebouced, setSearchDateDebouced] = useState<Date>(date);

    const {
        data: projectsValorisationData,
        isLoading: isLoadingProjectsValorisation,
    } = useGetValorisationsProjectQuery(
        {
            pathProjectId: projectId,
            queryProjectId: projectId,
            date: searchDateDebouced
                ? new Date(searchDateDebouced)?.toISOString()
                : undefined,
        },
        {
            skip: projectId === undefined,
        }
    );

    useDebouncedEffect(
        () => {
            setSearchDateDebouced(searchDate);
        },
        [searchDate],
        400
    );

    const [deleteProjectValorisation, { isLoading: isLoadingDelete }] =
        useDeleteProjectSubSectorMutation();

    const handleDelete = async () => {
        const bol = window.confirm(
            'Voulez vous vraiment supprimer toutes les valorisations ?'
        );
        if (bol) {
            await deleteProjectValorisation(projectId!)
                .unwrap()
                .then((res) => {
                    showSuccess(
                        'Deleted',
                        'Project valorisations deleted successfully'
                    );
                })
                .catch((err) => {
                    showError('Error', 'Error deleting project valorisations');
                });
        }
    };

    return (
        <React.Fragment>
            <>
                <ValorisationChart
                    valorisations={projectsValorisationData || []}
                />
                {projectId ? (
                    <div className="flex w-11/12 mx-auto justify-center items-center">
                        <LightBlueButtonSmall
                            onClick={() => handleDelete()}
                            className="my-3 text-[1rem] py-3"
                            // type={"button"}
                        >
                            Supprimer toutes les valorisations
                        </LightBlueButtonSmall>
                    </div>
                ) : null}
                <div className="flex gap-3 items-center justify-center">
                    <LabelComponentForm>
                        Selectionner une date:{' '}
                    </LabelComponentForm>
                    <input
                        type="date"
                        value={new Date(searchDate).toJSON()?.split('T')[0]}
                        onChange={(e) => setSearchDate(e.target.value as any)}
                        className="border-2 border-gray-300 rounded-md p-2"
                    />
                </div>
                <div className="w-full md:w-11/12 mx-auto">
                    <div className="grid md:grid-cols-3">
                        <LabelComponentForm>Date</LabelComponentForm>
                        <LabelComponentForm className=" md:col-span-2">
                            Valorisation <span className="required">*</span>
                        </LabelComponentForm>
                    </div>
                    <>
                        {projectsValorisationData &&
                        projectsValorisationData?.length > 0 ? (
                            <>
                                {projectsValorisationData &&
                                    projectsValorisationData?.map(
                                        (projectValorisation) => (
                                            <EditProjectValorisation
                                                key={projectValorisation.id}
                                                projectValorisation={
                                                    projectValorisation
                                                }
                                            />
                                        )
                                    )}
                            </>
                        ) : (
                            <p>
                                Aucune valorisation n'est stocké pour ce projet
                                où à cette date
                            </p>
                        )}
                    </>
                </div>
            </>
        </React.Fragment>
    );
}

function ValorisationChart({
    valorisations,
}: {
    valorisations: ProjectValorisationDto[];
}) {
    const series: ISeries[] = useMemo(() => {
        if (valorisations.length === 0) return [];
        return [
            {
                name: 'Valorisation €',
                data: valorisations.map((v) => ({
                    x: new Date(v.date).getTime(),
                    y: v.valorisation,
                })),
            },
        ];
    }, [valorisations]);

    return (
        <div className=" w-full md:w-11/12 mx-auto my-3 ">
            <ApexLineChart series={series} height={300} id="ValoProject" />
        </div>
    );
}

function EditProjectValorisation({
    projectValorisation,
}: {
    projectValorisation: ProjectValorisationDto;
}): JSX.Element {
    const [editProjectsValorisation, { isLoading: isLoadingEdit }] =
        useUpdateProjectValorisationMutation();

    const [deleteProjectValorisation, { isLoading: isLoadingDelete }] =
        useDeleteProjectValorisationByIdMutation();
    const { showError, showSuccess } = useNotificationContext();

    const { register, handleSubmit, reset, getValues, setValue, watch } =
        useForm<UpdateProjectValorisationDto>();

    const onSubmit = async (data: UpdateProjectValorisationDto) => {
        await editProjectsValorisation({
            updateProjectValorisationDto: {
                ...data,
                comment:
                    data.comment == undefined || data.comment == ''
                        ? 'modifié'
                        : data.comment,
                date: transformDate(data.date)?.toISOString(),
                valorisation: data.valorisation
                    ? stringToFloat(data.valorisation)
                    : undefined,
            },
            id: projectValorisation.id,
        })
            .unwrap()
            .then((res) => {
                showSuccess('Edited', 'Project editing successfully');
            })
            .catch((err) => {
                showError('Error', 'Error editing project');
            });
    };

    useEffect(() => {
        if (!isEmpty(projectValorisation)) {
            reset({
                ...projectValorisation,
                date: new Date(projectValorisation?.date!)
                    .toJSON()
                    ?.split('T')[0],
                valorisation: parseFloat(
                    projectValorisation?.valorisation?.toFixed(2)
                ),
            });
        }
    }, [projectValorisation]);

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        projectValorisationId: number;
    }>({
        deleteItem: () => deleteProjectValorisation(projectValorisation.id),
        message: 'valorisation',

        isLoading: isLoadingDelete,
    });

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid md:grid-cols-3 border-t-2 items-center"
        >
            <DeleteComponent title="cette valorisation" />

            <div className="">
                <p>
                    {getValues('date')
                        ? formatDate(new Date(getValues('date')!))
                        : ''}
                </p>
            </div>
            <InputComponent
                register={register}
                value={'valorisation'}
                type={'valorisation'}
                className={{
                    input: '',
                }}
            ></InputComponent>
            <div className="flex justify-center items-center gap-3 ">
                <PrimaryButton type="submit" loading={isLoadingEdit}>
                    Editer{' '}
                </PrimaryButton>
                <PrimaryButton
                    loading={isLoadingDelete}
                    onClick={() => setOpenDelete(true)}
                >
                    Delete{' '}
                </PrimaryButton>
            </div>
        </form>
    );
}
