import {
    TypeTransaction,
    useFetchTokenQuery,
    useGetTransactionsUserQuery,
} from '@api/api';
import {
    faChartLine,
    faLandmark,
    faMoneyBillTrendUp,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { ComputationUserDashboard } from '../../../function/ComputationUserDashboard';
import {
    compareTwoDate,
    formatDate,
    lastDay,
    printLargeValue,
} from '../../../function/Utils';

import Box from '../../commun/Box';

interface Props {
    computationObject: ComputationUserDashboard;
}

const Boxes: React.FC<Props> = ({ computationObject }) => {
    const { data: user } = useFetchTokenQuery();
    const { data: transactions } = useGetTransactionsUserQuery(user?.id ?? 0, {
        skip: !user,
    });

    return (
        <React.Fragment>
            {computationObject &&
            computationObject?.lastDateComputed &&
            new Date(computationObject.lastDateComputed).getTime() > 0 ? (
                <div className="block sm:absolute sm:top-[-40px] left-8 border rounded-sm bg-secondBackgroundColor px-2 py-1 font-titleFontFamily">
                    Informations calculées :{' '}
                    {formatDate(
                        computationObject.lastDateComputed ||
                            lastDay(new Date(Date.now()))
                    )}
                </div>
            ) : null}

            <Box
                value={printLargeValue(
                    computationObject
                        ?.getTotalAmountUser(
                            computationObject.lastDateComputed ??
                                lastDay(new Date(Date.now()))
                        )

                        .toFixed(2)
                )}
                text="Montant total"
                sigle="€"
                icon={faChartLine}
            />
            <Box
                value={computationObject?.getNbFundsInvestedByUser().toString()}
                text="Supports souscrits"
                icon={faLandmark}
            />
            <Box
                value={(
                    computationObject?.getTri(
                        computationObject.lastDateComputed ??
                            lastDay(new Date(Date.now()))
                    ) || 0
                )
                    .toFixed(2)
                    .toString()}
                text="TRI*"
                sigle="%"
                comment="*Taux de rendement interne"
                icon={faChartLine}
            />
            <Box
                value={printLargeValue(
                    (
                        computationObject?.getMoneyEarned(
                            computationObject.lastDateComputed ??
                                lastDay(new Date(Date.now()))
                        ) +
                            (transactions ?? [])
                                ?.filter(
                                    (t) =>
                                        t.type === TypeTransaction.cashUser &&
                                        compareTwoDate(
                                            new Date(t.date),
                                            lastDay(new Date(Date.now()))
                                        )
                                )
                                ?.reduce((acc, t) => acc + t.amount, 0) || 0
                    )
                        .toFixed(2)
                        .toString()
                )}
                text="Capital généré"
                sigle="€"
                icon={faMoneyBillTrendUp}
            />
        </React.Fragment>
    );
};

export default Boxes;
