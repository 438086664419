import {
    TransactionStatus,
    TypeTransaction,
    UpdateIssuedProjectDto,
    useGetProjectByIdQuery,
    useGetTransactionsProjectQuery,
    useUpdateIssuedProjectMutation,
} from '@api/api';
import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import { getInterest, numberDayBetweenDate, transformDate } from '@utils/Utils';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from '../../../commun/Buttons';
import InputComponent from '../../../commun/formComponent/InputComponent';
import PopUp from '../../../commun/PopUp';
import { useNotificationContext } from '../../../Context/notification-context';

function FormIssued({
    open,
    setOpen,
    projectId,
    refetchValo,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    projectId: number;
    refetchValo: any;
}) {
    const { showError, showSuccess } = useNotificationContext();

    const { register, handleSubmit, reset, getValues, watch, setValue } =
        useForm<UpdateIssuedProjectDto>({
            defaultValues: {
                date: transformDate(new Date(Date.now()))
                    .toJSON()
                    .split('T')[0],
                dateInterest: transformDate(new Date(Date.now()))
                    .toJSON()
                    .split('T')[0],
                projectId: projectId,
            },
        });

    const { data: project, isLoading } = useGetProjectByIdQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    const [editIssuedProject, { isLoading: isEditIssuedProjectLoading }] =
        useUpdateIssuedProjectMutation();

    const onSubmit = async (data: UpdateIssuedProjectDto) => {
        await editIssuedProject({
            updateIssuedProjectDto: {
                ...data,
                projectId: projectId,
                date: new Date(data.date)?.toISOString(),
                fondsId: project?.fondsId!,
                valorisation: parseFloat(data.valorisation.toString()),
                dateInterest: new Date(data.dateInterest)?.toISOString(),
            },
            id: projectId,
        })
            .unwrap()
            .then(async () => {
                await refetch();
                await refetchValo();
                showSuccess('Edited', 'Project issued edited successfully');
                setOpen(false);
            })
            .catch((err) => {
                showError('Error', 'Error editing issued project');
            });
    };

    const { data: transactions, refetch } = useGetTransactionsProjectQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    const valo = useMemo((): number => {
        if (project && transactions) {
            const nbDays = numberDayBetweenDate(
                transformDate(getValues('dateInterest')),
                transformDate(new Date(watch('date')))
            );
            const interest = getInterest(
                project.interests,
                transformDate(getValues('dateInterest'))
            );
            const initialAmount =
                transactions
                    ?.filter(
                        (t) =>
                            t.type === TypeTransaction.cashProject &&
                            t.status === TransactionStatus.VALIDATED
                    )
                    ?.reduce((sum, curr) => curr.amount + sum, 0) ?? 0;
            return (
                initialAmount +
                (((initialAmount * interest) / 100) * nbDays) / 365
            );
        } else {
            return 0;
        }
    }, [watch('dateInterest'), watch('date'), project, transactions]);

    useEffect(() => {
        setValue('valorisation', valo);
    }, [watch('dateInterest'), project, transactions]);

    return (
        <>
            <PopUp
                open={open}
                setOpen={setOpen}
                title={() => {
                    return (
                        <h3 className="text-2xl font-medium leading-6 text-gray-900">
                            Jouissance du projet
                        </h3>
                    );
                }}
                buttonBoolean={false}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formTemplateSingUp"
                >
                    <InputComponent
                        register={register}
                        value="date"
                        type="date"
                        commentaire={"Normalement ça doit être aujourd'hui"}
                    >
                        <LabelComponentForm>
                            Date modification de la valorisation
                            <span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>
                    <InputComponent
                        register={register}
                        value="dateInterest"
                        type="date"
                    >
                        <LabelComponentForm>
                            Date jouissance des intérêts
                            <span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>

                    <InputComponent
                        register={register}
                        value={'valorisation'}
                        type={'number'}
                        className={{
                            input: 'border-2 border-gray-300 p-2 rounded-lg',
                        }}
                    >
                        <LabelComponentForm>
                            Valorisation <span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>
                    <div className="flex justify-center items-center mt-3 w-full mx-auto">
                        <PrimaryButton
                            type="submit"
                            className="w-fit"
                            loading={isEditIssuedProjectLoading}
                        >
                            Valider
                        </PrimaryButton>
                    </div>
                </form>
            </PopUp>
        </>
    );
}

export default FormIssued;
