import {
    useFetchTokenQuery,
    useGetPerformanceIndexForUserQuery,
} from '@api/api';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetAllFondsQuery } from '../../../redux/features/fondsSlice';
import { useAppSelector } from '../../../redux/store';
import AreaChart, { ISeries } from '../../commun/Charts/AreaChart';
import Spinner from '../../commun/Spinner';

const ShareChart = () => {
    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

    const { data: shareData, isLoading } = useGetPerformanceIndexForUserQuery(
        user?.id ?? 0,
        {
            skip: !user,
        }
    );

    const pageState = useAppSelector(
        (state) => state.pageMeasurement.measurements
    );

    const { data: fondsData } = useGetAllFondsQuery();

    const color: string[] = [
        '#353D40',
        '#0A9155',
        '#F2B138',
        '#096742',
        '#1b96ae',
    ];

    const [series, setSeries] = useState<ISeries[]>([]);

    useEffect(() => {
        if (
            shareData &&
            ((shareData.sharesC && shareData?.sharesC?.length > 0) ||
                (shareData?.sharesD && shareData?.sharesD?.length)) &&
            fondsData &&
            fondsData?.length > 0
        ) {
            const series_: ISeries[] = [];

            if (shareData.sharesC && shareData?.sharesC?.length > 0) {
                for (const val of shareData?.sharesC) {
                    const fonds = fondsData.find((f) => f.id === val.fondsId);
                    if (fonds) {
                        const data = val.data.map((d) => {
                            return {
                                x: new Date(d.date).getTime(),
                                y: d.share,
                            };
                        });
                        series_.push({
                            name: `${fonds.name} titre C`,
                            data: data,
                        });
                    }
                }
            }
            if (shareData.sharesD && shareData?.sharesD?.length > 0) {
                for (const val of shareData?.sharesD) {
                    const fonds = fondsData.find((f) => f.id === val.fondsId);
                    if (fonds) {
                        const data = val.data.map((d) => {
                            return {
                                x: new Date(d.date).getTime(),
                                y: d.share,
                            };
                        });
                        series_.push({
                            name: `${fonds.name} titre D`,
                            data: data,
                        });
                    }
                }
            }
            setSeries(series_);
        }
    }, [shareData, fondsData]);

    const area = useMemo(() => {
        if (shareData?.firstDate && shareData?.lastDate) {
            return {
                dateStart: Math.max(
                    new Date(shareData.firstDate).getTime(),
                    new Date(shareData.lastDate).getTime() -
                        1000 * 3600 * 24 * 365
                ),
                dateEnd: new Date(shareData.lastDate).getTime(),
            };
        } else {
            return undefined;
        }
    }, [shareData]);

    return (
        <React.Fragment>
            <h2 className="text-center text-2xl mb-5">
                Indice de performance *
            </h2>
            {/* <canvas
        id="myChart3"
        // style={{ minWidth: "400px", minHeight: "200px" }}
      ></canvas> */}
            {isLoading ? (
                <Spinner />
            ) : (
                <AreaChart
                    series={series}
                    start={12}
                    area={area}
                    historic={true}
                />
            )}
        </React.Fragment>
    );
};

export default ShareChart;
