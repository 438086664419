import React from 'react';

interface Props {
    children: React.ReactNode;
    className?: string;
}

const BigTitleComponent: React.FC<Props> = ({ children, className }) => {
    return (
        <div
            className={` uppercase text-xl md:text-3xl border-y-2 py-2 text-mainColor text-center ${className ? className : ''}`}
        >
            {children}
        </div>
    );
};

export default BigTitleComponent;
