import { IStep } from "../../commun/StepComponent";

interface Props {
  steps: IStep[];
  current: number;
}

const StepSimpleComponent: React.FC<Props> = ({ steps, current }) => {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step, stepIdx) => (
          <li key={stepIdx} className="md:flex-1">
            {stepIdx < current ? (
              <p className="group flex flex-col border-l-4 border-secondColor py-2 pl-4 hover:border-mainColor md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span className="text-sm font-medium text-secondColor group-hover:text-mainColor">
                  {step.name}
                </span>
                <span className="text-sm font-medium">{step.description}</span>
              </p>
            ) : stepIdx === current ? (
              <p
                className="flex flex-col border-l-4 border-secondColor py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                aria-current="step"
              >
                <span className="text-sm font-medium text-secondColor">
                  {step.name}
                </span>
                <span className="text-sm font-medium">{step.description}</span>
              </p>
            ) : (
              <p className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                  {step.name}
                </span>
                <span className="text-sm font-medium">{step.description}</span>
              </p>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default StepSimpleComponent;
