import { AllowUpdateRequest, useAllowSecurityUpdateMutation } from '@api/api';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../../commun/Buttons';
import logo from './../../../assets/logo.png';

const LoginDashBoardAdmin = () => {
    const [content, setContent] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [viewPassword, setViewPassword] = useState<boolean>(false);

    const [login, { isLoading }] = useAllowSecurityUpdateMutation();

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data: AllowUpdateRequest = {
            name: 'updateBackOffice',
            content: content,
        };
        await login(data)
            .unwrap()
            .then((value) => {
                setMessage(value.message);

                if (value.message === 'success') {
                    window.location.href = '/DashBoardAdmin/BackOffice';
                }
                setContent('');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="h-screen flex flex-col justify-center items-center mx-auto">
            <div className="md:-mt-24">
                <Link to="/" className="my-8 h-14 w-14 block mx-auto">
                    <img src={logo} alt="logo Newparadigms" />
                </Link>
                <form
                    className=" formTemplateSingUp p-5 flex flex-col justify-center items-center"
                    onSubmit={(e) => onSubmit(e)}
                >
                    <div
                        className={`${
                            message === 'success'
                                ? 'text-green-600'
                                : 'text-red-600'
                        }`}
                    >
                        {message}
                    </div>
                    <div className="form-item">
                        <label>Password:</label>
                        <div className="relative w-full">
                            <input
                                type={viewPassword ? 'text' : 'password'}
                                onChange={(event) => {
                                    setContent(event.target.value);
                                }}
                                name="password"
                                className="form-control border border-grey"
                            />
                            <FontAwesomeIcon
                                icon={viewPassword ? faEye : faEyeSlash}
                                className="absolute -right-8 top-0 bottom-0 my-auto cursor-pointer"
                                onClick={() => setViewPassword(!viewPassword)}
                            />
                        </div>
                    </div>
                    <div className="w-fit mt-2 justify-center items-center mx-auto">
                        <PrimaryButton
                            className=""
                            loading={isLoading}
                            type="submit"
                        >
                            Se connecter
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginDashBoardAdmin;
