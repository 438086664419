import { QualiteInterface } from '@data/qualiteList';
import React, { useEffect } from 'react';
import Page from '../../function/page_info';
import { useAppSelector } from '../../redux/store';

interface Props {
    qualiteObject: QualiteInterface;
    keyProp: number;
}

const QualiteItems: React.FC<Props> = ({ qualiteObject, keyProp }) => {
    const pageData = useAppSelector(
        (state) => state.pageMeasurement.measurements
    );

    useEffect(() => {
        if (keyProp % 2 === 1) {
            Page.scrollFunction(qualiteObject.id, 'apparitionImg', true);
        }
    }, [pageData.scrollPage]);
    return (
        <div className="qualite_container">
            <div className="qualite_texte">
                <div className="qualite">
                    <h3>{qualiteObject.name}</h3>
                    <h4>{qualiteObject.sous_titre}</h4>

                    {qualiteObject?.qualites.map((value) => (
                        <p key={value}>{value}</p>
                    ))}
                </div>
            </div>
            <div
                className="img"
                id={qualiteObject.id}
                style={{ backgroundImage: `url(${qualiteObject.cover})` }}
            ></div>
        </div>
    );
};

export default QualiteItems;
