import { api } from '@api/api';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import articleReducer from './features/article';
import pageReducer, { getMeasures } from './features/page';

export const store = configureStore({
    reducer: {
        pageMeasurement: pageReducer,
        articleReducer: articleReducer,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(api.middleware),
    devTools: import.meta.env.VITE_ENV === 'development',
    // devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

window.addEventListener('resize', function () {
    store.dispatch(getMeasures());
});
window.addEventListener('scroll', function () {
    store.dispatch(getMeasures());
});
