import { formatInTimeZone } from 'date-fns-tz';
import React, { useContext, useState } from 'react';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { formatDate } from '../../../function/Utils';

import {
    TransactionDto,
    TransactionStatus,
    useGetTransactionsOutShareDQuery,
} from '@api/api';
import { typeFiltration } from '@interfaces/BackOffice';
import usePaginationTable from '../../commun/CustomHook/usePaginationTable';
import { useQueryParamCustom } from '../../commun/CustomHook/useQueryParamCustom';
import { DropDownComponentAdmin } from '../../commun/DropDownComponent';
import ExportComponent from '../../commun/ExportComponent';
import Loading from '../../commun/Loading';
import StatusTransaction from '../../commun/StatusTransaction';
import { MenuDashBoardAdminBoolState } from '../../Context/AppContext';
import WrapComponentDashboardUser from '../../DashboardUser/commun/WrapComponentDashboardUser';
import FiltrationColumnTable, {
    typeOrderOrFiltration,
} from '../BackOffice/Components/FiltrationColumnTable';
import GraphFutureTransaction from './GraphFutureTransaction';
import ManageTransactionOutShareD from './ManageTransactionOutShareD';

const ShareDIndex = () => {
    const menuBoolState = useContext(MenuDashBoardAdminBoolState);

    const [open, setOpen] = useState(false);
    const [graphDropDown, setGraphDropDown] = useState(false);

    return (
        <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
            <WrapComponentDashboardUser
                title="Gestion des titres D"
                description=""
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
            >
                {open ? (
                    <ManageTransactionOutShareD
                        setEditToggle={setOpen}
                        editToggle={open}
                    />
                ) : null}
                <div className=" relative">
                    <div className="w-full md:w-11/12 mx-auto mt-[30px]">
                        <DropDownComponentAdmin
                            bool={graphDropDown}
                            setBool={setGraphDropDown}
                            title="Future intérêts à distribuer"
                        >
                            <GraphFutureTransaction />
                        </DropDownComponentAdmin>
                    </div>
                    <TransactionTable setOpen={setOpen} />
                </div>
            </WrapComponentDashboardUser>
        </div>
    );
};

export default ShareDIndex;

function TransactionTable({
    setOpen,
}: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold text-left',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap  text-right',
    };

    const [investisorName, setInvestissorName] = useQueryParam(
        'investisorName',
        StringParam
    );

    const [fondsQuery, setFondsQuery] = useQueryParamCustom(
        'fondsId',
        NumberParam,
        1
    );
    const [statusQuery, setStatusQuery] = useQueryParam('status', StringParam);

    const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
    const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);

    const { data: manageShareDData, isLoading: isLoadingTransactions } =
        useGetTransactionsOutShareDQuery(
            {
                fondsId: fondsQuery || 0,
                limit: nbItemsShowed,
                offset: startIntervalNbItems,
                investisor: investisorName || '',
                status: statusQuery || '',
            },
            { skip: fondsQuery === 0 || fondsQuery === undefined }
        );

    const transactions = manageShareDData?.transactions;
    const nbItems = manageShareDData?.nbTransactions;

    const { ComponentFilter, dataFiltered, setFilterColumn } =
        usePaginationTable<TransactionDto>({
            initialOrder: 'date',
            data: transactions || [],
            nbItemsShowed: nbItemsShowed,
            startIntervalNbItems: startIntervalNbItems,
            setNbItemsShowed: setNbItemsShowed,
            setStartIntervalNbItems: setStartIntervalNbItems,
        });

    return (
        <div className="mt-4 relative">
            <ComponentFilter offset={true} nbItems={nbItems ?? 0}>
                <div className="relative md:absolute md:top-[-8px] md:right-3">
                    <ExportComponent
                        headers={['UserName', 'amount', 'Status', 'date']}
                        title="Parrainage"
                        data={dataFiltered.map((t) => {
                            return {
                                UserName: t.userNameTo,
                                amount: t.amount,
                                Status: t.status,
                                date: formatDate(new Date(t.date)),
                            };
                        })}
                    />
                </div>
            </ComponentFilter>

            <div className="flex flex-col max-w-full overflow-x-auto">
                <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
                    <div className="py-2 inline-block min-w-full">
                        {/* <p>
    Valeur total du fonds{" "}
    {printLargeValue(
      projectValorisationInfoData?.valorisationByProjects?.toFixed(
        2
      )
    )}
  </p> */}
                        <div className="overflow-auto">
                            <table className="table-auto overflow-scroll w-full">
                                <thead className="bg-white border-b border-t">
                                    <tr>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            #
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Fonds
                                        </th>
                                        <FiltrationColumnTable
                                            element={'to'}
                                            type={typeFiltration.name}
                                            setFilterColumn={setFilterColumn}
                                            setSearch={setInvestissorName}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Investisor
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.filter
                                            }
                                            textLeft={true}
                                        />
                                        <FiltrationColumnTable
                                            element={'amount'}
                                            type={typeFiltration.amount}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Montant
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.order
                                            }
                                            textLeft={false}
                                        />

                                        <FiltrationColumnTable
                                            element={'date'}
                                            type={typeFiltration.date}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Date
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.order
                                            }
                                            textLeft={false}
                                        />
                                        <FiltrationColumnTable
                                            element={'status'}
                                            setSearch={setStatusQuery}
                                            search={statusQuery}
                                            type={typeFiltration.exact}
                                            setFilterColumn={setFilterColumn}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.filter
                                            }
                                            value={['all'].concat(
                                                Object.values(
                                                    TransactionStatus
                                                ).map((v) => v)
                                            )}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Status
                                                </p>
                                            )}
                                            textLeft={false}
                                        />
                                    </tr>
                                </thead>
                                <tbody className="bg-white ">
                                    {isLoadingTransactions ? (
                                        <tr className="">
                                            <td
                                                colSpan={6}
                                                className="pt-10 text-center mx-auto"
                                            >
                                                <Loading />
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {dataFiltered && (
                                                <>
                                                    {dataFiltered.length ===
                                                    0 ? (
                                                        <tr className="w-full mx-auto">
                                                            <td
                                                                colSpan={6}
                                                                className="text-center text-gray-500 text-xl  pt-10"
                                                            >
                                                                Aucune
                                                                transaction
                                                                touvée
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <React.Fragment>
                                                            {dataFiltered.map(
                                                                (t, key) => (
                                                                    <RowElement
                                                                        t={t}
                                                                        key={
                                                                            key
                                                                        }
                                                                        number={
                                                                            key
                                                                        }
                                                                        setOpen={
                                                                            setOpen
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function RowElement({
    t,
    number,
    setOpen,
}: {
    t: TransactionDto;
    number: number;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const classNameObejct = {
        item: 'text-sm  font-light px-6 py-4 whitespace-nowrap',
    };

    const [transactionId, setTransactionId] = useQueryParam(
        'transactionId',
        NumberParam
    );

    //   const {data: transaction, isLoading: isLoadingTransaction} = useGetTransactionByIdQuery(transactionId || 0,{
    //     skip: transactionId === 0 || transactionId === undefined
    //   })

    return (
        <tr
            onClick={() => {
                setTransactionId(t.id);
                setOpen(true);
            }}
            className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                number % 2 === 0 ? 'bg-secondBackgroundColor' : 'bg-white '
            } `}
        >
            <td className={`${classNameObejct.item}`}>{number + 1}</td>
            <td className={`${classNameObejct.item}`}>{t.fondsName}</td>
            <td className={`${classNameObejct.item}`}>{t.userNameTo}</td>
            <td className={`${classNameObejct.item}`}>{t.amount}</td>
            <td className={`${classNameObejct.item} text-center `}>
                {formatInTimeZone(
                    new Date(t.date),
                    'Europe/Paris',
                    'dd/MM/yyyy'
                )}
            </td>
            <td className={`${classNameObejct.item} text-center `}>
                <StatusTransaction status={t.status} />
            </td>
        </tr>
    );
}
