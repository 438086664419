import React, { useEffect, useMemo } from 'react';

import { PrimaryButton } from '@components/commun/Buttons';
import FieldArray from '@components/commun/formComponent/FieldArray';
import {
    displayTitle,
    FormQuestionInterface,
    FormQuestionItemInterface,
} from '@components/DashboardUser/Profile/FormInvest/CommunComponent/DisplayCategoryQuestion';
import { DisplayQuestion } from '@components/DashboardUser/Profile/FormInvest/CommunComponent/DisplayQuestion';
import { MetricPercentDisplay } from '@components/DashboardUser/Profile/FormInvest/FormulaireEnd';

import {
    CategoryQuestion,
    KnowledgeQuestionDto,
    useFetchTokenQuery,
    useGetMetricKnowledgeUserQuery,
    useGetUserWithAllInfoForCustomerQuery,
} from '@api/api';
import { useForm } from 'react-hook-form';

interface Props {
    questions: KnowledgeQuestionDto[];
    category: CategoryQuestion;
    onValidate: () => void;
}

const DisplayGoodAnswerQuestion: React.FC<Props> = ({
    questions,
    category,
    onValidate,
}) => {
    const { data: userData } = useFetchTokenQuery();
    const { data: user } = useGetUserWithAllInfoForCustomerQuery(
        userData?.id ?? 0,
        {
            skip: !userData,
        }
    );

    const {
        data: metricKnowledgeUser,
        isLoading: isLoadingMetricKnowledgeUser,
    } = useGetMetricKnowledgeUserQuery(userData?.id ?? 0, {
        skip: !userData,
    });

    const formState = useForm<FormQuestionInterface>({});

    const {
        register,
        handleSubmit,
        watch,
        reset,
        getValues,
        setValue,
        control,
        formState: { isValid, isDirty },
    } = formState;

    useEffect(() => {
        if (questions && questions?.length > 0 && user) {
            const data: FormQuestionItemInterface[] = questions.map(
                (question) => {
                    const answer = user?.knowledgeUsers?.find(
                        (k) => k.question.id === question.id
                    )?.answers;

                    return {
                        questionId: question.id,
                        question: question,
                        answerIds: answer?.map((a) => a.id) ?? [],
                        answer: answer?.[0]?.answer ?? undefined,
                        answerId: answer?.[0]?.id,
                    };
                }
            );
            reset({ value: data });
        }
    }, [questions, user, category]);

    const disabled = useMemo(() => {
        // return true if all good answers are selected
        let disabled = false;
        questions.forEach((question, index) => {
            const goodAnswersIds = question.answers
                .filter((a) => a.goodAnswer)
                .map((a) => a.id);
            let userAnswersIds: number[] | number | undefined = watch(
                'value'
            )?.find((ua) => ua.question.id === question.id)?.answerIds;

            if (userAnswersIds === undefined) return true;

            if (typeof userAnswersIds === 'string') {
                userAnswersIds = [parseInt(userAnswersIds)];
            }

            // if array
            if (Array.isArray(userAnswersIds)) {
                userAnswersIds = userAnswersIds.map((id) => {
                    return typeof id === 'string' ? parseInt(id) : id;
                });
            }

            // if all the good answers are selected
            if (
                !(
                    goodAnswersIds.every((id) =>
                        userAnswersIds?.includes(id)
                    ) && goodAnswersIds.length === userAnswersIds.length
                )
            ) {
                disabled = true;
            }
        });

        return disabled;
    }, [watch()]);

    return (
        <div>
            <div className="!text-center font-semibold text-mainColor font-mainFontFamily !text-4xl mb-10 w-full">
                {displayTitle(category)}
            </div>
            <div className="mt-0 mb-6 px-2 w-full text-justify md:w-9/12 md:mx-auto">
                <p>
                    Voici le résultat de votre questionnaire. Vos bonnes
                    réponses sont affichées en vert, et les mauvaises en rouge.
                </p>
                <div className="my-8">
                    <h4 className="mt-5 text-center font-mainFontFamily text-xl">
                        Voici les resultats de vos connaissances
                    </h4>

                    <dl className="mt-5 w-full md:max-w-md mx-auto grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center">
                        {metricKnowledgeUser?.resultsPercent?.map(
                            (metric, index) => (
                                <div
                                    key={index}
                                    className="flex flex-col bg-gray-600/5 p-8"
                                >
                                    <MetricPercentDisplay data={metric} />
                                </div>
                            )
                        )}
                    </dl>
                </div>
                <p className="mt-3">
                    Pour vous assurer que nos solutions répondent à vos
                    attentes, il est dans votre intérêt de bien comprendre notre
                    fonctionnement.{' '}
                    <span className=" font-semibold">
                        Prenez le temps de lire attentivement et de corriger vos
                        réponses si nécessaire, avant de passer à l'étape
                        suivante.
                    </span>
                </p>
            </div>

            <FieldArray
                control={control}
                name="value"
                className="flex flex-col gap-3 mx-auto w-full md:w-11/12 lg:w-9/12"
            >
                {({ fields }) => (
                    <>
                        {fields.map((field, index) => (
                            <div className="mx-auto w-full" key={field.id}>
                                <DisplayQuestion
                                    control={control}
                                    register={register}
                                    setValue={setValue}
                                    formState={formState}
                                    watch={watch}
                                    showGoodAnswer={true}
                                    key={index}
                                    id={field.id}
                                    question={
                                        getValues(
                                            `value.${index}.question`
                                        ) as unknown as KnowledgeQuestionDto
                                    }
                                    index={index}
                                >
                                    <h3 className=" mt-4 text-start text-xl font-bold !font-mainFontFamily !mb-4">
                                        {
                                            (
                                                getValues(
                                                    `value.${index}.question`
                                                ) as unknown as KnowledgeQuestionDto
                                            ).question
                                        }
                                    </h3>
                                    {(
                                        getValues(
                                            `value.${index}.question`
                                        ) as unknown as KnowledgeQuestionDto
                                    ).comment ? (
                                        <p className="mb-3">
                                            {
                                                (
                                                    getValues(
                                                        `value.${index}.question`
                                                    ) as unknown as KnowledgeQuestionDto
                                                ).comment
                                            }
                                        </p>
                                    ) : null}
                                </DisplayQuestion>
                            </div>
                        ))}
                    </>
                )}
            </FieldArray>

            <div className="flex justify-center items-center flex-col gap-1 my-4">
                <PrimaryButton
                    onClick={onValidate}
                    className="w-fit"
                    disabled={disabled}
                >
                    Continuer
                </PrimaryButton>
                {disabled ? (
                    <p className="text-red-500 text-md italic my-2">
                        Veuillez sélectionner les bonnes réponses
                    </p>
                ) : null}
            </div>
        </div>
    );
};

export default DisplayGoodAnswerQuestion;
