import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import CookiesComponent from '../commun/CookiesComponent';
import PopUp from '../commun/PopUp';

const PolitiqueDonneesPerso = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [cookieConsent, setCookieConsent] = useState<string | null>(
        localStorage.getItem('accepterCookies')
    );
    const [toggleCookie, setToggleCookie] = useState<boolean>(
        localStorage.getItem('accepterCookies') ? false : true
    );

    const editCookieConsent = (): void => {
        Cookies.set('accepterCookies', 'null');
        setCookieConsent('null');
        setToggleCookie(true);
    };

    return (
        <React.Fragment>
            {toggleCookie ? (
                <PopUp
                    open={toggleCookie}
                    setOpen={setToggleCookie}
                    title={() => {
                        return <h2></h2>;
                    }}
                    disabledOnclose={true}
                    buttonBoolean={false}
                >
                    <CookiesComponent
                        setCookieConsent={setCookieConsent}
                        setToggleCookie={setToggleCookie}
                    />
                </PopUp>
            ) : null}
            {/* {(cookieConsent == "null" || cookieConsent === undefined) && (
        <CookiesComponent
          setCookieConsent={setCookieConsent}
          setToggleCookie={setToggleCookie}
        />
      )} */}
            <div className="reglementation-page-container">
                <h1>Politique de protection des données personnelles</h1>
                <p>
                    La présente Charte des données personnelle (« la Charte »)
                    est à jour au 13/10/2022.
                </p>
                <p>
                    New Paradigms assure la sécurité et la confidentialité des
                    informations qui lui sont confiées
                </p>
                <p>
                    Le site (ci-après « le Site ») est susceptible de collecter
                    certaines données personnelles des Utilisateurs. Une donnée
                    personnelle étant « Toute donnée relative à une personne
                    physique et susceptible de contribuer à l’identification de
                    cette dernière, de manière directe ou indirecte, que cette
                    donnée soit confidentielle ou publique (« Données
                    Personnelles »). »
                </p>
                <p>
                    La présente Charte a pour objet d’exposer les conditions
                    dans lesquelles les données personnelles collectées sont
                    traitées et utilisées, afin d’informer aux mieux les
                    personnes concernées. La Charte fait partie intégrante des
                    Conditions Générales d’Utilisation (« CGU »), les termes
                    portant une majuscule ayant une définition identique à
                    celles des CGU.
                </p>
                <p>
                    L’Editeur accorde une importance toute particulière à la
                    protection des données personnelles de ses Utilisateurs.
                </p>

                <h2>1. IDENTITÉ DU RESPONSABLE DU TRAITEMENT</h2>
                <p>
                    New Paradigms, Société en commandite spéciale (SCSp) au
                    capital de x euros dont le siège social est situé 2 Rue Jean
                    Giono 31130 Balma, Immatriculée au registre du commerce de
                    Luxembourg sous le numéro B 251 823 (ci-après « New
                    Paradigms ») représenté par Antoine FLIPO co-founder, dûment
                    habilité aux fins présentes,
                </p>
                <p>(Ci-après, "l'Éditeur")</p>
                <p>
                    Coordonnées du délégué à la protection des données
                    personnelles (DPO) :
                </p>
                <p>dpo@newparadigms.com</p>
                <p>167 avenue saint Maur 59110 La Madeleine</p>

                <h2>
                    2. RESPECT DE LA LOI DU 6 JANVIER 1978 DITE " LOI
                    INFORMATIQUE ET LIBERTÉ " ET DU RÈGLEMENT 2016/679
                </h2>
                <p>
                    L’Éditeur est responsable du traitement des données à
                    caractère personnel et s’engage à respecter, d’une part, la
                    loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux
                    fichiers et aux libertés, dite Loi « Informatique et
                    Libertés », telle que modifiée par la loi du 6 août 2004, et
                    d’autre part le Règlement européen UE/2016/679.
                </p>
                <h2>3. CONSENTEMENT</h2>
                <p>
                    En utilisant notre Site, l’Utilisateur accepte la politique
                    de respect de la vie privée de l'Éditeur, et consent au fait
                    que certaines données soient collectées selon les modalités
                    et principes décrits ci-après.
                </p>
                <p>
                    La création d’un Compte Utilisateur et/ou Investisseur sur
                    le Site est impossible si les informations obligatoires
                    sollicitées dans le formulaire d’inscription sont
                    manquantes.
                </p>

                <h2>4. MÉTHODES DE COLLECTE</h2>
                <p>
                    L’Éditeur peut recueillir des informations concernant
                    l’Utilisateur à différents moments.
                </p>
                <ul>
                    <li>
                        Lors de la saisie des informations personnelles pour la
                        création d’un Compte ;
                    </li>
                    <li>
                        Lors de la navigation sur le Site par le biais de
                        Cookies ;
                    </li>
                    <li>Lors des échanges écrits ou oraux (emails).</li>
                </ul>
                <p>
                    La communication et le traitement des données est
                    indispensable à la fourniture des Services et/ou au respect
                    des exigences légales et règlementaires auxquelles le Site
                    est soumis. En dehors de ces cas, aucune donnée n’est
                    collectée sans l’accord préalable.
                </p>
                <h2>5. INFORMATIONS COLLECTÉES</h2>
                <p>
                    Les informations collectées pour la création d’un Compte
                    Utilisateur sont les suivantes :
                </p>
                <ul>
                    <li>Civilité, Nom et prénom*;</li>
                    <li>Adresse email*;</li>
                </ul>
                <p>
                    Les informations collectées pour la création d’un Compte
                    Investisseur sont les suivantes :
                </p>
                <ul>
                    <li>Nom et prénom*;</li>
                    <li>Nom d’usage*,</li>
                    <li>Nationalité*,</li>
                    <li>Situation professionnelle*</li>
                    <li>Date et lieu de naissance*;</li>
                    <li>Adresse email*;</li>
                    <li>Adresse postale*,</li>
                    <li>Lieu de résidence fiscale*,</li>
                    <li>Numéro de téléphone*,</li>
                    <li>Situation familiale*,</li>
                    <li>Toutes informations données volontairement.</li>
                </ul>
                <p>
                    En plus des informations collectées, le Site demande la
                    fourniture de pièces complémentaires et notamment :
                </p>
                <ul>
                    <li>
                        Deux pièces d’identité en cours de validité comprenant
                        obligatoirement la carte nationale d’identité,
                    </li>
                    <li>
                        Un justificatif de domicile, daté de moins de 3 mois,
                    </li>
                    <li>Un relevé d’identité bancaire,</li>
                    <li>
                        Des pièces complémentaires pour certains cas et
                        notamment si l’Utilisateur est une personne morale (Kbis
                        de moins de 3 mois et statuts de la société)
                    </li>
                </ul>
                <p>Par ailleurs, des données sont collectées :</p>
                <ul>
                    <li>
                        Dans le cadre de la navigation sur le Site, concernant
                        le comportement et les préférences de l’Internaute ;
                    </li>
                    <li>
                        Dans le cadre du processus « Know Your Customer » avec
                        la réalisation d’un test d’adéquation entre les
                        objectifs d’investissement et les Services proposés où
                        sont demandés les revenus annuels net du foyer, la
                        répartition global et le montant des engagement
                        financiers actuels et futurs,
                    </li>
                    <li>
                        Dans la cadre des échanges avec le Site par mail ou
                        chat,
                    </li>
                    <li>
                        Dans le cadre d’un paiement aux fins de versement d’une
                        souscription, d’une donation ou d’un remboursement.
                    </li>
                </ul>

                <h2>6. FINALITÉS</h2>
                <p>
                    L'Editeur ne collectera que les données adéquates,
                    pertinentes et strictement nécessaires à la finalité du
                    traitement dans le respect du principe de proportionnalité.
                </p>
                <p>
                    En fonction des situations, les finalités sont les suivantes
                    :
                </p>
                <p>Les données du Compte sont utilisées pour :</p>
                <ul>
                    <li>
                        la constitution d’un fichier d’Utilisateurs inscrits,
                    </li>
                    <li>la gestion du Compte,</li>
                    <li>permettre d’utiliser les services du Site,</li>
                    <li>
                        respecter les obligations légales et réglementaires,
                    </li>
                    <li>lutter contre la fraude,</li>
                    <li>sécuriser les transactions,</li>
                    <li>établir des preuves de réalisation des Services,</li>
                    <li>fournir des offres personnalisées</li>
                    <li>
                        établir des documents officiels (attestations de
                        propriété, IFU,…)
                    </li>
                </ul>
                <p>
                    La technologie évoluant, l'Éditeur pourrait être amené à
                    ajouter des finalités qui ne sont pas encore prévues dans
                    cette Charte. Le cas échéant, les modifications des règles
                    de confidentialité seront effectuées sur cette page.
                </p>
                <h2>7. COOKIES</h2>
                <h3>7.1 Définition du cookie</h3>
                <p>
                    Un cookie est un petit fichier texte qui est placé ou stocké
                    sur le terminal (ordinateur, tablette ou appareil mobile) en
                    cas de visite du Site. Le cookie permet à un site de
                    reconnaître l’Utilisateur, de l’aider à naviguer de page en
                    page sur le Site, fournit des connexions sécurisées et se
                    souvient des préférences lors des prochaines visites.
                </p>
                <p>
                    New Paradigms a recours à l'utilisation de cookies afin de
                    reconnaître l’Internaute lors de sa connexion sur le Site.
                </p>
                <p>
                    Leur durée de conservation varie selon le type de cookie
                    utilisé. Les cookies de session ne durent que le temps d’une
                    session de navigation, c'est-à-dire de l’arrivée de
                    l’Internaute sur le Site jusqu’à la fermeture de son
                    navigateur Internet.
                </p>
                <p>
                    Les cookies permanents font revivre le fichier au-delà de la
                    session initiale. Il est alors de la responsabilité de
                    l’Internaute de configurer son navigateur de manière à
                    empêcher cette situation. Les cookies persistants ont une
                    durée de vie maximale de 31 jours.
                </p>
                <h3>7.2 Consentement et suppression des cookies</h3>
                <p>
                    Ce consentement est obtenu par une validation claire sur une
                    bannière dédiée à cet usage et pourra être modifié à tout
                    moment par l’Utilisateur, en cliquant{' '}
                    <span
                        className="second-color pointer"
                        onClick={() => editCookieConsent()}
                    >
                        ici
                    </span>
                    .
                </p>

                <p>
                    Par ailleurs, l’Internaute peut à tout moment désactiver les
                    cookies enregistrés sur son terminal. Pour cela, il lui
                    suffit de sélectionner les paramètres appropriés dans son
                    navigateur. Cependant, cette désactivation aura pour
                    conséquence d'empêcher l'accès à certaines fonctionnalités
                    du Site permettant de personnaliser les services proposés
                    par New Paradigms.
                </p>
                <h3>7.3 Les différents types de cookies utilisés</h3>
                <p>
                    Les cookies strictement nécessaires permettent de naviguer
                    sur le Site et sont indispensables au bon fonctionnement du
                    Site. Leur désactivation entraînera des difficultés dans
                    l’utilisation du Site et aura pour conséquence d'empêcher
                    l'accès à certaines fonctionnalités.
                </p>
                <p>
                    Les cookies fonctionnels permettent de mémoriser les
                    préférences, les choix afin de personnaliser l’expérience
                    sur le Site.
                </p>
                {/* <h3>7.4 Balises Web</h3>
        <p>
          New Paradigms utilise des balises web sur certaines pages du Site qui
          permettent de compter le nombre de visiteurs de la page. Ces balises
          web peuvent être utilisées avec certains de nos partenaires
          commerciaux, notamment afin de mesurer et améliorer l'efficacité de
          certaines publicités.
        </p>
        <p>
          Les informations collectées via ces balises web sont anonymes et
          permettent de connaître la fréquentation de certaines pages du Site,
          et ce afin de mieux assister les Internautes du Site.
        </p> */}

                <h2>8. DESTINATAIRE DES DONNÉES</h2>
                <p>
                    Les données et informations collectées sur l’Utilisateur
                    sont destinées à :
                </p>
                <ul>
                    <li>
                        certains salariés de l’Éditeur, ainsi qu’à ses éventuels
                        sous-traitants, ou partenaires dont l’intervention est
                        nécessaire pour l’exécution des Services lesquels sont
                        soumis au respect des mêmes règles sur la protection des
                        données personnelles que l’Éditeur,
                    </li>
                    <li>
                        aux autorités publiques lorsqu’elles en font la demande,
                        ou dans le cadre des obligations réglementaires ou
                        légales.
                    </li>
                </ul>
                <p>Aucune vente ne sera faite de ces données collectées.</p>
                <p>
                    L’Éditeur se réserve le droit de transférer les données à
                    caractère personnel dans le cas d’un changement de son
                    contrôle ou de la cession de tout ou partie de ses actifs,
                    notamment par acquisition ou fusion avec une autre société.
                </p>
                <p>
                    Les données personnelles collectées ne font l’objet d’aucun
                    transfert en dehors de l’Union Européenne.
                </p>
                <p>
                    Néanmoins, les données peuvent être transmises aux autorités
                    compétentes sur requête et notamment aux organismes publics,
                    exclusivement pour répondre aux obligations légales, les
                    auxiliaires de justice, les officiers ministériels et les
                    organismes chargés d’effectuer le recouvrement de créances.
                </p>
                <h2>9. DURÉE DE CONSERVATION</h2>
                <p>
                    Les données sont conservées par l’Éditeur pendant la durée
                    nécessaire aux finalités pour lesquelles elles ont été
                    collectées et pour répondre à des obligations légales et/ou
                    règlementaires. Il est précisé en particulier que :
                </p>
                <ul>
                    <li>
                        pour la gestion du compte personnel, les données sont
                        conservées tant que le Compte est actif, étant entendu
                        comme un Compte qui détient encore des participations ou
                        des fonds sur le compte de paiement et pendant 5 ans
                        après la dernière connexion au Site ;
                    </li>
                </ul>
                <p>
                    Toutefois, certaines données pourront être conservées et
                    anonymisées pour être utilisées à des fins statistiques.
                </p>
                <h2>10. LES DONNÉES BANCAIRES</h2>
                {/* à faire */}
                <h2>11. LES DROITS DE L’INTERNAUTE</h2>
                <p>
                    L’Internaute dispose de plusieurs droits à valoir à
                    l’adresse indiquée à l’article 2 des présentes et notamment
                    :
                </p>
                <ul>
                    <li>
                        droit d’accéder à ses données faisant l’objet d’un
                        traitement (article 15 du RGPD),
                    </li>
                    <li>
                        droit de retirer son consentement à tout moment, sans
                        remettre en cause le traitement mis en œuvre jusque-là
                        et sous réserve que le retrait de consentement
                        contrevienne à l’utilisation des Services en cours
                        (article 7 du RGPD),
                    </li>
                    <li>
                        droit d’obtenir la rectification de données inexactes ou
                        le complément de données incomplètes (article 16 du
                        RGPD),
                    </li>
                    <li>
                        droit d’obtenir l’effacement de ses données, dans les
                        cas prévus à l’article 17 du RGPD après la suppression
                        du Compte,
                    </li>
                    <li>
                        droit à la portabilité de ses données (article 20 du
                        RGPD),
                    </li>
                </ul>
                <p>
                    Au titre de ce droit à la portabilité des données à
                    caractère personnel, l’Utilisateur peut également demander à
                    ce que les données soient transmises directement à un autre
                    responsable de traitement, sous réserve que cela soit
                    techniquement possible. Il est toutefois précisé que ce
                    droit à la portabilité ne couvre pas les données
                    statistiques ou anonymisées, ni les données déduites et
                    dérivées qui sont créés par l’Editeur à partir des données
                    fournies.
                </p>
                <ul>
                    <li>
                        droit d’introduire une réclamation auprès de la CNIL
                        (article 13 du RGPD). Toute demande doit être
                        obligatoirement accompagnée d'une photocopie d'une pièce
                        d'identité conforme et valide, et sera traitée dans un
                        délai maximum de 2 mois suivant réception.
                    </li>
                </ul>
                <p>
                    Enfin, l’Utilisateur peut définir des directives relatives à
                    la conservation, à l’effacement et à la communication de ses
                    données à caractère personnel après son décès.
                </p>
                <h2>12. DÉMARCHAGE TÉLÉPHONIQUE</h2>
                <p>
                    BLOCTEL est la liste d’opposition au démarchage téléphonique
                    sur laquelle tout consommateur peut s’inscrire gratuitement
                    afin de ne plus être démarché téléphoniquement par un
                    professionnel avec lequel il n’a pas de relation
                    contractuelle en cours, conformément à la loi n° 2014-344 du
                    17 mars 2014 relative à la consommation.
                </p>
                <p>
                    La loi précise qu’il est interdit à tout professionnel,
                    directement ou par l’intermédiaire d’un tiers agissant pour
                    son compte, de démarcher téléphoniquement un consommateur
                    inscrit sur cette liste, à l’exception des cas énumérés par
                    la loi. En particulier, l’Utilisateur pourra toujours être
                    appelé dans les cas suivants :
                </p>
                <ul>
                    <li>
                        Par les professionnels avec lesquels l’Utilisateur a un
                        contrat en cours ;
                    </li>
                    <li>
                        Pour des appels de prospection en vue de la fourniture
                        de journaux, de périodiques ou de magazines ;
                    </li>
                    <li>
                        Pour des motifs qui ne concernent pas la vente de biens
                        ou de services tels que :
                    </li>
                    <li className="unset-dot">
                        <ul>
                            <li>Les appels émanant d’un service public ;</li>
                            <li>
                                Les appels émanant d’instituts d’études et de
                                sondage ;
                            </li>
                            <li>
                                Les appels émanant d’associations à but non
                                lucratif ;
                            </li>
                        </ul>
                    </li>
                    <li>
                        Si l’Utilisateur a communiqué de manière libre et non
                        équivoque son numéro afin d’être rappelé.
                    </li>
                </ul>
                <p>
                    Si l’Utilisateur est inscrit sur BLOCTEL, New Paradigms ne
                    sera autorisé à appeler uniquement l’Utilisateur ayant un
                    Compte Investisseur.
                </p>
                <h2>13. SÉCURITÉ</h2>
                <p>
                    L’Editeur s’engage à faire ses meilleurs efforts pour
                    protéger vos données à caractère personnel, afin notamment
                    d’empêcher qu’elles soient déformées, endommagées ou
                    communiquées à des tiers non autorisés.
                </p>
                <h2>14. RÉCLAMATIONS et DROIT APPLICABLE</h2>
                <p>
                    Conformément à la réglementation en vigueur, New Paradigms
                    SA, en sa qualité de Prestataire de Services
                    d’Investissement (PSI), agréée Entreprise d’Investissement
                    par l’Autorité de Contrôle Prudentiel et de Résolution
                    (ACPR) sous le numéro CIB 11783, a établi et maintient
                    opérationnelle une procédure en vue du traitement
                    raisonnable et rapide
                </p>
                <p>
                    En cas de réclamation, l’Utilisateur peut introduire une
                    réclamation auprès de la Commission Nationale de
                    l'Informatique et des Libertés (CNIL) :
                </p>
                <p>Commission Nationale de l'Informatique et des Libertés</p>
                <p>3 Place de Fontenoy</p>
                <p>TSA 80715 - 75334 PARIS CEDEX 07</p>
                <p>Tél : 01 53 73 22 22</p>
                <p>Fax : 01 53 73 22 00</p>
                <p>
                    <a href="https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil">
                        https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil
                    </a>
                    <br />
                    Tout litige en relation avec l’utilisation de vos données
                    personnelles est soumis au droit Français. Toute réclamation
                    ou litige découlant de l'interprétation ou de l'exécution
                    des présentes de préservation des données personnelles sera
                    soumis aux tribunaux compétents dans les conditions de droit
                    commun.
                </p>
            </div>
        </React.Fragment>
    );
};

export default PolitiqueDonneesPerso;
