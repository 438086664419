import axios from 'axios';

export interface UploadDocumentTransactionDto {
    transactionId: number;
    file: File;
    // type: TypeDocumentTransaction;
    fileName: string;
    comment?: string;
}

export async function uploadDocumentTransaction(
    request: UploadDocumentTransactionDto
) {
    try {
        const response = await axios({
            method: 'get',
            url: `${import.meta.env.VITE_API_URL}transaction/documents/url/put`,
            params: {
                fileType: encodeURIComponent(request.file.type),
                transactionId: request.transactionId,
            },
            withCredentials: true,
        });
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}transaction/documents/post`,
            data: {
                transactionId: request.transactionId,
                fileName: encodeURIComponent(request.fileName),
                // type: request.type,
                comment: request.comment,
                key: key,
            },
            withCredentials: true,
        });

        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}
