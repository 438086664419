import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { FieldValues, UseFormHandleSubmit } from 'react-hook-form';
import { PrimaryButton } from '../Buttons';
import Loading from '../Loading';

export interface IFormTemplate<T extends FieldValues> {
    title?: string;
    handleSubmit: UseFormHandleSubmit<T>;
    onSubmit: (data: T) => Promise<void>;
    isValid?: boolean;
    useSubmitBtn?: boolean;
    loading?: boolean;
    className?: string;
    renderEndComponent?: () => ReactNode;
}

const FormTemplate = <T extends object>(
    props: IFormTemplate<T> & { children?: ReactNode }
) => {
    return (
        <div className={`mt-4 ${props.className ? props.className : ''}`}>
            <motion.div
                className={`mx-auto`}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
            >
                {!!props.title && (
                    <div className="head_form">
                        <h1 className="text-3xl text-center font-mainFontFamily md:text-4xl">
                            {props.title}
                        </h1>
                    </div>
                )}
                <form
                    onSubmit={props.handleSubmit(props.onSubmit)}
                    className="h-full mx-auto "
                >
                    {props.children}
                    {props.useSubmitBtn === false ? null : (
                        <PrimaryButton
                            type="submit"
                            disabled={!props.isValid}
                            className={`w-fit mx-auto my-6 
              }`}
                        >
                            {props.loading !== undefined &&
                            props.loading === true ? (
                                <Loading size={4} />
                            ) : (
                                'Valider'
                            )}
                        </PrimaryButton>
                    )}
                </form>
                {props.renderEndComponent ? props.renderEndComponent() : null}
            </motion.div>
        </div>
    );
};

export default FormTemplate;
