import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline';
import React from 'react';

export const SuccessIcon: React.FC<Props> = ({ className }) => {
    return (
        <CheckCircleIcon
            className={`h-6 w-6 text-green-400 ${className}`}
            aria-hidden="true"
        />
    );
};

interface Props {
    className?: string;
}

export const ErrorIcon: React.FC<Props> = ({ className }) => {
    return (
        <ExclamationIcon
            className={`h-6 w-6 text-red-400 ${className}`}
            aria-hidden="true"
        />
    );
};

export const WarningIcon: React.FC<Props> = ({ className }) => {
    return (
        <CheckCircleIcon
            className={`h-6 w-6 text-orange-400 ${className}`}
            aria-hidden="true"
        />
    );
};
