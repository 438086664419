import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { displayDocumentSide } from '@utils/DocumentUtilis';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatDate, removeDays } from '../../../function/Utils';

import {
    CreateDocumentUserDto,
    CreateOrUpdateDocumentUserValidationDto,
    DocumentUserDto,
    DocumentUserName,
    DocumentUserSide,
    DocumentUserType,
    TypeValidationDocumentUser,
    UpdateDocumentUserValidationDto,
    useCreateValidateDocumentUserMutation,
    useDeleteDocumentUserMutation,
    useGetAllUserDocumentsQuery,
    useGetUserWithAllInfoQuery,
    UserWithAllInfoForAdminDto,
    useUpdateValidationDocumentUserMutation,
} from '@api/api';
import {
    FormCreateDocumentUserRequest,
    uploadDocumentUser,
} from '@api/features/userSlice';
import { useNotificationContext } from '../../Context/notification-context';
import { TitleDocumentUser } from '../../DashboardUser/Profile/FormInvest/DocumentsIdentity1';
import {
    PrimaryButton,
    SecondaryButton,
    WhiteButton,
} from '../../commun/Buttons';
import useDeleteItem from '../../commun/CustomHook/useDeleteItem';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import StatusDocument from '../../commun/StatusDocument';
import HoverEffect from '../../commun/animation/HoverEffect';
import InputComponent from '../../commun/formComponent/InputComponent';
import RadioComponent from '../../commun/formComponent/RadioComponent';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import TextAreaComponent from '../../commun/formComponent/TextAreaComponent';
import UploaderSingle from '../../commun/formComponent/UploaderSingle';
import { convertTypeDocumentInTypeValidation } from './UserManage';

export default function Documents({ userId }: { userId: number }) {
    const {
        data: documents,
        isLoading: loadingUser,
        refetch,
    } = useGetAllUserDocumentsQuery(userId);

    const { data: user, isLoading: loading } =
        useGetUserWithAllInfoQuery(userId);
    const [addToggle, setAddToggle] = useState(false);
    return (
        <>
            {addToggle && user ? (
                <AddDocument
                    user={user!}
                    open={addToggle}
                    setOpen={setAddToggle}
                />
            ) : null}
            {loadingUser || loading ? (
                <Loading />
            ) : (
                <div className="relative mx-auto">
                    <h3 className="t font-mainFontFamily font-semibold">
                        <span className=" uppercase ">{user?.lastName} </span>
                        {user?.firstName}
                    </h3>
                    <div className="my-4 flex justify-center items-center">
                        <PrimaryButton onClick={() => setAddToggle(true)}>
                            Ajouter un document
                        </PrimaryButton>
                    </div>
                    {documents ? (
                        <div className="w-full md:w-11/12 mx-auto items-center flex flex-col gap-3">
                            {documents?.map((d, key) => (
                                <DisplayDocument
                                    document={d}
                                    user={user!}
                                    key={key}
                                />
                            ))}
                        </div>
                    ) : null}
                </div>
            )}
        </>
    );
}

export interface DocumentUserValidationDtoForm {
    id?: number;
    firstname: boolean | string;
    firstnameComment?: null | string;
    lastname: boolean | string;
    lastnameComment?: null | string;
    birthDay?: null | boolean | string;
    birthDayComment?: null | string;
    birthPlace?: null | boolean | string;
    birthPlaceComment?: null | string;
    comment?: null | string;
    documentId: number;
    dateValidity: boolean | string;
    dateValidityComment?: null | string;
    dateValidityEnd?: null | Date | string;
    userId: number;
    type: TypeValidationDocumentUser;
    address?: null | boolean | string;
    addressComment?: null | string;
    acceptedDocument: boolean | string;
}

function DisplayDocument({
    document,
    user,
}: {
    document: DocumentUserDto;
    user: UserWithAllInfoForAdminDto;
}) {
    const type = document?.key.split('.')[1];

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { isValid },
    } = useForm<DocumentUserValidationDtoForm>({
        defaultValues: document.validation
            ? {
                  ...document.validation,
                  documentId: document.id,
                  dateValidityEnd: document.validation.dateValidityEnd
                      ? new Date(document.validation.dateValidityEnd)
                            .toJSON()
                            .split('T')[0]
                      : undefined,
                  firstname: document.validation.firstname ? 'true' : 'false',
                  lastname: document.validation.lastname ? 'true' : 'false',
                  birthPlace:
                      document.validation.birthPlace !== undefined
                          ? document.validation.birthPlace
                              ? 'true'
                              : 'false'
                          : undefined,
                  birthDay:
                      document.validation.birthDay !== undefined
                          ? document.validation.birthDay
                              ? 'true'
                              : 'false'
                          : undefined,
                  dateValidity: document.validation.dateValidity
                      ? 'true'
                      : 'false',
                  address:
                      document.validation.address !== undefined
                          ? document.validation.address
                              ? 'true'
                              : 'false'
                          : undefined,
                  acceptedDocument: document.validation.acceptedDocument
                      ? 'true'
                      : 'false',
              }
            : {},
    });

    const [validateDocument, { isLoading: isLoadfindAdd }] =
        useCreateValidateDocumentUserMutation();
    const [editValidationDocument, { isLoading: isLoadingEdit }] =
        useUpdateValidationDocumentUserMutation();

    const { showError, showSuccess } = useNotificationContext();

    useEffect(() => {
        if (document.validation !== undefined) {
            reset({
                ...document.validation,
                documentId: document.id,

                dateValidityEnd: document.validation.dateValidityEnd
                    ? new Date(document.validation.dateValidityEnd)
                          .toJSON()
                          .split('T')[0]
                    : undefined,
                firstname: document.validation.firstname ? 'true' : 'false',
                lastname: document.validation.lastname ? 'true' : 'false',
                birthPlace:
                    document.validation.birthPlace !== undefined
                        ? document.validation.birthPlace
                            ? 'true'
                            : 'false'
                        : undefined,
                birthDay:
                    document.validation.birthDay !== undefined
                        ? document.validation.birthDay
                            ? 'true'
                            : 'false'
                        : undefined,
                dateValidity: document.validation.dateValidity
                    ? 'true'
                    : 'false',
                acceptedDocument: document.validation.acceptedDocument
                    ? 'true'
                    : 'false',
                address:
                    document.validation.address !== undefined
                        ? document.validation.address
                            ? 'true'
                            : 'false'
                        : undefined,
            });
        }
    }, [document, user]);

    const typeValidation = convertTypeDocumentInTypeValidation(document.type);
    const onSubmit = async (data: DocumentUserValidationDtoForm) => {
        if (data.id !== undefined) {
            const dataBis: UpdateDocumentUserValidationDto = {
                ...data,
                id: data.id!,
                documentId: document.id,
                userId: user.id,
                firstname: data.firstname == 'true' ? true : false,
                lastname: data.lastname == 'true' ? true : false,
                acceptedDocument:
                    data.acceptedDocument == 'true' ? true : false,
                birthPlace:
                    data.birthPlace !== undefined
                        ? data.birthPlace == 'true'
                            ? true
                            : false
                        : undefined,
                birthDay:
                    data.birthDay !== undefined
                        ? data.birthDay == 'true'
                            ? true
                            : false
                        : undefined,
                dateValidity: data.dateValidity == 'true' ? true : false,

                dateValidityEnd: data.dateValidityEnd
                    ? new Date(data.dateValidityEnd)?.toISOString()
                    : undefined,
                address:
                    data.address !== undefined
                        ? data.address == 'true'
                            ? true
                            : false
                        : undefined,
                type: typeValidation,
            };
            await editValidationDocument({
                updateDocumentUserValidationDto: dataBis,
                documentId: document.id,
            })
                .unwrap()
                .then(() => {
                    showSuccess('Edited', 'Validation edited successfully');
                    setPopUp(false);
                })
                .catch((res) => {
                    showError('Error', 'Error editing validation');
                });
        } else {
            delete data.id;
            const dataBis: CreateOrUpdateDocumentUserValidationDto = {
                ...data,
                documentId: document.id,
                userId: user.id,
                firstname: data.firstname == 'true' ? true : false,
                acceptedDocument:
                    data.acceptedDocument == 'true' ? true : false,
                lastname: data.lastname == 'true' ? true : false,
                birthPlace:
                    data.birthPlace !== undefined
                        ? data.birthPlace == 'true'
                            ? true
                            : false
                        : undefined,
                birthDay:
                    data.birthDay !== undefined
                        ? data.birthDay == 'true'
                            ? true
                            : false
                        : undefined,
                dateValidity: data.dateValidity == 'true' ? true : false,

                dateValidityEnd: data.dateValidityEnd
                    ? new Date(data.dateValidityEnd)?.toISOString()
                    : undefined,
                address:
                    data.address !== undefined
                        ? data.address == 'true'
                            ? true
                            : false
                        : undefined,
                type: typeValidation,
            };
            await validateDocument(dataBis)
                .unwrap()
                .then(() => {
                    showSuccess('Created', 'Validation created successfully');
                    setPopUp(false);
                })
                .catch((res) => {
                    showError('Error', 'Error creating validation');
                });
        }
    };

    const [popUp, setPopUp] = useState(false);

    const [deleteDocument, { isLoading: isLoadingDelete }] =
        useDeleteDocumentUserMutation();

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        typeTransactionId: number;
    }>({
        deleteItem: () => deleteDocument(document.id || 0),
        message: 'document',
        isLoading: isLoadingDelete,
    });

    return (
        <>
            {popUp ? (
                <PopUp
                    open={popUp}
                    setOpen={setPopUp}
                    buttonBoolean={false}
                    width="w-full md:w-9/12"
                    title={() => {
                        return (
                            <>
                                <h3 className="text-2xl font-semibold leading-6 text-gray-900">
                                    {document.type} - {document.name}
                                </h3>
                                <p>{decodeURIComponent(document.fileName)}</p>
                            </>
                        );
                    }}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="w-full flex mx-auto flex-col justify-center gap-3 md:w-10/12">
                            <div className="grid md:grid-cols-2">
                                <div className="">
                                    <a href={document.url} download={'test'}>
                                        <div className="text-sm font-medium leading-6 cursor-pointer text-gray-900">
                                            <FontAwesomeIcon
                                                icon={faDownload}
                                                className="text-mainColor hover:text-secondColor w-[20px] h-[20px]"
                                            />
                                        </div>
                                    </a>
                                    {type !== 'pdf' ? (
                                        <img src={document?.url} className="" />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faFilePdf}
                                            size={'2x'}
                                        />
                                    )}
                                </div>
                                <div className="flex flex-col gap-2 mt-2">
                                    <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center">
                                        <h4 className="text-start font-semibold">
                                            Prénom
                                        </h4>
                                        <p className="text-sm text-start">
                                            {user.firstName}
                                        </p>
                                        <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                            <RadioComponent
                                                name={'firstname'}
                                                watch={watch}
                                                className={{
                                                    div: '',
                                                    input: '!w-[15px]',
                                                    label: 'text-sm',
                                                }}
                                                register={register}
                                                values={[
                                                    {
                                                        label: 'Valider',
                                                        value: 'true',
                                                    },
                                                    {
                                                        label: 'Refuser',
                                                        value: 'false',
                                                    },
                                                ]}
                                            ></RadioComponent>
                                        </div>
                                        <div className="col-span-2 md:col-span-3">
                                            <TextAreaComponent
                                                required={false}
                                                rows={2}
                                                value={'firstnameComment'}
                                                className={{
                                                    input: '!outline-1 border-2',
                                                }}
                                                register={register}
                                            >
                                                <div className="text-start text-sm text-gray-400 italic">
                                                    Commentaire
                                                </div>
                                            </TextAreaComponent>
                                        </div>
                                    </div>
                                    <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center">
                                        <h4 className="text-start font-semibold">
                                            Nom
                                        </h4>
                                        <p className="text-sm text-start">
                                            {user.lastName}
                                        </p>
                                        <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                            <RadioComponent
                                                name={'lastname'}
                                                watch={watch}
                                                className={{
                                                    div: '',
                                                    input: '!w-[15px]',
                                                    label: 'text-sm',
                                                }}
                                                register={register}
                                                values={[
                                                    {
                                                        label: 'Valider',
                                                        value: 'true',
                                                    },
                                                    {
                                                        label: 'Refuser',
                                                        value: 'false',
                                                    },
                                                ]}
                                            ></RadioComponent>
                                        </div>
                                        <div className=" col-span-2 md:col-span-3">
                                            <TextAreaComponent
                                                required={false}
                                                rows={2}
                                                value={'lastnameComment'}
                                                className={{
                                                    input: '!outline-1 border-2',
                                                }}
                                                register={register}
                                            >
                                                <div className="text-start text-sm text-gray-400 italic">
                                                    Commentaire
                                                </div>
                                            </TextAreaComponent>
                                        </div>
                                    </div>
                                    {[
                                        TypeValidationDocumentUser.IDENTITY,
                                    ].includes(typeValidation) ? (
                                        <>
                                            <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center">
                                                <h4 className="text-start font-semibold">
                                                    Date de naissance
                                                </h4>
                                                <p className="text-sm text-start">
                                                    {user.userInfo?.birthDay
                                                        ? formatDate(
                                                              new Date(
                                                                  user.userInfo?.birthDay
                                                              )
                                                          )
                                                        : 'Date de naissance non trouvée'}
                                                </p>
                                                <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                                    <RadioComponent
                                                        name={'birthDay'}
                                                        watch={watch}
                                                        className={{
                                                            div: '',
                                                            input: '!w-[15px]',
                                                            label: 'text-sm',
                                                        }}
                                                        register={register}
                                                        values={[
                                                            {
                                                                label: 'Valider',
                                                                value: 'true',
                                                            },
                                                            {
                                                                label: 'Refuser',
                                                                value: 'false',
                                                            },
                                                        ]}
                                                    ></RadioComponent>
                                                </div>
                                                <div className=" col-span-2 md:col-span-3">
                                                    <TextAreaComponent
                                                        required={false}
                                                        rows={2}
                                                        value={
                                                            'birthDayComment'
                                                        }
                                                        className={{
                                                            input: '!outline-1 border-2',
                                                        }}
                                                        register={register}
                                                    >
                                                        <div className="text-start text-sm text-gray-400 italic">
                                                            Commentaire
                                                        </div>
                                                    </TextAreaComponent>
                                                </div>
                                            </div>
                                            <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center justify-start">
                                                <h4 className="text-start font-semibold">
                                                    Lieu de naissance
                                                </h4>
                                                <p className="text-sm text-start">
                                                    {user.userInfo?.birthPlace}
                                                </p>
                                                <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                                    <RadioComponent
                                                        name={'birthPlace'}
                                                        watch={watch}
                                                        className={{
                                                            div: '',
                                                            input: '!w-[15px]',
                                                            label: 'text-sm',
                                                        }}
                                                        register={register}
                                                        values={[
                                                            {
                                                                label: 'Valider',
                                                                value: 'true',
                                                            },
                                                            {
                                                                label: 'Refuser',
                                                                value: 'false',
                                                            },
                                                        ]}
                                                    ></RadioComponent>
                                                </div>
                                                <div className=" col-span-2 md:col-span-3">
                                                    <TextAreaComponent
                                                        required={false}
                                                        rows={2}
                                                        value={
                                                            'birthPlaceComment'
                                                        }
                                                        className={{
                                                            input: '!outline-1 border-2',
                                                        }}
                                                        register={register}
                                                    >
                                                        <div className="text-start text-sm text-gray-400 italic">
                                                            Commentaire
                                                        </div>
                                                    </TextAreaComponent>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                    {[
                                        TypeValidationDocumentUser.IDENTITY,
                                        TypeValidationDocumentUser.IDENTITY_HOSY_THIRD_PARTY,
                                    ].includes(typeValidation) ? (
                                        <div className="md:grid flex flex-col w-full gap-2 md:grid-cols-2 md:items-center md:justify-start">
                                            <h4 className="text-start font-semibold">
                                                Date validité
                                            </h4>

                                            <InputComponent
                                                value={'dateValidityEnd'}
                                                register={register}
                                                type="date"
                                                className={{
                                                    input: 'border-2 rounded-sm',
                                                }}
                                            >
                                                <div className="text-start">
                                                    Fin
                                                </div>
                                            </InputComponent>
                                            <div className="flex gap-2 col-span-3">
                                                <RadioComponent
                                                    name={'dateValidity'}
                                                    watch={watch}
                                                    className={{
                                                        div: '',
                                                        input: '!w-[15px]',
                                                        label: 'text-sm',
                                                    }}
                                                    register={register}
                                                    values={[
                                                        {
                                                            label: 'Valider',
                                                            value: 'true',
                                                        },
                                                        {
                                                            label: 'Refuser',
                                                            value: 'false',
                                                        },
                                                    ]}
                                                ></RadioComponent>
                                            </div>
                                            <div className=" col-span-2 md:col-span-3">
                                                <TextAreaComponent
                                                    required={false}
                                                    rows={2}
                                                    value={
                                                        'dateValidityComment'
                                                    }
                                                    className={{
                                                        input: '!outline-1 border-2',
                                                    }}
                                                    register={register}
                                                >
                                                    <div className="text-start text-sm text-gray-400 italic">
                                                        Commentaire
                                                    </div>
                                                </TextAreaComponent>
                                            </div>
                                        </div>
                                    ) : null}
                                    {[
                                        TypeValidationDocumentUser.RESIDENCY,
                                        TypeValidationDocumentUser.RESIDENCY_HOST_THIRD_PARTY,
                                        TypeValidationDocumentUser.ATTESTATION_HOST_THIRD_PARTY,
                                    ].includes(typeValidation) ? (
                                        <>
                                            <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center">
                                                <h4 className="text-start font-semibold">
                                                    Adresse
                                                </h4>
                                                <p className="text-sm text-start">
                                                    {user.userInfo?.adress}
                                                </p>
                                                <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                                    <RadioComponent
                                                        name={'address'}
                                                        watch={watch}
                                                        className={{
                                                            div: '',
                                                            input: '!w-[15px]',
                                                            label: 'text-sm',
                                                        }}
                                                        register={register}
                                                        values={[
                                                            {
                                                                label: 'Valider',
                                                                value: 'true',
                                                            },
                                                            {
                                                                label: 'Refuser',
                                                                value: 'false',
                                                            },
                                                        ]}
                                                    ></RadioComponent>
                                                </div>
                                                <div className=" col-span-2 md:col-span-3">
                                                    <TextAreaComponent
                                                        required={false}
                                                        rows={2}
                                                        value={'addressComment'}
                                                        className={{
                                                            input: '!outline-1 border-2',
                                                        }}
                                                        register={register}
                                                    >
                                                        <div className="text-start text-sm text-gray-400 italic">
                                                            Commentaire
                                                        </div>
                                                    </TextAreaComponent>
                                                </div>
                                            </div>
                                            <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center">
                                                <h4 className="text-start font-semibold">
                                                    Date Validity
                                                </h4>
                                                <p className="text-sm text-start">
                                                    Document après{' '}
                                                    <span className=" font-semibold">
                                                        {user.dateSubscription
                                                            ? formatDate(
                                                                  removeDays(
                                                                      new Date(
                                                                          user.dateSubscription
                                                                      ),
                                                                      90
                                                                  )
                                                              )
                                                            : null}{' '}
                                                    </span>
                                                    autorisé
                                                </p>
                                                <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                                    <RadioComponent
                                                        name={'dateValidity'}
                                                        watch={watch}
                                                        className={{
                                                            div: '',
                                                            input: '!w-[15px]',
                                                            label: 'text-sm',
                                                        }}
                                                        register={register}
                                                        values={[
                                                            {
                                                                label: 'Valider',
                                                                value: 'true',
                                                            },
                                                            {
                                                                label: 'Refuser',
                                                                value: 'false',
                                                            },
                                                        ]}
                                                    ></RadioComponent>
                                                </div>
                                                <div className=" col-span-2 md:col-span-3">
                                                    <TextAreaComponent
                                                        required={false}
                                                        rows={2}
                                                        value={
                                                            'dateValidityComment'
                                                        }
                                                        className={{
                                                            input: '!outline-1 border-2',
                                                        }}
                                                        register={register}
                                                    >
                                                        <div className="text-start text-sm text-gray-400 italic">
                                                            Commentaire
                                                        </div>
                                                    </TextAreaComponent>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                            <div className="">
                                <div className="grid md:grid-cols-2 w-full my-2 md:w-2/3 mx-auto justify-center items-center">
                                    <h4 className="text-start font-semibold">
                                        Type du document accepté
                                    </h4>
                                    <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                        <RadioComponent
                                            name={'acceptedDocument'}
                                            watch={watch}
                                            className={{
                                                div: '',
                                                input: '!w-[15px]',
                                                label: 'text-sm',
                                            }}
                                            register={register}
                                            values={[
                                                {
                                                    label: 'Oui',
                                                    value: 'true',
                                                },
                                                {
                                                    label: 'Non',
                                                    value: 'false',
                                                },
                                            ]}
                                        ></RadioComponent>
                                    </div>
                                </div>
                                <TextAreaComponent
                                    register={register}
                                    value={'comment'}
                                    rows={3}
                                    required={false}
                                    className={{
                                        input: '!outline-1 border-2',
                                    }}
                                >
                                    <div className="text-start text-gray-400 italic">
                                        Commentaire général
                                    </div>
                                </TextAreaComponent>
                            </div>
                            <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
                                <WhiteButton
                                    onClick={() => {
                                        setPopUp(false);
                                    }}
                                    className="w-full"
                                >
                                    Cancel
                                </WhiteButton>
                                <PrimaryButton
                                    disabled={!isValid}
                                    type="submit"
                                    className="w-full"
                                >
                                    {isLoadfindAdd || isLoadingEdit ? (
                                        <Loading size={4} />
                                    ) : (
                                        'Checked'
                                    )}
                                </PrimaryButton>
                            </div>
                        </div>
                    </form>
                </PopUp>
            ) : (
                <div className={`flex w-full items-center relative gap-3`}>
                    <DeleteComponent title="ce document" />
                    <div
                        className={`${
                            type !== 'pdf'
                                ? 'cursor-pointer max-w-[20%]'
                                : 'w-[20%]'
                        } justify-self-start`}
                    >
                        {type !== 'pdf' ? (
                            <img
                                src={document?.url}
                                className=""
                                onClick={() => setPopUp(true)}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faFilePdf} size={'2x'} />
                        )}
                    </div>

                    <div className="text-sm text-start w-[30%] font-medium leading-6 text-gray-900">
                        <span className=" font-semibold">
                            {document.type} - {document.name} -{' '}
                            {displayDocumentSide(document.side)}
                            {formatInTimeZone(
                                new Date(document.date),
                                'Europe/Paris',
                                'dd/MM/yyyy'
                            )}
                        </span>{' '}
                        <span className="text-gray-500">
                            {decodeURIComponent(document.fileName)}
                        </span>
                    </div>

                    <div className=" justify-self-end flex gap-5 items-center">
                        <a href={document.url} download={'test'}>
                            <div className="text-sm font-medium leading-6 cursor-pointer text-gray-900">
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    className="text-mainColor  hover:text-secondColor w-[20px] h-[20px]"
                                />
                            </div>
                        </a>
                        <SecondaryButton
                            className=""
                            onClick={() => setPopUp(true)}
                        >
                            Vérifier <br /> le document
                        </SecondaryButton>
                        <StatusDocument status={document.status} />
                        <HoverEffect
                            className={''}
                            onClick={() => setOpenDelete(true)}
                        >
                            <FontAwesomeIcon
                                icon={faTrash}
                                className="text-titleColor hover:text-gray-400 w-[20px] h-[20px]"
                            />
                        </HoverEffect>
                    </div>
                </div>
            )}
        </>
    );
}

export interface FormCreateDocumentUser extends CreateDocumentUserDto {
    file: File[];
}

function AddDocument({
    user,
    open,
    setOpen,
}: {
    user: UserWithAllInfoForAdminDto;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const {
        register,
        watch,
        getValues,
        setValue,
        reset,
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<FormCreateDocumentUser>({
        defaultValues: {
            type: DocumentUserType.OTHER,
            name: DocumentUserName.OTHER,
            side: DocumentUserSide.BOTH,
        },
    });

    const {
        data: documents,
        isLoading: loadingUser,
        refetch,
    } = useGetAllUserDocumentsQuery(user.id);

    const { showError, showSuccess } = useNotificationContext();
    const [isLoadingUpload, setIsLoadingUpload] = React.useState(false);

    const onSubmit = async (dataForm: FormCreateDocumentUser) => {
        setIsLoadingUpload((curr) => !curr);
        const file = dataForm.file[0];

        const data: FormCreateDocumentUserRequest = {
            ...dataForm,
            file: file,
            userId: user?.id!,
            side: dataForm.side,
            type: dataForm.type,
            fileName: file.name,
        };

        await uploadDocumentUser(data)
            .then(async (res) => {
                if (res.sucess) {
                    await refetch();
                    showSuccess('Created', 'Fichier envoyé');
                    setOpen(false);
                } else {
                    showError(
                        'Error',
                        `Erreur lors de l'envoie de ${data.fileName}`
                    );
                }
            })
            .catch((res) => {
                showError(
                    'Error',
                    `Erreur lors de l'envoie de ${data.fileName}`
                );
            });
        setIsLoadingUpload((curr) => !curr);
    };

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            width="w-full md:w-9/12 min-h-[500px]"
            title={() => {
                return (
                    <>
                        <h3 className="text-2xl font-semibold leading-6 text-gray-900">
                            Ajouter un document
                        </h3>
                    </>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-3"
            >
                <div className="flex flex-col gap-3 justify-center items-center w-full h-full">
                    <div
                        className={`mx-auto w-11/12 md:w-2/3 flex justify-start items-start flex-col gap-1
              
              `}
                        // ${
                        //   !watch("name") ? "mt-[-30%]" : ""
                        // }
                    >
                        <SelectComponent
                            register={register}
                            control={control}
                            value={'name'}
                            getValues={getValues}
                            setValue={setValue}
                            optionValues={Object.values(DocumentUserName).map(
                                (v) => {
                                    return {
                                        label: v,
                                        value: v,
                                    };
                                }
                            )}
                        >
                            <h3 className=" font-semibold ">
                                Choisisser un type de document{' '}
                                <span className="required">*</span>
                            </h3>
                        </SelectComponent>

                        <SelectComponent
                            register={register}
                            control={control}
                            value={'type'}
                            getValues={getValues}
                            setValue={setValue}
                            optionValues={Object.values(DocumentUserType).map(
                                (v) => {
                                    return {
                                        label: v,
                                        value: v,
                                    };
                                }
                            )}
                        >
                            <h3 className=" font-semibold mt-3">
                                Choisisser la catégorie
                                <span className="required">*</span>
                            </h3>
                        </SelectComponent>
                        <SelectComponent
                            register={register}
                            control={control}
                            value={'side'}
                            getValues={getValues}
                            setValue={setValue}
                            optionValues={Object.values(DocumentUserSide).map(
                                (v) => {
                                    return {
                                        label: v,
                                        value: v,
                                    };
                                }
                            )}
                        >
                            <h3 className=" font-semibold mt-3">
                                Choisisser le côté du document (recto + verso =
                                Both)
                                <span className="required">*</span>
                            </h3>
                        </SelectComponent>
                    </div>
                </div>
                {watch('name') ? (
                    <>
                        <UploaderSingle
                            register={register}
                            value={'file'}
                            watch={watch}
                            setValue={setValue}
                            loading={isLoadingUpload}
                            button={false}
                            componentEnd={() => {
                                return (
                                    <p className="italic commentaireInput text-justify text-[0.8rem]">
                                        <span className="font-bold">
                                            Documents acceptés:
                                        </span>{' '}
                                        Documents recto et verso.
                                    </p>
                                );
                            }}
                        >
                            <h3 className="text-2xl mb-5">
                                {TitleDocumentUser(watch('name'))} de{' '}
                                {user?.firstName}
                                <span className="uppercase">
                                    {' '}
                                    {user?.lastName}
                                </span>
                                <span className="required">*</span>
                            </h3>
                        </UploaderSingle>

                        <div className="flex items-center gap-2 justify-center mt-10 mb-4 ">
                            <WhiteButton onClick={() => setOpen(false)}>
                                Cancel
                            </WhiteButton>
                            <PrimaryButton
                                type="submit"
                                loading={isLoadingUpload}
                                disabled={
                                    (watch('file') as any[])?.length > 0
                                        ? false
                                        : true
                                }
                            >
                                Ajouter
                            </PrimaryButton>
                        </div>
                    </>
                ) : null}
            </form>
        </PopUp>
    );
}
