import React from "react";
import { useParams } from "react-router";
import Footer from "../components/commun/Footer";
import Navigation from "../components/commun/Navigation";
import AvertissementRisques from "../components/reglementations/AvertissementRisques";
import CGU from "../components/reglementations/CGU";
import MentionsLegales from "../components/reglementations/MentionsLegales";
import Menu from "../components/reglementations/Menu";
import PolitiqueDonneesPerso from "../components/reglementations/PolitiqueDonneesPerso";
import NotFound from "./NotFound";

type PathParams = {
  path: string;
};

const Reglementations = () => {
  const { path } = useParams<PathParams>();

  const routeFunction = () => {
    switch (path) {
      case "conditions-generales-utilisation":
        return <CGU />;
      case "Donnees-personnelles":
        return <PolitiqueDonneesPerso />;
      case "mentions-legales":
        return <MentionsLegales />;
      case "risques":
        return <AvertissementRisques />;
      default:
        return <NotFound />;
    }
  };

  return (
    <React.Fragment>
      <Navigation bol_nosfonds={false} bol_transparent={false} />
      <Menu />
      {routeFunction()}
      <Footer />
    </React.Fragment>
  );
};

export default Reglementations;
