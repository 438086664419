import {
    TransactionDto,
    TransactionStatus,
    TypeTransaction,
    useCreateTransactionMutation,
} from '@api/api';
import {
    UploadDocumentTransactionDto,
    uploadDocumentTransaction,
} from '@api/features/transactionSlice';
import TransactionForm, {
    TypeTransactionProject2Cash,
    UpdateOrCreateTransactionDtoForm,
} from '@components/commun/formComponent/ModelForm/Transaction/TransactionForm';
import { useNotificationContext } from '@components/Context/notification-context';
import { stringToFloat, stringToInt, transformDate } from '@utils/Utils';
import { ReactNode } from 'react';

interface Props {
    renderButtons: (props: { isLoading: boolean }) => ReactNode;
    container: (props: {
        children: ReactNode;
        expanded: boolean;
        setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    }) => ReactNode;
    defaultValues?: Partial<UpdateOrCreateTransactionDtoForm>;
}

function CreateTransactionForm(props: Props): JSX.Element {
    const { showError, showSuccess } = useNotificationContext();

    const [addTransaction] = useCreateTransactionMutation();

    const onSubmit = async (
        data: UpdateOrCreateTransactionDtoForm,
        sponsorId?:
            | {
                  sponsorId: number | false;
              }
            | undefined
    ) => {
        const files = data?.files;
        delete data.files;

        if (
            data.type === TypeTransaction.newParadigmsCash ||
            data.type === TypeTransaction.platformCash
        ) {
            data = {
                ...data,
                from: 0,
            };
        } else if (data.type === TypeTransaction.cashNewParadigms) {
            data = {
                ...data,
                to: 0,
            };
        } else if (data.type === TypeTransaction.projectCash) {
            let type = TypeTransaction.projectCash;
            switch (data.typeTransactionProject2Cash) {
                case TypeTransactionProject2Cash.Capital:
                    type = TypeTransaction.buyingProjectCash;
                    break;
                case TypeTransactionProject2Cash.Closure:
                    type = TypeTransaction.projectStopCash;
                    break;
                default:
                    break;
            }
            data.type = type;
        }

        if (data.sponsored && sponsorId?.sponsorId && data.transactionUserIn) {
            data.transactionUserIn.sponsorId = sponsorId?.sponsorId;
        }

        delete data.typeTransactionProject2Cash;
        delete data.sponsored;

        await addTransaction({
            ...data,
            fondsId: stringToInt(data.fondsId),
            date: transformDate(data.date)?.toISOString(),
            to: data.to,
            from: data.from,
            amount: stringToFloat(data.amount),
            status: TransactionStatus.VALIDATED,
            dateInterest: data.dateInterest
                ? transformDate(data.dateInterest)?.toISOString()
                : undefined,
        })
            .unwrap()
            .then(async (res) => {
                try {
                    if (files) {
                        for (let i = 0; i < files.length; i++) {
                            const data: UploadDocumentTransactionDto = {
                                transactionId: (res as TransactionDto).id,
                                file: files[i],
                                fileName: files[i].name,
                                // type:(res.payload as TransactionDto)?.type === TypeTransaction.buyingProjectCash ? TypeDocumentTransaction. ,
                            };
                            await uploadDocumentTransaction(data)
                                .then((res) => {
                                    if (res.sucess) {
                                        showSuccess(
                                            'Created',
                                            'File uploaded successfully'
                                        );
                                    } else {
                                        showError(
                                            'Error',
                                            `Error uploading file ${data.fileName}: ${res.message}`
                                        );
                                    }
                                })
                                .catch((res) => {
                                    showError(
                                        'Error',
                                        `Error uploading file ${data.fileName}: ${res.message}`
                                    );
                                });
                        }
                    }
                } catch (error) {
                    showError('Error', 'Error uploading files');

                    return;
                }
                showSuccess('Created', 'Transaction creating successfully');
            })
            .catch((err) => {
                showError('Error', 'Error creating transaction');
            });
    };

    return <TransactionForm {...props} onSubmit={onSubmit} />;
}

export default CreateTransactionForm;
