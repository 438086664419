import React, { ReactNode, useCallback, useEffect } from 'react';
import {
    Control,
    Controller,
    FieldValues,
    Path,
    useController,
    useForm,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import Select, { CSSObjectWithLabel } from 'react-select';
import { StringParam, useQueryParam } from 'use-query-params';
import { debounce } from '../../../function/Utils';
// import { Validate } from 'react-hook-form';

export interface IOptionValues {
    value: string | number;
    label: string;
}

interface Props<T extends FieldValues> {
    value: Path<T>;
    optionValues: IOptionValues[];
    commentaire?: string;
    register: UseFormRegister<T>;
    cursorNotAllowed?: true;
    container?: true;
    split?: string;
    setValue?: UseFormSetValue<T>;
    useParamBoolean?: boolean;
    getValues?: UseFormGetValues<T>;
    watch?: UseFormWatch<T>;
    control: Control<T, Path<T>>;
    required?: boolean;
    className?: string;
    setSearch?: React.Dispatch<React.SetStateAction<string>>;
    styles?: CSSObjectWithLabel;
}

const SelectComponent = <T extends object>(
    props: Props<T> & { children?: ReactNode }
) => {
    const [queryParams, setQueryParam] = useQueryParam(
        props.value,
        StringParam
    );

    const { control: controlTest } = useForm<T>();

    const {
        field: {
            value: property,
            onChange: propertyOnChange,
            ...restPropertyField
        },
    } = useController({
        name: props.value,
        control: props?.control ? props.control : controlTest,
    });

    useEffect(() => {
        if (props.useParamBoolean) {
            if (queryParams && property === undefined) {
                propertyOnChange(queryParams);
            }
        }
    }, [queryParams]);

    const updateSearch = useCallback(
        debounce((value: string) => {
            if (props?.setSearch) props?.setSearch(value);
        }, 500),
        []
    );

    const cursorFunction = (): JSX.Element => {
        return (
            <>
                {props.control ? (
                    <Controller
                        name={props.value}
                        control={props.control}
                        rules={{
                            required:
                                props.required === undefined
                                    ? true
                                    : props.required,
                        }}
                        render={({ field }) => (
                            <Select
                                styles={{
                                    // control: (baseStyles, state) => ({
                                    //   ...baseStyles,
                                    //   borderWidth: "0px",
                                    // }),
                                    control: (provided, state) => ({
                                        ...provided,
                                        ...props.styles,
                                    }),
                                    // option: (provided, state) => ({
                                    //   ...provided,
                                    //   backgroundColor: state.isSelected ? "#F2F2F2" : "#fff",
                                    //   color: "#A1A1A1",
                                    //   fontSize: "0.875rem",
                                    //   fontWeight: 500,
                                    //   "&:hover": {
                                    //     backgroundColor: "#F2F2F2",
                                    //   },
                                    // }),
                                    // menu: (provided, state) => ({
                                    //   ...provided,
                                    //   backgroundColor: "#F2F2F2",
                                    //   color: "#A1A1A1",
                                    //   fontSize: "0.875rem",
                                    //   fontWeight: 500,
                                    // }),
                                    // singleValue: (provided, state) => ({
                                    //   ...provided,
                                    //   color: "#A1A1A1",
                                    //   fontSize: "0.875rem",
                                    //   fontWeight: 500,
                                    // }),
                                }}
                                className={`w-full text-left  ${
                                    props?.cursorNotAllowed &&
                                    'pointer-events-none'
                                } ${props.className}`}
                                onInputChange={(v) => updateSearch(v)}
                                required={
                                    props.required === undefined
                                        ? true
                                        : props.required
                                }
                                // placeholder="Select Language"
                                isClearable
                                {...field}
                                options={props.optionValues as any}
                                {...props.register(props.value)}
                                value={
                                    property
                                        ? props.optionValues
                                              ?.map((value, index) => {
                                                  return {
                                                      value: value.value,
                                                      label: value.label,
                                                  };
                                              })
                                              .find((x) => x.value == property)
                                        : property
                                }
                                onChange={(option) => {
                                    if (props.useParamBoolean) {
                                        setQueryParam(
                                            option
                                                ? option.value
                                                : (option as any)
                                        );
                                    }
                                    propertyOnChange(
                                        option ? option.value : option
                                    );
                                }}
                                // {...restPropertyField}
                            />
                        )}
                    />
                ) : (
                    <select
                        className={`w-full text-left border-2 rounded-md p-1  ${
                            props?.cursorNotAllowed && 'pointer-events-none'
                        }`}
                        {...(props.register(props.value),
                        { required: props.required === false ? false : true })}
                        required={props.required === false ? false : true}
                    >
                        {props.optionValues.map((optionValue, key) => (
                            <option value={optionValue.value} key={key}>
                                {optionValue.label}
                            </option>
                        ))}
                    </select>
                )}
            </>
        );
    };

    const containerFunction = (): JSX.Element => {
        return (
            <>
                {props.children}
                {props.cursorNotAllowed ? (
                    <div className="cursor-not-allowed w-full">
                        {cursorFunction()}
                    </div>
                ) : (
                    cursorFunction()
                )}
                {!!props.commentaire && (
                    <p className="italic commentaireInput">
                        {props.commentaire}
                    </p>
                )}
            </>
        );
    };

    return (
        <React.Fragment>
            {props.container ? (
                <div className="form-item">{containerFunction()}</div>
            ) : (
                containerFunction()
            )}
        </React.Fragment>
    );
};

export default SelectComponent;
{
    /* <SingleSelector
          className="py-2 px-5 w-full text-left"
          useURLQuery={false}
          onSelect={(value) => {
            if (props.setValue) {
              props.setValue(props.value, value.name as any);
            }
          }}
          textRender={(value) =>
            props.split ? value.name.split(props.split)[0] : value.name
          }
          objects={props.optionValues?.map((option, key) => {
            return {
              name: option,
              id: key,
            };
          })}
        /> */
}
