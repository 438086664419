import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useEffect, useState } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';

interface Props<T> {
    startIntervalNbItems: number;
    offsetName?: string;
    setStartIntervalNbItems: React.Dispatch<React.SetStateAction<number>>;
    nbItemsShowed: number;
    setNbItemsShowed: React.Dispatch<React.SetStateAction<number>>;
    array: T[];
    offset?: boolean;
    setRangeChosenOffset?: React.Dispatch<React.SetStateAction<number>>;
    rangeChosenOffset?: number;
    nbItems?: number;
    rangeChosen?: number;
    setRangeChosen?: React.Dispatch<React.SetStateAction<number>>;
}

const FilterApparition = <T extends object>(
    props: Props<T> & { children?: ReactNode }
) => {
    let [rangeChosen, setRangeChosen] = useState<number>(1);

    rangeChosen = props.rangeChosen ?? rangeChosen;
    setRangeChosen = props.setRangeChosen ?? setRangeChosen;

    const nbItems = props.nbItems ?? props.array.length;

    const [offsetQuery, setOffsetQuery] = useQueryParam(
        props.offsetName ?? 'offset',
        NumberParam
    );

    useEffect(() => {
        if (
            offsetQuery !== undefined &&
            offsetQuery !== null &&
            props.startIntervalNbItems === 0
        ) {
            props.setStartIntervalNbItems(offsetQuery);
            setRangeChosen(Math.floor(offsetQuery / props.nbItemsShowed) + 1);
        }
    }, [offsetQuery]);

    const handleChangeRange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setRangeChosen(parseInt(e.target.value));
        props.setStartIntervalNbItems(
            (parseInt(e.target.value) - 1) * props.nbItemsShowed
        );
        setOffsetQuery((parseInt(e.target.value) - 1) * props.nbItemsShowed);
    };

    const updateRangeStartInterval = (nb: number) => {
        if (nb === 1) {
            if (rangeChosen + 1 < nbItems / props.nbItemsShowed + 1) {
                props.setStartIntervalNbItems(
                    rangeChosen * props.nbItemsShowed
                );
                setOffsetQuery(rangeChosen * props.nbItemsShowed);
                setRangeChosen((current) => current + nb);
            }
        } else if (nb === -1) {
            if (rangeChosen === 1) return;
            props.setStartIntervalNbItems(
                Math.max(0, (rangeChosen - 2) * props.nbItemsShowed)
            );
            setOffsetQuery(
                Math.max(0, (rangeChosen - 2) * props.nbItemsShowed)
            );
            setRangeChosen((current) => current + nb);
        } else if (nb === 2) {
            if (nbItems % props.nbItemsShowed !== 0) {
                setRangeChosen(Math.floor(nbItems / props.nbItemsShowed) + 1);
                props.setStartIntervalNbItems(
                    Math.floor(nbItems / props.nbItemsShowed) *
                        props.nbItemsShowed
                );
                setOffsetQuery(
                    Math.floor(nbItems / props.nbItemsShowed) *
                        props.nbItemsShowed
                );
            } else {
                setRangeChosen(Math.floor(nbItems / props.nbItemsShowed));
                props.setStartIntervalNbItems(
                    (Math.floor(nbItems / props.nbItemsShowed) - 1) *
                        props.nbItemsShowed
                );
                setOffsetQuery(
                    (Math.floor(nbItems / props.nbItemsShowed) - 1) *
                        props.nbItemsShowed
                );
            }
        } else {
            setRangeChosen(1);
            props.setStartIntervalNbItems(0);
            setOffsetQuery(0);
        }
    };

    const updateOffset = (int: number) => {
        if (props.rangeChosenOffset && props.setRangeChosenOffset) {
            if (int === 1) {
                props.setStartIntervalNbItems(
                    props.rangeChosenOffset * props.nbItemsShowed
                );
                setOffsetQuery(props.rangeChosenOffset * props.nbItemsShowed);
            } else {
                if (props.rangeChosenOffset === 1) return;
                props.setStartIntervalNbItems(
                    Math.max(
                        0,
                        (props.rangeChosenOffset - 2) * props.nbItemsShowed
                    )
                );
                setOffsetQuery(
                    Math.max(
                        0,
                        (props.rangeChosenOffset - 2) * props.nbItemsShowed
                    )
                );
            }
            props.setRangeChosenOffset((current) => current + int);
        } else {
            if (int === 1) {
                props.setStartIntervalNbItems(
                    rangeChosen * props.nbItemsShowed
                );
                setOffsetQuery(rangeChosen * props.nbItemsShowed);
            } else {
                if (rangeChosen === 1) return;
                props.setStartIntervalNbItems(
                    Math.max(0, (rangeChosen - 2) * props.nbItemsShowed)
                );
                setOffsetQuery(
                    Math.max(0, (rangeChosen - 2) * props.nbItemsShowed)
                );
            }
            setRangeChosen((current) => current + int);
        }
    };

    const displayNbRanges = () => {
        const nbRanges = [];
        for (let i = 1; i < nbItems / props.nbItemsShowed + 1; i++) {
            nbRanges.push(i);
        }
        return nbRanges;
    };
    return (
        <div className="flex gap-5 flex-wrap justify-center items-center">
            <div
                className="cursor-pointer"
                onClick={() => updateRangeStartInterval(-2)}
            >
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="text-mainColor"
                />
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="text-mainColor"
                />
            </div>
            <div
                className="cursor-pointer"
                onClick={() =>
                    props?.offset
                        ? updateOffset(-1)
                        : updateRangeStartInterval(-1)
                }
            >
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="text-mainColor"
                />
            </div>
            <div className="flex gap-2">
                <p>Page</p>
                <select
                    value={rangeChosen}
                    onChange={(e) => {
                        handleChangeRange(e);
                    }}
                >
                    {displayNbRanges().map((val, index) => {
                        return (
                            <option key={index} value={val}>
                                {val}
                            </option>
                        );
                    })}
                </select>
            </div>

            <div
                className="cursor-pointer"
                onClick={() =>
                    props?.offset
                        ? updateOffset(1)
                        : updateRangeStartInterval(1)
                }
            >
                <FontAwesomeIcon
                    icon={faChevronRight}
                    className="text-mainColor"
                />
            </div>
            <div
                className="cursor-pointer"
                onClick={() => updateRangeStartInterval(2)}
            >
                <FontAwesomeIcon
                    icon={faChevronRight}
                    className="text-mainColor"
                />
                <FontAwesomeIcon
                    icon={faChevronRight}
                    className="text-mainColor"
                />
            </div>
            <div className="flex gap-2">
                <label>Afficher par</label>
                <input
                    type="number"
                    step="any"
                    className="w-12 font-semibold"
                    value={props.nbItemsShowed}
                    onChange={(e) => {
                        props.setNbItemsShowed(
                            parseInt(e.target.value ?? 0) !== 0
                                ? parseInt(e.target.value)
                                : 1
                        );
                        props.setStartIntervalNbItems(0);
                        setOffsetQuery(0);
                    }}
                />
            </div>
            {props.children}
        </div>
    );
};

export default FilterApparition;
