import { FirstDateInFundsInterface, ShareDto } from '@api/api';
import {
    add30Days,
    addDays,
    compareTwoDate,
    isDateClosed,
    nextDay,
    numberDayBetweenDate,
} from './Utils';

export const getShareValueFunction = (share: ShareDto): number | null => {
    if (share) {
        return share.share;
    } else {
        return null;
    }
};

export interface IShareCombinedInFunds {
    FondsId: number;
    share: number;
    nbShare: number;
    performances: number;
    tri: number;
    initialAmount: number;
    firstDate: Date;
    initialValueOfShare: number;
    proportionBloqued: number;
}

export interface IFondsInfoUser {
    fondsId: number;
    fondsName: string;
    infoCombined: IShareCombinedInFunds;
    transactions: ITransactionInfo[];
}

export interface ITransactionInfo {
    share: number;
    nbShare: number;
    initialAmount: number;
    dateStartInvestisseur: Date;
    initialValueOfShare: number;
    typeShare: 'cumulative' | 'distribution';
    performance: number;
    tri: number;
    proportionBloqued: number;
}

interface ITransactionInfoWithFundsId extends ITransactionInfo {
    fondsId: number;
}

export interface ILastDateComputedByFunds {
    fondsId: number;
    lastDate: Date;
}

export class ComputationUserDashboard {
    shareDate: ShareDto[];
    lastDateComputedByFunds: ILastDateComputedByFunds[];
    lastDateComputed: Date;
    fundsInvested: { id: number; name: string }[];

    constructor(
        shareDate: ShareDto[],
        public userId: number,
        public firstDate: FirstDateInFundsInterface[]
    ) {
        this.shareDate = shareDate.filter((share) => share.userId === userId);
        this.userId = userId;
        this.firstDate = firstDate;
        this.fundsInvested = this.shareDate.reduce(
            (acc: { id: number; name: string }[], share) => {
                if (acc.map((v) => v.id).includes(share.fondsId)) {
                    return acc;
                } else {
                    return [
                        ...acc,
                        {
                            id: share.fondsId,
                            name: share.fondsName,
                        },
                    ];
                }
            },
            []
        );

        const shareSorted = [
            ...this.shareDate.sort(
                (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
            ),
        ];

        this.lastDateComputedByFunds = this.fundsInvested.map(
            (fondsInvested) => {
                const share = shareSorted.filter(
                    (share) => share.fondsId === fondsInvested.id
                )[0];
                return {
                    fondsId: fondsInvested.id,
                    lastDate: new Date(share.date),
                };
            }
        );

        this.lastDateComputed = new Date(
            Math.min(
                ...this.lastDateComputedByFunds.map((v) => v.lastDate.getTime())
            )
        );
    }

    // -------------------- General methods -----------------

    isDateComputed(date: Date): boolean {
        return (
            this.shareDate.filter((share) =>
                isDateClosed(new Date(share.date), date)
            ).length > 0
        );
    }

    getShareById(id: number, date: Date): ShareDto {
        return this.shareDate.filter(
            (share) =>
                share.transactionId === id &&
                isDateClosed(date, new Date(share.date))
        )[0];
    }

    getShareValue(shareId: number, date: Date): number {
        const share = this.getShareById(shareId, date);
        return share.share;
    }

    getDateInitForShare(dateStartInvestisseur: Date, fondsId: number) {
        const firstDate = this.firstDate.find(
            (f) => f.fondsId === fondsId
        )?.date;
        if (!firstDate) {
            return dateStartInvestisseur;
        } else {
            if (
                compareTwoDate(
                    dateStartInvestisseur,
                    addDays(new Date(firstDate), 20)
                )
            ) {
                return dateStartInvestisseur;
            } else {
                return add30Days(dateStartInvestisseur);
            }
        }
    }

    getTransactionInfo(date: Date, transactionId: number): ITransactionInfo {
        const share = this.shareDate.filter(
            (share) =>
                share.transactionId === transactionId &&
                isDateClosed(date, new Date(share.date))
        )[0];
        if (share.typeShare === 'cumulative') {
            const shareValue = share.share;
            const nbDay: number = this.nbDays(share, date);
            return {
                share: shareValue,
                nbShare: share.nbShare,
                initialAmount: share.initialAmount,
                dateStartInvestisseur: new Date(share.dateStartInvestisseur),
                initialValueOfShare: share.initialShare,
                performance:
                    ((shareValue - share.initialShare) * 100) /
                    share.initialShare,
                tri:
                    (Math.pow(shareValue / share.initialShare, 365 / nbDay) -
                        1) *
                    100,
                proportionBloqued:
                    (100 * share.valoBlackList) / (shareValue * share.nbShare),
                typeShare: share.typeShare,
            };
        } else {
            return {
                share: share.share,
                nbShare: share.nbShare,
                initialAmount: share.initialAmount,
                dateStartInvestisseur: new Date(share.dateStartInvestisseur),
                initialValueOfShare: share.initialShare,
                performance: (share?.performanceShareD || 0) * 100,
                tri: (share?.irrShareD || 0) * 100,
                proportionBloqued:
                    (100 * share.valoBlackList) / (share.share * share.nbShare),
                typeShare: share.typeShare,
            };
        }
    }
    // Method that give the initial value of share of a user at a given Funds
    getInitialValueOfShare(date: Date, fondsId: number): number {
        const initialShare: number = this.shareDate
            .filter(
                (share) =>
                    isDateClosed(new Date(share.date), date) &&
                    share.fondsId === fondsId
            )
            .reduce((sumShare, share) => {
                return sumShare + share.initialShare * share.nbShare;
            }, 0);

        return initialShare / this.getNbShareUserByFunds(date, fondsId);
    }

    getPerformanceShare(date: Date, shareId: number): number {
        const share: ShareDto = this.getShareById(shareId, date);
        const performance: number =
            (this.getShareValue(shareId, date) - share.initialShare) /
            share.initialShare;
        return performance;
    }

    // ------------------- Methods that give overall results for a user for a given funds -----------------

    allInfoUser(date: Date): IFondsInfoUser[] {
        const res: IFondsInfoUser[] = this.fundsInvested?.map((fonds) => {
            const transactions = this.transactionsInfo(date, fonds.id);
            return {
                fondsId: fonds.id,
                fondsName: fonds.name,
                infoCombined: this.shareCombinedInFunds(transactions, fonds.id),
                transactions: transactions,
            };
        });

        return res;
    }

    allInfoUserByFunds(
        date: Date,
        fondsId: number
    ): IFondsInfoUser | undefined {
        const fonds = this.fundsInvested.find((f) => f.id === fondsId);
        if (fonds === undefined) return undefined;
        const transactions = this.transactionsInfo(date, fonds.id);

        return {
            fondsId: fonds.id,
            fondsName: fonds.name,
            infoCombined: this.shareCombinedInFunds(transactions, fondsId),
            transactions: transactions,
        };
    }

    // Method that give info for each transaction of a user in a given funds
    transactionsInfo(date: Date, fondsId: number): ITransactionInfo[] {
        // get transaction for one funds
        const transactions: number[] = this.shareDate
            .filter((s) => s.fondsId === fondsId)
            .reduce((acc: number[], share) => {
                if (acc.includes(share.transactionId)) {
                    return acc;
                } else {
                    return [...acc, share.transactionId];
                }
            }, []);

        const res: ITransactionInfo[] = transactions.map(
            (transactionId: number) => {
                const transactionBasicInfo = this.getTransactionInfo(
                    date,
                    transactionId
                );
                return transactionBasicInfo;
            }
        );
        return res;
    }

    // Method which creat an array of all the information of a user in a fonds
    // private shareCombinedInFundsArray(date: Date): IShareCombinedInFunds[] {

    //     let shareCombinedInFunds: IShareCombinedInFunds[] = this.fundsInvested.map((fonds) => {

    //         return {
    //             FondsId: fonds.id,
    //             share: this.getShareUserByFunds(date, fonds.id),
    //             nbShare: this.getNbShareUserByFunds(date, fonds.id),
    //             performances: this.getPerformancesUserByFunds(date, fonds.id),
    //             tri: this.getTriByFunds(date, fonds.id),
    //             initialAmount: this.getInitialAmountByFunds(date, fonds.id),
    //             firstDate: this.getFirstTransactionInFunds(fonds.id),
    //             initialValueOfShare: this.getInitialValueOfShare(date, fonds.id),
    //         }
    //     })
    //     return shareCombinedInFunds
    // }

    shareCombinedInFunds(
        transactionsInfo: ITransactionInfo[],
        fondsId: number
    ): IShareCombinedInFunds {
        let share: number = transactionsInfo.reduce((acc, transaction) => {
            if (transaction.typeShare === 'cumulative') {
                return acc + transaction.share * transaction.nbShare;
            } else {
                return (
                    acc +
                    transaction?.initialValueOfShare *
                        (1 + transaction.performance / 100) *
                        transaction.nbShare
                );
            }
        }, 0);

        const nbShare: number = transactionsInfo.reduce((acc, transaction) => {
            return acc + transaction.nbShare;
        }, 0);

        share = share / nbShare;

        const totalAmount: number = transactionsInfo.reduce(
            (acc, transaction) => acc + transaction.share * transaction.nbShare,
            0
        );

        const initialAmount: number = transactionsInfo.reduce(
            (acc, transaction) => {
                return acc + transaction.initialAmount;
            },
            0
        );

        const initialValueOfShare: number = transactionsInfo.reduce(
            (acc, transaction) => {
                return (
                    acc +
                    (transaction.initialValueOfShare *
                        (transaction.share * transaction.nbShare)) /
                        totalAmount
                );
            },
            0
        );

        const firstDate: Date = transactionsInfo.reduce((acc, transaction) => {
            if (acc < new Date(transaction.dateStartInvestisseur)) {
                return acc;
            } else {
                return new Date(transaction.dateStartInvestisseur);
            }
        }, new Date());

        const performances: number = transactionsInfo.reduce(
            (acc, transaction) =>
                acc +
                (transaction.performance *
                    (transaction.share * transaction.nbShare)) /
                    totalAmount,
            0
        );

        const tri = transactionsInfo.reduce(
            (acc, transaction) =>
                acc +
                (transaction.tri * (transaction.share * transaction.nbShare)) /
                    totalAmount,
            0
        );

        const proportionBloqued = transactionsInfo.reduce(
            (acc, transaction) =>
                acc +
                (transaction.proportionBloqued *
                    (transaction.share * transaction.nbShare)) /
                    totalAmount,
            0
        );

        return {
            FondsId: fondsId,
            share: share,
            nbShare: nbShare,
            performances: performances,
            tri: tri,
            initialAmount: initialAmount,
            firstDate: firstDate,
            initialValueOfShare: initialValueOfShare,
            proportionBloqued: proportionBloqued,
        };
    }

    // Method which gives the performance of a User in a funds
    getPerformancesUserByFunds(date: Date, fondsId: number): number {
        const firstTransaction = this.getFirstTransactionInFunds(fondsId);
        if (compareTwoDate(date, nextDay(add30Days(firstTransaction))))
            return 0;
        const [performances, totalAmount] = this.shareDate
            .filter(
                (share) =>
                    isDateClosed(new Date(share.date), date) &&
                    share.fondsId === fondsId
            )
            .reduce(
                ([sumPerformances, total], share) => {
                    return ([sumPerformances, total] = [
                        sumPerformances +
                            this.getPerformanceShare(
                                date,
                                share.transactionId
                            ) *
                                this.getShareValue(share.transactionId, date) *
                                share.nbShare,
                        total +
                            this.getShareValue(share.transactionId, date) *
                                share.nbShare,
                    ]);
                },
                [0, 0]
            );

        return (performances / totalAmount) * 100;
    }

    getTriByFunds(date: Date, fondsId: number): number {
        const firstTransaction = this.getFirstTransactionInFunds(fondsId);

        if (compareTwoDate(date, nextDay(add30Days(firstTransaction))))
            return 0;

        const [tri, totalAmount] = this.shareDate
            .filter(
                (share) =>
                    isDateClosed(new Date(share.date), date) &&
                    share.fondsId === fondsId &&
                    compareTwoDate(
                        nextDay(
                            add30Days(new Date(share.dateStartInvestisseur))
                        ),
                        date
                    )
            )
            .reduce(
                ([sumTri, total], share) => {
                    const valo =
                        this.getShareValue(share.transactionId, date) *
                        share.nbShare;
                    const n = this.nbDays(share, date) / 365;
                    return ([sumTri, total] = [
                        sumTri +
                            (Math.pow(
                                this.getShareValue(share.transactionId, date) /
                                    share.initialShare,
                                1 / n
                            ) -
                                1) *
                                valo,
                        total + valo,
                    ]);
                },
                [0, 0]
            );

        // let [performances, totalAmount] = this.shareDate.filter((share) => share.fondsId === fondsId && isDateClosed(new Date(share.date), date) && compareTwoDate(nextDay(add30Days(new Date(share.dateStartInvestisseur))), date)).reduce(([sumPerformances, total], share) => {
        //     return [sumPerformances, total] = [sumPerformances + this.getPerformanceShare(date, share.transactionId) * (365 / numberDayBetweenDate(add30Days(new Date(share.dateStartInvestisseur)), date)) * this.getShareValue(share.transactionId, date) * share.nbShare, total + this.getShareValue(share.transactionId, date) * share.nbShare];
        // }, [0, 0])

        return (tri / totalAmount) * 100;
    }

    // Method returning the share at the given date of an user ponderated with all his transactions
    // The share is ponderated with the share of the user at the date of the transaction
    getShareUserByFunds(date: Date, fondsId: number): number {
        const sumShares: number = this.shareDate
            .filter(
                (share) =>
                    isDateClosed(new Date(share.date), date) &&
                    share.fondsId === fondsId
            )
            .reduce((sumShare, share) => {
                return (
                    sumShare +
                    this.getShareValue(share.transactionId, date) *
                        share.nbShare
                );
            }, 0);

        return sumShares / this.getNbShareUserByFunds(date, fondsId);
    }

    // Mtethod returning the number of share at the given date of an user
    getNbShareUserByFunds(date: Date, fondsId: number): number {
        const nbShare = this.shareDate
            .filter(
                (share) =>
                    isDateClosed(new Date(share.date), date) &&
                    share.fondsId === fondsId
            )
            .reduce((sumNbShare, share) => {
                return sumNbShare + share.nbShare;
            }, 0);
        return nbShare;
    }

    getInitialAmountByFunds(date: Date, fondsId: number): number {
        const initialAmount = this.shareDate
            .filter(
                (share) =>
                    isDateClosed(new Date(share.date), date) &&
                    share.fondsId === fondsId
            )
            .reduce((sumInitialAmount, share) => {
                return sumInitialAmount + share.initialAmount;
            }, 0);
        return initialAmount;
    }

    // ------------------- Methods that give overall resutls of a user -----------------

    getPerformancesUser(date: Date): number {
        const firstTransaction = this.getFirstTransactionDate();
        if (compareTwoDate(date, nextDay(add30Days(firstTransaction))))
            return 0;

        const [performances, totalAmount] = this.shareDate
            .filter(
                (share) =>
                    isDateClosed(new Date(share.date), date) &&
                    compareTwoDate(
                        nextDay(
                            add30Days(new Date(share.dateStartInvestisseur))
                        ),
                        date
                    )
            )
            .reduce(
                ([sumPerformances, total], share) => {
                    return ([sumPerformances, total] = [
                        sumPerformances +
                            this.getPerformanceShare(
                                date,
                                share.transactionId
                            ) *
                                this.getShareValue(share.transactionId, date) *
                                share.nbShare,
                        total +
                            this.getShareValue(share.transactionId, date) *
                                share.nbShare,
                    ]);
                },
                [0, 0]
            );

        return (performances / totalAmount) * 100;
    }

    getTri(date: Date): number {
        const firstTransaction = this.getFirstTransactionDate();
        if (compareTwoDate(date, nextDay(add30Days(firstTransaction)))) {
            return 0;
        } else {
            const [tri, totalAmount] = this.shareDate
                .filter(
                    (share) =>
                        isDateClosed(new Date(share.date), date) &&
                        compareTwoDate(
                            nextDay(
                                add30Days(new Date(share.dateStartInvestisseur))
                            ),
                            date
                        )
                )
                .reduce(
                    ([sumTri, total], share) => {
                        const valo =
                            this.getShareValue(share.transactionId, date) *
                            share.nbShare;
                        const n = this.nbDays(share, date) / 365;
                        return ([sumTri, total] = [
                            sumTri +
                                (Math.pow(
                                    this.getShareValue(
                                        share.transactionId,
                                        date
                                    ) / share.initialShare,
                                    1 / n
                                ) -
                                    1) *
                                    valo,
                            total + valo,
                        ]);
                    },
                    [0, 0]
                );

            return (tri / totalAmount) * 100;
        }
    }

    nbDays(share: ShareDto, date: Date): number {
        const dateStartInvestisseur = new Date(share.dateStartInvestisseur);
        const dateInit = this.getDateInitForShare(
            dateStartInvestisseur,
            share.fondsId
        );
        // if (import.meta.env.VITE_API_URL === "http://localhost:5002/api/") fondsComparaison = 5
        return numberDayBetweenDate(dateInit, date);
    }

    getFirstTransactionInFunds(fondsId: number): Date {
        return new Date(
            this.shareDate
                .filter((share) => share.fondsId === fondsId)
                .sort(
                    (a, b) =>
                        new Date(a.date).getTime() - new Date(b.date).getTime()
                )?.[0]?.dateStartInvestisseur
        );
    }

    getFirstTransactionDate(): Date {
        return new Date(
            this.shareDate.sort(
                (a, b) =>
                    new Date(a.dateStartInvestisseur).getTime() -
                    new Date(b.dateStartInvestisseur).getTime()
            )?.[0]?.dateStartInvestisseur
        );
    }

    getTotalAmountUser(date: Date): number {
        const totalAmount = this.shareDate
            .filter((share) => isDateClosed(new Date(share.date), date))
            .reduce((sumAmount, share) => {
                return (
                    sumAmount +
                    this.getShareValue(share.transactionId, date) *
                        share.nbShare
                );
            }, 0);
        return totalAmount;
    }

    //Method that gives all the funds invested by a user
    getNbFundsInvestedByUser(): number {
        const fundsInvestedByUser: number[] = this.shareDate.reduce(
            (acc: number[], share) => {
                if (acc.includes(share.fondsId)) {
                    return acc;
                } else {
                    return [...acc, share.fondsId];
                }
            },
            []
        );
        return fundsInvestedByUser.length;
    }

    getFundsInvestedByUser(): number[] {
        const fundsInvestedByUser: number[] = this.shareDate.reduce(
            (acc: number[], share) => {
                if (acc.includes(share.fondsId)) {
                    return acc;
                } else {
                    return [...acc, share.fondsId];
                }
            },
            []
        );
        return fundsInvestedByUser;
    }

    getMontantInvestedByUser(date: Date): number {
        const totalAmount = this.shareDate
            .filter((share) => isDateClosed(new Date(share.date), date))
            .reduce((sumAmount, share) => {
                return sumAmount + share.initialAmount;
            }, 0);
        return totalAmount;
    }

    getMoneyEarned(date: Date): number {
        const totalAmount = this.shareDate
            .filter((share) => isDateClosed(new Date(share.date), date))
            .reduce((sumAmount, share) => {
                return (
                    sumAmount +
                    this.getShareValue(share.transactionId, date) *
                        share.nbShare -
                    share.initialAmount
                );
            }, 0);
        return totalAmount;
    }
}
