import { Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { formatDate } from "../../function/Utils";
import { ISocketMessage, useSocket } from "../Context/socket-context";

const SocketComponent: React.FC<ISocketMessage> = (props) => {
  return (
    <Transition
      show={true}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-center">
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">
                {props?.date ? formatDate(props.date) : null}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                {props?.progress ?? null}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                {props?.message ?? null}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default SocketComponent;
