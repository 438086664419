import { faArrowRight, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const ContactPopUp = () => {
    const [choseToggle, setChoseToggle] = useState(false);
    const [hover, setHover] = useState(false);

    const navigate = useNavigate();

    return (
        <div
            className="fixed bottom-5 right-4"
            onMouseLeave={() => {
                setHover(false);
                setChoseToggle(false);
            }}
        >
            <motion.div
                className="py-1 px-2 cursor-pointer flex items-center gap-2 rounded-md  w-fit"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setChoseToggle(!choseToggle)}
                onHoverStart={() => setHover(true)}
            >
                {!hover ? (
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.2 }}
                        className=""
                    ></motion.div>
                ) : null}
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            backgroundColor: `${hover ? 'rgba(200, 200, 200, 0.3)' : ''}`,
                        }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.2 }}
                        className="rounded-[50px] py-1 px-3"
                    >
                        <div className="flex gap-3 items-center">
                            {hover ? (
                                <p className="test font-titleFontFamily text-mainColor">
                                    Une question ?
                                </p>
                            ) : null}
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className="text-mainColor text-3xl cursor-pointer"
                            />
                        </div>
                    </motion.div>
                </AnimatePresence>
                {/* <p className="test font-titleFontFamily text-mainColor">
          Une question ?
        </p> */}
            </motion.div>

            <AnimatePresence>
                {choseToggle ? (
                    <div className="absolute flex flex-col bottom-[50px] left-0 w-full h-full  bg-opacity-50 justify-center items-center">
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{
                                duration: 0.1,
                                delay: 0.1,
                            }}
                            whileHover={{ translateX: 1.15 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={async () => {
                                // await handlePdfClick();
                            }}
                            className="mb-1 z-10 relative cursor-pointer w-[200px] mt-2 px-2 py-1 flex gap-1 items-center rounded-md opacity-80 bg-white shadow-low"
                        >
                            Consulter notre FAQ
                            <div className="absolute right-1 ">
                                <FontAwesomeIcon
                                    icon={faArrowRight}
                                    className="text-titleColor"
                                />
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            whileHover={{ translateX: 1.15 }}
                            whileTap={{ scale: 0.95 }}
                            className="px-2 z-10 py-1 relative cursor-pointer w-[200px] flex gap-1 items-center rounded-md opacity-80 bg-white shadow-low"
                            transition={{
                                duration: 0.1,
                            }}
                            onClick={() => navigate('/Contact')}
                        >
                            {/* <FontAwesomeIcon
                icon={faArrowTurnRight}
                className="text-titleColor"
              /> */}
                            Nous contacter
                            <div className="absolute right-1 ">
                                <FontAwesomeIcon
                                    icon={faArrowRight}
                                    className="text-titleColor"
                                />
                            </div>
                        </motion.div>
                    </div>
                ) : null}
            </AnimatePresence>
        </div>
    );
};

export default ContactPopUp;
