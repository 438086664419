import {
    TransactionUserDto,
    TypeTransaction,
    useFetchTokenQuery,
    useGetTransactionsUserQuery,
} from '@api/api';
import React, { useEffect, useState } from 'react';
import {
    filterFunctions,
    formatDate,
    isEmpty,
    printLargeValue,
} from '../../../function/Utils';
import { DisplayTypeTransaction } from '../../../function/functionUtilis';

import { typeFiltration } from '@interfaces/BackOffice';
import ExportComponent from '../../commun/ExportComponent';
import Loading from '../../commun/Loading';
import StatusTransaction from '../../commun/StatusTransaction';
import FilterApparition from '../BackOffice/Components/FilterApparition';
import FiltrationColumnTable, {
    filtrationInterface,
    typeOrderOrFiltration,
} from '../BackOffice/Components/FiltrationColumnTable';

export function Transaction({ userId }: { userId: number }) {
    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();
    const { data: transactions, isLoading: isLoadingTransactions } =
        useGetTransactionsUserQuery(userId);

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold text-right',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap  text-right',
    };

    const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
    const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);
    const [filterColumn, setFilterColumn] = useState<
        filtrationInterface<TransactionUserDto>[]
    >([
        {
            element: 'date',
            type: typeFiltration.date,
            value: 'decroissant',
            orderOrFiltration: typeOrderOrFiltration.order,
        },
    ]);

    const [transactionsDataFiltered, setTransactionsDataFiltered] = useState<
        TransactionUserDto[]
    >([]);

    const filtrationFunction = () => {
        let transactionDataFiltered_init: TransactionUserDto[] =
            transactions ?? [];
        for (const item of filterColumn) {
            transactionDataFiltered_init = filterFunctions(
                transactionDataFiltered_init,
                item.element,
                item.type,
                item.value
            );
        }
        setTransactionsDataFiltered(transactionDataFiltered_init);
    };

    useEffect(() => {
        if (!isEmpty(transactions)) {
            filtrationFunction();
        }
    }, [filterColumn, transactions]);

    return (
        <>
            <div className="w-full relative mb-5">
                <h2 className="text-center font-titleFontFamily text-2xl font-semibold">
                    Historique de vos transactions
                </h2>
            </div>

            <div className="relative">
                <FilterApparition
                    startIntervalNbItems={startIntervalNbItems}
                    setNbItemsShowed={setNbItemsShowed}
                    nbItemsShowed={nbItemsShowed}
                    setStartIntervalNbItems={setStartIntervalNbItems}
                    array={transactionsDataFiltered!}
                >
                    <div className="relative md:absolute md:top-[-10px] md:right-3">
                        <ExportComponent
                            headers={[
                                'Type',
                                'Fonds',
                                'Montant',
                                'Valeur de part',
                                'Nombre de part',
                                'Plus-value',
                                'Date',
                                'Statut',
                            ]}
                            title="Historique des transactions"
                            userData={{
                                name: `${user?.lastName.toLocaleUpperCase()} ${
                                    user?.firstName
                                }`,
                            }}
                            data={transactionsDataFiltered.map((val) => {
                                return {
                                    Type:
                                        val.type === TypeTransaction.userCash
                                            ? 'Investis'
                                            : 'Retrait',
                                    Fonds: val.fondsName,
                                    Montant: printLargeValue(
                                        val.amount.toFixed(2)
                                    ),
                                    ValeurPart: val.share?.toFixed(2) ?? 100,
                                    NombrePart: val.nbShare.toFixed(2),
                                    PlusValue: val.capitalGain,
                                    Date: formatDate(new Date(val.date)),
                                    Statut: val.status,
                                };
                            })}
                        />
                    </div>
                </FilterApparition>
                <div className="flex flex-col max-w-full overflow-x-auto">
                    <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
                        <div className="py-2 inline-block min-w-full">
                            <div className="overflow-hidden">
                                {/* <p>
      Valeur total du fonds{" "}
      {printLargeValue(
        projectValorisationInfoData?.valorisationByProjects?.toFixed(
          2
        )
      )}
    </p> */}
                                <table className="min-w-full">
                                    <thead className="bg-white border-b border-t">
                                        <tr>
                                            <FiltrationColumnTable
                                                element={'type'}
                                                type={typeFiltration.exact}
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.filter
                                                }
                                                optionValues={[
                                                    {
                                                        value: 'all',
                                                        label: 'Tous',
                                                    },
                                                    {
                                                        value: TypeTransaction.userCash,
                                                        label: 'Investis',
                                                    },
                                                    {
                                                        value: TypeTransaction.cashUser,
                                                        label: 'Retrait',
                                                    },
                                                ]}
                                                columnName={() => (
                                                    <p className="inline-block text-left">
                                                        Type
                                                    </p>
                                                )}
                                                textLeft={true}
                                            />
                                            <FiltrationColumnTable
                                                element={'fondsName'}
                                                type={typeFiltration.exact}
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                columnName={() => (
                                                    <p className="inline-block">
                                                        Fonds
                                                    </p>
                                                )}
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.filter
                                                }
                                                optionValues={[
                                                    {
                                                        value: 'all',
                                                        label: 'Tous',
                                                    },

                                                    ...(
                                                        transactions ?? []
                                                    )?.reduce(
                                                        (
                                                            acc: {
                                                                value: string;
                                                                label: string;
                                                            }[],
                                                            item
                                                        ) => {
                                                            if (
                                                                !acc
                                                                    .map(
                                                                        (v) =>
                                                                            v.value
                                                                    )
                                                                    .includes(
                                                                        item.fondsName
                                                                    )
                                                            ) {
                                                                return acc.concat(
                                                                    [
                                                                        {
                                                                            value: item.fondsName,
                                                                            label: item.fondsName,
                                                                        },
                                                                    ]
                                                                );
                                                            }
                                                            return acc;
                                                        },
                                                        []
                                                    ),
                                                ]}
                                                textLeft={true}
                                            />

                                            <FiltrationColumnTable
                                                element={'amount'}
                                                type={typeFiltration.amount}
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                columnName={() => (
                                                    <p className="inline-block">
                                                        Montant
                                                    </p>
                                                )}
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.order
                                                }
                                                textLeft={false}
                                            />

                                            <th
                                                scope="col"
                                                className={classNameObejct.head}
                                            >
                                                Valeur part
                                            </th>
                                            <th
                                                scope="col"
                                                className={classNameObejct.head}
                                            >
                                                Nombre part
                                            </th>

                                            <FiltrationColumnTable
                                                element={'capitalGain'}
                                                type={typeFiltration.amount}
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                columnName={() => (
                                                    <p className="text-center">
                                                        Plus-value
                                                    </p>
                                                )}
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.order
                                                }
                                                textLeft={false}
                                            />

                                            <FiltrationColumnTable
                                                element={'date'}
                                                type={typeFiltration.date}
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                columnName={() => (
                                                    <p className="inline-block text-center">
                                                        Date
                                                    </p>
                                                )}
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.order
                                                }
                                                textLeft={false}
                                            />
                                            <th
                                                scope="col"
                                                className={classNameObejct.head}
                                            >
                                                Statut
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoadingTransactions ? (
                                            <tr className="">
                                                <td
                                                    colSpan={8}
                                                    className="pt-10 text-center mx-auto"
                                                >
                                                    <Loading />
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {transactions &&
                                                transactions?.length === 0 ? (
                                                    <tr className="w-full mx-auto">
                                                        <td
                                                            colSpan={8}
                                                            className="text-center text-gray-500 text-xl  pt-10"
                                                        >
                                                            Aucune transaction
                                                            touvée
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    transactionsDataFiltered && (
                                                        <React.Fragment>
                                                            {transactionsDataFiltered
                                                                .filter(
                                                                    (
                                                                        val,
                                                                        index
                                                                    ) =>
                                                                        index >=
                                                                            startIntervalNbItems &&
                                                                        index <
                                                                            startIntervalNbItems +
                                                                                nbItemsShowed
                                                                )
                                                                .map(
                                                                    (
                                                                        transaction,
                                                                        key
                                                                    ) => (
                                                                        <RowElement
                                                                            transaction={
                                                                                transaction
                                                                            }
                                                                            key={
                                                                                key
                                                                            }
                                                                            number={
                                                                                key
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function RowElement({
    transaction,
    number,
}: {
    transaction: TransactionUserDto;
    number: number;
}) {
    const classNameObejct = {
        item: 'text-sm text-center font-light px-6 py-4 whitespace-nowrap',
    };

    return (
        <tr
            className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                number % 2 === 0 ? 'bg-secondBackgroundColor' : 'bg-white '
            } `}
        >
            <td className={`${classNameObejct.item}`}>
                {DisplayTypeTransaction(transaction.type)}
            </td>
            <td className={`${classNameObejct.item}`}>
                {transaction.fondsName ?? 'Fonds inconnu'}
            </td>
            <td className={`${classNameObejct.item}`}>
                {printLargeValue(transaction.amount.toFixed(2))}
            </td>
            <td className={`${classNameObejct.item}`}>
                {transaction.share?.toFixed(2) ?? 100}
            </td>
            <td className={`${classNameObejct.item}`}>
                {transaction.nbShare.toFixed(2)}
            </td>
            <td className={`${classNameObejct.item}`}>
                {transaction.type === TypeTransaction.userCash
                    ? '-'
                    : printLargeValue(
                          (transaction.capitalGain as any).toFixed(2)
                      )}
            </td>
            <td className={`${classNameObejct.item}`}>
                {formatDate(new Date(transaction.date))}
            </td>
            <td className={`${classNameObejct.item}`}>
                <StatusTransaction status={transaction.status} />
            </td>
        </tr>
    );
}
