import {
    api as generatedApi,
    VerifyValidationTokenApiArg,
    VerifyValidationTokenApiResponse,
} from '@api/api';

export const validationCodeApi = generatedApi.injectEndpoints({
    endpoints: (builder) => ({
        verifyValidationTokenOverrided: builder.mutation<
            VerifyValidationTokenApiResponse,
            VerifyValidationTokenApiArg
        >({
            query: (queryArg) => ({
                url: `/validationCode/token/${queryArg}`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: true,
});

export const { useVerifyValidationTokenOverridedMutation } = validationCodeApi;
