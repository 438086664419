import React, { ReactNode } from 'react';
import {
    Control,
    FieldErrorsImpl,
    FieldValues,
    Path,
    UseControllerProps,
    UseFormRegister,
    UseFormWatch,
    useController,
} from 'react-hook-form';

interface Props<T extends FieldValues> {
    register: UseFormRegister<T>;
    watch: UseFormWatch<T>;
    errors?: FieldErrorsImpl<T>;
    name: Path<T> & keyof T;
    showGoodAnswer?: boolean;
    id?: string;
    values: {
        label: string;
        value: string | number;
        title?: string;
        goodAnswer?: boolean;
    }[];
    cursorNotAllowed?: true;
    control: Control<T, Path<T>>;
}

const RadioArrayComponent = <T extends object>(
    props: Props<T> & { children?: ReactNode }
) => {
    const {
        field: {
            value: property,
            onChange: propertyOnChange,
            ...restPropertyField
        },
    } = useController({
        name: props.name,
        control: props.control,
    });

    const jsxFunction = (): JSX.Element => {
        return (
            <>
                {props.values?.map((value, key) => (
                    <div
                        key={`${props.id ?? ''}_${key}`}
                        className="checkbox_containe my-2 mx-1 form-item form-check"
                    >
                        <div className="">
                            {value.title ? (
                                <p className=" font-titleFontFamily text-[1.1rem] mb-2 font-semibold text-mainColor">
                                    {value.title}
                                </p>
                            ) : null}
                            <div className="flex gap-2 justify-start items-start relative">
                                <input
                                    type="radio"
                                    //   name={props.name}
                                    value={value.value}
                                    id={`${props.name}_${key}-${props.id ?? ''}`}
                                    defaultChecked={
                                        (
                                            props?.watch(props.name) as any[]
                                        )?.includes(value.value) && true
                                    }
                                    {...props.register(props.name, {
                                        required: true,
                                    })}
                                    required
                                    style={{ width: '15px', height: '15px' }}
                                    className={`form-check-input absolute top-[1px] ${
                                        props?.cursorNotAllowed &&
                                        'pointer-events-none'
                                    } `}
                                />
                                <label
                                    className={`pointer ml-7 text-start ${
                                        value.goodAnswer && props.showGoodAnswer
                                            ? 'text-green-500 '
                                            : ''
                                    } ${
                                        (
                                            props?.watch(props.name) as any[]
                                        )?.includes(value.value) &&
                                        !value.goodAnswer &&
                                        props.showGoodAnswer
                                            ? 'text-red-500'
                                            : ''
                                    }`}
                                    htmlFor={`${props.name}_${key}-${props.id ?? ''}`}
                                >
                                    {value.label}
                                </label>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    };
    return (
        <React.Fragment>
            {props.children}
            {props.cursorNotAllowed ? (
                <div className="cursor-not-allowed w-full">{jsxFunction()}</div>
            ) : (
                jsxFunction()
            )}
        </React.Fragment>
    );
};

export default RadioArrayComponent;

function Input<T extends FieldValues>(props: UseControllerProps<T>) {
    const { field, fieldState } = useController(props);

    return (
        <div>
            <input {...field} placeholder={props.name} />
            <p>{fieldState.isTouched && 'Touched'}</p>
            <p>{fieldState.isDirty && 'Dirty'}</p>
            <p>{fieldState.invalid ? 'invalid' : 'valid'}</p>
        </div>
    );
}
