// HoverTextWrapper.tsx
import React, { useState } from "react";
import { motion } from "framer-motion";

interface HoverTextWrapperProps {
  children: React.ReactElement; // Ensures children is a single React element
  hoverText: string;
}

const HoverTextWrapper: React.FC<HoverTextWrapperProps> = ({
  children,
  hoverText,
}) => {
  // State to manage hover status
  const [isHovered, setIsHovered] = useState(false);

  // Variants for the hover text appearance
  const variants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 },
  };

  // Clone the child element to attach hover event handlers
  const childWithProps = React.cloneElement(children, {
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
    style: { ...children.props.style, zIndex: 2 }, // Ensure child is above the text overlay
  });

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {childWithProps}
      {isHovered && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          className=" w-fit"
          variants={variants}
          transition={{ duration: 0.5 }}
          style={{
            position: "absolute",
            bottom: "100%", // Position the text below the icon; adjust as needed
            // left: "25%",
            right: "25%",
            // set in the middle of the children

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.7)", // Semi-transparent background
            color: "white",
            padding: "8px",
            borderRadius: "4px",
            zIndex: 1, // Ensure text appears above the background but below the child in stacking context
          }}
        >
          {hoverText}
        </motion.div>
      )}
    </div>
  );
};

export default HoverTextWrapper;
