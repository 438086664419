import { ProjectForUserDto } from '@api/api';
import { Icon } from 'leaflet';
import React, { useCallback } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { useAppSelector } from '../../../redux/store';
import LocationIcon from './../../../assets/map-marker-svgrepo-com.svg';

interface Props {
    project?: ProjectForUserDto;
    height: string;
    width: string;
    className?: string;
}

const ProjectMap: React.FC<Props> = ({ project, height, width, className }) => {
    const measurement = useAppSelector((state) => state.pageMeasurement);

    const Mapcomponent = useCallback(() => {
        return (
            <>
                {project && project?.latitude && project?.longitude ? (
                    <div
                        className={`flex w-full justify-center items-center ${className}`}
                    >
                        <MapContainer
                            center={[project.latitude, project.longitude]}
                            zoom={5}
                            style={{ height: height, width: width }}
                            scrollWheelZoom={false}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker
                                icon={
                                    new Icon({
                                        iconUrl: LocationIcon,
                                        iconSize: [25, 41],
                                        iconAnchor: [10, 41],
                                    })
                                }
                                key={project.id}
                                position={[project.latitude, project.longitude]}
                            />
                        </MapContainer>
                    </div>
                ) : null}
            </>
        );
    }, [measurement?.measurements.widthScreen, project]);
    return (
        <>
            <Mapcomponent />
        </>
    );
};

export default ProjectMap;
