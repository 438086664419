import { CategoryQuestion } from '@api/api';
import DisplayCategoriesQuestion, {
    CategoryQuestionConfig,
} from './CommunComponent/DisplayCategoriesQuestion';

const FormulaireEtapeKnowledge = () => {
    const categories: CategoryQuestionConfig[] = [
        {
            category: CategoryQuestion.fundsFunctionning,
            showGoogdAnswer: true,
        },
        {
            category: CategoryQuestion.assets,
            showGoogdAnswer: false,
        },
    ];

    return (
        <DisplayCategoriesQuestion
            categories={categories}
            stepQuestionName="stepKnowledgeQuestionFormProfil"
        />
    );
};

export default FormulaireEtapeKnowledge;
