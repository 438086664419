import React from "react";
import Spinner from "../../commun/Spinner";

interface Props {
  size?: number;
  element: string;
}

const ElementNotComputedComponent: React.FC<Props> = ({ size, element }) => {
  return (
    <div className="flex justify-center gap-3 items-center mt-5 ">
      <div className="">
        <Spinner size={size ?? 4} />
      </div>

      <p className="font-titleFontFamily">
        {element} ne sont pas encore calculées pour cette date.
      </p>
    </div>
  );
};

export default ElementNotComputedComponent;
