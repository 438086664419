import React, { useEffect, useMemo, useState } from 'react';
import { displayMilionNumber } from '../../../function/Utils';
import { useGetSyntheticInfoFondsQuery } from '../../../redux/features/fondsSlice';
import { ISeries } from '../../commun/Charts/AreaChart';
import BarChartApex from '../../commun/Charts/BarChartApex';
import PieReChart, { IReChartPieSeries } from '../../commun/Charts/PieReChart';
import TwoLevelPieChart, {
    IReChartPieOuterSeries,
} from '../../commun/Charts/TwoLevelPieChart';
import Loading from '../../commun/Loading';
import DisplayStatsV1 from '../../commun/Stats/DisplayStatsV1';
import { TitreFondsUser } from './FondsUser';

interface Props {
    fondsId: number;
    loading: boolean;
    tabRef: React.RefObject<HTMLDivElement>;
}

const SyntheticInfoFonds = ({ fondsId, loading, tabRef }: Props) => {
    const [newInvestisorData, setNewInvestisorData] = useState<boolean>(true);

    const {
        data: syntheticInfo,
        isLoading: isLoadingSyntheticInfo,
        isFetching: isFetchingSyntheticInfo,
    } = useGetSyntheticInfoFondsQuery(
        {
            fondsId: fondsId,
            newInvestisor: newInvestisorData,
        },
        {
            skip: fondsId === undefined,
        }
    );

    const [distributionProject, setDistributionProject] = useState<
        IReChartPieSeries[]
    >([]);

    useEffect(() => {
        if (!syntheticInfo) return;

        const res = syntheticInfo.proportion_project?.map((item) => ({
            name: item.name,
            value: parseFloat(item.proportion?.toFixed(2)),
        }));
        setDistributionProject(res);
    }, [syntheticInfo]);

    const dataOuter: IReChartPieOuterSeries[] = useMemo(() => {
        if (!distributionProject) return [];

        // distributionProject.sort((a, b) => a.value - b.value);
        const total = distributionProject.length;
        const quantileSize = Math.floor(total / 4);
        let rest = total % 4;
        const quantiles = [];
        let start = 0;
        let end = quantileSize;
        for (let i = 0; i < 4; i++) {
            if (rest > 0) {
                end = end + 1;
            }
            const quantileData = distributionProject?.slice(start, end);

            const sum = quantileData?.reduce(
                (acc: number, item: any) => acc + item.value,
                0
            );
            quantiles.push({
                name: `Q${i + 1}`,
                value: parseFloat(sum?.toFixed(2)),
                length: end - start,
            });
            start = end;
            rest = rest - 1;
            end = end + quantileSize;
        }

        return quantiles;
    }, [distributionProject]);

    const distributionPlatform: IReChartPieSeries[] = useMemo(() => {
        if (!syntheticInfo) return [];

        return syntheticInfo.proportion_platform?.map((item) => ({
            name: item.name,
            value: parseFloat(item.proportion?.toFixed(2)),
        }));
    }, [syntheticInfo]);

    const marketTypology: IReChartPieSeries[] = useMemo(() => {
        if (!syntheticInfo) return [];

        return syntheticInfo.proportion_market_typology.map((item) => ({
            name: item.name,
            value: parseFloat(item.proportion?.toFixed(2)),
        }));
    }, [syntheticInfo]);

    const nbProjectsInvestedPerYear: ISeries[] = useMemo(() => {
        if (!syntheticInfo) return [];

        return [
            {
                name: 'Nombre de projets',
                data: syntheticInfo.nb_project_yearly.map((item) => {
                    return {
                        x: item.year,
                        y: item.nb_project,
                    };
                }),
            },
        ];
    }, [syntheticInfo]);

    const stats = useMemo(() => {
        if (!syntheticInfo) return [];

        return [
            {
                name: 'Nombre de projets actuels',
                value: `${syntheticInfo?.nb_project}`,
            },
            {
                name: 'Nombre de projets financés',
                value: `${syntheticInfo?.nb_project_funded}`,
            },
            {
                name: 'En retard',
                value: `${syntheticInfo?.proportion_delayed?.toFixed(1)} % `,
            },

            {
                name: 'Défaut',
                value: `${syntheticInfo?.proportion_default?.toFixed(1)} %`,
            },
            {
                name: 'Projets immobilisés',
                value: `${syntheticInfo?.proportion_bl?.toFixed(1)} %`,
            },
            {
                name: 'investis',
                value: `${displayMilionNumber(syntheticInfo?.totalInvested)} €`,
            },
        ];
    }, [syntheticInfo]);

    const noProject = useMemo(() => {
        if (!syntheticInfo) return true;

        if (syntheticInfo?.nb_project === 0) return true;

        return false;
    }, [syntheticInfo]);

    const sectorProportion: IReChartPieOuterSeries[] = useMemo(() => {
        if (!syntheticInfo) return [];

        return syntheticInfo?.proportion_sector.map((s) => {
            return {
                name: s?.name,
                value: parseFloat(s.proportion?.toFixed(2)),
                length: s.proportion_sub_sector?.length,
            };
        });
    }, [syntheticInfo]);

    const subSectorProportion: IReChartPieSeries[] = useMemo(() => {
        if (!syntheticInfo) return [];

        return syntheticInfo?.proportion_sector
            .flatMap((s) => s.proportion_sub_sector)
            ?.map((sub) => {
                return {
                    name: sub.name,
                    value: parseFloat(sub.proportion?.toFixed(2)),
                };
            });
    }, [syntheticInfo]);

    const classNameTitreChart =
        'text-center text-lg font-bold font-mainFontFamily px-3 md:w-11/12 mx-auto';

    const offset = useMemo(() => {
        return (tabRef?.current?.getBoundingClientRect()?.height || 50) - 1;
    }, [tabRef?.current?.getBoundingClientRect()]);

    if (loading)
        return (
            <div className=" w-full flex items-center justify-center h-[90vh]">
                <Loading />
            </div>
        );

    const statsInfo = {
        title: 'Synthèse en quelques chiffres',
    };

    return (
        <>
            <TitreFondsUser>Portefeuille</TitreFondsUser>

            <div className=" mb-5">
                <p className=" text-justify pl-2 pr-5 md:pr-2 my-4 w-full md:w-11/12 mx-auto">
                    Cette section offre un aperçu du fonds, incluant une analyse
                    détaillée de sa diversification à travers différents
                    projets, plateformes de financement participatif et
                    secteurs d'activité.
                </p>

                <div
                    className={` z-20 sticky w-full flex justify-center items-center flex-col md:flex-row`}
                    style={{
                        top: `${offset}px`,
                    }}
                >
                    <p
                        className={`cursor-pointer w-full px-2 py-1 md:w-fit p-0 md:py-2 md:px-3 md:rounded-bl-md ${
                            !newInvestisorData
                                ? 'bg-backgroundColor border-2 text-gray-400'
                                : 'bg-mainColor text-white border-2 border-mainColor'
                        }`}
                        onClick={() => setNewInvestisorData(true)}
                    >
                        Données pour un nouvel investisseur
                    </p>
                    <p
                        className={`cursor-pointer  w-full md:w-fit px-2 py-1 md:py-2 md:px-3 md:rounded-br-md ${
                            newInvestisorData
                                ? 'bg-backgroundColor border-2 text-gray-400'
                                : 'bg-mainColor text-white border-2 border-mainColor '
                        }`}
                        onClick={() => setNewInvestisorData(false)}
                    >
                        Données sur le fonds dans son ensemble
                    </p>
                </div>
                {isLoadingSyntheticInfo || isFetchingSyntheticInfo ? (
                    <div className=" h-[90vh]">
                        <Loading />
                    </div>
                ) : (
                    <>
                        {noProject ? (
                            <div className="h-[30vh] flex justify-center items-center">
                                <h3 className="text-center text-lg  font-mainFontFamily px-3 ">
                                    Aucun projet financé pour le moment
                                </h3>
                            </div>
                        ) : (
                            <div className=" bg-backgroundColor pb-4">
                                <DisplayStatsV1
                                    stats={stats}
                                    title={statsInfo.title}
                                    className=" col-span-2 my-20"
                                >
                                    <div className="flex justify-center h-full items-center flex-col">
                                        <h3
                                            className={`text-center text-lg  font-mainFontFamily px-3 `}
                                        >
                                            Nombre de projets financés par an
                                        </h3>
                                        <BarChartApex
                                            horizontal={false}
                                            data={nbProjectsInvestedPerYear}
                                        />
                                    </div>
                                </DisplayStatsV1>
                                <div className=" md:col-span-2 h-[0.8px] w-11/12 mx-auto md:w-2/3 bg-mainColor mt-5 mb-7"></div>
                                <div className="grid w-full mx-auto md:grid-cols-2 items-start">
                                    <div className=" flex justify-around items-center flex-col bg-gray-400/5 py-2 h-full">
                                        <h3
                                            className={`${classNameTitreChart}`}
                                        >
                                            Distribution des projets (%)
                                        </h3>
                                        {distributionProject?.length > 8 ? (
                                            <>
                                                <TwoLevelPieChart
                                                    dataInner={
                                                        distributionProject
                                                    }
                                                    dataOuter={dataOuter}
                                                    insideColor="#624763"
                                                    k={2}
                                                />
                                                <p className=" text-center md:text-start mt-2">
                                                    Chaque quartile (Qi) possède
                                                    le même nombre de projets
                                                </p>
                                            </>
                                        ) : (
                                            <PieReChart
                                                data={distributionProject}
                                                k={2}
                                            />
                                        )}
                                    </div>
                                    <div className="flex justify-around items-center bg-gray-400/5 flex-col py-2 h-full">
                                        <h3
                                            className={`${classNameTitreChart}`}
                                        >
                                            Distribution des plateformes de
                                            crowdfunding utilisées (%)
                                        </h3>
                                        <PieReChart
                                            data={distributionPlatform}
                                            k={0}
                                        />
                                    </div>
                                    <div className="flex justify-around items-center flex-col py-2 h-full">
                                        <h3
                                            className={`${classNameTitreChart}`}
                                        >
                                            Distribution des types de marché (%)
                                        </h3>
                                        <PieReChart
                                            data={marketTypology}
                                            k={3}
                                        />
                                    </div>
                                    <div className=" flex justify-around items-center flex-col  py-2 h-full">
                                        <h3
                                            className={`${classNameTitreChart}`}
                                        >
                                            Distribution des types de projet (%)
                                        </h3>
                                        {sectorProportion?.length > 1 ? (
                                            <TwoLevelPieChart
                                                dataInner={subSectorProportion}
                                                dataOuter={sectorProportion}
                                                insideColor="#B1B7D1"
                                                k={1}
                                            />
                                        ) : (
                                            <PieReChart
                                                data={subSectorProportion}
                                                k={1}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default SyntheticInfoFonds;
