import { CategoryQuestion } from '@api/api';
import DisplayCategoriesQuestion, {
    CategoryQuestionConfig,
} from './CommunComponent/DisplayCategoriesQuestion';

const FormulaireEtapeInvestment = () => {
    const categories: CategoryQuestionConfig[] = [
        {
            category: CategoryQuestion.targetInvestissments,
            showGoogdAnswer: false,
        },
        {
            category: CategoryQuestion.experienceInvestissments,
            showGoogdAnswer: false,
        },
    ];

    return (
        <DisplayCategoriesQuestion
            categories={categories}
            stepQuestionName="stepQuestionFormProfil"
        />
    );
};

export default FormulaireEtapeInvestment;
