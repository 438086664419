import { useContext } from 'react';
import { MenuDashBoardUserBoolState } from '../../Context/AppContext';
import WrapComponentDashboardUser from '../commun/WrapComponentDashboardUser';

const Documents = () => {
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    return (
        <div
            className={`mx-auto  md:mt-0 lg:px-2 md:pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto `}
        >
            <WrapComponentDashboardUser
                title="Documents"
                description="Ici vous pourrez télécharger des documents sur les projets investis."
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
            >
                <div className="flex overflow-auto justify-center items-center h-[70vh]">
                    <p>Cette page est en construction</p>
                </div>
            </WrapComponentDashboardUser>
        </div>
    );
};

export default Documents;
