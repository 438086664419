import {
    UpdateCustomUserFeeDto,
    useDeleteCustomUserFeeMutation,
    useGetCustomUserFeeByIdQuery,
    useUpdateCustomUserFeeMutation,
} from '@api/api';
import { PrimaryButton, WhiteButton } from '@components/commun/Buttons';
import useDeleteItem from '@components/commun/CustomHook/useDeleteItem';
import CustomUserFeeForm, {
    CreateOrUpdateCustomUserFeeFormDto,
} from '@components/commun/formComponent/ModelForm/CustomUserFee/CustomUserFeeForm';
import PopUp from '@components/commun/PopUp';
import { useNotificationContext } from '@components/Context/notification-context';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateCustomUserFee = ({ open, setOpen }: Props) => {
    const { showError, showSuccess } = useNotificationContext();

    const [customUserFeeId, setCustomUserFeeId] = useQueryParam(
        'customUserFeeId',
        NumberParam
    );

    const { data: customUserFee } = useGetCustomUserFeeByIdQuery(
        customUserFeeId || 0,
        {
            skip: !customUserFeeId,
        }
    );

    const [updateCustomUserFee, { isLoading }] =
        useUpdateCustomUserFeeMutation();

    const onSubmit = async (data: CreateOrUpdateCustomUserFeeFormDto) => {
        const dataToSend: UpdateCustomUserFeeDto = {
            investAccountId: data.investAccountId,
            feeId: data.feeId,
            dateValidaty: data.dateValidaty ? data.dateValidaty : null,
            type: data.type,
            value: data.value,
            typeInvestisseurId: data.typeInvestisseurId,
            id: customUserFee?.id!,
        };

        await updateCustomUserFee({
            updateCustomUserFeeDto: dataToSend,
            id: customUserFee?.id!,
        })
            .unwrap()
            .then(() => {
                showSuccess('Success', 'Custom User Fee updated successfully');
                setOpen(false);
            })
            .catch((error) => {
                showError('Error', 'Error while updating Custom User Fee');
            });
    };

    const [
        deleteCustomUserFee,
        { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
    ] = useDeleteCustomUserFeeMutation();

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        customUserFeeId: number;
    }>({
        deleteItem: () => deleteCustomUserFee(customUserFee?.id || 0),
        toDoIfSuccess: () => setOpen(false),
        message: 'custom user fee',
        isLoading: isLoadingDelete,
    });

    const defaultValues = useMemo(() => {
        if (!customUserFee) return {};
        return {
            investAccountId: customUserFee.investAccountId,
            feeId: customUserFee.feeId,
            dateValidaty: customUserFee.dateValidaty
                ? new Date(customUserFee.dateValidaty)
                      ?.toISOString()
                      ?.split('T')[0]
                : undefined,
            type: customUserFee.type,
            value: customUserFee.value,
            userId: customUserFee.userId,
            typeInvestisseurId: customUserFee.typeInvestisseurId,
        };
    }, [customUserFee]);

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            title={() => {
                return (
                    <>
                        <h3 className="text-2xl w-10/12 mx-auto font-semibold font-mainFontFamily leading-6 text-gray-900">
                            Editer custom fee for{' '}
                            <span className=" text-mainColor">
                                {customUserFee?.userName}
                            </span>
                        </h3>
                        {customUserFeeId ? (
                            <FontAwesomeIcon
                                onClick={() => setOpenDelete(true)}
                                icon={faTrash}
                                className="absolute top-4 md:top-0 cursor-pointer left-4 transition-all hover:scale-105"
                            />
                        ) : null}
                    </>
                );
            }}
        >
            <DeleteComponent title="ce frais " />
            <CustomUserFeeForm
                onSubmit={onSubmit}
                defaultValues={defaultValues}
                renderButtons={
                    <>
                        <WhiteButton
                            onClick={() => {
                                setOpen(false);
                            }}
                            className="w-full"
                        >
                            Cancel
                        </WhiteButton>
                        <PrimaryButton
                            loading={isLoading}
                            type="submit"
                            className="w-full"
                        >
                            Edit
                        </PrimaryButton>
                    </>
                }
            />
        </PopUp>
    );
};

export default UpdateCustomUserFee;
