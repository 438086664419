import { DocumentUserName, DocumentUserSide, DocumentUserType } from '@api/api';
import {
    FormCreateDocumentUserRequest,
    uploadDocumentUser,
} from '../../../redux/features/userSlice';

import { FormCreateDocumentUserAddress } from '../../DashboardUser/Profile/FormInvest/DocumentAdress';
import { FormCreateSidedDocumentUser } from '../../DashboardUser/Profile/FormInvest/DocumentsIdentity1';
import { getSidedFiles } from '../CustomHook/useSidedDocument';

export async function onSubmitDocumentAddress(
    documentAdress: FormCreateDocumentUserAddress,
    showError: (
        title: string,
        message: string,
        errorMessage?: string | undefined
    ) => void,
    showSuccess: (title: string, message: string) => void,
    userId: number
) {
    if (!documentAdress) {
        return true;
    }
    if (
        documentAdress?.sidedFiles?.documentBothSide?.file?.length === 0 &&
        documentAdress?.sidedFiles?.documentVersoSide?.file?.length === 0 &&
        documentAdress?.sidedFiles?.documentRectoSide?.file?.length === 0 &&
        documentAdress?.values.every((v) => v.file.length === 0)
    ) {
        return true;
    }

    let succeed = true;
    const lengthFiles = documentAdress?.values?.length;

    const fileAddressIdentity = getSidedFiles(documentAdress?.sidedFiles);

    for (const value of fileAddressIdentity) {
        const file = value.file[0];

        const data: FormCreateDocumentUserRequest = {
            fileType: '',
            file: file,
            name: value.name,
            userId: userId,
            side: value.side,
            type: value.type,
            fileName: file.name,
            key: '',
        };

        await uploadDocumentUser(data)
            .then((res) => {
                if (res.sucess) {
                    showSuccess(
                        'Envoyé',
                        'Le document a été envoyé avec le succès'
                    );
                } else {
                    succeed = false;
                    showError(
                        'Erreur',
                        `Erreur lors de l'envoie de ${data.fileName}`
                    );
                }
            })
            .catch((res) => {
                succeed = false;

                showError(
                    'Erreur',
                    `Erreur lors de l'envoie de ${data.fileName}`
                );
            });
    }

    for (let i = 0; i < lengthFiles; i++) {
        const value = documentAdress.values[i];

        const file = value.file?.[0];
        if (!file) continue;

        const dataNew: FormCreateDocumentUserRequest = {
            file: file,
            userId: userId,
            side: DocumentUserSide.BOTH,
            type: DocumentUserType.ADDRESS,
            name: documentAdress.name,
            fileName: file.name,
            fileType: '',
            key: '',
        };

        if (documentAdress.name === DocumentUserName.THIRD_PARTY_ATTESTATION) {
            dataNew.name = value.name;
            if (i === 0) {
                dataNew.type = DocumentUserType.ADDRESS_THIRD_PARTY;
            } else {
                dataNew.type = DocumentUserType.ADDRESS_THIRD_PARTY_ATTESTATION;
                dataNew.name = DocumentUserName.THIRD_PARTY_ATTESTATION;
            }
        }

        await uploadDocumentUser(dataNew)
            .then((res) => {
                if (res.sucess) {
                } else {
                    succeed = false;

                    showError(
                        'Erreur',
                        `Erreur lors de l'envoie de ${dataNew.fileName}`
                    );
                }
            })
            .catch((res) => {
                succeed = false;
                showError(
                    'Erreur',
                    `Erreur lors de l'envoie de ${dataNew.fileName}`
                );
            });
    }

    if (succeed) {
        return true;
    } else {
        return false;
    }
}

export async function onSubmitDocumentIdentity(
    documentIdentity1: FormCreateSidedDocumentUser,
    showError: (
        title: string,
        message: string,
        errorMessage?: string | undefined
    ) => void,
    showSuccess: (title: string, message: string) => void,
    userId: number
) {
    let succeed = true;

    if (!documentIdentity1) {
        return true;
    }

    const sidedFilesIdentity = getSidedFiles(documentIdentity1.sidedFiles);

    for (const value of sidedFilesIdentity) {
        const file = value.file[0];

        const data: FormCreateDocumentUserRequest = {
            fileType: '',
            file: file,
            name: documentIdentity1.name,
            userId: userId,
            side: value.side,
            type: value.type,
            fileName: file.name,
            key: '',
        };

        await uploadDocumentUser(data)
            .then((res) => {
                if (res.sucess) {
                    showSuccess(
                        'Envoyé',
                        'Le document a été envoyé avec le succès'
                    );
                } else {
                    succeed = false;
                    showError(
                        'Erreur',
                        `Erreur lors de l'envoie de ${data.fileName}`
                    );
                }
            })
            .catch((res) => {
                succeed = false;

                showError(
                    'Erreur',
                    `Erreur lors de l'envoie de ${data.fileName}`
                );
            });
    }

    return succeed;
}
