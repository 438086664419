import React, { useEffect, useState } from 'react';
import { filterFunctions } from '../../../function/Utils';
import { typeFiltration } from '../../../types/BackOffice';
import FilterApparition from '../../DashboardAdmin/BackOffice/Components/FilterApparition';
import {
    filtrationInterface,
    typeOrderOrFiltration,
} from '../../DashboardAdmin/BackOffice/Components/FiltrationColumnTable';

interface Props<T> {
    initialOrder: keyof T;
    data: T[];
    startIntervalNbItems: number;
    nbItemsShowed: number;
    setStartIntervalNbItems: React.Dispatch<React.SetStateAction<number>>;
    setNbItemsShowed: React.Dispatch<React.SetStateAction<number>>;
}

const usePaginationTable = <T extends object>({
    initialOrder,
    data,
    startIntervalNbItems,
    setNbItemsShowed,
    setStartIntervalNbItems,
    nbItemsShowed,
}: Props<T>) => {
    const [rangeChosen, setRangeChosen] = useState<number>(1);

    const [filterColumn, setFilterColumn] = useState<filtrationInterface<T>[]>([
        {
            element: initialOrder,
            type: typeFiltration.date,
            value: 'decroissant',
            orderOrFiltration: typeOrderOrFiltration.order,
        },
    ]);

    const [dataFiltered, setDataFiltered] = useState<T[]>(data ?? []);

    const filtrationFunction = () => {
        let transactionDataFiltered_init: T[] = data ?? [];
        for (const item of filterColumn) {
            transactionDataFiltered_init = filterFunctions(
                transactionDataFiltered_init,
                item.element,
                item.type,
                item.value
            );
        }
        setDataFiltered(transactionDataFiltered_init);
    };

    useEffect(() => {
        if (data) {
            filtrationFunction();
        }
    }, [filterColumn, data]);

    const ComponentFilter = ({
        children,
        offset,
        nbItems,
    }: {
        children: React.ReactNode;
        offset: boolean;
        nbItems: number;
    }) => (
        <FilterApparition
            startIntervalNbItems={startIntervalNbItems}
            setStartIntervalNbItems={setStartIntervalNbItems}
            nbItemsShowed={nbItemsShowed}
            setNbItemsShowed={setNbItemsShowed}
            array={dataFiltered || []}
            setRangeChosenOffset={offset ? setRangeChosen : undefined}
            rangeChosenOffset={offset ? rangeChosen : undefined}
            offset={offset}
            nbItems={nbItems ?? 0}
        >
            {children}
        </FilterApparition>
    );

    return {
        ComponentFilter,
        rangeChosen,
        setRangeChosen,
        dataFiltered,
        filterColumn,
        setFilterColumn,
    };
};

export default usePaginationTable;
