import { useFetchTokenQuery } from '@api/api';
import {
    MenuDashBoardUserBool,
    MenuDashBoardUserBoolState,
} from '@components/Context/AppContext';
import Activities from '@components/DashboardUser/Activities';
import ContactPopUp from '@components/DashboardUser/commun/ContactPopUp';
import IndexFondsUser from '@components/DashboardUser/Fonds/IndexFondsUser';
import Investir from '@components/DashboardUser/Investir/Investir';
import Menu from '@components/DashboardUser/Menu';
import Overview from '@components/DashboardUser/Overview/Overview';
import Documents from '@components/DashboardUser/Profile/Documents';
import InvestAccount from '@components/DashboardUser/Profile/InvestAccount/InvestAccount';
import Parameter from '@components/DashboardUser/Profile/Parameter';
import Profil from '@components/DashboardUser/Profile/Profil';
import Sponsor from '@components/DashboardUser/Profile/Sponsor/Sponsor';
import IndexSimulation from '@components/DashboardUser/Simulation/IndexSimulation';
import IndexTransactionUser from '@components/DashboardUser/Transactions/IndexTransactionUser';
import Wallet from '@components/DashboardUser/Wallet/Wallet';
import Withdraw from '@components/DashboardUser/Withdraw/Withdraw';
import Seconnecter from '@pages/Seconnecter';
import { useState } from 'react';

import { useLocation, useParams } from 'react-router';

type PathParams = {
    path: string;
};

const DashboardUser = () => {
    const { path } = useParams<PathParams>();

    const [toggleMenu, setToggleMenu] = useState(true);

    const location = useLocation();

    const currentLocation = location.pathname + location.search;

    const menuToggleObject: MenuDashBoardUserBool = {
        toggleMenu: toggleMenu,
        setToggleMenu: setToggleMenu,
    };

    const routeFunction = () => {
        switch (path) {
            case 'tableau-de-bord':
                return <Activities />;
            case 'nos-fonds':
                return <IndexFondsUser />;
            case 'donnees-personnelles':
                return <Profil />;
            case 'portefeuille':
                return <Wallet />;
            case 'Paramètres':
                return <Parameter />;
            case 'Parrainage':
                return <Sponsor />;
            case 'Documents':
                return <Documents />;
            case 'investir':
                return <Investir />;
            case 'withdraw':
                return <Withdraw />;
            case 'transactions':
                return <IndexTransactionUser />;
            case 'overview':
                return <Overview />;
            case 'invest-account':
                return <InvestAccount />;
            case 'simulations':
                return <IndexSimulation />;
            default:
        }
    };
    const { data: user, isLoading } = useFetchTokenQuery();

    return (
        <>
            {user ? (
                <MenuDashBoardUserBoolState.Provider value={menuToggleObject}>
                    <div
                        className={`dashboard_container_page dashboard_client ${
                            toggleMenu === true ? '!w-[99%]' : '!w-[100%]'
                        }`}
                    >
                        <Menu />
                        {routeFunction()}
                        <ContactPopUp />
                    </div>
                </MenuDashBoardUserBoolState.Provider>
            ) : (
                <Seconnecter path={`${currentLocation}`} />
            )}
        </>
    );
};

export default DashboardUser;
