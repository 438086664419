import { CheckIcon } from "@heroicons/react/outline";

const steps = [
  {
    id: "01",
    name: "Job Details",
    description: "Vitae sed mi luctus laoreet.",
    href: "#",
    status: "complete",
  },
  {
    id: "02",
    name: "Application form",
    description: "Cursus semper viverra.",
    href: "#",
    status: "current",
  },
  {
    id: "03",
    name: "Preview",
    description: "Penatibus eu quis ante.",
    href: "#",
    status: "upcoming",
  },
];

export enum StatusStepEnum {
  upcoming = "upcoming",
  current = "current",
  complete = "complete",
}

export interface IStep {
  // id: string;
  name: string;
  description?: string;
  href?: string;
  error?: string;
  //   status?: string;
}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  steps: IStep[];
  current: number;
  color?: string;
  onClick?: (id: number) => void;
}

const StepComponent: React.FC<Props> = ({ steps, current, onClick, color }) => {
  return (
    <div className="lg:border-b lg:border-t lg:border-gray-200 mb-2">
      <nav className="mx-auto px-4 sm:px-6 lg:px-2" aria-label="Progress">
        <ol
          role="list"
          className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
        >
          {steps.map((step, stepIdx) => (
            <li
              key={stepIdx}
              onClick={() => {
                if (onClick) {
                  onClick(stepIdx);
                }
              }}
              className="relative overflow-hidden lg:flex-1"
            >
              <div
                className={classNames(
                  stepIdx === 0 ? "rounded-t-md border-b-0" : "",
                  stepIdx === steps.length - 1 ? "rounded-b-md border-t-0" : "",
                  "overflow-hidden border border-gray-200 lg:border-0"
                )}
              >
                {stepIdx < current ? (
                  <a href={step.href} className="group">
                    <span
                      className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "flex  px-6 py-5 text-sm font-medium",
                        step.description ? "items-start" : "items-center"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={`flex h-10 w-10 items-center justify-center rounded-full ${
                            step.error ? "bg-secondColor" : "bg-secondColor"
                          } `}
                        >
                          <CheckIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span className="text-sm text-start font-medium">
                          {step.name}
                        </span>
                        <span className="text-sm text-start font-medium text-gray-400">
                          {step.error ? step.error : step.description}
                        </span>
                      </span>
                    </span>
                  </a>
                ) : stepIdx === current ? (
                  <a href={step.href} aria-current="step">
                    <span
                      className={`absolute left-0 top-0 h-full w-1 ${
                        step.error ? "bg-red-600" : "bg-secondColor"
                      } lg:bottom-0 lg:top-auto lg:h-1 lg:w-full`}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "flex px-6 py-5 text-sm font-medium",
                        step.description ? "items-start" : "items-center"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={`flex h-10 w-10 items-center justify-center rounded-full border-2 ${
                            step.error ? "border-red-600" : "border-secondColor"
                          }`}
                        >
                          <span
                            className={`${
                              step.error ? "text-red-600" : "text-secondColor"
                            }`}
                          >
                            {stepIdx + 1}
                          </span>
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span
                          className={`text-sm font-medium ${
                            step.error ? "text-red-600" : "text-secondColor"
                          }`}
                        >
                          {step.name}
                        </span>
                        <span className="text-sm font-medium text-gray-400">
                          {step.error ? step.error : step.description}
                        </span>
                      </span>
                    </span>
                  </a>
                ) : (
                  <a href={step.href} className="group">
                    <span
                      className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "flex px-6 py-5 text-sm font-medium",
                        step.description ? "items-start" : "items-center"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                          <span className="text-gray-500">{stepIdx + 1}</span>
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-gray-500">
                          {step.name}
                        </span>
                        <span className="text-sm font-medium text-gray-400">
                          {step.error ? step.error : step.description}
                        </span>
                      </span>
                    </span>
                  </a>
                )}

                {stepIdx !== 0 ? (
                  <>
                    {/* Separator */}
                    <div
                      className="absolute inset-0 left-0 top-0 hidden w-3 lg:block"
                      aria-hidden="true"
                    >
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default StepComponent;
