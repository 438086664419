import React from 'react';

import { DocumentUserDto, TypeValidationDocumentUser } from '@api/api';
import { formatDate } from '../../../function/Utils';
import { documentNameForUser } from '../../DashboardUser/Profile/ValidationUser';
import { PrimaryButton } from '../Buttons';
import { ErrorIcon, SuccessIcon } from '../IconsFeedBack';
import PopUp from '../PopUp';

interface Props {
    document: DocumentUserDto;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    index: number;
}
const DisplayDocumentValidation: React.FC<Props> = ({
    document,
    setOpen,
    open,
    index,
}) => {
    const typeValidation = document.validation?.type;

    const classNameDiv =
        'grid grid-cols-1 md:grid-cols-2 border-b-2 py-2 space-y-2 md:space-y-0';
    const classNameH3 =
        'text-sm font-mainFontFamily font-semibold text-center md:text-start';
    const classNameP = 'flex justify-center items-center';
    const classNameComment = ' px-2 md:px-0 text-start text-gray-400 italic';

    return (
        <PopUp
            title={() => {
                return (
                    <div className=" font-semibold">
                        <p className="text-2xl">
                            {documentNameForUser(document)}
                        </p>
                        <p className="text-md text-gray-400 italic">
                            Document {index + 1}
                        </p>
                    </div>
                );
            }}
            width="w-full max-w-[400px]"
            buttonBoolean={false}
            setOpen={setOpen}
            open={open}
        >
            <div className=" flex gap-2 flex-col ">
                <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Type du document</h3>
                    <p className={`${classNameP}`}>
                        {document.validation?.acceptedDocument ? (
                            <SuccessIcon />
                        ) : (
                            <ErrorIcon />
                        )}
                    </p>
                </div>

                <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Prénom</h3>
                    <p className={`${classNameP}`}>
                        {document.validation?.firstname ? (
                            <SuccessIcon />
                        ) : (
                            <ErrorIcon />
                        )}
                    </p>
                </div>
                {document.validation?.firstnameComment !== '' ? (
                    <div className={`${classNameDiv}`}>
                        <h3 className={`${classNameH3}`}>Commentaire prénom</h3>
                        <p className={`${classNameComment}`}>
                            {document.validation?.firstnameComment ?? ''}
                        </p>
                    </div>
                ) : null}

                <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Nom</h3>
                    <p className={`${classNameP}`}>
                        {document.validation?.lastname ? (
                            <SuccessIcon />
                        ) : (
                            <ErrorIcon />
                        )}
                    </p>
                </div>
                {document.validation?.lastnameComment !== '' ? (
                    <div className={`${classNameDiv}`}>
                        <h3 className={`${classNameH3}`}>Commentaire nom</h3>
                        <p className={`${classNameComment}`}>
                            {document.validation?.firstnameComment ?? ''}
                        </p>
                    </div>
                ) : null}
                <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Date validité</h3>
                    <p className={`${classNameP}`}>
                        {document.validation?.dateValidity ? (
                            <SuccessIcon />
                        ) : (
                            <ErrorIcon />
                        )}
                    </p>
                </div>
                {document.validation?.dateValidityComment !== '' &&
                document.validation?.dateValidityComment !== null ? (
                    <div className={`${classNameDiv}`}>
                        <h3 className={`${classNameH3}`}>
                            Commentaire date de validité
                        </h3>
                        <p className={`${classNameComment}`}>
                            {document.validation?.dateValidityComment ?? ''}
                        </p>
                    </div>
                ) : null}
                {typeValidation &&
                [TypeValidationDocumentUser.IDENTITY].includes(
                    typeValidation
                ) ? (
                    <>
                        <div className={`${classNameDiv}`}>
                            <h3 className={`${classNameH3}`}>
                                Date de naissance
                            </h3>
                            <p className={`${classNameP}`}>
                                {document.validation?.birthDay ? (
                                    <SuccessIcon />
                                ) : (
                                    <ErrorIcon />
                                )}
                            </p>
                        </div>
                        {document.validation?.birthDayComment !== '' &&
                        document.validation?.birthDayComment !== null ? (
                            <div className={`${classNameDiv}`}>
                                <h3 className={`${classNameH3}`}>
                                    Commentaire date de naissance
                                </h3>
                                <p className={`${classNameComment}`}>
                                    {document.validation?.birthDayComment ?? ''}
                                </p>
                            </div>
                        ) : null}
                        <div className={`${classNameDiv}`}>
                            <h3 className={`${classNameH3}`}>
                                Lieu de naissance
                            </h3>
                            <p className={`${classNameP}`}>
                                {document.validation?.birthPlace ? (
                                    <SuccessIcon />
                                ) : (
                                    <ErrorIcon />
                                )}
                            </p>
                        </div>
                        {document.validation?.birthPlaceComment !== '' &&
                        document.validation?.birthPlaceComment !== undefined ? (
                            <div className={`${classNameDiv}`}>
                                <h3 className={`${classNameH3}`}>
                                    Commentaire lieu de naissance
                                </h3>
                                <p className={`${classNameComment}`}>
                                    {document.validation?.birthPlaceComment ??
                                        ''}
                                </p>
                            </div>
                        ) : null}
                    </>
                ) : null}

                {typeValidation &&
                [
                    TypeValidationDocumentUser.IDENTITY,
                    TypeValidationDocumentUser.IDENTITY_HOSY_THIRD_PARTY,
                ].includes(typeValidation) ? (
                    <div className={`${classNameDiv}`}>
                        <h3 className={`${classNameH3}`}>Date fin</h3>
                        <p className={`${classNameComment} !text-center`}>
                            {document?.validation?.dateValidityEnd
                                ? formatDate(
                                      new Date(
                                          document?.validation?.dateValidityEnd
                                      )
                                  )
                                : ''}{' '}
                        </p>
                    </div>
                ) : null}

                {typeValidation &&
                [
                    TypeValidationDocumentUser.RESIDENCY,
                    TypeValidationDocumentUser.RESIDENCY_HOST_THIRD_PARTY,
                    TypeValidationDocumentUser.ATTESTATION_HOST_THIRD_PARTY,
                ].includes(typeValidation) ? (
                    <>
                        <div className={`${classNameDiv}`}>
                            <h3 className={`${classNameH3}`}>Adresse</h3>
                            <p className={`${classNameP}`}>
                                {document.validation?.address ? (
                                    <SuccessIcon />
                                ) : (
                                    <ErrorIcon />
                                )}
                            </p>
                        </div>
                        {document.validation?.addressComment !== '' ? (
                            <div className={`${classNameDiv}`}>
                                <h3 className={`${classNameH3}`}>
                                    Commentaire adresse
                                </h3>
                                <p className={`${classNameComment}`}>
                                    {document.validation?.addressComment ?? ''}
                                </p>
                            </div>
                        ) : null}
                    </>
                ) : null}

                {document.validation?.comment !== '' ? (
                    <div className={`${classNameDiv}`}>
                        <h3 className={`${classNameH3}`}>
                            Commentaire général
                        </h3>
                        <p className={`${classNameComment}`}>
                            {document.validation?.comment ?? ''}
                        </p>
                    </div>
                ) : null}
                <div className="w-full flex justify-center items-center mt-3 md:col-span-2">
                    <PrimaryButton onClick={() => setOpen(false)}>
                        Fermer
                    </PrimaryButton>
                </div>
            </div>
        </PopUp>
    );
};

export default DisplayDocumentValidation;
