import React, { useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import ArticlesManage from '../components/DashboardAdmin/ArticlesManage';

import MetricsIndex from '@components/DashboardAdmin/MetricsPage/MetricsIndex';
import {
    AllowState,
    MenuDashBoardAdminBool,
    MenuDashBoardAdminBoolState,
} from '../components/Context/AppContext';
import IndexBackOffice from '../components/DashboardAdmin/BackOffice/IndexBackOffice';
import FeesIndex from '../components/DashboardAdmin/Fees/FeesIndex';
import IndexFondsAdmin from '../components/DashboardAdmin/Fonds/IndexFondsAdmin';
import IndexImpactData from '../components/DashboardAdmin/ImpactData/IndexImpactData';
import Menu from '../components/DashboardAdmin/Menu';
import ProjectsManage from '../components/DashboardAdmin/ProjectManage/ProjectsManage';
import ProspectUser from '../components/DashboardAdmin/ProspectUser';
import LoginDashBoardAdmin from '../components/DashboardAdmin/Security/LoginDashBoardAdmin';
import Security from '../components/DashboardAdmin/Security/Security';
import ShareDIndex from '../components/DashboardAdmin/ShareD/ShareDIndex';
import SponsorIndex from '../components/DashboardAdmin/Sponsor/SponsorIndex';
import UserManage from '../components/DashboardAdmin/UserManage/UserManage';
import UtilisateursManage from '../components/DashboardAdmin/UserManage/UsersManage';
import { toInt } from '../function/Utils';
import { tableBackOfficeInterface } from '../types/BackOffice';
import NotFound from './NotFound';

type PathParams = {
    path: string;
};

type FondsParams = {
    idFonds: string;
};

type ProjetParams = {
    idProjet: string;
};

type ProjectBOParams = {
    idProjectBO: string;
};

type backOfficeParams = {
    idBackOffice: string;
};

type UserParams = {
    idUser: string;
};

type TableParams = {
    idTable: tableBackOfficeInterface;
};

type transactionParams = {
    idTransaction: string;
};

type blackListParams = {
    idBlackList: string;
};

export interface indexBackOfficeInterface {
    idBackOffice?: string;
    idUser?: string;
    idTable?: tableBackOfficeInterface;
    idProjectBO?: string;
    idTransaction?: string;
    idBlackList?: string;
}

const DashboardAdmin = () => {
    const { path } = useParams<PathParams>();
    const { idFonds } = useParams<FondsParams>();
    const { idProjet } = useParams<ProjetParams>();
    const { idBackOffice } = useParams<backOfficeParams>();
    const { idUser } = useParams<UserParams>();
    const { idTable } = useParams<TableParams>();
    const { idProjectBO } = useParams<ProjectBOParams>();
    const { idTransaction } = useParams<transactionParams>();
    const { idBlackList } = useParams<blackListParams>();

    const [toggleMenu, setToggleMenu] = useState(true);

    const menuToggleObject: MenuDashBoardAdminBool = {
        toggleMenu: toggleMenu,
        setToggleMenu: setToggleMenu,
    };

    const location = useLocation();

    const routeFunction = () => {
        if (path) {
            switch (path) {
                case 'Utilisateurs':
                    if (idUser) {
                        return <UserManage userId={toInt(idUser)} />;
                    } else {
                        return <UtilisateursManage />;
                    }
                case 'prospects':
                    return <ProspectUser />;
                case 'titre-d-manager':
                    return <ShareDIndex />;
                case 'sponsor':
                    return <SponsorIndex />;
                case 'fees':
                    return <FeesIndex />;
                case 'impacts-management':
                    return <IndexImpactData />;
                case 'metrics':
                    return <MetricsIndex />;
                case 'Articles':
                    return <ArticlesManage />;
                case 'ProjectsManage':
                    return <ProjectsManage />;
                case 'BackOffice':
                    let idObject: indexBackOfficeInterface = {};
                    if (idBackOffice) {
                        idObject = { idBackOffice: idBackOffice };
                    } else if (idUser) {
                        idObject = { idUser: idUser };
                    } else if (idTable) {
                        idObject = { idTable: idTable };
                    } else if (idProjectBO) {
                        idObject = { idProjectBO: idProjectBO };
                    } else if (idTransaction) {
                        idObject = { idTransaction: idTransaction };
                    } else if (idBlackList) {
                        idObject = { idBlackList: idBlackList };
                    }
                    return <IndexBackOffice idObject={idObject} />;
                case 'Fonds':
                    return <IndexFondsAdmin />;
                case 'Securite':
                    return <Security />;
                default:
            }
        } else {
            return <NotFound />;
        }
    };

    const allowState = useContext(AllowState);

    return (
        <div
            className={`dashboard_container_page dashboard_admin ${
                toggleMenu === true ? '!w-[99%]' : '!w-[100%]'
            }`}
        >
            {allowState && allowState.allow ? (
                <MenuDashBoardAdminBoolState.Provider value={menuToggleObject}>
                    <React.Fragment>
                        <Menu />
                        {routeFunction()}
                    </React.Fragment>
                </MenuDashBoardAdminBoolState.Provider>
            ) : (
                <React.Fragment>
                    <LoginDashBoardAdmin />
                </React.Fragment>
            )}
        </div>
    );
};

export default DashboardAdmin;
