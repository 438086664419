import {
    ShareDto,
    useFetchTokenQuery,
    useGetAllFondsQuery,
    useGetSharesByUserIdQuery,
} from '@api/api';
import React, { useContext, useEffect, useState } from 'react';
import { ComputationUserDashboard } from '../../../function/ComputationUserDashboard';
import { isEmpty, setDate } from '../../../function/Utils';

import { typeFiltration } from '@interfaces/BackOffice';
import { BackOfficeUserState } from '../../Context/AppContext';
import FiltrationColumnTable, {
    filtrationInterface,
    typeOrderOrFiltration,
} from '../../DashboardAdmin/BackOffice/Components/FiltrationColumnTable';
import ElementTable from './ElementTable';

const TransactionsTable = () => {
    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

    const { data: shareDatafull, isLoading } = useGetSharesByUserIdQuery(
        user?.id ?? 0,
        {
            skip: !user,
        }
    );
    const [ShareDataFiltered, setShareDataFiltered] = useState<
        ComputationUserDashboard | undefined
    >();

    const shareData = shareDatafull?.shares;

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold text-right',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap text-right',
    };

    const [filterColumn, setFilterColumn] = useState<
        filtrationInterface<ShareDto>[]
    >([
        {
            element: 'dateStartInvestisseur',
            type: typeFiltration.date,
            value: 'decroissant',
            orderOrFiltration: typeOrderOrFiltration.order,
        },
    ]);
    const backOfficeUserState = useContext(BackOfficeUserState);
    const { data: fondsData } = useGetAllFondsQuery();

    const [dateSelectedState, setDateSelected] = useState<Date | undefined>();

    useEffect(() => {
        if (
            ShareDataFiltered &&
            backOfficeUserState?.year &&
            backOfficeUserState?.month !== undefined &&
            backOfficeUserState?.day
        )
            setDateSelected(
                ShareDataFiltered?.lastDateComputed ??
                    setDate(
                        backOfficeUserState?.year!,
                        backOfficeUserState?.month!,
                        backOfficeUserState?.day!
                    )
            );
    }, [backOfficeUserState, ShareDataFiltered]);

    useEffect(() => {
        if (shareData && backOfficeUserState && user) {
            const computationObject: ComputationUserDashboard =
                new ComputationUserDashboard(
                    shareData,
                    user?.id!,
                    shareDatafull?.firstDates
                );

            setShareDataFiltered(computationObject);
        }
    }, [filterColumn, shareData, backOfficeUserState, user]);

    return (
        <React.Fragment>
            <div className="flex flex-col overflow-x-auto">
                <div className="  lg:mx-auto min-w-full">
                    <div className="py-2 inline-block min-w-full">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="bg-white border-b ">
                                    <tr className="w-full">
                                        {/* <th scope="col" className={`${classNameObejct.head} `}>
                      #
                    </th> */}
                                        <th
                                            scope="col"
                                            className={`${classNameObejct.head} !text-left`}
                                        >
                                            Fonds
                                        </th>

                                        <FiltrationColumnTable
                                            element={'dateStartInvestisseur'}
                                            type={typeFiltration.date}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Transaction <br /> effectué
                                                    le
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.order
                                            }
                                            textLeft={true}
                                        />
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Montant
                                            <br /> investi
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            nombre <br /> de titres
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Valeur <br /> nominale
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Valeur d'un <br /> titre*
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Performance
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            TRI <br />
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Valeur nette du
                                            <br /> portefeuille
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Date de la <br /> valorisation
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!(
                                        shareData !== undefined &&
                                        !isEmpty(fondsData) &&
                                        backOfficeUserState &&
                                        dateSelectedState !== undefined
                                    ) && (
                                        <React.Fragment>
                                            {!(
                                                isEmpty(shareData) &&
                                                ShareDataFiltered !==
                                                    undefined &&
                                                dateSelectedState !== undefined
                                            ) &&
                                                ShareDataFiltered?.allInfoUser(
                                                    ShareDataFiltered.lastDateComputed ??
                                                        setDate(
                                                            backOfficeUserState?.year!,
                                                            backOfficeUserState?.month!,
                                                            backOfficeUserState?.day!
                                                        )
                                                ).map((fondsInfo, key) => (
                                                    <ElementTable
                                                        fondsInfo={fondsInfo}
                                                        key={key}
                                                        dateSelected={
                                                            dateSelectedState!
                                                        }
                                                        index1={key}
                                                    />
                                                ))}
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TransactionsTable;
