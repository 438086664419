import {
    BankAccountFondsDto,
    BankAccountFondsForUserDto,
    CategoryDocumentFonds,
    api as generatedApi,
    TypeDocumentFonds,
} from '@api/api';

import axios from 'axios';

export const fondsApi = generatedApi.enhanceEndpoints({
    addTagTypes: ['FondsUser', 'Documents', 'Fonds', 'BankAccount'],
    endpoints: {
        getAllFonds: {
            providesTags: ['Fonds'],
        },
        getFondsById: {
            providesTags: ['Fonds'],
        },
        getFondsUser: {
            providesTags: ['FondsUser'],
        },
        getSyntheticInfoFonds: {},
        getPerformancePerFonds: {},
        createFonds: {
            invalidatesTags: ['Fonds'],
        },
        deleteFonds: {
            invalidatesTags: ['Fonds'],
        },
        updateFonds: {
            invalidatesTags: ['FondsUser', 'Fonds'],
        },
        associateFondsWithUser: {
            invalidatesTags: ['FondsUser', 'Fonds'],
        },
        getFondsBasicMetrics: {},
        getDocumentsByFondsId: {
            providesTags: ['Documents'],
        },
        getInvestFondsDocuments: {
            providesTags: ['Documents'],
        },
        getFondsOverviewDocuments: {
            providesTags: ['Documents'],
        },
        deleteDocumentFonds: {
            invalidatesTags: ['Documents'],
        },
        getBankFondsInfoForSubscription: {
            transformResponse: (response: BankAccountFondsForUserDto[]) => {
                return response.map((bankAccount) => {
                    return {
                        ...bankAccount,
                        iban: bankAccount.iban
                            ? bankAccount.iban.replace(/(.{4})/g, '$1 ')
                            : '',
                    };
                });
            },
        },
        getBankInfoByFondsId: {
            providesTags: ['BankAccount'],
            transformResponse: (response: BankAccountFondsDto) => {
                return {
                    ...response,
                    iban: response.iban
                        ? response.iban.replace(/(.{4})/g, '$1 ')
                        : '',
                };
            },
        },
        createBankAccount: {
            invalidatesTags: ['BankAccount'],
        },
        updateBankAccount: {
            invalidatesTags: ['BankAccount'],
        },
        deleteBankAccount: {
            invalidatesTags: ['BankAccount'],
        },
    },
});

export const {
    useGetAllFondsQuery,
    useCreateFondsMutation,
    useDeleteFondsMutation,
    useUpdateFondsMutation,
    useCreateBankAccountMutation,
    useDeleteBankAccountMutation,
    useGetBankFondsInfoForSubscriptionQuery,
    useGetBankInfoByFondsIdQuery,
    useGetFondsUserQuery,
    useGetFondsByIdQuery,
    useGetDocumentsByFondsIdQuery,
    useDeleteDocumentFondsMutation,
    useUpdateBankAccountMutation,
    useGetInvestFondsDocumentsQuery,
    useGetSyntheticInfoFondsQuery,
    useGetFondsBasicMetricsQuery,
    useGetFondsOverviewDocumentsQuery,
    useAssociateFondsWithUserMutation,
    useGetPerformancePerFondsQuery,
} = fondsApi;

export interface UploadDocumentFondsDto {
    fondsId: number;
    file: File;
    type: TypeDocumentFonds;
    fileName: string;
    category?: CategoryDocumentFonds;

    comment?: string;
    fondsName: string;
}

export async function uploadDocumentFonds(request: UploadDocumentFondsDto) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}fonds/documents/url/put`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    fondsName: request.fondsName,
                    fondsId: request.fondsId,
                    type: request.type,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}fonds/documents/post`,
            data: {
                fondsId: request.fondsId,
                fileName: encodeURIComponent(request.fileName),
                type: request.type,
                comment: request.comment,
                category: request.category,
                key: key,
            },
            withCredentials: true,
        });

        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}
