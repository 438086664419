import React, { useContext, useEffect } from "react";
import { LoadingBackOfficeState } from "../Context/AppContext";
import { motion } from "framer-motion";

const Loading = ({
  size = 8,
  className,
}: {
  size?: number;
  className?: string;
}) => {
  const circleVariants = {
    start: {
      rotate: 0,
    },
    end: {
      rotate: 360,
    },
  };

  const circleTransition = {
    duration: 1,
    repeat: Infinity,
    ease: "linear",
  };

  return (
    <div className="h-full w-full flex items-center justify-center">
      <div
        style={{ borderTopColor: "transparent" }}
        className={
          `h-${size} w-${size} border-2 border-gray-400 border-solid rounded-full animate-spin ` +
          className
        }
      ></div>
    </div>
  );
};

export default Loading;
