import { useContext } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import CustomFees from '@components/DashboardAdmin/Fees/CustomFees';
import FeeTables from '@components/DashboardAdmin/Fees/FeeTables';
import { IMenu } from '@components/DashboardAdmin/UserManage/UserManage';
import { TabsMenu } from '@components/commun/HorizontalMenu';
import { MenuDashBoardAdminBoolState } from '../../Context/AppContext';
import WrapComponentDashboardUser from '../../DashboardUser/commun/WrapComponentDashboardUser';

const FeesIndex = () => {
    const menuBoolState = useContext(MenuDashBoardAdminBoolState);

    const [pageQuery, setPageQuery] = useQueryParam('feePage', StringParam);

    const menuList: IMenu[] = [
        {
            queryPage: 'fee-tables',
            label: 'Table des frais',
        },
        {
            queryPage: 'custom-fees',
            label: 'Frais personnalisés',
        },
    ];

    const selectPage = () => {
        switch (pageQuery) {
            case 'fee-tables':
                return <FeeTables />;
            case 'custom-fees':
                return <CustomFees />;
            default:
                return <FeeTables />;
        }
    };

    return (
        <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
            <WrapComponentDashboardUser
                title="Gestion des frais"
                description=""
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
            >
                <div className="md:h-fit bg-backgroundColor w-full flex justify-center items-center mt-[-2px]   sticky top-[-2px] z-[99] mb-2 mx-auto">
                    <TabsMenu
                        className="  "
                        items={menuList}
                        queryPage="feePage"
                        onClick={() => {}}
                    />
                </div>
                <div className="">{selectPage()}</div>
            </WrapComponentDashboardUser>
        </div>
    );
};

export default FeesIndex;
