import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import { WhiteButton } from '../Buttons';

interface Props {
    remove: (index: number) => void;
    index: number;
    className?: string;
}

const RemoveFieldArrayComponent: React.FC<Props> = ({
    remove,
    index,
    className,
}) => {
    return (
        <div
            className={`flex justify-center items-center my-2
    ${className}`}
        >
            <WhiteButton onClick={() => remove(index)}>
                <XIcon className="w-4 h-4" />
            </WhiteButton>
        </div>
    );
};

export default RemoveFieldArrayComponent;
