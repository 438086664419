import { useGetOverviewFondsInfoOverviewAdminQuery } from '@api/api';
import React, { useContext } from 'react';
import { isEmpty, setDate } from '../../../../function/Utils';
import {
    BackOfficeState,
    RootBlackOfficeState,
} from '../../../Context/AppContext';
import TableComponent from '../Components/TableComponent';
import FondsElement from './FondsElement';

const TableSummaryFunds = () => {
    const backOfficeState = useContext(BackOfficeState);

    const rootState = useContext(RootBlackOfficeState);

    const { data: ovierViewAdminInfo, isLoading } =
        useGetOverviewFondsInfoOverviewAdminQuery(
            setDate(
                backOfficeState?.year!,
                backOfficeState?.month!,
                backOfficeState?.day ?? 0
            ).toISOString(),
            {
                skip:
                    !backOfficeState?.year ||
                    backOfficeState?.month === undefined ||
                    !backOfficeState?.day ||
                    rootState?.applyFeesBool === undefined,
            }
        );

    return (
        <React.Fragment>
            <TableComponent
                head={[
                    'Fonds',
                    'Valorisation',
                    'Date de valorisation',
                    "Nombre d'utilisateurs",
                    'Investissement moyen',
                    'TRI',
                    'TRI actuel',
                    'TRI derniers 30 jours',
                    'TRI depuis 1er janvier',
                ]}
            >
                <tbody>
                    {!!(
                        !isEmpty(ovierViewAdminInfo?.fondsInfo) &&
                        backOfficeState
                    ) && (
                        <React.Fragment>
                            {ovierViewAdminInfo?.fondsInfo.map((fond, key) => (
                                <FondsElement
                                    fonds={fond}
                                    key={key}
                                    number={key}
                                />
                            ))}
                        </React.Fragment>
                    )}
                </tbody>
            </TableComponent>
        </React.Fragment>
    );
};

export default TableSummaryFunds;
