import React from 'react';

import FirstFondPage from '../components/commun/FirstFondPage';
import Footer from '../components/commun/Footer';
import Navigation from '../components/commun/Navigation';

import { pageHeader } from '@api/features/page';
import coverBackground from './../assets/articles.jpg';

const Articles = () => {
    const NewsList: pageHeader = {
        coverBackground: coverBackground,
        coverImg: './img/articles.jpg',
        classN: 'Articles',
        h1_: 'Articles',
    };

    return (
        <React.Fragment>
            <Navigation bol_nosfonds={false} bol_transparent={true} />
            <FirstFondPage pageHeader={NewsList} />

            <Footer />
        </React.Fragment>
    );
};

export default Articles;
