import { useContext, useEffect } from 'react';
import {
    EtapeForm,
    MenuDashBoardUserBoolState,
} from '../../../Context/AppContext';
import { PrimaryButton } from '../../../commun/Buttons';
import DocumentsAdress from './DocumentAdress';
import DocumentsIdentity1 from './DocumentsIdentity1';
import FormEtapeInvestAccount from './FormEtapeInvestAccount';
import FormulaireEtapeIdentity from './FormulaireEtapeIdentity';

const FormulaireEtape0 = () => {
    const etapeObject = useContext(EtapeForm);

    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    useEffect(() => {
        sessionStorage.setItem(
            'nextFormProfil',
            (etapeObject?.stepInsideStep0 || 0).toString()
        );
        const elementDashBoard = document.querySelector(
            '.form_investisseur_container'
        ) as HTMLElement;
        elementDashBoard.scrollTo(0, 0);
    }, [etapeObject?.stepInsideStep0]);

    return (
        <>
            {etapeObject?.stepInsideStep0 === 0 ? (
                <div className="flex_center_column h-[85vh] md:h-[70vh] mx-auto w-10/12 !px-2 text-lg mb-4">
                    {/* <p className="">
            Pour des raisons réglementaires, il est nécessaire que vous mettiez
            à jour vos données personnelles.
          </p> */}
                    <p className="mt-2">
                        Complétez votre profil et obtenez un accès avancé à
                        notre platforme d'investissement.
                    </p>
                    <p className="font-semibold text-2xl !mb-0">
                        Les documents suivants vont seront demandés :
                    </p>
                    <ul className="mb-5 !mt-0 flex flex-col gap-2">
                        <li className=" list-disc mt-2">
                            1 pièce d'identité (passeport, carte d'identité,
                            carte de séjour)
                        </li>
                        <li className="list-disc">
                            1 justificatif de domicile (facture d'electricité,
                            facture de gaz, relevé banque)
                        </li>
                        <li className="list-disc">
                            1 RIB (Relevé d'identité bancaire) pour les
                            virements (ceci est optionnel, vous pouvez le
                            fournir plus tard)
                        </li>
                        {/* <li className="list-disc">1 RIB</li> */}
                    </ul>
                    <PrimaryButton
                        onClick={() => {
                            menuBoolState?.setToggleMenu(false);
                            etapeObject?.setStepInsideStep0(
                                etapeObject?.stepInsideStep0 + 1
                            );
                        }}
                    >
                        Complèter son profil
                    </PrimaryButton>
                    {/* <div
        className="submit pointer transitionBasic_hover font_family_title"
        onClick={() => etapeObject?.setEtape(etapeObject?.etape + 1)}
      >
        Mettre à jour
      </div> */}
                </div>
            ) : etapeObject?.stepInsideStep0 === 1 ? (
                <FormulaireEtapeIdentity />
            ) : etapeObject?.stepInsideStep0 === 2 ? (
                <FormEtapeInvestAccount />
            ) : etapeObject?.stepInsideStep0 === 3 ? (
                <DocumentsIdentity1 />
            ) : (
                // : etapeObject?.stepInsideStep0 === 3 ? (
                //   <DocumentsIdentity2  />
                // )
                <DocumentsAdress />
            )}
        </>
    );
};

export default FormulaireEtape0;
