import {
    CreateTransactionDto,
    TransactionStatus,
    TypeTransaction,
    useGetAllPlatformsQuery,
    useGetAllTypeInvestisseursForFormQuery,
    useGetCashIdsQuery,
    useGetProjectsSearchQuery,
    useGetTransactionsUserQuery,
    useGetTransactionUserSponsorIdQuery,
    useGetUsersSearchQuery,
    useGetUserWithAllInfoQuery,
} from '@api/api';
import { useGetAllFondsQuery } from '@api/features/fondsSlice';

import { PrimaryButton } from '@components/commun/Buttons';
import CheckboxComponent from '@components/commun/formComponent/CheckboxComponent';
import FieldArray from '@components/commun/formComponent/FieldArray';
import InputComponent, {
    NumericInput,
} from '@components/commun/formComponent/InputComponent';
import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import RadioComponent from '@components/commun/formComponent/RadioComponent';
import RemoveFieldArrayComponent from '@components/commun/formComponent/RemoveFieldArrayComponent';
import SelectComponent, {
    IOptionValues,
} from '@components/commun/formComponent/SelectComponent';
import UploaderMulti from '@components/commun/formComponent/UploaderMulti';
import { UpdateOrCreateTransactionUserInDto } from '@interfaces/transaction/transactionUserInType';
import { UpdateOrCreateTransactionUserOutDto } from '@interfaces/transaction/transactionUserOutType';
import { TypeInvestisseurIdEnum } from '@interfaces/typeInvestisseur';

import {
    defaultValuesTransaction,
    defaultValuesTransactionUserIn,
    defaultValuesTransactionUserOut,
    defaultValuesTransactionUserOutItem,
} from '@utils/dataHelper/TransactionHelper';
import { formatDate, stringToInt } from '@utils/Utils';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export interface UpdateOrCreateTransactionDto extends CreateTransactionDto {
    id?: number;
    transactionUserOut?: UpdateOrCreateTransactionUserOutDto;
    transactionUserIn?: UpdateOrCreateTransactionUserInDto;
}

export enum TypeTransactionProject2Cash {
    Coupon = 'Coupon',
    Capital = 'Capital',
    Closure = 'Fermeture',
}

export interface UpdateOrCreateTransactionDtoForm
    extends UpdateOrCreateTransactionDto {
    files?: File[];
    sponsored?: boolean;
    typeTransactionProject2Cash?: TypeTransactionProject2Cash;
}

export interface TransactionFormProps {
    renderButtons: (props: { isLoading: boolean }) => ReactNode;
    container: (props: {
        children: ReactNode;
        expanded: boolean;
        setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    }) => ReactNode;
    defaultValues?: Partial<UpdateOrCreateTransactionDtoForm>;
    renderDocument?: () => ReactNode;
    onSubmit: (
        data: UpdateOrCreateTransactionDtoForm,
        sponsorId?:
            | {
                  sponsorId: number | false;
              }
            | undefined
    ) => Promise<void>;
}

const TransactionForm = ({
    renderButtons,
    container,
    defaultValues,
    onSubmit,
    renderDocument,
}: TransactionFormProps) => {
    const { data: fondsData } = useGetAllFondsQuery();

    const { data: platformData } = useGetAllPlatformsQuery();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchFrom, setSearchFrom] = useState<string>('');
    const [searchTo, setSearchTo] = useState<string>('');

    const [expanded, setExpanded] = useState<boolean>(true);

    const formState = useForm<UpdateOrCreateTransactionDtoForm>({
        defaultValues: {
            ...defaultValuesTransaction,
            ...defaultValues,
        },
    });

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        control,
    } = formState;

    useEffect(() => {
        const actualValue = getValues();
        const newValues = {
            ...actualValue,
            ...defaultValues,
        };
        reset(newValues);
    }, [defaultValues, getValues, reset]);

    const { data: sponsorId } = useGetTransactionUserSponsorIdQuery(
        watch('from') ?? 0,
        {
            skip: watch('type') !== TypeTransaction.userCash || !watch('from'),
        }
    );

    const { data: cashData } = useGetCashIdsQuery();
    const { data: projectData } = useGetProjectsSearchQuery(
        {
            fondsId: getValues('fondsId') ?? 0,
            search:
                watch('type') === TypeTransaction.cashProject
                    ? searchTo
                    : searchFrom,
        },
        {
            skip:
                watch('type') === TypeTransaction.cashProject ||
                watch('type') === TypeTransaction.projectCash
                    ? false
                    : true,
        }
    );

    const { data: usersData } = useGetUsersSearchQuery(
        watch('type') === TypeTransaction.userCash ? searchFrom : searchTo,
        {
            skip:
                watch('type') === TypeTransaction.userCash ||
                watch('type') === TypeTransaction.cashUser ||
                watch('type') === TypeTransaction.newParadigmsUser
                    ? false
                    : true,
        }
    );

    const { data: userAllInfo } = useGetUserWithAllInfoQuery(
        watch('type') === TypeTransaction.userCash
            ? watch('from')
            : watch('type') === TypeTransaction.cashUser
              ? watch('to')
              : 0,
        {
            skip:
                (watch('type') === TypeTransaction.userCash &&
                    watch('from') !== undefined) ||
                (watch('type') === TypeTransaction.cashUser &&
                    watch('to') !== undefined)
                    ? false
                    : true,
        }
    );

    const { data: typeInvestisseurs } =
        useGetAllTypeInvestisseursForFormQuery();

    const { data: transactionUser } = useGetTransactionsUserQuery(
        watch('to') ?? 0,
        {
            skip:
                watch('type') !== TypeTransaction.cashUser ||
                watch('to') === undefined
                    ? true
                    : false,
        }
    );

    const transactionUserSelect = (): IOptionValues[] => {
        if (transactionUser) {
            return transactionUser
                .filter((t) => t.fondsId === watch('fondsId'))
                .map((t) => {
                    return {
                        label: `date: ${formatDate(new Date(t.date))} montant: ${t.amount}`,
                        value: t.id,
                    };
                });
        } else {
            return [];
        }
    };

    const selectFunc = useCallback(
        (type: TypeTransaction, from: boolean): IOptionValues[] => {
            const cash: IOptionValues[] =
                cashData
                    ?.filter((c) => c.fondsId === getValues('fondsId'))
                    .map((val) => {
                        return {
                            label: 'Cash',
                            value: val.id,
                        };
                    }) ?? [];

            const user: IOptionValues[] =
                usersData?.map((user) => {
                    return {
                        label: `${user.firstName} ${user.lastName}`,
                        value: user.id,
                    };
                }) ?? [];

            const projects: IOptionValues[] =
                projectData?.map((project) => {
                    return {
                        label: project.name,
                        value: project.id,
                    };
                }) ?? [];

            const newParadimgs: IOptionValues[] = [
                { label: 'New Paradimgs', value: 1 },
            ];
            const debt: IOptionValues[] = [{ value: 1, label: 'Dette' }];

            const plaform: IOptionValues[] =
                platformData?.map((platform) => {
                    return {
                        label: platform.name,
                        value: platform.id,
                    };
                }) ?? [];

            switch (type) {
                case TypeTransaction.cashProject:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(projects[0].value as string));
                        return projects;
                    }
                case TypeTransaction.cashUser:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(user[0].value as string));

                        return user;
                    }
                case TypeTransaction.projectCash:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(projects[0].value as string));
                        return projects;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(cash[0].value as string));

                        return cash;
                    }
                case TypeTransaction.userCash:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(user[0].value as string));
                        return user;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(cash[0].value as string));

                        return cash;
                    }
                case TypeTransaction.cashNewParadigms:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(newParadimgs[0].value as string));
                        return newParadimgs;
                    }

                case TypeTransaction.newParadigmsCash:
                    if (from) {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(newParadimgs[0].value as string));
                        return newParadimgs;
                    } else {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    }
                case TypeTransaction.newParadigmsUser:
                    if (from) {
                        return newParadimgs;
                    } else {
                        return user;
                    }
                case TypeTransaction.userNewParadigms:
                    if (from) {
                        return user;
                    } else {
                        return newParadimgs;
                    }
                case TypeTransaction.cashDebt:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(debt[0].value as string));
                        return debt;
                    }
                case TypeTransaction.debtCash:
                    if (from) {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(debt[0].value as string));
                        return debt;
                    } else {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    }
                case TypeTransaction.buyingProjectCash:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(projects[0].value as string));
                        return projects;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(cash[0].value as string));
                        return cash;
                    }
                case TypeTransaction.projectStopCash:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(projects[0].value as string));
                        return projects;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(cash[0].value as string));
                        return cash;
                    }
                case TypeTransaction.platformCash:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(plaform[0].value as string));
                        return plaform;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(cash[0].value as string));
                        return cash;
                    }
            }
        },
        [watch('fondsId'), cashData, usersData, projectData, watch('type')]
    );

    useEffect(() => {
        setSearchFrom('');
        setSearchTo('');
        reset({
            ...watch(),

            from:
                watch('from') == undefined
                    ? stringToInt(selectFunc(watch('type'), true)[0]?.value)
                    : watch('from'),
            to:
                watch('to') == undefined
                    ? stringToInt(selectFunc(watch('type'), false)[0]?.value)
                    : watch('to'),
        });

        if (watch('type') === TypeTransaction.cashProject) {
            setValue(
                'typeTransactionProject2Cash',
                TypeTransactionProject2Cash.Coupon
            );
        }

        if (watch('type') === TypeTransaction.userCash) {
            setValue(
                'transactionUserIn',
                watch('transactionUserIn')?.typeShare
                    ? watch('transactionUserIn')
                    : (defaultValuesTransactionUserIn as any)
            );

            setValue(
                'transactionUserIn.investAccountId',
                watch('transactionUserIn.investAccountId') ??
                    userAllInfo?.investAccounts?.[0]?.id
            );

            setValue(
                'dateInterest',
                new Date(watch('date'))?.toJSON().split('T')[0]
            );
        } else {
            setValue('transactionUserIn', undefined);
        }

        if (watch('type') === TypeTransaction.cashUser) {
            setValue(
                'transactionUserOut',
                watch('transactionUserOut') ??
                    (defaultValuesTransactionUserOut as any)
            );

            setValue(
                'transactionUserOut.investAccountId',
                watch('transactionUserOut.investAccountId') ??
                    userAllInfo?.investAccounts?.[0]?.id
            );
        }
    }, [
        watch('type'),
        watch('fondsId'),
        selectFunc,
        reset,
        watch,
        setValue,
        userAllInfo,
    ]);

    const onSubmitLogic = async (data: UpdateOrCreateTransactionDtoForm) => {
        setIsLoading((curr) => !curr);
        await onSubmit(data, sponsorId);
        setIsLoading((curr) => !curr);
    };

    const component = (
        <form
            onSubmit={handleSubmit(onSubmitLogic)}
            className="formTemplateSingUp"
            style={{ width: `${expanded ? '90%' : '80%'}` }}
        >
            <div
                className={`w-full mx-auto ${
                    expanded
                        ? 'sm:flex sm:gap-2 sm:justify-between sm:items-center '
                        : ''
                }`}
            >
                <div className={`w-full ${expanded ? 'sm:w-10/12 ' : ''}`}>
                    <SelectComponent
                        register={register}
                        value={'fondsId'}
                        container={true}
                        optionValues={
                            fondsData?.map((val) => {
                                return {
                                    value: val.id,
                                    label: val.name,
                                };
                            }) ?? []
                        }
                        control={control}
                    >
                        <h3>
                            Fonds <span className="required">*</span>
                        </h3>
                    </SelectComponent>
                    <SelectComponent
                        register={register}
                        value={'type'}
                        container={true}
                        watch={watch}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        useParamBoolean={true}
                        optionValues={[
                            TypeTransaction.userCash,
                            TypeTransaction.cashUser,
                            TypeTransaction.cashProject,
                            TypeTransaction.projectCash,
                            TypeTransaction.cashNewParadigms,
                            TypeTransaction.newParadigmsCash,
                            TypeTransaction.newParadigmsUser,
                            TypeTransaction.userNewParadigms,
                            TypeTransaction.cashDebt,
                            TypeTransaction.debtCash,
                            TypeTransaction.buyingProjectCash,
                            TypeTransaction.platformCash,
                        ].map((val) => {
                            return {
                                value: val,
                                label: val,
                            };
                        })}
                    >
                        <h3>
                            Type de transaction{' '}
                            <span className="required">*</span>
                        </h3>
                    </SelectComponent>

                    {watch('type') === TypeTransaction.projectCash ? (
                        <div className="flex items-center gap-2 justify-center">
                            <RadioComponent
                                register={register}
                                watch={watch}
                                name={'typeTransactionProject2Cash'}
                                values={[
                                    {
                                        value: TypeTransactionProject2Cash.Coupon,
                                        label: TypeTransactionProject2Cash.Coupon,
                                    },
                                    {
                                        value: TypeTransactionProject2Cash.Capital,
                                        label: TypeTransactionProject2Cash.Capital,
                                    },
                                    // {
                                    //   value: TypeTransactionProject2Cash.Closure,
                                    //   label: TypeTransactionProject2Cash.Closure,
                                    // },
                                ]}
                            ></RadioComponent>
                        </div>
                    ) : null}

                    <SelectComponent
                        register={register}
                        value={'from'}
                        setSearch={setSearchFrom}
                        container={true}
                        optionValues={selectFunc(watch('type'), true)}
                        control={control}
                        watch={watch}
                        getValues={getValues}
                        setValue={setValue}
                        useParamBoolean={true}
                    >
                        <h3>
                            Origine <span className="required">*</span>
                        </h3>
                    </SelectComponent>

                    <SelectComponent
                        register={register}
                        value={'to'}
                        container={true}
                        setSearch={setSearchTo}
                        optionValues={selectFunc(watch('type'), false)}
                        watch={watch}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        useParamBoolean={true}
                    >
                        <h3>
                            Pour <span className="required">*</span>
                        </h3>
                    </SelectComponent>

                    {watch('type') === TypeTransaction.cashUser ||
                    watch('type') === TypeTransaction.userCash ? (
                        <SelectComponent
                            value={'transactionUserIn.investAccountId'}
                            register={register}
                            container={true}
                            optionValues={
                                userAllInfo?.investAccounts?.map((val) => {
                                    return {
                                        value: val.id,
                                        label:
                                            val.physicalOrMoral === 'physical'
                                                ? 'Compte physique'
                                                : val.moralAccount
                                                      ?.companyName ||
                                                  'Compte moral',
                                    };
                                }) || []
                            }
                            watch={watch}
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                        >
                            <LabelComponentForm>
                                Invest account
                            </LabelComponentForm>
                        </SelectComponent>
                    ) : null}

                    {watch('type') !== TypeTransaction.cashUser ? (
                        <>
                            <InputComponent
                                register={register}
                                value={'amount'}
                                type={'number'}
                            >
                                <h3>
                                    Montant <span className="required">*</span>
                                </h3>
                            </InputComponent>
                        </>
                    ) : (
                        <>
                            <CheckboxComponent
                                register={register}
                                watch={watch}
                                values={[
                                    {
                                        label: 'Sortie totale du potefeuille',
                                        name: 'transactionUserOut.emptyInvest',
                                    },
                                ]}
                            >
                                {/* <h3>
Valeur actuelle de la part <span className="required">*</span>
</h3> */}
                            </CheckboxComponent>
                            {!watch('transactionUserOut.emptyInvest') ? (
                                <InputComponent
                                    register={register}
                                    value={'amount'}
                                    type={'number'}
                                >
                                    <h3>
                                        Montant{' '}
                                        <span className="required">*</span>
                                    </h3>
                                </InputComponent>
                            ) : null}

                            <TitleComponentForm>
                                Le montant est retiré en utilisant les
                                transactions suivantes
                            </TitleComponentForm>
                            <FieldArray
                                control={control}
                                name={
                                    'transactionUserOut.transactionUserOutItems'
                                }
                            >
                                {({ fields, append, remove }) => (
                                    <>
                                        <div className="">
                                            {fields.map((field, index) => (
                                                <div
                                                    key={field.id}
                                                    className=" grid grid-cols-2 gap-x-3"
                                                >
                                                    <div className=" text-start my-2 font-semibold text-xl col-span-2 border-b-2">
                                                        Transaction {index + 1}
                                                    </div>
                                                    <NumericInput
                                                        formState={formState}
                                                        name={`transactionUserOut.transactionUserOutItems.${index}.withdrawalFee`}
                                                        min={0}
                                                    >
                                                        <LabelComponentForm>
                                                            Frais de sotie
                                                        </LabelComponentForm>
                                                    </NumericInput>

                                                    <NumericInput
                                                        formState={formState}
                                                        name={`transactionUserOut.transactionUserOutItems.${index}.share`}
                                                        min={0}
                                                    >
                                                        <LabelComponentForm>
                                                            Part
                                                        </LabelComponentForm>
                                                    </NumericInput>

                                                    <NumericInput
                                                        formState={formState}
                                                        name={`transactionUserOut.transactionUserOutItems.${index}.nbShare`}
                                                        min={0}
                                                    >
                                                        <LabelComponentForm>
                                                            Nombre de part
                                                        </LabelComponentForm>
                                                    </NumericInput>

                                                    <SelectComponent
                                                        register={register}
                                                        value={`transactionUserOut.transactionUserOutItems.${index}.transactionInId`}
                                                        container={true}
                                                        optionValues={transactionUserSelect()}
                                                        watch={watch}
                                                        control={control}
                                                        getValues={getValues}
                                                        setValue={setValue}
                                                        useParamBoolean={true}
                                                    >
                                                        <LabelComponentForm>
                                                            Transaction{' '}
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </LabelComponentForm>
                                                    </SelectComponent>

                                                    <RemoveFieldArrayComponent
                                                        remove={remove}
                                                        index={index}
                                                        className=" col-span-2"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <PrimaryButton
                                            className=" mx-auto my-2"
                                            onClick={() =>
                                                append({
                                                    ...(defaultValuesTransactionUserOutItem as any),
                                                    // projectId: project.current?.id!,
                                                })
                                            }
                                        >
                                            Ajouter
                                        </PrimaryButton>
                                    </>
                                )}
                            </FieldArray>
                        </>
                    )}

                    {watch('type') === TypeTransaction.userCash ? (
                        <>
                            {!watch('transactionUserIn.actualShare') ? (
                                <InputComponent
                                    register={register}
                                    value={'transactionUserIn.initialShare'}
                                    type={'number'}
                                >
                                    <h3>
                                        Valeur initial de la part{' '}
                                        <span className="required">*</span>
                                    </h3>
                                </InputComponent>
                            ) : null}

                            <CheckboxComponent
                                register={register}
                                watch={watch}
                                values={[
                                    {
                                        label: 'Valeur actuelle de la part ?',
                                        name: 'transactionUserIn.initialShare',
                                    },
                                ]}
                            >
                                {/* <h3>
Valeur actuelle de la part <span className="required">*</span>
</h3> */}
                            </CheckboxComponent>
                        </>
                    ) : null}
                    {watch('type') === TypeTransaction.userCash ? (
                        <>
                            <RadioComponent
                                register={register}
                                watch={watch}
                                name={'transactionUserIn.typeShare'}
                                className={{
                                    container: 'grid grid-cols-2 gap-2',
                                }}
                                values={[
                                    {
                                        value: 'cumulative',
                                        label: 'Capitalise',
                                    },
                                    {
                                        value: 'distribution',
                                        label: 'Distribue',
                                    },
                                ]}
                            >
                                <h3 className=" col-span-2 text-start">
                                    Type de titre{' '}
                                    <span className="required">*</span>
                                </h3>
                            </RadioComponent>

                            <SelectComponent
                                register={register}
                                value={'transactionUserIn.typeInvestisseurId'}
                                control={control}
                                container={true}
                                optionValues={
                                    typeInvestisseurs?.map((val) => {
                                        return {
                                            value: val.id,
                                            label: val.name,
                                        };
                                    }) || []
                                }
                            >
                                <h3>
                                    Type investisseur{' '}
                                    <span className="required">*</span>
                                </h3>
                            </SelectComponent>
                            {watch('transactionUserIn.typeInvestisseurId') !==
                            TypeInvestisseurIdEnum.noFees ? (
                                <InputComponent
                                    register={register}
                                    value={'transactionUserIn.managementFee'}
                                    type={'number'}
                                >
                                    <h3>
                                        Valeur des frais{' '}
                                        <span className="required">*</span>
                                    </h3>
                                </InputComponent>
                            ) : null}
                            <InputComponent
                                register={register}
                                value={'transactionUserIn.entranceFee'}
                                type={'number'}
                            >
                                <h3>
                                    Frais d'entrée{' '}
                                    <span className="required">*</span>
                                </h3>
                            </InputComponent>
                            <InputComponent
                                register={register}
                                value={'transactionUserIn.donationFee'}
                                type={'number'}
                            >
                                <h3>
                                    Donation frais{' '}
                                    <span className="required">*</span>
                                </h3>
                            </InputComponent>
                            {sponsorId ? (
                                <CheckboxComponent
                                    register={register}
                                    watch={watch}
                                    values={[
                                        {
                                            label: 'Transaction parrainé?',
                                            name: 'sponsored',
                                        },
                                    ]}
                                />
                            ) : null}
                            <InputComponent
                                register={register}
                                value={'dateInterest'}
                                type={'date'}
                                required={false}
                            >
                                <h3>Date émission des titres</h3>
                            </InputComponent>
                        </>
                    ) : null}

                    <InputComponent
                        register={register}
                        value={'date'}
                        type={'date'}
                    >
                        <h3>
                            Date <span className="required">*</span>
                        </h3>
                    </InputComponent>
                    {watch('type') === TypeTransaction.cashProject ? (
                        <InputComponent
                            register={register}
                            value={'dateInterest'}
                            type={'date'}
                            required={false}
                        >
                            <h3>Date début des intérêts</h3>
                        </InputComponent>
                    ) : null}
                    <InputComponent
                        register={register}
                        value={'comment'}
                        required={false}
                    >
                        <h3>Commentaire</h3>
                    </InputComponent>
                    <SelectComponent
                        register={register}
                        value={'status'}
                        container={true}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        watch={watch}
                        optionValues={Object.values(TransactionStatus).map(
                            (v: string) => {
                                return {
                                    value: v,
                                    label: v,
                                };
                            }
                        )}
                    >
                        <h3>
                            Status <span className="required">*</span>
                        </h3>
                    </SelectComponent>
                </div>
                {expanded ? (
                    <div className="w-full">
                        <UploaderMulti
                            register={register}
                            value={'files'}
                            watch={watch}
                            setValue={setValue}
                            control={control}
                            size={10}
                            button={false}
                        >
                            <h3 className="text-2xl mb-5">
                                Ajouter des documents
                            </h3>
                        </UploaderMulti>
                        {renderDocument ? renderDocument() : null}
                    </div>
                ) : null}
            </div>
            <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
                {renderButtons({ isLoading })}
            </div>
        </form>
    );

    return (
        <>
            {container({
                children: component,
                expanded,
                setExpanded,
            })}
        </>
    );
};

export default TransactionForm;
