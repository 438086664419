import React from "react";

interface Props {
  scaleValue: number;
}
const CareScaleFond: React.FC<Props> = ({ scaleValue }) => {
  const range = [1, 2, 3, 4, 5];

  function scaleType(classN: string) {
    return <div className={`cercle ${classN}`}></div>;
  }

  return (
    <React.Fragment>
      {range.map((rangeElem) =>
        scaleValue >= rangeElem ? (
          <React.Fragment key={rangeElem.toString()}>
            {scaleType("plein")}
          </React.Fragment>
        ) : (
          <React.Fragment key={rangeElem.toString()}>
            {scaleType("")}
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default CareScaleFond;
