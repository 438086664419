import {
    SubscriptionContractForSigningDto,
    SubscriptionUserFondsDto,
    useContractSignedMutation,
    useFetchTokenQuery,
    useGetLatestFeeConfigurationByFeeIdQuery,
    useGetSubscriptionUserQuery,
    useGetUserWithAllInfoForCustomerQuery,
    useSendSubscriptionToBeSignedMutation,
} from '@api/api';
import { DocusealForm } from '@docuseal/react';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
    generateInvestmentContractPdf,
    getNumeroBulletinSubscriptions,
} from '@utils/reportHelper/generateInvestmentContractPdf';
import { transformDate } from '@utils/Utils';
import { PrimaryButton } from '../../commun/Buttons';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import { MenuDashBoardUserBoolState } from '../../Context/AppContext';
import { useNotificationContext } from '../../Context/notification-context';

const GenerateContract = ({
    s,
    sign,
    setSign,
    admin = false,
}: {
    s: SubscriptionUserFondsDto;
    sign: boolean;
    setSign: React.Dispatch<React.SetStateAction<boolean>>;
    admin?: boolean;
}) => {
    const { data: userFetch, isLoading: isLoadingFetch } = useFetchTokenQuery();

    const { data: user, isLoading: isLoading } =
        useGetUserWithAllInfoForCustomerQuery(userFetch?.id || 0, {
            skip: userFetch ? false : true,
        });

    const [uri, setUri] = useState<undefined | string>();
    const [uriPrview, setUriPreview] = useState<undefined | string>();

    const performanceFeeId = 3;

    const { data: feeWithConfigurations } =
        useGetLatestFeeConfigurationByFeeIdQuery({
            feeId: performanceFeeId,
            date: transformDate(new Date()).toISOString(),
        });

    useEffect(() => {
        if (user && s && feeWithConfigurations) {
            setUri(
                generateInvestmentContractPdf(user, s, feeWithConfigurations)
            );
            setUriPreview(
                generateInvestmentContractPdf(
                    user,
                    s,
                    feeWithConfigurations,
                    true
                )
            );
        }
    }, [user, s, feeWithConfigurations]);

    const { showError, showSuccess } = useNotificationContext();
    const [slug, setSlug] = useState<undefined | string>();

    const [sendPdf, { isLoading: isLoadingSend }] =
        useSendSubscriptionToBeSignedMutation();

    const sendPdfToBeSigned = async () => {
        if (uri) {
            const data: SubscriptionContractForSigningDto = {
                file: uri?.split(',')[1],
                userId: userFetch?.id!,
                subscriptionUserFondsId: s.id,
                email: user?.email!,
                phone: user?.userInfo?.phone!,
                name: `Souscription n° ${getNumeroBulletinSubscriptions(s.id)}- ${
                    user?.firstName
                } ${user?.lastName.toUpperCase()}`,
            };
            // Update if contract already exists
            // Add slug to susbsription Add table contractInfo (slud, templateId, dateGenerated)
            await sendPdf({
                subscriptionContractForSigningDto: data,
                userId: userFetch?.id!,
            })
                .unwrap()
                .then((data: any) => {
                    setSlug(data.slug);
                    setSign(true);
                    menuBoolState?.setToggleMenu(!menuBoolState?.toggleMenu);
                    showSuccess('Succès', 'Le document peut être signé');
                })
                .catch((e) => {
                    showError(
                        'Erreur',
                        "Une erreur s'est produite lors de l'envoi du document"
                    );
                });
        }
    };

    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    return (
        <>
            {isLoadingFetch || isLoading ? (
                <Loading />
            ) : uriPrview ? (
                <>
                    {/* h-[96vh] */}
                    <div className=" m-2 ">
                        {isLoadingFetch || isLoading || isLoadingSend ? (
                            <Loading />
                        ) : (
                            <>
                                {slug && sign ? (
                                    <SignDocument
                                        slug={slug}
                                        email={user?.email!}
                                        sId={s.id}
                                        userId={userFetch?.id!}
                                        phoneNumber={user?.userInfo?.phone!}
                                        setSign={setSign}
                                    />
                                ) : (
                                    <>
                                        {/* <embed
                      src={uriPrview}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    ></embed> */}
                                        <object
                                            data={uriPrview}
                                            type="application/pdf"
                                            width="100%"
                                            height="100%"
                                            // style={{ minHeight: "80vh" }}
                                            id="pdfObject"
                                            onLoad={() => {
                                                if (
                                                    document.getElementById(
                                                        'pdfObject'
                                                    )
                                                ) {
                                                    document.getElementById(
                                                        'pdfObject'
                                                    )!.style.minHeight = '80vh';
                                                }
                                            }}
                                        >
                                            <p>
                                                Your browser does not support
                                                PDFs.
                                                <a
                                                    href={uriPrview}
                                                    className=" text-mainColor"
                                                    download={'test'}
                                                    target="_blank"
                                                >
                                                    {' '}
                                                    Download the PDF
                                                </a>
                                                .
                                            </p>
                                        </object>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="w-full justify-center flex items-center my-5">
                        {uri && !sign && !admin ? (
                            <PrimaryButton
                                onClick={sendPdfToBeSigned}
                                loading={isLoadingSend}
                            >
                                Signer le document
                            </PrimaryButton>
                        ) : null}
                        {/* <PrimaryButton
              onClick={() => getSubmission(s.id)}
              loading={isLoadingGetSubmission}
            >
              Get submission
            </PrimaryButton> */}
                    </div>
                </>
            ) : (
                <div className="text-white h-full m-2 flex items-center justify-center">
                    Something went wrong when generating report.
                </div>
            )}
        </>
    );
};

function SignDocument({
    slug,
    email,
    sId,
    userId,
    setSign,
    phoneNumber,
}: {
    slug: string;
    phoneNumber: string;
    email: string;
    sId: number;
    userId: number;
    setSign: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [contractSingned, { isLoading: isLoadingContractSingned }] =
        useContractSignedMutation();

    const { refetch } = useGetSubscriptionUserQuery(
        {
            subscriptionUserFondsId: sId,
            userId: userId || 0,
        },
        {
            skip: !sId || userId === undefined,
        }
    );
    const text = (
        <>
            Veuillez signer la souscription en inscrivant votre{' '}
            <span className=" font-semibold">nom</span> et{' '}
            <span className=" font-semibold">prénom</span>, et confirmer la
            signature avec le code reçu par SMS. Si, le numéro ({phoneNumber})
            n'est plus valide cliquez{' '}
            <Link
                className=" text-mainColor hover:underline font-semibold"
                to={`/DashBoardClient/donnees-personnelles?pageDonneePerso=edit-user`}
            >
                ici
            </Link>{' '}
            pour le modifier.
        </>
    );

    const [open, setOpen] = useState<boolean>(true);
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    const [openLoading, setOpenLoading] = useState<boolean>(false);

    const onComplete = async (data: any) => {
        setOpenLoading((curr) => !curr);
        contractSingned({
            userId: userId,
            contractSignedDto: {
                jsonSigned: JSON.stringify(data),
                subscriptionUserFondsId: sId,
            },
        })
            .unwrap()
            .then((res: any) => {
                setOpenLoading((curr) => !curr);

                setSign(false);
                window.location.reload();
                // if (res?.message && res.message === "Document not found") {
                //   // await 2s
                //   setTimeout(() => {
                //     setSign(false);
                //     refetch();
                //   }, 2000);
                // } else {

                // }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="relative">
            {isLoadingContractSingned ? (
                <LoadingToGetContract
                    isLoading={isLoadingContractSingned}
                    open={openLoading}
                    setOpen={setOpenLoading}
                />
            ) : null}
            <PopUp
                width=" max-w-[700px]"
                open={open}
                buttonBoolean={false}
                setOpen={setOpen}
            >
                <>
                    <div className=" text-justify">{text}</div>
                    <div className="justify-center mt-4 items-center flex">
                        <PrimaryButton onClick={() => setOpen(false)}>
                            D'accord
                        </PrimaryButton>
                    </div>
                </>
            </PopUp>

            <div className=" z-[19] sticky top-0 bg-thirdBackgroundColor w-full">
                <div className="z-[101] my-2 flex justify-center">
                    <PrimaryButton
                        onClick={() => {
                            setSign(false);
                            menuBoolState?.setToggleMenu(
                                !menuBoolState?.toggleMenu
                            );
                        }}
                    >
                        Annuler
                    </PrimaryButton>
                </div>
                <h3 className="px-2 font-mainFontFamily text-lg">{text}</h3>
            </div>

            <DocusealForm
                src={`https://docuseal.co/d/${slug}`}
                email={email}
                onComplete={(data) => onComplete(data)}
                expand={false}
            />
        </div>
        // </PopUp>
    );
}

export default GenerateContract;

function LoadingToGetContract({
    isLoading,
    open,
    setOpen,
}: {
    isLoading: boolean;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    return (
        <PopUp
            width=" max-w-[700px]"
            open={open}
            buttonBoolean={false}
            setOpen={setOpen}
        >
            <div className="h-full w-full flex items-center justify-center">
                <p> Veuillez patienter quelque instant</p>
                {isLoading ? <Loading /> : null}
            </div>
        </PopUp>
    );
}

// Example function to generate PDF for an investment contract
