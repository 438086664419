import { useFetchTokenQuery } from '@api/api';
import { MenuDashBoardUserBoolState } from '@components/Context/AppContext';
import WrapComponentDashboardUser from '@components/DashboardUser/commun/WrapComponentDashboardUser';
import { useContext } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';

const Withdraw = () => {
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

    const [stepWithdraw, setStepWithdraw] = useQueryParam(
        'stepWithdraw',
        NumberParam
    );

    return (
        <div
            className={`mx-auto  md:mt-0 lg:px-2 md:pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto `}
        >
            <WrapComponentDashboardUser
                title="Retrait"
                backButton={stepWithdraw !== undefined}
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
            >
                <></>
            </WrapComponentDashboardUser>
        </div>
    );
};

export default Withdraw;
