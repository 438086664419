import React from 'react';
import FormulaireContact from '../components/About/FormulaireContact';
import Avertissementcomponent from '../components/commun/Avertissementcomponent';
import Footer from '../components/commun/Footer';
import Navigation from '../components/commun/Navigation';

const Contact = () => {
    return (
        <React.Fragment>
            <Navigation bol_nosfonds={false} bol_transparent={false} />
            <FormulaireContact />
            <Avertissementcomponent />

            <Footer />
        </React.Fragment>
    );
};

export default Contact;
