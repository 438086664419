import {
    useFetchTokenQuery,
    useGetUserWithAllInfoForCustomerQuery,
    UserWithAllInfoDto,
    useUpdateUserBasicInfoMutation,
} from '@api/api';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { formatDate } from '../../../../function/Utils';

import { MenuDashBoardUserBoolState } from '../../../Context/AppContext';
import { useNotificationContext } from '../../../Context/notification-context';
import { IMenu } from '../../../DashboardAdmin/UserManage/UserManage';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import Loading from '../../../commun/Loading';
import WrapComponentDashboardUser from '../../commun/WrapComponentDashboardUser';
import UpdateUserInfo, { FormUpdateUserInfo } from '../UpdateUserInfo';
import FormQuestion from './FormQuestion';

import HorizontalMenu from '../../../commun/HorizontalMenu';

import { isValidAdressForm } from '@utils/DocumentUserHelper';
import {
    onSubmitDocumentAddress,
    onSubmitDocumentIdentity,
} from '../../../commun/formComponent/OnSubmitHelpers';

const DonneesPerso = () => {
    const { data: userFetch, isLoading: isLoadingFetch } = useFetchTokenQuery();

    const { data: user, isLoading: isLoading } =
        useGetUserWithAllInfoForCustomerQuery(userFetch?.id || 0, {
            skip: userFetch ? false : true,
        });

    const [pageQuery, setPageQuery] = useQueryParam(
        'pageDonneePerso',
        StringParam
    );

    const navigate = useNavigate();

    const menuList: IMenu[] = [
        {
            queryPage: 'Identite',
            label: 'Identité',
        },
        {
            queryPage: 'Documents',
            label: 'Documents',
        },
        {
            queryPage: 'questions',
            label: 'Questionnaires',
        },
    ];

    const selectPage = () => {
        switch (pageQuery) {
            case 'Identite':
                return <Identite user={user!} />;
            case 'Documents':
                return <Document user={user!} />;
            case 'questions':
                return <FormQuestion user={user!} />;
            default:
                return <Identite user={user!} />;
                break;
        }
    };

    useEffect(() => {
        if (pageQuery === undefined) {
            setPageQuery('Identite');
        }
    }, []);

    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    return (
        <>
            {isLoading || isLoadingFetch ? (
                <Loading />
            ) : (
                <div
                    className={`mx-auto w-full md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto`}
                >
                    <WrapComponentDashboardUser
                        title="Données personnelles"
                        description=""
                        backButton={pageQuery === 'edit-user' ? true : false}
                        onClickReturn={() => navigate(-1)}
                        classNameHeader={`${
                            !menuBoolState?.toggleMenu ? '!pl-[40px]' : ''
                        }`}
                        classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
                        subTitle={
                            pageQuery === 'edit-user' ? (
                                <div className="flex gap-3 justify-end items-center">
                                    {/* <WhiteButton
                    className=""
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="mr-2 text-secondColor"
                    />
                    Retour
                  </WhiteButton> */}
                                    <p
                                        className={`text-end text-xl uppercase text-titleColor`}
                                    >
                                        Modifier ses données personnelles
                                    </p>
                                </div>
                            ) : null
                        }
                    >
                        {pageQuery === 'edit-user' ? null : (
                            <div className="md:h-fit ml-2">
                                <HorizontalMenu
                                    items={menuList}
                                    queryPage="pageDonneePerso"
                                />
                            </div>
                        )}
                        <div className={`py-3 `}>
                            {isLoading || isLoadingFetch ? (
                                <Loading />
                            ) : (
                                <>
                                    {pageQuery === 'edit-user' && user ? (
                                        <UpdateInfo user={user!} />
                                    ) : (
                                        <>{selectPage()}</>
                                    )}
                                </>
                            )}
                        </div>
                    </WrapComponentDashboardUser>
                </div>
                // <div className="mx-auto  md:mt-0 lg:px-2 pb-4 pt-6 md:w-[100%] h-[100vh] overflow-auto">
                //   <div className=" lg:shadow-low sm:rounded-lg overflow-auto md:grid md:grid-rows-userInfoInAdminDashboard h-full p-2">
                //     <div className="md:h-fit">
                //       <Menu menuList={menuList} />
                //     </div>
                //     <div className="p-3">{selectPage()}</div>
                //   </div>
                // </div>
            )}
        </>
    );
};

function Menu({ menuList }: { menuList: IMenu[] }) {
    const [pageQuery, setPageQuery] = useQueryParam(
        'pageDonneePerso',
        StringParam
    );

    return (
        <>
            {/* Menu with User identity, KYC, Documents, Historique Transactions, Audit log  */}
            <div className="flex h-fit justify-stretch w-full items-center flex-wrap mt-4 mb-2">
                {menuList.map((item, key) => (
                    <div
                        key={key}
                        className={`w-full md:w-fit flex items-center justify-center py-2 md:px-5 cursor-pointer ${
                            pageQuery === item.queryPage
                                ? 'border-b-4 border-b-mainColor'
                                : ''
                        }`}
                        onClick={() => setPageQuery(item.queryPage)}
                    >
                        <p className="">{item.label}</p>
                    </div>
                ))}
            </div>
        </>
    );
}

export default DonneesPerso;

function Identite({ user }: { user: UserWithAllInfoDto }) {
    const navigate = useNavigate();

    const [pageQuery, setPageQuery] = useQueryParam(
        'pageDonneePerso',
        StringParam
    );

    return (
        <div className="relative mx-auto w-full px-2">
            <div className="w-full my-4 flex justify-center items-center">
                <PrimaryButton onClick={() => setPageQuery('edit-user')}>
                    Modifier ses données personnelles
                </PrimaryButton>
            </div>
            <div className="relative overflow-auto">
                {/* <div className="absolute right-5 top-2">
                {user.confirmed ? (
                  <SuccessButton>Compte Confirmer</SuccessButton>
                ) : (
                  <ErrorButton>Compte Non confirmé</ErrorButton>
                )}
              </div> */}
                <dl className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0 ">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            Prénom
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {user.firstName} {user.lastName}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            Sexe
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {user.sex}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            Adresse email
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {user.email}
                        </dd>
                    </div>

                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            Nationalité
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {user.userInfo?.nationality}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            Adresse
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {user.userInfo?.adress}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            Date de naissance
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {formatDate(new Date(user.userInfo?.birthDay!))}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            Lieu de naissance
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {user.userInfo?.birthPlace}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            Sponsor
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            Cette utilisateur a parrainé{' '}
                            <span className="font-semibold">
                                {user?.sponsor?.length ?? 0}
                            </span>{' '}
                            utilisateurs
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                            Status
                        </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {user.status}
                        </dd>
                    </div>
                    {/* <div className="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Attachments
                  </dt>
                  <dd className="mt-2 text-sm text-gray-900">
                    <ul
                      role="list"
                      className="divide-y divide-gray-100 rounded-md border border-gray-200"
                    >
                      <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                        <div className="flex w-0 flex-1 items-center">
                          <PaperClipIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="ml-4 flex min-w-0 flex-1 gap-2">
                            <span className="truncate font-medium">
                              resume_back_end_developer.pdf
                            </span>
                            <span className="flex-shrink-0 text-gray-400">
                              2.4mb
                            </span>
                          </div>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <a
                            href="#"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Download
                          </a>
                        </div>
                      </li>
                      <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                        <div className="flex w-0 flex-1 items-center">
                          <PaperClipIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="ml-4 flex min-w-0 flex-1 gap-2">
                            <span className="truncate font-medium">
                              coverletter_back_end_developer.pdf
                            </span>
                            <span className="flex-shrink-0 text-gray-400">
                              4.5mb
                            </span>
                          </div>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <a
                            href="#"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Download
                          </a>
                        </div>
                      </li>
                    </ul>
                  </dd>
                </div> */}
                </dl>
            </div>
        </div>
    );
}

function UpdateInfo({ user }: { user: UserWithAllInfoDto }) {
    const [pageQuery, setPageQuery] = useQueryParam(
        'pageDonneePerso',
        StringParam
    );

    const [editUser] = useUpdateUserBasicInfoMutation();

    const formState = useForm<FormUpdateUserInfo>({
        defaultValues: {
            userId: user?.id!,
            userInfoInvestAccount: {
                adress: user?.userInfo?.adress ?? '',
                phone: user?.userInfo?.phone ?? '',
                professionalSituation:
                    user?.userInfo?.professionalSituation ?? '',
                politicalyExposed: (user?.userInfo?.politicalyExposed ==
                    undefined || user?.userInfo?.politicalyExposed == null
                    ? undefined
                    : user?.userInfo?.politicalyExposed
                      ? 'true'
                      : 'false') as any,
                politicalyExposedDetails:
                    user?.userInfo?.politicalyExposedDetails ?? '',

                usCitizen: 'false' as any,
            },
        },
    });

    const {
        register,
        control,
        watch,
        setValue,
        handleSubmit,
        formState: { dirtyFields },
    } = formState;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { showError, showSuccess } = useNotificationContext();

    const onSubmit = async (data: FormUpdateUserInfo) => {
        setLoading((curr) => !curr);

        // update doc address

        let succeed = true;

        succeed = await onSubmitDocumentAddress(
            data?.userInfoInvestAccount?.documentAdress,
            showError,
            showSuccess,
            user?.id!
        );

        succeed = await onSubmitDocumentIdentity(
            data?.userInfoInvestAccount?.documentIdentity1,
            showError,
            showSuccess,
            user?.id!
        );

        await editUser({
            userId: data.userId,
            adress: data.userInfoInvestAccount.adress,
            phone: data.userInfoInvestAccount.phone,
            professionalSituation:
                data.userInfoInvestAccount.professionalSituation,
            politicalyExposed: data.userInfoInvestAccount.politicalyExposed,
            politicalyExposedDetails:
                data.userInfoInvestAccount.politicalyExposedDetails,
            usCitizen: data.userInfoInvestAccount.usCitizen,
        })
            .unwrap()
            .then(() => {
                showSuccess(
                    'Modifié',
                    'Vos données personnelles ont été modifié avec succès'
                );
            })
            .catch(() => {
                showError(
                    'Erreur',
                    'Erreur lors de la modification de vos données personnelles'
                );
            });
        setLoading((curr) => !curr);
        if (succeed) {
            setPageQuery('Identite');
        }
    };

    const isValidAdressFormState = useMemo(() => {
        if (!dirtyFields.userInfoInvestAccount?.adress) return true;

        return isValidAdressForm(watch('userInfoInvestAccount.documentAdress'));
    }, [dirtyFields.userInfoInvestAccount?.adress, watch()]);

    // const { ValidationCode, handleSubmitForm } = useValidationCode({
    //   onSubmit: handleSubmit(onSubmit),
    // });

    return (
        <div className="form_investisseur_container w-full md:w-[11/12] mx-auto px-2">
            {/* <ValidationCode>
        <p className="text-lg text-titleColor">
          Afin de valider la modification vous devez rentrer ci-dessous le code
          envoyé par mail
        </p>
      </ValidationCode> */}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={``}
                style={{ width: `90%` }}
            >
                <div
                    className={`w-full mx-auto flex flex-col
           
        `}
                >
                    <UpdateUserInfo formState={formState as any} />
                    <div className="w-full mt-3 mb-2 flex justify-center gap-3 items-center">
                        <WhiteButton onClick={() => navigate(-1)}>
                            Annuler
                        </WhiteButton>
                        <PrimaryButton
                            type="submit"
                            loading={loading}
                            disabled={
                                (watch(
                                    'userInfoInvestAccount.usCitizen'
                                ) as any) == 'true' || !isValidAdressFormState
                                    ? true
                                    : false
                            }
                        >
                            Valider
                        </PrimaryButton>
                    </div>
                    {!isValidAdressFormState ? (
                        <div className="">
                            <p className="text-red-500 text-sm">
                                Veuillez mettre à jour le ou les documents
                                relatifs à votre adresse.
                            </p>
                        </div>
                    ) : null}
                </div>
            </form>
        </div>
    );
}

function Document({ user }: { user: UserWithAllInfoDto }) {
    return (
        <div className="relative mx-auto overflow-auto w-full">
            <div className="overflow-auto">Documents</div>
        </div>
    );
}
