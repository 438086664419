export default function Spinner({
  size = 8,
  className,
}: {
  size?: number;
  className?: string;
}) {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <div
        style={{ borderTopColor: "transparent" }}
        className={
          "h-" +
          size +
          " w-" +
          size +
          " border-2 border-gray-400 border-solid rounded-full animate-spin " +
          className
        }
      ></div>
    </div>
  );
}
