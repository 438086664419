import React, { useCallback, useMemo } from "react";
import { IReChartPieSeries } from "./PieReChart";

import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
} from "recharts";
import { useAppSelector } from "../../../redux/store";
// @ts-ignore
import palette from "google-palette";

export interface IReChartPieOuterSeries {
  name: string;
  value: number;
  length: number;
}

interface Props {
  dataInner: IReChartPieSeries[];
  dataOuter: IReChartPieOuterSeries[];
  insideColor: string;
  seize?: {
    md?: number;
    lg?: number;
    sm?: number;
  };
  k?: number;
  legend?: boolean;
  label?: boolean;
}

const TwoLevelPieChart = ({
  dataInner,
  dataOuter,
  insideColor,
  k = 0,
  label = true,
  legend = true,
  seize = {
    md: 120,
    lg: 150,
    sm: 80,
  },
}: Props) => {
  const pageState = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );
  const measure =
    (pageState.heightScreen > 1000
      ? seize.lg
      : pageState.heightScreen > 650
      ? seize.md
      : seize.sm) || 100;

  const COLORS = palette(["sequential"], dataOuter.length + 1, k).map(
    (col: any) => `#${col}`
  );

  const indexLength = useMemo(() => {
    let length = 0;

    return dataOuter.map((d, index) => {
      length += d.length;
      return {
        index: index + 1,
        length,
      };
    });
  }, [dataOuter]);

  const getColor = useCallback(
    (index: number) => {
      let k = 0;
      for (let i = 0; i < dataOuter.length; i++) {
        if (i === 0 && indexLength[i].length >= index) {
          k = indexLength[i].index;
          break;
        }

        if (
          i > 0 &&
          indexLength[i - 1].length <= index &&
          indexLength[i].length >= index
        ) {
          k = indexLength[i].index;
          break;
        }
      }
      return COLORS[k || 0];
    },
    [indexLength]
  );

  return (
    // <ResponsiveContainer width="100%" height="400px">
    <PieChart
      width={pageState.widthScreen < 650 ? 325 : 400}
      height={pageState.heightScreen > 1000 ? 400 : 350}
    >
      <Pie
        data={dataInner}
        isAnimationActive={false}
        dataKey="value"
        cx="50%"
        cy="50%"
        outerRadius={measure - 30}
        fill={insideColor}
        // label={label}
      >
        <>
          {dataInner.map((entry, index) => (
            <Cell key={`cell-${index + 1}`} fill={getColor(index + 1)} />
          ))}
        </>
      </Pie>
      <Tooltip formatter={(value, name, props) => value + " %"} />
      <Pie
        data={dataOuter}
        dataKey="value"
        isAnimationActive={false}
        cx="50%"
        cy="50%"
        innerRadius={measure - 20}
        outerRadius={measure}
        fill="#388FFF"
        label={label}
      >
        <>
          {dataOuter.map((entry, index) => (
            <Cell
              key={`cell-${index + 1}`}
              fill={COLORS[(index + 1) % (COLORS.length + 1)]}
            />
          ))}
        </>
      </Pie>

      {legend ? (
        <Legend
          payload={dataOuter.map((item, index) => ({
            value: item.name,
            type: "square",
            color: COLORS[(index + 1) % (COLORS.length + 1)],
          }))}
        />
      ) : null}
    </PieChart>
    // </ResponsiveContainer>
  );
};

export default TwoLevelPieChart;
