import React from "react";

const Avantages: React.FC = () => {
  return (
    <React.Fragment>
      <h2 className="avantages_titre">
        New Paradigms c'est aussi choisir la simplicité
      </h2>
      <div className="avantages">
        <ul className="avantages_sub">
          <li>Souscription (&lt;10 minutes)</li>
          <li>Intégration CGP</li>
        </ul>
        <ul className="avantages_sub">
          <li>
            Une approche entièrement <br /> digitalisée
          </li>
        </ul>
        <ul className="avantages_sub">
          <li>Suivi des investissements</li>
          <li>Suivi des liquidités</li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Avantages;
