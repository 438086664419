import React from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "../../function/Utils";
import { useAppSelector } from "../../redux/store";
import ArticleManage from "./ArticleManage";
import Menu from "./Menu";

const ArticlesManage = () => {
  const articlesData = [
    ...useAppSelector((state) => state.articleReducer.articles),
  ];

  return (
    <div className="articleManage item_container config_1">
      <div className="head">
        <h3>Voici la liste des articles</h3>
      </div>
      <div className="list_articles_container">
        <div className="head_list_articles">
          <h2>Titre</h2>
          <h2>Date de publication</h2>
          <h2>Catégories</h2>
          <h2>Ordre</h2>
        </div>

        {!isEmpty(articlesData) &&
          articlesData
            ?.sort((a, b) => a.order - b.order)
            ?.map((article, key) => (
              <ArticleManage key={key} article={article} number={key} />
            ))}
      </div>
    </div>
  );
};

export default ArticlesManage;
