import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { IMenu } from '../DashboardAdmin/UserManage/UserManage';

interface Props {
    items: IMenu[];
    queryPage: string;
    className?: string;
    onClick?: () => void;
}

const HorizontalMenu: React.FC<Props> = ({ items, queryPage }) => {
    const [pageQuery, setPageQuery] = useQueryParam(queryPage, StringParam);

    return (
        <div>
            <div className="sm:hidden mt-3 ">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    className="block rounded-md py-1 px-1 w-11/12 mx-auto !border-mainColor !border-2  focus:border-secondColor focus:ring-secondColor"
                    defaultValue={
                        items.find((i) => i.queryPage === queryPage)?.label
                    }
                    onChange={(e) => setPageQuery(e.target.value)}
                    value={pageQuery as any}
                >
                    {items.map((tab) => (
                        <option key={tab.label} value={tab.queryPage}>
                            {tab.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="flex h-fit justify-stretch w-full items-center flex-wrap mt-4 mb-2">
                    {items.map((item, key) => (
                        <div
                            key={key}
                            className={`w-full md:w-fit flex items-center justify-center py-2 md:px-5 cursor-pointer ${
                                pageQuery === item.queryPage
                                    ? 'border-b-4 border-b-mainColor'
                                    : ''
                            }`}
                            onClick={() => setPageQuery(item.queryPage)}
                        >
                            <p className="">{item.label}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* Menu with User identity, KYC, Documents, Historique Transactions, Audit log  */}
        </div>
    );
};

export default HorizontalMenu;

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

export function TabsMenu({ items, queryPage, onClick, className }: Props) {
    const [pageQuery, setPageQuery] = useQueryParam(queryPage, StringParam);

    useEffect(() => {
        if (!pageQuery) {
            setPageQuery(items[0].queryPage);
        }
    }, []);

    return (
        <div className={`${className ? className : ''} w-11/12 md:w-full`}>
            <div className="sm:hidden w-full my-1">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    className="block rounded-md py-1 px-1 w-full mx-auto !border-gray-300 !border-2  focus:border-secondColor focus:ring-secondColor"
                    defaultValue={
                        items.find((i) => i.queryPage === queryPage)?.label
                    }
                    onChange={(e) => {
                        setPageQuery(e.target.value);
                        onClick && onClick();
                    }}
                    value={pageQuery as any}
                >
                    {items.map((tab) => (
                        <option key={tab.label} value={tab.queryPage}>
                            {tab.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <nav
                    className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                    aria-label="Tabs"
                >
                    {items.map((tab, tabIdx) => (
                        <p
                            key={tab.label}
                            onClick={() => {
                                setPageQuery(tab.queryPage);
                                onClick && onClick();
                            }}
                            className={classNames(
                                tab.queryPage === pageQuery
                                    ? 'text-gray-900'
                                    : 'text-gray-500 hover:text-gray-700',
                                tabIdx === 0 ? 'rounded-l-lg' : '',
                                tabIdx === items.length - 1
                                    ? 'rounded-r-lg'
                                    : '',
                                'group relative min-w-0 flex-1 overflow-hidden cursor-pointer bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
                            )}
                            aria-current={
                                tab.queryPage === pageQuery ? 'page' : undefined
                            }
                        >
                            <span>{tab.label}</span>
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    tab.queryPage === pageQuery
                                        ? 'bg-secondColor'
                                        : 'bg-transparent',
                                    'absolute inset-x-0 bottom-0 h-0.5'
                                )}
                            />
                        </p>
                    ))}
                </nav>
            </div>
        </div>
    );
}
