interface Props {
  setActiveCategoryFond: React.Dispatch<React.SetStateAction<string>>;
  activeCategoryFond: string;
  categories: string[];
}

const CategoriesFond: React.FC<Props> = ({
  setActiveCategoryFond,
  categories,
  activeCategoryFond,
}) => {
  return (
    <div className="flex-col items-center md:flex-row categories space-y-5 md:space-y-0 md:space-x-3">
      <select
        value={activeCategoryFond}
        onChange={(e) => setActiveCategoryFond(e.target.value)}
        className="categories_select border-texteColor border"
      >
        <option value="">---</option>
        {categories.map((cat) => (
          <option key={cat} value={cat}>
            {cat}
          </option>
        ))}
      </select>
      <button className="pointer" onClick={() => setActiveCategoryFond("")}>
        Réinitialiser
      </button>
    </div>
  );
};

export default CategoriesFond;
