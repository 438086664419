import {
    useGetDashboardUserQuery,
    useGetProjectByIdForUserQuery,
} from '@api/api';
import { faSeedling, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef } from 'react';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { PrimaryButton } from '../../commun/Buttons';
import Loading from '../../commun/Loading';
import { MenuDashBoardUserBoolState } from '../../Context/AppContext';
import { DisplayFondsImpact } from '../Activities';
import WrapComponentDashboardUser from '../commun/WrapComponentDashboardUser';

import { useNavigate } from 'react-router';
import { LazyImage } from '../../commun/animation/LazyImage';
import TableComponent from '../../DashboardAdmin/BackOffice/Components/TableComponent';
import FondsUser from './FondsUser';
import ProjectUser from './ProjectUser';

const IndexFondsUser = () => {
    const [queryPage, setQueryPage] = useQueryParam(
        'pageFondsUser',
        StringParam
    );
    const [queryFondsId, setQueryFondsId] = useQueryParam(
        'fondsId',
        NumberParam
    );
    const [queryProjectId, setQueryProjectId] = useQueryParam(
        'projectId',
        NumberParam
    );
    const [queryScroll, setQueryScroll] = useQueryParam(
        'scrollHeight',
        NumberParam
    );

    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    const { data: dashBordUserAdmin, isLoading } = useGetDashboardUserQuery();

    const handleClickReturn = () => {
        switch (queryPage) {
            case 'fonds':
                setQueryPage(undefined);
                setQueryFondsId(undefined);
                setQueryScroll(undefined);
                break;
            case 'project':
                setQueryPage('fonds');
                setQueryProjectId(undefined);
                break;
            default:
                setQueryPage(undefined);
                setQueryFondsId(undefined);
                setQueryProjectId(undefined);
                setQueryScroll(undefined);
                break;
        }
    };

    const {
        data: project,
        isLoading: isLoadingProject,
        refetch: refetchProject,
    } = useGetProjectByIdForUserQuery(queryProjectId ?? 0, {
        skip: queryProjectId === undefined,
    });

    const displaySubtitle = (): string => {
        switch (queryPage) {
            case 'fonds':
                return (
                    dashBordUserAdmin?.fondsInfo.find(
                        (f) => f.fondsId === queryFondsId
                    )?.fondsName ?? ''
                );
            case 'project':
                return project?.name ?? '';
            default:
                return '';
        }
    };

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (queryPage === 'fonds' && queryScroll === undefined && scrollRef) {
            scrollRef?.current?.scrollTo(0, 0);
        }
    }, [queryPage, queryFondsId, scrollRef]);

    const selectComponent = () => {
        if (queryPage === 'fonds' && queryFondsId) {
            return <FondsUser fondsId={queryFondsId} listRef={scrollRef} />;
        } else if (queryPage === 'project' && queryProjectId) {
            return <ProjectUser projectId={queryProjectId} />;
        } else {
            return <OverviewFonds />;
        }
    };

    return (
        <div
            className={`mx-auto  md:mt-0 lg:px-2 md:pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto `}
        >
            <WrapComponentDashboardUser
                title="Nos fonds"
                description="Découvrez nos différents fonds d'investissement."
                backButton={queryPage !== undefined}
                onClickReturn={handleClickReturn}
                subTitle={
                    queryPage !== undefined ? (
                        <div className="flex gap-3 justify-end items-center">
                            <p className="text-xl text-titleColor">
                                {displaySubtitle()}
                            </p>
                            {/* <WhiteButton className="" onClick={() => handleClickReturn()}>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="mr-2 text-secondColor"
                />
                Retour
              </WhiteButton> */}
                        </div>
                    ) : null
                }
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
                ref={scrollRef}
            >
                <div className=" relative md:mr-0">
                    {isLoading ? <Loading /> : <>{selectComponent()}</>}
                </div>
            </WrapComponentDashboardUser>
        </div>
    );
};

function OverviewFonds() {
    const { data: dashBordUser, isLoading } = useGetDashboardUserQuery();

    const [queryPage, setQueryPage] = useQueryParam(
        'pageFondsUser',
        StringParam
    );
    const [queryFondsId, setQueryFondsId] = useQueryParam(
        'fondsId',
        NumberParam
    );

    return (
        <div className="mt-5 justify-center md:w-11/12 mx-auto mb-3">
            <FondsTable />
            <div className="gap-5  flex flex-wrap justify-center w-full">
                {dashBordUser?.fondsInfo?.map((f) => (
                    <div
                        key={f.fondsId}
                        onClick={() => {
                            setQueryPage('fonds');
                            setQueryFondsId(f.fondsId);
                        }}
                        className="grid py-1 w-[300px] md:w-[350px] bg-secondBackgroundColor rounded-md grid-rows-fondsCard cursor-pointer transition-all hover:opacity-70 hover:scale-[1.01]"
                    >
                        <h3 className="my-1 text-center font-mainFontFamily text-2xl text-gray-500">
                            {f.fondsName}
                        </h3>
                        <LazyImage
                            src={f.cover || ''}
                            className={{
                                img: 'w-full h-[200px] bg-cover bg-center rounded-sm',
                                div: 'h-[200px] my-2',
                            }}
                        />
                        <div className="grid grid-cols-3 place-items-center font-semibold text-center">
                            <p>TRI</p>
                            <p>Taux de défaut</p>
                            <p>Impact</p>
                        </div>
                        <div className="grid grid-cols-3 place-items-center text-center">
                            <p className="text-secondColor">
                                {(f.triNotBlackListLast30Days > 0
                                    ? f.triNotBlackListLast30Days -
                                      (f.triNotBlackListLast30Days -
                                          f.fondsPerformance) *
                                          0.3
                                    : 0
                                ).toFixed(2)}{' '}
                                %
                            </p>
                            <p className="">0.00 %</p>
                            <div className="flex justify-center items-start mx-auto w-fit">
                                <DisplayFondsImpact name={f.fondsName} />
                            </div>
                        </div>
                        <div className="flex w-full mw-auto justify-center items-center my-2">
                            <PrimaryButton>Découvrir</PrimaryButton>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default IndexFondsUser;

function FondsTable() {
    const { data: dashBordUserAdmin, isLoading } = useGetDashboardUserQuery();
    const classNameObejct = {
        item: 'text-sm font-light px-1 py-2 whitespace-nowrap',
    };

    const navigate = useNavigate();

    return (
        <div className="p-2 my-2 pb-4">
            <h3 className=" font-mainFontFamily text-center text-lg font-semibold lg:text-2xl">
                Synthèse comparative de nos fonds
            </h3>
            {isLoading ? (
                <div className="flex justify-center items-center">
                    <Loading />
                </div>
            ) : (
                <div className="w-full h-full">
                    <React.Fragment>
                        <TableComponent
                            head={[
                                '',
                                'Performance *',
                                'Diversification % **',
                                'Taux de défaut %',
                                'Impact',
                            ]}
                        >
                            <tbody>
                                <React.Fragment>
                                    {dashBordUserAdmin?.fondsInfo
                                        ?.filter((f) => f.show)
                                        ?.map((fond, key) => (
                                            <tr
                                                className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                                                    key % 2 === 1
                                                        ? 'bg-secondBackgroundColor'
                                                        : 'bg-white '
                                                } `}
                                            >
                                                <td
                                                    className={`${classNameObejct.item} pl-1`}
                                                >
                                                    {fond.fondsName}
                                                </td>
                                                <td
                                                    className={`${classNameObejct.item}`}
                                                >
                                                    {(fond.triNotBlackListLast30Days >
                                                    0
                                                        ? fond.triNotBlackListLast30Days -
                                                          (fond.triNotBlackListLast30Days -
                                                              fond.fondsPerformance) *
                                                              0.3
                                                        : 0
                                                    ).toFixed(2)}{' '}
                                                    %
                                                </td>
                                                <td
                                                    className={`${classNameObejct.item}`}
                                                >
                                                    {fond.diversification} %
                                                </td>
                                                <td
                                                    className={`${classNameObejct.item}`}
                                                >
                                                    0.00 %
                                                </td>
                                                <DisplayFondsImpact
                                                    name={fond.fondsName}
                                                />
                                            </tr>
                                        ))}
                                </React.Fragment>
                            </tbody>
                        </TableComponent>

                        <div className="mt-2 mb-5 w-fit mx-auto">
                            <PrimaryButton
                                onClick={() =>
                                    navigate('/DashBoardClient/investir')
                                }
                            >
                                Investir
                            </PrimaryButton>
                        </div>
                    </React.Fragment>
                    <div className=" bottom-[4px] left-2 flex flex-wrap gap-2 text-gray-500 italic text-md">
                        <p className=" ">
                            * Performance des 30 derniers jours.
                        </p>
                        <p className=" ">
                            ** Exposition moyenne du portefeuille à un projet.
                        </p>
                        <p>
                            <FontAwesomeIcon
                                icon={faSeedling}
                                className="text-green-600"
                            />
                            : Impact environnemental
                        </p>
                        <p>
                            <FontAwesomeIcon
                                icon={faUsers}
                                className="text-mainColor"
                            />
                            : Impact social
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}
