import { createContext, useContext, useReducer } from 'react';

import { EnumSocketTypeFromServer } from '@components/DashboardAdmin/BackOffice/Overview/DataBase';
import SocketComponent from '../commun/SocketComponent';

export interface ISocketMessage {
    id: string;
    message: string;
    progress?: string;
    date?: Date;
    type: EnumSocketTypeFromServer;
}

export interface ISessionSocket {
    socketMessage: ISocketMessage;
}

type Action =
    | { type: 'ADD'; create: ISocketMessage }
    | { type: 'DELETE'; id: string }
    | { type: 'REMOVE_ALL' }
    | { type: 'UPDATE'; update: ISocketMessage }
    | { type: 'CREATE_SESSION'; create: ISessionSocket };

const initialState: ISocketMessage[] = [];
export const SocketContext = createContext<{
    state: ISocketMessage[];
    dispatch: React.Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

export const ADD = 'ADD';
export const DELETE = 'DELETE';
export const REMOVE_ALL = 'REMOVE_ALL';
export const UPDATE = 'UPDATE';

export const socketReducer = (state: ISocketMessage[], action: Action) => {
    switch (action.type) {
        case ADD:
            return [
                ...state,
                {
                    // id: ,
                    ...action.create,
                },
            ];
        case UPDATE:
            return state.map((t) =>
                t.id === action.update.id ? action.update : t
            );
        case DELETE:
            return state.filter((t) => t.id !== action.id);
        case REMOVE_ALL:
            return initialState;
        default:
            return state;
    }
};

export function SocketProvider(props: { children: React.ReactNode }) {
    const [sockets, socketDispatch] = useReducer(socketReducer, initialState);
    return (
        <SocketContext.Provider
            value={{ state: sockets, dispatch: socketDispatch }}
        >
            {props.children}
            <div
                aria-live="assertive"
                className="fixed z-50 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
                <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                    {sockets.map((n, key) => (
                        <SocketComponent {...n} key={key}></SocketComponent>
                    ))}
                </div>
            </div>
        </SocketContext.Provider>
    );
}

export const useSocket = () => {
    return useContext(SocketContext);
};
