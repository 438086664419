import {
    TransactionStatus,
    TransactionTableDto,
    TypeTransaction,
    useGetTransactionsTableQuery,
} from '@api/api';
import UpdateTransactionForm from '@components/commun/formComponent/ModelForm/Transaction/UpdateTransactionForm';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { typeFiltration } from '@interfaces/BackOffice';
import {
    filterFunctions,
    formatDate,
    printLargeValue,
    typeTransactionFunc,
} from '@utils/Utils';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import Loading from '../../../commun/Loading';
import PopUp from '../../../commun/PopUp';
import StatusTransaction from '../../../commun/StatusTransaction';
import { BackOfficeState } from '../../../Context/AppContext';
import FilterApparition from '../Components/FilterApparition';
import FiltrationColumnTable, {
    filtrationInterface,
    typeOrderOrFiltration,
} from '../Components/FiltrationColumnTable';

interface Props {
    editTransactionId?: number;
    addTransaction?: true;
}

const ViewTableTransactions: React.FC<Props> = ({
    editTransactionId,
    addTransaction,
}) => {
    const backOfficeState = useContext(BackOfficeState);

    const [editToggle, setEditToggle] = useState<boolean>(false);

    const [queryParam, setQueryParam] = useQueryParam(
        'transactionId',
        NumberParam
    );

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold text-right',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap text-right',
    };

    const [transactionsDataFiltered, setTransactionsDataFiltered] = useState<
        TransactionTableDto[]
    >([]);
    const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
    const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);
    const [filterColumn, setFilterColumn] = useState<
        filtrationInterface<TransactionTableDto>[]
    >([
        {
            element: 'date',
            type: typeFiltration.date,
            value: 'decroissant',
            orderOrFiltration: typeOrderOrFiltration.order,
        },
    ]);

    // useEffect(() => {
    //   if (!isEmpty(transactionsData)) {
    //   filtrationFunction();

    //   }
    // }, [transactionsData]);

    const [searchFrom, setSearchFrom] = useState<string>('');
    const [searchTo, setSearchTo] = useState<string>('');
    const [searchType, setSearchType] = useState<string>('');
    const [status, setStatus] = useState<string>(TransactionStatus.VALIDATED);

    const {
        data: transactionsDataWithNbrows,
        isLoading,
        isFetching,
        isError,
    } = useGetTransactionsTableQuery(
        {
            limit: nbItemsShowed,
            offset: startIntervalNbItems,
            dateStart: new Date(2020, 0, 1)?.toISOString(),
            dateEnd: new Date(2040, 0, 1)?.toISOString(),
            fondsId: backOfficeState?.fondsId || 0,
            from: searchFrom,
            to: searchTo,
            type: searchType,
            status: status,
        },
        {
            skip:
                (backOfficeState?.fondsId || 0) == 0 ||
                backOfficeState?.fondsId === undefined ||
                nbItemsShowed > 0
                    ? false
                    : true,
        }
    );

    // const { data: irr } = useGetIrrFondsIdQuery(backOfficeState?.fondsId || 0, {
    //   skip: backOfficeState?.fondsId === undefined,
    // });

    // console.log("irr", irr);

    const transactionsData = transactionsDataWithNbrows?.rows;
    const nbItems = transactionsDataWithNbrows?.count;

    const filtrationFunction = () => {
        let transactionDataFiltered_init: TransactionTableDto[] =
            transactionsData ?? [];
        for (const item of filterColumn) {
            transactionDataFiltered_init = filterFunctions(
                transactionDataFiltered_init,
                item.element,
                item.type,
                item.value
            );
        }
        setTransactionsDataFiltered(transactionDataFiltered_init);
    };

    useEffect(() => {
        if (!isEmpty(transactionsData)) {
            filtrationFunction();
        }
    }, [filterColumn, transactionsData]);

    const [fillInterestDate, setFillInterestDate] = useState<boolean>(false);

    const handleFillInterestDate = () => {
        if (fillInterestDate) {
            setSearchType(TypeTransaction.cashProject);
            setFilterColumn([
                {
                    element: 'date',
                    type: typeFiltration.date,
                    value: 'decroissant',
                    orderOrFiltration: typeOrderOrFiltration.order,
                },
                {
                    element: 'type',
                    type: typeFiltration.exact,
                    value: TypeTransaction.cashProject,
                    orderOrFiltration: typeOrderOrFiltration.filter,
                },

                {
                    element: 'projectToClosed',
                    type: typeFiltration.boolean,
                    value: false,
                    orderOrFiltration: typeOrderOrFiltration.filter,
                },
            ]);
        } else {
            setSearchType('');
            setFilterColumn([
                {
                    element: 'date',
                    type: typeFiltration.date,
                    value: 'decroissant',
                    orderOrFiltration: typeOrderOrFiltration.order,
                },
            ]);
        }
    };

    useEffect(() => {
        handleFillInterestDate();
    }, [fillInterestDate]);

    return (
        <React.Fragment>
            <>
                {editToggle ? (
                    <EditTransaction
                        setEditToggle={setEditToggle}
                        editToggle={editToggle}
                    />
                ) : null}
                <div className="flex justify-end gap-3 items-center">
                    <PrimaryButton
                        onClick={() => setFillInterestDate(!fillInterestDate)}
                    >
                        {fillInterestDate
                            ? 'Annuler'
                            : "Remplir les dates d'intérêts"}
                    </PrimaryButton>
                </div>
                <FilterApparition
                    startIntervalNbItems={startIntervalNbItems}
                    setNbItemsShowed={setNbItemsShowed}
                    nbItemsShowed={nbItemsShowed}
                    offsetName="transactionOffset"
                    setStartIntervalNbItems={setStartIntervalNbItems}
                    array={transactionsData ?? []}
                    nbItems={nbItems || 0}
                    offset={true}
                />
                <div className="flex flex-col max-w-full overflow-x-auto">
                    <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
                        <div className="py-2 inline-block min-w-full">
                            <div className="overflow-hidden">
                                <table className="min-w-full relative">
                                    <thead className="bg-white border-b border-t">
                                        <tr>
                                            <th
                                                scope="col"
                                                className={`text-sm text-gray-900 px-6 py-4 text-left font-semibold `}
                                            >
                                                #
                                            </th>
                                            <FiltrationColumnTable
                                                element={'type'}
                                                setSearch={setSearchType}
                                                search={searchType}
                                                type={typeFiltration.exact}
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.filter
                                                }
                                                value={['all'].concat(
                                                    Object.values(
                                                        TypeTransaction
                                                    ).map((v) => v)
                                                )}
                                                columnName={() => (
                                                    <p className="inline-block">
                                                        Type
                                                    </p>
                                                )}
                                                textLeft={false}
                                            />
                                            <FiltrationColumnTable
                                                element={'from'}
                                                search={searchFrom}
                                                setSearch={setSearchFrom}
                                                type={
                                                    typeFiltration.nameWithIdTransaction
                                                }
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                columnName={() => (
                                                    <p className="inline-block">
                                                        Origine
                                                    </p>
                                                )}
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.filter
                                                }
                                                textLeft={false}
                                            />
                                            <FiltrationColumnTable
                                                element={'to'}
                                                search={searchTo}
                                                setSearch={setSearchTo}
                                                type={
                                                    typeFiltration.nameWithIdTransaction
                                                }
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                columnName={() => (
                                                    <p className="inline-block">
                                                        Pour
                                                    </p>
                                                )}
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.filter
                                                }
                                                textLeft={false}
                                            />

                                            <FiltrationColumnTable
                                                element={'amount'}
                                                type={typeFiltration.amount}
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                columnName={() => (
                                                    <p className="inline-block">
                                                        Montant
                                                    </p>
                                                )}
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.order
                                                }
                                                textLeft={false}
                                            />
                                            <FiltrationColumnTable
                                                element={'date'}
                                                type={typeFiltration.date}
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                columnName={() => (
                                                    <p className="inline-block">
                                                        Date
                                                    </p>
                                                )}
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.order
                                                }
                                                textLeft={false}
                                            />
                                            <FiltrationColumnTable
                                                element={'dateInterest'}
                                                type={typeFiltration.date}
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                columnName={() => (
                                                    <p className="inline-block">
                                                        Date intérêt
                                                    </p>
                                                )}
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.order
                                                }
                                                textLeft={false}
                                            />
                                            <FiltrationColumnTable
                                                element={'status'}
                                                setSearch={setStatus}
                                                search={status}
                                                type={typeFiltration.exact}
                                                setFilterColumn={
                                                    setFilterColumn
                                                }
                                                orderOrFiltration={
                                                    typeOrderOrFiltration.filter
                                                }
                                                value={['all'].concat(
                                                    Object.values(
                                                        TransactionStatus
                                                    ).map((v) => v)
                                                )}
                                                columnName={() => (
                                                    <p className="inline-block">
                                                        Statut
                                                    </p>
                                                )}
                                                textLeft={false}
                                            />
                                        </tr>
                                    </thead>

                                    <React.Fragment>
                                        <tbody className="relative">
                                            {(isLoading || isFetching) &&
                                            !editToggle ? (
                                                <tr className="">
                                                    <td
                                                        colSpan={8}
                                                        className="pt-10 text-center mx-auto"
                                                    >
                                                        <Loading />
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {!!(
                                                        backOfficeState?.fondsId !==
                                                            undefined &&
                                                        transactionsDataFiltered !==
                                                            undefined &&
                                                        transactionsData
                                                    ) && (
                                                        <>
                                                            {transactionsData.length ===
                                                            0 ? (
                                                                <tr className="w-full mx-auto">
                                                                    <td
                                                                        colSpan={
                                                                            8
                                                                        }
                                                                        className="text-center text-gray-500 text-xl  pt-10"
                                                                    >
                                                                        Aucune
                                                                        transaction
                                                                        touvée
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {transactionsDataFiltered
                                                                        ?.filter(
                                                                            (
                                                                                val
                                                                            ) =>
                                                                                val.fondsId ===
                                                                                    backOfficeState?.fondsId &&
                                                                                (fillInterestDate
                                                                                    ? val.dateInterest ==
                                                                                      null
                                                                                    : true)
                                                                        )
                                                                        // .filter(
                                                                        //   (val, index) =>
                                                                        //     index >= startIntervalNbItems &&
                                                                        //     index <
                                                                        //       startIntervalNbItems + nbItemsShowed
                                                                        // )
                                                                        // .sort(
                                                                        //   (a, b) =>
                                                                        //     new Date(b.date).getTime() -
                                                                        //     new Date(a.date).getTime()
                                                                        // )
                                                                        .map(
                                                                            (
                                                                                transaction,
                                                                                key
                                                                            ) => (
                                                                                <tr
                                                                                    onClick={() => {
                                                                                        setQueryParam(
                                                                                            transaction.id
                                                                                        );
                                                                                        setEditToggle(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                                                                                        key %
                                                                                            2 ===
                                                                                        0
                                                                                            ? 'bg-gray-100'
                                                                                            : 'bg-white'
                                                                                    } `}
                                                                                >
                                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                                        {key +
                                                                                            1 +
                                                                                            startIntervalNbItems}
                                                                                    </td>
                                                                                    <td
                                                                                        className={
                                                                                            classNameObejct.item
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            transaction.type
                                                                                        }
                                                                                    </td>
                                                                                    <td
                                                                                        className={
                                                                                            classNameObejct.item
                                                                                        }
                                                                                    >
                                                                                        {typeTransactionFunc(
                                                                                            transaction.type,
                                                                                            true,
                                                                                            transaction
                                                                                        )}
                                                                                    </td>
                                                                                    <td
                                                                                        className={
                                                                                            classNameObejct.item
                                                                                        }
                                                                                    >
                                                                                        {typeTransactionFunc(
                                                                                            transaction.type,
                                                                                            false,
                                                                                            transaction
                                                                                        )}
                                                                                    </td>
                                                                                    <td
                                                                                        className={
                                                                                            classNameObejct.item
                                                                                        }
                                                                                    >
                                                                                        {printLargeValue(
                                                                                            transaction.amount
                                                                                        )}
                                                                                    </td>
                                                                                    <td
                                                                                        className={
                                                                                            classNameObejct.item
                                                                                        }
                                                                                    >
                                                                                        {formatDate(
                                                                                            new Date(
                                                                                                transaction.date
                                                                                            )
                                                                                        )}
                                                                                    </td>
                                                                                    <td
                                                                                        className={`${classNameObejct.item} !text-center`}
                                                                                    >
                                                                                        {transaction.dateInterest
                                                                                            ? formatDate(
                                                                                                  new Date(
                                                                                                      transaction.dateInterest!
                                                                                                  )
                                                                                              )
                                                                                            : '-'}
                                                                                    </td>
                                                                                    <td
                                                                                        className={
                                                                                            classNameObejct.item
                                                                                        }
                                                                                    >
                                                                                        <StatusTransaction
                                                                                            status={
                                                                                                transaction.status
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                </React.Fragment>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </React.Fragment>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </React.Fragment>
    );
};

export default ViewTableTransactions;

function EditTransaction({
    editToggle,
    setEditToggle,
}: {
    editToggle: boolean;
    setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const [transactionId, setQueryParam] = useQueryParam(
        'transactionId',
        NumberParam
    );

    return (
        <>
            {transactionId ? (
                <UpdateTransactionForm
                    transactionId={transactionId}
                    afterSubmitEditLogic={() => setEditToggle(false)}
                    afterDeleteLogic={() => setEditToggle(false)}
                    renderButtons={({ isLoading }) => (
                        <>
                            <WhiteButton
                                onClick={() => {
                                    setEditToggle(false);
                                }}
                                className="w-full"
                            >
                                Cancel
                            </WhiteButton>
                            <PrimaryButton
                                loading={isLoading}
                                type="submit"
                                className="w-full"
                            >
                                Edit
                            </PrimaryButton>
                        </>
                    )}
                    container={({
                        children,
                        setExpanded,
                        expanded,
                        transaction,
                        setOpenDelete,
                        openDelete,
                    }) => (
                        <PopUp
                            open={editToggle}
                            setOpen={setEditToggle}
                            buttonBoolean={false}
                            expand={expanded}
                            setExpand={setExpanded}
                            disabledOnclose={openDelete}
                            title={() => {
                                return (
                                    <div className="relative">
                                        <h3 className="text-3xl font-semibold leading-6 text-gray-900">
                                            Editer cette transaction
                                        </h3>
                                        {transaction && transaction?.id ? (
                                            <div className="absolute top-[-20px] cursor-pointer left-4 transition-all hover:scale-105">
                                                <FontAwesomeIcon
                                                    onClick={() =>
                                                        setOpenDelete(true)
                                                    }
                                                    icon={faTrash}
                                                    className=""
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                );
                            }}
                        >
                            {children}
                        </PopUp>
                    )}
                />
            ) : null}
        </>
    );
}
