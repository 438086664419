import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    stringToFloat,
    stringToInt,
    transformDate,
} from '../../../../function/Utils';

import {
    ProjectStatus,
    UpdateBlackListDto,
    useDeleteBlacklistByIdMutation,
    useGetProjectByIdQuery,
    useUpdateBlacklistMutation,
} from '@api/api';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import useDeleteItem from '../../../commun/CustomHook/useDeleteItem';
import FieldArray from '../../../commun/formComponent/FieldArray';
import InputComponent from '../../../commun/formComponent/InputComponent';
import RemoveFieldArrayComponent from '../../../commun/formComponent/RemoveFieldArrayComponent';
import SelectComponent from '../../../commun/formComponent/SelectComponent';
import PopUp from '../../../commun/PopUp';
import { useNotificationContext } from '../../../Context/notification-context';

function UpdateProjectBlacklist({
    projectId,
    open,
    setOpen,
}: {
    projectId: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const {
        data: project,
        isLoading,
        refetch,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const [toggleDelete, setToggleDelete] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        control,
        getValues,
    } = useForm<UpdateBlackListDto>();

    const [editBlackList, { isLoading: isLoadingEdit }] =
        useUpdateBlacklistMutation();

    const { showSuccess, showError } = useNotificationContext();

    const onSubmit = async (data: UpdateBlackListDto) => {
        data = {
            ...data,

            comment: data.comment ?? undefined,
            fondsId: data.fondsId,
            dates: data.dates.map((dateb) => {
                return {
                    date: transformDate(dateb.date).toDateString(),
                    deletedAt: dateb.deletedAt
                        ? transformDate(dateb.deletedAt).toDateString()
                        : undefined,
                };
            }),
            projectId: data.projectId,
            interests: data.interests.map((pi) => {
                return {
                    id: pi.id ?? undefined,
                    value: stringToFloat(pi.value),
                    fondsId: stringToInt(data.fondsId),
                    blackListId: stringToInt(data.id),
                    date: transformDate(pi.date).toDateString(),
                };
            }),
        };
        editBlackList(data)
            .unwrap()
            .then(async (res) => {
                await refetch();
                setOpen(false);
                showSuccess(
                    'Edited',
                    'Project in blackList editing successfully'
                );
            })
            .catch((err) => {
                showError('Error', 'Error editing project in blacklist');
            });
    };

    const setDefautlValues = (): void => {
        if (project && project.blacklist) {
            reset({
                fondsId: project.fondsId,
                id: project.blacklist?.id,
                projectId: project.blacklist?.projectId,
                comment: project.blacklist?.comment,
                statusProject: project.status,
                dates:
                    project.blacklist?.dates.map((dateb) => {
                        return {
                            date: transformDate(new Date(dateb.date).getTime())
                                .toJSON()
                                .split('T')[0] as any,
                            deletedAt: dateb.deletedAt
                                ? (transformDate(
                                      new Date(dateb.deletedAt).getTime()
                                  )
                                      .toJSON()
                                      .split('T')[0] as any)
                                : undefined,
                        };
                    }) ?? [],

                interests:
                    project.blacklist?.interests.map((pi) => {
                        return {
                            ...pi,
                            date: new Date(pi.date).toJSON()?.split('T')[0],
                        };
                    }) ?? [],
            });
        }
    };

    useEffect(() => {
        if (project && project.blacklist) {
            setDefautlValues();
        }
    }, [project]);

    const updateDateEnd = useCallback(
        debounce(() => {
            if (getValues(`dates.0.date`) != null) {
                setValue(
                    `interests`,
                    getValues(`interests`).map((val, index) => {
                        if (index === 0) {
                            return {
                                ...val,
                                date: transformDate(
                                    new Date(getValues(`dates.0.date`))
                                )
                                    .toJSON()
                                    .split('T')[0],
                            };
                        } else {
                            return val;
                        }
                    })
                );
            }
        }, 500),
        []
    );

    useEffect(() => {
        updateDateEnd();
    }, [watch(`dates.0.date`)]);

    const [deleteBlackList, { isLoading: isLoadingDelete }] =
        useDeleteBlacklistByIdMutation();

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        projectId: number;
        blaclListId: number;
    }>({
        deleteItem: () =>
            deleteBlackList({
                blackListId: project?.blacklist?.id!,
                projectId: project?.id!,
            }),
        message: 'Project in blackList',
        isLoading: isLoadingDelete,
        toDoIfSuccess: async () => {
            await refetch();
        },
    });

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title={() => {
                return (
                    <div className="relative">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Editer le projet en liste noire
                        </h3>
                        {project?.blacklist?.id ? (
                            <FontAwesomeIcon
                                onClick={() => setOpenDelete(true)}
                                icon={faTrash}
                                className="absolute top-8 md:top-0 cursor-pointer right-4 transition-all hover:scale-105"
                            />
                        ) : null}
                    </div>
                );
            }}
            buttonBoolean={false}
        >
            <DeleteComponent title="ce projet en blackList" />
            {/* {toggleDelete ? (
          <DeleteProjectInBlackListComponent
            open={toggleDelete}
            setOpen={setToggleDelete}
            projectId={projectId}
            blackListId={project?.blacklist?.id!}
            statusProject={project?.status!}
            setEditBlacklisted={setOpen}
          />
        ) : null} */}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
            >
                <FieldArray control={control} className="w-full" name="dates">
                    {({ fields, append, remove }) => (
                        <>
                            <div className="flex flex-col w-full space-y-2 mb-2">
                                {fields.map((field, index) => (
                                    <div
                                        key={field.id}
                                        className={`grid ${
                                            index > 0
                                                ? 'grid-cols-[1fr,1fr,auto]'
                                                : 'grid-cols-2'
                                        }  w-full gap-x-2 relative`}
                                    >
                                        <InputComponent
                                            register={register}
                                            className={{
                                                containerAbove: 'w-full',
                                            }}
                                            type="date"
                                            value={`dates.${index}.date`}
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Date{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>

                                        <InputComponent
                                            register={register}
                                            className={{
                                                containerAbove: 'w-full',
                                            }}
                                            type="date"
                                            required={false}
                                            value={`dates.${index}.deletedAt`}
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                DeletedAt
                                            </h3>
                                        </InputComponent>
                                        {index > 0 ? (
                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                                className=""
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                            <PrimaryButton
                                className=" mx-auto mb-2"
                                onClick={() =>
                                    append({
                                        date: new Date()
                                            .toJSON()
                                            ?.split('T')[0],
                                        deletedAt: undefined,
                                        // projectId: project.current?.id!,
                                    } as any)
                                }
                            >
                                Ajouter
                            </PrimaryButton>
                        </>
                    )}
                </FieldArray>

                <SelectComponent
                    register={register}
                    value={'statusProject'}
                    container={true}
                    optionValues={Object.values(ProjectStatus)?.map((val) => {
                        return {
                            value: val,
                            label: val,
                        };
                    })}
                    control={control}
                >
                    <h3>
                        Situation du projet <span className="required">*</span>
                    </h3>
                </SelectComponent>

                <h3 className="text-center my-2 mx-auto">
                    List des intérêt en fonction de la date
                </h3>
                <FieldArray control={control} name="interests">
                    {({ fields, append, remove }) => (
                        <>
                            <div className="flex flex-col w-full space-y-2 mb-2">
                                {fields.map((field, index) => (
                                    <div
                                        key={field.id}
                                        className={`grid ${
                                            index > 0
                                                ? 'grid-cols-[1fr,1fr,auto]'
                                                : 'grid-cols-2'
                                        }  w-full gap-x-2 relative`}
                                    >
                                        <InputComponent
                                            register={register}
                                            type="date"
                                            value={`interests.${index}.date`}
                                            cursorNotAllowed={
                                                index === 0 ? true : undefined
                                            }
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Date{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>

                                        <InputComponent
                                            register={register}
                                            type="number"
                                            value={`interests.${index}.value`}
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Intérêt{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>
                                        {index > 0 ? (
                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                            <PrimaryButton
                                className=" mx-auto mb-2"
                                onClick={() =>
                                    append({
                                        date: new Date()
                                            .toJSON()
                                            ?.split('T')[0],
                                        value: 0,
                                        fondsId: watch('fondsId')!,
                                        // projectId: project.current?.id!,
                                    })
                                }
                            >
                                Ajouter
                            </PrimaryButton>
                        </>
                    )}
                </FieldArray>

                <InputComponent
                    register={register}
                    value={'comment'}
                    required={false}
                >
                    <h3>Commentaire</h3>
                </InputComponent>
                <div className="mt-5 w-full sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 text-center">
                    <WhiteButton
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit" loading={isLoadingEdit}>
                        Modifier
                    </PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}

export default UpdateProjectBlacklist;
