import { TabsMenu } from '@components/commun/HorizontalMenu';
import { MenuDashBoardAdminBoolState } from '@components/Context/AppContext';
import CheckShareComputation from '@components/DashboardAdmin/MetricsPage/CheckShareComputation';
import MetricsFeeValue from '@components/DashboardAdmin/MetricsPage/MetricsFeeValue';
import { IMenu } from '@components/DashboardAdmin/UserManage/UserManage';
import WrapComponentDashboardUser from '@components/DashboardUser/commun/WrapComponentDashboardUser';
import { useContext } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

const MetricsIndex = () => {
    const menuBoolState = useContext(MenuDashBoardAdminBoolState);

    const [pageQuery, setPageQuery] = useQueryParam('metricsPage', StringParam);

    const menuList: IMenu[] = [
        {
            queryPage: 'metrics-fee',
            label: 'Frais',
        },
        {
            queryPage: 'check-share-computation',
            label: 'Check share computation',
        },
    ];

    const selectPage = () => {
        switch (pageQuery) {
            case 'metrics-fee':
                return <MetricsFeeValue />;
            case 'check-share-computation':
                return <CheckShareComputation />;
            default:
                return <MetricsFeeValue />;
        }
    };

    return (
        <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh]">
            <WrapComponentDashboardUser
                title="Gestion des frais"
                description=""
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
            >
                <div className="md:h-fit bg-backgroundColor w-full flex justify-center items-center mt-[-2px]   sticky top-[-2px] z-[99] mb-2 mx-auto">
                    <TabsMenu
                        className="  "
                        items={menuList}
                        queryPage="metricsPage"
                        onClick={() => {}}
                    />
                </div>
                <div className="">{selectPage()}</div>
            </WrapComponentDashboardUser>
        </div>
    );
};

export default MetricsIndex;
