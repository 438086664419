import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { Icon } from "leaflet";
import LocationIcon from "./../../../assets/LocationIcon.svg";
import PlacesAutocomplete, {
  Suggestion,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

interface CoordinatesDto {
  latitude: number;
  longitude: number;
}

interface Props<T extends FieldValues> {
  height: string;
  width: string;
  //   value: Path<T> & keyof T;
  control: Control<T, Path<T>>;
  initialCenter?: {
    latitude: number;
    longitude: number;
  };
}

const PickCoordonates = <T extends object>({
  height,
  width,
  //   value,
  control,
  initialCenter = {
    latitude: 46.41,
    longitude: 2.332,
  },
}: Props<T>) => {
  const {
    field: {
      value: latitude,
      onChange: latitudeOnChange,
      ...restLatitudeField
    },
  } = useController<T>({
    name: "latitude" as Path<T>,
    control: control,
  });

  const {
    field: {
      value: longitude,
      onChange: longitudeOnChange,
      ...restLongitudeField
    },
  } = useController<T>({
    name: "longitude" as Path<T>,
    control: control,
  });

  const {
    field: { value: address, onChange: addressOnChange, ...restAddressField },
  } = useController<T>({
    name: "address" as Path<T>,
    control: control,
  });

  function handleMapClick(event: any) {
    const { latlng } = event;

    latitudeOnChange(parseFloat(latlng.lat));
    longitudeOnChange(parseFloat(latlng.lng));
  }

  function handleAdressChange(value: string) {
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        latitudeOnChange(latLng.lat);
        longitudeOnChange(latLng.lng);
        addressOnChange(value);
        const addressData = {
          address: value,
          latitude: latLng.lat,
          longitude: latLng.lng,
        };
      })
      .catch((error) => console.error("Error", error));
  }

  function AddCoordsToMap() {
    useMapEvents({
      click: handleMapClick,
    });
    return null;
  }

  return (
    <div className="w-full mx-auto z-[0] flex flex-col gap-2 my-3">
      <label
        htmlFor="fuelsId"
        className="block mt-2 font-titleFontFamily text-lg font-semibold text-gray-700 text-center"
      >
        Location <span className=" text-red-600">*</span>
      </label>
      <MapContainer
        center={[
          initialCenter?.latitude ?? 46.41,
          initialCenter?.longitude ?? 2.332,
        ]}
        zoom={4}
        style={{ height: height, width: width, zIndex: "0" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <AddCoordsToMap />
        {latitude && longitude && (
          <Marker
            icon={
              new Icon({
                iconUrl: LocationIcon,
                iconSize: [25, 41],
                iconAnchor: [10, 41],
              })
            }
            position={{
              lat: latitude,
              lng: longitude,
            }}
          ></Marker>
        )}
      </MapContainer>
      <div className="flex gap-2 flex-col md:flex-row items-center w-full">
        <div className="w-full">
          <label
            htmlFor="fuelsId"
            className="block text-sm font-semibold text-gray-700 text-center"
          >
            Latitude <span className=" text-red-600">*</span>
          </label>
          <input
            className={`border p-2 focus:ring-orange-400 focus:border-orange-400 flex-1 block w-full rounded-md sm:text-sm border-gray-300`}
            type="number"
            step="any"
            required
            placeholder="latitude"
            value={latitude ? latitude.toString().replace(",", ".") : latitude}
            onChange={(e) => {
              latitudeOnChange(parseFloat(e.target.value));
            }}
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="fuelsId"
            className="block font-semibold text-sm text-gray-700 text-center"
          >
            Longitude <span className=" text-red-600">*</span>
          </label>
          <input
            type="number"
            className={`border p-2 focus:ring-orange-400 focus:border-orange-400 flex-1 block w-full rounded-md sm:text-sm border-gray-300`}
            step="any"
            required
            placeholder="longitude"
            value={
              longitude ? longitude.toString().replace(",", ".") : longitude
            }
            onChange={(e) => {
              longitudeOnChange(parseFloat(e.target.value));
            }}
          />
        </div>
      </div>
      <div className="">
        <label
          htmlFor="fuelsId"
          className="block font-semibold text-sm text-gray-700 text-left ml-1"
        >
          Adresse
        </label>
        <PlacesAutocomplete
          value={address}
          onSelect={(v) => handleAdressChange(v)}
          onChange={(v) => addressOnChange(v)}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Adresse...",
                  className:
                    "location-search-input w-full border p-2 focus:ring-orange-400 focus:border-orange-400 flex-1 block w-full rounded-md sm:text-sm border-gray-300",
                })}
              />
              <div
                className={`autocomplete-dropdown-container ${
                  loading || suggestions?.length > 0
                    ? "border-2 rounded-md mt-2"
                    : ""
                }  `}
              >
                {loading && <div>Loading...</div>}
                {suggestions?.map((suggestion, key) => {
                  const className = suggestion.active
                    ? "suggestion-item--active "
                    : "suggestion-item ";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                        key,
                      })}
                      className="border-b-2 px-2 py-1 text-left"
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    </div>
  );
};

export default PickCoordonates;
