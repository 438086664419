import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import {
  FieldValues,
  Path,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

interface Props<T extends FieldValues> {
  watch: any;
  name: Path<T>;
  setValue: UseFormSetValue<T>;
  size: number;
}

const PreviewFile = <T extends object>(
  props: Props<T> & { children?: ReactNode }
) => {
  // useEffect(() => {
  //   if (props.watch?.(props.name)?.[0] != null) {
  //     if (props.watch?.(props.name)?.[0].type.split("/")[1] === "pdf") {
  //       setUploadedDocument(props.watch?.(props.name)?.[0].name);
  //     }
  //   }
  // }, [props.watch?.(props.name)])

  return (
    <div className="mx-auto mt-3 px-3 w-full flex justify-between flex-row gap-2 items-center">
      {!!props.watch?.(props.name)?.[0] && (
        <React.Fragment>
          {props.watch?.(props.name)?.[0] != null &&
          props.watch?.(props.name)?.[0].type.split("/")[1] === "pdf" ? (
            <FontAwesomeIcon icon={faFilePdf} className="scale-[2]" />
          ) : (
            <img
              src={
                props.watch?.(props.name)?.[0] != null
                  ? URL.createObjectURL(props.watch(props.name)![0])
                  : undefined
              }
              className="w-[150px] mt-10 mb-3"
              alt="..."
            />
          )}
          <div className="">
            <p className="text-sm">{props.watch?.(props.name)?.[0].name}</p>

            <p
              className={`text-bold text-sm font-semibold ${
                props.watch?.(props.name)?.[0].size! / 1024 > props.size * 1024
                  ? "text-red-600"
                  : "text-green-500"
              }`}
            >
              {props.watch?.(props.name)?.[0].size! / 1024 >
              props.size * 1024 ? (
                <>
                  La taille du document n'est pas valide (
                  {(props.watch?.(props.name)?.[0].size! / 1024).toFixed(0)} ko)
                </>
              ) : (
                <>
                  La taille du document est valide (
                  {(props.watch?.(props.name)?.[0].size! / 1024).toFixed(0)} ko)
                </>
              )}
            </p>
          </div>
          <div className="">
            <FontAwesomeIcon
              icon={faTrash}
              className="cursor-pointer text-gray-400"
              onClick={() => props.setValue?.(props.name, [] as any)}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PreviewFile;
