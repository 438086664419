import React from "react";
import { createMarkup } from "../../function/Utils";
import draftToHtml from "draftjs-to-html";
interface Props {
  className?: string;
  content?: string;
  children?: React.ReactNode;
}
const DisplayTextEditor: React.FC<Props> = ({
  className,

  content,
  children,
}) => {
  return (
    <>
      {content && content.includes("blocks") ? (
        <div
          dangerouslySetInnerHTML={createMarkup(
            draftToHtml(JSON?.parse?.(content!))
          )}
          className={`text-editor ${className}`}
        ></div>
      ) : (
        <div>{content ?? ""}</div>
      )}
    </>
  );
};

export default DisplayTextEditor;
