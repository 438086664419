export default function TitlePopUpForm({
    children,
    className,
    onClick,
}: {
    className?: string;
    children: React.ReactNode;
    onClick?: () => void;
}) {
    return (
        <h2
            onClick={onClick}
            className={`text-xl md:text-3xl mb-8 font-semibold text-mainColor font-mainFontFamily ${className}`}
        >
            {children}
        </h2>
    );
}
