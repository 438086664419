import {
    useFetchTokenQuery,
    useGetUserWithAllInfoForCustomerQuery,
    UserStatusType,
    UserWithAllInfoDto,
} from '@api/api';
import AddDashComponent from '@components/commun/Layout/AddDashComponent';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { MenuDashBoardUserBoolState } from '../../../Context/AppContext';
import { PrimaryButton } from '../../../commun/Buttons';
import WrapComponentDashboardUser from '../../commun/WrapComponentDashboardUser';
import { DisplayMoralAccountCard } from '../DisplayMoralAccountCard';
import AddInvestAccount from './AddInvestAccount';
import DisplayInvestAccount from './DisplayInvestAccount';
import EditInvestAccount from './EditInvestAccount';

const InvestAccount = () => {
    const { data: userFetch, isLoading: isLoadingFetch } = useFetchTokenQuery();

    const { data: user, isLoading: isLoading } =
        useGetUserWithAllInfoForCustomerQuery(userFetch?.id || 0, {
            skip: userFetch ? false : true,
        });

    const [pageQuery, setPageQuery] = useQueryParam(
        'pageInvestAccount',
        StringParam
    );

    const selectPage = () => {
        if (user) {
            switch (pageQuery) {
                case 'invest-account':
                    return <Index user={user!} />;
                case 'add-invest-account':
                    return <AddInvestAccount user={user!} />;
                case 'edit-invest-account':
                    return <EditInvestAccount user={user!} />;
                default:
                    return <Index user={user!} />;
                    break;
            }
        } else {
            return <Index user={user} />;
        }
    };

    useEffect(() => {
        if (pageQuery === undefined) {
            setPageQuery('invest-account');
        }
    }, []);
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    const navigate = useNavigate();

    return (
        <div
            className={`mx-auto  md:mt-0 md:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto`}
        >
            <WrapComponentDashboardUser
                title="Compte d'investissement"
                description=""
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
                backButton={
                    pageQuery === 'add-invest-account' ||
                    pageQuery === 'edit-invest-account'
                        ? true
                        : false
                }
                onClickReturn={() => {
                    navigate(-1);
                }}
                subTitle={
                    <div className="flex gap-3 justify-end items-center">
                        <p
                            className={`text-end text-xl uppercase text-titleColor`}
                        >
                            {pageQuery === 'add-invest-account'
                                ? 'Création'
                                : pageQuery === 'edit-invest-account'
                                  ? 'Modification'
                                  : ''}
                        </p>
                    </div>
                }
            >
                <div
                    className={`p-3 overflow-auto 
                    }`}
                >
                    {selectPage()}
                </div>
            </WrapComponentDashboardUser>
        </div>
    );
};

const Index = ({ user }: { user: UserWithAllInfoDto | undefined }) => {
    const [viewCard, setViewCard] = useState(false);
    const [investAccountIdQuery, setInvestAccountIdQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );

    const navigate = useNavigate();

    return (
        <div className="relative mx-auto overflow-auto w-full">
            {viewCard && investAccountIdQuery && user ? (
                <DisplayMoralAccountCard
                    userId={user?.id}
                    open={viewCard}
                    setOpen={setViewCard}
                />
            ) : null}
            <div className="">
                {user?.investAccounts?.length === 0 ? (
                    <div className="flex flex-col items-center justify-center gap-2 my-2">
                        <p className="text-center">
                            Vous n'avez pas encore de compte d'investissement
                        </p>
                        {user?.status ===
                        UserStatusType.doitRemplirSesDonnEsPersonnelles ? (
                            <>
                                <p className=" text-orange-500 text-justify my-2 w-full md:w-10/12 mx-auto">
                                    Vous devez d'abord remplir vos données
                                    personnelles afin de valider votre profil.
                                    Vous pourrez ensuite créer un nombre
                                    illimité de comptes d'investissement.{' '}
                                </p>
                                <div className="flex mt-3 justify-center items-center">
                                    <PrimaryButton>
                                        <Link
                                            to="/DashBoardClient/donnees-personnelles"
                                            className="text-white"
                                        >
                                            Remplir mes données personnelles
                                        </Link>
                                    </PrimaryButton>
                                </div>
                            </>
                        ) : null}
                    </div>
                ) : null}
                {/* <div className="w-full mt-2 mb-4 flex justify-center items-center">
          <Link to="/DashBoardClient/invest-account?pageInvestAccount=add-invest-account">
            <PrimaryButton disabled={user?.status === UserStatusType.doitRemplirSesDonnEsPersonnelles}>
              Créer un compte
            </PrimaryButton>
          </Link>
        </div> */}
                {user?.status ===
                UserStatusType.doitRemplirSesDonnEsPersonnelles ? null : (
                    <>
                        <h3 className="mt-2 mb-4 font-mainFontFamily text-center text-xl">
                            Voici la liste de vos comptes d'investissement
                        </h3>
                        <div className="mx-auto md:mt-2 grid md:grid-cols-2 lg:grid-cols-3 gap-2">
                            {user?.investAccounts?.map((i, index) => (
                                <div
                                    key={index}
                                    className="w-full relative flex flex-col justify-start bg-secondBackgroundColor rounded-lg py-2 px-4"
                                >
                                    <DisplayInvestAccount
                                        setViewCard={setViewCard}
                                        viewCard={viewCard}
                                        i={i}
                                    />
                                </div>
                            ))}
                            <AddDashComponent
                                onClick={() =>
                                    navigate(
                                        '/DashBoardClient/invest-account?pageInvestAccount=add-invest-account'
                                    )
                                }
                            >
                                <p>Ajouter un compte</p>
                            </AddDashComponent>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default InvestAccount;
