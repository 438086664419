import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useState } from "react";
import {
  FieldErrorsImpl,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";

interface Props<T extends FieldValues> {
  errors: FieldErrorsImpl<T>;
  register: UseFormRegister<T>;
  name: Path<T> & keyof T;
  label: string;
  componentError: () => JSX.Element;
}

const PasswordInput = <T extends object>(
  props: Props<T> & { children?: ReactNode }
) => {
  const [viewPassword, setViewPassword] = useState<boolean>(false);

  return (
    <div className="form-item">
      <label>
        {props.label} <span className="required">*</span>
      </label>
      <div className="relative w-full">
        <input
          type={viewPassword ? "text" : "password"}
          {...props.register(props.name)}
          className={`form-control border-texteColor border ${
            props.errors[props.name] ? "is-invalid" : ""
          }`}
        />
        <FontAwesomeIcon
          icon={viewPassword ? faEye : faEyeSlash}
          className="absolute right-3 top-0 bottom-0 my-auto cursor-pointer md:-right-8"
          onClick={() => setViewPassword(!viewPassword)}
        />
      </div>

      {props.componentError()}
    </div>
  );
};

export default PasswordInput;
