import { useGetFutureTransactionsOutShareDQuery } from '@api/api';
import { addDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { NumberParam } from 'use-query-params';
import {
    compareTwoDate,
    isDateClosed,
    printLargeValue,
} from '../../../function/Utils';
import { useGetAllFondsQuery } from '../../../redux/features/fondsSlice';
import AreaChart, { ISeries } from '../../commun/Charts/AreaChart';
import { useQueryParamCustom } from '../../commun/CustomHook/useQueryParamCustom';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import TableComponent from '../BackOffice/Components/TableComponent';

interface FilterForm {
    fondsId: number;
}

const GraphFutureTransaction = () => {
    const { data: fondsData, isLoading: isLoadingFonds } =
        useGetAllFondsQuery();

    const [fondsQuery, setFondsQuery] = useQueryParamCustom(
        'fondsId',
        NumberParam,
        1
    );
    const { register, control, setValue, getValues, watch, handleSubmit } =
        useForm<FilterForm>();
    const { data, isLoading } = useGetFutureTransactionsOutShareDQuery(
        fondsQuery || 0
    );

    const series: ISeries[] = useMemo(() => {
        if (data && data?.amountTransactionOutShareDDay.length > 0) {
            const res = [
                {
                    name: 'Part €',
                    data:
                        data?.amountTransactionOutShareDDay?.map((d) => {
                            return {
                                x: new Date(d.date).getTime(),
                                y: d.amount,
                            };
                        }) ?? [],
                },
            ];

            const dataSorted = [...res[0].data].sort((a, b) => b.x - a.x);
            // get last date
            const lastDate = dataSorted[0].x;

            // get first date
            const firstDate = dataSorted[dataSorted.length - 1].x;

            const result = [];
            let dateI = new Date(firstDate);
            while (compareTwoDate(dateI, new Date(lastDate))) {
                const value = dataSorted.find((d) =>
                    isDateClosed(new Date(d.x), dateI)
                );
                if (!value) {
                    result.push({
                        x: dateI.getTime(),
                        y: 0,
                    });
                } else {
                    result.push({
                        x: dateI.getTime(),
                        y: value.y,
                    });
                }
                dateI = addDays(dateI, 1);
            }
            return [
                {
                    name: 'Part €',
                    data: result,
                },
            ];
        } else {
            const res = [];
            for (let i = 0; i < 30; i++) {
                res.push({
                    x: addDays(new Date(), -i).getTime(),
                    y: 0,
                });
            }
            return [
                {
                    name: 'Part €',
                    data: res,
                },
            ];
        }
    }, [data]);

    const rows = data?.amountTransactionOutShareDMonth?.map((d) => {
        return {
            date: d.date,
            amount: d.amount,
        };
    });

    const classNameObejct = {
        item: 'text-sm font-light px-1 py-2 whitespace-nowrap',
    };

    return (
        <div className="">
            <div className="flex w-full justify-center items-center mb-4">
                <div className="w-fit">
                    <SelectComponent
                        className="!w-[250px]"
                        useParamBoolean={true}
                        optionValues={
                            fondsData?.map((f) => {
                                return {
                                    label: f.name,
                                    value: f.id,
                                };
                            }) ?? []
                        }
                        value={'fondsId'}
                        register={register}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                    >
                        <label className="mb-2 font-mainFontFamily text-mainColor text-lg">
                            Sélectionner un fonds
                        </label>
                    </SelectComponent>
                </div>
            </div>

            <div className="">
                <AreaChart series={series} />
            </div>
            <div className="p-2 flex flex-col gap-2 mx-auto max-w-lg">
                <TableComponent head={['Mois', 'Montant']}>
                    <tbody>
                        {data ? (
                            <>
                                {rows?.map((val, key) => (
                                    <tr
                                        className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                                            key % 2 === 1
                                                ? 'bg-secondBackgroundColor'
                                                : 'bg-white '
                                        } `}
                                    >
                                        <td
                                            className={`${classNameObejct.item} pl-1`}
                                        >
                                            {' '}
                                            {formatInTimeZone(
                                                new Date(val.date),
                                                'Europe/Paris',
                                                'dd/MM/yyyy'
                                            )}
                                        </td>
                                        <td
                                            className={`${classNameObejct.item}`}
                                        >
                                            {printLargeValue(
                                                val.amount?.toFixed(2)
                                            )}{' '}
                                            €
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ) : null}
                    </tbody>
                </TableComponent>
            </div>
        </div>
    );
};

export default GraphFutureTransaction;
