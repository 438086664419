import { useSendResetPasswordEmailMutation } from '@api/api';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useNotificationContext } from '../components/Context/notification-context';
import { PrimaryButton } from '../components/commun/Buttons';
import logo from './../assets/logo.png';

interface formInterface {
    email: string;
}

const AskResetPassword = () => {
    const { showError, showSuccess } = useNotificationContext();

    const validationSchema: Yup.SchemaOf<formInterface> = Yup.object().shape({
        email: Yup.string()
            .required('Email est demandé')
            .email('Email est invalid')
            .lowercase()
            .trim(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<formInterface>({
        resolver: yupResolver(validationSchema),
    });

    const [sendResetPasswordEmail, { isLoading }] =
        useSendResetPasswordEmailMutation();

    const onSubmit = async (data: formInterface) => {
        await sendResetPasswordEmail(data.email)
            .unwrap()
            .then(() => {
                showSuccess(
                    'Envoyé',
                    `Une email à été envoyé à ${data.email} pour réinitialiser votre mot de passe ! `
                );
            })
            .catch((err) => {
                if (err?.response.data?.error === 'email does not exist') {
                    showError('Erreur', `L'email ${data.email} n'existe pas !`);
                } else {
                    showError(
                        'Erreur',
                        "Un problème est survenue lors de l'envoie de votre email !"
                    );
                }
            });
    };

    return (
        <React.Fragment>
            {/* <Navigation bol_nosfonds={false} bol_transparent={false} /> */}
            <div className="flex flex-col items-center justify-start h-screen">
                <Link to="/" className="my-8 h-14 w-14 md:mt-20">
                    <img src={logo} alt="logo Newparadigms" />
                </Link>

                <div className="connexion_container">
                    <div className="connexion">
                        <h3 className="my-3 text-justify">
                            Vous avez oublié votre mot de passe ? Pas de
                            problème veuillez mentioner votre email pour
                            recevoir un email de réinitialisation de votre mot
                            de passe. (Valable 10 minutes)
                        </h3>
                        <form
                            className="formTemplateSingUp"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="form-item">
                                <label>
                                    Votre email{' '}
                                    <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    {...register('email')}
                                    className={`form-control border-texteColor border ${
                                        errors.email ? 'is-invalid' : ''
                                    }`}
                                />
                                <div className="invalid-feedback">
                                    {errors.email?.message}
                                </div>
                            </div>
                            <div className="flex justify-center items-center w-full mt-3">
                                <PrimaryButton type="submit">
                                    Envoyer un email de réinitialisation
                                </PrimaryButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AskResetPassword;
