import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './Loading';

export function PrimaryButton({
    children,
    className,
    onClick,
    disabled = false,
    type = 'button',
    loading = false,
}: {
    children?: React.ReactNode;
    className?: string;
    onClick?: React.ReactEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
    loading?: boolean;
}) {
    return (
        <button
            disabled={disabled}
            type={type}
            onClick={onClick && onClick}
            className={`inline-flex text-center justify-center items-center cursor-pointer border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-mainColor hover:bg-secondColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50 disabled:cursor-not-allowed px-3 py-2
          ${className ? className : ''}`}
        >
            {loading ? <Loading size={4} /> : children}
        </button>
    );
}

export function SecondaryButton({
    children,
    className,
    onClick,
    disabled = false,
    type = 'button',
}: {
    children?: React.ReactNode;
    className?: string;
    onClick?: React.ReactEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
}) {
    return (
        <button
            disabled={disabled}
            type={type}
            onClick={onClick && onClick}
            className={`inline-flex text-center justify-center items-center cursor-pointer border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-secondColor hover:bg-mainColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50 disabled:cursor-not-allowed px-3 py-2
          ${className ? className : ''}`}
        >
            {children}
        </button>
    );
}

export function WhiteButton({
    children,
    className,
    onClick,
    loading,
    disabled = false,
}: {
    children?: React.ReactNode;
    className?: string;
    onClick?: React.ReactEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    loading?: boolean;
}) {
    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={`${
                className ? className : ''
            } inline-flex text-center items-center justify-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 disabled:opacity-50 disabled:cursor-not-allowed focus:ring-offset-2 focus:ring-gray-300`}
        >
            {loading ? <Loading size={4} /> : children}
        </button>
    );
}

export function DarkBlueButton({
    children,
    className,
    onClick,
    disabled = false,
}: {
    children?: React.ReactNode;
    className?: string;
    onClick?: React.ReactEventHandler<HTMLButtonElement>;
    disabled?: boolean;
}) {
    return (
        <button
            type="button"
            disabled={disabled}
            onClick={() => onClick}
            className={`${
                className ? className : ''
            } inline-flex items-center px-3 py-2   text-sm  font-medium rounded-md shadow-sm text-white bg-blue-600 border border-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-50`}
        >
            {children}
        </button>
    );
}

export function LightBlueButtonSmall({
    children,
    className,
    onClick,
    disabled = false,
}: {
    children?: React.ReactNode;
    className?: string;
    onClick?: React.ReactEventHandler<HTMLButtonElement>;
    disabled?: boolean;
}) {
    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={`${
                className ? className : ''
            } inline-flex items-center px-2.5 py-1 border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed`}
        >
            {children}
        </button>
    );
}

export function OrangeButton({
    children,
    className,
    onClick,
    disabled = false,
}: {
    children?: React.ReactNode;
    className?: string;
    onClick?: React.ReactEventHandler<HTMLButtonElement>;
    disabled?: boolean;
}) {
    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={`${
                className ? className : ''
            } inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-400 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed`}
        >
            {children}
        </button>
    );
}

export function SuccessButton({
    children,
    className,
    onClick,
    disabled = false,
}: {
    children?: React.ReactNode;
    className?: string;
    onClick?: React.ReactEventHandler<HTMLButtonElement>;
    disabled?: boolean;
}) {
    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={`${
                className ? className : ''
            } inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed`}
        >
            {children}
        </button>
    );
}

export function ErrorButton({
    children,
    className,
    onClick,
    disabled = false,
}: {
    children?: React.ReactNode;
    className?: string;
    onClick?: React.ReactEventHandler<HTMLButtonElement>;
    disabled?: boolean;
}) {
    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={`${
                className ? className : ''
            } inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed`}
        >
            {children}
        </button>
    );
}

export function BackButton({
    onClick,
    className,
    textBackButton,
}: {
    onClick: () => void;
    className?: string;
    textBackButton?: string;
}) {
    return (
        <>
            <div className="absolute top-[-40px] right-0 md:hidden">
                <div className="flex justify-start items-center ">
                    <WhiteButton
                        className={` min-w-[50px] `}
                        onClick={() => onClick && onClick()}
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className={`text-secondColor ${textBackButton ? 'mr-2' : ''}`}
                        />
                        {textBackButton ? textBackButton : null}
                    </WhiteButton>
                </div>
            </div>
            <div
                className={`hidden md:block self-start ${textBackButton ? '-mt-1 ' : ' '}`}
            >
                <div className="flex justify-end items-start ">
                    <WhiteButton
                        className={` min-w-[50px]  ${textBackButton ? '' : '!py-1 '}`}
                        onClick={() => onClick()}
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className={`text-secondColor ${textBackButton ? 'mr-2' : ''}`}
                        />
                        {textBackButton ? textBackButton : null}
                    </WhiteButton>
                </div>
            </div>
        </>
    );
}
