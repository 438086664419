import {
    CreateCustomUserFeeDto,
    CustomUserFeeType,
    useGetAllFeesQuery,
    useGetAllTypeInvestisseursForFormQuery,
    useGetUsersSearchQuery,
    useGetUserWithAllInfoQuery,
} from '@api/api';
import InputComponent, {
    NumericInput,
} from '@components/commun/formComponent/InputComponent';
import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import SelectComponent from '@components/commun/formComponent/SelectComponent';
import { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export interface CreateOrUpdateCustomUserFeeFormDto
    extends CreateCustomUserFeeDto {
    id?: number;
    userId?: number;
}

interface Props {
    renderButtons: ReactNode;
    defaultValues?: Partial<CreateOrUpdateCustomUserFeeFormDto>;
    onSubmit: (data: CreateOrUpdateCustomUserFeeFormDto) => Promise<void>;
}

const CustomUserFeeForm = ({
    onSubmit,
    defaultValues,
    renderButtons,
}: Props) => {
    const formState = useForm<CreateOrUpdateCustomUserFeeFormDto>({
        defaultValues: {
            type: CustomUserFeeType.ONCE,
            feeId: 2,
            typeInvestisseurId: 1,
            ...defaultValues,
        },
    });

    const [userName, setUserName] = useState<string>('');

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        control,
    } = formState;

    const userId = watch('userId');

    useEffect(() => {
        const actualValue = getValues();
        const newValues = {
            ...actualValue,
            ...defaultValues,
        };
        reset(newValues);
    }, [defaultValues, getValues, reset]);

    const onSubmitLogic = async (data: CreateOrUpdateCustomUserFeeFormDto) => {
        await onSubmit(data);
    };

    const { data: usersData } = useGetUsersSearchQuery(userName || '');

    const { data: userAllInfo } = useGetUserWithAllInfoQuery(userId || 0, {
        skip: !userId,
    });

    const { data: fees } = useGetAllFeesQuery();

    const { data: typeInvestisseurs } =
        useGetAllTypeInvestisseursForFormQuery();

    useEffect(() => {
        if (userId) {
            const investAccountId = watch('investAccountId');
            // if investAccountId is in userAllInfo.investAccounts keep it else set it to undefined
            const investAccount = userAllInfo?.investAccounts?.find(
                (ia) => ia.id === investAccountId
            );
            if (!investAccount) {
                const availableInvestAccount =
                    userAllInfo?.investAccounts?.[0]?.id;
                setValue(
                    'investAccountId',
                    availableInvestAccount || (undefined as any)
                );
            }
        }
    }, [userId, userAllInfo]);

    return (
        <form
            onSubmit={handleSubmit(onSubmitLogic)}
            className="formTemplateSingUp"
        >
            <SelectComponent
                register={register}
                value={'userId'}
                setSearch={setUserName}
                container={true}
                optionValues={(usersData || [])?.map((user) => ({
                    value: user.id,
                    label: `${user.firstName} ${user.lastName}`,
                }))}
                control={control}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
            >
                <LabelComponentForm>User</LabelComponentForm>
            </SelectComponent>

            <SelectComponent
                value={'investAccountId'}
                register={register}
                container={true}
                optionValues={
                    userAllInfo?.investAccounts?.map((val) => {
                        return {
                            value: val.id,
                            label:
                                val.physicalOrMoral === 'physical'
                                    ? `Compte physique (${userAllInfo?.firstName}  ${userAllInfo?.lastName})`
                                    : val.moralAccount?.companyName ||
                                      'Compte moral',
                        };
                    }) || []
                }
                watch={watch}
                control={control}
                getValues={getValues}
                setValue={setValue}
            >
                <LabelComponentForm>Invest account</LabelComponentForm>
            </SelectComponent>

            <SelectComponent
                value={'feeId'}
                register={register}
                container={true}
                optionValues={(fees || []).map((fee) => ({
                    value: fee.id,
                    label: fee.name,
                }))}
                watch={watch}
                control={control}
                getValues={getValues}
                setValue={setValue}
            >
                <LabelComponentForm>Fee</LabelComponentForm>
            </SelectComponent>

            <NumericInput formState={formState} name={'value'} min={0} max={10}>
                <LabelComponentForm>Value</LabelComponentForm>
            </NumericInput>

            <SelectComponent
                value={'typeInvestisseurId'}
                register={register}
                container={true}
                optionValues={(typeInvestisseurs || []).map((t) => ({
                    value: t.id,
                    label: t.name,
                }))}
                watch={watch}
                control={control}
                getValues={getValues}
                setValue={setValue}
            >
                <LabelComponentForm>Type investisseur</LabelComponentForm>
            </SelectComponent>

            <InputComponent
                register={register}
                value={'dateValidaty'}
                type="date"
                required={false}
            >
                <LabelComponentForm>Date validité (fin)</LabelComponentForm>
            </InputComponent>

            <SelectComponent
                value={'type'}
                register={register}
                container={true}
                optionValues={Object.values(CustomUserFeeType).map((v) => ({
                    value: v,
                    label: v,
                }))}
                watch={watch}
                control={control}
                getValues={getValues}
                setValue={setValue}
            >
                <LabelComponentForm>Type</LabelComponentForm>
            </SelectComponent>

            <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
                {renderButtons}
            </div>
        </form>
    );
};

export default CustomUserFeeForm;
