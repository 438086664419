export const statusCompany = [
    "SAS",
    "SARL",
    "SA",
    "SNC",
    "SCI",
    "SASU",
    "EURL",
    "SELARL",
    "SASU",
    "SC",
    "ME (Micro-Entreprise)",
    "Autre (à préciser)"
];