import React, { createContext, useContext, useReducer } from 'react';
import Notifications from '../commun/Notifications';

export interface INotification {
    level: Level;
    title?: string;
    message: string;
    progress?: string;
    date?: Date;
    id: string;
    computation?: boolean;
    fondsName?: string;
    errorMessage?: string;
}

type Action =
    | { type: 'ADD'; create: INotification }
    | { type: 'DELETE'; id: string }
    | { type: 'REMOVE_ALL' }
    | { type: 'UPDATE'; update: INotification };

const initialState: INotification[] = [];
export const NotificationContext = createContext<{
    state: INotification[];
    dispatch: React.Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

export const ADD = 'ADD';
export const DELETE = 'DELETE';
export const REMOVE_ALL = 'REMOVE_ALL';
export const UPDATE = 'UPDATE';

export enum Level {
    SUCCESS = 1,
    INFO = 2,
    WARNING = 4,
    ERROR = 5,
    LOADING = 6,
}

export const notificationReducer = (state: INotification[], action: Action) => {
    switch (action.type) {
        case ADD:
            if (action.create.id == undefined) {
                return state;
            }
            return [
                ...state,
                {
                    // id: ,
                    ...action.create,
                },
            ];
        case UPDATE:
            return state.map((t) =>
                t.id === action.update.id ? action.update : t
            );
        case DELETE:
            return state.filter((t) => t.id !== action.id);
        case REMOVE_ALL:
            return initialState;
        default:
            return state;
    }
};

export function NotificationProvider(props: { children: React.ReactNode }) {
    const [notifications, notificationDispatch] = useReducer(
        notificationReducer,
        initialState
    );

    return (
        <NotificationContext.Provider
            value={{ state: notifications, dispatch: notificationDispatch }}
        >
            {props.children}

            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="fixed z-[1003] inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
                <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                    {notifications.map((n) => (
                        <Notifications
                            key={n.level}
                            message={n.message}
                            title={n.title}
                            level={n.level}
                            id={n.id}
                            date={n?.date}
                            progress={n?.progress}
                            computation={n?.computation}
                            fondsName={n?.fondsName}
                            errorMessage={n?.errorMessage}
                        ></Notifications>
                    ))}
                </div>
            </div>
        </NotificationContext.Provider>
    );
}

export const useNotificationContext = () => {
    const { dispatch } = useContext(NotificationContext);

    // create random ID
    let counter = 0;

    const randomId = () => {
        const uniqueId = `${Date.now()}-${counter}`;
        counter += 1;
        return uniqueId;
    };

    const showSuccess = (title: string, message: string) => {
        dispatch({
            type: ADD,
            create: {
                id: randomId(),
                level: Level.SUCCESS,
                title: title,
                message: message,
            },
        });
    };

    const showError = (
        title: string,
        message: string,
        errorMessage?: string
    ) => {
        dispatch({
            type: ADD,
            create: {
                id: randomId(),
                level: Level.ERROR,
                title: title,
                message: message,
                errorMessage: errorMessage,
            },
        });
    };

    const showInfo = (title: string, message: string) => {
        dispatch({
            type: ADD,
            create: {
                id: randomId(),
                level: Level.INFO,
                title: title,
                message: message,
            },
        });
    };

    return { dispatch, showSuccess, showError, showInfo };
};
