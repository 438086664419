export enum tableBackOfficeInterface {
    project = "Projets",
    transactions = "Transactions",
    blackLists = "Liste noire",
    users = "Utilisateurs",
    overview = "Overview",
    platform = "Plateformes",
}

export enum typeFiltration {
    date = "date",
    amount = "amount",
    name = "name",
    firstName_Surname = "firstName_Surname",
    exact = "exact",
    nameWithIdTransaction = "nameWithIdTransaction",
    nameWithId = "nameWithId",
    boolean = "boolean",

}