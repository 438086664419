import React, { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { IFondsInfoUser } from "../../../../function/ComputationUserDashboard";
import { formatDate, printLargeValue } from "../../../../function/Utils";
import { IAggragateShareComninedInFunds } from "../../../../function/ComputationAdmin";

interface Props {
  fondsInfo: IAggragateShareComninedInFunds;
  index1: number;
  dateChosen: Date;
  offset: number;
}

const ElementTable: React.FC<Props> = ({
  fondsInfo,
  index1,
  dateChosen,
  offset,
}) => {
  const classNameObejct = {
    head: "text-sm text-gray-900 px-6 py-4 text-left font-semibold text-right",
    item: "text-sm font-light px-6 py-4 whitespace-nowrap text-right",
  };

  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <tr
        onClick={() => {
          setExpanded(!expanded);
        }}
        key={index1}
        className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
          index1 % 2 === 0 ? "bg-gray-100" : "bg-white"
        } `}
      >
        <td className="px-6 py-4 flex gap-3 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
          <ChevronRightIcon
            aria-hidden="true"
            className={`w-4 h-4  mx-auto transform transition ease-in-out duration-200 ${
              expanded ? "rotate-90" : "rotate-0"
            }`}
          ></ChevronRightIcon>{" "}
          {index1 + 1 + offset}
        </td>
        <td className={classNameObejct.item}>
          {formatDate(fondsInfo.infoCombined.firstDate)}
        </td>
        <td className={classNameObejct.item}>
          <span className="uppercase">{fondsInfo.lastName}</span>{" "}
          {fondsInfo.firstName}
        </td>
        <td className={classNameObejct.item}>
          {printLargeValue(fondsInfo.infoCombined.initialAmount)}
        </td>
        <td className={classNameObejct.item}>
          {printLargeValue(fondsInfo.infoCombined.nbShare.toFixed(2))} <br />
        </td>
        <td className={classNameObejct.item}>
          {fondsInfo.infoCombined.initialValueOfShare
            // for now we take one transaction but one user can have several transaction in a funds

            .toFixed(2)}
        </td>
        <td className={classNameObejct.item}>
          {printLargeValue(fondsInfo.infoCombined.share.toFixed(2))}
          <br />
        </td>
        <td className={classNameObejct.item}>
          {fondsInfo.infoCombined.performances.toFixed(2)} %
        </td>
        <td className={classNameObejct.item}>
          {fondsInfo.infoCombined.tri.toFixed(2)} %
        </td>

        <td className={classNameObejct.item}>
          {printLargeValue(
            (
              fondsInfo.infoCombined.nbShare * fondsInfo.infoCombined.share
            ).toFixed(2)
          )}
        </td>
        <td className={classNameObejct.item}>
          {fondsInfo.infoCombined.proportionBloqued.toFixed(3)} %
        </td>
        <td className={classNameObejct.item}>{formatDate(dateChosen!)}</td>
      </tr>
      {expanded ? (
        <>
          {fondsInfo.transactions.map((t, index) => (
            <tr
              key={index}
              className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 bg-gray-300 `}
            >
              {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
                {index + 1}
              </td> */}
              <td className={classNameObejct.item}>Transaction {index + 1}</td>

              <td className={classNameObejct.item}>
                {formatDate(t.dateStartInvestisseur)}
              </td>
              <td className={classNameObejct.item}>
                <span className="uppercase">{fondsInfo.lastName}</span>{" "}
                {fondsInfo.firstName}
              </td>
              <td className={classNameObejct.item}>
                {printLargeValue(t.initialAmount)}
              </td>
              <td className={classNameObejct.item}>
                {printLargeValue(t.nbShare.toFixed(2))} <br />
              </td>
              <td className={classNameObejct.item}>
                {t.initialValueOfShare.toFixed(2)}
              </td>
              <td className={classNameObejct.item}>
                {printLargeValue(t.share.toFixed(8))}
                <br />
              </td>
              <td className={classNameObejct.item}>
                {t.performance.toFixed(2)} %
              </td>
              <td className={classNameObejct.item}>{t.tri.toFixed(2)} %</td>
              <td className={classNameObejct.item}>
                {printLargeValue((t.share * t.nbShare).toFixed(2))}
              </td>
              <td className={classNameObejct.item}>
                {t.proportionBloqued.toFixed(3)} %
              </td>
              <td className={classNameObejct.item}>{formatDate(dateChosen)}</td>
            </tr>
          ))}
        </>
      ) : null}
    </>
  );
};

export default ElementTable;
