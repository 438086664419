import React from "react";
import { fondList } from "../../data/fondList";
import VueEnsFondItems from "./VueEnsFondItems";

const VueEnsPresentationFonds = () => {
  return (
    <div className="VueEnsPresentationFonds">
      {fondList
        .sort((a, b) => a.oderVueEns - b.oderVueEns)
        .map((fond, key) => (
          <React.Fragment key={key}>
            <VueEnsFondItems fond={fond} />
          </React.Fragment>
        ))}
    </div>
  );
};

export default VueEnsPresentationFonds;
