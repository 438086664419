import {
    CheckShareComputationApiArg,
    CheckShareComputationValueDto,
    useCheckShareComputationQuery,
    useGetAllFondsQuery,
} from '@api/api';
import useDebouncedEffect from '@components/commun/CustomHook/useDebouncedEffect';
import SelectComponent from '@components/commun/formComponent/SelectComponent';
import Loading from '@components/commun/Loading';
import { useDateRangePickerCustom } from '@components/commun/wskit/DateTime/DateTimeRangePicker';
import { addDays } from 'date-fns';
import { useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useForm } from 'react-hook-form';

const CheckShareComputation = () => {
    const formState = useForm<CheckShareComputationApiArg>({
        defaultValues: {
            fondsId: 1,
        },
    });

    const { register, watch, setValue, control } = formState;

    const date = addDays(new Date(), -1);

    const { valueRange, DateRangePickerCustom } = useDateRangePickerCustom({
        formState,
        formEndDateName: 'dateEnd',
        formStartDateName: 'dateStart',
        defaultValueCustom: {
            custom: {
                start: addDays(date, -7),
                end: date,
            },
        },
    });

    // Debounced state
    const [debouncedValues, setDebouncedValues] = useState({
        dateStart: watch('dateStart'),
        dateEnd: watch('dateEnd'),
        fondsId: watch('fondsId'),
    });

    useDebouncedEffect(
        () => {
            setDebouncedValues({
                dateStart: watch('dateStart'),
                dateEnd: watch('dateEnd'),
                fondsId: watch('fondsId'),
            });
        },
        [watch('dateStart'), watch('dateEnd'), watch('fondsId')],
        500
    );

    const { data: fonds } = useGetAllFondsQuery();

    const { data: checkShareData, isLoading } = useCheckShareComputationQuery(
        {
            dateStart: debouncedValues.dateStart
                ? new Date(debouncedValues.dateStart)?.toISOString()
                : new Date().toISOString(),
            dateEnd: debouncedValues.dateEnd
                ? new Date(debouncedValues.dateEnd)?.toISOString()
                : new Date().toISOString(),
            fondsId: debouncedValues.fondsId,
        },
        {
            skip:
                !debouncedValues.dateStart ||
                !debouncedValues.dateEnd ||
                !debouncedValues.fondsId,
        }
    );

    return (
        <div className="grid pb-10 lg:grid-cols-2 gap-x-4 px-2">
            <div className="formTemplateSingUp w-full md:w-10/12 lg:w-2/3   lg:col-span-2">
                <div className="w-full my-4 gap-x-4 grid md:grid-cols-3">
                    {/* <InputComponent register={register} value="dateStart" type="date">
            <label className=" font-semibold !text-lg">Date Start</label>
          </InputComponent>
          <InputComponent register={register} value="dateEnd" type="date">
            <label className=" font-semibold !text-lg">Date End</label>
          </InputComponent> */}
                    <div className="w-full flex justify-center items-center col-span-2">
                        <DateRangePickerCustom label="Date Range" />
                    </div>

                    <SelectComponent
                        register={register}
                        setValue={setValue}
                        control={control}
                        watch={watch}
                        container={true}
                        value="fondsId"
                        optionValues={
                            fonds?.map((fond) => ({
                                value: fond.id,
                                label: fond.name,
                            })) || []
                        }
                    >
                        <label className=" font-semibold !text-lg">Fonds</label>
                    </SelectComponent>
                </div>
            </div>
            {isLoading ? (
                <div className=" col-span-2">
                    <Loading />
                </div>
            ) : (
                <>
                    {checkShareData?.map((data) => (
                        <GraphComponent data={data} />
                    ))}
                </>
            )}
        </div>
    );
};

export default CheckShareComputation;

function GraphComponent({ data }: { data: CheckShareComputationValueDto }) {
    const graphData = useMemo(() => {
        const labels = data.values.map((value) => value.date);
        // if abs(value) below 1 show negative power
        const values = data.values.map((value) =>
            Math.abs(value.amount) < 1 ? value.amount : value.amount.toFixed(2)
        );

        return {
            labels,
            datasets: [
                {
                    label: data.name,
                    data: values,
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: 'rgba(255, 99, 132, 0.2)',
                },
            ],
        };
    }, [data]);

    return (
        <div key={data.name} className="">
            <h2 className=" bg-bgDashboardClient mr-1 pl-4 p-2 uppercase rounded-md mt-2 font-mainFontFamily text-2xl text-mainColor">
                {data?.name}
            </h2>

            <Line data={graphData} />
        </div>
    );
}
