import axios from "axios";
import React, { useCallback, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { UidContext } from "../components/Context/AppContext";
import {
  Level,
  useNotificationContext,
} from "../components/Context/notification-context";
import { debounce } from "../function/Utils";
import Home from "./Home";

const ValidationEmail = () => {
  const { token } = useParams();
  const { showError, showSuccess, showInfo } = useNotificationContext();
  const userId = useContext(UidContext);

  const navigate = useNavigate();

  const validationEmail = useCallback(
    debounce(async () => {
      await axios({
        method: "get",
        url: `${import.meta.env.VITE_API_URL}email/confirmation/${token}`,
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.message === "Email already valided") {
            showInfo("Validation", "Votre email a déjà été validé");
          } else {
            showSuccess("Validation", "Votre email a bien été validé");
          }
          console.log(userId?.uid);
          if (!userId?.uid) {
            navigate("/SeConnecter");
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          showError("Erreur", "Votre email n'a pas pu être validé");
        });
    }),
    []
  );

  useEffect(() => {
    validationEmail();
  }, []);
  return <Home />;
};

export default ValidationEmail;
