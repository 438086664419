import { ReactNode } from 'react';
import {
    Control,
    FieldValues,
    Path,
    UseFormRegister,
    UseFormReturn,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';

import { KnowledgeQuestionDto, TypeQuestion } from '@api/api';
import InputComponent, {
    NumericInput,
} from '../../../../commun/formComponent/InputComponent';
import SelectComponent from '../../../../commun/formComponent/SelectComponent';
import SelectMultiComponent from '../../../../commun/formComponent/SelectMultiComponent';
import CheckboxArrayComponent from '../FormComponentForArray/CheckBoxArrayComponent';
import RadioArrayComponent from '../FormComponentForArray/RadioArrayComponent';

export interface PropsDisplayQuestion<T extends FieldValues> {
    register: UseFormRegister<T>;
    watch: UseFormWatch<T>;
    setValue: UseFormSetValue<T>;
    question: KnowledgeQuestionDto;
    control: Control<T, Path<T>>;
    index: number;
    showGoodAnswer?: boolean;
    id?: string;
    formState?: UseFormReturn<T, any>;
}
export const DisplayQuestion = <T extends object>(
    props: PropsDisplayQuestion<T> & { children?: ReactNode }
) => {
    const displayFunction = (question: KnowledgeQuestionDto) => {
        // console.log(question.type);
        switch (question.type) {
            case TypeQuestion.radio:
                return (
                    <RadioArrayComponent
                        control={props.control}
                        register={props.register}
                        watch={props.watch}
                        id={props.id}
                        showGoodAnswer={props.showGoodAnswer}
                        name={`value.${props.index}.answerIds` as any}
                        values={question.answers.map((a) => {
                            return {
                                label: a?.answerJson?.answer ?? '',
                                value: parseInt(a.id as unknown as string),
                                title: a?.title ?? undefined,
                                goodAnswer: a?.goodAnswer,
                            };
                        })}
                    >
                        {props.children}
                    </RadioArrayComponent>
                );
            case TypeQuestion.checkbox:
                return (
                    <CheckboxArrayComponent
                        control={props.control}
                        register={props.register}
                        watch={props.watch}
                        name={`value.${props.index}.answerIds` as any}
                        values={[...question.answers]
                            ?.sort(
                                (a, b) =>
                                    (a.answerJson?.order || 0) -
                                    (b.answerJson?.order || 0)
                            )
                            .map((a) => {
                                return {
                                    label: a?.answerJson?.answer ?? '',
                                    value: a.id,
                                    title: a.answerJson?.title,
                                    subTitle: a.answerJson?.subTitle,
                                };
                            })}
                    >
                        {props.children}
                    </CheckboxArrayComponent>
                );
            case TypeQuestion.select:
                return (
                    <SelectComponent
                        control={props.control}
                        register={props.register}
                        watch={props.watch}
                        value={`value.${props.index}.answerIds` as any}
                        optionValues={question.answers.map((a) => {
                            return {
                                label: a?.answerJson?.answer ?? '',
                                value: a.id,
                            };
                        })}
                    >
                        {props.children}
                    </SelectComponent>
                );
            case TypeQuestion.selectMulti:
                return (
                    <SelectMultiComponent
                        control={props.control}
                        setValue={props.setValue}
                        register={props.register}
                        watch={props.watch}
                        optionsRender={(option) => option.label}
                        valueRender={(value) => value.value}
                        value={`value.${props.index}.answerIds` as any}
                        optionValues={question.answers.map((a) => {
                            return {
                                label: a?.answerJson?.answer ?? '',
                                value: a.id,
                            };
                        })}
                    >
                        {props.children}
                    </SelectMultiComponent>
                );
            case TypeQuestion.text:
                return (
                    <InputComponent
                        register={props.register}
                        watch={props.watch}
                        type={'text'}
                        value={`value.${props.index}.answer` as any}
                    >
                        {props.children}
                    </InputComponent>
                );
            case TypeQuestion.number:
                return (
                    <NumericInput
                        formState={props.formState!}
                        name={`value.${props.index}.answer` as any}
                        max={question.max}
                        min={question.min}
                    >
                        {props.children}
                    </NumericInput>
                );
            default:
                return <></>;
        }
    };

    return <>{displayFunction(props.question)}</>;
};
