import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';

const baseQuery = baseQueryHandleError(
    fetchBaseQuery({
        baseUrl: `${import.meta.env.VITE_API_URL}`,
        credentials: 'include', // Include cookies for authentication
        prepareHeaders: (headers) => {
            // Add your custom headers here, e.g., authentication tokens
            return headers;
        },
    })
);

export const emptyApi = createApi({
    baseQuery,
    endpoints: () => ({}),
});

function baseQueryHandleError(
    baseQuery: BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
    >
) {
    return async (arg: string | FetchArgs, api: any, extraOptions: any) => {
        const result = await baseQuery(arg, api, extraOptions);
        if ((result?.error?.data as any)?.error === 'TokenExpiredError') {
            const currentLocation = window.location;
            const currentPath =
                currentLocation.pathname +
                currentLocation.search +
                (currentLocation.search === ''
                    ? '?sessionExpired=1'
                    : '&sessionExpired=1');

            window.location.href = currentPath;
        }
        return result;
    };
}
