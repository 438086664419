import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
    onClick: () => void;
    className?: string;
    children: React.ReactNode;
}

const AddDashComponent: React.FC<Props> = ({
    onClick,
    className,
    children,
}) => {
    return (
        <div
            onClick={onClick}
            className={` w-full min-h-[120px] relative flex gap-2 border-gray-300 hover:bg-bgDashboardClient cursor-pointer justify-center items-center border-2 border-dashed  rounded-lg py-2 px-4
        ${className ? className : ''}`}
        >
            <FontAwesomeIcon icon={faPlus} className="text-xl" />
            {children}
        </div>
    );
};

export default AddDashComponent;
