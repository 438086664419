import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PopUp from "../PopUp";
import {
  Level,
  useNotificationContext,
} from "../../Context/notification-context";
import { PrimaryButton, WhiteButton } from "../Buttons";

interface Props<T> {
  deleteItem: any;
  message: string;
  defaultValues?: any;
  isLoading: boolean;
  toDoIfSuccess?: () => void;
  unwrapBoolean?: boolean;
}

const useDeleteItem = <T extends object>({
  deleteItem,
  message,
  isLoading,
  toDoIfSuccess,
  defaultValues,
  unwrapBoolean = true,
}: Props<T>) => {
  const [open, setOpen] = useState<boolean>(false);

  const { register, handleSubmit, reset, watch } = useForm<T>({
    defaultValues: {
      ...defaultValues,
    },
  });

  const { showError, showSuccess } = useNotificationContext();

  const handleDelete = async () => {
    if (unwrapBoolean) {
      await deleteItem()
        .unwrap()
        .then(() => {
          setOpen(false);
          showSuccess("Supprimé", `${message} supprimé avec succès`);
          toDoIfSuccess && toDoIfSuccess();
        })
        .catch(() => {
          showError("Erreur", `Erreur lors de la suppression ${message}`);
        });
    } else {
      await deleteItem()
        .then(() => {
          setOpen(false);
          showSuccess("Supprimé", `${message} supprimé avec succès`);
          toDoIfSuccess && toDoIfSuccess();
        })
        .catch(() => {
          showError("Error", `Erreur lors de la suppression ${message}`);
        });
    }
  };

  const DeleteComponent = ({
    children,
    title,
  }: {
    children?: React.ReactNode;
    title: string;
  }) => (
    <PopUp
      open={open}
      setOpen={setOpen}
      buttonBoolean={false}
      title={() => {
        return (
          <div className="relative">
            <h3 className="text-lg font-mainFontFamily font-medium leading-6 text-gray-900">
              Supprimer {title}
            </h3>
          </div>
        );
      }}
    >
      <div className="formTemplateSingUp">
        {children}

        <div className="flex w-full mt-2 items-center justify-center gap-3">
          <WhiteButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Annuler
          </WhiteButton>
          <PrimaryButton
            loading={isLoading}
            onClick={() => {
              handleDelete();
            }}
          >
            Supprimer
          </PrimaryButton>
        </div>
      </div>
    </PopUp>
  );

  return {
    DeleteComponent,
    setOpen,
    open,
    register,
    watch,
  };
};

export default useDeleteItem;
