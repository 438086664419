import { useCallback, useEffect } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';

import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { useForm, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useNavigate } from 'react-router';

import {
    CreateOrUpdateDocumentValidationDto,
    CreateOrUpdateValidationInvestAccountDto,
    DocumentBeneficialOwnerDto,
    DocumentMoralAccountType,
    StateStatus,
    useCreateOrUpdateValidationsInvestAccountMutation,
    useGetAllLatestDocumentsInvestAccountByUserIdQuery,
    useGetInvestAccountByIdQuery,
    useGetUserWithAllInfoQuery,
} from '@api/api';
import { useNotificationContext } from '../../Context/notification-context';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import DisplayDocumentBeneficialOwner from '../../commun/DisplayDocuments/DisplayDocumentBeneficialOwner';
import DisplayDocumentInvestAccount from '../../commun/DisplayDocuments/DisplayDocumentInvestAccount';
import DisplayDocumentMoralAccount from '../../commun/DisplayDocuments/DisplayDocumentMoralAccount';
import Loading from '../../commun/Loading';
import FieldArray from '../../commun/formComponent/FieldArray';
import RadioComponent from '../../commun/formComponent/RadioComponent';
import TextAreaComponent from '../../commun/formComponent/TextAreaComponent';

const ValidationInvestAccount = ({ userId }: { userId: number }) => {
    const [investAccountIdQuery, setInvestAccountQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );

    const {
        data: investAccount,
        isLoading: isLoadingInvestAccount,
        refetch: refetchInvestAccount,
    } = useGetInvestAccountByIdQuery(
        {
            userId: userId,
            investAccountId: investAccountIdQuery || 0,
        },
        {
            skip: userId === undefined || investAccountIdQuery === undefined,
        }
    );

    const {
        data: documents,
        isLoading: isLoadingDocuments,
        refetch,
    } = useGetAllLatestDocumentsInvestAccountByUserIdQuery(
        {
            userId: userId,
            investAccountId: investAccountIdQuery || 0,
        },
        {
            skip: userId === undefined || investAccountIdQuery === undefined,
        }
    );

    const {
        register,
        watch,
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { isValid },
    } = useForm<CreateOrUpdateValidationInvestAccountDto>({
        defaultValues: {
            moralAccount: [],
            beneficialOwner: [],
        },
    });

    const { refetch: refetchUserAllInfo } = useGetUserWithAllInfoQuery(userId);

    const navigate = useNavigate();

    const [createOrUpdateValidation, { isLoading: isLoadingEdit }] =
        useCreateOrUpdateValidationsInvestAccountMutation();

    const { showError, showSuccess } = useNotificationContext();
    const onSubmit = async (data: CreateOrUpdateValidationInvestAccountDto) => {
        const request: CreateOrUpdateValidationInvestAccountDto = {
            status: data.status,
            investAccountId: investAccountIdQuery!,
            investAccount: {
                ...data.investAccount,
                performValidationUserId: userId,
            },
            moralAccount:
                data.moralAccount?.map((m) => ({
                    ...m,
                    performValidationUserId: userId,
                })) || [],
            beneficialOwner:
                data.beneficialOwner?.map((b) => ({
                    ...b,
                    performValidationUserId: userId,
                })) || [],
        };

        await createOrUpdateValidation(request)
            .unwrap()
            .then((res) => {
                showSuccess('Succès', 'Validation effectuée avec succès');
                // invalide invest account tag rtk query

                refetchUserAllInfo();
                refetchInvestAccount();
                navigate(-1);
            })
            .catch((err) => {
                showError(
                    'Erreur',
                    'Une erreur est survenue lors de la validation'
                );
            });
    };

    const documentKbisDb = documents?.moralAccount?.find(
        (m) => m.type === DocumentMoralAccountType.KBIS
    );
    const documentStatusDb = documents?.moralAccount?.find(
        (m) => m.type === DocumentMoralAccountType.STATUS
    );
    const documentDeclarationBeneficial_OwnerDb = documents?.moralAccount?.find(
        (m) => m.type === DocumentMoralAccountType.DECLARATION_BENEFICIAL_OWNER
    );

    const setDefautlValues = useCallback(() => {
        let moralAccount: CreateOrUpdateDocumentValidationDto[] = [];
        let beneficial: CreateOrUpdateDocumentValidationDto[] = [];
        if (
            investAccount &&
            documentKbisDb &&
            documentStatusDb &&
            documentDeclarationBeneficial_OwnerDb &&
            investAccount.physicalOrMoral === 'moral'
        ) {
            if (documentKbisDb.validation !== undefined) {
                moralAccount = [
                    {
                        documentId: documentKbisDb.validation.documentId,
                        status: documentKbisDb.validation.status,
                        comment: documentKbisDb.validation.comment,

                        performValidationUserId: 0,
                    },
                ];
            } else {
                moralAccount = [
                    {
                        documentId: documentKbisDb?.id!,
                        status: StateStatus.validated,
                        comment: '',

                        performValidationUserId: userId,
                    },
                ];
            }

            if (documentStatusDb.validation !== undefined) {
                moralAccount = [
                    ...moralAccount,
                    {
                        documentId: documentStatusDb?.id,
                        status: documentStatusDb.validation.status,
                        comment: documentStatusDb.validation.comment,

                        performValidationUserId: 0,
                    },
                ];
            } else {
                moralAccount = [
                    ...moralAccount,
                    {
                        documentId: documentStatusDb?.id!,
                        status: StateStatus.validated,
                        comment: '',

                        performValidationUserId: userId,
                    },
                ];
            }

            if (
                documentDeclarationBeneficial_OwnerDb.validation !== undefined
            ) {
                moralAccount = [
                    ...moralAccount,
                    {
                        documentId: documentDeclarationBeneficial_OwnerDb?.id!,
                        status: documentDeclarationBeneficial_OwnerDb.validation
                            .status,
                        comment:
                            documentDeclarationBeneficial_OwnerDb.validation
                                .comment,

                        performValidationUserId: 0,
                    },
                ];
            } else {
                moralAccount = [
                    ...moralAccount,
                    {
                        documentId: documentDeclarationBeneficial_OwnerDb?.id!,
                        status: StateStatus.validated,
                        comment: '',

                        performValidationUserId: userId,
                    },
                ];
            }
        }

        if (investAccount && investAccount.physicalOrMoral === 'moral') {
            beneficial =
                documents?.beneficialOwner?.map((b) => {
                    if (b.validation !== undefined) {
                        return {
                            documentId: b.id!,
                            status: b.validation.status,
                            comment: b.validation.comment,
                        } as any;
                    } else {
                        return {
                            documentId: b.id!,
                            status: StateStatus.validated,
                            comment: '',

                            performValidationUserId: userId,
                        } as any;
                    }
                }) || [];
        }

        reset({
            status: investAccount?.status,
            investAccountId: investAccount?.id!,
            investAccount:
                documents?.investAccount?.validation !== undefined
                    ? {
                          comment:
                              documents?.investAccount.validation?.comment ||
                              '',
                          status:
                              documents?.investAccount.validation?.status ||
                              StateStatus.validated,

                          documentId: documents?.investAccount.id!,
                          id: documents?.investAccount.validation?.id,
                      }
                    : {
                          comment: '',
                          status: StateStatus.validated,

                          performValidationUserId: userId,
                          documentId: documents?.investAccount?.id || 0,
                      },
            moralAccount: moralAccount,
            beneficialOwner: beneficial,
        });
    }, [
        investAccount,
        documents,
        documentKbisDb,
        documentStatusDb,
        documentDeclarationBeneficial_OwnerDb,
    ]);

    useEffect(() => {
        setDefautlValues();
    }, [
        investAccount,
        documentKbisDb,
        documentStatusDb,
        documents,
        documentDeclarationBeneficial_OwnerDb,
    ]);

    const classNameDiv =
        'grid grid-cols-1 space-y-2 md:space-y-0 md:grid-cols-2 border-b-2 py-2';
    const classNameH3 =
        'text-md font-mainFontFamily font-semibold text-center md:text-start';
    const classNameP = 'flex justify-center items-center';

    const moralAccount = investAccount?.moralAccount;
    return (
        <div>
            {isLoadingInvestAccount || isLoadingDocuments ? (
                <Loading />
            ) : (
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formTemplateSingUp gap-1"
                >
                    {!investAccount?.iban ? (
                        <h3 className=" text-center mx-auto text-orange-400 font-mainFontFamily text-lg">
                            Ce compte n'a pas encore complétement complété on ne
                            peut pas le vérifier
                        </h3>
                    ) : null}
                    <div className="w-full">
                        <TitleComponentForm>IBAN</TitleComponentForm>
                        <div className="mx-auto md:w-11/12">
                            <p className="my-2">
                                <span className=" font-semibold">IBAN:</span>{' '}
                                {investAccount?.iban}
                            </p>
                            {documents?.investAccount ? (
                                <DisplayDocumentInvestAccount
                                    document={documents?.investAccount}
                                    userId={userId}
                                    refetch={refetch}
                                />
                            ) : null}
                            <TextAreaComponent
                                register={register}
                                value={'investAccount.comment'}
                                required={false}
                            >
                                <LabelComponentForm>
                                    Commentaire
                                </LabelComponentForm>
                            </TextAreaComponent>

                            <div className="grid grid-cols-3 items-center mb-4 gap-x-2">
                                <RadioComponent
                                    name={'investAccount.status'}
                                    watch={watch}
                                    className={{
                                        div: '',
                                        input: '!w-[15px]',
                                        label: 'text-sm',
                                    }}
                                    register={register}
                                    values={[
                                        {
                                            label: 'Valider',
                                            value: StateStatus.validated,
                                        },
                                        {
                                            label: 'Refuser',
                                            value: StateStatus.unValidated,
                                        },
                                    ]}
                                >
                                    <LabelComponentForm>
                                        Validation du rib{' '}
                                        <span className="text-red-500">*</span>
                                    </LabelComponentForm>
                                </RadioComponent>
                            </div>
                        </div>

                        {investAccount?.physicalOrMoral === 'moral' &&
                        moralAccount !== undefined ? (
                            <>
                                <TitleComponentForm>
                                    Document moraux
                                </TitleComponentForm>
                                <div className="mx-auto md:w-11/12">
                                    <div className={`${classNameDiv}`}>
                                        <h3 className={`${classNameH3}`}>
                                            Adresse
                                        </h3>
                                        <p className={`${classNameP}`}>
                                            {moralAccount.address}
                                        </p>
                                    </div>
                                    {moralAccount.addressComplement ? (
                                        <div className={`${classNameDiv}`}>
                                            <h3 className={`${classNameH3}`}>
                                                Complément d'adresse
                                            </h3>
                                            <p className={`${classNameP}`}>
                                                {moralAccount.addressComplement}
                                            </p>
                                        </div>
                                    ) : null}
                                    <div className={`${classNameDiv}`}>
                                        <h3 className={`${classNameH3}`}>
                                            N° d'immatriculation
                                        </h3>
                                        <p
                                            className={`${classNameP} text-left`}
                                        >
                                            {moralAccount.registrationNumber}
                                        </p>
                                    </div>
                                    <div className={`${classNameDiv}`}>
                                        <h3 className={`${classNameH3}`}>
                                            Status juridique
                                        </h3>
                                        <p
                                            className={`${classNameP} text-left`}
                                        >
                                            {moralAccount.legalStatus}
                                        </p>
                                    </div>
                                    <div className={`${classNameDiv} mb-5`}>
                                        <h3 className={`${classNameH3}`}>
                                            Catégorisation d'ivestisseur
                                        </h3>
                                        <p
                                            className={`${classNameP} text-left`}
                                        >
                                            {
                                                moralAccount.investorCategorization
                                            }
                                        </p>
                                    </div>

                                    {documentKbisDb ? (
                                        <DisplayDocumentMoralAccount
                                            document={documentKbisDb}
                                            userId={userId}
                                            refetch={refetch}
                                        />
                                    ) : null}
                                    <TextAreaComponent
                                        register={register}
                                        value={`moralAccount.${0}.comment`}
                                        required={false}
                                    >
                                        <LabelComponentForm>
                                            Commentaire
                                        </LabelComponentForm>
                                    </TextAreaComponent>
                                    <div className="grid grid-cols-3 items-center mb-4 gap-x-2">
                                        <RadioComponent
                                            name={`moralAccount.${0}.status`}
                                            watch={watch}
                                            className={{
                                                div: '',
                                                input: '!w-[15px]',
                                                label: 'text-sm',
                                            }}
                                            register={register}
                                            values={[
                                                {
                                                    label: 'Valider',
                                                    value: StateStatus.validated,
                                                },
                                                {
                                                    label: 'Refuser',
                                                    value: StateStatus.unValidated,
                                                },
                                            ]}
                                        >
                                            <LabelComponentForm>
                                                Validation du Kbis{' '}
                                                <span className="text-red-500">
                                                    *
                                                </span>
                                            </LabelComponentForm>
                                        </RadioComponent>
                                    </div>
                                    {documentStatusDb ? (
                                        <DisplayDocumentMoralAccount
                                            document={documentStatusDb}
                                            userId={userId}
                                            refetch={refetch}
                                        />
                                    ) : null}
                                    <TextAreaComponent
                                        register={register}
                                        value={`moralAccount.${1}.comment`}
                                        required={false}
                                    >
                                        <LabelComponentForm>
                                            Commentaire
                                        </LabelComponentForm>
                                    </TextAreaComponent>
                                    <div className="grid grid-cols-3 items-center mb-4 gap-x-2">
                                        <RadioComponent
                                            name={`moralAccount.${1}.status`}
                                            watch={watch}
                                            className={{
                                                div: '',
                                                input: '!w-[15px]',
                                                label: 'text-sm',
                                            }}
                                            register={register}
                                            values={[
                                                {
                                                    label: 'Valider',
                                                    value: StateStatus.validated,
                                                },
                                                {
                                                    label: 'Refuser',
                                                    value: StateStatus.unValidated,
                                                },
                                            ]}
                                        >
                                            <LabelComponentForm>
                                                Validation des statuts{' '}
                                                <span className="text-red-500">
                                                    *
                                                </span>
                                            </LabelComponentForm>
                                        </RadioComponent>
                                    </div>
                                    {documentDeclarationBeneficial_OwnerDb ? (
                                        <DisplayDocumentMoralAccount
                                            document={
                                                documentDeclarationBeneficial_OwnerDb
                                            }
                                            userId={userId}
                                            refetch={refetch}
                                        />
                                    ) : null}

                                    <TextAreaComponent
                                        register={register}
                                        value={`moralAccount.${2}.comment`}
                                        required={false}
                                    >
                                        <LabelComponentForm>
                                            Commentaire
                                        </LabelComponentForm>
                                    </TextAreaComponent>
                                    <div className="grid grid-cols-3 items-center mb-4 gap-x-2">
                                        <RadioComponent
                                            name={`moralAccount.${2}.status`}
                                            watch={watch}
                                            className={{
                                                div: '',
                                                input: '!w-[15px]',
                                                label: 'text-sm',
                                            }}
                                            register={register}
                                            values={[
                                                {
                                                    label: 'Valider',
                                                    value: StateStatus.validated,
                                                },
                                                {
                                                    label: 'Refuser',
                                                    value: StateStatus.unValidated,
                                                },
                                            ]}
                                        >
                                            <LabelComponentForm>
                                                Validation de la déclaration des
                                                bénéficiaires effectifs{' '}
                                                <span className="text-red-500">
                                                    *
                                                </span>
                                            </LabelComponentForm>
                                        </RadioComponent>
                                    </div>
                                </div>
                                <TitleComponentForm>
                                    Documents bénéficiaire
                                </TitleComponentForm>
                                <div className="mx-auto md:w-11/12">
                                    <FieldArray
                                        name={`beneficialOwner`}
                                        control={control}
                                    >
                                        {({ fields, append, remove }) => (
                                            <>
                                                <div className="flex flex-col w-full space-y-2 mb-2">
                                                    {fields.map(
                                                        (field, index) => (
                                                            <div
                                                                key={field.id}
                                                                className="grid grid-cols-2 w-full gap-x-2 relative"
                                                            >
                                                                <ValidateBeneficial
                                                                    userId={
                                                                        userId
                                                                    }
                                                                    watch={
                                                                        watch
                                                                    }
                                                                    register={
                                                                        register
                                                                    }
                                                                    documents={
                                                                        documents?.beneficialOwner ||
                                                                        []
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className="mx-auto md:w-11/12 flex justify-center flex-col items-center">
                        <RadioComponent
                            name={`status`}
                            watch={watch}
                            className={{
                                div: ' !mx-auto !w-fit',
                                input: '!w-[15px]',
                                label: 'text-sm',
                            }}
                            register={register}
                            values={[
                                {
                                    label: 'Valider',
                                    value: StateStatus.validated,
                                },
                                {
                                    label: 'Refuser',
                                    value: StateStatus.unValidated,
                                },
                            ]}
                        >
                            <LabelComponentForm>
                                Validation du compte investisseur{' '}
                                <span className="text-red-500">*</span>
                            </LabelComponentForm>
                        </RadioComponent>
                    </div>

                    <div className="w-full flex gap-3 justify-center items-center">
                        <WhiteButton onClick={() => navigate(-1)}>
                            Annuler
                        </WhiteButton>
                        <PrimaryButton
                            disabled={!isValid || !investAccount?.iban}
                            type="submit"
                            loading={isLoadingEdit}
                        >
                            Valider
                        </PrimaryButton>
                    </div>
                </form>
            )}
        </div>
    );
};

export default ValidationInvestAccount;

export function ValidateBeneficial({
    register,
    documents,
    index,
    watch,
    userId,
}: {
    register: UseFormRegister<CreateOrUpdateValidationInvestAccountDto>;
    documents: DocumentBeneficialOwnerDto[];
    index: number;
    watch: UseFormWatch<CreateOrUpdateValidationInvestAccountDto>;
    userId: number;
}) {
    const document = documents?.find(
        (doc) => doc.id === watch(`beneficialOwner.${index}.documentId`)
    )!;

    return (
        <>
            <h3 className="text-start font-semibold font-mainFontFamily">
                Bénéficiaire {index + 1} ({document?.firstName}{' '}
                {document?.lastName})
            </h3>
            {document ? (
                <div className=" col-span-2 mb-5">
                    <DisplayDocumentBeneficialOwner
                        document={document}
                        userId={userId}
                    />
                </div>
            ) : null}

            <TextAreaComponent
                register={register}
                value={`beneficialOwner.${index}.comment`}
                required={false}
            >
                <LabelComponentForm>Commentaire</LabelComponentForm>
            </TextAreaComponent>

            <div className="mb-4">
                <RadioComponent
                    name={`beneficialOwner.${index}.status`}
                    watch={watch}
                    className={{
                        div: ' !mx-auto !w-fit',
                        input: '!w-[15px]',
                        label: 'text-sm',
                    }}
                    register={register}
                    values={[
                        {
                            label: 'Valider',
                            value: StateStatus.validated,
                        },
                        {
                            label: 'Refuser',
                            value: StateStatus.unValidated,
                        },
                    ]}
                >
                    <LabelComponentForm className="!text-center">
                        Validation de la pièce d'identité{' '}
                        <span className="text-red-500">*</span>
                    </LabelComponentForm>
                </RadioComponent>
            </div>
        </>
    );
}
