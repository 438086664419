import React, { useContext, useEffect, useState } from 'react';
import { setDate } from '../../../../function/Utils';

import { useGetOverviewFondsInfoOverviewAdminQuery } from '@api/api';
import Loading from '../../../commun/Loading';
import {
    BackOfficeState,
    RootBlackOfficeState,
} from '../../../Context/AppContext';
import AmontInFunds from './AmontInFunds';
import Benefices from './Benefices';
import ChartFunds from './ChartFunds';
import DataBase from './DataBase';
import Liquidity from './Liquidity';
import TableSummaryFunds from './TableSummaryFunds';
import Tri from './Tri';

const OverviewBackOffice = () => {
    const rootState = useContext(RootBlackOfficeState);

    const [computation, setComputation] = useState<boolean>(false);

    const backOfficeState = useContext(BackOfficeState);

    const [dateChosen, setDateChosen] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if (
            backOfficeState?.day &&
            backOfficeState?.year &&
            backOfficeState?.month !== undefined
        ) {
            setDateChosen(
                setDate(
                    backOfficeState?.year!,
                    backOfficeState?.month!,
                    backOfficeState?.day!
                )
            );
        }
    }, [
        backOfficeState?.day && backOfficeState?.day,
        backOfficeState?.year && backOfficeState?.year,
        backOfficeState?.month && backOfficeState?.month,
    ]);

    const {
        data: ovierViewAdminInfo,
        isLoading,
        isFetching,
        isError,
    } = useGetOverviewFondsInfoOverviewAdminQuery(
        (dateChosen ?? new Date())?.toISOString(),
        {
            skip: !dateChosen || rootState?.applyFeesBool === undefined,
        }
    );

    // const [test, setTest] = useState<any>();

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap',
    };

    const [error, setError] = useState(false);

    useEffect(() => {
        if (isError) {
            setError(true);
        }
    }, [isError]);

    return (
        <React.Fragment>
            {(isLoading || isFetching) && !error ? (
                <Loading />
            ) : (
                <div className="flex flex-col">
                    {/* <div className="mb-4">
            <h3
              className="mb-2 ml-4 cursor-pointer"
              onClick={() => setCronToggle(!cronToggle)}
            >
              Automatique jobs{" "}
              <FontAwesomeIcon
                icon={faChevronRight}
                className={`${!cronToggle ? "" : "rotate-90"} transition-all`}
              />
            </h3> */}
                    {/* {cronJob.length > 0 && cronToggle ? (
              <TableComponent
                head={["#", "Nom", "last run", "next run"]}
                array={cronJob}
              >
                <tbody>
                  {!!(backOfficeState?.fondsId !== undefined) && (
                    <React.Fragment>
                      {cronJob.map((item, key) => (
                        <tr
                          key={key}
                          className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                            key % 2 === 0 ? "bg-gray-100" : "bg-white"
                          } `}
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {key + 1}
                          </td>
                          <td className={classNameObejct.item}>{item?.name}</td>
                          <td className={`${classNameObejct.item}`}>
                            {item?.lastTime ? item.lastTime : ""}
                          </td>
                          <td className={`${classNameObejct.item}`}>
                            {item?.nextTime}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  )}
                </tbody>
              </TableComponent>
            ) : null} */}
                    {/* </div> */}
                    <div className="flex">
                        <DataBase
                            computation={computation}
                            setComputation={setComputation}
                        />
                    </div>
                    <div className="grid gap-5 grid-cols-1 grid-rows-2 lg:grid-cols-[2fr,1fr] lg:grid-rows-1 my-5 w-full rounded-xl lg:shadow-low py-5 lg:gap-0">
                        <div className="flex gap-5 items-center max-w-1/2 flex-wrap lg:pl-3 text-sm">
                            <Benefices />
                        </div>
                        <div className="w-1/3 mx-auto max-w-xs min-w-[300px]">
                            <ChartFunds />
                        </div>
                    </div>

                    <div className="overflow-auto rounded-lg shalow-low p-5 overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full flex flex-wrap lg:flex-nowrap items-center justify-center md:px-10 gap-20">
                        <div className="w-10/12">
                            <AmontInFunds />
                        </div>
                        <div className="w-10/12">
                            <Liquidity />
                            {/* <Tri /> */}
                        </div>
                    </div>
                    <div className="overflow-auto rounded-lg shalow-low p-5 overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full flex flex-wrap lg:flex-nowrap items-center justify-center md:px-10 gap-20">
                        <div className="w-10/12">
                            <Tri />
                        </div>
                    </div>

                    <div className="">
                        <TableSummaryFunds />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default OverviewBackOffice;
