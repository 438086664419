import {
    TransactionStatus,
    TypeTransaction,
    useGetLastProjectValorisationComputedQuery,
    useGetProjectByIdQuery,
    useGetTransactionsProjectQuery,
    useGetTriProjectQuery,
} from '@api/api';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { formatDate, printLargeValue } from '@utils/Utils';
import { useState } from 'react';
import { PrimaryButton } from '../../../commun/Buttons';
import Loading from '../../../commun/Loading';

import AddProjectInBlacklist from './AddProjectInBlacklist';
import EditProjectStatus from './EditProjectStatus';
import FormClosure from './FormClosure';
import FormIssued from './FormIssued';
import Reporting from './Reporting';
import UpdateProjectBlacklist from './UpdateProjectBlacklist';

function LabelGrid({ children }: { children: React.ReactNode }) {
    return (
        <h3 className="text-md font-mainFontFamily font-medium leading-6 text-gray-900">
            {children}
        </h3>
    );
}

function GridElement({ children }: { children: React.ReactNode }) {
    return (
        <div className="grid md:grid-cols-2 border-b-2 py-2">{children}</div>
    );
}

export function Suivis({ projectId }: { projectId: number }) {
    const {
        data: transactions,
        isLoading: isLoadingTransactionsProject,
        isFetching: isFetchingTransactionsProject,
        refetch,
    } = useGetTransactionsProjectQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const [toggleFormIssued, setToggleFormIssued] = useState(false);
    const [toggleFormBlackList, setToggleFormBlackList] = useState(false);
    const [toggleFormBlackListEdit, setToggleFormBlackListEdit] =
        useState(false);
    const [toggleFormClosure, setToggleFormClosure] = useState(false);
    const [toggleFormEditStatus, setToggleFormEditStatus] = useState(false);

    const { data: project, isLoading } = useGetProjectByIdQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    const {
        data: lastValo,
        isLoading: isLoadingLastValo,
        refetch: refetchValo,
    } = useGetLastProjectValorisationComputedQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const { data: tri, isLoading: isLoadingTri } = useGetTriProjectQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    return (
        <div className="w-full md:w-11/12 mx-auto p-1">
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    {toggleFormEditStatus ? (
                        <EditProjectStatus
                            open={toggleFormEditStatus}
                            setOpen={setToggleFormEditStatus}
                            projectId={projectId}
                        />
                    ) : null}
                    {toggleFormIssued ? (
                        <FormIssued
                            open={toggleFormIssued}
                            setOpen={setToggleFormIssued}
                            projectId={projectId}
                            refetchValo={refetchValo}
                        />
                    ) : null}
                    {toggleFormBlackList ? (
                        <AddProjectInBlacklist
                            open={toggleFormBlackList}
                            setOpen={setToggleFormBlackList}
                            projectId={projectId}
                        />
                    ) : null}
                    {toggleFormBlackListEdit ? (
                        <UpdateProjectBlacklist
                            open={toggleFormBlackListEdit}
                            setOpen={setToggleFormBlackListEdit}
                            projectId={projectId}
                        />
                    ) : null}
                    {toggleFormClosure ? (
                        <FormClosure
                            editToggle={toggleFormClosure}
                            setEditToggle={setToggleFormClosure}
                            projectId={projectId}
                            refetchValo={refetchValo}
                            valorisation={
                                lastValo?.valorisation
                                    ? parseFloat(
                                          lastValo?.valorisation.toFixed(2)
                                      )
                                    : 0
                            }
                        />
                    ) : null}

                    <TitleComponentForm>Suivi du projet</TitleComponentForm>
                    <div className="grid px-1 mb-4">
                        <GridElement>
                            <LabelGrid>Situation</LabelGrid>
                            <div className="grid grid-cols-2">
                                <p>{project?.status ? project.status : null}</p>
                                <PrimaryButton
                                    onClick={() =>
                                        setToggleFormEditStatus(true)
                                    }
                                    className="w-fit"
                                >
                                    Modifier
                                </PrimaryButton>
                            </div>
                        </GridElement>
                        <GridElement>
                            <LabelGrid>Date jouissance des intérêts</LabelGrid>
                            {project?.dateInterest ? (
                                <>
                                    {formatDate(
                                        new Date(project?.dateInterest)
                                    )}
                                </>
                            ) : (
                                <div className="flex justify-center items-center w-full mx-auto">
                                    <PrimaryButton
                                        className="w-fit"
                                        onClick={() =>
                                            setToggleFormIssued(true)
                                        }
                                    >
                                        Ajouter
                                    </PrimaryButton>
                                </div>
                            )}
                        </GridElement>
                        <GridElement>
                            <LabelGrid>
                                Valorisation
                                {isLoadingLastValo ? (
                                    <Loading />
                                ) : (
                                    <>
                                        {lastValo?.date ? (
                                            <>
                                                {' '}
                                                (
                                                {formatDate(
                                                    new Date(lastValo?.date)
                                                )}
                                                )
                                            </>
                                        ) : null}
                                    </>
                                )}
                            </LabelGrid>
                            {isLoadingLastValo ? (
                                <Loading />
                            ) : (
                                <>
                                    {printLargeValue(
                                        lastValo?.valorisation.toFixed(2)
                                    )}{' '}
                                    €
                                </>
                            )}
                        </GridElement>
                        <GridElement>
                            <LabelGrid>Date de sortie théorique</LabelGrid>
                            {project?.dateEnd
                                ? formatDate(project.dateEnd)
                                : null}
                        </GridElement>
                        <GridElement>
                            <LabelGrid>Distribution</LabelGrid>
                            {project?.payment ? project.payment : null}
                        </GridElement>
                        <GridElement>
                            <LabelGrid>Montant distribué</LabelGrid>
                            {isLoadingTransactionsProject ? (
                                <Loading />
                            ) : (
                                <>
                                    {printLargeValue(
                                        transactions
                                            ?.filter(
                                                (t) =>
                                                    t.type !==
                                                        TypeTransaction.cashProject &&
                                                    t.status ===
                                                        TransactionStatus.VALIDATED
                                                // &&
                                                // compareTwoDate(new Date(t.date), new Date(Date.now()))
                                            )
                                            ?.reduce(
                                                (sum, curr) =>
                                                    curr.amount + sum,
                                                0
                                            )
                                            .toFixed(2)
                                    )}{' '}
                                    €
                                </>
                            )}
                        </GridElement>
                        <GridElement>
                            <LabelGrid>TRI</LabelGrid>
                            {isLoadingTri ? (
                                <Loading />
                            ) : (
                                <>
                                    {tri
                                        ? tri.tri.toFixed(2)
                                        : 'Pas disponible'}{' '}
                                    %
                                </>
                            )}
                        </GridElement>
                        {project?.dateBlackList ? (
                            <>
                                <GridElement>
                                    <LabelGrid>Date liste noire</LabelGrid>

                                    <div className="grid grid-cols-2">
                                        {formatDate(
                                            new Date(project?.dateBlackList)
                                        )}
                                        <PrimaryButton
                                            onClick={() =>
                                                setToggleFormBlackListEdit(true)
                                            }
                                            className="w-fit"
                                        >
                                            Modifier
                                        </PrimaryButton>
                                    </div>
                                </GridElement>
                                <GridElement>
                                    <LabelGrid>
                                        Date deleted from liste noire
                                    </LabelGrid>
                                    {project?.dateBlackListDeletedAt ? (
                                        <div className="grid grid-cols-2">
                                            {formatDate(
                                                new Date(
                                                    project?.dateBlackListDeletedAt
                                                )
                                            )}
                                            <PrimaryButton
                                                onClick={() =>
                                                    setToggleFormBlackListEdit(
                                                        true
                                                    )
                                                }
                                                className="w-fit"
                                            >
                                                Modifier
                                            </PrimaryButton>
                                        </div>
                                    ) : null}
                                </GridElement>
                            </>
                        ) : (
                            <GridElement>
                                <LabelGrid>Date liste noire</LabelGrid>
                                <div className="flex justify-center items-center w-full mx-auto">
                                    <PrimaryButton
                                        className="w-fit"
                                        onClick={() =>
                                            setToggleFormBlackList(true)
                                        }
                                    >
                                        Ajouter
                                    </PrimaryButton>
                                </div>
                            </GridElement>
                        )}

                        <GridElement>
                            <LabelGrid>Date projet terminé</LabelGrid>
                            {project?.closed ? (
                                <div className="grid grid-cols-2">
                                    {formatDate(new Date(project.dateEnd))}
                                    <PrimaryButton
                                        onClick={() =>
                                            setToggleFormClosure(true)
                                        }
                                        className="w-fit"
                                    >
                                        Modifier
                                    </PrimaryButton>
                                </div>
                            ) : (
                                <div className="flex justify-center items-center w-full mx-auto">
                                    <PrimaryButton
                                        className="w-fit"
                                        onClick={() =>
                                            setToggleFormClosure(true)
                                        }
                                    >
                                        Cloturer
                                    </PrimaryButton>
                                </div>
                            )}
                        </GridElement>
                    </div>
                    <TitleComponentForm>Reporting</TitleComponentForm>
                    <Reporting projectId={projectId} />
                </>
            )}
        </div>
    );
}

export function displayHideLongText(
    type: string,
    text?: string,
    nb = 30
): string {
    if (text) {
        if (text.length > nb) {
            return text.substring(0, nb) + '...';
        } else {
            return text;
        }
    } else {
        return type;
    }
}
