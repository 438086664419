import { useEffect, useState } from 'react';
import { isEmpty, lastDay } from '../../../function/Utils';
import { useGetAllFondsQuery } from '../../../redux/features/fondsSlice';
import {
    BackOfficeUserState,
    BackOfficeUserStateInterface,
} from '../../Context/AppContext';
import PieChartFunds from './PieChartFunds';
import ShareChart from './ShareChart';
import TransactionsTable from './TransactionsTable';

const Wallet = () => {
    const { data: fondsData } = useGetAllFondsQuery();

    const date = lastDay(new Date(Date.now()));

    const [year, setYear] = useState<number>(date.getFullYear());
    const [month, setMonth] = useState<number>(date.getMonth());
    const [day, setDay] = useState<number>(date.getDate());

    const [fondsId, setFondsId] = useState<number | undefined>();

    useEffect(() => {
        if (
            !isEmpty(fondsData) &&
            fondsData !== undefined &&
            fondsId === undefined
        ) {
            setFondsId(fondsData[0].id!);
        }
    }, [fondsData]);

    const backOfficeState: BackOfficeUserStateInterface = {
        year: year,
        month: month,
        day: day,
        setDay: setDay,
        setMonth: setMonth,
        setYear: setYear,
        fondsId: fondsId,
        setFondsId: setFondsId,
    };

    return (
        <BackOfficeUserState.Provider value={backOfficeState}>
            <div className="mx-auto  md:mt-0 lg:px-2 md:pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto grid grid-cols-1 gap-3 grid-rows-[auto auto]">
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-1 overflow-auto">
                    <div className="lg:bg-bgDashboardClient lg:rounded-lg lg:shadow-low lg:my-2 lg:mx-1 p-10 lg:p-5">
                        <ShareChart />
                    </div>
                    <div className="lg:bg-bgDashboardClient lg:rounded-lg p-10 lg:p-5 lg:shadow-low lg:my-2 lg:mx-1 flex justify-center items-center mt-5">
                        <div className="w-full mx-auto ">
                            <PieChartFunds />
                        </div>
                    </div>
                </div>

                <div className="shadow-low rounded-lg lg:overflow-y-auto">
                    <TransactionsTable />
                    <p className="md:ml-2 mt-0 pt-0 py-2 text-sm italic">
                        * Performance moyenne par fonds{' '}
                    </p>
                </div>
            </div>
        </BackOfficeUserState.Provider>
    );
};

export default Wallet;
