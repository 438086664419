import { DocumentUserName } from '@api/api';
import { FormCreateDocumentUserAddress } from '@components/DashboardUser/Profile/FormInvest/DocumentAdress';

export function isValidAdressForm(address: FormCreateDocumentUserAddress) {
    if (!address?.name) return true;
    if (
        (address.name !== DocumentUserName.THIRD_PARTY_ATTESTATION &&
            address.values?.[0]?.file?.length > 0) ||
        (address.name === DocumentUserName.THIRD_PARTY_ATTESTATION &&
            address.values?.[0]?.file?.length > 0 &&
            address.values?.[1]?.file?.length > 0)
    ) {
        return true;
    }

    return false;
}
