import React, { useMemo } from "react";
import { TitreFondsUser } from "./FondsUser";
import { useGetFondsOverviewDocumentsQuery } from "../../../redux/features/fondsSlice";
import Loading from "../../commun/Loading";
import DisplayDocumentFonds from "../../commun/DisplayDocuments/DisplayDocumentFonds";

interface Props {
  fondsId: number;
}

const FonctioningFonds: React.FC<Props> = ({ fondsId }) => {
  return (
    <div className=" mb-5">
      <TitreFondsUser>Documents</TitreFondsUser>
      <Document fondsId={fondsId} />
    </div>
  );
};

export default FonctioningFonds;

function Document({ fondsId }: { fondsId: number }) {
  const { data: documentsFonds, isLoading: isLoadingDocument } =
    useGetFondsOverviewDocumentsQuery(fondsId, {
      skip: fondsId === undefined,
    });

  if (isLoadingDocument) {
    return (
      <div className=" w-full flex items-center justify-center h-[90vh]">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full px-2 md:w-11/12 mx-auto h-[50vh]">
      {documentsFonds?.length === 0 ? (
        <div className="w-full">
          <p className=" text-center">Aucun document n'a été trouvé.</p>
        </div>
      ) : null}
      {documentsFonds?.map((document) => (
        <DisplayDocumentFonds document={document} key={document.id} />
      ))}
    </div>
  );
}
