// function which are used in the application backOffice which will filter the data display on the screen according to the date, name of certain columun, amount of money, etc.

import {
    BlackListDateDto,
    BlackListDto,
    FeeConfigurationDto,
    PlatformDto,
    TransactionDto,
    TransactionTableDto,
    TypeTransaction,
} from '@api/api';
import { typeFiltration } from '@interfaces/BackOffice';
import DOMPurify from 'dompurify';

export function filterFunctions<
    T extends {
        type?: TypeTransaction;
        id?: number;
        to?: number | string;
        from?: number | string;
        surname?: string;
        firstName?: string;
        PlatformId?: number;
    },
>(
    array: T[],
    element: keyof T,
    type: typeFiltration,
    value: string | boolean | number | undefined,
    platfromData?: PlatformDto[]
): T[] {
    const arrayCopy: T[] = [...array];
    switch (type) {
        case typeFiltration.date:
            if (value === 'croissant') {
                return arrayCopy.sort((a, b) => {
                    return (
                        new Date(a[element] as string | Date).getTime() -
                        new Date(b[element] as string | Date).getTime()
                    );
                });
            } else {
                return arrayCopy.sort((a, b) => {
                    return (
                        new Date(b[element] as string | Date).getTime() -
                        new Date(a[element] as string | Date).getTime()
                    );
                });
            }
        case typeFiltration.amount:
            if (value === 'croissant') {
                return arrayCopy.sort((a, b) => {
                    return (
                        (a[element] as unknown as number) -
                        (b[element] as unknown as number)
                    );
                });
            } else {
                return arrayCopy.sort((a, b) => {
                    return (
                        (b[element] as unknown as number) -
                        (a[element] as unknown as number)
                    );
                });
            }

        case typeFiltration.name:
            if (value === 'all') return arrayCopy;
            return arrayCopy.filter((item) => {
                return (item[element] as unknown as string)
                    .toLowerCase()
                    .includes((value as string).toLocaleLowerCase());
            });
        case typeFiltration.firstName_Surname:
            if (value === 'all') return arrayCopy;
            return arrayCopy.filter((item) => {
                return `${item['surname'] as unknown as string} ${item['firstName'] as unknown as string}`
                    .toLowerCase()
                    .includes((value as string).toLocaleLowerCase());
            });
        case typeFiltration.nameWithId:
            if (value === 'all') return arrayCopy;
            return arrayCopy.filter((item) => {
                return platfromData
                    ?.filter((plat) => plat.id === item.PlatformId!)?.[0]
                    ?.name.toLowerCase()
                    .includes((value as string).toLocaleLowerCase());
            });

        case typeFiltration.exact:
            if (value === 'all') return arrayCopy;
            return arrayCopy.filter((item) => {
                return (
                    (item[element] as unknown as string).toLowerCase() ===
                    (value as string).toLocaleLowerCase()
                );
            });
        case typeFiltration.boolean:
            if (value === 'all') return arrayCopy;
            return arrayCopy.filter((item) => {
                return (
                    (item[element] as unknown as boolean) == (value as boolean)
                );
            });
        default:
            return arrayCopy;
    }
}

export function typeTransactionFunc(
    type: TypeTransaction,
    from: boolean,
    transaction: TransactionDto | TransactionTableDto
): string {
    switch (type) {
        case TypeTransaction.cashProject:
            if (from) {
                return 'Cash';
            } else {
                return transaction.projectNameTo!;
            }
        case TypeTransaction.cashUser:
            if (from) {
                return 'Cash';
            } else {
                return transaction.userNameTo!;
            }
        case TypeTransaction.projectCash:
            if (from) {
                return transaction.projectNameFrom!;
            } else {
                return 'Cash';
            }
        case TypeTransaction.userCash:
            if (from) {
                return transaction.userNameFrom!;
            } else {
                return 'Cash';
            }
        case TypeTransaction.cashNewParadigms:
            if (from) {
                return 'Cash';
            } else {
                return 'New Paradimgs';
            }
        case TypeTransaction.newParadigmsCash:
            if (from) {
                return 'New Paradimgs';
            } else {
                return 'Cash';
            }

        case TypeTransaction.newParadigmsUser:
            if (from) {
                return 'New Paradimgs';
            } else {
                return transaction.userNameTo!;
            }
        case TypeTransaction.userNewParadigms:
            if (from) {
                return transaction.userNameFrom!;
            } else {
                return 'New Paradimgs';
            }
        case TypeTransaction.platformCash:
            if (from) {
                return 'Platform';
            } else {
                return 'Cash';
            }
        case TypeTransaction.cashDebt:
            if (from) {
                return 'Cash';
            } else {
                return 'Debt';
            }
        case TypeTransaction.debtCash:
            if (from) {
                return 'Debt';
            } else {
                return 'Cash';
            }
        case TypeTransaction.buyingProjectCash:
            if (from) {
                return transaction.projectNameFrom!;
            } else {
                return 'Cash';
            }
        case TypeTransaction.projectStopCash:
            if (from) {
                return transaction.projectNameFrom!;
            } else {
                return 'Cash';
            }
    }
}

// get intereset of a proejct for a given date
export function getInterest<
    T extends {
        date: string | Date;
        value: number;
    },
>(interests: T[], date: Date): number {
    const interestsort = [...interests];
    let interest = interestsort
        ?.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        )
        ?.filter((projectInterest) =>
            compareTwoDate(new Date(projectInterest.date), date)
        );
    if (interest?.length === 0) {
        interest = interestsort?.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
    }
    return interest?.[0]?.value;
}

export const getDateBlackList = (
    blackList: BlackListDto,
    date: Date
): BlackListDateDto | undefined => {
    let dateBlackList = blackList.dates
        ?.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        )
        ?.filter((dateBlackList) =>
            compareTwoDate(new Date(dateBlackList.date), date)
        );
    if (dateBlackList?.length === 0) {
        dateBlackList = blackList.dates?.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
    }
    return dateBlackList?.[0];
};

export const getDateOperationFee = (
    operationFees: FeeConfigurationDto[],
    date: Date
): FeeConfigurationDto | undefined => {
    let dateType = operationFees
        ?.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        )
        ?.filter((dateType) => compareTwoDate(new Date(dateType.date), date));
    if (dateType?.length === 0) {
        dateType = operationFees?.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
    }
    return dateType?.[0];
};

export const getInitialAmountTransanction = (
    amount: number,
    entranceFee: number
) => {
    const res = amount / (1 + entranceFee);
    // return arrondie au centième
    return Math.round(res * 100) / 100;
};

export const isEmpty = (value: any) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const toInt = (x: string | undefined): number => {
    if (x) return parseInt(x);
    else return 0;
};

export const refreshImage = (
    ref: React.RefObject<HTMLImageElement>,
    imgURL: string
) => {
    // create a new timestamp
    const timestamp = new Date().getTime();

    const queryString = '?t=' + timestamp;
    if (ref.current) ref.current.src = imgURL + queryString;
};

export const createDateNow = (): Date => {
    const date = new Date(Date.now());
    const dateObject = setDate(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
    );
    return dateObject;
};

export const createDateFromFormatDate = (date: string): Date => {
    const dateArray = date.split('/');
    const dateObject = new Date(
        parseInt(dateArray[2]),
        parseInt(dateArray[1]) - 1,
        parseInt(dateArray[0], 2)
    );
    return dateObject;
};

export const formatDate = (date: Date | string, annee = 0): string => {
    date = new Date(date);
    if (date.getMonth() + 1 < 10) {
        const formatted_date = `${date.getUTCDate() > 9 ? `${date.getUTCDate()}` : `0${date.getUTCDate()}`}/0${date.getMonth() + 1}/${
            date.getFullYear() - annee
        }`;

        return formatted_date;
    } else {
        const formatted_date = `${date.getUTCDate() > 9 ? `${date.getUTCDate()}` : `0${date.getUTCDate()}`}/${date.getMonth() + 1}/${
            date.getFullYear() - annee
        }`;
        return formatted_date;
    }
};

// format date month/year
export const formatDateMonthYear = (date: Date | string): string => {
    date = new Date(date);
    if (date.getMonth() + 1 < 10) {
        const formatted_date = `0${date.getMonth() + 1}/${date.getFullYear()}`;

        return formatted_date;
    } else {
        const formatted_date = `${date.getMonth() + 1}/${date.getFullYear()}`;
        return formatted_date;
    }
};

export const numberDayBetweenDate = (
    dateStart: Date,
    dateEnd: Date
): number => {
    const date1: Date = new Date(
        dateStart.getFullYear(),
        dateStart.getMonth(),
        dateStart.getDate()
    );
    const date2: Date = new Date(
        dateEnd.getFullYear(),
        dateEnd.getMonth(),
        dateEnd.getDate()
    );
    if (date2.getTime() - date1.getTime() < 0) {
        return 0;
    }
    return Math.round((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
};

export const nextMonth = (date: Date): Date => {
    let month: number = date.getMonth();
    let year: number = date.getFullYear();

    if (month < 11) {
        month += 1;
    } else {
        month = 0;
        year += 1;
    }
    return new Date(year, month, 1, 2);
};

export const add30Days = (date: Date): Date => {
    return new Date(date.getTime() + 1000 * 3600 * 24 * 30);
};

export const addDays = (date: Date, nbDays: number): Date => {
    return new Date(date.getTime() + 1000 * 3600 * 24 * nbDays);
};

export const addNbDaysBeforeInterest = (date: Date): Date => {
    return add30Days(date);
};

export const remove30Days = (date: Date): Date => {
    return new Date(date.getTime() - 1000 * 3600 * 24 * 30);
};

export const removeDays = (date: Date, nbDays: number): Date => {
    return new Date(date.getTime() - 1000 * 3600 * 24 * nbDays);
};

export const nextDay = (date: Date): Date => {
    return new Date(date.getTime() + 1000 * 3600 * 24);
};

export const getNbMonthBetweenDates = (
    dateStart: Date,
    dateEnd: Date
): number => {
    const dateStartYear: number = dateStart.getFullYear();
    const dateEndYear: number = dateEnd.getFullYear();
    const dateStartMonth: number = dateStart.getMonth();
    const dateEndMonth: number = dateEnd.getMonth();
    return (dateEndYear - dateStartYear) * 12 + dateEndMonth - dateStartMonth;
};

export const createDateArray = (dateStart: Date, dateEnd: Date): number[] => {
    const date: number[] = [];
    let year: number = dateStart.getFullYear();
    let month: number = dateStart.getMonth();

    while (year != dateEnd.getFullYear() || month != dateEnd.getMonth()) {
        date.push(new Date(year, month, 1).getTime());
        if (month === 11) {
            month = 0;
            year += 1;
        } else {
            month += 1;
        }
    }
    date.push(new Date(year, month, 1).getTime());
    return date;
};

export function getTrimesterYearDateFormat(date: Date): string {
    const year: number = date.getFullYear();
    const month: number = date.getMonth();
    const trimester: number = Math.floor(month / 3) + 1;
    return `T${trimester} ${year}`;
}

export const printLargeValue = (value: number | string | undefined): string => {
    if (typeof value === 'undefined') {
        return '';
    } else {
        let str: string;
        if (typeof value === 'number') {
            str = value.toString();
        } else str = value;
        const list: string[] = str.split('.');
        if (list.length === 2) str = list[0];
        const result: string = str.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
        if (list.length === 2) {
            return `${result}.${list[1]}`;
        } else return result;
    }
};

export const stringToInt = (value: number | string): number => {
    if (typeof value === 'string') {
        return parseInt(value);
    }
    return value;
};

export const stringToFloat = (value: number | string) => {
    if (typeof value === 'string') {
        value = value.replace(',', '.');
        return parseFloat(value);
    }
    return value;
};

export const lastDay = (date: Date): Date => {
    return new Date(date.getTime() - 1000 * 3600 * 24);
};

export const lastMonth = (date: Date): Date => {
    let month: number = date.getMonth();
    let year: number = date.getFullYear();

    if (month > 0) {
        month -= 1;
    } else {
        month = 11;
        year -= 1;
    }
    return new Date(year, month, 1, 2);
};

export const addNbMonthsToDate = (date: Date, nbMonths: number): Date => {
    let dateEnd: Date = date;

    for (let i = 1; i <= nbMonths; i++) {
        dateEnd = nextMonth(dateEnd);
    }

    return new Date(dateEnd.getFullYear(), dateEnd.getMonth(), date.getDate());
};

export const startMonthOrLastMonth = (date: Date): Date => {
    return date.getDate() === 1 ? lastMonth(date) : startMonth(date);
};

export const startMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), 1, 2);
};

export const setDate = (year: number, month: number, day: number): Date => {
    return new Date(year, month, day, 2);
};

export const transformDate = (
    date: Date | string | number | undefined
): Date => {
    if (date === undefined) return new Date();
    date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 2);
};

// function which comare two dates if they have less than 3 hours between them return true else return false
export const compareTwoDate = (date1: Date, date2: Date): boolean => {
    const diff = date2?.getTime() - date1?.getTime();
    if (diff === undefined) return false;
    const diffHours = Math.abs(diff) / (1000 * 3600);
    if (diffHours <= 3 || diff >= 0) {
        return true;
    } else {
        return false;
    }
};

export const isDateClosed = (date1: Date, date2: Date): boolean => {
    const diff = date2.getTime() - date1.getTime();
    const diffHours = Math.abs(diff) / (1000 * 3600);
    if (diffHours <= 3) {
        return true;
    } else {
        return false;
    }
};

// function using compareTwoDate that says if a date is between two dates
export function isDateBetween(
    dateStart: Date,
    dateEnd: Date,
    date: Date
): boolean {
    if (compareTwoDate(dateStart, date) && compareTwoDate(date, dateEnd)) {
        return true;
    } else {
        return false;
    }
}

export const monthNumberToNbDay = (date: Date): number => {
    switch (date.getMonth() - 1) {
        case -1:
            return 31;
        case 0:
            return 31;
        case 1:
            if (date.getFullYear() % 4 === 0) {
                return 29;
            } else {
                return 28;
            }
        case 2:
            return 31;
        case 3:
            return 30;
        case 4:
            return 31;
        case 5:
            return 30;
        case 6:
            return 31;
        case 7:
            return 31;
        case 8:
            return 30;
        case 9:
            return 31;
        case 10:
            return 30;
        case 11:
            return 31;
        default:
            return 0;
    }
};

// function that transforms a sentence into one word with a calpital letter at the beginning of each word
export function sentenceToCamelCase(sentence: string): string {
    return sentence
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
}

// take various arguments and return a string of classes to be used in the className attribute, can put
// special class if the argument is true (e.g. isTrue && "specialClass")
export function classNames(...classes: any[]): string {
    return classes.filter(Boolean).join(' ');
}

export const debounce = (cb: any, delay = 1000) => {
    let timeout: any;

    return (...args: any) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            cb(...args);
        }, delay);
    };
};

export function createMarkup(html: any) {
    return {
        __html: DOMPurify.sanitize(html),
    };
}

export const throttle = (cb: any, delay = 1000) => {
    let shouldWait: boolean = false;
    let waitingArgs: any;

    const timeoutFunc = () => {
        if (waitingArgs === null) {
            shouldWait = false;
        } else {
            cb(...waitingArgs);
            waitingArgs = null;
            setTimeout(timeoutFunc, delay);
        }
    };

    return (...args: any) => {
        if (shouldWait) {
            waitingArgs = args;
            return;
        }

        cb(...args);
        shouldWait = true;

        setTimeout(timeoutFunc, delay);
    };
};

export const monthNumberToString = (int: number): string => {
    switch (int) {
        case 0:
            return 'Janvier';
        case 1:
            return 'Février';
        case 2:
            return 'Mars';
        case 3:
            return 'Avril';
        case 4:
            return 'Mai';
        case 5:
            return 'Juin';
        case 6:
            return 'Juillet';
        case 7:
            return 'Août';
        case 8:
            return 'Septembre';
        case 9:
            return 'Octobre';
        case 10:
            return 'Novembre';
        case 11:
            return 'Décembre';
        default:
            return '';
    }
};

export function groupBy(array: any[], key: string) {
    const res = array.reduce((result, currentItem) => {
        // Get the value of the key for the current item
        const groupKey = currentItem[key];

        // If the key doesn't exist yet, create it
        if (!result[groupKey]) {
            result[groupKey] = [];
        }

        // Add the current item to the group
        result[groupKey].push(currentItem);

        // Return the updated result object
        return result;
    }, {}); // Start with an empty object

    const result = [];
    for (let i = 0; i < Object.keys(res).length; i++) {
        const key = Object.keys(res)[i];
        result.push(res[key]);
    }
    return result;
}

export function numericValue(value: string): number | typeof NaN {
    return parseFloat(value?.replace(/[^\d.-]/g, '')); // Keep digits, the decimal point, and negative sign
}

export function formattedNumber(value: string): string {
    const res = value
        ?.replace(/\D/g, '')
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return res;
}

export function hasProperty<T extends object, K extends PropertyKey>(
    obj: T,
    key: K
): obj is T & Record<K, unknown> {
    return key in obj;
}

export const isSafari = () =>
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// display number as x M (million) if value > 1 000 000 else print number
export const displayMilionNumber = (value: number): string => {
    if (value > 1000000) {
        return `${(value / 1000000).toFixed(1)} M`;
    } else {
        return printLargeValue(value) || '';
    }
};

export function getIdsAssociationToChange(
    currentIds: number[],
    newIds: number[]
): { idsToAdd: number[]; idsToRemove: number[] } {
    const idsToRemove = currentIds.filter((id) => !newIds.includes(id));
    const idsToAdd = newIds.filter((id) => !currentIds.includes(id));
    return { idsToAdd, idsToRemove };
}

// remove an attribute from an object
export function removeAttribute<
    T extends object,
    K extends string | number | symbol,
>(obj: T, key: K): Omit<T, K> {
    if (hasProperty(obj, key)) {
        const { [key]: _, ...rest } = obj;
        return rest;
    } else {
        return obj;
    }
}

export function removeAttributes<
    T extends object,
    K extends string | number | symbol,
>(obj: T, key: K[]): Omit<T, K> {
    let res = obj;
    key.forEach((k) => {
        res = removeAttribute(res, k) as T;
    });
    return res;
}
