import { useAppSelector } from '@api/store';
import ParentScrollComponent from '@components/commun/Layout/ParentScrollComponent';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { BackButton } from '../../commun/Buttons';

interface Props {
    title: string;
    description?: string;
    children: React.ReactNode;
    classNameContainer?: string;
    classNameHeader?: string;
    subTitle?: React.ReactNode;
    backButton?: boolean;
    onClickReturn?: () => void;
    textBackButton?: string;
    classNameScollComponent?: string;
    scrollClassName?: string;
}

const WrapComponentDashboardUser = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            title,
            description,
            children,
            classNameContainer,
            classNameHeader,
            subTitle,
            backButton,
            onClickReturn,
            classNameScollComponent,
            textBackButton,
            scrollClassName,
        },
        ref
    ) => {
        // get the route

        const location = useLocation();
        const navigate = useNavigate();

        const onClickDefault = () => navigate(-1);

        const pageMeasurement = useAppSelector(
            (state) => state.pageMeasurement
        );

        useEffect(() => {
            const headerHeight =
                (
                    document.querySelector(
                        '.headerWrapComponentDashBoard'
                    ) as HTMLElement
                )?.offsetHeight || 0;
            document.documentElement.style.setProperty(
                '--dynamic-height-WrapComponentDashboardUser',
                `calc(100vh - ${headerHeight + 10}px)`
            );
        }, [pageMeasurement]);

        return (
            <div
                className={`bg-white h-full lg:shadow-low sm:rounded-lg relative overflow-hidden ${classNameContainer}`}
            >
                <div
                    className={`px-4 py-5 headerWrapComponentDashBoard grid md:grid-cols-2 ${
                        location.pathname.includes('DashBoardAdmin')
                            ? 'bg-secondBackgroundColor'
                            : 'bg-secondBackgroundColor'
                    }  ${classNameHeader}`}
                >
                    <div className="relative flex flex-col-reverse gap-2  md:grid md:grid-cols-[auto,1fr] ml-2 md:gap-x-3 ">
                        {backButton ? (
                            <div className="relative">
                                <BackButton
                                    onClick={onClickReturn || onClickDefault}
                                    textBackButton={textBackButton}
                                />
                            </div>
                        ) : null}
                        <div className={`${backButton ? '' : 'md:col-span-2'}`}>
                            <h3
                                className={`text-2xl font-mainFontFamily leading-6 font-semibold text-mainColor `}
                            >
                                {title}
                            </h3>
                            {description ? (
                                <p
                                    className={`mt-1 max-w-2xl text-sm text-gray-500 hidden md:block`}
                                >
                                    {description}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    {subTitle ? (
                        <div className=" self-center">{subTitle}</div>
                    ) : null}
                </div>
                <ParentScrollComponent
                    ref={ref}
                    scrollClassName={scrollClassName}
                    className={`border-t border-gray-200 ${classNameScollComponent ? classNameScollComponent : 'h-[var(--dynamic-height-WrapComponentDashboardUser)]'}`}
                >
                    {children}
                </ParentScrollComponent>
            </div>
        );
    }
);

export default WrapComponentDashboardUser;
