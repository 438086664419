const VueEnsTextePresentation: React.FC = () => {
  return (
    <div className="texteIntroduction">
      <p>
        Nos fonds permettent d'investir de manière <strong>diversifiée</strong>{" "}
        dans un ensemble de <strong>PME Francaises</strong> en apportant des
        financements sous forme de <strong>dettes</strong>, ce qui a pour
        objectif de garantir à nos investisseurs une certaine{" "}
        <strong>sécurité</strong> et une <strong>visibilité</strong> sur la{" "}
        <strong>liquidité</strong> de leur investissement ainsi que leurs{" "}
        <strong>rendements</strong> esperés.
      </p>
      <div className="line"></div>
    </div>
  );
};

export default VueEnsTextePresentation;
