import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { MenuDashBoardAdminBoolState } from '../../Context/AppContext';
import WrapComponentDashboardUser from '../../DashboardUser/commun/WrapComponentDashboardUser';
import { PrimaryButton } from '../../commun/Buttons';

import {
    CreateImpactDataDto,
    ImpactDataDto,
    UpdateImpactDataDto,
    useCreateImpactDataMutation,
    useGetImpactsDataQuery,
    useGetProjectFormDataQuery,
    useGetProjectSectorsQuery,
    useUpdateImpactDataMutation,
} from '@api/api';
import Loading from '@components/commun/Loading';
import { useForm } from 'react-hook-form';
import { units } from '../../../data/ImpactForm';
import { useNotificationContext } from '../../Context/notification-context';
import InputComponent from '../../commun/formComponent/InputComponent';
import SelectComponent from '../../commun/formComponent/SelectComponent';

const IndexImpactData = () => {
    const menuBoolState = useContext(MenuDashBoardAdminBoolState);
    const navigate = useNavigate();

    const { data: impacts, isLoading: isLoadingImpacts } =
        useGetImpactsDataQuery();

    return (
        <div
            className={`mx-auto  md:mt-0 lg:px-2 md:pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto `}
        >
            <WrapComponentDashboardUser
                title="Impacts management"
                description="Configuration des impacts data"
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
            >
                {isLoadingImpacts ? (
                    <Loading />
                ) : (
                    <div>
                        <AddImpactData />
                        <div className="grid grid-cols-frRepeat5FrAuto w-full mx-auto px-2 mt-5">
                            <div className="">Typologie marché</div>
                            <div className="">Secteur</div>
                            <div className="">Sous Secteur</div>
                            <div className="">Valeur</div>
                            <div className="">Unité</div>
                            <div className="w-[60px]">{''}</div>
                        </div>
                        {impacts?.map((impact) => (
                            <ImpactDataUpdate impact={impact} />
                        ))}
                    </div>
                )}
            </WrapComponentDashboardUser>
        </div>
    );
};

export default IndexImpactData;

function AddImpactData() {
    const { showError, showSuccess } = useNotificationContext();

    const {
        register,
        handleSubmit,
        getValues,
        control,
        setValue,
        reset,
        watch,
    } = useForm<CreateImpactDataDto>();

    const [addImpactData, { isLoading: isLoadingEditImpact }] =
        useCreateImpactDataMutation();

    const onSubmit = async (data: CreateImpactDataDto) => {
        await addImpactData(data)
            .unwrap()
            .then(() => {
                showSuccess('Created', 'Impact data created successfully');
            })
            .catch(() => {
                showError('Error', 'Error creating impact data');
            });
    };

    const { data: formData, isLoading: isLoadingFormData } =
        useGetProjectFormDataQuery();

    const { projectMarketTypology } = formData ?? {};

    const { data: projectSectors } = useGetProjectSectorsQuery();

    useEffect(() => {
        setValue('subSectorId', undefined);
    }, [watch('sectorId')]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-frRepeat5FrAuto px-2"
        >
            <div className="">
                <SelectComponent
                    styles={{
                        border: '2px solid gray-500',

                        borderRadius: '0rem',
                        width: '100%',
                    }}
                    className={' !border-none'}
                    register={register}
                    control={control}
                    value={'projectMarketTypologyId'}
                    optionValues={
                        projectMarketTypology?.map((marketTypology) => {
                            return {
                                value: marketTypology.id,
                                label: marketTypology.value,
                            };
                        }) ?? []
                    }
                    watch={watch}
                    getValues={getValues}
                    required={false}
                >
                    <label>Typologie marché</label>
                </SelectComponent>
            </div>
            <div className="">
                <SelectComponent
                    styles={{
                        border: '2px solid gray-500',

                        borderRadius: '0rem',
                        width: '100%',
                    }}
                    register={register}
                    control={control}
                    value={'sectorId'}
                    optionValues={
                        projectSectors?.map((s) => {
                            return {
                                value: s.id,
                                label: s.value,
                            };
                        }) ?? []
                    }
                    watch={watch}
                    getValues={getValues}
                    required={false}
                >
                    <label>Secteur</label>
                </SelectComponent>
            </div>
            <div className="">
                <SelectComponent
                    styles={{
                        border: '2px solid gray-500',

                        borderRadius: '0rem',
                        width: '100%',
                    }}
                    register={register}
                    control={control}
                    value={'subSectorId'}
                    optionValues={
                        projectSectors
                            ?.find((s) => s.id === watch('sectorId'))
                            ?.subSectors?.map((s) => {
                                return { value: s.id, label: s.value };
                            }) || []
                    }
                    watch={watch}
                    getValues={getValues}
                    required={false}
                >
                    <label>Sous Secteur</label>
                </SelectComponent>
            </div>
            <div className="">
                <InputComponent
                    value={'value'}
                    register={register}
                    className={{
                        input: 'border-[2px] border-[hsl(0, 0%, 80%)] py-[5.3px] px-1',
                    }}
                >
                    <label>Valeur</label>
                </InputComponent>
            </div>
            <div className="">
                <SelectComponent
                    styles={{
                        border: '2px solid gray-500',

                        borderRadius: '0rem',
                        width: '100%',
                    }}
                    register={register}
                    control={control}
                    value={'unit'}
                    optionValues={
                        units?.map((s) => {
                            return {
                                value: s,
                                label: s,
                            };
                        }) ?? []
                    }
                    watch={watch}
                    getValues={getValues}
                    required={false}
                >
                    <label>Unité</label>
                </SelectComponent>
            </div>
            <div className="flex justify-center items-end pb-1 mx-2 ">
                <PrimaryButton type="submit">Ajouter</PrimaryButton>
            </div>
        </form>
    );
}

function ImpactDataUpdate({ impact }: { impact: ImpactDataDto }) {
    const { showError, showSuccess } = useNotificationContext();

    const { data: formData, isLoading: isLoadingFormData } =
        useGetProjectFormDataQuery();

    const { projectMarketTypology } = formData ?? {};

    const { data: projectSectors } = useGetProjectSectorsQuery();

    const {
        register,
        handleSubmit,
        getValues,
        control,
        setValue,
        reset,
        watch,
    } = useForm<UpdateImpactDataDto>({
        defaultValues: {
            id: impact.id,
            value: impact.value,
            unit: impact.unit,
            subSectorId: impact.subSectorId,
            projectMarketTypologyId: impact.projectMarketTypologyId,
            sectorId: impact.sectorId,
        },
    });

    const [editImpactData, { isLoading: isLoadingEditImpact }] =
        useUpdateImpactDataMutation();

    const onSubmit = async (data: UpdateImpactDataDto) => {
        await editImpactData({
            id: data.id,
            updateImpactDataDto: data,
        })
            .unwrap()
            .then(() => {
                showSuccess('Edited', 'Impact data edited successfully');
            })
            .catch(() => {
                showError('Error', 'Error editing impact data');
            });
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-frRepeat5FrAuto w-full mx-auto px-2"
        >
            <div className="">
                <SelectComponent
                    styles={{
                        border: '2px solid gray-500',

                        borderRadius: '0rem',
                        // height: "3rem",
                        width: '100%',
                        // backgroundColor: "#F2F2F2",
                        // color: "#A1A1A1",

                        '&:hover': {
                            borderColor: '#F2F2F2',
                        },
                    }}
                    className={' !border-none'}
                    register={register}
                    control={control}
                    value={'projectMarketTypologyId'}
                    optionValues={
                        projectMarketTypology?.map((marketTypology) => {
                            return {
                                value: marketTypology.id,
                                label: marketTypology.value,
                            };
                        }) ?? []
                    }
                    watch={watch}
                    getValues={getValues}
                    required={false}
                ></SelectComponent>
            </div>
            <div className="">
                <SelectComponent
                    styles={{
                        border: '2px solid gray-500',

                        borderRadius: '0rem',
                        width: '100%',
                    }}
                    register={register}
                    control={control}
                    value={'sectorId'}
                    optionValues={
                        projectSectors?.map((s) => {
                            return {
                                value: s.id,
                                label: s.value,
                            };
                        }) ?? []
                    }
                    watch={watch}
                    getValues={getValues}
                    required={false}
                ></SelectComponent>
            </div>
            <div className="">
                <SelectComponent
                    styles={{
                        border: '2px solid gray-500',

                        borderRadius: '0rem',
                        width: '100%',
                    }}
                    register={register}
                    control={control}
                    value={'subSectorId'}
                    optionValues={
                        projectSectors
                            ?.find((s) => s.id === watch('sectorId'))
                            ?.subSectors?.map((s) => {
                                return { value: s.id, label: s.value };
                            }) || []
                    }
                    watch={watch}
                    getValues={getValues}
                    required={false}
                ></SelectComponent>
            </div>
            <div className="">
                <InputComponent
                    value={'value'}
                    register={register}
                    className={{
                        input: 'border-[2px] border-[hsl(0, 0%, 80%)] py-[5.2px] px-1',
                    }}
                ></InputComponent>
            </div>
            <div className="">
                <SelectComponent
                    styles={{
                        border: '2px solid gray-500',

                        borderRadius: '0rem',
                        width: '100%',
                    }}
                    register={register}
                    control={control}
                    value={'unit'}
                    optionValues={
                        units?.map((s) => {
                            return {
                                value: s,
                                label: s,
                            };
                        }) ?? []
                    }
                    watch={watch}
                    getValues={getValues}
                    required={false}
                ></SelectComponent>
            </div>
            <div className="flex justify-center items-end pb-2 mx-2 ">
                <PrimaryButton type="submit">Edit</PrimaryButton>
            </div>
        </form>
    );
}
