import { menuDashboard } from '@data/MenuDashboardClient';
import {
    faArrowLeft,
    faNewspaper,
    faRightFromBracket,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { menuListAdmin } from '../../data/MenuDashboardAdmin';
import { logout } from '../../function/logout';
import { MenuDashBoardAdminBoolState } from '../Context/AppContext';
import logo from './../../assets/logo.png';

const Menu = () => {
    const menuBottom: menuDashboard[] = [
        {
            icon: faUser,
            name: 'Sécurité',
            path: 'Securite',
        },
        {
            icon: faNewspaper,
            name: 'item2',
            path: 'item2',
        },
    ];

    const menuBoolState = useContext(MenuDashBoardAdminBoolState);

    // const handleIconClick = () => {
    //   let icon = document.querySelectorAll(".toggleclassDashAdmin");
    //   for (let e of icon) {
    //     e.classList.toggle("activeIcon");
    //   }
    //   menuBoolState?.setToggleMenu(!menuBoolState.toggleMenu);
    // };

    return (
        <React.Fragment>
            <p
                className={`toggleclassDashAdmin pointer ${
                    menuBoolState?.toggleMenu ? 'activeIcon' : ''
                }`}
                onClick={() => {
                    menuBoolState?.setToggleMenu(!menuBoolState.toggleMenu);
                }}
            >
                <span></span>
                <span></span>
                <span></span>
            </p>
            {!!menuBoolState?.toggleMenu && (
                <React.Fragment>
                    <div className="Menu_container !w-[300px]">
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                            <div className="flex relative flex-col">
                                <p>
                                    Espace <br /> Admin
                                </p>
                                <Link
                                    to="/DashBoardClient/tableau-de-bord"
                                    className="switch_dashboard "
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} />{' '}
                                    Espace Client
                                </Link>
                            </div>
                        </div>
                        <div className="menuTop_container">
                            {menuListAdmin.map((value, key) => (
                                <NavLink
                                    to={`/DashBoardAdmin/${value.path}`}
                                    className="element"
                                    key={key}
                                >
                                    <FontAwesomeIcon icon={value.icon} />

                                    {value.name}
                                </NavLink>
                            ))}
                        </div>
                        <div className="menuBottom_container">
                            {menuBottom.map((value, key) => (
                                <NavLink
                                    to={`/DashBoardAdmin/${value.path}`}
                                    className="element"
                                    key={key}
                                >
                                    <FontAwesomeIcon icon={value.icon} />

                                    {value.name}
                                </NavLink>
                            ))}
                        </div>
                        <div
                            onClick={() => logout()}
                            className="logout cursor-pointer"
                        >
                            <span className="text-mainColor">Déconnexion</span>{' '}
                            <FontAwesomeIcon
                                icon={faRightFromBracket}
                                className="text-white"
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Menu;
