import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Page from "../../function/page_info";
import { useAppSelector } from "../../redux/store";
import LoginRegister from "../SeConnecter/LoginRegister";

interface Props {
  path: string;
}

const ConnectezVous: React.FC<Props> = ({ path }) => {
  const pageData = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );

  useEffect(() => {
    Page.scrollFunction(
      "ConnectezVous_container_id",
      "apparitionConnectezVous",
      true,
      100
    );
  }, [pageData.scrollPage]);
  return (
    <div className="connectezVous_container" id="ConnectezVous_container_id">
      <div className="text">
        <h2>
          <span>Connectez-vous</span> <br /> pour accèder aux détails de ce
          fonds.
        </h2>
      </div>

      <div className="loginRegister_container">
        {/* <Link to="/Seconnecter">Se connecter/s'inscricre</Link> */}
        <LoginRegister path={path} />
      </div>
    </div>
  );
};

export default ConnectezVous;
