import React from "react";

const TexteIntroduction: React.FC = () => {
  return (
    <div className="texteIntroduction">
      <p>
        Contribuer à une <strong>société plus durable</strong> tout en
        bénéficiant d'un retour sur <strong>investissement optimisé</strong>.
        New Paradigms est spécialisée dans le financement obligataires des PMEs
        françaises du secteur immobilier, agricole et des énergies
        renouvelables.
      </p>
      <div className="line"></div>
    </div>
  );
};

export default TexteIntroduction;
