import { UserWithAllInfoDto } from '@api/api';
import BigTitleComponent from '@components/commun/BigTitleComponent';
import { PrimaryButton, WhiteButton } from '@components/commun/Buttons';
import UpdateInvestAccountComponent from '@components/commun/formComponent/ModelForm/InvestAccount/UpdateInvestAccountComponent';
import {
    onSubmitDocumentAddress,
    onSubmitDocumentIdentity,
} from '@components/commun/formComponent/OnSubmitHelpers';
import { useNotificationContext } from '@components/Context/notification-context';
import UpdateUserInfo, {
    FormUpdateUserInvestAccountDto,
} from '@components/DashboardUser/Profile/UpdateUserInfo';

import { hasProperty } from '@utils/Utils';
import React from 'react';
import { useNavigate } from 'react-router';
import { NumberParam, useQueryParam } from 'use-query-params';

const EditInvestAccountInvestir = ({
    user,
    setToogleInvestInfo,
    isUserAccepted,
    children,
}: {
    user: UserWithAllInfoDto;
    isUserAccepted: boolean;
    toogleInvestInfo: boolean;
    setToogleInvestInfo: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
}) => {
    const navigate = useNavigate();

    const [investAccountIdQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );

    const { showError, showSuccess } = useNotificationContext();

    // const { ValidationCode, handleSubmitForm } = useValidationCode({
    //   onSubmit: handleSubmit(onSubmit),
    // });

    return (
        <UpdateInvestAccountComponent
            user={user}
            useSubmitBtn={false}
            userInfoInvestAccount={{
                adress: user?.userInfo?.adress ?? '',
                phone: user?.userInfo?.phone ?? '',
                professionalSituation:
                    user?.userInfo?.professionalSituation ?? '',
                politicalyExposed:
                    user?.userInfo?.politicalyExposed == undefined ||
                    user?.userInfo?.politicalyExposed == null
                        ? 'false'
                        : user?.userInfo?.politicalyExposed
                          ? 'true'
                          : ('false' as any),
                politicalyExposedDetails:
                    user?.userInfo?.politicalyExposedDetails ?? '',

                usCitizen: 'false' as any,
            }}
            investAccountId={investAccountIdQuery ?? 0}
            beforeSubmitLogic={async ({ data }) => {
                let succeed = true;

                if (!hasProperty(data, 'userInfoInvestAccount')) {
                    return false;
                }

                // update doc adress
                succeed = await onSubmitDocumentAddress(
                    (
                        data.userInfoInvestAccount as FormUpdateUserInvestAccountDto
                    ).documentAdress,
                    showError,
                    showSuccess,
                    user?.id!
                );

                // update doc indentity
                succeed = await onSubmitDocumentIdentity(
                    (
                        data.userInfoInvestAccount as FormUpdateUserInvestAccountDto
                    ).documentIdentity1,
                    showError,
                    showSuccess,
                    user?.id!
                );
                return succeed;
            }}
            onSubmitSuccess={() => {
                setToogleInvestInfo((curr) => !curr);
            }}
        >
            {({ formState, disabled, loading }) => (
                <>
                    <BigTitleComponent className=" mt-7">
                        Vérifiez vos informations personnelles
                    </BigTitleComponent>
                    <UpdateUserInfo
                        formState={formState as any}
                        className="w-full px-3 md:w-11/12 md:mx-auto lg:w-9/12"
                    >
                        {/* <h3 className="w-full font-mainFontFamily text-center text-xl">
              Mettez à jour vos documents d'identités si nécessaire
            </h3> */}
                    </UpdateUserInfo>
                    {children}

                    <div className="w-full mt-3 mb-10 flex justify-center gap-3 items-center">
                        <WhiteButton onClick={() => navigate(-1)}>
                            Annuler
                        </WhiteButton>
                        <PrimaryButton
                            type="submit"
                            loading={loading}
                            disabled={
                                disabled || !isUserAccepted ? true : false
                            }
                        >
                            Continuer
                        </PrimaryButton>
                    </div>
                    {disabled ? (
                        <div className="w-full md:w-11/12 mx-auto p-3">
                            <p className="text-red-500 text-sm">
                                Veuillez remplir tous les champs et/ou mettez à
                                jours les documents nécessaires (si vous avez
                                modifié votre adresse, vous devez mettre à jour
                                votre justificatif de domicile)
                            </p>
                        </div>
                    ) : null}
                </>
            )}
        </UpdateInvestAccountComponent>
    );
};

export default EditInvestAccountInvestir;
