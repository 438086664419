import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { useNavigate } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';

import {
    useGetAllLatestDocumentsInvestAccountByUserIdQuery,
    useGetInvestAccountByIdQuery,
} from '@api/api';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import DisplayDocumentBeneficialOwner from '../../commun/DisplayDocuments/DisplayDocumentBeneficialOwner';
import DisplayDocumentMoralAccount from '../../commun/DisplayDocuments/DisplayDocumentMoralAccount';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';

export function DisplayMoralAccountCard({
    open,
    setOpen,
    userId,
    forAdminView = false,
}: {
    userId: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    forAdminView?: boolean;
}) {
    const classNameDiv =
        'grid grid-cols-1 space-y-2 md:space-y-0 md:grid-cols-2 border-b-2 py-2';
    const classNameH3 =
        'text-md font-mainFontFamily font-semibold text-center md:text-start';
    const classNameP = '';

    const [investAccountIdQuery, setInvestAccountIdQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );

    const {
        data: documents,
        isLoading: isLoadingDocuments,
        refetch,
    } = useGetAllLatestDocumentsInvestAccountByUserIdQuery(
        {
            userId: userId,
            investAccountId: investAccountIdQuery || 0,
        },
        { skip: investAccountIdQuery === undefined }
    );

    const navigate = useNavigate();

    const { data: investAccount, isLoading: isLoadingInvestAccount } =
        useGetInvestAccountByIdQuery(
            {
                userId: userId,
                investAccountId: investAccountIdQuery || 0,
            },
            {
                skip: investAccountIdQuery === undefined,
            }
        );

    const moralAccount = investAccount?.moralAccount;
    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title={() => {
                return (
                    <h3 className="font-mainFontFamily text-2xl text-mainColor font-semibold">
                        {investAccount?.moralAccount?.companyName}
                    </h3>
                );
            }}
            buttonBoolean={false}
            width="w-full md:w-10/12 xl:w-2/3"
        >
            <div className="mt-5 w-[95%] flex gap-2 flex-col  md:px-5 mx-auto">
                {isLoadingInvestAccount || moralAccount === undefined ? (
                    <Loading />
                ) : (
                    <>
                        <div className={`${classNameDiv}`}>
                            <h3 className={`${classNameH3}`}>Adresse</h3>
                            <p className={`${classNameP}`}>
                                {moralAccount.address}
                            </p>
                        </div>
                        {moralAccount.addressComplement ? (
                            <div className={`${classNameDiv}`}>
                                <h3 className={`${classNameH3}`}>
                                    Complément d'adresse
                                </h3>
                                <p className={`${classNameP}`}>
                                    {moralAccount.addressComplement}
                                </p>
                            </div>
                        ) : null}
                        <div className={`${classNameDiv}`}>
                            <h3 className={`${classNameH3}`}>
                                N° d'immatriculation
                            </h3>
                            <p className={`${classNameP} text-left`}>
                                {moralAccount.registrationNumber}
                            </p>
                        </div>
                        <div className={`${classNameDiv}`}>
                            <h3 className={`${classNameH3}`}>
                                Status juridique
                            </h3>
                            <p className={`${classNameP} text-left`}>
                                {moralAccount.legalStatus}
                            </p>
                        </div>
                        <div className={`${classNameDiv}`}>
                            <h3 className={`${classNameH3}`}>
                                Catégorisation d'ivestisseur
                            </h3>
                            <p className={`${classNameP} text-left`}>
                                {moralAccount.investorCategorization}
                            </p>
                        </div>
                    </>
                )}

                <TitleComponentForm>Documents</TitleComponentForm>
                <div className="flex flex-col gap-2">
                    {isLoadingDocuments ? (
                        <Loading />
                    ) : (
                        <>
                            {documents &&
                            documents.moralAccount?.length! > 0 ? (
                                documents.moralAccount.map((doc, index) => (
                                    <DisplayDocumentMoralAccount
                                        document={doc}
                                        key={index}
                                        userId={userId}
                                        refetch={
                                            forAdminView ? refetch : undefined
                                        }
                                    />
                                ))
                            ) : (
                                <p>Aucun document n'a été trouvé</p>
                            )}
                        </>
                    )}
                </div>
                <TitleComponentForm>Bénéficiaire effectif</TitleComponentForm>
                <div className="w-full flex flex-col gap-2">
                    {moralAccount?.beneficialOwners?.length === 0 ? (
                        <p>Aucun bénéficiaire effectif</p>
                    ) : (
                        <>
                            {moralAccount?.beneficialOwners?.map((b, index) => (
                                <div key={b.id} className="">
                                    <div className="">
                                        <h3 className="text-start font-semibold font-mainFontFamily">
                                            Bénéficiaire {index + 1}
                                        </h3>
                                        <dl className="grid w-full grid-cols-1 sm:grid-cols-3">
                                            <div className="border-t sm:justify-self-start border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0 ">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Nom complet
                                                </dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                                    {b.firstName} {b.lastName}
                                                </dd>
                                            </div>
                                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                                    Nationalité
                                                </dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                                    {b.nationality}
                                                </dd>
                                            </div>
                                            <div className="border-t border-gray-100 sm:justify-self-end px-4 py-6 sm:col-span-1 sm:px-0">
                                                <dt className="text-sm font-medium leading-6  text-gray-900">
                                                    Pourcentage détenu
                                                </dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                                    {b.proportionShareholder}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                    <div className="">
                                        <DisplayDocumentBeneficialOwner
                                            refetch={
                                                forAdminView
                                                    ? refetch
                                                    : undefined
                                            }
                                            document={
                                                documents?.beneficialOwner?.find(
                                                    (d) =>
                                                        d.beneficialOwnerId ===
                                                        b.id
                                                )!
                                            }
                                            userId={userId}
                                        />
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
                <div className="flex w-full justify-center gap-3 items-center">
                    <WhiteButton onClick={() => setOpen(false)}>
                        Fermer
                    </WhiteButton>
                    <PrimaryButton
                        onClick={() =>
                            navigate(
                                `/DashBoardClient/donnees-personnelles?pageDonneePerso=edit-invest-account&investAccountId=${investAccountIdQuery}`
                            )
                        }
                    >
                        Modifier
                    </PrimaryButton>
                </div>
            </div>
        </PopUp>
    );
}
