import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import {
    compareTwoDate,
    formatDate,
    lastDay,
    setDate,
    transformDate,
} from '../../../../function/Utils';

import { useForm } from 'react-hook-form';
import { useGetAllFondsQuery } from '../../../../redux/features/fondsSlice';

import {
    useDeleteAllSharesComputationInfoBetweenDatesMutation,
    useGetLatestDateComputedQuery,
    useGetOverviewFondsInfoOverviewAdminQuery,
} from '@api/api';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import CheckboxComponent from '../../../commun/formComponent/CheckboxComponent';
import InputComponent from '../../../commun/formComponent/InputComponent';
import RadioComponent from '../../../commun/formComponent/RadioComponent';
import SelectMultiComponent from '../../../commun/formComponent/SelectMultiComponent';
import Loading from '../../../commun/Loading';
import PopUp from '../../../commun/PopUp';
import {
    BackOfficeState,
    RootBlackOfficeState,
} from '../../../Context/AppContext';
import {
    Level,
    useNotificationContext,
} from '../../../Context/notification-context';

interface Props {
    computation: boolean;
    setComputation: React.Dispatch<React.SetStateAction<boolean>>;
}

enum EnumTypeAction {
    delete = 'Supprimer',
    compute = 'Calculer',
}

interface IAction {
    type: EnumTypeAction;
    all: boolean;
    applyFeesBool: boolean;
    dateStart?: string;
    dateEnd?: string;
}

export interface FormComputationRequest {
    type: EnumTypeAction;
    all: boolean;
    applyFeesBool: boolean;
    dateStart?: string;
    dateEnd?: string;
    fondsIds: number[];
}

export interface ISocketMessageFromClient {
    api: EnumSocketApi;
    type: EnumSocketTypeFromClient;
    action: EnumSocketAction;
    message: string;
    backOfficeInfo?: IBackOfficeInfo;
}

export interface IBackOfficeInfo {
    fondsIds: number[];
    date: Date;
    dailyShareBool: boolean;
    dateEnd?: Date;
}

export enum EnumSocketApi {
    share = 'share',
}

export enum EnumSocketTypeFromClient {
    start = 'start',
}

export enum EnumSocketAction {
    computeAllShares = 'computeAllShares',
    computeSharesMonth = 'computeSharesMonth',
    computeProjectValorisations = 'computeProjectValorisations',
    computeProjectValorisationsMonth = 'computeProjectValorisationsMonth',
    computeShareOfTheday = 'computeShareOfTheday',
    computeShareBetweenDate = 'computeShareBetweenDate',
    computeProjectValorisationsBetweenDate = 'computeProjectValorisationsBetweenDate',
    computeEverythingBetween2Dates = 'computeEverythingBetween2Dates',
    computeEverything = 'computeEverything',
}

export interface ISocketMessageFromServer<T = any> {
    type: EnumSocketTypeFromServer;
    message: string;
    progress?: string;
    date?: string;
    fondsId?: number;
    data?: T[];
}

export enum EnumSocketTypeFromServer {
    start = 'start',
    progress = 'progress',
    completed = 'completed',
    error = 'error',
}

const DataBase: React.FC<Props> = () => {
    const backOfficeState = useContext(BackOfficeState);
    const rootState = useContext(RootBlackOfficeState);

    const { data: fondsData } = useGetAllFondsQuery();

    const style: CSSProperties = {
        marginBottom: '0x',
    };

    const [dateChosen, setDateChosen] = useState<Date | undefined>(undefined);

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        formState: { isValid },
    } = useForm<FormComputationRequest>({
        defaultValues: {
            type: EnumTypeAction.compute,
            all: false,
            applyFeesBool: rootState?.applyFeesBool ?? true,
            dateEnd: transformDate(new Date(Date.now())).toJSON().split('T')[0],
            dateStart: transformDate(new Date(Date.now()))
                .toJSON()
                .split('T')[0],
            fondsIds: [],
        },
    });

    useEffect(() => {
        if (
            backOfficeState?.day &&
            backOfficeState?.year &&
            backOfficeState?.month !== undefined
        ) {
            setDateChosen(
                setDate(
                    backOfficeState?.year!,
                    backOfficeState?.month!,
                    backOfficeState?.day!
                )
            );
        }
    }, [
        backOfficeState?.day && backOfficeState?.day,
        backOfficeState?.year && backOfficeState?.year,
        backOfficeState?.month && backOfficeState?.month,
    ]);

    const [message, setMessage] = useState('');

    const [selectActionToggle, setSelectActionToggle] = useState(false);

    const {
        showError,
        showSuccess,
        dispatch: dispatchNotification,
    } = useNotificationContext();

    const connectionSSE = async (
        action: EnumSocketAction,
        formData: FormComputationRequest,
        dateStart?: Date,
        dateEnd?: Date
    ) => {
        const data: ISocketMessageFromClient = {
            api: EnumSocketApi.share,
            type: EnumSocketTypeFromClient.start,
            action: action,
            message: 'test',
            backOfficeInfo: {
                fondsIds: formData.fondsIds ?? [],
                date:
                    dateStart ??
                    setDate(backOfficeState?.year!, backOfficeState?.month!, 1),
                dailyShareBool: rootState?.dailyShare ?? true,
                dateEnd:
                    dateEnd ??
                    setDate(backOfficeState?.year!, backOfficeState?.month!, 1),
            },
        };

        const eventSource = new EventSource(
            `${
                import.meta.env.VITE_API_URL
            }shareApi/startComputation/${JSON.stringify(data)}`,
            { withCredentials: true }
        );
        const idSSE = new Date().toString();

        eventSource.addEventListener('open', (event) => {
            dispatchNotification({
                type: 'ADD',
                create: {
                    id: idSSE,
                    title: action,
                    level: Level.LOADING,
                    message: action,
                    computation: true,
                },
            });
        });

        eventSource.addEventListener('message', (event) => {
            const messageFromServer = JSON.parse(event.data);

            switch (messageFromServer.type) {
                case EnumSocketTypeFromServer.start:
                    dispatchNotification({
                        type: 'UPDATE',
                        update: {
                            id: idSSE,
                            title: action,
                            level: Level.LOADING,
                            message: messageFromServer.message,
                            computation: true,
                        },
                    });
                    break;
                case EnumSocketTypeFromServer.progress:
                    dispatchNotification({
                        type: 'UPDATE',
                        update: {
                            id: idSSE,
                            progress: messageFromServer.progress,
                            title: action,
                            level: Level.LOADING,
                            date: messageFromServer.date
                                ? new Date(messageFromServer.date)
                                : undefined,
                            message: messageFromServer.message,
                            computation: true,
                            fondsName: messageFromServer.fondsId
                                ? fondsData?.find(
                                      (f) => f.id === messageFromServer.fondsId
                                  )?.name
                                : undefined,
                        },
                    });
                    break;
                case EnumSocketTypeFromServer.completed:
                    dispatchNotification({
                        type: 'UPDATE',
                        update: {
                            id: idSSE,
                            level: Level.SUCCESS,
                            title: action,
                            progress: messageFromServer.progress,
                            date: messageFromServer.date
                                ? new Date(messageFromServer.date)
                                : undefined,
                            message: messageFromServer.message,
                            computation: true,
                        },
                    });
                    setTimeout(() => {
                        dispatchNotification({
                            type: 'DELETE',
                            id: idSSE,
                        });
                    }, 5000);
                    eventSource.close();
                    refetchLastDate();
                    refetchOverviewAdminInfo();
                    break;
                case EnumSocketTypeFromServer.error:
                    dispatchNotification({
                        type: 'UPDATE',
                        update: {
                            id: idSSE,
                            level: Level.ERROR,
                            title: 'Error',
                            message: messageFromServer.message,
                            errorMessage: messageFromServer.errorMessage,
                        },
                    });
                    setTimeout(() => {
                        dispatchNotification({
                            type: 'DELETE',
                            id: idSSE,
                        });
                    }, 5000);
                    eventSource.close();
                    break;

                default:
                    eventSource.close();
            }
        });

        eventSource.onerror = () => {
            eventSource.close();
        };
    };

    const [deleteShareBetweenDate, { isLoading: isLoadingDelete }] =
        useDeleteAllSharesComputationInfoBetweenDatesMutation();

    const onSubmit = async (data: FormComputationRequest) => {
        if (
            compareTwoDate(new Date(data.dateEnd!), new Date(data.dateStart!))
        ) {
            setMessage('Date start cannot be after date end or the same day');
            return;
        }

        switch (data.type) {
            case EnumTypeAction.compute:
                if (data.all) {
                } else {
                    connectionSSE(
                        EnumSocketAction.computeEverythingBetween2Dates,
                        data,
                        transformDate(data.dateStart),
                        transformDate(data.dateEnd)
                    );
                }
                break;
            case EnumTypeAction.delete:
                if (data.all) {
                    // await dispatch(deleteAllShares());
                } else {
                    const fondsIds = data.fondsIds ?? [];
                    await deleteShareBetweenDate({
                        dateStart: transformDate(data.dateStart)?.toISOString(),
                        dateEnd: transformDate(data.dateEnd)?.toISOString(),
                        fondsIds: fondsIds as any,
                    })
                        .unwrap()
                        .then(() => {
                            showSuccess('Deleted', 'Data deleted successfully');
                        })
                        .catch(() => {
                            showError('Error', 'Error deleting data');
                        });
                }
                break;
        }
    };

    const { data: lastDateComputed, refetch: refetchLastDate } =
        useGetLatestDateComputedQuery(undefined, {});

    useEffect(() => {
        if (dateChosen) {
            let dateStart = lastDay(dateChosen).toJSON()?.split('T')[0];
            if (lastDateComputed) {
                const dataComputed = [...lastDateComputed];
                const date = new Date(
                    dataComputed?.sort(
                        (a, b) =>
                            new Date(a?.lastDateComputed)?.getTime() -
                            new Date(b?.lastDateComputed)?.getTime()
                    )?.[0]?.lastDateComputed
                );

                if (!compareTwoDate(dateChosen, date)) {
                    dateStart = date.toJSON()?.split('T')[0];
                }
            }
            reset({
                ...watch(),
                type: EnumTypeAction.compute,
                all: false,
                applyFeesBool: rootState?.applyFeesBool ?? true,
                dateEnd: dateChosen?.toJSON()?.split('T')[0],
                dateStart: dateStart,
            });
        }
    }, [lastDateComputed, dateChosen]);

    const {
        data: ovierViewAdminInfo,
        isLoading,
        isFetching,
        refetch: refetchOverviewAdminInfo,
    } = useGetOverviewFondsInfoOverviewAdminQuery(
        (dateChosen ?? new Date()).toISOString(),

        {
            skip: !dateChosen || rootState?.applyFeesBool === undefined,
        }
    );

    return (
        <React.Fragment>
            {selectActionToggle ? (
                <PopUp
                    width="sm:min-w-[400px] md:w-[600px]"
                    title={() => {
                        return (
                            <>
                                <h3 className="text-xl font-medium leading-6 text-gray-900">
                                    Selectioner une action
                                </h3>
                            </>
                        );
                    }}
                    open={selectActionToggle}
                    setOpen={setSelectActionToggle}
                    submitItemName="Valider"
                    buttonBoolean={false}
                >
                    <div className="w-full mx-auto">
                        <div className="mb-3 pb-3 border-b-2">
                            <h3 className="font-mainFontFamily font-semibold mb-2">
                                Dernière date calculée :
                            </h3>
                            {lastDateComputed?.map((v, index) => (
                                <div
                                    key={index}
                                    className="grid grid-cols-2 mx-auto gap-3 w-9/12"
                                >
                                    <p className="text-start">{v.fondsName}</p>
                                    <p>{formatDate(v?.lastDateComputed)}</p>
                                </div>
                            ))}
                        </div>

                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="formTemplateSingUp gap-1"
                        >
                            {/* <SelectComponent
                register={register}
                value={"category"}
                control={control}
                optionValues={[
                  { value: EnumCategory.share, label: EnumCategory.share },
                  {
                    value: EnumCategory.projectValorisation,
                    label: EnumCategory.projectValorisation,
                  },
                  { value: EnumCategory.all, label: "Tout" },
                ]}
              >
                <h3 className="text-lg text-start font-medium leading-6 text-gray-900">
                  Category
                </h3>
              </SelectComponent> */}
                            <div className="flex justify-between w-full">
                                <RadioComponent
                                    register={register}
                                    name={'type'}
                                    watch={watch}
                                    values={[
                                        {
                                            value: EnumTypeAction.compute,
                                            label: EnumTypeAction.compute,
                                        },
                                        {
                                            value: EnumTypeAction.delete,
                                            label: EnumTypeAction.delete,
                                        },
                                    ]}
                                ></RadioComponent>
                            </div>

                            <CheckboxComponent
                                register={register}
                                watch={watch}
                                values={[
                                    {
                                        label:
                                            watch('type') ===
                                            EnumTypeAction.compute
                                                ? 'Tout recalculer'
                                                : 'Tout supprimer',
                                        name: 'all',
                                    },
                                ]}
                            ></CheckboxComponent>

                            <SelectMultiComponent
                                register={register}
                                setValue={setValue}
                                control={control}
                                value={'fondsIds'}
                                optionValues={
                                    fondsData?.map((f) => {
                                        return {
                                            label: f.name,
                                            value: f.id,
                                        };
                                    }) ?? []
                                }
                                optionsRender={(option) => option.label}
                                valueRender={(option) => option.value}
                            >
                                <h3 className="text-lg text-start font-medium leading-6 text-gray-900">
                                    Fonds à{' '}
                                    {watch('type') === EnumTypeAction.compute
                                        ? 'calculer'
                                        : 'supprimer'}{' '}
                                    <span className="text-red-500">*</span>
                                </h3>
                            </SelectMultiComponent>

                            {watch('all') ? null : (
                                <>
                                    <InputComponent
                                        register={register}
                                        type={'date'}
                                        value={'dateStart'}
                                        maxDate={
                                            watch('dateEnd')
                                                ? lastDay(
                                                      new Date(
                                                          watch(
                                                              'dateEnd'
                                                          ) as any
                                                      )
                                                  )
                                                : undefined
                                        }
                                    >
                                        <h3 className="text-lg text-start font-medium leading-6 text-gray-900">
                                            Date start{' '}
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        </h3>
                                    </InputComponent>
                                    <InputComponent
                                        register={register}
                                        type={'date'}
                                        value={'dateEnd'}
                                    >
                                        <h3 className="text-lg text-start font-medium leading-6 text-gray-900">
                                            Date end{' '}
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        </h3>
                                    </InputComponent>
                                </>
                            )}
                            <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
                                <WhiteButton
                                    onClick={() => {
                                        setSelectActionToggle(false);
                                    }}
                                    className="w-full"
                                >
                                    Cancel
                                </WhiteButton>
                                <PrimaryButton
                                    disabled={!isValid}
                                    type="submit"
                                    className="w-full"
                                >
                                    {isLoadingDelete ? (
                                        <Loading size={4} />
                                    ) : watch('type') ===
                                      EnumTypeAction.compute ? (
                                        'Calculer'
                                    ) : (
                                        'Supprimer'
                                    )}
                                </PrimaryButton>
                            </div>
                        </form>
                        {/* <div className="flex flex-col my-5 justify-start">
              <h3 className="text-lg text-start font-medium leading-6 text-gray-900">
                Category
              </h3>
              <select
                onChange={(e) =>
                  setStateAction({
                    ...actionState,
                    category: e.target.value as EnumCategory,
                  })
                }
                value={actionState.category}
                className="border-2 rounded-md p-1"
              >
                <option value={EnumCategory.share}>{EnumCategory.share}</option>
                <option value={EnumCategory.projectValorisation}>
                  {EnumCategory.projectValorisation}
                </option>
                <option value={EnumCategory.all}>Tout</option>
              </select>
            </div>

            <div className="flex flex-col my-5 justify-start">
              <h3 className="text-lg text-start font-medium leading-6 text-gray-900">
                Type
              </h3>

              <select
                onChange={(e) =>
                  setStateAction({
                    ...actionState,
                    type: e.target.value as EnumTypeAction,
                  })
                }
                className="border-2 rounded-md p-1 w-full "
                value={actionState.type}
              >
                <option value={EnumTypeAction.compute}>
                  {EnumTypeAction.compute}
                </option>
                <option value={EnumTypeAction.delete}>
                  {EnumTypeAction.delete}
                </option>
              </select>
            </div>

            <div className="flex gap-2 my-5">
              <input
                type="checkbox"
                id="all"
                onChange={(e) =>
                  setStateAction({
                    ...actionState,
                    all: e.target.checked,
                  })
                }
                value={actionState.all ? 1 : 0}
                checked={actionState.all ? true : false}
                className=""
              />
              <label htmlFor="all" className="cursor-pointer ">
                {actionState.type === EnumTypeAction.compute
                  ? "Tout recalculer"
                  : "Tout supprimer"}
              </label>
            </div>

            {actionState.category === EnumCategory.share ? (
              <div className="flex gap-2 my-5">
                <input
                  type="checkbox"
                  id="applyFeesBool"
                  onChange={(e) =>
                    setStateAction({
                      ...actionState,
                      applyFeesBool: e.target.checked,
                    })
                  }
                  value={actionState.applyFeesBool ? 1 : 0}
                  checked={actionState.applyFeesBool ? true : false}
                  className=""
                />
                <label htmlFor="applyFeesBool" className="cursor-pointer ">
                  Appliquer les frais
                </label>
              </div>
            ) : null}

            {actionState.all ? null : (
              <>
                <div className="flex flex-col my-5 justify-start">
                  <h3 className="text-lg text-start font-medium leading-6 text-gray-900">
                    Date de début
                  </h3>

                  <input
                    className="w-full border rounded-md p-1"
                    type="date"
                    onChange={(e) =>
                      setStateAction({
                        ...actionState,
                        dateStart: e.target.value,
                      })
                    }
                    value={actionState.dateStart}
                  ></input>
                </div>
              </>
            )}
            <div className="flex flex-col my-5 justify-start">
              <h3 className="text-lg text-start font-medium leading-6 text-gray-900">
                Date de fin
              </h3>

              <input
                className="w-full border rounded-md p-1"
                type="date"
                onChange={(e) =>
                  setStateAction({
                    ...actionState,
                    dateEnd: e.target.value,
                  })
                }
                value={actionState.dateEnd}
              ></input>
            </div> */}
                    </div>
                </PopUp>
            ) : (
                <PrimaryButton
                    onClick={async () => {
                        // await dispatch(getLatestDateComputed());
                        setSelectActionToggle(true);
                    }}
                    className="ml-3"
                >
                    <p>Sélection une action</p>
                </PrimaryButton>
            )}
        </React.Fragment>
    );
};

export default DataBase;
