import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TitreInvestirComponent } from '../DashboardUser/Investir/EtapeInvestAccount';
import TitleComponentForm from './formComponent/LayoutComponents/TitleComponentForm';

interface Props {
    bool: boolean;
    setBool: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    className?: string;
    children: React.ReactNode;
}

export const DropDownComponentAdmin: React.FC<Props> = ({
    bool,
    setBool,
    title,
    className,
    children,
}) => {
    return (
        <div className={`${className}`}>
            <TitleComponentForm
                className="grid !w-full mt-3 grid-cols-2 cursor-pointer"
                onClick={() => setBool(!bool)}
            >
                <div className="">{title}</div>
                <div className="  justify-self-end pr-2">
                    <FontAwesomeIcon
                        icon={faArrowRight}
                        className={` transition-all ${bool ? 'transform rotate-90' : ''}`}
                    />
                </div>
            </TitleComponentForm>
            {bool ? <>{children}</> : null}
        </div>
    );
};

export const DropDownComponentUser: React.FC<Props> = ({
    bool,
    setBool,
    title,
    className,
    children,
}) => {
    return (
        <div className={`${className}`}>
            <TitreInvestirComponent
                className="grid !w-full mt-3 grid-cols-[auto,auto]  md:grid-cols-2 cursor-pointer"
                onClick={() => setBool(!bool)}
            >
                <div className="">{title}</div>
                <div className="  justify-self-end pr-2">
                    <FontAwesomeIcon
                        icon={faArrowRight}
                        className={` transition-all ${bool ? 'transform rotate-90' : ''}`}
                    />
                </div>
            </TitreInvestirComponent>
            {bool ? <>{children}</> : null}
        </div>
    );
};
