import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import React from 'react';
import { useForm } from 'react-hook-form';

import {
    CreateProjectSubSectorDto,
    useCreateProjectSubSectorMutation,
    useGetProjectSectorsQuery,
} from '@api/api';
import { useNotificationContext } from '../../Context/notification-context';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import PopUp from '../../commun/PopUp';
import InputComponent from '../../commun/formComponent/InputComponent';
import SelectComponent from '../../commun/formComponent/SelectComponent';

const AddProjectSubSector = ({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { data: projectSectors } = useGetProjectSectorsQuery();

    const [AddProjectSubSector, { isLoading: isLoadindAdd }] =
        useCreateProjectSubSectorMutation();

    const formState = useForm<CreateProjectSubSectorDto>();

    const { register, handleSubmit, watch, control, setValue } = formState;

    const { showError, showSuccess } = useNotificationContext();

    const onSubmit = async (data: CreateProjectSubSectorDto) => {
        await AddProjectSubSector(data)
            .unwrap()
            .then(() => {
                showSuccess('Created', 'sous secteur added');
                setOpen(false);
            })
            .catch(() => {
                showError('Error', 'Error adding sous secteur');
            });
    };

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            title={() => {
                return (
                    <div className="text-center text-mainColor font-bold text-2xl">
                        Add Sous secteur
                    </div>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
            >
                <SelectComponent
                    value={'sectorId'}
                    register={register}
                    control={control}
                    optionValues={
                        projectSectors?.map((sector) => ({
                            value: sector.id,
                            label: sector.value,
                        })) || []
                    }
                >
                    <LabelComponentForm>Sector</LabelComponentForm>
                </SelectComponent>
                <InputComponent value={'value'} register={register}>
                    <LabelComponentForm>Name</LabelComponentForm>
                </InputComponent>

                <div className="w-full mt-4 flex justify-center items-center gap-2 ">
                    <WhiteButton onClick={() => setOpen(false)}>
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit" loading={isLoadindAdd}>
                        Add
                    </PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
};

export default AddProjectSubSector;
