import {
    CreateCustomUserFeeDto,
    useCreateCustomUserFeeMutation,
} from '@api/api';
import { PrimaryButton, WhiteButton } from '@components/commun/Buttons';
import CustomUserFeeForm, {
    CreateOrUpdateCustomUserFeeFormDto,
} from '@components/commun/formComponent/ModelForm/CustomUserFee/CustomUserFeeForm';
import PopUp from '@components/commun/PopUp';
import { useNotificationContext } from '@components/Context/notification-context';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddCustomUserFee = ({ open, setOpen }: Props) => {
    const { showError, showSuccess } = useNotificationContext();

    const [addCustomUserFee, { isLoading }] = useCreateCustomUserFeeMutation();

    const onSubmit = async (data: CreateOrUpdateCustomUserFeeFormDto) => {
        const dataToSend: CreateCustomUserFeeDto = {
            investAccountId: data.investAccountId,
            feeId: data.feeId,
            dateValidaty: data.dateValidaty ? data.dateValidaty : null,
            type: data.type,
            value: data.value,
            typeInvestisseurId: data.typeInvestisseurId,
        };

        await addCustomUserFee(dataToSend)
            .unwrap()
            .then(() => {
                showSuccess('Success', 'Custom User Fee added successfully');
            })
            .catch((error) => {
                showError('Error', 'Error while adding Custom User Fee');
            });
    };

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            title={() => {
                return (
                    <>
                        <h3 className="text-2xl font-mainFontFamily font-semibold leading-6 text-gray-900">
                            Add Custom User Fee
                        </h3>
                    </>
                );
            }}
        >
            <CustomUserFeeForm
                onSubmit={onSubmit}
                renderButtons={
                    <>
                        <WhiteButton
                            onClick={() => {
                                setOpen(false);
                            }}
                            className="w-full"
                        >
                            Cancel
                        </WhiteButton>
                        <PrimaryButton
                            loading={isLoading}
                            type="submit"
                            className="w-full"
                        >
                            Add
                        </PrimaryButton>
                    </>
                }
            />
        </PopUp>
    );
};

export default AddCustomUserFee;
