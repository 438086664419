import equilibre from '../assets/PM2.jpg';
import responsable from '../assets/beth-macdonald-permaculture.jpg';
import renouvelable from '../assets/nicholas-doherty-small-ER.jpg';

export interface fondInterface {
    name: string;
    category: string;
    id: string;
    rendement: number;
    liquidite: number;
    social: number;
    environnemental: number;
    cover: string;
    oderVueEns: number;
    oderHomePage: number;
    path: false | string;
    textePresentation?: () => JSX.Element;
}

export const fondList: fondInterface[] = [
    // {
    // 	name: 'Résilience',
    // 	category: 'Immobilier',
    // 	id: '3f',
    // 	rendement: 3,
    // 	liquidite: 2,
    // 	social: 4,
    // 	environnemental: 4,
    // 	cover: resilience,
    // 	oderVueEns: 5,
    // 	oderHomePage: 1,
    // 	path: false,
    // 	texte: "Portefeuille diversifié sur plus de 100 projets et bénéficiant d'une garantie pouvant suporter jusqu'à une baisse de 80% de la valeur total de ses investissements.",

    // },
    {
        name: 'Energie renouvelable',
        category: 'environnement',
        id: '2f',
        rendement: 4.5,
        liquidite: 2,
        social: 5,
        environnemental: 5,
        cover: renouvelable,
        oderVueEns: 2,
        oderHomePage: 2,
        path: false,
        textePresentation: () => {
            return (
                <p>
                    Portefeuille d'environ{' '}
                    <span className="bold">
                        30 projets d'énergies renouvelables
                    </span>{' '}
                    contribuant à la résilience énergétique et à la création
                    d'emplois sur le territoire. La stratégie du fonds se
                    concentre sur des investissements résilients et se démarque
                    par son impact sur l'aménagement du territoire.
                </p>
            );
        },
    },

    // {
    // 	name: 'Immobilier dynamique',
    // 	category: 'Immobilier',
    // 	id: '1f',
    // 	rendement: 9,
    // 	liquidite: 2,
    // 	social: 4,
    // 	environnemental: 3,
    // 	cover: dynamique,
    // 	oderVueEns: 1,
    // 	oderHomePage: 3,
    // 	path: "/ImmobilierDynamique",
    // 	texte: "Portefeuille d'un minimum 30 projets de et réhabilitation immobilière contribuant à la résilience énergétique et à la création d'emplois sur le territoire. Le fonds selectionne les opportunités à haute valeur ajoutée et se démarque par son excellent ratio rendement/risque grace à une sélection rigoureuse et à une importante diversification de ses actifs.",
    // },
    {
        name: 'Agriculture responsable',
        category: 'environnement',
        id: '4f',
        rendement: 4.5,
        liquidite: 2,
        social: 5,
        environnemental: 5,
        cover: responsable,
        oderVueEns: 3,
        oderHomePage: 1,
        path: false,
        textePresentation: () => {
            return (
                <p>
                    Portefeuille d'environ{' '}
                    <span className="bold">
                        30 projets d'agriculture durable
                    </span>{' '}
                    contribuant à la résilience alimentaire et la création
                    d'emplois sur le territoire. La stratègie du fonds se
                    concentre sur des investissements resilients et se démarque
                    par son{' '}
                    <span className="bold">impact positif sur le vivant</span>.
                </p>
            );
        },
    },
    {
        name: 'Immobilier',
        category: 'Immobilier',
        id: '5f',
        rendement: 7,
        liquidite: 2,
        social: 4,
        environnemental: 3,
        cover: equilibre,
        oderVueEns: 1,
        oderHomePage: 3,
        path: '/Immobilier',
        textePresentation: () => {
            return (
                <p>
                    Portefeuille d'un{' '}
                    <span className="bold">minimum 30 projets</span> de
                    <span className="bold"> promotion</span> et{' '}
                    <span className="bold">réhabilitation immobilière</span>{' '}
                    contribuant à la résilience énergétique et la création
                    d'emplois sur le territoire. La stratégie du fonds se
                    concentre sur des{' '}
                    <span className="bold">investissements résilients</span> et
                    se démarque par son excellent ratio rendement/risque grace à
                    une sélection rigoureuse et à une importante diversification
                    de ses actifs.
                </p>
            );
        },
    },
];
