import React from "react";

const Avertissementcomponent: React.FC = () => {
  return (
    <div className="avertissement_container">
      <h2>Avertissement</h2>
      <p>
        Les fonds sont principalement investis dans des entreprises non cotées
        en bourse qui présentent des risques particuliers. Vous devez prendre
        connaissance des facteurs de risques de ces fonds en société en
        commandite spéciale décrits à la rubrique « profil de risque et de
        rendement» du Règlement. Compte tenu de l’objectif et de la stratégie
        d’investissement des fonds, la fiscalité qui leur est applicable est
        celle de droit commun relative aux produits et gains de cession des
        SCSp, sans application d’aucun régime de faveur propre. L'investissement
        dans des supports non cotés présente un risque de perte partielle ou
        totale du capital investi ainsi qu'un risque d'illiquidité (difficultés
        à céder les titres).{" "}
      </p>
    </div>
  );
};

export default Avertissementcomponent;
