import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
    ContactMessageDto,
    useFetchTokenQuery,
    useSendMessageMutation,
} from '@api/api';
import { useNotificationContext } from '../Context/notification-context';
import { PrimaryButton } from '../commun/Buttons';

const FormulaireContact: React.FC = (): JSX.Element => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors, isValid },
    } = useForm<ContactMessageDto>();

    const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();

    useEffect(() => {
        if (userData) {
            reset({
                email: userData.email,
                firstName: userData.firstName,
                lastName: userData.lastName,
                userId: userData.id,
            });
        }
    }, [userData]);

    const { showError, showSuccess } = useNotificationContext();

    const [sendMessage, { isLoading }] = useSendMessageMutation();

    const onSubmit = async (data: ContactMessageDto) => {
        await sendMessage(data)
            .unwrap()
            .then(() => {
                showSuccess(
                    'Messge envoyé',
                    'Votre message a bien été envoyé, nous vous répondrons dans les plus brefs délais'
                );
                setValue('message', '');
                setValue('subject', '');
            })
            .catch(() => {
                showError(
                    'Erreur',
                    "Une erreur est survenue lors de l'envoi du message"
                );
            });
    };

    const email = 'antoine.flipo@new-paradigms-am.com';

    return (
        <div className="relative isolate bg-white mt-[60px] md:mt-[0px]">
            <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
                    <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                        <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                            <svg
                                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                                        width={200}
                                        height={200}
                                        x="100%"
                                        y={-1}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <path
                                            d="M130 200V.5M.5 .5H200"
                                            fill="none"
                                        />
                                    </pattern>
                                </defs>
                                <rect
                                    width="100%"
                                    height="100%"
                                    strokeWidth={0}
                                    fill="white"
                                />
                                <svg
                                    x="100%"
                                    y={-1}
                                    className="overflow-visible fill-gray-50"
                                >
                                    <path
                                        d="M-470.5 0h201v201h-201Z"
                                        strokeWidth={0}
                                    />
                                </svg>
                                <rect
                                    width="100%"
                                    height="100%"
                                    strokeWidth={0}
                                    fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                                />
                            </svg>
                        </div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                            Contactez-nous
                        </h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600"></p>
                        <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                            {/* <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Address</span>
                  <FontAwesomeIcon
                    icon={faBuilding}
                    className="h-7 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  545 Mavis Island
                  <br />
                  Chicago, IL 99191
                </dd>
              </div> */}
                            {/* <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telephone</span>
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="h-7 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <a
                    className="hover:text-gray-900"
                    href="tel:+1 (555) 234-5678"
                  >
                    +1 (555) 234-5678
                  </a>
                </dd>
              </div> */}
                            <div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Email</span>
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className="h-7 w-6 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </dt>
                                <dd>
                                    <a
                                        className="hover:text-gray-900"
                                        href={`mailto:${email}`}
                                    >
                                        {email}
                                    </a>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48"
                >
                    <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label
                                    htmlFor="first-name"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Prénom
                                    <span className="text-red-400">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        {...register('firstName', {
                                            required: true,
                                        })}
                                        id="first-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mainColor sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="last-name"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Nom<span className="text-red-400">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        {...register('lastName', {
                                            required: true,
                                        })}
                                        id="last-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mainColor sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Email<span className="text-red-400">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="email"
                                        {...register('email', {
                                            required: true,
                                        })}
                                        id="email"
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mainColor sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Sujet<span className="text-red-400">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        {...register('subject', {
                                            required: true,
                                        })}
                                        id="subject"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mainColor sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="message"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Message
                                    <span className="text-red-400">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <textarea
                                        {...register('message', {
                                            required: true,
                                        })}
                                        id="message"
                                        rows={4}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mainColor sm:text-sm sm:leading-6"
                                        defaultValue={''}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex justify-center items-center">
                            <PrimaryButton
                                type="submit"
                                loading={isLoading}
                                disabled={!isValid}
                            >
                                Envoyer
                            </PrimaryButton>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FormulaireContact;
