import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import update from '../../assets/edit.svg';
import { isEmpty, toInt } from '../../function/Utils';
import { editArticle, getArticles } from '../../redux/features/article';
import { useAppDispatch } from '../../redux/store';
import { Article, CategoryArticleInterface } from '../../types/Articles';

interface Props {
    article: Article;
    number: number;
}

const ArticleManage: React.FC<Props> = ({ article, number }) => {
    const [categories, setCategories] = useState<
        CategoryArticleInterface[] | null
    >(null);
    const [editContent, setEditContent] = useState<number>(article.order);
    const [editToggle, setEditToggle] = useState(false);

    const dispatch = useAppDispatch();

    const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = { ...article, id: article.id, order: editContent };
        await dispatch(editArticle(data));
        setEditToggle(!editToggle);
        dispatch(getArticles());
    };

    useEffect(() => {
        const articles = document.querySelectorAll(
            '.article_container'
        ) as NodeListOf<HTMLElement>;
        if (article.show) {
            articles[number].style.backgroundColor = 'rgb(176, 232, 182)';
        } else {
            articles[number].style.backgroundColor = 'rgb(232, 176, 176)';
        }
    }, [article]);

    return (
        <React.Fragment>
            <div className="article_container">
                <div>
                    <p>
                        <Link to={`/Articles/${article.id}`}>
                            {article.title}
                        </Link>
                    </p>
                    <div className="link">
                        <Link className="italic" to={`/Articles/${article.id}`}>
                            Voir l'article
                        </Link>
                    </div>
                </div>
                <div>
                    <p>
                        {article.datepublication instanceof Date
                            ? article.datepublication.toLocaleDateString(
                                  'en-GB'
                              )
                            : new Date(
                                  article.datepublication
                              ).toLocaleDateString('en-GB')}
                    </p>
                </div>
                <div>
                    <select>
                        {!isEmpty(categories) &&
                            categories?.map((categorie, key) => (
                                <option key={key} value={categorie.content}>
                                    {categorie.type}: {categorie.content}
                                </option>
                            ))}
                    </select>
                </div>

                {editToggle ? (
                    <form onSubmit={(e) => handleForm(e)}>
                        <div className="edit-delete">
                            <img
                                onClick={() => setEditToggle(!editToggle)}
                                src={update}
                                alt="edit"
                                className="pointer"
                            />
                        </div>
                        <input
                            type="text"
                            name="order"
                            style={{ width: '30px' }}
                            defaultValue={article.order}
                            onChange={(e) =>
                                setEditContent(toInt(e.target.value))
                            }
                        />
                        <input
                            className="pointer"
                            type="submit"
                            value="Valider la modification"
                        />
                    </form>
                ) : (
                    <div>
                        <div className="edit-delete">
                            <img
                                onClick={() => setEditToggle(!editToggle)}
                                src={update}
                                alt="edit"
                                className="pointer"
                            />
                        </div>
                        <p onClick={() => setEditToggle(!editToggle)}>
                            {article.order}
                        </p>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default ArticleManage;
