import { CreateFondsDto, useGetDashboardUserQuery } from '@api/api';
import CheckboxComponent from '@components/commun/formComponent/CheckboxComponent';
import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import { stringToFloat, transformDate } from '../../../function/Utils';
import { useCreateFondsMutation } from '../../../redux/features/fondsSlice';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import InputComponent from '../../commun/formComponent/InputComponent';
import TextAreaComponent from '../../commun/formComponent/TextAreaComponent';
import TextEditorComponent from '../../commun/formComponent/TextEditorComponent';
import { useNotificationContext } from '../../Context/notification-context';

const AddFonds = ({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const {
        register,
        watch,
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm<CreateFondsDto>({
        defaultValues: {
            dateCreation: new Date()?.toISOString().split('T')[0] as any,
        },
    });

    const {
        field: { onChange: onChangeComment, value: comment },
    } = useController<CreateFondsDto>({
        name: 'description',
        control: control,
    });

    const [addFonds, { isLoading: isLoadingAddFonds }] =
        useCreateFondsMutation();

    const { showError, showSuccess } = useNotificationContext();

    const { refetch } = useGetDashboardUserQuery();

    const onSubmit = async (data: CreateFondsDto) => {
        const request: CreateFondsDto = {
            name: data.name,
            dateCreation: transformDate(data.dateCreation)?.toISOString(),
            show: data.show,
            order: 1,
            performances: [
                {
                    date: transformDate(data.dateCreation)?.toISOString(),
                    value: stringToFloat(data.performances?.[0].value),
                },
            ],
            description: comment as string,
            overviewInfo: data.overviewInfo,
        };

        await addFonds(request)
            .unwrap()
            .then(async () => {
                showSuccess('Created', 'Fonds created successfully');

                await refetch();
                setOpen(false);
            })
            .catch((err) => {
                showError('Error', 'Error creating Fonds', err.data?.message);
            });
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={`formTemplateSingUp`}
            style={{ width: `90%` }}
        >
            <div
                className={`w-full mx-auto
            `}
            >
                <TitleComponentForm>CREATION FONDS</TitleComponentForm>
                <div className="grid md:grid-cols-2 md:gap-x-2">
                    <InputComponent register={register} value={'name'}>
                        <LabelComponentForm>
                            Nom du fonds <span className="text-red-500">*</span>
                        </LabelComponentForm>
                    </InputComponent>
                    <InputComponent
                        register={register}
                        value={'performances.0.value'}
                        type={'number'}
                    >
                        <LabelComponentForm>
                            Performance <span className="text-red-500">*</span>
                        </LabelComponentForm>
                    </InputComponent>
                    <CheckboxComponent
                        watch={watch}
                        register={register}
                        values={[
                            {
                                name: 'show',
                                label: 'Fonds investisable',
                            },
                        ]}
                    />
                </div>
                <InputComponent
                    register={register}
                    value={'dateCreation'}
                    type={'date'}
                >
                    <LabelComponentForm>
                        Date de création <span className="text-red-500">*</span>
                    </LabelComponentForm>
                </InputComponent>

                <TextAreaComponent
                    register={register}
                    value={'overviewInfo.subTitle'}
                    required={false}
                >
                    <LabelComponentForm>
                        Court sous titre pour Fonds user page
                    </LabelComponentForm>
                </TextAreaComponent>
                <TextAreaComponent
                    register={register}
                    required={false}
                    value={'overviewInfo.text'}
                >
                    <LabelComponentForm>
                        Court text pour Fonds user page
                    </LabelComponentForm>
                </TextAreaComponent>

                <TextEditorComponent
                    content={comment as any as string}
                    setContent={onChangeComment}
                    className=" md:col-span-2"
                >
                    <LabelComponentForm>Description</LabelComponentForm>
                </TextEditorComponent>
            </div>
            <div className="w-full mt-3 mb-2 flex justify-center gap-3 items-center">
                <WhiteButton onClick={() => setOpen(false)}>
                    Annuler
                </WhiteButton>
                <PrimaryButton
                    type="submit"
                    loading={isLoadingAddFonds}
                    disabled={!isValid ? true : false}
                >
                    Valider
                </PrimaryButton>
            </div>
        </form>
    );
};

export default AddFonds;
