import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { Article } from '../../types/Articles'



interface acticleState {
    articles: Article[];
    loading: boolean;
    errors: any;
}

const initialState: acticleState = {
    articles: [],
    loading: false,
    errors: null,
}

// actions are processes that get data from backend
export const getArticles = createAsyncThunk<Article[]>(
    "articles/getArtilces",
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}articles`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const addArticle = createAsyncThunk<Article, Article>(
    "articles/addArticle",
    async (data, thunkApi) => {
        try {
            const response: AxiosResponse<any, any> = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}articles`,
                data: data,
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const editArticle = createAsyncThunk<Article, Article>(
    "articles/editArticle",
    async (data, thunkApi) => {
        try {
            const response: AxiosResponse<any, any> = await axios({
                method: "put",
                url: `${import.meta.env.VITE_API_URL}articles/${data.id}`,
                data: { ...data },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteArticle = createAsyncThunk<number, number>(
    "articles/deleteArticle",
    async (articleId: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}articles/${articleId}`,
                withCredentials: true,
            })
            return articleId
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const uploadCoverArticle = createAsyncThunk<FormData, FormData>(
    "articles/uploadCoverArticle",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}articles/upload`,
                data: data,
                headers: { "Content-Type": 'multipart/form-data' },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const uploadCoverAuteurArticle = createAsyncThunk<FormData, FormData>(
    "articles/uploadCoverAuteurArticle",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}articles/upload/auteur`,
                data: data,
                headers: { "Content-Type": 'multipart/form-data' },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

// reducers -> reduce to a specific state -> changes state 

export const articleSlice = createSlice({
    name: "articles",
    initialState,
    reducers: {
        setArticles: (state, action: PayloadAction<Article[]>) => {
            state.articles = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getArticles.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getArticles.fulfilled, (state, action) => {
            state.articles = action.payload;
            state.loading = false;
        });
        builder.addCase(getArticles.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addArticle.fulfilled, (state, action) => {
            state.articles = [...state.articles, action.payload]
            state.loading = false;
        });
        builder.addCase(addArticle.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addArticle.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editArticle.fulfilled, (state, action) => {
            state.loading = false;
            state.articles = state.articles.map((article) => {
                if (article.id === action.payload.id) {
                    return {
                        ...action.payload,
                    };
                } else return article;

            });
        });
        builder.addCase(editArticle.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editArticle.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteArticle.fulfilled, (state, action) => {
            state.loading = false;
            state.articles = state.articles.filter((article) => article.id !== action.payload);
        });
        builder.addCase(deleteArticle.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteArticle.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(uploadCoverArticle.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(uploadCoverArticle.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(uploadCoverAuteurArticle.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(uploadCoverAuteurArticle.fulfilled, (state, action) => {
            state.loading = false;
        });
    }

});

export default articleSlice.reducer;
export const { setArticles } = articleSlice.actions;


