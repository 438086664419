export const situationProfessionelleListe: string[] =
  [
    "  Agriculteur(trice)"
    , "Artisan(e)"
    , "Commerçant(e)"
    , "Chef(fe) d'entreprise"
    , "Cadre de la fonction publique"
    , "Cadre du secteur privé"
    , "Enseignant(e)"
    , "Professions Libérales"
    , "Employé(e) du secteur public"
    , "Employé(e) du secteur privé"
    , "Ouvrier(ère)"
    , "Retraité(e)"
    , "Demandeur(se) d'emploi"
    , "Elève , étudiant(e), apprenti(e)"
    , "Autre personne sans activité professionnelle"

  ]

export const PaysListe: string[] = [
  "France",
  "Allemagne",
  "Autriche",
  "Belgique",
  "Bulgarie",
  "Chypre",
  "Croatie",
  "Danemark",
  "Espagne",
  "Estonie",
  "Finlande",
  "Grèce",
  "Hongrie",
  "Irlande",
  "Italie",
  "Lituanie",
  "Lettonie",
  "Luxembourg",
  "Malte",
  "Pays-Bas",
  "Pologne",
  "Portugal",
  "République tchèque",
  "Roumanie",
  "Slovaquie",
  "Slovénie",
  "Suède",
];

export const situationProListe: string[] = [
  "Artisan, commerçant, chef d'entreprise",
  "Profession libérale, scientifique ou artistique",
  "Cadre",
  "Employé",
  "Agriculture exploitant",
  "Ouvrier",
  "Retraité",
  "Sans activité professionnelle / étudiant",
];

export const secteurActiviteListe: string[] = [
  "Culture et production animale, chasse et services annexes",
  "Sylviculture et exploitation forestière",
  "Pêche et aquaculture",
  "Extraction de houille et de lignite",
  "Extraction d'hydrocarbures",
  "Extraction de minerais métalliques",
  "Autres industries extractives",
  "Services de soutien aux industries extractives",
  "Industries alimentaires",
  "Fabrication de boissons",
  "Fabrication de produits à base de tabac",
  "Fabrication de textiles",
  "Industrie de l'habillement",
  "Industrie du cuir et de la chaussure",
  "Travail du bois et fabrication d'articles en bois et en liège, à l'exception des meubles ; fabrication d'articles en vannerie et sparterie",
  "Industrie du papier et du carton",
  "Imprimerie et reproduction d'enregistrements",
  "Cokéfaction et raffinage",
  "Industrie chimique",
  "Industrie pharmaceutique",
  "Fabrication de produits en caoutchouc et en plastique",
  "Fabrication d'autres produits minéraux non métalliques",
  "Métallurgie",
  "Fabrication de produits métalliques, à l'exception des machines et des équipements",
  "Fabrication de produits informatiques, électroniques et optiques",
  "Fabrication d'équipements électriques",
  "Fabrication de machines et équipements n.c.a.",
  "Industrie automobile",
  "Fabrication d'autres matériels de transport",
  "Fabrication de meubles",
  "Autres industries manufacturières",
  "Réparation et installation de machines et d'équipements",
  "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
  "Captage, traitement et distribution d'eau",
  "Collecte et traitement des eaux usées",
  "Collecte, traitement et élimination des déchets ; récupération",
  "Dépollution et autres services de gestion des déchets",
  "Construction de bâtiments",
  "Génie civil",
  "Travaux de construction spécialisés",
  "Commerce et réparation d'automobiles et de motocycles",
  "Commerce de gros, à l'exception des automobiles et des motocycles",
  "Commerce de détail, à l'exception des automobiles et des motocycles",
  "Transports terrestres et transport par conduites",
  "Transports par eau",
  "Entreposage et services auxiliaires des transports",
  "Activités de poste et de courrier",
  "Hébergement",
  "Restauration",
  "Édition",
  "Production de films cinématographiques, de vidéo et de programmes de télévision ; enregistrement sonore et édition musicale",
  "Programmation et diffusion",
  "Télécommunications",
  "Programmation, conseil et autres activités informatiques",
  "Services d'information",
  "Activités des services financiers, hors assurance et caisses de retraite",
  "Assurance",
  "Activités auxiliaires de services financiers et d'assurance",
  "Activités immobilières",
  "Activités juridiques et comptables",
  "Activités des sièges sociaux ; conseil de gestion",
  "Activités d'architecture et d'ingénierie ; activités de contrôle et analyses techniques",
  "Recherche-développement scientifique",
  "Publicité et études de marché",
  "Autres activités spécialisées, scientifiques et techniques",
  "Activités vétérinaires",
  "Activités de location et location-bail",
  "Activités liées à l'emploi",
  "Activités des agences de voyage, voyagistes, services de réservation et activités connexes",
  "Enquêtes et sécurité",
  "Services relatifs aux bâtiments et aménagement paysager",
  "Activités administratives et autres activités de soutien aux entreprises",
  "Administration publique et défense ; sécurité sociale obligatoire",
  "Enseignement",
  "Activités pour la santé humaine",
  "Hébergement médico-social et social",
  "Hébergement médico-social et social",
  "Action sociale sans hébergement",
  "Activités créatives, artistiques et de spectacle",
  "Bibliothèques, archives, musées et autres activités culturelles",
  "Organisation de jeux de hasard et d'argent",
  "Activités sportives, récréatives et de loisirs",
  "Activités des organisations associatives",
  "Réparation d'ordinateurs et de biens personnels et domestiques",
  "Autres services personnels",
  "Activités des ménages en tant qu'employeurs de personnel domestique",
  "Activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre",
  "Activités des organisations et organismes extraterritoriaux",
  "Autre",
];

export const indicatifPaysList: string[] = ["Fr +33"];

export const allPaysList: string[] = [
  "France",
  "Afghanistan",
  "Afrique du Sud",
  "Albanie",
  "Algérie",
  "Allemagne",
  "Andorre",
  "Angola",
  "Anguilla",
  "Antigua-et-Barbuda",
  "Antilles Néerlandaises",
  "Arabie Saoudite",
  "Argentine",
  "Arménie",
  "Aruba",
  "Australie",
  "Autriche",
  "Azerbaïdjan",
  "Bahamas",
  "Bahreïn",
  "Bangladesh",
  "Barbade",
  "Belgique",
  "Belize",
  "Bénin",
  "Bermudes",
  "Bhoutan",
  "Biélorussie",
  "Birmanie (Myanmar)",
  "Bolivie",
  "Bosnie-Herzégovine",
  "Botswana",
  "Brésil",
  "Brunei",
  "Bulgarie",
  "Burkina Faso",
  "Burundi",
  "Cambodge",
  "Cameroun",
  "Canada",
  "Cap-vert",
  "Chili",
  "Chine",
  "Chypre",
  "Colombie",
  "Comores",
  "Corée du Nord",
  "Corée du Sud",
  "Costa Rica",
  "Côte d’Ivoire",
  "Croatie",
  "Cuba",
  "Danemark",
  "Djibouti",
  "Dominique",
  "Égypte",
  "Émirats Arabes Unis",
  "Équateur",
  "Érythrée",
  "Espagne",
  "Estonie",
  "États Fédérés de Micronésie",
  "États-Unis",
  "Éthiopie",
  "Fidji",
  "Finlande",
  "Gabon",
  "Gambie",
  "Géorgie",
  "Géorgie du Sud et les Îles Sandwich du Sud",
  "Ghana",
  "Gibraltar",
  "Grèce",
  "Grenade",
  "Groenland",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinée",
  "Guinée Équatoriale",
  "Guinée-Bissau",
  "Guyana",
  "Guyane Française",
  "Haïti",
  "Honduras",
  "Hong-Kong",
  "Hongrie",
  "Île Christmas",
  "Île de Man",
  "Île Norfolk",
  "Îles Åland",
  "Îles Caïmanes",
  "Îles Cocos (Keeling)",
  "Îles Cook",
  "Îles Féroé",
  "Îles Malouines",
  "Îles Mariannes du Nord",
  "Îles Marshall",
  "Îles Pitcairn",
  "Îles Salomon",
  "Îles Turks et Caïques",
  "Îles Vierges Britanniques",
  "Îles Vierges des États-Unis",
  "Inde",
  "Indonésie",
  "Iran",
  "Iraq",
  "Irlande",
  "Islande",
  "Israël",
  "Italie",
  "Jamaïque",
  "Japon",
  "Jordanie",
  "Kazakhstan",
  "Kenya",
  "Kirghizistan",
  "Kiribati",
  "Koweït",
  "Laos",
  "Le Vatican",
  "Lesotho",
  "Lettonie",
  "Liban",
  "Libéria",
  "Libye",
  "Liechtenstein",
  "Lituanie",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malaisie",
  "Malawi",
  "Maldives",
  "Mali",
  "Malte",
  "Maroc",
  "Martinique",
  "Maurice",
  "Mauritanie",
  "Mayotte",
  "Mexique",
  "Moldavie",
  "Monaco",
  "Mongolie",
  "Monténégro",
  "Montserrat",
  "Mozambique",
  "Namibie",
  "Nauru",
  "Népal",
  "Nicaragua",
  "Niger",
  "Nigéria",
  "Niué",
  "Norvège",
  "Nouvelle-Calédonie",
  "Nouvelle-Zélande",
  "Oman",
  "Ouganda",
  "Ouzbékistan",
  "Pakistan",
  "Palaos",
  "Panama",
  "Papouasie-Nouvelle-Guinée",
  "Paraguay",
  "Pays-Bas",
  "Pérou",
  "Philippines",
  "Pologne",
  "Polynésie Française",
  "Porto Rico",
  "Portugal",
  "Qatar",
  "République Centrafricaine",
  "République de Macédoine",
  "République Démocratique du Congo",
  "République Dominicaine",
  "République du Congo",
  "République Tchèque",
  "Réunion",
  "Roumanie",
  "Royaume-Uni",
  "Russie",
  "Rwanda",
  "Sahara Occidental",
  "Sainte-Hélène",
  "Sainte-Lucie",
  "Saint-Kitts-et-Nevis",
  "Saint-Marin",
  "Saint-Pierre-et-Miquelon",
  "Saint-Vincent-et-les Grenadines",
  "Salvador",
  "Samoa",
  "Samoa Américaines",
  "Sao Tomé-et-Principe",
  "Sénégal",
  "Serbie",
  "Seychelles",
  "Sierra Leone",
  "Singapour",
  "Slovaquie",
  "Slovénie",
  "Somalie",
  "Soudan",
  "Sri Lanka",
  "Suède",
  "Suisse",
  "Suriname",
  "Svalbard et Jan Mayen",
  "Swaziland",
  "Syrie",
  "Tadjikistan",
  "Taïwan",
  "Tanzanie",
  "Tchad",
  "Terres Australes Françaises",
  "Thaïlande",
  "Timor Oriental",
  "Togo",
  "Tonga",
  "Trinité-et-Tobago",
  "Tunisie",
  "Turkménistan",
  "Turquie",
  "Tuvalu",
  "Ukraine",
  "Uruguay",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Wallis et Futuna",
  "Yémen",
  "Zambie",
  "Zimbabwe",
];

export const personneConcerneList: string[] = [
  "Moi-même",
  "Un membre de ma famille ou une personne qui m'est étroitement liée",
];
