import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useEffect } from "react";
import {
  FieldValues,
  Path,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

interface Props<T extends FieldValues> {
  file: File;
  index: number;
  remove: (index?: number | number[] | undefined) => void;
  size: number;
}

const PreviewFiles = <T extends object>(
  props: Props<T> & { children?: ReactNode }
) => {
  return (
    <div className="mx-auto mt-3 px-3 w-full flex justify-between flex-row gap-2 items-center">
      {props.file && (
        <React.Fragment key={props.file.name}>
          {props.file.type.split("/")[1] === "pdf" ? (
            <FontAwesomeIcon icon={faFilePdf} className="scale-[2]" />
          ) : (
            <img
              src={URL.createObjectURL(props.file)}
              className="w-[150px] mt-10 mb-3"
              alt="..."
            />
          )}
          <div className="">
            <p className="text-sm">{props.file.name}</p>
            <p
              className={`text-bold text-sm font-semibold ${
                props.file.size / 1024 > props.size * 1024
                  ? "text-red-600"
                  : "text-green-500"
              }`}
            >
              {props.file.size / 1024 > props.size * 1024 ? (
                <>
                  La taille du document n'est pas valide (
                  {(props.file.size / 1024).toFixed(0)} ko)
                </>
              ) : (
                <>
                  La taille du document est valide (
                  {(props.file.size / 1024).toFixed(0)} ko)
                </>
              )}
            </p>
          </div>
          <div className="">
            <FontAwesomeIcon
              icon={faTrash}
              className="cursor-pointer text-gray-400"
              onClick={() => props.remove(props.index)}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PreviewFiles;
