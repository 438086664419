import { TypeDocumentProject } from '@api/api';
import axios from 'axios';

export interface UploadDocumentProjectDto {
    projectId: number;
    file: File;
    type: TypeDocumentProject;
    fileName: string;
    comment?: string;
    projectName: string;
    reportingId?: number;
}

export async function uploadDocumentProject(payload: UploadDocumentProjectDto) {
    try {
        const res1 = await axios({
            method: 'get',
            url: `${import.meta.env.VITE_API_URL}project/documents/url/put`,
            params: {
                fileType: encodeURIComponent(payload.file.type),
                projectName: payload.projectName,
                projectId: payload.projectId,
                type: payload.type,
            },
            withCredentials: true,
        });

        const { uploadUrl, key } = res1.data as any;

        const res2 = await axios.put(uploadUrl, payload.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}project/documents/post`,
            data: {
                projectId: payload.projectId,
                fileName: encodeURIComponent(payload.fileName),
                type: payload.type,
                comment: payload.comment,
                reportingId: payload?.reportingId,
                key: key,
            },
            withCredentials: true,
        });

        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}
