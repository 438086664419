import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useNotificationContext } from '../components/Context/notification-context';
import Login from '../components/SeConnecter/Login';
import Registration from '../components/SeConnecter/Registration';
import { PrimaryButton } from '../components/commun/Buttons';
import { SuccessIcon } from '../components/commun/IconsFeedBack';
import PopUp from '../components/commun/PopUp';
import logo from './../assets/logo.png';

const Seconnecter = ({ path }: { path?: string }) => {
    const [registerQuery, setRegisterQuery] = useQueryParam(
        'register',
        BooleanParam
    );
    const [messageRegister, setMessageRegister] = useState<boolean | null>(
        null
    );

    const [openMessageCreationCompte, setOpenMessageCreattionCompte] =
        useState(false);

    useEffect(() => {
        const test = document.querySelector('.choice_container') as HTMLElement;
        const p = test.querySelectorAll('p');
        if (registerQuery) {
            p[0].style.backgroundColor = 'white';
            p[0].style.zIndex = '0';

            p[1].style.backgroundColor = 'rgb(241, 240, 239)';
            p[1].style.zIndex = '10';
        } else {
            p[1].style.backgroundColor = 'white';
            p[1].style.zIndex = '0';
            p[0].style.zIndex = '10';
            p[0].style.backgroundColor = 'rgb(241, 240, 239)';
        }
    }, [registerQuery]);

    useEffect(() => {
        const e = document.querySelector(
            'message_temporaire_Container_sucessful'
        ) as HTMLElement;
        if (messageRegister) {
            setTimeout(() => {
                setMessageRegister(null);
            }, 4000);
        }
    }, [messageRegister]);

    const [sessionExpired, setSessionExpired] = useQueryParam(
        'sessionExpired',
        BooleanParam
    );

    const { showInfo } = useNotificationContext();

    useEffect(() => {
        if (sessionExpired) {
            showInfo(
                'Session expirée',
                'Votre session a expiré, veuillez vous reconnecter'
            );
        }
        setSessionExpired(undefined);
    }, [sessionExpired]);

    return (
        <React.Fragment>
            {openMessageCreationCompte ? (
                <MessageCompteCreated
                    open={openMessageCreationCompte}
                    setOpen={setOpenMessageCreattionCompte}
                />
            ) : null}
            {/* <Navigation bol_nosfonds={false} bol_transparent={false} /> */}
            <div className="flex flex-col items-center justify-start h-screen">
                <Link to="/" className="my-8 h-14 w-14 md:mt-20">
                    <img src={logo} alt="logo Newparadigms" />
                </Link>

                <div className="connexion_container">
                    <div className="choice_container">
                        <p
                            className="pointer"
                            onClick={() => setRegisterQuery(false)}
                            // onMouseOver={() => setRegister(false)}
                        >
                            Se connecter
                        </p>
                        <p
                            className="pointer"
                            onClick={() => setRegisterQuery(true)}
                            // onMouseOver={() => setRegister(true)}
                        >
                            S'inscrire
                        </p>
                    </div>
                    <div
                        className={`connexion w-full ${
                            registerQuery
                                ? 'sm:min-w-[400px] md:min-w-[700px]'
                                : 'sm:max-w-[350px]'
                        } `}
                    >
                        {registerQuery ? (
                            <Registration
                                setOpenMessageCreattionCompte={
                                    setOpenMessageCreattionCompte
                                }
                            />
                        ) : (
                            <Login
                                path={
                                    path || '/DashBoardClient/tableau-de-bord'
                                }
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* <Footer /> */}
        </React.Fragment>
    );
};

export default Seconnecter;

export function MessageCompteCreated({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            disabledOnclose={true}
            buttonBoolean={false}
        >
            <div className="">
                <div className="flex gap-3 justify-center items-center">
                    <h3 className=" font-semibold font-mainFontFamily text-mainColor text-2xl text-center">
                        Compte créé{' '}
                    </h3>{' '}
                    <SuccessIcon />
                </div>
                <p className=" mt-4">
                    Votre compte a bien été créé, vous allez recevoir un email
                    de validation. Vérifiez vos spams
                </p>
                <div className="my-4 flex justify-center items-center">
                    <PrimaryButton onClick={() => setOpen(false)}>
                        Fermer
                    </PrimaryButton>
                </div>
            </div>
        </PopUp>
    );
}
