import React, { useEffect } from "react";

const MentionsLegales = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="reglementation-page-container">
      <h1>Mentions légales</h1>

      <h2>1. New Paradigms</h2>
      <ul className="unset-dot">
        <li>
          <span>New paradigms -</span>{" "}
          <a href="http://www.newparadigms.com">http://www.newparadigms.com</a>
        </li>
        <li>Société en commandite spéciale (SCSp)</li>
        <li>
          <span>Capital :</span> x euros
        </li>
        <li>
          <span>Immatriculation :</span> B 251 823 Luxembourg
        </li>
        <li>
          <span>Adresse du siège social :</span> 5 rue Heienhaff, 1736
          Senningerberg, Luxembourg
        </li>
        <li>
          <span>Directeur de publication :</span> Antoine FLIPO
        </li>
        <li>
          <span>Crédit photos :</span>
          <a href="https://unsplash.com/images">Unsplash</a>
        </li>
      </ul>

      <h2>1. Hébergement</h2>

      <ul className="unset-dot">
        <li>
          Le site Web est hébergé par{" "}
          <a href="https://www.planethoster.com/">Planet Hoster</a>
        </li>
        <li>
          <span>Société :</span> PLANET HOSTER
        </li>
        <li>
          <span>Adresse web :</span> https://www.planethoster.com
        </li>
        <li>
          <span>Adresse Postale :</span>
          4416 Louis B. Mayer, Laval (Québec) H7P 0G1, Canada
        </li>
        <li>
          <span>Téléphone :</span> (Nº Vert): 0 805 080 426
        </li>
        <li>
          <span>E-Mail :</span> https://www.planethoster.com/fr/Contact
        </li>
      </ul>
    </div>
  );
};

export default MentionsLegales;
