import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { removeAttributes, transformDate } from '../../../function/Utils';
import {
    uploadDocumentFonds,
    UploadDocumentFondsDto,
    useCreateBankAccountMutation,
    useDeleteBankAccountMutation,
    useGetBankInfoByFondsIdQuery,
    useGetDocumentsByFondsIdQuery,
    useGetFondsByIdQuery,
    useUpdateBankAccountMutation,
    useUpdateFondsMutation,
} from '../../../redux/features/fondsSlice';

import {
    BankAccountFondsDto,
    CreateBankAccountFondsDto,
    TypeDocumentFonds,
    UpdateBankAccountFondsDto,
    UpdateFondsDto,
    useGetDashboardUserQuery,
    useGetProjectsSearchQuery,
} from '@api/api';

import { TabsMenu } from '@components/commun/HorizontalMenu';
import CheckboxComponent from '@components/commun/formComponent/CheckboxComponent';
import { useNotificationContext } from '../../Context/notification-context';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import useDeleteItem from '../../commun/CustomHook/useDeleteItem';
import DisplayDocumentFonds from '../../commun/DisplayDocuments/DisplayDocumentFonds';
import Loading from '../../commun/Loading';
import FieldArray from '../../commun/formComponent/FieldArray';
import InputComponent from '../../commun/formComponent/InputComponent';
import RemoveFieldArrayComponent from '../../commun/formComponent/RemoveFieldArrayComponent';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import TextAreaComponent from '../../commun/formComponent/TextAreaComponent';
import TextEditorComponent from '../../commun/formComponent/TextEditorComponent';
import UploaderSingle from '../../commun/formComponent/UploaderSingle';
import { IMenu } from '../UserManage/UserManage';
import DocumentFonds from './DocumentFonds';

const FondsAdmin = () => {
    const [queryFondsId, setQueryFondsId] = useQueryParam(
        'fondsId',
        NumberParam
    );
    const [queryPage, setQueryPage] = useQueryParam(
        'pageFondsEdit',
        StringParam
    );

    const menuList: IMenu[] = [
        {
            queryPage: 'basic-info',
            label: 'Basic info',
        },
        {
            queryPage: 'impact',
            label: 'Impact',
        },
        {
            queryPage: 'documents',
            label: 'Documents',
        },
        {
            queryPage: 'bank-account',
            label: 'Bank Account',
        },
    ];

    const selectPage = () => {
        switch (queryPage) {
            case 'basic-info':
                return <BasicInfo fondsId={queryFondsId || 0} />;
            case 'impact':
                return <Impact fondsId={queryFondsId || 0} />;
            case 'documents':
                return <DocumentFonds fondsId={queryFondsId || 0} />;
            case 'bank-account':
                return <BankAccount fondsId={queryFondsId || 0} />;
            default:
                return <BasicInfo fondsId={queryFondsId || 0} />;
                break;
        }
    };

    return (
        <div className="relative w-full">
            <div className="md:absolute top-2 right-2 flex gap-3">
                {/* <div className="">
          <PrimaryButton
            onClick={() => {
              setQueryParams("fonds");
              setProjectIdQueryParams(undefined);
            }}
          >
            Ajouter un projet
          </PrimaryButton>
        </div> */}
            </div>

            <div className="md:h-fit bg-backgroundColor w-full flex justify-center items-center mt-[-2px]   sticky top-[-2px] z-[99] mb-2 mx-auto">
                <TabsMenu
                    className="  "
                    items={menuList}
                    queryPage="pageFondsEdit"
                    onClick={() => {}}
                />
            </div>
            <div className="p-3 overflow_projectManage">{selectPage()}</div>
        </div>
    );
};

export default FondsAdmin;

function BankAccount({ fondsId }: { fondsId: number }) {
    const { data: BankAccount, isLoading: isLoadingBankAccount } =
        useGetBankInfoByFondsIdQuery(fondsId);

    return (
        <>
            {isLoadingBankAccount ? (
                <Loading />
            ) : (
                <>
                    {BankAccount ? (
                        <EditBankAccount bankAccount={BankAccount} />
                    ) : (
                        <AddBankAccount fondsId={fondsId} />
                    )}
                </>
            )}
        </>
    );
}

export interface FormBankAccountFondsDto extends CreateBankAccountFondsDto {
    documentRib: FileList;
}

export interface FormUpdateBankAccountFondsDto
    extends UpdateBankAccountFondsDto {
    documentRib: FileList;
}

function EditBankAccount({
    bankAccount,
}: {
    bankAccount: BankAccountFondsDto;
}) {
    const [EditBankAccount, { isLoading: isLoadingEditBankAccount }] =
        useUpdateBankAccountMutation();

    const [deleteBankAccount, { isLoading: isLoadingDelete }] =
        useDeleteBankAccountMutation();

    const { showError, showSuccess } = useNotificationContext();

    const {
        register,
        watch,
        reset,
        setValue,
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<FormUpdateBankAccountFondsDto>({
        defaultValues: {
            ...bankAccount,
        },
    });

    const {
        data: documents,
        refetch: refetchDocument,
        isLoading: isLoadingDocuments,
    } = useGetDocumentsByFondsIdQuery(bankAccount.fondsId ?? 0, {
        skip: bankAccount.fondsId === undefined,
    });

    const documentRib = documents?.find(
        (d) => d.type === TypeDocumentFonds.RIB
    );

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        documentId: number;
        userId: number;
    }>({
        deleteItem: () => deleteBankAccount(bankAccount.id),

        message: 'document',
        isLoading: isLoadingDelete,
    });

    const onSubmit = async (data: FormUpdateBankAccountFondsDto) => {
        if (data.iban) {
            data.iban = data.iban.replace(/\s+/g, '');
        }

        await EditBankAccount({
            bankAccountId: bankAccount.id,
            updateBankAccountFondsDto: {
                fondsId: bankAccount.fondsId,
                iban: data.iban,
                bic: data.bic,
                id: bankAccount.id,
                country: data.country,
                beneficiary: data.beneficiary,
            },
        })
            .unwrap()
            .then(async () => {
                const file = data.documentRib?.[0];
                if (file) {
                    const dataUpload: UploadDocumentFondsDto = {
                        fondsId: bankAccount.fondsId,
                        type: TypeDocumentFonds.RIB,
                        file: file,
                        fileName: file.name,
                        fondsName: bankAccount.fondsName,
                    };

                    await uploadDocumentFonds(dataUpload).then((res) => {
                        if (res.sucess) {
                        } else {
                            showError(
                                'Error',
                                `Erreur lors de l'envoie de ${dataUpload.fileName}`
                            );
                        }
                    });
                    await refetchDocument().catch((res) => {
                        showError(
                            'Error',
                            `Erreur lors de l'envoie de ${dataUpload.fileName}`
                        );
                    });
                }
                showSuccess('Edited', 'Bank account edited successfully');
            })
            .catch((err) => {
                showError(
                    'Error',
                    'Error editing Bank account',
                    err.data?.message
                );
            });
    };

    return (
        <>
            <DeleteComponent title="ce compte en bank" />

            <form
                onSubmit={handleSubmit(onSubmit)}
                className={`formTemplateSingUp`}
                style={{ width: `90%` }}
            >
                <div
                    className={`w-full mx-auto
          `}
                >
                    <TitleComponentForm className="relative">
                        BANK ACCOUNT
                        <div className="absolute right-3 top-1">
                            <FontAwesomeIcon
                                icon={faTrash}
                                className="text-secondColor cursor-pointer"
                                onClick={() => setOpenDelete(true)}
                            />
                        </div>
                    </TitleComponentForm>
                    <div className="grid md:grid-cols-2 mt-4 gap-x-2">
                        <InputComponent value={'iban'} register={register}>
                            <label htmlFor="iban" className=" font-semibold">
                                IBAN<span className=" text-red-500">*</span>
                            </label>
                        </InputComponent>
                        <InputComponent value={'bic'} register={register}>
                            <label htmlFor="iban" className=" font-semibold">
                                BIC<span className=" text-red-500">*</span>
                            </label>
                        </InputComponent>

                        <InputComponent value={'country'} register={register}>
                            <label htmlFor="iban" className=" font-semibold">
                                Pays<span className=" text-red-500">*</span>
                            </label>
                        </InputComponent>
                        <InputComponent
                            value={'beneficiary'}
                            register={register}
                        >
                            <label htmlFor="iban" className=" font-semibold">
                                Bénéficiaire
                                <span className=" text-red-500">*</span>
                            </label>
                        </InputComponent>
                    </div>
                    <>
                        <UploaderSingle
                            register={register}
                            value={'documentRib'}
                            watch={watch}
                            setValue={setValue}
                            button={false}
                            componentEnd={() => {
                                return (
                                    <p className="italic commentaireInput text-justify text-[0.8rem]">
                                        <span className="font-bold">
                                            Documents acceptés:
                                        </span>{' '}
                                        Documents recto et verso.
                                    </p>
                                );
                            }}
                        >
                            <h3 className="text-2xl mb-5">
                                Ajoutez un RIB{' '}
                                <span className=" text-red-500">*</span>
                            </h3>
                        </UploaderSingle>
                        {documentRib ? (
                            <DisplayDocumentFonds
                                document={documentRib}
                                refetch={refetchDocument}
                            />
                        ) : (
                            <p>Aucun document n'a été trouvé</p>
                        )}
                    </>
                    <div className="w-full mt-3 flex justify-center items-center">
                        <PrimaryButton
                            type="submit"
                            disabled={!isValid}
                            loading={isLoadingEditBankAccount}
                        >
                            Modifier
                        </PrimaryButton>
                    </div>
                </div>
            </form>
        </>
    );
}

function AddBankAccount({ fondsId }: { fondsId: number }) {
    const [AddBankAccount, { isLoading: isLoadingAddBankAccount }] =
        useCreateBankAccountMutation();

    const { showError, showSuccess } = useNotificationContext();

    const {
        register,
        watch,
        reset,
        handleSubmit,
        setValue,
        control,
        formState: { isValid },
    } = useForm<FormBankAccountFondsDto>({
        defaultValues: {
            fondsId: fondsId,
        },
    });

    const { data: fonds, isLoading: isLoadingFonds } = useGetFondsByIdQuery(
        fondsId ?? 0,
        {
            skip: fondsId === undefined,
        }
    );

    const {
        data: documents,
        refetch: refetchDocument,
        isLoading: isLoadingDocuments,
    } = useGetDocumentsByFondsIdQuery(fondsId ?? 0, {
        skip: fondsId === undefined,
    });

    const onSubmit = async (data: FormBankAccountFondsDto) => {
        data.iban = data.iban.replace(/\s+/g, '');
        let error = true;
        await AddBankAccount({
            fondsId: fondsId,
            iban: data.iban,
            country: data.country,
            beneficiary: data.beneficiary,
            bic: data.bic,
        })
            .unwrap()
            .then(async () => {
                const file = data.documentRib?.[0];
                if (file) {
                    const dataUpload: UploadDocumentFondsDto = {
                        fondsId: fondsId,
                        type: TypeDocumentFonds.RIB,
                        file: file,
                        fileName: file.name,
                        fondsName: fonds?.name!,
                    };
                    error = false;

                    await uploadDocumentFonds(dataUpload).then((res) => {
                        if (res.sucess) {
                        } else {
                            showError(
                                'Erreur',
                                `Erreur lors de l'envoie de ${dataUpload.fileName}`
                            );
                        }
                    });
                    await refetchDocument().catch((res) => {
                        showError(
                            'Erreur',
                            `Erreur lors de l'envoie de ${dataUpload.fileName}`
                        );
                    });
                }
                showSuccess('Edited', 'Bank account created successfully');
            })
            .catch((err) => {
                if (error === true) {
                    showError(
                        'Erreur',
                        `Erreur while creating bank account`,
                        err.data?.message
                    );
                } else {
                    showSuccess('Edited', 'Bank account created successfully');
                    // reload the page
                    window.location.reload();
                }
            });
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={`formTemplateSingUp`}
            style={{ width: `90%` }}
        >
            <div
                className={`w-full mx-auto
        `}
            >
                <TitleComponentForm>BANK ACCOUNT</TitleComponentForm>
                <div className="grid md:grid-cols-2 mt-4 gap-x-2">
                    <InputComponent value={'iban'} register={register}>
                        <label htmlFor="iban" className=" font-semibold">
                            IBAN<span className=" text-red-500">*</span>
                        </label>
                    </InputComponent>
                    <InputComponent value={'bic'} register={register}>
                        <label htmlFor="iban" className=" font-semibold">
                            BIC<span className=" text-red-500">*</span>
                        </label>
                    </InputComponent>
                    <InputComponent value={'country'} register={register}>
                        <label htmlFor="iban" className=" font-semibold">
                            Pays<span className=" text-red-500">*</span>
                        </label>
                    </InputComponent>
                    <InputComponent value={'beneficiary'} register={register}>
                        <label htmlFor="iban" className=" font-semibold">
                            Bénéficiaire<span className=" text-red-500">*</span>
                        </label>
                    </InputComponent>
                </div>
                <>
                    <UploaderSingle
                        register={register}
                        value={'documentRib'}
                        watch={watch}
                        setValue={setValue}
                        button={false}
                        componentEnd={() => {
                            return (
                                <p className="italic commentaireInput text-justify text-[0.8rem]">
                                    <span className="font-bold">
                                        Documents acceptés:
                                    </span>{' '}
                                    Documents recto et verso.
                                </p>
                            );
                        }}
                    >
                        <h3 className="text-2xl mb-5">
                            Ajoutez un RIB{' '}
                            <span className=" text-red-500">*</span>
                        </h3>
                    </UploaderSingle>
                </>

                <div className="w-full mt-3 flex justify-center items-center">
                    <PrimaryButton
                        type="submit"
                        disabled={
                            !isValid || !(watch('documentRib')?.length > 0)
                        }
                        loading={isLoadingAddBankAccount}
                    >
                        Ajouter
                    </PrimaryButton>
                </div>
            </div>
        </form>
    );
}

function Impact({ fondsId }: { fondsId: number }) {
    return <></>;
}

function BasicInfo({ fondsId }: { fondsId: number }) {
    const {
        register,
        watch,
        control,
        getValues,
        setValue,
        handleSubmit,
        reset,
        formState: { isValid },
    } = useForm<UpdateFondsDto>();

    const [editFonds, { isLoading: isLoadingEditFonds }] =
        useUpdateFondsMutation();

    const { showError, showSuccess } = useNotificationContext();

    const {
        field: { onChange: onChangeComment, value: comment },
    } = useController<UpdateFondsDto>({
        name: 'description',
        control: control,
    });

    const navigate = useNavigate();

    const { data: fonds, isLoading: isLoadingFonds } = useGetFondsByIdQuery(
        fondsId ?? 0,
        {
            skip: fondsId === undefined,
        }
    );

    const [searchProject, setSearchProject] = useState('');

    const { data: projects, isLoading: isLoadingProject } =
        useGetProjectsSearchQuery(
            {
                fondsId: fondsId,
                search: searchProject,
            },
            {
                skip: fondsId === undefined,
            }
        );

    useEffect(() => {
        if (fonds) {
            const perfSort = [...(fonds.performances || [])];

            reset({
                ...fonds,
                show: fonds.show,
                dateCreation: fonds?.dateCreation
                    ? (new Date(fonds.dateCreation)
                          ?.toISOString()
                          .split('T')[0] as any)
                    : (new Date()?.toISOString().split('T')[0] as any),
                performances: perfSort
                    ?.sort(
                        (a, b) =>
                            new Date(a.date).getTime() -
                            new Date(b.date).getTime()
                    )
                    .map((perf) => {
                        return {
                            ...perf,
                            date: new Date(perf.date)
                                .toJSON()
                                .split('T')[0] as any,
                            value: perf.value,
                            fondsId: perf.fondsId,
                        };
                    }),
            });
        }
    }, [fonds]);

    const { refetch } = useGetDashboardUserQuery();

    const onSubmit = async (data: UpdateFondsDto) => {
        if (data.dateCreation) {
            data.dateCreation = data.dateCreation
                ? new Date(data.dateCreation)?.toISOString()
                : undefined;
        }

        if (data.performances) {
            data.performances = data?.performances?.map((perf, index) => {
                if (index === 0) {
                    return {
                        ...perf,
                        date: transformDate(
                            new Date(data?.dateCreation || perf.date)
                        )?.toISOString(),
                        value: perf.value,
                        fondsId: perf.fondsId,
                    };
                }
                return {
                    ...perf,
                    date: transformDate(new Date(perf.date))?.toISOString(),
                    value: perf.value,
                    fondsId: perf.fondsId,
                };
            });
        }

        // remove performance and platforms attributes
        data = removeAttributes(data, ['platforms', 'performance']);

        await editFonds({
            updateFondsDto: data,
            id: fonds?.id!,
        })
            .unwrap()
            .then(async () => {
                showSuccess('Edited', 'Fonds edited successfully');
                await refetch();
            })
            .catch((err) => {
                showError('Error', 'Error editing Fonds', err.data?.message);
            });
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={`formTemplateSingUp`}
            style={{ width: `90%` }}
        >
            <div
                className={`w-full mx-auto
            `}
            >
                <TitleComponentForm>GENERAL INFORMATION</TitleComponentForm>
                <div className="grid md:grid-cols-2 md:gap-x-2">
                    <div className=" flex flex-col gap-2">
                        <InputComponent register={register} value={'name'}>
                            <LabelComponentForm>
                                Nom du fonds{' '}
                                <span className="text-red-500">*</span>
                            </LabelComponentForm>
                        </InputComponent>

                        <CheckboxComponent
                            watch={watch}
                            register={register}
                            values={[
                                {
                                    name: 'show',
                                    label: 'Fonds investisable',
                                },
                            ]}
                        />
                    </div>

                    <FieldArray
                        className=""
                        control={control}
                        name="performances"
                    >
                        {({ fields, append, remove }) => (
                            <>
                                <LabelComponentForm>
                                    Objectif de performance du fonds
                                </LabelComponentForm>
                                <div className="flex flex-col w-full space-y-2 mb-2">
                                    {fields.map((field, index) => (
                                        <div
                                            key={field.id}
                                            className={`grid ${
                                                index > 0
                                                    ? 'grid-cols-[1fr,1fr,auto]'
                                                    : 'grid-cols-2'
                                            }  w-full gap-x-2 relative`}
                                        >
                                            <InputComponent
                                                register={register}
                                                type="date"
                                                value={`performances.${index}.date`}
                                                cursorNotAllowed={
                                                    index === 0
                                                        ? true
                                                        : undefined
                                                }
                                                // className={{ input: "row-span-1 col-span-1" }}
                                            >
                                                <h3 className="text-left">
                                                    Date{' '}
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </h3>
                                            </InputComponent>

                                            <InputComponent
                                                register={register}
                                                type="number"
                                                value={`performances.${index}.value`}
                                                // className={{ input: "row-span-1 col-span-1" }}
                                            >
                                                <h3 className="text-left">
                                                    Intérêt{' '}
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </h3>
                                            </InputComponent>
                                            {index > 0 ? (
                                                <RemoveFieldArrayComponent
                                                    remove={remove}
                                                    index={index}
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                                <div className=" w-full flex justify-center items-center">
                                    <PrimaryButton
                                        className=" mx-auto mb-2"
                                        onClick={() =>
                                            append({
                                                date: new Date()
                                                    .toJSON()
                                                    ?.split('T')[0] as any,
                                                value: undefined,
                                                fondsId: fonds?.id!,
                                            } as any)
                                        }
                                    >
                                        Ajouter
                                    </PrimaryButton>
                                </div>
                            </>
                        )}
                    </FieldArray>
                </div>
                <InputComponent
                    register={register}
                    value={'dateCreation'}
                    type={'date'}
                >
                    <LabelComponentForm>
                        Date de création <span className="text-red-500">*</span>
                    </LabelComponentForm>
                </InputComponent>

                <TextAreaComponent
                    register={register}
                    required={false}
                    value={'overviewInfo.subTitle'}
                >
                    <LabelComponentForm>
                        Court sous titre pour Fonds user page
                    </LabelComponentForm>
                </TextAreaComponent>
                <TextAreaComponent
                    register={register}
                    required={false}
                    value={'overviewInfo.text'}
                >
                    <LabelComponentForm>
                        Court text pour Fonds user page
                    </LabelComponentForm>
                </TextAreaComponent>
                <SelectComponent
                    register={register}
                    required={false}
                    value={'overviewInfo.monthProjectId'}
                    setSearch={setSearchProject}
                    container={true}
                    optionValues={
                        projects?.map((p) => ({
                            value: p.id,
                            label: p.name,
                        })) || []
                    }
                    control={control}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                >
                    <LabelComponentForm>Projet du mois</LabelComponentForm>
                </SelectComponent>

                <TextEditorComponent
                    content={comment as any as string}
                    setContent={onChangeComment}
                >
                    <LabelComponentForm>Description</LabelComponentForm>
                </TextEditorComponent>
                {/* <DisplayTextEditor content={comment as any as string} /> */}
            </div>
            <div className="w-full mt-3 mb-2 flex justify-center gap-3 items-center">
                <WhiteButton onClick={() => navigate('/DashBoardAdmin/Fonds')}>
                    Annuler
                </WhiteButton>
                <PrimaryButton
                    type="submit"
                    loading={isLoadingEditFonds}
                    disabled={!isValid ? true : false}
                >
                    Valider
                </PrimaryButton>
            </div>
        </form>
    );
}
