import {
    CreateDocumentBeneficialOwnerDto,
    CreateDocumentInvestAccountDto,
    CreateDocumentMoralAccountDto,
    CreateDocumentSubscriptionDto,
    CreateDocumentUserDto,
} from '@api/api';
import axios from 'axios';

export interface FormCreateDocumentUserRequest extends CreateDocumentUserDto {
    file: File;
}

export interface FormCreateDocumentInvestAccountRequest
    extends CreateDocumentInvestAccountDto {
    file: File;
    userId: number;
}

export interface FormCreateDocumentMoralAccountRequest
    extends CreateDocumentMoralAccountDto {
    file: File;
    userId: number;
}

export interface FormCreateDocumentBeneficialOwnerRequest
    extends CreateDocumentBeneficialOwnerDto {
    file: File;
    userId: number;
}

export async function uploadDocumentUser(
    request: FormCreateDocumentUserRequest
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}documentUser/url/put/${request.userId}`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    userId: request.userId,
                    name: request.name,
                    type: request.type,
                    side: request.side,
                    fileName: encodeURIComponent(request.fileName),
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}documentUser/document/${request.userId}`,
            data: {
                userId: request.userId,
                name: request.name,
                type: request.type,
                side: request.side,
                fileName: encodeURIComponent(request.fileName),
                key: key,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}

// upload document invest account
export async function uploadDocumentInvestAccount(
    request: FormCreateDocumentInvestAccountRequest
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}documentInvestAccount/url/put/investAccount/${request.userId}`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    investAccountId: request.investAccountId,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}documentInvestAccount/investAccount/${request.userId}`,
            data: {
                investAccountId: request.investAccountId,
                fileName: encodeURIComponent(request.fileName),
                iban: request.iban,
                bic: request.bic,
                key: key,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}

// upload document moral account
export async function uploadDocumentMoralAccount(
    request: FormCreateDocumentMoralAccountRequest
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}documentInvestAccount/url/put/moralAccount/${request.userId}`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    moralAccountId: request.moralAccountId,
                    type: request.type,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}documentInvestAccount/moralAccount/${request.userId}`,
            data: {
                moralAccountId: request.moralAccountId,
                fileName: encodeURIComponent(request.fileName),
                key: key,
                type: request.type,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}

// upload document beneficial owner
export async function uploadDocumentBeneficialOwner(
    request: FormCreateDocumentBeneficialOwnerRequest
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}documentInvestAccount/url/put/beneficialOwner/${request.userId}`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    beneficialOwnerId: request.beneficialOwnerId,
                    name: request.name,
                    side: request.side,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}documentInvestAccount/beneficialOwner/${request.userId}`,
            data: {
                beneficialOwnerId: request.beneficialOwnerId,
                fileName: encodeURIComponent(request.fileName),
                key: key,
                name: request.name,
                side: request.side,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}

export interface FormUploadSubscriptionDto
    extends CreateDocumentSubscriptionDto {
    file: File;
    userId: number;
}

// upload subscriptionContract
export async function uploadSubscriptionContract(
    request: FormUploadSubscriptionDto
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}documentUser/subscription/url/put/${request.userId}`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    subscriptionUserFondsId: request.subscriptionUserFondsId,
                    type: request.type,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}documentUser/subscription/${request.userId}`,
            data: {
                subscriptionUserFondsId: request.subscriptionUserFondsId,
                fileName: encodeURIComponent(request.fileName),
                key: key,
                type: request.type,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}
