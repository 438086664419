import { createSlice } from '@reduxjs/toolkit';
import Page from '../../function/page_info';

interface pageState {
    measurements: pageMeasurement;
}

export interface pageHeader {
    coverBackground: string;
    coverImg: string;
    classN: string;
    h1_: string;
    h2_?: string;
}

interface pageMeasurement {
    widthScreen: number;
    heightScreen: number;
    scrollPage: number;
}

const initialState: pageState = {
    measurements: {
        widthScreen: Page.getXmax(),
        heightScreen: Page.getYmax(),
        scrollPage: Page.getScrollPage(),
    },
};

const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        getMeasures: (state) => {
            state.measurements = {
                widthScreen: Page.getXmax(),
                heightScreen: Page.getYmax(),
                scrollPage: Page.getScrollPage(),
            };
        },
    },
});

export const { getMeasures } = pageSlice.actions;

export default pageSlice.reducer;
