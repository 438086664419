import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useCallback } from 'react';
import { debounce } from '../../../../function/Utils';
import { typeFiltration } from '../../../../types/BackOffice';

export interface filtrationInterface<T> {
    element: keyof T;
    type: typeFiltration;
    value?: string | number | boolean;
    orderOrFiltration: typeOrderOrFiltration;
}

export enum typeOrderOrFiltration {
    filter = 'filter',
    order = 'order',
}

interface Props<T> {
    element: keyof T;
    type: typeFiltration;
    value?: string | number | boolean | string[] | number[];
    columnName: () => JSX.Element;
    useFilter?: boolean;
    setFilterColumn: React.Dispatch<
        React.SetStateAction<filtrationInterface<T>[]>
    >;
    orderOrFiltration: typeOrderOrFiltration;
    textLeft: boolean;
    optionValues?: { label: string; value: string | number }[];
    setSearch?: React.Dispatch<React.SetStateAction<any>>;
    search?: any;
}

const FiltrationColumnTable = <T extends object>(
    props: Props<T> & { children?: ReactNode }
) => {
    const classNameObejct = {
        head: `text-sm text-gray-900 px-6 py-4  font-semibold ${
            props.textLeft ? 'text-left' : 'text-right'
        }`,
    };

    const [croissant, setCroissant] = React.useState<boolean>(false);

    const [valueSelect, setValueSelect] = React.useState<string | number>(
        props?.search ?? ''
    );

    const updateSearch = useCallback(
        debounce((value: string) => {
            if (props?.setSearch) {
                if (value === 'all') props?.setSearch('');
                else props?.setSearch(value);
            }
        }, 500),
        []
    );

    const handleChange = (
        e?: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
    ) => {
        updateSearch(e?.target.value);
        props.setFilterColumn((current) => {
            if (props.orderOrFiltration === typeOrderOrFiltration.order) {
                return current.map((val) => {
                    if (val.orderOrFiltration === props.orderOrFiltration) {
                        return {
                            ...val,
                            type: props.type,
                            element: props.element,
                            columnName: props.columnName,
                            value:
                                props.type === typeFiltration.name ||
                                props.type === typeFiltration.exact
                                    ? e?.target.value
                                    : !croissant
                                      ? 'croissant'
                                      : 'decroissant',
                        };
                    } else {
                        return val;
                    }
                });
            } else if (props.useFilter) {
                if (
                    current.filter((val) => val.element === props.element)
                        .length === 0
                ) {
                    return current.concat([
                        {
                            element: props.element,
                            type: props.type,
                            orderOrFiltration: props.orderOrFiltration,
                            value:
                                props.type !== typeFiltration.date &&
                                props.type !== typeFiltration.amount
                                    ? e?.target.value
                                    : !croissant
                                      ? 'croissant'
                                      : 'decroissant',
                        },
                    ]);
                } else {
                    return current.map((val) => {
                        if (val.element === props.element) {
                            return {
                                ...val,
                                type: props.type,
                                value:
                                    props.type !== typeFiltration.date &&
                                    props.type !== typeFiltration.amount
                                        ? e?.target.value
                                        : !croissant
                                          ? 'croissant'
                                          : 'decroissant',
                            };
                        } else {
                            return val;
                        }
                    });
                }
            } else {
                return current;
            }
        });
    };

    return (
        <th
            scope="col"
            className={`cursor-pointer mx-auto px-3 w-fit relative ${classNameObejct.head} `}
            onClick={() => {
                if (props.orderOrFiltration === typeOrderOrFiltration.order) {
                    handleChange();
                    setCroissant((current) => !current);
                }
            }}
        >
            {props.value &&
            typeof props.value !== 'string' &&
            typeof props.value !== 'number' ? (
                <select
                    className="text-center cursor-pointer border rounded-md p-1 outline-none mr-2"
                    onChange={(e) => {
                        setValueSelect(e.target.value);
                        handleChange(e);
                    }}
                    value={valueSelect}
                >
                    {(props.value as any[]).map((value, index) => (
                        <option key={index} value={value}>
                            {value}
                        </option>
                    ))}
                </select>
            ) : null}
            {props.optionValues && (
                <select
                    className="text-center cursor-pointer border rounded-md p-1 outline-none mr-2"
                    onChange={(e) => {
                        setValueSelect(e.target.value);
                        handleChange(e);
                    }}
                    value={valueSelect}
                >
                    {props.optionValues!.map((value, index) => (
                        <option key={index} value={value.value}>
                            {value.label}
                        </option>
                    ))}
                </select>
            )}

            {props.orderOrFiltration === typeOrderOrFiltration.order ? (
                <>
                    <div className="flex items-center gap-2 justify-center">
                        {props.columnName()}
                        <FontAwesomeIcon
                            icon={croissant ? faArrowDown : faArrowUp}
                            className=" "
                        />{' '}
                    </div>
                </>
            ) : null}

            {props.textLeft ? (
                <React.Fragment>
                    {props.orderOrFiltration !== typeOrderOrFiltration.order
                        ? props.columnName()
                        : null}{' '}
                    {props.type === typeFiltration.name ||
                    props.type === typeFiltration.nameWithId ||
                    props.type === typeFiltration.nameWithIdTransaction ||
                    props.type === typeFiltration.firstName_Surname ? (
                        <input
                            type="input"
                            className="ml-3 w-[7rem] !text-gray-600 !font-light italic"
                            value={
                                valueSelect === 'all' ? undefined : valueSelect
                            }
                            placeholder="rechercher..."
                            onChange={(e) => {
                                setValueSelect(e.target.value);
                                handleChange(e);
                            }}
                        />
                    ) : null}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {props.type === typeFiltration.name ||
                    props.type === typeFiltration.nameWithId ||
                    props.type === typeFiltration.nameWithIdTransaction ||
                    props.type === typeFiltration.firstName_Surname ? (
                        <input
                            type="input"
                            className="ml-3 w-[7rem] !text-gray-600 !font-light italic"
                            value={
                                valueSelect === 'all' ? undefined : valueSelect
                            }
                            placeholder="rechercher..."
                            onChange={(e) => {
                                setValueSelect(e.target.value);
                                handleChange(e);
                            }}
                        />
                    ) : null}
                    {props.orderOrFiltration !== typeOrderOrFiltration.order
                        ? props.columnName()
                        : null}{' '}
                </React.Fragment>
            )}
        </th>
    );
};

export default FiltrationColumnTable;
