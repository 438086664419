import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import {
    debounce,
    formatDate,
    getInterest,
    setDate,
    stringToFloat,
    stringToInt,
    transformDate,
} from '../../../../function/Utils';

import {
    UpdateBlackListDto,
    useDeleteBlacklistByIdMutation,
    useGetBlackListByIdQuery,
    useGetBlackListsByFondsIdQuery,
    useUpdateBlacklistMutation,
} from '@api/api';
import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import { displayHideLongText } from '@components/DashboardAdmin/ProjectManage/Suivi/Suivis';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import useDeleteItem from '../../../commun/CustomHook/useDeleteItem';
import FieldArray from '../../../commun/formComponent/FieldArray';
import InputComponent from '../../../commun/formComponent/InputComponent';
import RemoveFieldArrayComponent from '../../../commun/formComponent/RemoveFieldArrayComponent';
import TextAreaComponent from '../../../commun/formComponent/TextAreaComponent';
import Loading from '../../../commun/Loading';
import PopUp from '../../../commun/PopUp';
import { BackOfficeState } from '../../../Context/AppContext';
import { useNotificationContext } from '../../../Context/notification-context';
import TableComponent from '../Components/TableComponent';

const ViewTableBlackList = () => {
    const backOfficeState = useContext(BackOfficeState);
    const navigate = useNavigate();

    const { data: blackListData, isLoading: isLoadingBlackList } =
        useGetBlackListsByFondsIdQuery(backOfficeState?.fondsId ?? 0, {
            skip: backOfficeState?.fondsId === undefined,
        });

    const [editToggle, setEditToggle] = useState<boolean>(false);

    const [queryParam, setQueryParam] = useQueryParam(
        'blacklistId',
        StringParam
    );

    const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
    const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap text-left',
    };

    const [dateChosen, setDateChosen] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if (
            backOfficeState?.day &&
            backOfficeState?.year &&
            backOfficeState?.month !== undefined
        ) {
            setDateChosen(
                setDate(
                    backOfficeState?.year!,
                    backOfficeState?.month!,
                    backOfficeState?.day!
                )
            );
        }
    }, [
        backOfficeState?.day && backOfficeState?.day,
        backOfficeState?.year && backOfficeState?.year,
        backOfficeState?.month && backOfficeState?.month,
    ]);

    return (
        <React.Fragment>
            {editToggle ? (
                <EditBlackList
                    setEditToggle={setEditToggle}
                    editToggle={editToggle}
                />
            ) : null}
            <TableComponent
                head={[
                    '#',
                    'Projet',
                    'Interêt',
                    'Date',
                    'Date deleted',
                    'commentaire',
                ]}
                array={blackListData?.filter(
                    (val) => val.fondsId === backOfficeState?.fondsId
                )}
                startIntervalNbItems={startIntervalNbItems}
                setNbItemsShowed={setNbItemsShowed}
                nbItemsShowed={nbItemsShowed}
                setStartIntervalNbItems={setStartIntervalNbItems}
            >
                <tbody>
                    {isLoadingBlackList ? (
                        <tr className="">
                            <td
                                colSpan={6}
                                className="pt-10 text-center mx-auto"
                            >
                                <Loading />
                            </td>
                        </tr>
                    ) : (
                        <>
                            {!!(
                                backOfficeState?.fondsId !== undefined &&
                                dateChosen
                            ) &&
                                blackListData && (
                                    <React.Fragment>
                                        {blackListData.length === 0 ? (
                                            <tr className="w-full mx-auto">
                                                <td
                                                    colSpan={6}
                                                    className="text-center text-gray-500 text-xl  pt-10"
                                                >
                                                    Aucune blackList touvée
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {blackListData?.map(
                                                    (item, key) => (
                                                        <tr
                                                            onClick={() => {
                                                                if (item.id) {
                                                                    setQueryParam(
                                                                        item.id?.toString()
                                                                    );
                                                                    setEditToggle(
                                                                        true
                                                                    );
                                                                }
                                                            }}
                                                            key={key}
                                                            className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                                                                key % 2 === 0
                                                                    ? 'bg-gray-100'
                                                                    : 'bg-white'
                                                            } `}
                                                        >
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                {key + 1}
                                                            </td>
                                                            <td
                                                                className={
                                                                    classNameObejct.item
                                                                }
                                                            >
                                                                {
                                                                    item.projectName
                                                                }
                                                            </td>
                                                            <td
                                                                className={
                                                                    classNameObejct.item
                                                                }
                                                            >
                                                                {getInterest(
                                                                    item.interests,
                                                                    dateChosen
                                                                )}{' '}
                                                                %
                                                            </td>
                                                            <td
                                                                className={
                                                                    classNameObejct.item
                                                                }
                                                            >
                                                                {formatDate(
                                                                    new Date(
                                                                        item.date
                                                                    )
                                                                )}
                                                            </td>
                                                            <td
                                                                className={
                                                                    classNameObejct.item
                                                                }
                                                            >
                                                                {item?.deletedAt
                                                                    ? formatDate(
                                                                          new Date(
                                                                              item?.deletedAt
                                                                          )
                                                                      )
                                                                    : ''}
                                                            </td>
                                                            <td
                                                                className={
                                                                    classNameObejct.item
                                                                }
                                                            >
                                                                {displayHideLongText(
                                                                    'Pas de commentaire',
                                                                    item?.comment ||
                                                                        '',
                                                                    100
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </React.Fragment>
                                )}
                        </>
                    )}
                </tbody>
            </TableComponent>
        </React.Fragment>
    );
};

export default ViewTableBlackList;

function EditBlackList({
    setEditToggle,
    editToggle,
}: {
    setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
    editToggle: boolean;
}) {
    const backOfficeState = useContext(BackOfficeState);

    const { showError, showSuccess } = useNotificationContext();

    const [queryParam, setQueryParam] = useQueryParam(
        'blacklistId',
        StringParam
    );
    const blacklistId: number | undefined = queryParam
        ? parseInt(queryParam)
        : undefined;

    // const projectsData = useAppSelector((state) => state.projetReducer.projets);

    const [search, setSearch] = useState('');

    const { data: blackListData } = useGetBlackListByIdQuery(blacklistId ?? 0, {
        skip: blacklistId === undefined,
    });

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        control,
    } = useForm<UpdateBlackListDto>({});

    useEffect(() => {
        if (blackListData) {
            reset({
                fondsId: blackListData?.fondsId,
                id: blackListData?.id,
                projectId: blackListData?.projectId,
                comment: blackListData?.comment,
                dates:
                    blackListData?.dates.map((dateb) => {
                        return {
                            date: transformDate(new Date(dateb.date).getTime())
                                .toJSON()
                                .split('T')[0] as any,
                            deletedAt: dateb.deletedAt
                                ? (transformDate(
                                      new Date(dateb.deletedAt).getTime()
                                  )
                                      .toJSON()
                                      .split('T')[0] as any)
                                : undefined,
                        };
                    }) ?? [],
                interests:
                    blackListData?.interests.map((pi) => {
                        return {
                            ...pi,
                            date: new Date(pi.date).toJSON()?.split('T')[0],
                        };
                    }) ?? [],
            });
        }
    }, [blackListData, queryParam]);

    const [editBlackList, { isLoading }] = useUpdateBlacklistMutation();

    const [deleteBlackList, { isLoading: isLoadingDelete }] =
        useDeleteBlacklistByIdMutation();

    const onSubmit = async (data: UpdateBlackListDto) => {
        editBlackList({
            ...data,
            comment: data.comment ?? undefined,
            fondsId: data.fondsId,
            dates: data.dates.map((dateb) => {
                return {
                    date: transformDate(dateb.date)?.toISOString(),
                    deletedAt: dateb.deletedAt
                        ? transformDate(dateb.deletedAt)?.toISOString()
                        : undefined,
                };
            }),
            projectId: data.projectId,
            interests: data.interests.map((pi) => {
                return {
                    id: pi.id ?? undefined,
                    value: stringToFloat(pi.value),
                    fondsId: stringToInt(data.fondsId),
                    blackListId: stringToInt(data.id),
                    date: transformDate(pi.date)?.toISOString(),
                };
            }),
        })
            .unwrap()
            .then((res) => {
                setEditToggle(false);
                showSuccess(
                    'Edited',
                    'Project in blackList editing successfully'
                );
            })
            .catch((err: any) => {
                showError(
                    'Error',
                    'Error editing project in blacklist',
                    err.data?.message
                );
            });
    };

    const updateDateEnd = useCallback(
        debounce(() => {
            if (getValues(`dates.0.date`) != null) {
                setValue(
                    `interests`,
                    getValues(`interests`).map((val, index) => {
                        if (index === 0) {
                            return {
                                ...val,
                                date: transformDate(
                                    new Date(getValues(`dates.0.date`))
                                )
                                    .toJSON()
                                    .split('T')[0],
                            };
                        } else {
                            return val;
                        }
                    })
                );
            }
        }, 500),
        []
    );

    useEffect(() => {
        updateDateEnd();
    }, [watch(`dates.0.date`)]);

    const { setOpen, DeleteComponent } = useDeleteItem<{
        projectId: number;
        blaclListId: number;
    }>({
        deleteItem: () =>
            deleteBlackList({
                blackListId: blackListData?.id!,
                projectId: blackListData?.projectId!,
            }),
        toDoIfSuccess: () => setEditToggle(false),
        message: 'Project in blackList',
        defaultValues: {
            blackListId: blackListData?.id,
            projectId: blackListData?.projectId,
        },
        isLoading: isLoadingDelete,
    });

    return (
        <PopUp
            open={editToggle}
            setOpen={setEditToggle}
            buttonBoolean={false}
            submitItemName={`Editer`}
            onClickSubmit={() => {
                handleSubmit(onSubmit);
            }}
            title={() => {
                return (
                    <div className="relative">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Editer ce projet en blacklist
                        </h3>
                        {blackListData && blackListData?.id ? (
                            <FontAwesomeIcon
                                onClick={() => setOpen(true)}
                                icon={faTrash}
                                className="absolute top-8 md:top-0 cursor-pointer right-4 transition-all hover:scale-105"
                            />
                        ) : null}
                    </div>
                );
            }}
        >
            <DeleteComponent title="ce projet en blackList">
                {/* <InputComponent register={registerDelete} value={"date"} type={"date"}>
          <h3>Date</h3>
        </InputComponent> */}
            </DeleteComponent>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
            >
                <div className="grid mb-4 grid-cols-2  items-center">
                    <LabelComponentForm>Projec Name:</LabelComponentForm>
                    <p className="mt-2 mb-1 font-semibold">
                        {blackListData?.projectName}
                    </p>
                </div>
                <FieldArray control={control} className="w-full" name="dates">
                    {({ fields, append, remove }) => (
                        <>
                            <div className="flex flex-col w-full space-y-2 mb-2">
                                {fields.map((field, index) => (
                                    <div
                                        key={field.id}
                                        className={`grid ${
                                            index > 0
                                                ? 'grid-cols-[1fr,1fr,auto]'
                                                : 'grid-cols-2'
                                        }  w-full gap-x-2 relative`}
                                    >
                                        <InputComponent
                                            register={register}
                                            type="date"
                                            value={`dates.${index}.date`}

                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Date{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>

                                        <InputComponent
                                            register={register}
                                            type="date"
                                            required={false}
                                            value={`dates.${index}.deletedAt`}
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                DeletedAt
                                            </h3>
                                        </InputComponent>
                                        {index > 0 ? (
                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                            <PrimaryButton
                                className=" mx-auto mb-2"
                                onClick={() =>
                                    append({
                                        date: new Date()
                                            .toJSON()
                                            ?.split('T')[0],
                                        deletedAt: undefined,
                                        // projectId: project.current?.id!,
                                    } as any)
                                }
                            >
                                Ajouter
                            </PrimaryButton>
                        </>
                    )}
                </FieldArray>
                <h3 className="text-center my-2 mx-auto">
                    List des intérêt en fonction de la date
                </h3>
                <FieldArray control={control} name="interests">
                    {({ fields, append, remove }) => (
                        <>
                            <div className="flex flex-col w-full space-y-2 mb-2">
                                {fields.map((field, index) => (
                                    <div
                                        key={field.id}
                                        className={`grid ${
                                            index > 0
                                                ? 'grid-cols-[1fr,1fr,auto]'
                                                : 'grid-cols-2'
                                        }  w-full gap-x-2 relative`}
                                    >
                                        <InputComponent
                                            register={register}
                                            type="date"
                                            value={`interests.${index}.date`}
                                            cursorNotAllowed={
                                                index === 0 ? true : undefined
                                            }
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Date{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>

                                        <InputComponent
                                            register={register}
                                            type="number"
                                            value={`interests.${index}.value`}
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Intérêt{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>
                                        {index > 0 ? (
                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                            <PrimaryButton
                                className=" mx-auto mb-2"
                                onClick={() =>
                                    append({
                                        date: new Date()
                                            .toJSON()
                                            ?.split('T')[0],
                                        value: 0,
                                        fondsId: blackListData?.fondsId!,
                                        blackListId: blackListData?.id!,
                                    })
                                }
                            >
                                Ajouter
                            </PrimaryButton>
                        </>
                    )}
                </FieldArray>

                <TextAreaComponent
                    register={register}
                    value={'comment'}
                    required={false}
                >
                    <h3>Commentaire</h3>
                </TextAreaComponent>
                <div className="mt-5 w-full sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 text-center">
                    <WhiteButton
                        onClick={() => {
                            setEditToggle(false);
                        }}
                    >
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit">
                        {isLoading ? <Loading size={4} /> : 'Edit'}
                    </PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}
