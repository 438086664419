import React from "react";
import { ISeries } from "./AreaChart";
import { ApexOptions } from "apexcharts";
import ApexCharts from "react-apexcharts";

interface Props {
  data: ISeries[];
  height?: number;
  horizontal?: boolean;
}

const BarChartApex = ({ data, height = 350, horizontal = false }: Props) => {
  const options: ApexOptions = {
    plotOptions: {
      bar: {
        columnWidth: "60%",
        horizontal: horizontal,
      },
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: data?.[0]?.data?.map((d) => d.x),
    },

    legend: {
      show: true,
      showForSingleSeries: false,
      customLegendItems: data.map((d) => d.name),
    },
  };

  const series = data?.map((d) => ({
    name: d.name,
    data: d.data.map((d) => d.y),
  }));
  return (
    <ApexCharts series={series} options={options} type="bar" height={height} />
  );
};

export default BarChartApex;
