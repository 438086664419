import qualite_1 from '../assets/quali1.jpg';
import qualite_2 from '../assets/quali2.jpg';
import qualite_3 from '../assets/suivi.png';

export interface QualiteInterface {
    name: string;
    sous_titre: string;
    qualites: string[];
    cover: string;
    id: string;
}

export const qualiteList: QualiteInterface[] = [
    {
        name: 'Impact',
        sous_titre: 'Donner du sens à votre argent',
        qualites: [
            'Créer des emplois durables en France',
            'Financer la transition énergétique',
            'Financer une agriculture responsable',
            "Faciliter l'accès aux logements et à leur rénovation énergétique",
        ],
        cover: qualite_1,
        id: 'qualite1',
    },
    {
        name: 'Rendement',
        sous_titre: 'Un gain réel de performance',
        qualites: [
            'Un accès aux réseaux institutionnels (+de projets, - de coûts)',
            'Utilisation de leviers (20%)',
            'Des frais compétitifs',
        ],
        cover: qualite_2,
        id: 'qualite2',
    },
    {
        name: 'Risque',
        sous_titre: 'Un risque mesuré',
        qualites: [
            'Des portefeuilles 100% obligataires et diversifiés ( > 30 investissements)',
            'Des fonds peu volatiles (< 3%)',
            "Des investissements adossés à d'importants actifs tangibles…",
            '... et bénéficiant de clauses avantageuses (cautions...)',
        ],
        cover: qualite_3,
        id: 'qualite3',
    },
];
