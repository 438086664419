import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import Page from "../../function/page_info";

const ContactSection: React.FC = (): JSX.Element => {
  const pageData = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );

  useEffect(() => {
    Page.scrollFunction("contactIcon", "apparitionContact", false, 100);
  }, [pageData.scrollPage]);

  return (
    <div id="contactIcon" className="contact_container">
      <div className="contact_card icon_style">
        <div className="icon">
          <FontAwesomeIcon icon={faEnvelope} size="7x" />
        </div>
        <Link to="/Contact">
          <h3>
            Nous contacter
            <FontAwesomeIcon icon={faArrowRight} className="fa_after" />
          </h3>
        </Link>
      </div>
    </div>
  );
};

export default ContactSection;
