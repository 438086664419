import { CreateDocumentPlatformDto } from '@api/api';
import axios from 'axios';

export interface FormCreateDocumentPlatform extends CreateDocumentPlatformDto {
    file: File[];
}

export interface FormCreateDocumentPlatformRequest
    extends CreateDocumentPlatformDto {
    file: File;
    platformName: string;
}

export async function uploadDocumentPlatform(
    request: FormCreateDocumentPlatformRequest
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}platform/documents/url/put`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    platformId: request.platformId,
                    platformName: request.platformName,
                    type: request.type,
                    category: request.category,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}platform/documents/post`,
            data: {
                platformId: request.platformId,
                type: request.type,
                comment: request?.comment,
                category: request.category,
                fileName: encodeURIComponent(request.fileName),
                key: key,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}
