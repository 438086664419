import {
    useGetDashboardUserQuery,
    useGetHistoricalPerfForNewUserQuery,
} from '@api/api';
import useDebouncedEffect from '@components/commun/CustomHook/useDebouncedEffect';
import { useMemo, useState } from 'react';
import { formatDateMonthYear } from '../../../function/Utils';
import { useGetPerformancePerFondsQuery } from '../../../redux/features/fondsSlice';
import { WrappingTable } from '../../DashboardAdmin/BackOffice/Components/TableComponent';

const HistoricalPerf = ({ fondsId }: { fondsId: number }) => {
    const { data: performanceFonds, isLoading: isLoadingPerf } =
        useGetPerformancePerFondsQuery(fondsId);

    const { data: dashBordUserAdmin, isLoading } = useGetDashboardUserQuery();

    const [date, setDate] = useState<string | undefined>();

    useDebouncedEffect(
        () => {
            setDate(new Date().toISOString());
        },
        [],
        100
    );

    const { data: perforNewUser } = useGetHistoricalPerfForNewUserQuery(
        {
            queryFondsId: fondsId,
            date: date || new Date().toISOString(),
            fromNbDays: 400,
            pathFondsId: fondsId,
        },
        {
            skip: !date,
        }
    );

    const fonds_i = dashBordUserAdmin?.fondsInfo?.find(
        (f) => f.fondsId === fondsId
    );

    const columns = useMemo(() => {
        if (!performanceFonds) {
            return [];
        }

        return [
            ...(performanceFonds?.yearlyValues?.map((p) => {
                return {
                    topHeader: p.year,
                    botHeader: `${formatDateMonthYear(new Date(p.startDate))} - ${formatDateMonthYear(
                        new Date(p.endDate)
                    )}`,
                    perf: `${p.perf.toFixed(2)} %`,
                };
            }) || []),
        ];
    }, [performanceFonds]);

    const newInvestisorColumn = useMemo(() => {
        if (!performanceFonds || !perforNewUser) {
            return [];
        }

        return [
            {
                topHeader: 'Performance nouveaux investisseurs',
                botHeader: `${formatDateMonthYear(new Date(perforNewUser.dateStart))} - ${formatDateMonthYear(
                    new Date(perforNewUser.dateEnd)
                )}`,
                perf: `${(perforNewUser?.tri || 0)?.toFixed(2)} %`,
            },
            {
                topHeader: "Performance d'un nouvel investisseur",
                botHeader: `durant les 30 derniers jours`,
                perf: `${(fonds_i && fonds_i?.triNotBlackListLast30Days > 0
                    ? fonds_i?.triNotBlackListLast30Days -
                      (fonds_i?.triNotBlackListLast30Days -
                          fonds_i?.fondsPerformance) *
                          0.3
                    : 0
                )?.toFixed(2)} %`,
            },
        ];
    }, [performanceFonds, perforNewUser]);

    const classNameObejct = {
        head: 'text-sm font-light px-1 py-4 whitespace-nowrap text-center  ',
        item: 'text-sm font-light px-1 py-2 whitespace-nowrap text-center',
    };

    return (
        <div className="w-full md:w-10/12 mx-auto px-2 mt-4 md:border-2 md:border-gray-300 md:rounded-md p-1">
            <p className=" text-justify">
                La performance du fonds est calculée en incluant les coupons
                nets réinvestis et nets de frais de gestion (directs et
                indirects) et hors frais d'entrée et de sortie. Les performances
                passées ne préjugent pas des performaces futures.
            </p>
            <div className="w-full my-3  mx-auto px-2">
                <WrappingTable>
                    <thead className="bg-white border-b border-t">
                        <tr>
                            {columns?.map((val, key) => (
                                <th
                                    key={key}
                                    scope="col"
                                    className={`${classNameObejct.head} `}
                                >
                                    <span className=" font-semibold">
                                        {val.topHeader}
                                    </span>{' '}
                                    <br />({val.botHeader})
                                </th>
                            ))}
                            <th
                                colSpan={2}
                                scope="col"
                                className={`${classNameObejct.head} `}
                            >
                                <div className="w-full mb-2">
                                    <span className=" font-semibold">
                                        Performances nouveaux investisseurs
                                    </span>{' '}
                                </div>

                                <div className="flex justify-between">
                                    {newInvestisorColumn?.map((val, key) => (
                                        <span key={key} className="">
                                            ({val.botHeader})
                                        </span>
                                    ))}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {performanceFonds ? (
                            <>
                                <tr
                                    className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 bg-secondBackgroundColor `}
                                >
                                    {columns?.map((val, key) => (
                                        <td
                                            className={`${classNameObejct.item} pl-1`}
                                        >
                                            {val.perf}
                                        </td>
                                    ))}
                                    {newInvestisorColumn?.map((val, key) => (
                                        <td
                                            key={key}
                                            className={`${classNameObejct.item} pl-1 font-semibold`}
                                        >
                                            {val.perf}
                                        </td>
                                    ))}
                                </tr>
                            </>
                        ) : null}
                    </tbody>
                </WrappingTable>
            </div>
            <p className="text-sm italic">
                Société créée le 24/02/2021. Performance calculée en EUR.
            </p>
        </div>
    );
};

export default HistoricalPerf;
