import React from "react";
import Loading from "../Loading";
import { WhiteButton, PrimaryButton } from "../Buttons";

interface Props {
  path?: string;
  addItemName?: string;
  isLoading?: boolean;
  onClickSubmit?: React.ReactEventHandler<HTMLButtonElement>;
  onClickCancel?: React.ReactEventHandler<HTMLButtonElement>;
  isValid?: boolean;
}

const SubmitComponent = (props: Props) => {
  return (
    <div className="flex w-full justify-center items-center my-4 gap-3">
      {/* <Link href={props.path}> */}
      <WhiteButton onClick={props.onClickCancel}>Cancel</WhiteButton>
      {/* </Link> */}
      <PrimaryButton
        disabled={props.isValid !== undefined && !props.isValid ? true : false}
        type="submit"
        onClick={props.onClickSubmit}
      >
        {props?.isLoading ? (
          <Loading className="border-white" size={4} />
        ) : (
          props.addItemName
        )}
      </PrimaryButton>
    </div>
  );
};

export default SubmitComponent;
