import { TransactionStatus, TypeTransaction } from '@api/api';
import { TypeInvestisseurIdEnum } from '@interfaces/typeInvestisseur';

export const defaultValuesTransaction = {
    fondsId: 0,
    status: TransactionStatus.VALIDATED,
    type: TypeTransaction.cashProject,
    amount: 0,
    date: new Date(Date.now()).toJSON().split('T')[0],
    transactionUserIn: undefined,
    transactionUserOut: undefined,
};

export const defaultValuesTransactionUserIn = {
    entranceFee: 0.02,
    managementFee: 0.005,
    donationFee: 0,
    typeShare: 'cumulative',
    initialShare: 100,
    actualShare: false,
    typeInvestisseurId: TypeInvestisseurIdEnum.normal,
};

export const defaultValuesTransactionUserOut = {
    transactionUserOutItems: [],
};

export const defaultValuesTransactionUserOutItem = {
    withdrawalFee: 0,
    share: 0,
    nbShare: 0,
};
