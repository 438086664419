import { useGetOverviewFondsInfoOverviewAdminQuery } from '@api/api';
import { Chart } from 'chart.js';
import React, { useContext, useEffect } from 'react';
import { fondsColor } from '../../../../data/FondsData';
import { setDate } from '../../../../function/Utils';
import { useGetAllFondsQuery } from '../../../../redux/features/fondsSlice';
import { useAppSelector } from '../../../../redux/store';
import {
    BackOfficeState,
    RootBlackOfficeState,
} from '../../../Context/AppContext';

const ChartFunds = () => {
    const pageState = useAppSelector(
        (state) => state.pageMeasurement.measurements
    );

    const { data: fondsData } = useGetAllFondsQuery();

    const backOfficeState = useContext(BackOfficeState);

    const rootState = useContext(RootBlackOfficeState);

    const { data: ovierViewAdminInfo, isLoading } =
        useGetOverviewFondsInfoOverviewAdminQuery(
            setDate(
                backOfficeState?.year!,
                backOfficeState?.month!,
                backOfficeState?.day ?? 0
            ).toISOString(),
            {
                skip:
                    !backOfficeState?.year ||
                    backOfficeState?.month === undefined ||
                    !backOfficeState?.day ||
                    rootState?.applyFeesBool === undefined,
            }
        );

    useEffect(() => {
        if (ovierViewAdminInfo && backOfficeState) {
            const hook = document.getElementById(
                'myChart'
            )! as HTMLCanvasElement;
            const ctx = hook.getContext('2d')!;
            const myChart = new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: fondsData?.map((fonds) => {
                        return fonds.name;
                    }),
                    datasets: [
                        {
                            label: 'Dataset 1',
                            data: ovierViewAdminInfo.fondsInfo?.map((val) => {
                                return val.valorisationByUsers;
                            }),
                            backgroundColor: fondsColor,
                        },
                    ],
                },
                options: {
                    responsive: true,

                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                font: {
                                    size: 16,
                                },
                            },
                        },

                        title: {
                            display: false,
                            text: 'Répartition des fonds',
                            color: 'rgb(40,40,40)',
                            position: 'top',
                        },
                    },
                },
            });
            // ctx.shadowColor = "black";

            return () => {
                myChart.destroy();
            };
        }
    }, [pageState.widthScreen, backOfficeState, ovierViewAdminInfo]);

    return (
        <React.Fragment>
            <canvas id="myChart" className=""></canvas>
        </React.Fragment>
    );
};

export default ChartFunds;
