import { QueryParamConfig } from "use-query-params";

import { useQueryParam } from "use-query-params";
import { useEffect, useState } from "react";

export function useQueryParamCustom<T>(
  name: string,
  type: QueryParamConfig<T, T>,
  defaultValue: T
) {
  // Directly use useQueryParam to get the values from the URL
  const [queryParam, seQueryParam] = useQueryParam(name, type);

  // State for holding the effective startDate and endDate, which might come from the URL or be the default
  const [effectiveQueryParam, setEffeciveQueryParam] =
    useState<T>(defaultValue);

  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    // If startDateParam is not null and is a valid date, use it; otherwise, use a default value
    if (queryParam) {
      setEffeciveQueryParam(queryParam);
    } else if (!init) {
      setInit(true);
      seQueryParam(defaultValue);
      //   setEffeciveQueryParam(defaultValue); // Set to default value or specific default date
    }
  }, [queryParam]); // Depend on the query param values

  return [effectiveQueryParam, seQueryParam] as const;
}
