import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import CookiesComponent from './components/commun/CookiesComponent';
import PopUp from './components/commun/PopUp';
import { NotificationProvider } from './components/Context/notification-context';
import { SocketProvider } from './components/Context/socket-context';
import RoutesComponent from './components/RoutesComponent';

function App() {
    const [sucessRequest, setSucessRequest] = useState<boolean>(false);
    const [fetchBool, setFetchBool] = useState<boolean>(false);

    useEffect(() => {
        if (fetchBool && !sucessRequest && Cookies.get('refresh') != 'true') {
            Cookies.set('refresh', 'true', { expires: 1 });
            window.location.reload();
        }
    }, [fetchBool]);

    const [cookieConsent, setCookieConsent] = useState<string | null>(
        localStorage.getItem('accepterCookies')
    );

    const [toggleCookie, setToggleCookie] = useState<boolean>(
        localStorage.getItem('accepterCookies') !== null ? false : true
    );

    return (
        <SocketProvider>
            <NotificationProvider>
                {toggleCookie ? (
                    <PopUp
                        open={toggleCookie}
                        setOpen={setToggleCookie}
                        title={() => {
                            return <h2></h2>;
                        }}
                        disabledOnclose={true}
                        buttonBoolean={false}
                    >
                        <CookiesComponent
                            setCookieConsent={setCookieConsent}
                            setToggleCookie={setToggleCookie}
                        />
                    </PopUp>
                ) : null}
                <RoutesComponent />
            </NotificationProvider>
        </SocketProvider>
    );
}

export default App;
