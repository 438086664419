import { useContext, useEffect } from 'react';
import {
    BooleanParam,
    NumberParam,
    StringParam,
    useQueryParam,
} from 'use-query-params';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import { MenuDashBoardAdminBoolState } from '../../Context/AppContext';
import WrapComponentDashboardUser from '../../DashboardUser/commun/WrapComponentDashboardUser';

import SearchComponent from '../../commun/SearchComponent';

import { useForm } from 'react-hook-form';
import { useGetAllFondsQuery } from '../../../redux/features/fondsSlice';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import Loading from '../../commun/Loading';

import '@silevis/reactgrid/styles.css';

import { faArrowLeft, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';

import { IMenu } from '../UserManage/UserManage';

import {
    useDeleteProjectByIdMutation,
    useGetProjectByIdQuery,
    useGetProjectsSearchQuery,
    useUpdateShowProjectMutation,
} from '@api/api';
import { TabsMenu } from '@components/commun/HorizontalMenu';
import { AddProject } from './AddProject';
import { BasicInfo } from './BasicInfo';
import { Documents } from './Documents';
import { Impact } from './Impact';
import { Interest } from './Interest';
import InvestTools from './InvestTools/InvestTools';
import { Suivis } from './Suivi/Suivis';
import { Transaction } from './Transactions';
import { Valorisation } from './Valorisation';

export const displayProjectName = (
    name: string,
    platformName: string,
    dateStart: Date
): string => {
    if (name.split('-').length > 2) {
        return name;
    } else {
        return `${name} - ${platformName} - ${new Date(dateStart).getFullYear()}`;
    }
};

const ProjectsManage = () => {
    const menuBoolState = useContext(MenuDashBoardAdminBoolState);

    const [queryParams, setQueryParams] = useQueryParam(
        'projectManage',
        StringParam
    );
    const [projectIdQueryParams, setProjectIdQueryParams] = useQueryParam(
        'projectId',
        NumberParam
    );

    const {
        data: project,
        isLoading: isLoadingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectIdQueryParams ?? 0, {
        skip: projectIdQueryParams === undefined,
    });

    const [returnQuery, setReturnQuery] = useQueryParam(
        'returnBackoffice',
        BooleanParam
    );

    const navigate = useNavigate();

    const selectComponent = () => {
        if (queryParams === 'ADD') {
            return <AddProject />;
        } else if (queryParams === 'EDIT' && projectIdQueryParams) {
            return <EditProject projectId={projectIdQueryParams} />;
        } else if (queryParams === 'tools') {
            return <InvestTools />;
        } else {
            return <Index />;
        }
    };

    const [deleteProject, { isLoading: isLoadingDelete }] =
        useDeleteProjectByIdMutation();

    useEffect(() => {
        if (queryParams === undefined) {
            setReturnQuery(false);
        }
    }, [queryParams]);

    useEffect(() => {
        const elementDashBoard = document.querySelector(
            '.overflow_projectManage'
        ) as HTMLElement;
        elementDashBoard?.scrollTo(0, 0);
    }, [queryParams]);

    const [editShowProject, { isLoading: isLoadingEditShowProject }] =
        useUpdateShowProjectMutation();

    const handleShowProject = async () => {
        if (project?.id) {
            await editShowProject({
                id: project?.id!,
                body: { show: !project?.show },
            });
            // refetchProject();
        }
    };

    return (
        <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
            <WrapComponentDashboardUser
                title="Projects management"
                description="Créer et modifier des projets."
                subTitle={
                    queryParams === 'EDIT' &&
                    projectIdQueryParams !== undefined &&
                    project !== undefined ? (
                        <div
                            className={`text-end flex justify-end gap-1 text-xl uppercase text-titleColor`}
                        >
                            <div
                                onClick={() => handleShowProject()}
                                className=" cursor-pointer"
                            >
                                {project.show ? (
                                    <span className="text-green-500">
                                        Visible
                                    </span>
                                ) : (
                                    <span className="text-red-500">Caché</span>
                                )}
                            </div>
                            <p className="text-end">
                                {displayProjectName(
                                    project.name,
                                    project.platformName || '',
                                    new Date(project.dateStart)
                                )}
                            </p>
                            {/* <p
                className=" inline-block ml-2 cursor-pointer"
                onClick={() => handleDelete()}
              >
                <FontAwesomeIcon className=" text-secondColor" icon={faTrash} />
              </p> */}
                        </div>
                    ) : queryParams === 'ADD' ? (
                        <div className="flex gap-3 justify-end items-center">
                            <p
                                className={`text-end text-xl uppercase text-titleColor`}
                            >
                                Création d'un projet
                            </p>
                        </div>
                    ) : undefined
                }
                onClickReturn={() => {
                    setQueryParams(undefined);
                    setProjectIdQueryParams(undefined);
                }}
                backButton={!!queryParams}
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
            >
                {selectComponent()}
            </WrapComponentDashboardUser>
        </div>
    );
};

export default ProjectsManage;

interface FilterForm {
    fonds: number;
}

function Filter() {
    const [fondsQuery, setFondsQuery] = useQueryParam('fonds', NumberParam);
    const [searchQuery, setSearchQuery] = useQueryParam('search', StringParam);

    const { data: fondsData, isLoading: isLoadingFonds } =
        useGetAllFondsQuery();

    const { register, control, setValue, getValues, watch, handleSubmit } =
        useForm<FilterForm>();

    useEffect(() => {
        if (fondsQuery === undefined && fondsData) {
            setFondsQuery(fondsData?.[0].id);
        }
    }, [fondsData]);

    return (
        <>
            {isLoadingFonds ? (
                <Loading />
            ) : (
                <>
                    <div className="w-full mt-10 flex justify-center items-center mb-5">
                        <SearchComponent
                            setSearch={setSearchQuery as any}
                            search={searchQuery as any}
                        />
                    </div>
                    <div className="flex gap-3 justify-start items-center">
                        <form className="ml-5 flex flex-col">
                            <SelectComponent
                                className="!w-[250px]"
                                useParamBoolean={true}
                                optionValues={
                                    fondsData?.map((f) => {
                                        return {
                                            label: f.name,
                                            value: f.id,
                                        };
                                    }) ?? []
                                }
                                value={'fonds'}
                                register={register}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                            >
                                <label className="mb-2 font-titleFontFamily text-mainColor text-lg">
                                    Sélectionner un fonds
                                </label>
                            </SelectComponent>
                        </form>
                    </div>
                </>
            )}
        </>
    );
}

function Index() {
    const [fondsQuery, setFondsQuery] = useQueryParam('fonds', NumberParam);
    const [searchQuery, setSearchQuery] = useQueryParam('search', StringParam);

    const [queryParams, setQueryParams] = useQueryParam(
        'projectManage',
        StringParam
    );

    const [projectIdQueryParams, setProjectIdQueryParams] = useQueryParam(
        'projectId',
        NumberParam
    );

    const [pageQuery, setPageQuery] = useQueryParam(
        'pageEditProject',
        StringParam
    );

    const {
        data: projects,
        isLoading: isLoadingProjects,
        isFetching: isFetchingProjects,
    } = useGetProjectsSearchQuery(
        {
            fondsId: fondsQuery || 0,
            search: searchQuery || '',
        },
        {
            skip: fondsQuery === undefined,
        }
    );

    return (
        <div className="pb-5 overflow_projectManage  relative">
            <div className="md:absolute top-2 left-2">
                <PrimaryButton
                    onClick={() => {
                        setQueryParams('tools');

                        setProjectIdQueryParams(undefined);
                    }}
                >
                    <FontAwesomeIcon icon={faGear} className=" mr-2" />{' '}
                    Investissement tools
                </PrimaryButton>
            </div>
            <div className="md:absolute top-2 right-2">
                <PrimaryButton
                    onClick={() => {
                        setQueryParams('ADD');

                        setProjectIdQueryParams(undefined);
                    }}
                >
                    Ajouter un projet
                </PrimaryButton>
            </div>
            <div className="w-[95%] mx-auto">
                <Filter />
            </div>
            {isLoadingProjects || isFetchingProjects ? (
                <Loading />
            ) : (
                <div className="grid mx-auto w-11/12 mt-3">
                    <div className="grid grid-cols-2 font-semibold">
                        <div className="">Nom</div>
                        <div className="">id</div>
                    </div>
                    {projects?.map((p, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                setProjectIdQueryParams(p.id!);
                                setQueryParams('EDIT');
                                setPageQuery('BasicInfo');
                            }}
                            className={`grid grid-cols-2 p-1 m-0 cursor-pointer ${
                                index % 2 === 0
                                    ? 'bg-secondBackgroundColor'
                                    : 'bg-white'
                            }`}
                        >
                            <div className="">{p.name}</div>
                            <div className="">{p.id}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

function EditProject({ projectId }: { projectId: number }) {
    const [pageQuery, setPageQuery] = useQueryParam(
        'pageEditProject',
        StringParam
    );

    const [queryParams, setQueryParams] = useQueryParam(
        'projectManage',
        StringParam
    );

    const [projectIdQueryParams, setProjectIdQueryParams] = useQueryParam(
        'projectId',
        NumberParam
    );

    const navigate = useNavigate();

    const menuList: IMenu[] = [
        {
            queryPage: 'BasicInfo',
            label: 'Basic info',
        },
        {
            queryPage: 'Impact',
            label: 'Impact',
        },
        {
            queryPage: 'Documents',
            label: 'Documents',
        },
        {
            queryPage: 'Transaction',
            label: 'Transactions',
        },
        {
            queryPage: 'Suivi',
            label: 'Suivi',
        },

        {
            queryPage: 'Interest',
            label: 'Intérêts',
        },
        {
            queryPage: 'Valorisation',
            label: 'Valorisation',
        },
    ];

    const selectPage = () => {
        switch (pageQuery) {
            case 'BasicInfo':
                return <BasicInfo projectId={projectId} />;
            case 'Impact':
                return <Impact projectId={projectId} />;
            case 'Documents':
                return <Documents projectId={projectId} />;
            case 'Transaction':
                return <Transaction projectId={projectId} />;
            case 'Suivi':
                return <Suivis projectId={projectId} />;
            case 'Interest':
                return <Interest projectId={projectId} />;
            case 'Valorisation':
                return <Valorisation projectId={projectId} />;
            default:
                return <BasicInfo projectId={projectId} />;
                break;
        }
    };

    const [returnQuery, setReturnQuery] = useQueryParam(
        'returnBackoffice',
        BooleanParam
    );

    return (
        <div className="relative w-full">
            <div className="md:absolute top-2 right-2 flex gap-3">
                <div
                    onClick={() => {
                        returnQuery ? navigate(-1) : setQueryParams(undefined);
                    }}
                    className=" cursor-pointer "
                >
                    <WhiteButton className="">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="mr-2 text-secondColor"
                        />
                        Retour
                    </WhiteButton>
                </div>
                <div className="">
                    <PrimaryButton
                        onClick={() => {
                            setQueryParams('ADD');
                            setProjectIdQueryParams(undefined);
                        }}
                    >
                        Ajouter un projet
                    </PrimaryButton>
                </div>
            </div>

            <div className="md:h-fit bg-backgroundColor w-full flex justify-center items-center mt-[-2px]   sticky top-[-2px] z-[99] mb-2 mx-auto">
                <TabsMenu
                    className="  "
                    items={menuList}
                    queryPage="pageEditProject"
                    onClick={() => {}}
                />
            </div>
            <div className="overflow_projectManage">{selectPage()}</div>
        </div>
    );
}
