import { DisplayDocumentsFormInvestisor } from '@components/commun/DisplayDocuments/DisplayDocumentsFormInvestisor';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';

import {
    DocumentUserDto,
    DocumentUserName,
    DocumentUserSide,
    DocumentUserType,
    StateStatus,
} from '@api/api';
import { PrimaryButton } from '../Buttons';
import DisplayDocumentValidation from '../DisplayDocuments/DisplayDocumentValidation';
import CheckboxComponent from '../formComponent/CheckboxComponent';
import UploaderSingle from '../formComponent/UploaderSingle';

export interface FormSidedDocument {
    documentRectoSide: {
        file: File[];
    };
    documentVersoSide: {
        file: File[];
    };
    documentBothSide: {
        file: File[];
    };
    sideNotBothBool: boolean;
    name: DocumentUserName;
    type: DocumentUserType;
}

interface Props<T extends FieldValues> {
    formState: UseFormReturn<T, any>;
    type: DocumentUserType;
    documents: DocumentUserDto[];
    isLoadingUpload: boolean;
    nameVariable: Path<T>;
    fileNameVariable: Path<T> & PathValue<T, Path<T>>;
}

const useSidedDocument = <T extends FieldValues>({
    formState,
    isLoadingUpload,
    type,
    documents,
    nameVariable,
    fileNameVariable,
}: Props<T> & { children?: ReactNode }) => {
    const document_recto = documents?.find(
        (doc) => doc.type === type && doc.side === DocumentUserSide.FRONT
    );

    const document_both = documents?.find(
        (doc) => doc.type === type && doc.side === DocumentUserSide.BOTH
    );

    const document_verso = documents?.find(
        (doc) => doc.type === type && doc.side === DocumentUserSide.BACK
    );

    const { watch, setValue, register, getValues } = formState;

    const name = useMemo(() => {
        return getValues(nameVariable) as DocumentUserName;
    }, [watch(nameVariable)]);

    useEffect(() => {
        if (documents?.length! > 0) {
            if (document_verso) {
                setValue(
                    `${fileNameVariable}.sideNotBothBool` as Path<T>,
                    true as any
                );
                if (document_recto) {
                    setValue(nameVariable, document_recto?.name as any);
                }
            } else {
                if (document_both) {
                    setValue(nameVariable, document_both?.name as any);
                }
            }
        }
    }, [documents]);

    useEffect(() => {
        setValue(
            `${fileNameVariable}.name` as Path<T>,
            getValues(nameVariable)
        );
    }, [watch(fileNameVariable)]);

    useEffect(() => {
        setValue(`${fileNameVariable}.type` as Path<T>, type as any);
    }, []);

    const [openBoth, setOpenBoth] = useState(false);
    const [openRecto, setOpenRecto] = useState(false);
    const [openVerso, setOpenVerso] = useState(false);

    const isValidForm = useMemo(() => {
        if (watch(`${fileNameVariable}.sideNotBothBool` as Path<T>) === true) {
            if (
                (document_verso !== undefined ||
                    (
                        watch(
                            `${fileNameVariable}.documentVersoSide` as Path<T>
                        ) as any
                    )?.file.length > 0) &&
                (document_recto !== undefined ||
                    (
                        watch(
                            `${fileNameVariable}.documentRectoSide` as Path<T>
                        ) as any
                    )?.file.length > 0)
            ) {
                return true;
            }
        } else {
            if (
                document_both !== undefined ||
                (
                    watch(
                        `${fileNameVariable}.documentBothSide` as Path<T>
                    ) as any
                )?.file.length > 0
            ) {
                return true;
            }
        }
        return false;
    }, [watch()]);

    const getFiles = useCallback(() => {
        const values = [];
        const dataForm = getValues(
            `${fileNameVariable}` as Path<T>
        ) as FormSidedDocument;
        if (dataForm.sideNotBothBool) {
            if (dataForm.documentRectoSide.file?.length > 0) {
                values.push({
                    file: dataForm.documentRectoSide.file,
                    side: DocumentUserSide.FRONT,
                    name: name,
                    type: type,
                });
            }
            if (dataForm.documentVersoSide.file?.length > 0) {
                values.push({
                    file: dataForm.documentVersoSide.file,
                    side: DocumentUserSide.BACK,
                    name: name,
                    type: type,
                });
            }
        } else {
            if (dataForm.documentBothSide.file?.length > 0) {
                values.push({
                    file: dataForm.documentBothSide.file,
                    side: DocumentUserSide.BOTH,
                    name: name,
                    type: type,
                });
            }
        }
        return values;
    }, [watch()]);

    const DisplaySidedDocumentValidation = useCallback(() => {
        return (
            <>
                {openRecto && document_recto ? (
                    <DisplayDocumentValidation
                        document={document_recto}
                        setOpen={setOpenRecto}
                        open={openRecto}
                        index={0}
                    />
                ) : null}

                {openBoth && document_both ? (
                    <DisplayDocumentValidation
                        document={document_both}
                        setOpen={setOpenBoth}
                        open={openBoth}
                        index={0}
                    />
                ) : null}

                {openVerso && document_verso ? (
                    <DisplayDocumentValidation
                        document={document_verso}
                        setOpen={setOpenVerso}
                        open={openVerso}
                        index={0}
                    />
                ) : null}
            </>
        );
    }, [documents, openRecto, openBoth, openVerso]);

    const SidedFileDocument = useCallback(
        ({ children }: { children: ReactNode }) => {
            return (
                <>
                    <div className=" w-fit mx-auto flex items-center mt-5 mb-[-20px]">
                        <CheckboxComponent
                            register={register}
                            watch={watch}
                            values={[
                                {
                                    label: "J'ai le recto et le verso sur deux documents différents",
                                    name: `${fileNameVariable}.sideNotBothBool` as Path<T> &
                                        keyof T,
                                },
                            ]}
                        />
                    </div>
                    {watch(`${fileNameVariable}.sideNotBothBool` as Path<T>) ? (
                        <>
                            <UploaderSingle
                                register={register}
                                value={
                                    `${fileNameVariable}.documentRectoSide.file` as Path<T>
                                }
                                watch={watch}
                                setValue={setValue}
                                popup={false}
                                loading={isLoadingUpload}
                                button={false}
                                documentAlreadyUploaded={!!document_recto}
                                componentEnd={() => {
                                    return (
                                        <p className="italic commentaireInput text-justify">
                                            <span className="font-bold">
                                                Documents acceptés:
                                            </span>{' '}
                                            Documents recto.
                                        </p>
                                    );
                                }}
                            >
                                <h3 className="text-2xl mb-5">
                                    {children}
                                    <span className=" font-semibold">
                                        (Recto)
                                    </span>
                                </h3>
                            </UploaderSingle>
                            {document_recto &&
                            document_recto?.status ===
                                StateStatus.unValidated ? (
                                <div className="flex flex-col gap-2">
                                    <p className="text-center text-red-600">
                                        Veuillez renvoyer ce document et/ou
                                        modifier vos informations personnelles
                                        pour correspondre à ce document si
                                        nécessaire.
                                    </p>
                                    <PrimaryButton
                                        className="w-fit mx-auto"
                                        onClick={() => setOpenRecto(true)}
                                    >
                                        Visualiser à nouveau les éléments à
                                        modifier
                                    </PrimaryButton>
                                </div>
                            ) : null}
                            {document_recto ? (
                                <>
                                    <DisplayDocumentsFormInvestisor
                                        document={document_recto}
                                    />
                                </>
                            ) : null}

                            <UploaderSingle
                                register={register}
                                value={
                                    `${fileNameVariable}.documentVersoSide.file` as Path<T>
                                }
                                watch={watch}
                                setValue={setValue}
                                popup={false}
                                loading={isLoadingUpload}
                                button={false}
                                documentAlreadyUploaded={!!document_verso}
                                componentEnd={() => {
                                    return (
                                        <p className="italic commentaireInput text-justify">
                                            <span className="font-bold">
                                                Documents acceptés:
                                            </span>{' '}
                                            Documents verso.
                                        </p>
                                    );
                                }}
                            >
                                <h3 className="text-2xl mb-5">
                                    {children}
                                    <span className=" font-semibold">
                                        (Verso)
                                    </span>
                                </h3>
                            </UploaderSingle>
                            {document_verso &&
                            document_verso?.status ===
                                StateStatus.unValidated ? (
                                <div className="flex flex-col gap-2">
                                    <p className="text-center text-red-600">
                                        Veuillez renvoyer ce document et/ou
                                        modifier vos informations personnelles
                                        pour correspondre à ce document si
                                        nécessaire.
                                    </p>
                                    <PrimaryButton
                                        className="w-fit mx-auto"
                                        onClick={() => setOpenVerso(true)}
                                    >
                                        Visualiser à nouveau les éléments à
                                        modifier
                                    </PrimaryButton>
                                </div>
                            ) : null}
                            {document_verso ? (
                                <>
                                    <DisplayDocumentsFormInvestisor
                                        document={document_verso}
                                    />
                                </>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <UploaderSingle
                                register={register}
                                value={
                                    `${fileNameVariable}.documentBothSide.file` as Path<T>
                                }
                                watch={watch}
                                setValue={setValue}
                                popup={true}
                                loading={isLoadingUpload}
                                documentAlreadyUploaded={!!document_both}
                                button={false}
                                componentEnd={() => {
                                    return (
                                        <p className="italic commentaireInput text-justify">
                                            <span className="font-bold">
                                                Documents acceptés:
                                            </span>{' '}
                                            Documents recto et verso.
                                        </p>
                                    );
                                }}
                            >
                                <h3 className="text-2xl mb-5">{children}</h3>
                            </UploaderSingle>
                            {document_both &&
                            document_both?.status ===
                                StateStatus.unValidated ? (
                                <div className="flex flex-col gap-2">
                                    <p className="text-center text-red-600">
                                        Veuillez renvoyer ce document et/ou
                                        modifier vos informations personnelles
                                        pour correspondre à ce document si
                                        nécessaire.
                                    </p>
                                    <PrimaryButton
                                        className="w-fit mx-auto"
                                        onClick={() => setOpenBoth(true)}
                                    >
                                        Visualiser à nouveau les éléments à
                                        modifier
                                    </PrimaryButton>
                                </div>
                            ) : null}
                            {document_both ? (
                                <>
                                    <DisplayDocumentsFormInvestisor
                                        document={document_both}
                                    />
                                </>
                            ) : null}
                        </>
                    )}
                </>
            );
        },
        [documents]
    );

    return {
        SidedFileDocument,
        isValidForm,
        getFiles,
        DisplaySidedDocumentValidation,
    };
};

export default useSidedDocument;

export function getSidedFiles(dataForm: FormSidedDocument) {
    const values = [];

    if (dataForm.sideNotBothBool) {
        if (dataForm.documentRectoSide?.file?.length > 0) {
            values.push({
                file: dataForm.documentRectoSide.file,
                side: DocumentUserSide.FRONT,
                name: dataForm.name,
                type: dataForm.type,
            });
        }
        if (dataForm.documentVersoSide?.file?.length > 0) {
            values.push({
                file: dataForm.documentVersoSide.file,
                side: DocumentUserSide.BACK,
                name: dataForm.name,
                type: dataForm.type,
            });
        }
    } else {
        if (dataForm.documentBothSide?.file?.length > 0) {
            values.push({
                file: dataForm.documentBothSide.file,
                side: DocumentUserSide.BOTH,
                name: dataForm.name,
                type: dataForm.type,
            });
        }
    }
    return values;
}
