import { useFetchTokenQuery, useUpdateEmailMutation } from '@api/api';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../../redux/store';
import { PrimaryButton } from '../../../commun/Buttons';
import useValidationCode from '../../../commun/formComponent/ValidationCode';
import { useNotificationContext } from '../../../Context/notification-context';

interface formInterface {
    email: string;
}

const ChangeEmail = () => {
    const dispatch = useAppDispatch();

    const { data: user } = useFetchTokenQuery();

    const [message, setMessage] = useState<string>('');

    const validationSchema: Yup.SchemaOf<formInterface> = Yup.object().shape({
        email: Yup.string()
            .required('Email est demandé')
            .email('Email est invalid')
            .lowercase()
            .trim(),
    });

    const { showError, showSuccess } = useNotificationContext();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<formInterface>({
        resolver: yupResolver(validationSchema),
        defaultValues: { email: user?.email },
    });

    const [changeEmail, { isLoading }] = useUpdateEmailMutation();

    const onSubmit = async (data: formInterface) => {
        if (user) {
            await changeEmail({
                updateEmailUserDto: {
                    email: data.email,
                    id: user?.id,
                },
                userId: user?.id,
            })
                .unwrap()
                .then((value) => {
                    if (value?.message === 'success') {
                        setMessage('Email modifié !');
                        showSuccess(
                            'Modifié',
                            'Votre email a bien été modifié !'
                        );
                    } else {
                        setMessage("L'email est déjà utilisé");
                        showError('Erreur', "L'email est déjà utilisé");
                    }
                })
                .catch(() => {
                    showError(
                        'Erreur',
                        'Un problème est survenue lors de la modification de votre email !'
                    );
                });
        }
    };

    const { ValidationCode, handleSubmitForm } = useValidationCode({
        onSubmit: handleSubmit(onSubmit),
    });

    return (
        <form
            className="formTemplateSingUp"
            onSubmit={handleSubmit(handleSubmitForm)}
        >
            <ValidationCode>
                <p className="text-lg text-titleColor">
                    Afin de valider la modification vous devez rentrer
                    ci-dessous le code envoyé par mail
                </p>
            </ValidationCode>
            <p
                className={
                    message === 'Email modifié !'
                        ? 'text-green-600'
                        : 'text-red-600'
                }
            >
                {message}
            </p>
            <div className="form-item">
                <label>
                    Nouveau email <span className="required">*</span>
                </label>
                <input
                    type="text"
                    {...register('email')}
                    className={`form-control border-texteColor border ${
                        errors.email ? 'is-invalid' : ''
                    }`}
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
            </div>
            <div className="w-fit mt-2 justify-center items-center mx-auto">
                <PrimaryButton className="" type="submit" loading={isLoading}>
                    Modifier l'email
                </PrimaryButton>
            </div>
        </form>
    );
};

export default ChangeEmail;
