import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import {
    filterFunctions,
    formatDate,
    isEmpty,
    printLargeValue,
} from '../../../function/Utils';
import { DisplayTypeTransaction } from '../../../function/functionUtilis';

import {
    TransactionUserDto,
    TypeTransaction,
    useFetchTokenQuery,
    useGetTransactionsUserQuery,
} from '@api/api';
import { typeFiltration } from '../../../types/BackOffice';
import { MenuDashBoardUserBoolState } from '../../Context/AppContext';
import FilterApparition from '../../DashboardAdmin/BackOffice/Components/FilterApparition';
import FiltrationColumnTable, {
    filtrationInterface,
    typeOrderOrFiltration,
} from '../../DashboardAdmin/BackOffice/Components/FiltrationColumnTable';
import ExportComponent from '../../commun/ExportComponent';
import Loading from '../../commun/Loading';
import StatusTransaction from '../../commun/StatusTransaction';
import WrapComponentDashboardUser from '../commun/WrapComponentDashboardUser';
// import { MagnifyingGlassIcon } from "@heroicons/react";

const IndexTransactionUser = () => {
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    return (
        <div
            className={`mx-auto  md:mt-0 lg:px-2 md:pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto `}
        >
            <WrapComponentDashboardUser
                title="Historique de vos transactions"
                description=""
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={` ${!menuBoolState?.toggleMenu ? '' : ''}`}
            >
                <div className=" w-[95%] md:w-full mx-auto ">
                    <TransactionTable />
                </div>
            </WrapComponentDashboardUser>
        </div>
    );
};

export default IndexTransactionUser;

function Filter() {
    const handleInvest = () => {
        // TODO: Implement the invest functionality
        console.log('Invest button clicked');
    };

    const handleWithdraw = () => {
        // TODO: Implement the withdraw functionality
        console.log('Withdraw button clicked');
    };

    return (
        <div className="flex justify-around items-center lg:w-2/3 w-11/12 ">
            <div className="w-full max-w-lg lg:max-w-xs">
                <label htmlFor="search" className="sr-only">
                    Search
                </label>
                <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </div>
                    <input
                        id="search"
                        name="search"
                        className="block w-full rounded-md border-0 bg-gray-200 py-1.5 pl-10 pr-3 text-gray-300 placeholder:text-gray-400 focus:bg-white focus:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Search"
                        type="search"
                    />
                </div>
            </div>
            {/* <div className="relative flex cursor-pointer justify-center items-center rounded-lg shadow-low w-[200px] h-[120px] hover:bg-gray-200">
        <FontAwesomeIcon
          icon={faArrowUp}
          className="scale-[5] text-green-700 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
        <p className="font-titleFontFamily text-lg">Investir</p>
      </div>
      <FontAwesomeIcon
        icon={faMoneyBillTransfer}
        className="scale-[5] text-mainColor"
      />
      <div className="flex justify-center items-center rounded-lg shadow-low w-[200px] h-[120px] bg-secondBackgroundColor hover:bg-gray-200">
        <p>Retirer</p>
      </div> */}
            {/* <div className="App">
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AttachMoneyIcon />}
            onClick={handleInvest}
          >
            Invest
          </Button>
        </motion.div>

        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <IconButton color="primary" onClick={handleWithdraw}>
            <ArrowBackIcon />
          </IconButton>
        </motion.div>

        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <IconButton color="primary" onClick={handleWithdraw}>
            <ArrowForwardIcon />
          </IconButton>
        </motion.div>
      </div> */}
        </div>
    );
}

function TransactionTable() {
    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();
    const { data: transactions, isLoading: isLoadingTransactions } =
        useGetTransactionsUserQuery(user?.id ?? 0, {
            skip: !user,
        });

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold text-center',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap  text-right',
    };

    const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
    const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);
    const [filterColumn, setFilterColumn] = useState<
        filtrationInterface<TransactionUserDto>[]
    >([
        {
            element: 'date',
            type: typeFiltration.date,
            value: 'decroissant',
            orderOrFiltration: typeOrderOrFiltration.order,
        },
    ]);

    const [transactionsDataFiltered, setTransactionsDataFiltered] = useState<
        TransactionUserDto[]
    >([]);

    const filtrationFunction = () => {
        let transactionDataFiltered_init: TransactionUserDto[] =
            transactions ?? [];
        for (const item of filterColumn) {
            transactionDataFiltered_init = filterFunctions(
                transactionDataFiltered_init,
                item.element,
                item.type,
                item.value
            );
        }
        setTransactionsDataFiltered(transactionDataFiltered_init);
    };

    useEffect(() => {
        if (!isEmpty(transactions)) {
            filtrationFunction();
        }
    }, [filterColumn, transactions]);

    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    return (
        <div className="mt-5 relative ">
            <FilterApparition
                startIntervalNbItems={startIntervalNbItems}
                setNbItemsShowed={setNbItemsShowed}
                nbItemsShowed={nbItemsShowed}
                setStartIntervalNbItems={setStartIntervalNbItems}
                array={transactionsDataFiltered!}
            >
                <div className="relative md:absolute md:top-[-8px] md:right-3">
                    <ExportComponent
                        headers={[
                            'Type',
                            'Fonds',
                            'Montant',
                            'Valeur de part',
                            'Nombre de part',
                            'Plus-value',
                            'Date',
                            'Statut',
                        ]}
                        title="Historique des transactions"
                        userData={{
                            name: `${user?.lastName.toLocaleUpperCase()} ${user?.firstName}`,
                        }}
                        data={transactionsDataFiltered.map((val) => {
                            return {
                                Type:
                                    val.type === TypeTransaction.userCash
                                        ? 'Investis'
                                        : 'Retrait',
                                Fonds: val.fondsName,
                                Montant: printLargeValue(val.amount.toFixed(2)),
                                ValeurPart: val.share?.toFixed(2) ?? 100,
                                NombrePart: val.nbShare.toFixed(2),
                                PlusValue:
                                    val.type === TypeTransaction.userCash
                                        ? '-'
                                        : printLargeValue(
                                              (val.capitalGain as any).toFixed(
                                                  2
                                              )
                                          ),
                                Date: formatDate(new Date(val.date)),
                                Statut: val.status,
                            };
                        })}
                    />
                </div>
            </FilterApparition>
            <div className="flex mt-3 flex-col max-w-full overflow-x-auto">
                <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
                    <div className="py-2 inline-block min-w-full">
                        <div className="overflow-hidden">
                            {/* <p>
    Valeur total du fonds{" "}
    {printLargeValue(
      projectValorisationInfoData?.valorisationByProjects?.toFixed(
        2
      )
    )}
  </p> */}
                            <table className="min-w-full">
                                <thead className="bg-white border-b border-t">
                                    <tr>
                                        <FiltrationColumnTable
                                            element={'type'}
                                            type={typeFiltration.exact}
                                            setFilterColumn={setFilterColumn}
                                            useFilter={true}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.filter
                                            }
                                            optionValues={[
                                                {
                                                    value: 'all',
                                                    label: 'Tous',
                                                },
                                                {
                                                    value: TypeTransaction.userCash,
                                                    label: 'Investis',
                                                },
                                                {
                                                    value: TypeTransaction.cashUser,
                                                    label: 'Retrait',
                                                },
                                            ]}
                                            columnName={() => (
                                                <p className="inline-block text-left">
                                                    Type
                                                </p>
                                            )}
                                            textLeft={true}
                                        />
                                        <FiltrationColumnTable
                                            element={'fondsName'}
                                            useFilter={true}
                                            type={typeFiltration.exact}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="inline-block">
                                                    Fonds
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.filter
                                            }
                                            optionValues={[
                                                {
                                                    value: 'all',
                                                    label: 'Tous',
                                                },

                                                ...(transactions ?? [])?.reduce(
                                                    (
                                                        acc: {
                                                            value: string;
                                                            label: string;
                                                        }[],
                                                        item
                                                    ) => {
                                                        if (
                                                            !acc
                                                                .map(
                                                                    (v) =>
                                                                        v.value
                                                                )
                                                                .includes(
                                                                    item.fondsName
                                                                )
                                                        ) {
                                                            return acc.concat([
                                                                {
                                                                    value: item.fondsName,
                                                                    label: item.fondsName,
                                                                },
                                                            ]);
                                                        }
                                                        return acc;
                                                    },
                                                    []
                                                ),
                                            ]}
                                            textLeft={true}
                                        />

                                        <FiltrationColumnTable
                                            element={'amount'}
                                            type={typeFiltration.amount}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="text-center">
                                                    Montant
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.order
                                            }
                                            textLeft={false}
                                        />

                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Valeur part
                                        </th>
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Nombre part
                                        </th>

                                        <FiltrationColumnTable
                                            element={'capitalGain'}
                                            type={typeFiltration.amount}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="text-center">
                                                    Plus-value
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.order
                                            }
                                            textLeft={false}
                                        />

                                        <FiltrationColumnTable
                                            element={'date'}
                                            type={typeFiltration.date}
                                            setFilterColumn={setFilterColumn}
                                            columnName={() => (
                                                <p className="text-center">
                                                    Date
                                                </p>
                                            )}
                                            orderOrFiltration={
                                                typeOrderOrFiltration.order
                                            }
                                            textLeft={false}
                                        />
                                        <th
                                            scope="col"
                                            className={classNameObejct.head}
                                        >
                                            Statut
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoadingTransactions ? (
                                        <tr className="">
                                            <td
                                                colSpan={8}
                                                className="pt-10 text-center mx-auto"
                                            >
                                                <Loading />
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {transactions &&
                                            transactions?.length === 0 ? (
                                                <tr className="w-full mx-auto">
                                                    <td
                                                        colSpan={8}
                                                        className="text-center text-gray-500 text-xl  pt-10"
                                                    >
                                                        Aucune transaction
                                                        touvée
                                                    </td>
                                                </tr>
                                            ) : (
                                                transactionsDataFiltered && (
                                                    <React.Fragment>
                                                        {transactionsDataFiltered
                                                            .filter(
                                                                (val, index) =>
                                                                    index >=
                                                                        startIntervalNbItems &&
                                                                    index <
                                                                        startIntervalNbItems +
                                                                            nbItemsShowed
                                                            )
                                                            .map(
                                                                (
                                                                    transaction,
                                                                    key
                                                                ) => (
                                                                    <RowElement
                                                                        transaction={
                                                                            transaction
                                                                        }
                                                                        key={
                                                                            key
                                                                        }
                                                                        number={
                                                                            key
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                    </React.Fragment>
                                                )
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function RowElement({
    transaction,
    number,
}: {
    transaction: TransactionUserDto;
    number: number;
}) {
    const classNameObejct = {
        item: 'text-sm text-center font-light px-6 py-4 whitespace-nowrap',
    };

    return (
        <tr
            className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                number % 2 === 0 ? 'bg-secondBackgroundColor' : 'bg-white '
            } `}
        >
            <td className={`${classNameObejct.item}`}>
                {DisplayTypeTransaction(transaction.type)}
            </td>
            <td className={`${classNameObejct.item}`}>
                {transaction.fondsName ?? 'Fonds inconnu'}
            </td>
            <td className={`${classNameObejct.item}`}>
                {printLargeValue(transaction.amount.toFixed(2))}
            </td>
            <td className={`${classNameObejct.item}`}>
                {transaction.share?.toFixed(2) ?? 100}
            </td>
            <td className={`${classNameObejct.item}`}>
                {transaction.nbShare?.toFixed(2)}
            </td>
            <td className={`${classNameObejct.item}`}>
                {transaction.type === TypeTransaction.userCash
                    ? '-'
                    : printLargeValue(
                          (transaction.capitalGain as any)?.toFixed(2)
                      )}
            </td>
            <td className={`${classNameObejct.item}`}>
                {formatDate(new Date(transaction.date))}
            </td>
            <td className={`${classNameObejct.item}`}>
                <StatusTransaction status={transaction.status} />
            </td>
        </tr>
    );
}
