import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { ReactNode, useEffect, useState } from 'react';
import {
    FieldErrorsImpl,
    FieldValues,
    Path,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { PrimaryButton } from '../Buttons';
import Loading from '../Loading';
import PopUp from '../PopUp';
import PreviewFile from './PreviewFile';

interface Props<T extends FieldValues> {
    register: UseFormRegister<T>;
    documentAlreadyUploaded?: boolean;
    watch: UseFormWatch<T>;
    errors?: FieldErrorsImpl<T>;
    componentEnd?: () => JSX.Element;
    className?: {
        input?: string;
    };
    value: Path<T>;
    cursorNotAllowed?: true;
    setValue: UseFormSetValue<T>;
    getValues?: UseFormGetValues<T>;
    typeFile?: string[];
    button?: boolean;
    loading?: boolean;
    size?: number;
    required?: boolean;
    setSizeGood?: React.Dispatch<React.SetStateAction<boolean>>;
    popup?: boolean;
}
const UploaderSingle = <T extends object>(
    props: Props<T> & { children?: ReactNode }
) => {
    const [isDragOver, setIsDragOver] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const required = props.required ?? false;

    const typeFile = props.typeFile
        ? props.typeFile
        : ['png', 'jpg', 'jpeg', 'pdf'];

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(false);
        const files = event.dataTransfer.files;
        if (props.setValue && files && files.length > 0) {
            // check the type of the file if is not .png, .pdf .jpg .jpeg then say that
            // the type of the file is wrong
            const fileType = files[0].type.split('/')[1];
            if (typeFile.includes(fileType)) {
                props.setValue(props.value, files as any);
                setErrorMessage('');
            } else {
                setErrorMessage(`Le type ${fileType} n'est pas valide`);
            }
        }
    };

    const size = props.size ?? 4;
    useEffect(() => {
        if (
            props?.setSizeGood &&
            (props.watch(props.value) as any[])?.length > 0 &&
            (props.watch(props.value) as any[])?.[0]?.size / 1024 > size * 1024
        ) {
            props?.setSizeGood(false);
        }
        if (
            props?.setSizeGood &&
            (props.watch(props.value) as any[])?.length > 0 &&
            (props.watch(props.value) as any[])?.[0]?.size / 1024 < size * 1024
        ) {
            props?.setSizeGood(true);
        }
    }, [props, size]);

    useEffect(() => {
        // check if there is a document or not and setErrorMessage if not
        if (
            (props.watch(props.value) as any[])?.length === 0 &&
            !props?.documentAlreadyUploaded
        ) {
            setErrorMessage('Veuillez ajouter un fichier');
        } else {
            setErrorMessage('');
        }
    }, [props]);

    // useEffect(() => {
    //   console.log(props.watch(props.value));
    // }, [props.watch(props.value)]);
    const classNameObject = {
        h3: 'text-base mt-2',
        p: 'italic text-xs',
        input: 'w-[200px] md:w-[300px] h-[100px] absolute left-[10%] sm:left-[20%] md:left[30%] bg-secondBackgroundColor cursor-pointer  top-2 opacity-0',
        img: 'w-8 h-8 my-2',
        previewImage: 'w-20 my-2',
    };

    return (
        <motion.div
            className={`mx-auto w-full  md:w-11/12 md:max-w-xl  p-8 `}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
        >
            {props.popup && props.componentEnd && !props.loading ? (
                <PopUpComponent
                    component={props.componentEnd}
                    value={props.value}
                    watch={props.watch}
                />
            ) : null}
            {props.children}
            <div
                className={`relative  p-4 border-dashed border-2 rounded-lg border-mainColor flex flex-col items-center justify-center gap-3 w-full ${
                    isDragOver ? 'bg-gray-300' : ''
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {/* <img
          className={classNameObject.img}
          src={picture}
          alt="icon to upload file"
        /> */}

                <input
                    type="file"
                    accept={typeFile.map((type) => `.${type}`).join(',')}
                    // required
                    {
                        ...props.register(props.value)
                        // ,
                        // {
                        //   required: required === false ? false : true,
                        // }
                    }
                    className={classNameObject.input}
                />
                <div className="flex gap-3 items-center">
                    <FontAwesomeIcon
                        icon={faUpload}
                        className="text-mainColor hover:text-secondColor"
                    />
                    Glisser-déposer ou
                    <PrimaryButton className="w-20 h-8 my-2 inline-block">
                        <label htmlFor="file-upload">Ajouter</label>
                    </PrimaryButton>
                </div>
                <div className="">
                    <span className="font-bold">Fichiers supportés:</span>
                    <span className=" uppercase"> {typeFile.join(', ')}</span>
                </div>
                <div className="">
                    <span className="font-bold">Taille maximum:</span> {size} MB
                </div>
            </div>
            {errorMessage ? (
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className="text-red-500 text-xs italic my-2"
                >
                    {errorMessage}
                </motion.div>
            ) : null}

            {props.componentEnd && props.componentEnd()}
            <PreviewFile
                watch={props.watch}
                name={props.value}
                setValue={props.setValue}
                size={size}
            />
            {props.button === undefined || props.button === true ? (
                <div className="flex items-center justify-center mt-10">
                    <PrimaryButton
                        type="submit"
                        disabled={
                            (props.watch(props.value) as any[])?.length > 0 &&
                            (props.watch(props.value) as any[])?.[0]?.size /
                                1024 <
                                size * 1024
                                ? false
                                : true
                        }
                        className={`${
                            !((props.watch(props.value) as any[])?.length > 0)
                                ? 'opacity-50'
                                : ''
                        }`}
                    >
                        {props.loading !== undefined &&
                        props.loading === true ? (
                            <Loading size={4} />
                        ) : (
                            'Continuer'
                        )}
                    </PrimaryButton>
                </div>
            ) : null}
        </motion.div>
    );
};

export default UploaderSingle;

function PopUpComponent<T extends FieldValues>({
    component,
    value,
    watch,
}: {
    component: () => JSX.Element;
    value: Path<T>;
    watch: UseFormWatch<T>;
}) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (watch(value)?.length > 0 && !open) {
            setOpen(true);
        }
    }, [watch(value)]);

    return (
        <PopUp open={open} setOpen={setOpen} buttonBoolean={false}>
            <p className=" text-xl">
                Avez-vous bien vérifié que votre document respecte la ou les
                contraintes suivantes ?
            </p>
            <div className=" my-4 w-11/12 mx-auto text-justify">
                {component()}
            </div>
            <div className=" w-full flex justify-center items-center">
                <PrimaryButton onClick={() => setOpen(false)}>
                    Continuer
                </PrimaryButton>
            </div>
        </PopUp>
    );
}
