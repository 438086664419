import { debounce } from "@material-ui/core";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  Control,
  FieldValues,
  Path,
  UseFormRegister,
  useController,
  useForm,
} from "react-hook-form";
import PlacesAutocomplete, {
  Suggestion,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>;
  value: Path<T>;
  control: Control<T, Path<T>>;
  palceholder?: string;
}

const AdressCompletion = <T extends object>(
  props: Props<T> & { children?: ReactNode }
) => {
  const handleSelect = (selectedAddress: string) => {
    geocodeByAddress(selectedAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        propertyOnChange(selectedAddress);
        const addressData = {
          address: selectedAddress,
          latitude: latLng.lat,
          longitude: latLng.lng,
        };
      })
      .catch((error) => console.error("Error", error));
  };

  // const [valueState, setValueState] = useState("");

  const {
    field: {
      value: property,
      onChange: propertyOnChange,
      ...restPropertyField
    },
  } = useController({
    name: props.value,
    control: props.control,
  });

  return (
    <>
      {props.children}
      <PlacesAutocomplete
        onSelect={handleSelect}
        value={property}
        onChange={(v) => propertyOnChange(v)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: props?.palceholder ?? "Adresse...",
                className: "location-search-input w-full",
              })}
            />

            <div
              className={`autocomplete-dropdown-container ${
                loading || suggestions?.length > 0
                  ? "border-2 rounded-md mt-2"
                  : ""
              }  `}
            >
              {loading && <div>Loading...</div>}
              {suggestions?.map((suggestion, key) => {
                const className = suggestion.active
                  ? "suggestion-item--active "
                  : "suggestion-item ";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                      key,
                    })}
                    className="border-b-2 px-2 py-1"
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  );
};

export default AdressCompletion;
