import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import ImgComponent from "../commun/ImgComponent";
import ChartRendementImg from "./../../assets/rendementChart.png";
import Page from "../../function/page_info";
import { useAppSelector } from "../../redux/store";

const ChartRendement: React.FC = () => {
  const pageData = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );

  const [imgBol, setImgBol] = useState<boolean>(false);
  useEffect(() => {
    if (!imgBol) {
      const ctx2_ = document.getElementById(
        "rendementChart"
      ) as HTMLCanvasElement;
      const ctx2 = ctx2_?.getContext("2d");
      if (ctx2) {
        const myChart2 = new Chart(ctx2, {
          type: "line",
          data: {
            labels: [
              "Avril",
              "Mai",
              "Juin",
              "Juillet",
              "Août",
              "Septembre",
              "Octobre",
              "Novembre",
            ],
            datasets: [
              {
                label: "Rendement annualisé en %",
                data: [5.04, 4.39, 8.88, 8.76, 9.52, 9.74, 9.54, 9.94],
                backgroundColor: ["rgb(38, 121, 211)"],
                borderColor: ["rgb(38, 121, 211)"],
                borderWidth: 1,
              },
            ],
          },
          options: {
            layout: {
              padding: {
                bottom: 100,
              },
            },
            responsive: true,
            scales: {
              y: {
                ticks: {
                  font: {
                    size: 18,
                  },
                },
              },
              x: {
                ticks: {
                  font: {
                    size: 18,
                  },
                },
              },
            },
            plugins: {
              legend: {
                position: "top",
                labels: {
                  font: {
                    size: 18,
                  },
                },
              },
            },
            // title: {
            //   display: false,
            //   text: "Répartition du portefeuille",
            //   position: "top",
            // },
          },
        });
        return () => {
          myChart2.destroy();
        };
      }
    }
  }, [pageData.widthScreen, imgBol]);

  useEffect(() => {
    if (!imgBol) {
      Page.scrollFunction("ChartContainer_Id", "apparitionContact", true);
    }
  }, [pageData.scrollPage]);

  return !imgBol ? (
    <div id="ChartContainer_Id" className="ChartContainer">
      <h2>
        Rendements du fonds : <br /> <span>Immobilier dynamique</span>
      </h2>

      <canvas id="rendementChart"></canvas>

      {!!(pageData.widthScreen < 700) && (
        <div className="infoImg">
          <p
            onClick={() => {
              setImgBol(!imgBol);
            }}
            className="pointer"
          >
            Cliquez ici pour voir le graphique en plus grand.
          </p>
        </div>
      )}
    </div>
  ) : (
    <div className="imgBiggerContainer">
      <ImgComponent name="Chart rendement" cover={ChartRendementImg} />
      <div className="infoImg">
        <p
          onClick={() => {
            setImgBol(!imgBol);
          }}
          className="pointer"
        >
          Cliquez ici pour voir le graphique en plus petit.
        </p>
      </div>
    </div>
  );
};

export default ChartRendement;
