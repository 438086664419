import { CSSProperties, useContext, useEffect, useState } from 'react';
import { createDateArray, lastDay, startMonth } from '../../../function/Utils';

import { useFetchTokenQuery, useGetSharesByUserIdQuery } from '@api/api';
import { ComputationUserDashboard } from '../../../function/ComputationUserDashboard';
import {
    BackOfficeUserState,
    MenuDashBoardUserBoolState,
} from '../../Context/AppContext';
import Loading from '../../commun/Loading';
import WrapComponentDashboardUser from '../commun/WrapComponentDashboardUser';
import Boxes from './Boxes';
import ChartValo from './ChartValo';

const Overview = () => {
    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

    const styleDiv: CSSProperties = {
        width: '98%',
        height: '96vh',
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridTemplateRows: '1.8fr 1fr',
        rowGap: '1rem',
        columnGap: '1rem',
        justifyItems: 'stretch',
    };

    const styleGrid: CSSProperties = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        gridTemplateAreas: `"box1 box2" "box3 box4"`,
    };

    const date = lastDay(new Date());
    const dateBis = startMonth(date);
    dateBis.setMonth(dateBis.getMonth() - 11);

    const dates = createDateArray(new Date(dateBis), date).map(
        (date) => new Date(date)
    );

    const { data: shareDataFull, isLoading } = useGetSharesByUserIdQuery(
        user?.id ?? 0,
        {
            skip: !user,
        }
    );

    const shareData = shareDataFull?.shares;

    const [computationObject, setComputationObject] = useState<
        ComputationUserDashboard | undefined
    >();
    const backOfficeUserState = useContext(BackOfficeUserState);

    useEffect(() => {
        console.log('share', shareData);
    }, [shareData]);

    useEffect(() => {
        if (shareData && user) {
            const computationObject: ComputationUserDashboard =
                new ComputationUserDashboard(
                    shareData ?? [],
                    user?.id!,
                    shareDataFull.firstDates
                );

            setComputationObject(computationObject);
        }
    }, [shareData, user, backOfficeUserState]);
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    return (
        <div
            className={`mx-auto  md:mt-0 lg:px-2 md:pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto `}
        >
            <WrapComponentDashboardUser
                title="Vue d'ensemble de vos investissements"
                description=""
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
            >
                <div className=" ">
                    <div className="grid w-full grid-cols-1 xl:grid-cols-2 gap-3 items-center mt-12 mb-5  overflow-auto justify-center">
                        {computationObject ? (
                            <>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:mt-10 justify-items-center gap-2 relative">
                                    <Boxes
                                        computationObject={computationObject}
                                    />
                                </div>
                                <div className="p-5">
                                    <ChartValo
                                        computationObject={computationObject}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="g col-span-2">
                                <Loading />
                            </div>
                        )}
                    </div>

                    {/* <div className="overflow-auto shadow-in rounded-lg relative">
        <h3 className="margin_20_auto text_center text-xl font-bold">
          Vos tâches en attente :
        </h3>
        <div className="flex flex-row gap-3 ">
          {!isEmpty(listeTachesAttententes) &&
            listeTachesAttententes?.map((val, key) => (
              <div
                className="ml-3 text-center w-10/12 md:w-2/3 bg-thirdBackgroundColor px-2 py-3 transition-all border-secondColor border-l-4 shadow-low font-titleFontFamily cursor-pointer hover:translate-x-[1%] hover:bg-secondBackgroundColor "
                key={key}
              >
                {val.path ? (
                  <Link to={val.path}>
                    <p className=" font-mainFontFamily">{val.nom}</p>
                  </Link>
                ) : (
                  <p className="font-mainFontFamily">{val.nom}</p>
                )}
              </div>
            ))}
        </div>
      </div> */}
                </div>
            </WrapComponentDashboardUser>
        </div>
    );
};

export default Overview;
