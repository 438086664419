import {
    CheckUserProfileDto,
    CreateAssociationBetweenFondsAndUserDto,
    DocumentUserDto,
    DocumentUserType,
    StateStatus,
    TypeValidationDocumentUser,
    useDeleteCheckUserProfileMutation,
    useFetchTokenQuery,
    useGetActionLogByUserIdQuery,
    useGetLogOnUserByUserIdQuery,
    useGetUserDocumentsQuery,
    useGetUserWithAllInfoQuery,
    UserWithAllInfoForAdminDto,
} from '@api/api';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { filterFunctions, formatDate } from '../../../function/Utils';
import {
    useAssociateFondsWithUserMutation,
    useGetAllFondsQuery,
    useGetFondsUserQuery,
} from '../../../redux/features/fondsSlice';

import { typeFiltration } from '../../../types/BackOffice';

import { useNotificationContext } from '../../Context/notification-context';
import {
    PrimaryButton,
    SuccessButton,
    WhiteButton,
} from '../../commun/Buttons';
import DisplayDocumentValidation from '../../commun/DisplayDocuments/DisplayDocumentValidation';
import ExportComponent from '../../commun/ExportComponent';
import { ErrorIcon, SuccessIcon } from '../../commun/IconsFeedBack';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import {
    filtrationInterface,
    typeOrderOrFiltration,
} from '../BackOffice/Components/FiltrationColumnTable';
import { Transaction } from './Transaction';
import { ValidationProfilForm } from './ValidationUser';

import { AuditLogDto } from '@api/api';
import { MenuDashBoardAdminBoolState } from '@components/Context/AppContext';
import WrapComponentDashboardUser from '@components/DashboardUser/commun/WrapComponentDashboardUser';
import { TabsMenu } from '@components/commun/HorizontalMenu';
import ParentScrollComponent from '@components/commun/Layout/ParentScrollComponent';
import useDeleteItem from '../../commun/CustomHook/useDeleteItem';
import { displayHideLongText } from '../ProjectManage/Suivi/Suivis';
import Documents from './Documents';
import { Identite } from './Identity';
import SubscriptionAdmin from './SubscriptionAdmin';

interface Props {
    userId: number;
}

export interface IMenu {
    label: string;
    queryPage: string;
}

const UserManage: React.FC<Props> = ({ userId }) => {
    const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const [pageQuery, setPageQuery] = useQueryParam('pageUser', StringParam);

    const navigate = useNavigate();

    const menuList: IMenu[] = [
        {
            queryPage: 'general',
            label: 'Général',
        },
        {
            queryPage: 'subscriptions',
            label: 'Souscriptions',
        },
        {
            queryPage: 'documents',
            label: 'Documents',
        },
        {
            queryPage: 'transaction',
            label: 'Historique Transactions',
        },
        {
            queryPage: 'validation',
            label: 'Historique Validation',
        },
        {
            queryPage: 'audit-log',
            label: 'Audit Log',
        },
    ];
    const { data: user, isLoading: loading } =
        useGetUserWithAllInfoQuery(userId);

    const selectPage = () => {
        switch (pageQuery) {
            case 'Identité':
                return <Identite userId={userId} />;
            case 'documents':
                return <Documents userId={userId} />;
            case 'subscriptions':
                return <SubscriptionAdmin userId={userId} />;
            case 'transaction':
                return <Transaction userId={userId} />;
            case 'audit-log':
                return <AuditLog userId={userId} />;
            case 'validation':
                return <HistoriqueValidation user={user!} />;
            default:
                return <Identite userId={userId} />;
                break;
        }
    };

    const [togglePopUpValidationUser, setTogglePopUpValidationUser] =
        useState<boolean>(false);

    const [
        togglePopUpAssociationFondsUser,
        setTogglePopUpAssociationFondsUser,
    ] = useState<boolean>(false);

    const { data: userFonds, isLoading: isLoadingUserFonds } =
        useGetFondsUserQuery(userId, {
            skip: user === undefined || user === null,
        });

    const [statusQueryParam, setStatusQueryParam] = useQueryParam(
        'status',
        StringParam
    );

    const [filterQueryParam, setFilterQueryParam] = useQueryParam(
        'filterUsers',
        StringParam
    );

    const menuBoolState = useContext(MenuDashBoardAdminBoolState);

    return (
        <>
            {togglePopUpValidationUser && !userFonds ? (
                <ValidationProfilForm
                    setOpen={setTogglePopUpValidationUser}
                    open={togglePopUpValidationUser}
                    user={user!}
                />
            ) : null}

            {togglePopUpAssociationFondsUser && !userFonds ? (
                <AssociateUserToFunds
                    userId={userId}
                    open={togglePopUpAssociationFondsUser}
                    setOpen={setTogglePopUpAssociationFondsUser}
                />
            ) : null}
            <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
                <WrapComponentDashboardUser
                    title={`${user?.firstName} ${user?.lastName}`}
                    description="Manage user"
                    classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                    classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
                    backButton={true}
                    subTitle={
                        <div className="my-3 z-20 md:absolute top-0 flex gap-3  right-[40px]">
                            {!userFonds ? (
                                <>
                                    {user?.dateValidated ? (
                                        <SuccessButton
                                        // disabled={true}
                                        >
                                            Profil Validé
                                        </SuccessButton>
                                    ) : user?.dateSubscription ? (
                                        <PrimaryButton
                                            onClick={() =>
                                                setTogglePopUpValidationUser(
                                                    true
                                                )
                                            }
                                        >
                                            Valider le profil
                                        </PrimaryButton>
                                    ) : null}
                                </>
                            ) : null}

                            {userFonds ? (
                                <PrimaryButton>
                                    User Fonds {userFonds.name}
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton
                                    onClick={() =>
                                        setTogglePopUpAssociationFondsUser(true)
                                    }
                                >
                                    Associer un fonds
                                </PrimaryButton>
                            )}
                        </div>
                    }
                    onClickReturn={() => {
                        const string = [];
                        if (statusQueryParam !== undefined) {
                            string.push(`status=${statusQueryParam}&`);
                        }
                        string.push(`filterUsers=${filterQueryParam}`);

                        const res = string.join('');

                        navigate(`/DashBoardAdmin/Utilisateurs?${res}`);
                    }}
                    textBackButton="Retour"
                    classNameScollComponent="h-fit"
                >
                    <div className="relative w-full">
                        <div className="md:h-fit bg-backgroundColor w-full flex justify-center items-center mt-[-2px]   sticky top-[-2px] z-[99] mb-2 mx-auto">
                            <TabsMenu
                                className="  "
                                items={menuList}
                                queryPage="pageUser"
                                onClick={() => {}}
                            />
                        </div>
                        <ParentScrollComponent className="overflow_projectManage p-3 h-[82vh]">
                            {selectPage()}
                        </ParentScrollComponent>
                    </div>
                </WrapComponentDashboardUser>
            </div>

            {/* <div className="item_container rounded-lg lg:shadow-low p-3 !md:ml-2 !mt-2">
                <div className=" overflow-auto md:grid md:grid-rows-userInfoInAdminDashboard h-full p-2 lg:p-4">
                    <div className="md:max-h-24">
                        <div
                            onClick={() => {
                                const string = [];
                                if (statusQueryParam !== undefined) {
                                    string.push(`status=${statusQueryParam}&`);
                                }
                                string.push(`filterUsers=${filterQueryParam}`);

                                const res = string.join('');

                                navigate(`/DashBoardAdmin/Utilisateurs?${res}`);
                            }}
                            className="mt-4 w-fit md:mt-2 flex gap-3 cursor-pointer items-center"
                        >
                            <WhiteButton className="">
                                <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    className="mr-2 text-secondColor"
                                />
                                Retour
                            </WhiteButton>
                            <h1 className="text-2xl text-mainColor font-semibold font-mainFontFamily">
                                {user?.firstName} {user?.lastName.toUpperCase()}
                            </h1>
                        </div>
                        <div className="my-3 z-20 md:absolute space-x-2 top-5 right-[40px]">
                            {!userFonds ? (
                                <>
                                    {user?.dateValidated ? (
                                        <SuccessButton
                                        // disabled={true}
                                        >
                                            Profil Validé
                                        </SuccessButton>
                                    ) : user?.dateSubscription ? (
                                        <PrimaryButton
                                            onClick={() =>
                                                setTogglePopUpValidationUser(
                                                    true
                                                )
                                            }
                                        >
                                            Valider le profil
                                        </PrimaryButton>
                                    ) : null}
                                </>
                            ) : null}

                            {userFonds ? (
                                <PrimaryButton>
                                    User Fonds {userFonds.name}
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton
                                    onClick={() =>
                                        setTogglePopUpAssociationFondsUser(true)
                                    }
                                >
                                    Associer un fonds
                                </PrimaryButton>
                            )}
                        </div>

                        <Menu menuList={menuList} />
                    </div>
                    <ParentScrollComponent className="overflow_projectManage p-3 h-[82vh]">{selectPage()}
                        </ParentScrollComponent>
                </div>
            </div> */}
        </>
    );
};

export default UserManage;

function Menu({ menuList }: { menuList: IMenu[] }) {
    const [pageQuery, setPageQuery] = useQueryParam('pageUser', StringParam);

    return (
        <>
            {/* Menu with User identity, KYC, Documents, Historique Transactions, Audit log  */}
            <div className="flex h-fit justify-stretch w-full items-center flex-wrap my-2">
                {menuList.map((item, key) => (
                    <div
                        key={key}
                        className={`w-full md:w-fit flex items-center justify-center py-2 md:px-5 cursor-pointer ${
                            pageQuery === item.queryPage
                                ? 'border-b-4 border-b-mainColor'
                                : ''
                        }`}
                        onClick={() => setPageQuery(item.queryPage)}
                    >
                        <p className="">{item.label}</p>
                    </div>
                ))}
            </div>
        </>
    );
}

function AssociateUserToFunds({
    userId,
    open,
    setOpen,
}: {
    userId: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const {
        register,
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm<CreateAssociationBetweenFondsAndUserDto>({
        defaultValues: {
            userId: userId,
        },
    });

    const { data: fondsData } = useGetAllFondsQuery();

    // const { data: userFonds, isLoading: isLoadingUserFonds, refetch } =
    //   useGetFondsUserQuery(userId, {
    //     skip: userId === undefined || userId === null,
    //   });

    const { showError, showSuccess } = useNotificationContext();

    const [
        createAssociationFondsUser,
        { isLoading: isLoadingCreateAssociationFondsUser },
    ] = useAssociateFondsWithUserMutation();

    const onSubmit = async (data: CreateAssociationBetweenFondsAndUserDto) => {
        // console.log(data);
        await createAssociationFondsUser({
            createAssociationBetweenFondsAndUserDto: data,
            fondsId: data.fondsId,
        })
            .unwrap()
            .then(() => {
                showSuccess('Edited', 'User associated to fonds successfully');

                // refetch();
                setOpen(false);
            })
            .catch((res: any) => {
                showError(
                    'Error',
                    'Error associationting user to fonds',
                    res?.data?.message
                );
            });
    };

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            width="w-full max-w-md"
            buttonBoolean={false}
            title={() => {
                return (
                    <div className="relative">
                        <h3 className="text-3xl font-semibold leading-6 text-gray-900 ">
                            Associer ce compte à un fonds
                        </h3>
                    </div>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp gap-1"
            >
                <div className="flex flex-col gap-2 mx-auto w-11/12">
                    <SelectComponent
                        control={control}
                        register={register}
                        value={'fondsId'}
                        optionValues={
                            fondsData?.map((fond) => {
                                return {
                                    value: fond.id,
                                    label: fond.name,
                                };
                            }) ?? []
                        }
                    >
                        <label>Fonds</label>
                    </SelectComponent>
                    <div className="w-full mt-4 flex gap-2 mx-auto justify-center items-center">
                        <WhiteButton onClick={() => setOpen(false)}>
                            Cancel
                        </WhiteButton>
                        <PrimaryButton
                            disabled={!isValid}
                            type="submit"
                            loading={isLoadingCreateAssociationFondsUser}
                        >
                            Associer
                        </PrimaryButton>
                    </div>
                </div>
            </form>
        </PopUp>
    );
}

function HistoriqueValidation({
    user,
}: {
    user: UserWithAllInfoForAdminDto | undefined;
}) {
    const {
        data: documents,
        isLoading: loadingDocuments,
        refetch,
    } = useGetUserDocumentsQuery(user?.id || 0, {
        skip: user === undefined,
    });
    const { data: userAdmin, isLoading: loadingUser } = useFetchTokenQuery();

    if (user === undefined) {
        return (
            <div className="w-full flex justify-center items-center mt-5">
                <Loading />
            </div>
        );
    }

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-center font-semibold',
    };

    return (
        <>
            <div className="w-full relative mb-5">
                <h2 className="text-center font-titleFontFamily text-2xl font-semibold">
                    Historique de vos validations
                </h2>
            </div>

            <div className="relative">
                <div className="relative md:absolute md:top-[-40px] md:right-3">
                    <ExportComponent
                        headers={[
                            'Date',
                            'UserAdmin',
                            'Résultat',
                            'Commentaire',
                        ]}
                        title="Historique validations"
                        userData={{
                            name: `${userAdmin?.lastName.toLocaleUpperCase()} ${
                                userAdmin?.firstName
                            }`,
                        }}
                        data={
                            user?.checkUserProfile?.map((val) => {
                                return {
                                    Date: formatDate(new Date(val.date)),
                                    UserAdmin: val.userCheckName,
                                    Résultat:
                                        val.status === true
                                            ? 'Validé'
                                            : 'Refusé',
                                    comment: val?.comment,
                                };
                            }) || []
                        }
                    />
                </div>

                <div className="flex flex-col max-w-full overflow-x-auto">
                    <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
                        <div className="py-2 inline-block min-w-full">
                            <div className="overflow-hidden">
                                {/* <p>
  Valeur total du fonds{" "}
  {printLargeValue(
    projectValorisationInfoData?.valorisationByProjects?.toFixed(
      2
    )
  )}
</p> */}
                                <table className="min-w-full">
                                    <thead className="bg-white border-b border-t">
                                        <tr>
                                            <th
                                                scope="col"
                                                className={classNameObejct.head}
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className={classNameObejct.head}
                                            >
                                                UserAdmin
                                            </th>
                                            <th
                                                scope="col"
                                                className={classNameObejct.head}
                                            >
                                                Résultat
                                            </th>

                                            <th
                                                scope="col"
                                                className={classNameObejct.head}
                                            >
                                                Comment
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {user?.checkUserProfile &&
                                        user?.checkUserProfile?.length === 0 ? (
                                            <tr className="w-full mx-auto">
                                                <td
                                                    colSpan={4}
                                                    className="text-center text-gray-500 text-xl  pt-10"
                                                >
                                                    Aucune validation touvée
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {user?.checkUserProfile && (
                                                    <React.Fragment>
                                                        {user?.checkUserProfile?.map(
                                                            (
                                                                validation,
                                                                key
                                                            ) => (
                                                                <RowElementHistoriqueValidationTable
                                                                    validation={
                                                                        validation
                                                                    }
                                                                    key={key}
                                                                    number={key}
                                                                    documents={
                                                                        documents ??
                                                                        []
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function RowElementHistoriqueValidationTable({
    validation,
    number,
    documents,
}: {
    validation: CheckUserProfileDto;
    number: number;
    documents: DocumentUserDto[];
}) {
    const classNameObejct = {
        item: 'text-sm text-center font-light px-6 py-4 whitespace-nowrap',
    };

    const [open, setOpen] = useState(false);

    return (
        <>
            {open ? (
                <ViewValidationProfile
                    validation={validation}
                    setOpen={setOpen}
                    open={open}
                    documents={documents}
                />
            ) : null}
            <tr
                onClick={() => setOpen(true)}
                className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                    number % 2 === 0 ? 'bg-secondBackgroundColor' : 'bg-white '
                } `}
            >
                <td className={`${classNameObejct.item}`}>
                    {formatDate(new Date(validation.date))}
                </td>
                <td className={`${classNameObejct.item}`}>
                    {validation.userCheckName}
                </td>
                <td className={`${classNameObejct.item}`}>
                    {validation.status === true ? (
                        <span className=" text-green-600">Accepté</span>
                    ) : (
                        <span className=" text-red-600">Refusé</span>
                    )}
                </td>
                <td className={`${classNameObejct.item}`}>
                    {displayHideLongText(
                        'Aucun commentaire',
                        validation.comment,
                        60
                    )}
                </td>
            </tr>
        </>
    );
}

function ViewValidationProfile({
    validation,
    documents,
    setOpen,
    open,
}: {
    validation: CheckUserProfileDto;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    documents: DocumentUserDto[];
}) {
    const classNameDiv =
        'grid grid-cols-1 md:grid-cols-2 border-b-2 py-2 space-y-2 md:space-y-0';
    const classNameH3 =
        'text-sm font-mainFontFamily font-semibold text-center md:text-start';
    const classNameP = 'flex justify-center items-center';
    const classNameComment = ' px-2 md:px-0 text-start text-gray-400 italic';

    const [deleteValidation, { isLoading: isLoadingDelete }] =
        useDeleteCheckUserProfileMutation();

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        projectValorisationId: number;
    }>({
        deleteItem: () => deleteValidation(validation.id),
        message: 'validation',

        isLoading: isLoadingDelete,
    });

    const [openDocument, setOpenDocument] = useState(false);
    const [indexDocumentOpen, setIndexDocumentOpen] = useState<number>(0);
    return (
        <PopUp
            title={() => {
                return (
                    <div className=" font-semibold relative">
                        <p className="text-2xl">
                            Validation {formatDate(new Date(validation.date))}{' '}
                        </p>
                        <p className="text-md text-gray-400 italic">
                            {' '}
                            Effectué par {validation.userCheckName}{' '}
                        </p>

                        <FontAwesomeIcon
                            onClick={() => setOpenDelete(true)}
                            icon={faTrash}
                            className="absolute top-8 md:top-0 cursor-pointer right-4 transition-all hover:scale-105"
                        />
                    </div>
                );
            }}
            buttonBoolean={false}
            setOpen={setOpen}
            open={open}
        >
            <DeleteComponent title="cette validation" />

            <div className=" flex gap-2 flex-col ">
                <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Identité</h3>
                    <p className={`${classNameP}`}>
                        {validation.validIdentity ? (
                            <SuccessIcon />
                        ) : (
                            <ErrorIcon />
                        )}
                    </p>
                </div>
                {validation.commentIdentity !== '' ? (
                    <div className={`${classNameDiv}`}>
                        <h3 className={`${classNameH3}`}>
                            Commentaire identité
                        </h3>
                        <p className={`${classNameComment}`}>
                            {validation.commentIdentity ?? ''}
                        </p>
                    </div>
                ) : null}
                {documents.map((document, key) => (
                    <div
                        className={`${classNameDiv} cursor-pointer`}
                        key={key}
                        onClick={() => {
                            setOpenDocument(true);
                            setIndexDocumentOpen(key);
                        }}
                    >
                        {openDocument && indexDocumentOpen === key ? (
                            <DisplayDocumentValidation
                                document={document}
                                setOpen={setOpenDocument}
                                open={openDocument}
                                index={key}
                            />
                        ) : null}
                        <div className="flex flex-col gap-1">
                            <h3 className={`${classNameH3}`}>
                                {document.type} - {document.name}
                            </h3>
                            <p className="italic text-sm text-start text-gray-400">
                                Document {key + 1}
                            </p>
                        </div>
                        <div className={`${classNameP} relative`}>
                            {document?.status === StateStatus.validated ? (
                                <SuccessIcon />
                            ) : (
                                <ErrorIcon />
                            )}
                            <WhiteButton className="absolute right-0">
                                Détails
                            </WhiteButton>
                        </div>
                    </div>
                ))}
                <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Questions</h3>
                    <p className={`${classNameP}`}>
                        {validation.validQuestion ? (
                            <SuccessIcon />
                        ) : (
                            <ErrorIcon />
                        )}
                    </p>
                </div>
                {validation.commentQuestion !== '' ? (
                    <div className={`${classNameDiv}`}>
                        <h3 className={`${classNameH3}`}>
                            Commentaire questions
                        </h3>
                        <p className={`${classNameComment}`}>
                            {validation.commentQuestion ?? ''}
                        </p>
                    </div>
                ) : null}
                <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Validé</h3>
                    <p className={`${classNameP}`}>
                        {validation.status ? <SuccessIcon /> : <ErrorIcon />}
                    </p>
                </div>
                {validation.comment !== '' ? (
                    <div className={`${classNameDiv}`}>
                        <h3 className={`${classNameH3}`}>
                            Commentaire général
                        </h3>
                        <p className={`${classNameComment}`}>
                            {validation.comment ?? ''}
                        </p>
                    </div>
                ) : null}
                <div className="w-full flex justify-center items-center mt-3 md:col-span-2">
                    <PrimaryButton onClick={() => setOpen(false)}>
                        Fermer
                    </PrimaryButton>
                </div>
            </div>
        </PopUp>
    );
}

function AuditLog({ userId }: { userId: number }) {
    return (
        <div>
            <h3>AuditLog</h3>
            <AuditActionLog userId={userId} />
            <AuditOnUserLog userId={userId} />
        </div>
    );
}

function AuditOnUserLog({ userId }: { userId: number }) {
    const [transactionsDataFiltered, setTransactionsDataFiltered] = useState<
        AuditLogDto[]
    >([]);
    const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
    const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);
    const [filterColumn, setFilterColumn] = useState<
        filtrationInterface<AuditLogDto>[]
    >([
        {
            element: 'timeStamp',
            type: typeFiltration.date,
            value: 'decroissant',
            orderOrFiltration: typeOrderOrFiltration.order,
        },
    ]);

    const { data: auditLogOnUser } = useGetLogOnUserByUserIdQuery({
        userId,
        limit: nbItemsShowed,
        offset: startIntervalNbItems,
    });

    const filtrationFunction = () => {
        let transactionDataFiltered_init: AuditLogDto[] = auditLogOnUser ?? [];
        for (const item of filterColumn) {
            transactionDataFiltered_init = filterFunctions(
                transactionDataFiltered_init,
                item.element,
                item.type,
                item.value
            );
        }
        setTransactionsDataFiltered(transactionDataFiltered_init);
    };

    useEffect(() => {
        if (auditLogOnUser) {
            filtrationFunction();
        }
    }, [filterColumn, auditLogOnUser]);

    return (
        <div>
            <h3>Audit on user</h3>
        </div>
    );
}

function AuditActionLog({ userId }: { userId: number }) {
    const [transactionsDataFiltered, setTransactionsDataFiltered] = useState<
        AuditLogDto[]
    >([]);
    const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
    const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);
    const [filterColumn, setFilterColumn] = useState<
        filtrationInterface<AuditLogDto>[]
    >([
        {
            element: 'timeStamp',
            type: typeFiltration.date,
            value: 'decroissant',
            orderOrFiltration: typeOrderOrFiltration.order,
        },
    ]);
    const { data: auditLogAction } = useGetActionLogByUserIdQuery({
        userId,
        limit: nbItemsShowed,
        offset: startIntervalNbItems,
    });
    return <div>Audit on user</div>;
}

export const convertTypeDocumentInTypeValidation = (
    type: DocumentUserType
): TypeValidationDocumentUser => {
    if (
        [DocumentUserType.IDENTITY_1, DocumentUserType.IDENTITY_2].includes(
            type
        )
    ) {
        return TypeValidationDocumentUser.IDENTITY;
    } else if (type === DocumentUserType.ADDRESS) {
        return TypeValidationDocumentUser.RESIDENCY;
    } else if (type === DocumentUserType.ADDRESS_IDENTTITY) {
        return TypeValidationDocumentUser.IDENTITY_HOSY_THIRD_PARTY;
    } else if (type === DocumentUserType.ADDRESS_THIRD_PARTY) {
        return TypeValidationDocumentUser.RESIDENCY_HOST_THIRD_PARTY;
    } else {
        return TypeValidationDocumentUser.ATTESTATION_HOST_THIRD_PARTY;
    }
};
