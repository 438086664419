import { faClipboard, faCopy } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const CopyButton = ({ text }: { text: string }) => {
  const [copyToggle, setCopyToggle] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopyToggle(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [copyToggle]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      //   console.log("Text copied to clipboard");
      setCopyToggle(true); //
    } catch (err) {
      //   console.error("Failed to copy text: ", err);
    }
  };

  return (
    <button
      onClick={handleCopy}
      className="relative flex gap-3 items-center mx-3 w-[20px]"
    >
      <FontAwesomeIcon icon={faCopy} />
      {copyToggle ? (
        <p className="animate-apparition absolute w-[50px] -left-4 top-[-50px] italic rounded-md shadow-low bg-[rgba(240,240,240,0.9)]">
          Copié <FontAwesomeIcon icon={faCheck} />
        </p>
      ) : null}
    </button>
  );
};

export default CopyButton;
