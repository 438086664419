import React from "react";
import Navigation from "../components/commun/Navigation";

const NotFound: React.FC = () => {
  return (
    <React.Fragment>
      <Navigation bol_nosfonds={false} bol_transparent={false} />
      <div className="notfound">
        <h2>Erreur l'url correspond à aucune page</h2>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
