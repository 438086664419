import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Menu = () => {
  return (
    <div className="menu-reglementation-container">
      <NavLink to={"/Reglementations/conditions-generales-utilisation"}>
        Condition d'utilisations
      </NavLink>
      <NavLink to={"/Reglementations/Donnees-personnelles"}>
        Données personnelles
      </NavLink>
      <NavLink to={"/Reglementations/mentions-legales"}>
        Mentions légales
      </NavLink>
      <NavLink to={"/Reglementations/risques"}>
        Avertissements sur les risques
      </NavLink>
    </div>
  );
};

export default Menu;
