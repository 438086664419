import React from "react";
import { ISeries } from "./AreaChart";
import { ApexOptions } from "apexcharts";
import ApexCharts from "react-apexcharts";
import { printLargeValue } from "../../../function/Utils";
import { downloadIcon } from "../../DashboardUser/Fonds/PerformanceFonds";

interface Props {
  series: ISeries[];
  height: number;
  id: string;
}

const ApexLineChart = ({ series, height, id }: Props) => {
  const yAxes: any[] = [];
  series?.forEach((item, k) => {
    yAxes.push({
      title: {
        text: item.name,
      },
      opposite: k % 2 === 1,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        // color: "#008FFB",
      },
      decimalsInFloat: 2,
      labels: {
        formatter: (value: number) => {
          return printLargeValue(value.toFixed(2));
        },
      },
    });
  });

  const options: ApexOptions = {
    chart: {
      id: id,
      type: "line",
      // height: 350,
      animations: {
        enabled: false,
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "pan",
        show: false,
        tools: {
          download: downloadIcon,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 3, // Size of the dots
      strokeWidth: 2, // Width of the connecting lines
      strokeOpacity: 0.9,
      strokeColors: "#fff",
      colors: undefined, // Color of the dots
      hover: {
        size: 7,
      },
    },

    yaxis: yAxes,
    // yaxis: {
    //   tickAmount: 6,
    //   decimalsInFloat: 4,
    // },
    xaxis: {
      type: "datetime",
      // categories: series.data.map((d) => d.x),

      // min: new Date("01 Mar 2012").getTime(),
      // tickAmount: 6,
    },
    // tooltip: {
    //     x: {
    //         format: "dd MMM yyyy HH:mm",
    //     },
    //     y: yAxesTooltip,
    // },
    stroke: {
      width: 2,
      curve: "straight",
      lineCap: "butt",
    },
  };

  // let min = Math.max(0, series[0]?.data?.length - 1 - 12);
  const min = 0;

  return (
    <div>
      <ApexCharts
        type="line"
        series={series as any}
        options={options}
        height={`${height}`}
      />
    </div>
  );
};

export default ApexLineChart;
