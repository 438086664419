import React, { ReactNode } from 'react';
import {
    Control,
    Controller,
    FieldErrorsImpl,
    FieldValues,
    Path,
    UseFormRegister,
    UseFormWatch,
    useController,
} from 'react-hook-form';

interface Props<T extends FieldValues> {
    values: {
        label: string;
        value: number;
        title?: string;
        subTitle?: string;
    }[];
    name: Path<T>;
    register: UseFormRegister<T>;
    watch: UseFormWatch<T>;
    errors?: FieldErrorsImpl<T>;
    componentEnd?: () => JSX.Element;
    className?: {
        input?: string;
        label?: string;
        container?: string;
    };
    cursorNotAllowed?: true;
    control: Control<T, Path<T>>;
}

const CheckboxArrayComponent = <T extends object>(
    props: Props<T> & { children?: ReactNode }
) => {
    const {
        field: {
            value: property,
            onChange: propertyOnChange,
            ...restPropertyField
        },
    } = useController({
        name: props.name,
        control: props.control,
    });

    const jsxFunction = (): JSX.Element => {
        return (
            <>
                {props.values?.map((value, key) => (
                    <div key={key} className="mb-3">
                        {value.title ? (
                            <p className=" font-titleFontFamily text-2xl mb-3 mt-6 font-semibold text-mainColor">
                                {value.title}
                            </p>
                        ) : null}
                        <div className="flex gap-2 justify-start items-start relative">
                            <Controller
                                control={props.control}
                                name={props.name}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            type="checkbox"
                                            id={`${props.name}_${value.value}`}
                                            checked={(
                                                field.value as number[]
                                            )?.includes(value.value)}
                                            onChange={(e) => {
                                                const checked =
                                                    e.target.checked;
                                                let updatedValue: number[] =
                                                    field.value as number[];

                                                if (checked) {
                                                    updatedValue.push(
                                                        value.value
                                                    );
                                                } else {
                                                    updatedValue =
                                                        updatedValue?.filter(
                                                            (id) =>
                                                                id !==
                                                                value.value
                                                        );
                                                }

                                                field?.onChange(updatedValue);
                                            }}
                                            style={{
                                                width: '15px',
                                                height: '15px',
                                            }}
                                            className={`form-check-input absolute top-[4px] ${
                                                props?.cursorNotAllowed &&
                                                'pointer-events-none'
                                            }`}
                                        />
                                    </>
                                )}
                            />
                            <label
                                className={`pointer ml-5 ${
                                    props?.className?.label &&
                                    props?.className?.label
                                }`}
                                htmlFor={`${props.name}_${value.value}`}
                            >
                                {value.subTitle ? (
                                    <span className="font-semibold">
                                        {value.subTitle}
                                        <br />
                                    </span>
                                ) : null}
                                {value.label}
                            </label>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    return (
        <React.Fragment>
            {props.children}
            {props.cursorNotAllowed ? (
                <div
                    className={`cursor-not-allowed w-full ${props?.className?.container}`}
                >
                    {jsxFunction()}
                </div>
            ) : (
                <>
                    {props?.className?.container ? (
                        <div className={`${props?.className?.container}`}>
                            {jsxFunction()}
                        </div>
                    ) : (
                        <>{jsxFunction()}</>
                    )}
                </>
            )}
            {!!props.componentEnd && props.componentEnd()}
        </React.Fragment>
    );
};

export default CheckboxArrayComponent;
