import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const CGU = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="reglementation-page-container">
      <h1>Conditions Générales d'Utilisation</h1>
      <p>
        New Paradigms, Société en commandite spéciale (SCSp) au capital de x
        euros dont le siège social est situé 2 Rue Jean Giono 31130 Balma,
        Immatriculée au registre du commerce de Luxembourg sous le numéro B 251
        823 (ci-après « New Paradigms »), est :
      </p>
      <ul>
        <li>Fonds d'invesetissement</li>
      </ul>
      <p>
        New Paradigms exploite un site internet accessible à l'adress{" "}
        <a href="http://www.newparadigms.com">http://www.newparadigms.com</a>
      </p>
      <h2>Articlie liminaire</h2>
      <p>
        New Paradigms propose à l’Internaute une assistance technique accessible
        :
      </p>
      <ul>
        <li>
          à traver un formulaire disposible sur la page{" "}
          <Link to="/Contact">contact</Link>
        </li>
        .
      </ul>
      {/* à faire c'est comment noud contacter */}
      <h2>ARTICLE 1. Présentation des C.G.U</h2>
      <p>
        Les présentes Conditions Générales d'Utilisation (ci-après « CGU ») ont
        pour objet de définir les dispositions qui s'appliquent aux internautes
        personnes physiques ou morales dans le cadre de l’accès et l'utilisation
        des informations et services du Site (ci-après les « Internautes »), et
        ce quel que soit leur statut : Visiteur, Utilisateur ou Investisseur tel
        que définis à l’ARTICLE 3 ci-dessous et de décrire les services proposés
        sur le Site (« les Services »).
      </p>
      <p>
        Les présentes CGU pourront être complétées notamment par les conditions
        générales d’utilisation du prestataire de services de paiement et/ou de
        monnaie électronique ou tout intermédiaire mandaté par le Site ou par
        des conditions particulières qui seront présentées et devront être
        acceptées par l'Internaute préalablement à toute possibilité d'accès à
        certaines informations et certains services.
      </p>
      <h2>
        ARTICLE 2. Acceptation des C.G.U et conditions d’accès au Site et aux
        Services
      </h2>
      <p>
        Le Site est accessible à partir de l’adresse suivante{" "}
        <a href="http://www.newparadigms.com">http://www.newparadigms.com</a>
      </p>
      <p>
        L’accessibilité à certaines informations et services du Site est soumise
        à inscription et création d’un Compte dans les conditions définies dans
        l’ARTICLE 4 ci-après.
      </p>
      <p>
        Tout accès ou utilisation du Site vaut acceptation sans réserve des
        présentes CGU. L’Internaute qui n’accepte pas d’être lié par les
        présentes CGU ne doit pas accéder au Site ni utiliser les Services.
      </p>
      <p>
        New Paradigms peut modifier à tout moment les informations présentes sur
        le Site ainsi que dans les présentes CGU, notamment afin de respecter
        toute nouvelle législation et/ou règlementation. Les modifications de
        ces CGU sont opposables aux Internautes du Site à compter de leur mise
        en ligne. Dans ces conditions, l’Internaute est invité à consulter les
        CGU régulièrement.
      </p>
      <h2>ARTICLE 3. Statut des Internautes</h2>
      <p>
        Les Internautes peuvent avoir le statut suivant en fonction de leur
        activité sur le Site :
      </p>
      <ul>
        <li>
          « Visiteurs » désigne les Internautes personnes physiques ou morales
          accédant au Site mais ne s’inscrivant pas en qualité d’Utilisateur.
          Tout Visiteur est réputé accepter les règles régissant le
          fonctionnement du Site.
        </li>
        <li>
          « Utilisateurs » désigne les Internautes personnes physiques ou
          morales inscrits sur le Site
        </li>
        <li>
          « Investisseurs » désigne les Utilisateurs ayant investi dans un
          Projet via le Site
        </li>
      </ul>
      <h2>
        ARTICLE 4. Conditions de création d’un Compte (ci-avant et ci-après «
        Compte»)
      </h2>
      <p>Tout Visiteur peut se créer un Compte directement sur le Site.</p>
      <h3>4.1 Capacité juridique des titulaires d'un Compte</h3>
      <ul>
        <li>
          A toute personne physique disposant de la pleine capacité juridique
          pour s’engager au titre des présentes conditions générales. La
          personne physique qui ne dispose pas de la pleine capacité juridique
          ne peut accéder au Site et aux Services qu’avec l’accord de son
          représentant légal.
        </li>
        <li>
          A toute personne morale agissant par l’intermédiaire d’une personne
          physique disposant de la capacité juridique pour contracter au nom et
          pour le compte de la personne morale.
        </li>
      </ul>
      <h3>4.2 Création et utilisation du Compte Utilisateur</h3>
      <p>
        La création d’un Compte Utilisateur permet d’obtenir le statut
        d’Utilisateur. Tout Internaute procédant à la création d’un Compte
        Utilisateur déclare et garantit qu’il est dûment habilité et dispose des
        droits nécessaires s’il représente une personne morale,
      </p>
      <p>
        La Création d’un Compte Utilisateur nécessite d’accepter via une case à
        cocher les présentes CGU.{" "}
      </p>{" "}
      {/*ainsi que celle du prestataire de services de paiement et/ou de monnaie électronique.*/}
      <p>
        La création du Compte Utilisateur nécessite de remplir un formulaire en
        ligne impliquant la communication de :
      </p>
      <ul>
        <li>civilité</li>
        <li>son nom et prénom</li>
        <li>son adresse mail</li>
        <li>
          un mot de passe permettant ensuite d’accéder au Compte Utilisateur.
        </li>
      </ul>
      <p>
        Le Compte Utilisateur permet d’accéder à certains Services et notamment,
        sans que cette liste soit limitative :
      </p>
      <ul>
        <li>
          de naviguer sur le Site et d’accéder à davantage d'informations sur
          les fonds que le Compte Visiteur
        </li>
      </ul>
      <h3>4.3 Création et utilisation du Compte Investisseur</h3>
      <p>
        Pour accéder à des Services plus étendus, l’Utilisateur peut valider son
        profil investisseur. Le Compte Utilisateur sera alors validé en Compte
        Investisseur (ci-après « le Compte Investisseur »).
      </p>
      <p>
        Tout Utilisateur procédant à la création d’un Compte Investisseur
        déclare et garantit :
      </p>
      <p>
        (a) qu’il est dûment habilité et dispose des droits nécessaires s’il
        représente une personne morale,
      </p>
      <p>
        (b) que toutes les informations fournies lors de son inscription sont
        vraies et complètes,
      </p>
      <p>
        (c) qu’il maintiendra l'exactitude de telles informations en modifiant
        si nécessaire son Compte Investisseur.
      </p>
      <p>
        La création du Compte Investisseur nécessite de remplir un formulaire en
        ligne impliquant la communication d’un certain nombre de données
        obligatoires (et notamment pour les personnes physiques : civilité, nom
        et prénom, date et lieu de naissance, nationalité, adresse postale,
        adresse mail, numéro de téléphone, résidence fiscale, situation maritale
        ; pour les personnes morales : Extrait Kbis et statuts – niveau de
        revenus annuels net, répartition du patrimoine global) et de fournir des
        documents dont la liste est énoncée sur le Site conformément à la
        règlementation en vigueur relative à la lutte contre le blanchiment des
        capitaux et le financement du terrorisme.
      </p>
      <p>
        L’Utilisateur garantit que toutes les informations qu’il donne dans le
        formulaire d’inscription sont exactes, à jour et sincères et ne sont
        entachées d’aucun caractère trompeur.
      </p>
      <p>
        L’Utilisateur est informé et accepte que les informations saisies aux
        fins de création ou de mise à jour de son Compte Investisseur vaillent
        preuve de son identité. Les informations saisies par l’Utilisateur
        l’engagent dès leur validation.
      </p>
      <p>
        Toute déclaration fausse ou irrégulière pourra faire l’objet d’un
        traitement spécifique destiné à prévenir la fraude.
      </p>
      {/* <p>Enfin, l’Utilisateur pourra être contraint de répondre à un questionnaire d’adéquation permettant de mesure sa connaissance du risque et déterminer son profil investisseur.</p> */}
      {/* <p>La création d’un Compte Investisseur emporte la création d’un compte de paiement auprès du prestataire de paiement de New Paradimgs.</p> */}
      <p>
        Le Compte Investisseur permet d’accéder à des Services étendus par
        rapport au Compte Utilisateur et notamment d’accéder à des données
        supplémentaires sur les Projets et fonds ou d’investir dans les fonds
        présentés sur le Site.
      </p>
      <h3>4.4 Utilisation du Compte</h3>
      <p>
        L’ouverture d’un Compte donne accès à un espace personnel (ci-après :
        l’« Espace Personnel ») qui permet de gérer son utilisation des Services
        sous une forme et selon les moyens techniques que New Paradigms juge les
        plus appropriés pour rendre lesdits Services. L’Utilisateur peut accéder
        à tout moment à son Espace Personnel après s’être identifié à l’aide de
        son identifiant de connexion ainsi que de son mot de passe. Il s’engage
        à mettre à jour ces informations dans son Espace Personnel en cas de
        modifications, afin qu’elles correspondent toujours aux critères
        susvisés. L’Utilisateur s’engage à utiliser personnellement les Services
        et à ne permettre à aucun tiers de les utiliser à sa place ou pour son
        compte, sauf à en supporter l’entière responsabilité.
      </p>
      {/* <p>Il est pareillement responsable du maintien de la confidentialité de son identifiant et de son mot de passe. Il doit immédiatement contacter New Paradigms aux coordonnées mentionnées à l’ARTICLE liminaire des présentes s’il remarque que son Compte a été utilisé à son insu. Il reconnaît à New Paradigms le droit de prendre toutes mesures appropriées en pareil cas.</p> */}
      <p>
        Tout Utilisateur ou Investisseur ne peut bénéficier que d’un seul et
        unique Compte à son nom. En conséquence, l’Utilisateur ou Investisseur
        s'engage à ne pas créer ou utiliser sur le Site d'autres Comptes que
        celui initialement créé et attribué, que ce soit sous sa propre identité
        ou celle d'un tiers. L’identifiant unique du Compte est l’email de
        l’Utilisateur. Dès lors, si un Utilisateur souhaite créer un Compte à
        titre personnel et un Compte au nom de sa personne morale, il devra
        utiliser deux adresses mails distinctes.
      </p>
      {/* <p>Dans l'hypothèse où l’Utilisateur ou l’Investisseur aurait connaissance de ce qu'une autre personne a accédé à son compte, l’Utilisateur ou l’Investisseur en informera immédiatement New Paradigms aux coordonnées indiquées à l’ARTICLE liminaire des présentes et confirmera par courrier recommandé adressé au siège de New Paradigms tel qu’indiqué en en-tête des présentes cette information.</p> */}
      <h2>ARTICLE 5. Conditions d’utilisation du Site</h2>
      <p>
        L’Utilisateur s’engage à utiliser le Site dans les conditions prévues
        aux présentes CGU et aux éventuelles conditions particulières auxquelles
        il aurait pu adhérer conformément aux lois et règlements en vigueur.
        L’Utilisateur est seul responsable des informations, données,
        commentaires, images et plus généralement tous contenus qu’il transmet
        par l’intermédiaire du Site, et renonce à ce titre à engager tout
        recours à l'encontre de New Paradigms, notamment sur le fondement de
        l'atteinte au droit à l'image, à son honneur, à sa réputation ou à
        l'intimité de sa vie privée. New Paradigms ne peut être tenu responsable
        des éléments que l’Utilisateur divulgue de sa propre initiative. New
        Paradigms n'est pas responsable de l'exactitude des données fournies, ni
        des conséquences que la diffusion de ces données peut avoir sur un
        Internaute ou sur un Fonds. De ce fait, l’Utilisateur reconnait que son
        utilisation du Site se fait à ses « risques et périls ».
      </p>
      <h2>ARTICLE 6. Description des Services</h2>
      <p>
        Le Site fournit une solution permettant notamment aux Utilisateurs
        directement par l’intermédiaire du Site et à travers leur Espace
        Personnel :
      </p>
      <p>
        (i) d’obtenir le statut d’Investisseur en souscrivant dans des fonds
        proposés par New Paradigms.
      </p>
      <h3>6.1 Le Service d'investissement</h3>
      <p>
        L’Internaute qui envisage de réaliser un investissement devra valider
        son Compte Investisseur conformément à l’ARTICLE 4 des présentes.
      </p>
      <p>
        L’Investisseur devra alors suivre le processus d’investissement décrit
        sur le Site.
      </p>
      <p>
        La signature du bulletin de souscription vaut confirmation de la volonté
        d’investir.
      </p>
      <h3>6.2 Autres Services</h3>
      <p>
        Le Site propose en lecture un blog d’information proposant des articles
        sur l’actualité écrite par New Paradigms.
      </p>
      <p>
        En fonction du statut de l’Internaute, certaines fonctionnalités du Site
        ou certains Services sont ouverts :
      </p>
      <ul>
        <li>Le Visiteur peut accéder au Site dans une version dégradée,</li>
        <li>
          L’Utilisateur peut accéder aux Fiches Fonds avec un niveau
          d’information dégradé par rapport à l’Investisseur,
        </li>
        <li>
          L’Investisseur détenteur d’un Compte Investisseur a un accès intégral
          aux Fiches Fonds.
        </li>
      </ul>
      <p>
        New Paradigms peut également proposer des services de prestataires
        externes sur le Site.
      </p>
      <p>
        New Paradigms se réserve le droit de proposer tout autre Service qu’elle
        jugera utile, sous une forme et selon les fonctionnalités et moyens
        techniques qu’elle estimera les plus appropriés pour rendre lesdits
        Services.
      </p>
      <h2>ARTICLE 7. Conditions financières</h2>
      <p>New Paradigms propose à l’Internaute à titre gracieux :</p>
      <ul>
        <li>La navigation sur le Site en qualité de Visiteur,</li>
        <li>La création et l’utilisation d’un Compte Utilisateur,</li>
        <li>La création d’un Compte Investisseur.</li>
        {/*et par conséquent l’ouverture d’un compte de paiement.*/}
      </ul>
      <p>
        Le Service d’investissement tel qu’énoncé à l'articles 6.1 est payant
        selon les conditions et modalités indiquées sur le Site et notamment sur
        les Fiches Fonds.
      </p>
      <h2>ARTICLE 8. Données personnelles de l’Internaute</h2>
      <p>
        L’Internaute reconnaît que l’utilisation du Site et des Services vaut
        acceptation du traitement de ses données personnelles conformément à la
        loi applicable et aux dispositions de la politique de confidentialité.
      </p>
      <p>L’Utilisateur peut accéder à ces données dans son Espace Personnel.</p>
      <h2>ARTICLE 9. Données de New Paradigms</h2>
      <p>
        L’Utilisateur et notamment l’Investisseur ou le Donateur reconnaît et
        accepte expressément :
      </p>
      <p>
        (i) que les données recueillies sur le Site et les équipements
        informatiques de New Paradigms font foi de la réalité des opérations
        intervenues dans le cadre des présentes,
      </p>
      <p>
        (ii) que ces données constituent le seul mode de preuve admis entre les
        parties, notamment pour le calcul des sommes dues à New Paradigms.
      </p>
      <h2>ARTICLE 10. Obligation de l’Internaute</h2>
      <p>
        Sans préjudice des autres obligations prévues aux présentes,
        l’Internaute s’engage à respecter les obligations qui suivent.
      </p>
      <p>
        L’Internaute s’engage, dans son usage des Services, à respecter les lois
        et règlements en vigueur et à ne pas porter atteinte aux droits de tiers
        ou à l’ordre public.
      </p>
      <p>
        Il est seul responsable du bon accomplissement de toutes les formalités
        notamment administratives, fiscales et/ ou sociales et de tous les
        paiements de cotisations, taxes ou impôts de toutes natures qui lui
        incombent le cas échéant en relation avec son utilisation des Services.
        La responsabilité de New Paradigms ne pourra en aucun cas être engagée à
        ce titre.
      </p>
      <p>
        L’Internaute reconnaît avoir pris connaissance sur le Site des
        caractéristiques et contraintes, de l’ensemble des Services et notamment
        de l’avertissement général sur{" "}
        <Link to="/Reglementations/risques">
          les risques liés à l’investissement
        </Link>{" "}
        dans le cadre de l’utilisation des Services fournis par New Paradigms.
      </p>
      <p>
        L’Internaute s’engage à faire un usage strictement personnel des
        Services. Il s’interdit en conséquence de céder, concéder ou transférer
        tout ou partie de ses droits ou obligations au titre des présentes à un
        tiers, de quelque manière que ce soit.
      </p>
      <p>
        L’Internaute s’engage à fournir à New Paradigms toutes les informations
        nécessaires à la bonne exécution des Services. Plus généralement
        l’Internaute s’engage à coopérer activement avec New Paradigms en vue de
        la bonne exécution des présentes.
      </p>
      <p>
        L’Internaute s’interdit de diffuser, notamment et sans que cette liste
        soit exhaustive :
      </p>
      <ul>
        <li>
          des contenus pornographiques, obscènes, indécents, choquants ou
          inadaptés à un public familial, diffamatoires, injurieux, violents,
          racistes, xénophobes ou révisionnistes,
        </li>
        <li>des contenus contrefaisants,</li>
        <li>des contenus attentatoires à l’image d’un tiers,</li>
        <li>
          des contenus mensongers, trompeurs ou proposant ou promouvant des
          activités illicites, frauduleuses ou trompeuses,
        </li>
        <li>
          des contenus nuisibles aux systèmes informatiques de tiers (tels que
          virus, vers, chevaux de Troie, etc.),
        </li>
        <li>
          et plus généralement des contenus susceptibles de porter atteinte aux
          droits de tiers ou d’être préjudiciables à des tiers, de quelque
          manière et sous quelque forme que ce soit.
        </li>
      </ul>
      <p>
        L’Utilisateur est informé et accepte que la mise en œuvre des Services
        nécessite qu’il soit connecté à internet et que la qualité des Services
        dépend directement de cette connexion, dont il est seul responsable.
      </p>
      <p>
        L’Internaute s'engage à ne pas utiliser de dispositifs ou logiciels de
        toutes sortes afin de perturber ou tenter de perturber le bon
        fonctionnement du Site.
      </p>
      <h2>ARTICLE 11. OBLIGATION DE New Paradigms</h2>
      <p>
        New Paradigms mettra en œuvre tous les moyens afin de rendre le Site
        accessible 24 heures sur 24, sept jours sur sept, sauf en cas de force
        majeure ou d’un événement hors du contrôle de New Paradigms et sous
        réserve des périodes de maintenance et des pannes éventuelles.
      </p>
      <p>
        Dans la mesure du possible, New Paradigms avertira préalablement les
        Internautes des interruptions ou arrêts affectant toute ou partie du
        Site.
      </p>
      <p>
        New Paradigms s'engage à faire ses meilleurs efforts pour sécuriser
        l'accès, la consultation et l'utilisation des Services conformément aux
        règles d'usages de l'Internet.
      </p>
      <p>
        New Paradigms n'est pas responsable de l'altération, la perte ou la
        transmission accidentelle de données ou de l'envoi de virus via les
        contenus postés sur le Site par l’Internaute.
      </p>
      <p>
        En conséquence, la responsabilité de New Paradigms ne saurait être
        engagée notamment dans les cas suivants :
      </p>
      <ul>
        <li>
          interruptions momentanées d'une durée de quelques minutes, ou quelques
          heures, pour la mise à jour du Site ;
        </li>
        <li>
          difficultés de fonctionnement ou interruption momentanée des Services
          indépendamment de la volonté de New Paradigms notamment en cas
          d'interruption des services d'électricité ou de communications
          électroniques ;
        </li>
        <li>
          interruptions momentanées des Services nécessaires aux évolutions ou
          maintenance ;
        </li>
        <li>
          défaillance ou dysfonctionnements du réseau Internet dans la
          transmission de messages ou documents ;
        </li>
        <li>
          défaillance des prestataires et notamment ceux qui détiennent nos
          serveurs.
        </li>
      </ul>
      <h2>ARTICLE 12. Limite de responsabilité</h2>
      <h3>12.1 Absence ou limite de responsabilité</h3>
      <p>
        Du fait de son rôle limité d’intermédiaire, New Paradigms ne sera pas
        responsable à l'égard de l’Internaute de toute perte ou dommage qu'il
        pourrait subir.
      </p>
      <h4>12.1.1 Information en elle-même</h4>
      <ul>
        <li>
          Sur la qualité : New Paradigms n’est pas responsable de la confiance
          que l’Internaute pourrait accorder à l'exhaustivité, l'exactitude ou
          l'existence de toute donnée publiée sur le Site.
        </li>
        <li>
          Sur l’évolution : New Paradigms rappelle que les informations et
          services proposés sur le Site peuvent faire l’objet de modification ou
          de suppression, temporaire ou définitive, dans l'offre ou les
          fonctions proposées sur le Site ;
        </li>
        <li>
          Sur l’exhaustivité : Certaines informations présentées sur le Site
          peuvent contenir des prévisions ou autres déclarations prospectives
          concernant des événements futurs ou la performance financière des
          Projets. Ces déclarations ne sont que des prévisions, des opinions ou
          des estimations et la réalité peut se révéler très différente.
        </li>
      </ul>
      <h4>12.1.2 Défaut de communication</h4>
      <p>
        New Paradigms rappelle que les Utilisateurs sont responsables des
        informations précises concernant leurs Comptes.
      </p>
      <p>
        New Paradigms ne saurait voir sa responsabilité engagée du fait des
        activités ou des informations stockées si elle n’avait pas eu
        effectivement connaissance de leur caractère illicite ou si, dès le
        moment où elle en a eu connaissance, elle a agi promptement pour retirer
        ces données ou en rendre l’accès impossible.
      </p>
      <h4>12.1.3 Confidentialité</h4>
      <p>
        New Paradigms n’est pas responsable de l'incapacité de l'Utilisateur à
        conserver en toute sécurité et confidentialité son mot de passe ou les
        informations de son Compte.
      </p>
      <h4>12.1.4 Caractère public ou privé de l’information</h4>
      <p>
        New Paradigms n’est pas responsable de toute action ou fausse
        communication des Projets.
      </p>
      <h4>12.1.5 Relations entre les différents partenaires</h4>
      <p>
        New Paradigms n’est pas responsable des relations entre les différents
        partenaires et notamment : entre un Internaute et une société et/ou tout
        tiers dont le lien hypertexte apparait sur le Site.
      </p>
      <h4>12.1.6 Projets</h4>
      <h3>
        12.2 New Paradigms est expressément tenu à une obligation de moyens dans
        la fourniture du Service
      </h3>
      <p>
        Aucun conseil et aucune information, qu’ils soient oraux ou écrits,
        obtenus par l’Internaute dans le cadre de l’utilisation du Site ne sont
        susceptibles de créer des garanties non expressément prévues par les
        présentes CGU.
      </p>
      <p>
        Les sites et articles qui sont publiés sur le Site ou vers lesquels sont
        créés des liens ne constituent pas des consultations juridiques,
        comptables, commerciales, fiscales ou financières, qui supposent
        l’analyse d’un cas particulier par un professionnel.
      </p>
      <p>
        New Paradigms ne pourra pas être tenu responsable des dommages résultant
        de l’interprétation ou de l’utilisation des informations auxquelles le
        Site donne accès. Toutes les informations et/ou données contenues sur le
        Site peuvent contenir des erreurs techniques et/ou typographiques.
      </p>
      <p>
        L’Internaute est averti et conscient que New Paradigms n'aurait pas
        conclu le présent contrat sans les limitations de responsabilité et de
        garantie établies par les présentes.
      </p>
      <h2>ARTICLE 13. Confidentialité</h2>
      <p>
        Les Utilisateurs et Investisseurs auront accès à des informations
        détaillées relatives aux Projets sur les Fiches Projets. Toutes les
        informations relatives aux Projets, de quelque nature et/ou support
        qu’elles soient, doivent être considérées et traitées comme
        confidentielles (ci-après « Informations Confidentielles ») aussi
        longtemps que ces informations ne sont pas tombées dans le domaine
        public. Les Utilisateurs et Investisseurs s’engagent à ne pas divulguer
        toute ou partie des Informations Confidentielles à des tiers et à ne pas
        les utiliser dans un autre but que l’étude de l’opportunité d’investir
        dans les Projets.
      </p>
      <h2>ARTICLE 14. Blocage du Compte Utilisateur et Investisseur</h2>
      <p>
        Les Comptes, New Paradigms se réserve notamment le droit de suspendre ou
        bloquer immédiatement et sans préavis le Compte de l'Utilisateur ou
        Investisseur :
      </p>
      <ul>
        <li>
          qui aurait publié un contenu prohibé contraire aux lois et règlements
          en vigueur,
        </li>
        <li>
          ui aurait fourni des informations, fausses, inexactes ou incomplètes
          au moment de la création du Compte,
        </li>
        <li>
          qui aurait utilisé plusieurs Comptes sous sa propre identité ou celle
          de tiers sans avoir demandé et obtenu l’autorisation écrite préalable
          de New Paradigms,
        </li>
        <li>
          qui aurait réalisé une ou plusieurs promesses d’investissement sans
          jamais toutefois procéder aux versement des fonds, ce qui peut altérer
          la qualité des Services,
        </li>
        <li>en cas de détection d’anomalie sur le Compte,</li>
        <li>en cas d’utilisation anormale ou abusive du Compte</li>
        <li>
          en cas d’utilisation du compte de paiement à d’autres fins que celles
          de souscrire dans les projets proposés et percevoir les remboursements
          de ceux-ci.
        </li>
      </ul>
      <p>
        La suspension ou le blocage peut être temporaire et implique le fait de
        ne plus autoriser de souscriptions, de dépôt ou de retrait sur le compte
        de paiement ou de supprimer certaines souscriptions. Toutefois, New
        Paradigms peut décider à son initiative sans justification clôturer la
        relation d’affaires.
      </p>
      <p>
        Enfin, le prestataire de paiement de New Paradigms se réserve le droit,
        conformément à ces propres conditions générales d’utilisations, de
        suspendre l’accès ou bloquer sans préavis le Compte de l’Utilisateur ou
        l’Investisseur.
      </p>
      <h2>ARTICLE 15. Durée et Désinscription</h2>
      <p>Les présentes CGU s'appliquent :</p>
      <ul>
        <li>
          pour le Visiteur : de sa connexion au Site à la fin de sa navigation
          (« Déconnexion »);
        </li>
        <li>
          pour l’Utilisateur : de la création de son Compte jusqu'à sa clôture,
          pour quelque raison que ce soit.
        </li>
      </ul>
      <p>
        L’Utilisateur peut se désinscrire des Services à tout moment, en
        adressant une demande à cet effet à New Paradigms par email, aux
        coordonnées mentionnées à l’ARTICLE liminaire.
      </p>
      <p>
        Pour l’Utilisateur, la désinscription est effective dans un délai
        maximum de 7 (sept) jours à compter de cette demande.
      </p>
      <p>
        Toutefois, pour l’Utilisateur avec le statut d’Investisseur, la
        désinscription est effective dans un délai maximum de 7 (sept) jours à
        compter du moment où ce dernier n’utilise plus aucun Service du Site et
        sous réserve qu’il n’ait plus aucun investissement en cours.
      </p>
      <p>La désinscription effective entraine :</p>
      <ul>
        <li>
          la fermeture du Compte et la restitution le cas échéant, des sommes
          présentes sur ledit compte,
        </li>
        <li>l’absence d’accès aux données du Compte,</li>
      </ul>
      <h2>ARTICLE 16. Propriété intellectuelle</h2>
      <h3>16.1 Les droits et obligations de l’Internaute</h3>
      <p>
        Le Site est la propriété exclusive de New Paradigms. D'une manière
        générale, New Paradigms accorde un droit gratuit, personnel,
        non-exclusif et non-transférable d'accès et d'utilisation du Site, tout
        autre droit étant expressément exclu sans l'accord préalable écrit de
        New Paradigms. Le contenu du Site ne peut être téléchargé, copié,
        reproduit, distribué, transmis, diffusé, affiché, vendu, concédé sous
        licence ou exploité de toute autre manière et à quelque finalité que ce
        soit, sans l'accord préalable écrit de New Paradigms.
      </p>
      <h3>16.2 Les droits et obligations de New Paradigms</h3>
      <p>
        New Paradigms est titulaire de tous les droits de propriété
        intellectuelle ou d’usage relatifs au Site et aux éléments accessibles
        sur le Site, notamment : textes, photographies, images, icônes,
        illustrations, vidéos, sons, musiques, mises en page, charte graphique,
        logos, logiciels, programmes ou bases de données.
      </p>
      <p>
        Les marques et logos de New Paradigms et de ses partenaires figurant sur
        le Site sont des marques déposées. En vertu des articles L. 713-2 et
        suivant du Code de la Propriété Intellectuelle, sont interdites, sauf
        autorisation expresse de leurs titulaires, toute reproduction ou
        représentation totale ou partielle, toute modification ou suppression de
        ces marques.
      </p>
      <h2>ARTICLE 17. Liens hypertextes établis depuis le Site</h2>
      <h3>17.1 Liens hypertextes vers des sites tiers</h3>
      <h4>7.1.1 Les droits et devoirs de l’Internaute</h4>
      <p>
        Le Site peut contenir des liens hypertextes pointant vers d'autres sites
        Internet qui n'appartiennent pas à New Paradigms ou ne sont pas
        contrôlés par New Paradigms notamment ceux relatifs aux Projets
        (ci-après « Sites Tiers »).
      </p>
      <p>
        L’Internaute ne peut reproduire les liens hypertextes présents sur le
        Site.
      </p>
      <h4>17.1.2 Les droits et devoirs de New Paradigms</h4>
      <p>
        New Paradigms n'exerce aucun contrôle sur le contenu, les chartes de
        protection des données personnelles ou les pratiques des sites tiers et
        décline toute responsabilité à cet égard. New Paradigms n'est pas
        responsable de la disponibilité des sites tiers et ressources externes
        et ne cautionne ni la publicité, ni les produits ou autres matériels
        figurant sur ces sites Internet.
      </p>
      <h4>17.2 Liens hypertextes renvoyant au Site</h4>
      <p>
        Sont interdits sans autorisation préalable de New Paradigms tout lien
        hypertexte renvoyant au Site et utilisant notamment les techniques
        suivantes :
      </p>
      <ul>
        <li>
          Le "framing" défini comme une technique permettant, sans quitter le
          site d'origine, d'insérer une page du Site qui vient s'afficher dans
          un cadre de la page web d'origine ;
        </li>
        <li>
          Le "deep-linking" défini comme l'insertion d'un lien hypertexte
          renvoyant à une page secondaire du Site ;
        </li>
        <li>
          Le "inline linking" défini comme un hyperlien permettant d'inclure
          dans la page web d'origine une image provenant d'une autre page web
          est interdit.
        </li>
      </ul>
      <h2>ARTICLE 18. Clause de nullité</h2>
      <p>
        Si une ou plusieurs stipulations des présentes Conditions Générales
        d’Utilisation devaient être déclarées nulles, cette nullité n’aura aucun
        effet sur les autres stipulations qui demeureront valides et
        continueront à s’appliquer. Si une clause venait à être supprimée ou à
        manquer, elle serait remplacée par une disposition juridiquement valable
        et conforme à l'objet des présentes CGU.
      </p>
      {/* <h2>ARTICLE 19. Réclamations</h2> */}
      {/* à faire  */}
      {/* <h2>ARTICLE 20. Reprise d’activité de la Plateforme en cas de défaillance</h2> */}
      {/* à voire  */}
      <h2>ARTICLE 21. Conflit, compétences et loi applicable</h2>
      <p>
        Les présentes CGU sont régies, interprétées et appliquées conformément
        au droit français, la langue d'interprétation étant la langue française
        en cas de contestation sur la signification d'un terme ou d'une
        disposition des présentes.
      </p>
      <h2>ARTICLE 22. Entrée en vigueur</h2>
      <p>es présentes CGU ont été mises à jour le 13/10/2022</p>
    </div>
  );
};

export default CGU;
