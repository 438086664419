import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Footer = (): JSX.Element => {
  return (
    <footer>
      <div className="container_column">
        <div className="contact column">
          <h3>Contact</h3>
          {/* <p className="pointer">Prendre un rendez-vous</p> */}
          <p className="pointer">
            <Link to="/Contact"> Nous contacter</Link>
          </p>
        </div>
        <div className="suivre column">
          <h3>Nous suivre</h3>
          <a
            className="pointer"
            rel="noreferrer"
            href="https://www.linkedin.com/company/newparadigms/about/"
            target="_blank"
          >
            Linkedin
          </a>
        </div>
        <div className="plus column">
          <h3>En savoir plus</h3>
          <p className="pointer">Statistiques</p>
        </div>
        <div className="legales column">
          <h3>Informations légales</h3>
          <p className="pointer">
            <Link to="/Reglementations/mentions-legales">
              {" "}
              Mentions légales
            </Link>
          </p>
          <p className="pointer">
            <Link to="/Reglementations/conditions-generales-utilisation">
              CGU New Paradigms
            </Link>
          </p>
          <p className="pointer">
            <Link to="/Reglementations/Donnees-personnelles">
              Données personnelles
            </Link>
          </p>
          <p className="pointer">
            <Link to="/Reglementations/risques">
              Avertissements sur les risques
            </Link>
          </p>
        </div>
      </div>
      <div className="copyright">
        <p>
          Ce site est publié par NP Stable Sustainable Fund SCSp, dit "New
          Paradigms", société en commandite spéciale (SCSp) au capital de 2 505
          000.00€ au 31 juillet 2021 • Immatriculée au RCS de Luxembourg sous le
          numéro B 251 823 • Siège social : 5 rue Heienhaff, 1736 Senningerberg,
          Luxembourg • Directeur de la publication : Mr Antoine Flipo •
          L'hébergement du site est assuré par la société Planethoster :
          https://www.planethoster.com/fr/. Copyright{" "}
          <FontAwesomeIcon icon={faCopyright} className="faCopyright" />{" "}
          {new Date(Date.now()).getFullYear()} NP Stable Sustainable Fund SCSp.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
