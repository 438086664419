import React, { ReactNode, useState } from "react";
import FilterApparition from "./FilterApparition";

interface Props<T> {
  head: string[];
  array?: T[];
  height?: string;
  startIntervalNbItems?: number;
  setStartIntervalNbItems?: React.Dispatch<React.SetStateAction<number>>;
  nbItemsShowed?: number;
  setNbItemsShowed?: React.Dispatch<React.SetStateAction<number>>;
}

const TableComponent = <T extends object>(
  props: Props<T> & { children?: ReactNode }
) => {
  const classNameObejct = {
    head: `text-sm font-light px-1 py-4 whitespace-nowrap text-left font-semibold ${
      props.height ? "" : ""
    }`,
  };

  return (
    <React.Fragment>
      {props.array !== undefined &&
      props.startIntervalNbItems !== undefined &&
      props.nbItemsShowed !== undefined &&
      props.setNbItemsShowed !== undefined &&
      props.setStartIntervalNbItems !== undefined ? (
        <FilterApparition
          startIntervalNbItems={props.startIntervalNbItems}
          setNbItemsShowed={props.setNbItemsShowed}
          nbItemsShowed={props.nbItemsShowed}
          setStartIntervalNbItems={props.setStartIntervalNbItems}
          array={props.array}
        />
      ) : null}

      <WrappingTable height={props?.height}>
        <thead className="bg-white border-b border-t ">
          <tr
            className={`${
              props.height
                ? "bg-white sticky top-0 z-[2] border-b border-t  "
                : ""
            }`}
          >
            {props.head.map((val, key) => (
              <th key={key} scope="col" className={`${classNameObejct.head} `}>
                {val}
              </th>
            ))}
          </tr>
        </thead>
        {/* {!props.height ? <> {props.children}</> : null} */}
        {props.children}
      </WrappingTable>
      {/* <div className={`w-full overflow-auto ${props.height} rounded-b-md `}>
        <table className={`min-w-full border-collapse `}>
          {props.children}
        </table>
      </div> */}
    </React.Fragment>
  );
};

export default TableComponent;

export function WrappingTable({
  children,
  height,
}: {
  children: React.ReactNode;
  height?: string;
}) {
  return (
    <div className="flex flex-col overflow-x-auto w-full">
      <div className="overflow-x-auto lg:mx-auto min-w-full">
        <div className="py-2 inline-block min-w-full">
          <div
            className={`${
              height ? height + " overflow-auto" : "overflow-hidden"
            }  rounded-b-md `}
          >
            <table
              className={`${
                height ? " border-collapse" : "border-collapse"
              } min-w-full`}
            >
              {children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
