import React from 'react';

interface Props {
    className: string;
    children?: React.ReactNode;
    scrollClassName?: string;
}

const ParentScrollComponent = React.forwardRef<HTMLDivElement, Props>(
    ({ className, children, scrollClassName }, ref) => {
        return (
            <div className={`flex flex-col overflow-hidden ${className}  `}>
                <div
                    ref={ref}
                    className={`flex-1 pb-4 overflow-auto ${scrollClassName ? scrollClassName : ''}`}
                >
                    {children}
                </div>
            </div>
        );
    }
);

export default ParentScrollComponent;
