import React from "react";

interface Props {
  cover: string;
  name: string;
}

const ImgComponent: React.FC<Props> = ({ cover, name }) => {
  return (
    <div className="PageImg">
      <img src={cover} alt={`${name} cover`} />
    </div>
  );
};

export default ImgComponent;
