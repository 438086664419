import {
    useCreateSponsorCodeMutation,
    useFetchTokenQuery,
    useGetRecapSponsorshipUserQuery,
    useGetUserWithAllInfoForCustomerQuery,
} from '@api/api';
import { useContext, useState } from 'react';
import CopyButton from '../../../commun/CopyButton';
import Loading from '../../../commun/Loading';
import Spinner from '../../../commun/Spinner';
import { MenuDashBoardUserBoolState } from '../../../Context/AppContext';
import { useNotificationContext } from '../../../Context/notification-context';
import WrapComponentDashboardUser from '../../commun/WrapComponentDashboardUser';

const Sponsor = () => {
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [availableEarnings, setAvailableEarnings] = useState(0);

    const { showError, showSuccess } = useNotificationContext();

    const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();

    const { data: user, isLoading } = useGetUserWithAllInfoForCustomerQuery(
        userData?.id!,
        {
            skip: !userData?.id,
        }
    );

    const [createSponsorCode, { isLoading: loadingSponsorCode }] =
        useCreateSponsorCodeMutation();
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    const { data: recapSponsorShip, isLoading: isLoadingRecap } =
        useGetRecapSponsorshipUserQuery(userData?.id!, {
            skip: !userData?.id,
        });

    return (
        <div
            className={`mx-auto  md:mt-0 lg:px-2 md:pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto `}
        >
            <WrapComponentDashboardUser
                title="Parrainage"
                description="Partagez votre code de parrainage pour gagner une commission."
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
            >
                <dl className=" ">
                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-500">
                            Code de parrainage
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                            {user?.sponsorCode ? (
                                !loadingSponsorCode ? (
                                    <>
                                        <p className="text-center uppercase tracking-wide bg-secondBackgroundColor rounded-sm p-2">
                                            {user?.sponsorCode}
                                        </p>

                                        <CopyButton text={user?.sponsorCode} />
                                        {!user?.sponsor ||
                                        user.sponsor.length === 0 ? (
                                            <p className="text-green-500 italic">
                                                Pas encore utilisé
                                            </p>
                                        ) : (
                                            <p className="text-green-500 italic">
                                                Utilisé {user.sponsor.length}{' '}
                                                fois
                                            </p>
                                        )}
                                    </>
                                ) : (
                                    <Spinner size={5} />
                                )
                            ) : (
                                <p
                                    className="cursor-pointer rounded-md bg-mainColor text-white transition-all p-2 hover:scale-[1.02]"
                                    onClick={async () =>
                                        user &&
                                        (await createSponsorCode(user.id)
                                            .unwrap()
                                            .then((res) => {
                                                showSuccess(
                                                    'Créé',
                                                    'Code de parrainage créé avec succès'
                                                );
                                            })
                                            .catch((err) => {
                                                showError(
                                                    'Erreur',
                                                    'Erreur lors de la création du code de parainage'
                                                );
                                            }))
                                    }
                                >
                                    Générez votre code de parrainage
                                </p>
                            )}
                        </dd>
                    </div>
                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-500">
                            Votre lien d'invitation
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2">
                            {user?.sponsorCode ? (
                                <>
                                    <p className="py-2 px-1 bg-secondBackgroundColor rounded-md text-center w-fit max-w-[260px] break-words whitespace-normal">
                                        {window.location.origin}
                                        /SeConnecter?register=1&code=
                                        {user?.sponsorCode}
                                    </p>
                                    <CopyButton
                                        text={`${window.location.origin}/SeConnecter?register=1&code=${user?.sponsorCode}`}
                                    />
                                </>
                            ) : (
                                <p>
                                    Vous devez d'abord générer votre code de
                                    parrainage pour pouvoir partager votre lien
                                    d'invitation
                                </p>
                            )}
                        </dd>
                    </div>
                    {/* <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-semibold text-gray-500">Avantage</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-5">
              <div className="rounded-md shadow-low p-5">
                <p>
                  <span className="text-xl text-mainColor font-semibold font-titleFontFamily">
                    1 %{" "}
                  </span>{" "}
                  pour le parrain <span className="text-red-500">*</span>
                </p>
                <p className="italic text-titleColor text-sm">
                  Sur la première entrée du filleul
                </p>
              </div>
              <div className="rounded-md shadow-low p-5">
                <p>
                  <span className="text-xl text-mainColor font-semibold font-titleFontFamily">
                    0.5 %{" "}
                  </span>{" "}
                  pour le filleul <span className="text-red-500">*</span>
                </p>
                <p className="italic text-titleColor text-sm">
                  Sur la première entrée du filleul
                </p>
              </div>
            </dd>
          </div> */}
                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-500">
                            Comment ça fonctionne ?
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <p>
                                Partagez ce lien avec vos ami.es afin qu'ils ou
                                elles puissent s'incrire via celui-ci. Le
                                parrainage sera alors automatique et lors de
                                leur première entrée ils auront une réduction de
                                25% de leur frais d'entrée (1.5% au lieu de 2%).
                                Vous recevrez également une compensation pour
                                chaque filleul.le parrainé.e en fonction de la
                                valeur de leur première entrée.
                            </p>
                        </dd>
                    </div>

                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-500">
                            Commission récupérée
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {isLoadingRecap ? (
                                <Loading />
                            ) : (
                                <>
                                    {recapSponsorShip?.moneyReceived.toFixed(2)}{' '}
                                    €
                                </>
                            )}
                        </dd>
                    </div>
                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-500">
                            Commission disponible
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {isLoadingRecap ? (
                                <Loading />
                            ) : (
                                <>
                                    {(
                                        recapSponsorShip?.totalMoneyWon! -
                                        recapSponsorShip?.moneyReceived!
                                    ).toFixed(2)}{' '}
                                    €
                                </>
                            )}
                        </dd>
                    </div>
                </dl>
            </WrapComponentDashboardUser>
            {/* <div className="w-11/12 md:w-9/12 m-auto">
        <Diagram />
      </div> */}
        </div>
    );
};

export default Sponsor;
