import {
    ShareDto,
    useFetchTokenQuery,
    useGetSharesByUserIdQuery,
} from '@api/api';
import { Chart } from 'chart.js';
import React, { useContext, useEffect, useState } from 'react';
import { getShareValueFunction } from '../../../function/ComputationUserDashboard';
import {
    compareTwoDate,
    createDateArray,
    isDateClosed,
    isEmpty,
    lastDay,
    setDate,
    startMonth,
} from '../../../function/Utils';
import { useGetAllFondsQuery } from '../../../redux/features/fondsSlice';

import { useAppSelector } from '@api/store';
import { BackOfficeUserState } from '../../Context/AppContext';

const PieChartFunds = () => {
    const pageState = useAppSelector(
        (state) => state.pageMeasurement.measurements
    );
    const date = lastDay(new Date());
    const dateBis = startMonth(date);
    dateBis.setMonth(dateBis.getMonth() - 11);

    const dates = createDateArray(new Date(dateBis), date).map(
        (date) => new Date(date)
    );

    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

    const { data: shareDataFull, isLoading } = useGetSharesByUserIdQuery(
        user?.id ?? 0,
        {
            skip: !user,
        }
    );

    const shareData = shareDataFull?.shares;

    const { data: fondsData } = useGetAllFondsQuery();

    const backOfficeUserState = useContext(BackOfficeUserState);

    const [dateSelectedState, setDateSelected] = useState<Date>(
        setDate(backOfficeUserState?.year!, backOfficeUserState?.month!, 1)
    );

    useEffect(() => {
        if (!isEmpty(shareData) && backOfficeUserState !== null) {
            const sharesForSorted: ShareDto[] = [...(shareData ?? [])];
            const shareSorted = sharesForSorted.sort(
                (a, b) =>
                    new Date(a.date).getTime() - new Date(b.date).getTime()
            );

            const firstDate: Date = new Date(shareSorted?.[0].date);
            const dateSelected: Date = new Date(
                shareSorted?.[sharesForSorted.length - 1].date
            );
            // let dateSelected: Date = setDate(
            //   backOfficeUserState?.year!,
            //   backOfficeUserState?.month!,
            //   1
            // );
            if (compareTwoDate(dateSelected, dateSelected)) {
                // sharesForSorted = sharesForSorted.filter((val) =>
                //   isDateClosed(new Date(val.date), dateSelected)
                // );
                setDateSelected(dateSelected);
            } else {
                // sharesForSorted = sharesForSorted.filter((val) =>
                //   isDateClosed(new Date(val.date), lastestDate)
                // );
                setDateSelected(dateSelected);
            }
        }
    }, [shareData, backOfficeUserState]);

    let fondsIdInvested = shareData?.map((share) => share.fondsId);
    // get unique id
    fondsIdInvested = [...new Set(fondsIdInvested)];

    useEffect(() => {
        if (fondsData && BackOfficeUserState !== null && !isEmpty(shareData)) {
            const hook = document.getElementById(
                'myChart'
            )! as HTMLCanvasElement;
            const ctx = hook.getContext('2d')!;
            const myChart = new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: fondsData
                        ?.filter((f) => fondsIdInvested?.includes(f.id))
                        .map((fonds) => {
                            return fonds.name;
                        }),
                    datasets: [
                        {
                            label: 'Dataset 1',
                            data: fondsData?.map((fonds) => {
                                if (
                                    shareData &&
                                    shareData?.filter(
                                        (share) => share.fondsId === fonds.id
                                    ).length === 0
                                ) {
                                    return 0;
                                } else {
                                    return (
                                        (shareData ?? [])
                                            ?.filter(
                                                (share) =>
                                                    isDateClosed(
                                                        new Date(share.date),
                                                        dateSelectedState
                                                    ) &&
                                                    share.fondsId === fonds.id
                                            )
                                            ?.reduce((acc, val) => {
                                                const share =
                                                    getShareValueFunction(val);
                                                if (share !== null) {
                                                    return (
                                                        acc +
                                                        val.nbShare * share
                                                    );
                                                } else {
                                                    return acc;
                                                }
                                            }, 0) ?? 0
                                    );
                                }
                            }),
                            backgroundColor: [
                                '#353D40',
                                '#0A9155',
                                '#F2B138',
                                '#096742',
                                '#1b96ae',
                            ],
                        },
                    ],
                },
                options: {
                    responsive: true,

                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                font: {
                                    size: 16,
                                },
                            },
                        },

                        title: {
                            display: false,
                            text: 'Répartition des fonds',
                            color: 'rgb(40,40,40)',
                            position: 'top',
                        },
                    },
                },
            });
            // ctx.shadowColor = "black";

            return () => {
                myChart.destroy();
            };
        }
    }, [pageState.widthScreen, fondsData, shareData, dateSelectedState]);
    return (
        <React.Fragment>
            <h2 className="text-center text-2xl mb-5 w-full">
                Répartition de votre portefeuille
            </h2>
            <div className="max-w-xs min-w-[300px] mx-auto">
                <canvas id="myChart" className=""></canvas>
            </div>
        </React.Fragment>
    );
};

export default PieChartFunds;
