import jsPDF, { TextOptionsLight } from 'jspdf';

export function underlineText(text: string, x: number, y: number, doc: jsPDF) {
    // Calculate the width of the text
    const textWidth = doc.getTextWidth(text);
    doc.text(text, x, y);
    doc.setLineWidth(0.1);
    doc.setDrawColor(0); // Set the line color, black in this case
    doc.line(x, y + 1, x + textWidth, y + 1);
}

export function cusomBoldText(
    text: { content: string; type: 'normal' | 'bold' }[],
    x: number,
    y: number,
    doc: jsPDF,
    textOptionParagraph: TextOptionsLight
) {
    let xPosition = x;
    text.forEach((t) => {
        doc.setFont('helvetica', t.type);
        doc.text(t.content, xPosition, y, textOptionParagraph);
        xPosition += doc.getTextWidth(t.content);
    });
    doc.setFont('helvetica', 'normal');
}

export function headerComponent(
    title: string,
    y: number,
    height: number,
    doc: jsPDF
) {
    const textColor = [0, 0, 0];

    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(12);
    doc.text(title, 15, y + 2);
    doc.setFillColor(textColor[0], textColor[1], textColor[2]);
    doc.rect(15, y + 2.5, pageWidth - 25, 0.1, 'F');

    doc.rect(13, y - 2, 1, height, 'F');
    doc.setFontSize(9);
    doc.setFont('Helvetica', 'normal');
}
