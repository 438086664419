import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import {
    debounce,
    isEmpty,
    lastDay,
    removeAttribute,
    stringToFloat,
    stringToInt,
    transformDate,
} from '../../../function/Utils';
import { tableBackOfficeInterface } from '../../../types/BackOffice';

import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import InputComponent from '../../commun/formComponent/InputComponent';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import SelectMultiComponent from '../../commun/formComponent/SelectMultiComponent';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import {
    BackOfficeState,
    BackOfficeStateInterface,
    loadingBackOfficeInterface,
    LoadingBackOfficeState,
    MenuDashBoardAdminBoolState,
    RootBlackOfficeState,
} from '../../Context/AppContext';
import { useNotificationContext } from '../../Context/notification-context';
import DateManagement from './Components/DateManagement';
import OverviewBackOffice from './Overview/OverviewBackOffice';
import ViewTableBlackList from './ViewTable/ViewTableBlackList';
import ViewTablePlatform from './ViewTable/ViewTablePlatform';
import ViewTableProjects from './ViewTable/ViewTableProjects';
import ViewTableTransactions from './ViewTable/ViewTableTransactions';
import ViewTableUsers from './ViewTable/ViewTableUsers';

import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { useGetAllFondsQuery } from '../../../redux/features/fondsSlice';
import {
    FormCreateDocumentPlatform,
    FormCreateDocumentPlatformRequest,
    uploadDocumentPlatform,
} from '../../../redux/features/platformSlice';

import {
    CategoryDocumentPlatform,
    CreateBlackListDto,
    CreatePlatformDto,
    PlatformDto,
    TypeDocumentPlatform,
    useCreateBlacklistMutation,
    useCreatePlatformMutation,
    useGetAllPlatformsQuery,
    useGetProjectsSearchQuery,
} from '@api/api';
import CreateTransactionForm from '@components/commun/formComponent/ModelForm/Transaction/CreateTransactionForm';
import FieldArray from '../../commun/formComponent/FieldArray';
import RemoveFieldArrayComponent from '../../commun/formComponent/RemoveFieldArrayComponent';
import TextAreaComponent from '../../commun/formComponent/TextAreaComponent';
import TextEditorComponent from '../../commun/formComponent/TextEditorComponent';
import UploaderSingle from '../../commun/formComponent/UploaderSingle';
import WrapComponentDashboardUser from '../../DashboardUser/commun/WrapComponentDashboardUser';

interface Props {
    id?: number;
    addBool?: true;
}

const BackOffice: React.FC<Props> = ({ id, addBool }) => {
    const { data: fondsData } = useGetAllFondsQuery();

    const tableArray: tableBackOfficeInterface[] = [
        tableBackOfficeInterface.overview,
        tableBackOfficeInterface.project,
        tableBackOfficeInterface.users,
        tableBackOfficeInterface.transactions,
        tableBackOfficeInterface.blackLists,
        tableBackOfficeInterface.platform,
    ];

    const rootState = useContext(RootBlackOfficeState);

    const date = lastDay(new Date(Date.now()));
    // let date = setDate(2021, 11, 31);

    const [year, setYear] = useState<number>(date.getFullYear());
    const [month, setMonth] = useState<number>(date.getMonth());
    const [day, setDay] = useState<number>(date.getDate());

    const [loading, setLoading] = useState<boolean>(true);

    const loadingObject: loadingBackOfficeInterface = {
        loading: loading,
        setLoading: setLoading,
    };

    const [overviewState, setOverviewState] = useState<boolean>(false);

    const [addPlatformToggle, setAddPlatformToggle] = useState<boolean>(false);
    const [addBlacklistToggle, setAddBlacklistToggle] =
        useState<boolean>(false);
    const [addTransactionToggle, setAddTransactionToggle] =
        useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (
            rootState?.selectTable !== tableBackOfficeInterface.overview &&
            !overviewState
        ) {
            setOverviewState(true);
        } else if (
            rootState?.selectTable === tableBackOfficeInterface.overview &&
            overviewState
        ) {
            setOverviewState(false);
        }
    }, [rootState?.selectTable]);

    const [tableQuery, setTableQuery] = useQueryParam('table', StringParam);
    const [fondsQuery, setFondsQuery] = useQueryParam('fonds', NumberParam);

    const viewTableFunc = () => {
        switch (tableQuery) {
            case tableBackOfficeInterface.project:
                return (
                    <ViewTableProjects
                        editProjectId={id}
                        addProject={addBool}
                    />
                );
            case tableBackOfficeInterface.transactions:
                return (
                    <ViewTableTransactions
                        editTransactionId={id}
                        addTransaction={addBool}
                    />
                );
            case tableBackOfficeInterface.users:
                return <ViewTableUsers />;
            case tableBackOfficeInterface.blackLists:
                return <ViewTableBlackList />;
            case tableBackOfficeInterface.platform:
                return <ViewTablePlatform />;
            case tableBackOfficeInterface.overview:
                return <OverviewBackOffice />;
            default:
                return <OverviewBackOffice />;
        }
    };

    const backOfficeState: BackOfficeStateInterface = {
        year: year,
        month: month,
        day: day,
        setDay: setDay,
        setMonth: setMonth,
        setYear: setYear,
        fondsId: rootState?.fondsIdBackOffice,
        setFondsId: rootState?.setFondsIdBackOffice,
    };

    const classList = {
        link: 'rounded text-mainColor cursor-pointer italic px-3 py-1 text-md transition-all hover:scale-[1.01]',
    };

    const menuBoolState = useContext(MenuDashBoardAdminBoolState);

    return (
        <BackOfficeState.Provider value={backOfficeState}>
            <LoadingBackOfficeState.Provider value={loadingObject}>
                <React.Fragment>
                    {loading ? (
                        <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
                            <WrapComponentDashboardUser
                                title="Back office"
                                description=""
                                classNameHeader={`${
                                    !menuBoolState?.toggleMenu
                                        ? '!pl-[40px]'
                                        : ''
                                }`}
                                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
                            >
                                <div className=" px-2">
                                    <div className="flex mt-2 gap-5 mx-auto w-11/12 md:w-full md:mx-0 md:justify-center flex-wrap">
                                        {/* <StoreFondsHistoricalPerformance /> */}
                                        <p
                                            onClick={() => {
                                                navigate(
                                                    '/DashBoardAdmin/ProjectsManage?fonds=1&projectManage=ADD&returnBackoffice=1'
                                                );
                                            }}
                                            className={`${classList.link}`}
                                        >
                                            Ajouter des projets
                                        </p>
                                        <p
                                            onClick={() =>
                                                setAddTransactionToggle(true)
                                            }
                                            className={`${classList.link}`}
                                        >
                                            Ajouter des transactions
                                        </p>
                                        <p
                                            className={`${classList.link}`}
                                            onClick={() =>
                                                setAddBlacklistToggle(true)
                                            }
                                        >
                                            Ajouter des projets à la liste noire
                                        </p>
                                        <p
                                            className={`${classList.link}`}
                                            onClick={() =>
                                                setAddPlatformToggle(true)
                                            }
                                        >
                                            Ajouter des plateformes
                                        </p>
                                    </div>
                                    <div className="grid xl:grid-cols-2 my-3">
                                        <div className="flex justify-center items-center flex-col w-full mx-auto my-3">
                                            <div className="px-5 py-1 flex gap-1 flex-wrap justify-center">
                                                {fondsData?.map((val, key) => (
                                                    <p
                                                        key={key}
                                                        onClick={() => {
                                                            rootState?.setFondsIdBackOffice(
                                                                fondsData?.filter(
                                                                    (fonds) =>
                                                                        fonds.name ===
                                                                        val.name
                                                                )[0].id!
                                                            );
                                                            setFondsQuery(
                                                                fondsData?.filter(
                                                                    (fonds) =>
                                                                        fonds.name ===
                                                                        val.name
                                                                )[0].id!
                                                            );
                                                        }}
                                                        className={`${
                                                            rootState?.fondsIdBackOffice ===
                                                            val.id
                                                                ? 'rounded-md shadow-basic text-titleColor'
                                                                : null
                                                        } cursor-pointer transition-all hover:scale-[1.01] py-1 px-3 font-titleFontFamily`}
                                                    >
                                                        {val.name}
                                                    </p>
                                                ))}
                                            </div>

                                            <div className="mt-5 flex gap-5 items-center">
                                                <div className="p-1 flex gap-1 flex-wrap justify-center">
                                                    {tableArray?.map(
                                                        (val, key) => (
                                                            <p
                                                                key={key}
                                                                onClick={() => {
                                                                    rootState?.setSelectTable(
                                                                        val
                                                                    );
                                                                    setTableQuery(
                                                                        val
                                                                    );
                                                                }}
                                                                className={`${
                                                                    tableQuery ===
                                                                    val
                                                                        ? 'rounded-md bg-secondBackgroundColor text-mainColor shadow-in'
                                                                        : null
                                                                } cursor-pointer transition-all hover:scale-[1.01] py-1 px-3 font-titleFontFamily`}
                                                            >
                                                                {val}
                                                            </p>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <DateManagement />
                                        </div>
                                    </div>

                                    {addPlatformToggle ? (
                                        <AddPlatform
                                            addPlatformToggle={
                                                addPlatformToggle
                                            }
                                            setAddPlatformToggle={
                                                setAddPlatformToggle
                                            }
                                        />
                                    ) : null}

                                    {addBlacklistToggle ? (
                                        <AddBlacklist
                                            addBlacklistToggle={
                                                addBlacklistToggle
                                            }
                                            setAddBlacklistToggle={
                                                setAddBlacklistToggle
                                            }
                                        />
                                    ) : null}
                                    {/* {addProjectToggle ? (
                    <AddProject
                      addProjectToggle={addProjectToggle}
                      setAddProjectToggle={setAddProjectToggle}
                    />
                  ) : null} */}
                                    {addTransactionToggle ? (
                                        <AddTransaction
                                            addTransactionToggle={
                                                addTransactionToggle
                                            }
                                            setAddTransactionToggle={
                                                setAddTransactionToggle
                                            }
                                        />
                                    ) : null}

                                    <div className="">{viewTableFunc()}</div>
                                </div>
                            </WrapComponentDashboardUser>
                        </div>
                    ) : (
                        <Loading />
                    )}
                </React.Fragment>
            </LoadingBackOfficeState.Provider>
        </BackOfficeState.Provider>
    );
};

export default BackOffice;

function AddTransaction({
    addTransactionToggle,
    setAddTransactionToggle,
}: {
    addTransactionToggle: boolean;
    setAddTransactionToggle: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const rootState = useContext(RootBlackOfficeState);
    const defaultValues = { fondsId: rootState?.fondsIdBackOffice };

    return (
        <CreateTransactionForm
            renderButtons={({ isLoading }) => (
                <>
                    <WhiteButton
                        onClick={() => {
                            setAddTransactionToggle(false);
                        }}
                        className="w-full"
                    >
                        Cancel
                    </WhiteButton>
                    <PrimaryButton
                        loading={isLoading}
                        type="submit"
                        className="w-full"
                    >
                        Add
                    </PrimaryButton>
                </>
            )}
            defaultValues={defaultValues}
            container={({ children, setExpanded, expanded }) => (
                <PopUp
                    open={addTransactionToggle}
                    setOpen={setAddTransactionToggle}
                    buttonBoolean={false}
                    expand={expanded}
                    setExpand={setExpanded}
                    title={() => {
                        return (
                            <>
                                <h3 className="text-2xl font-semibold leading-6 text-gray-900">
                                    Ajouter une transaction
                                </h3>
                            </>
                        );
                    }}
                >
                    {children}
                </PopUp>
            )}
        />
    );
}

export interface FormCreatePlatform extends CreatePlatformDto {
    documents: FormCreateDocumentPlatform[];
}

function AddPlatform({
    addPlatformToggle,
    setAddPlatformToggle,
}: {
    addPlatformToggle: boolean;
    setAddPlatformToggle: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const { register, handleSubmit, watch, control, setValue, getValues } =
        useForm<FormCreatePlatform>({
            defaultValues: {
                documents: [],
            },
        });

    const { data: fondsData } = useGetAllFondsQuery();

    const [
        addPlatform,
        { isLoading: isLoadingEdit, isSuccess: isSuccessEdit },
    ] = useCreatePlatformMutation();

    const { refetch } = useGetAllPlatformsQuery();

    const { showError, showSuccess } = useNotificationContext();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmit = async (data: FormCreatePlatform) => {
        let dataToSend: CreatePlatformDto = data;
        dataToSend = removeAttribute(dataToSend, 'documents');
        await addPlatform(dataToSend)
            .unwrap()
            .then(async (res: PlatformDto) => {
                if (data?.documents?.length > 0) {
                    for (let i = 0; i < data?.documents?.length; i++) {
                        const document = data.documents[i];

                        const dataFile: FormCreateDocumentPlatformRequest = {
                            file: document.file[0] as File,
                            type: document.type,
                            platformId: res.id,
                            category: CategoryDocumentPlatform.public,
                            platformName: data.name,
                            fileName: document.file[0].name,
                            comment: document?.comment,
                            key: '',
                        };

                        await uploadDocumentPlatform(dataFile)
                            .then((res) => {
                                if (res.sucess) {
                                    showSuccess(
                                        'Envoyé',
                                        'Le document a été envoyé avec le succès'
                                    );
                                } else {
                                    showError(
                                        'Erreur',
                                        `Erreur lors de l'envoie de ${dataFile.fileName}`
                                    );
                                }
                            })
                            .catch((res) => {
                                showError(
                                    'Erreur',
                                    `Erreur lors de l'envoie de ${dataFile.fileName}`
                                );
                            });
                    }
                }

                showSuccess('Created', 'Platform added successfully');
            })
            .catch((err) => {
                showError('Error', 'Error adding this platform');
            });

        setIsLoading((curr) => !curr);
    };

    const {
        field: { onChange: onChangeComment, value: comment },
    } = useController<FormCreatePlatform>({
        name: 'description',
        control: control,
    });

    return (
        <PopUp
            open={addPlatformToggle}
            setOpen={setAddPlatformToggle}
            submitItemName={'Ajouter'}
            buttonBoolean={false}
            width="w-full md:w-10/12"
            onClickSubmit={() => {
                handleSubmit(onSubmit);
            }}
            title={() => {
                return (
                    <>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Ajouter une plateforme
                        </h3>
                    </>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp text-start"
            >
                <InputComponent register={register} value={'name'}>
                    <LabelComponentForm>
                        Nom de la plateforme{' '}
                        <span className="text-red-500">*</span>
                    </LabelComponentForm>
                </InputComponent>

                <SelectMultiComponent
                    register={register}
                    setValue={setValue}
                    value={'fondsIds'}
                    optionValues={
                        fondsData
                            ? fondsData?.map((fond) => {
                                  return { value: fond.id, label: fond.name };
                              })
                            : []
                    }
                    control={control}
                    optionsRender={(option) => option.label}
                    valueRender={(option) => option.value}
                >
                    <LabelComponentForm>
                        Fonds <span className="text-red-500">*</span>
                    </LabelComponentForm>
                </SelectMultiComponent>

                <TextEditorComponent
                    content={comment as any as string}
                    setContent={onChangeComment}
                    className=" w-full"
                >
                    <LabelComponentForm>Description</LabelComponentForm>
                </TextEditorComponent>

                <TitleComponentForm className="!w-full mt-3">
                    Document
                </TitleComponentForm>

                <FieldArray
                    control={control}
                    className="w-full"
                    name="documents"
                >
                    {({ fields, append, remove }) => (
                        <>
                            <div className="flex flex-col w-full mb-2">
                                {fields.map((field, index) => (
                                    <div key={field.id} className={``}>
                                        <SelectComponent
                                            register={register}
                                            control={control}
                                            value={`documents.${index}.type`}
                                            getValues={getValues}
                                            setValue={setValue}
                                            optionValues={Object.values(
                                                TypeDocumentPlatform
                                            ).map((v) => {
                                                return {
                                                    label: v,
                                                    value: v,
                                                };
                                            })}
                                        >
                                            <h3 className=" font-semibold mt-3">
                                                Choisisser le type du document
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </SelectComponent>

                                        <UploaderSingle
                                            register={register}
                                            value={`documents.${index}.file`}
                                            watch={watch}
                                            setValue={setValue}
                                            loading={isLoading}
                                            button={false}
                                        ></UploaderSingle>
                                        <div className=" w-full flex justify-center items-center">
                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className=" w-full flex justify-center items-center">
                                <PrimaryButton
                                    className=" mx-auto mb-2"
                                    onClick={() =>
                                        append({
                                            // projectId: project.current?.id!,
                                        } as any)
                                    }
                                >
                                    Ajouter un document
                                </PrimaryButton>
                            </div>
                        </>
                    )}
                </FieldArray>

                <div className="mt-5 w-fit mx-auto sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 text-center">
                    <WhiteButton
                        onClick={() => {
                            setAddPlatformToggle(false);
                        }}
                    >
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit">Add</PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}

function AddBlacklist({
    addBlacklistToggle,
    setAddBlacklistToggle,
}: {
    addBlacklistToggle: boolean;
    setAddBlacklistToggle: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        control,
        getValues,
    } = useForm<CreateBlackListDto>();

    const { showError, showSuccess } = useNotificationContext();

    const { data: fondsData } = useGetAllFondsQuery();

    const rootState = useContext(RootBlackOfficeState);
    const [search, setSearch] = useState('');

    const { data: projectData } = useGetProjectsSearchQuery(
        {
            fondsId: rootState?.fondsIdBackOffice ?? 0,
            search: search,
        },
        {
            skip: !rootState?.fondsIdBackOffice,
        }
    );

    const [
        addBlackList,
        { isLoading: isLoadingEdit, isSuccess: isSuccessEdit },
    ] = useCreateBlacklistMutation();

    const onSubmit = async (data: CreateBlackListDto) => {
        await addBlackList({
            ...data,
            comment: data.comment ?? undefined,
            fondsId: data.fondsId,
            dates: data.dates.map((dateb) => {
                return {
                    date: transformDate(dateb.date)?.toISOString(),
                    deletedAt: dateb.deletedAt
                        ? transformDate(dateb.deletedAt)?.toISOString()
                        : undefined,
                };
            }),
            projectId: data.projectId,
            interests: data.interests.map((pi, index) => {
                if (index === 0) {
                    return {
                        value: stringToFloat(pi.value),
                        fondsId: stringToFloat(data.fondsId),
                        // projectId: stringToInt(data.id),
                        date: transformDate(
                            data.dates?.[0].date
                        )?.toISOString(),
                    };
                }
                return {
                    value: stringToFloat(pi.value),
                    fondsId: stringToInt(data.fondsId),
                    // projectId: stringToInt(data.id),
                    date: transformDate(pi.date)?.toISOString(),
                };
            }),
        })
            .unwrap()
            .then((res) => {
                showSuccess(
                    'Created',
                    'Project added to the blacklist successfully'
                );
            })
            .catch((err) => {
                showError('Error', 'Error adding this project in blacklist');
            });
    };

    const setDefautlValues = (): void => {
        reset({
            fondsId: rootState?.fondsIdBackOffice,
            dates: [
                {
                    date: new Date(Date.now()).toJSON().split('T')[0] as any,
                    deletedAt: undefined,
                },
            ],
            interests: [
                {
                    date: new Date(Date.now()).toJSON().split('T')[0],
                    value: undefined,
                    fondsId: rootState?.fondsIdBackOffice,
                },
            ],
        });
        // setValue("ProjetId", array[0]);
    };

    useEffect(() => {
        if (!isEmpty(fondsData) && rootState?.fondsIdBackOffice) {
            setDefautlValues();
        }
    }, [fondsData, rootState?.fondsIdBackOffice]);

    const updateDateEnd = useCallback(
        debounce(() => {
            if (getValues(`dates.0.date`) != null) {
                setValue(
                    `interests`,
                    getValues(`interests`).map((val, index) => {
                        if (index === 0) {
                            return {
                                ...val,
                                date: transformDate(
                                    new Date(getValues(`dates.0.date`))
                                )
                                    .toJSON()
                                    .split('T')[0],
                            };
                        } else {
                            return val;
                        }
                    })
                );
            }
        }),
        []
    );

    useEffect(() => {
        updateDateEnd();
    }, [watch(`dates.0.date`)]);

    return (
        <PopUp
            open={addBlacklistToggle}
            setOpen={setAddBlacklistToggle}
            submitItemName={'Ajouter'}
            buttonBoolean={false}
            onClickSubmit={() => {
                onSubmit(watch());
            }}
            title={() => {
                return (
                    <>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Ajouter un project à la black list
                        </h3>
                    </>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
            >
                <SelectComponent
                    register={register}
                    value={'fondsId'}
                    container={true}
                    optionValues={
                        fondsData?.map((val) => {
                            return {
                                value: val.id,
                                label: val.name,
                            };
                        }) ?? []
                    }
                    control={control}
                >
                    <h3>
                        Fonds <span className="required">*</span>
                    </h3>
                </SelectComponent>

                <SelectComponent
                    register={register}
                    control={control}
                    setSearch={setSearch}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    value={'projectId'}
                    container={true}
                    optionValues={
                        projectData?.map((project, key) => {
                            return {
                                label: project.name,
                                value: project.id,
                            };
                        }) ?? []
                    }
                >
                    <h3>
                        Projet <span className="required">*</span>
                    </h3>
                </SelectComponent>
                <FieldArray control={control} className="w-full" name="dates">
                    {({ fields, append, remove }) => (
                        <>
                            <div className="flex flex-col w-full space-y-2 mb-2">
                                {fields.map((field, index) => (
                                    <div
                                        key={field.id}
                                        className={`grid ${
                                            index > 0
                                                ? 'grid-cols-[1fr,1fr,auto]'
                                                : 'grid-cols-2'
                                        }  w-full gap-x-2 relative`}
                                    >
                                        <InputComponent
                                            register={register}
                                            type="date"
                                            value={`dates.${index}.date`}
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Date{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>

                                        <InputComponent
                                            register={register}
                                            type="date"
                                            required={false}
                                            value={`dates.${index}.deletedAt`}
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                DeletedAt
                                            </h3>
                                        </InputComponent>
                                        {index > 0 ? (
                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                            <PrimaryButton
                                className=" mx-auto mb-2"
                                onClick={() =>
                                    append({
                                        date: new Date()
                                            .toJSON()
                                            ?.split('T')[0],
                                        deletedAt: undefined,
                                        // projectId: project.current?.id!,
                                    } as any)
                                }
                            >
                                Ajouter
                            </PrimaryButton>
                        </>
                    )}
                </FieldArray>

                <h3 className="text-center my-2 mx-auto">
                    List des intérêt en fonction de la date
                </h3>
                <FieldArray control={control} name="interests">
                    {({ fields, append, remove }) => (
                        <>
                            <div className="flex flex-col w-full space-y-2 mb-2">
                                {fields.map((field, index) => (
                                    <div
                                        key={field.id}
                                        className={`grid ${
                                            index > 0
                                                ? 'grid-cols-[1fr,1fr,auto]'
                                                : 'grid-cols-2'
                                        }  w-full gap-x-2 relative`}
                                    >
                                        <InputComponent
                                            register={register}
                                            type="date"
                                            value={`interests.${index}.date`}
                                            cursorNotAllowed={
                                                index === 0 ? true : undefined
                                            }
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Date{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>

                                        <InputComponent
                                            register={register}
                                            type="number"
                                            value={`interests.${index}.value`}
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Intérêt{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>
                                        {index > 0 ? (
                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                            <PrimaryButton
                                className=" mx-auto mb-2"
                                onClick={() =>
                                    append({
                                        date: new Date()
                                            .toJSON()
                                            ?.split('T')[0],
                                        value: 0,
                                        fondsId: watch('fondsId')!,
                                        // projectId: project.current?.id!,
                                    })
                                }
                            >
                                Ajouter
                            </PrimaryButton>
                        </>
                    )}
                </FieldArray>

                <TextAreaComponent
                    register={register}
                    value={'comment'}
                    required={false}
                >
                    <h3>Commentaire</h3>
                </TextAreaComponent>
                <div className="mt-5 w-full sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 text-center">
                    <WhiteButton
                        onClick={() => {
                            setAddBlacklistToggle(false);
                        }}
                    >
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit">Add</PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}
