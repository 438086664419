import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import UpdatePasswordSecurity from "./UpdatePasswordSecurity";

const Security = () => {
  const styleObject = {
    icon: "absolute -right-8 top-1 scale-75 transition-all duration-300",
    h2: "text-base md:text-2xl pointer relative w-max md:mx-auto",
  };
  return (
    <div className="item_container config_1 flex flex-col space-y-4 items-center  overflow-auto relative">
      <div className="md:-mt-20 T_1 sub_item_container boxShadow_false relative md:top-1/3">
        <div className="flex flex-col ml-5 space-y-4 mb-10 overflow-y-auto md:overflow-auto ">
          <h2 className={styleObject.h2}>
            Modifier le mot de passe admin{" "}
            <FontAwesomeIcon
              icon={faArrowRight}
              className={`${styleObject.icon} `}
            />
          </h2>

          <div className="md:w-1/2 mx-auto">
            <UpdatePasswordSecurity />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
