import {
    ProjectStatus,
    TypeTransaction,
    UpdateProjectClosureDto,
    useGetProjectByIdQuery,
    useGetTransactionsProjectQuery,
    useGetValorisationsProjectQuery,
    useUpdateClosureProjectMutation,
} from '@api/api';
import { stringToFloat, transformDate } from '@utils/Utils';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import CheckboxComponent from '../../../commun/formComponent/CheckboxComponent';
import InputComponent from '../../../commun/formComponent/InputComponent';
import RadioComponent from '../../../commun/formComponent/RadioComponent';
import PopUp from '../../../commun/PopUp';
import { useNotificationContext } from '../../../Context/notification-context';

function FormClosure({
    editToggle,
    setEditToggle,
    valorisation,
    projectId,
    refetchValo,
}: {
    projectId: number;
    editToggle: boolean;
    setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
    valorisation: number;
    refetchValo: any;
}) {
    const { showSuccess, showError } = useNotificationContext();

    const {
        data: projectsValorisationData,
        isLoading: isLoadingProjectsValorisation,
        refetch: refetchProjectsValorisation,
    } = useGetValorisationsProjectQuery(
        {
            pathProjectId: projectId,
            queryProjectId: projectId,
        },
        {
            skip: projectId === undefined,
        }
    );

    const { register, handleSubmit, reset, getValues, watch } =
        useForm<UpdateProjectClosureDto>();

    const { data: project, isLoading } = useGetProjectByIdQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );
    const { data: transactions, refetch } = useGetTransactionsProjectQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    const setDefautlValues = (): void => {
        reset({
            id: project?.id,
            status:
                project?.status === ProjectStatus.terminé ||
                project?.status === ProjectStatus.defaut
                    ? project?.status
                    : ProjectStatus.terminé,
            dateEnd: project?.closed
                ? new Date(project.dateEnd).toJSON().split('T')[0]
                : new Date(Date.now()).toJSON().split('T')[0],
            closed: true,
            valorisation: {
                comment: 'Fermeture du projet',
                valorisation: valorisation,
                initialAmount:
                    transactions
                        ?.filter((t) => t.type === TypeTransaction.cashProject)
                        ?.reduce((sum, curr) => curr.amount + sum, 0) ?? 0,
                fondsId: project?.fondsId,
                projectId: project?.id,
                interest:
                    project?.interests[project?.interests.length - 1].value,
            },
        });
    };

    useEffect(() => {
        if (project && transactions && projectId) {
            setDefautlValues();
        }
    }, [project, transactions]);

    const [
        editClosureProject,
        { isLoading: isLoadingEdit, isSuccess: isSuccessEdit },
    ] = useUpdateClosureProjectMutation();

    const onSubmit = async (data: UpdateProjectClosureDto) => {
        data = {
            ...data,
            dateEnd: transformDate(new Date(data.dateEnd))?.toISOString(),
            valorisation: {
                ...data.valorisation,
                valorisation: stringToFloat(data.valorisation.valorisation),
                date: transformDate(new Date(data.dateEnd))?.toISOString(),
            },
        };

        await editClosureProject({
            id: projectId,
            updateProjectClosureDto: data,
        })
            .unwrap()
            .then(async (res) => {
                showSuccess('Edited', 'Project edited successfully');
                await refetchValo();
                await refetch();
                await refetchProjectsValorisation();
                setEditToggle(false);
            })
            .catch((err) => {
                showError('Error', 'Error editing project');
            });
    };

    return (
        <>
            <PopUp
                open={editToggle}
                setOpen={setEditToggle}
                title={() => {
                    return (
                        <h3 className="text-xl font-mainFontFamily leading-6 font-medium mb-3 text-gray-900">
                            Clôturer le projet
                        </h3>
                    );
                }}
                buttonBoolean={false}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formTemplateSingUp"
                >
                    <InputComponent
                        register={register}
                        value="dateEnd"
                        type="date"
                    >
                        <h3 className="text-lg font-mainFontFamily leading-6 font-medium mb-3 text-gray-900">
                            Date fermeture projet{' '}
                            <span className="required">*</span>
                        </h3>
                    </InputComponent>

                    <InputComponent
                        register={register}
                        value={'valorisation.valorisation'}
                        type={'number'}
                        className={{
                            input: 'border-2 border-gray-300 p-2 rounded-lg',
                        }}
                    >
                        <h3 className="text-lg font-mainFontFamily leading-6 font-medium mb-3 text-gray-900">
                            Valorisation <span className="required">*</span>
                        </h3>
                    </InputComponent>

                    <RadioComponent
                        watch={watch}
                        className={{
                            container: 'flex gap-3',
                        }}
                        register={register}
                        name="status"
                        values={[
                            {
                                label: 'Fermer',
                                value: ProjectStatus.terminé,
                            },
                            {
                                label: 'Défaut',
                                value: ProjectStatus.defaut,
                            },
                        ]}
                    >
                        <h3 className="text-lg font-mainFontFamily leading-6 font-medium mb-3 text-gray-900">
                            Statut de la fermeture
                        </h3>
                    </RadioComponent>

                    <CheckboxComponent
                        register={register}
                        values={[
                            {
                                label: 'Projet fermé',
                                name: 'closed',
                            },
                        ]}
                        watch={watch}
                    />
                    <div className="mt-5 w-full sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 text-center">
                        <WhiteButton
                            onClick={() => {
                                setEditToggle(false);
                            }}
                        >
                            Cancel
                        </WhiteButton>
                        <PrimaryButton type="submit">Edit</PrimaryButton>
                    </div>
                </form>
            </PopUp>
        </>
    );
}

export default FormClosure;
